import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderTypeE } from 'enums';
import { WalletE } from 'blockchain-api/ewallets-api';
import { AthleteTradingInfoI } from 'types';

import { formatToUSD } from 'utils/currency-formatter';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';

import { FinalStateE, ModalStateI } from './hcx-card-confirmation-modal';

import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import styles from './buy-content.module.scss';

interface DataI {
  contractId: AthleteTradingInfoI['contractId'];
  amount: number;
  orderType: OrderTypeE;
  price: number;
  date: string;
}

interface HCXCardConfirmationModalBuyContentPropsI {
  type: string;
  data?: null | DataI;
  modalState: ModalStateI;
  handleWalletSelectChange?: (selectedWallet: WalletE) => void;
}

export const getDateStrForContent = (date: string | number | Date) =>
  `GTD : ${dateFormatter(date, DateFormatE.M_D_YYYY_H_MM_UTC).replace(', ', ' @ ')} GMT`;

export const HCXCardConfirmationModalBuyContent: FC<HCXCardConfirmationModalBuyContentPropsI> = ({
  type,
  data,
  modalState,
  handleWalletSelectChange,
}) => {
  const [selectedWallet, setSelectedWallet] = useState<WalletE>(WalletE.Metamask);

  const { t } = useTranslation('hcx-card');

  const { loading, finalState } = modalState;

  const handleSelectChange = (e: SelectChangeEvent<WalletE>) => {
    handleWalletSelectChange && handleWalletSelectChange(e.target.value as WalletE);
    setSelectedWallet(e.target.value as WalletE);
  };

  if (!data) return null;

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (finalState === FinalStateE.Success) {
    return (
      <Box display="flex" alignItems="center" mt={2}>
        ✔️ Bid successful ✔️
      </Box>
    );
  }

  if (finalState === FinalStateE.Error) {
    return (
      <Box display="flex" alignItems="center" mt={2}>
        ❌ Something went wrong ❌
      </Box>
    );
  }

  const { amount, contractId, orderType, price, date } = data;

  return (
    <div>
      <FormControl variant="standard">
        <InputLabel id="order-good-until-label" shrink>
          {t('wallet')}
        </InputLabel>
        <Select
          labelId="order-good-until-label"
          id="order-good-until"
          size="small"
          value={selectedWallet}
          name="orderGoodUntil"
          onChange={handleSelectChange}
        >
          <MenuItem value={WalletE.Metamask}>{WalletE.Metamask}</MenuItem>
          <MenuItem value={WalletE.Coinbase}>{WalletE.Coinbase}</MenuItem>
        </Select>
      </FormControl>
      <Typography className={styles.confirmationContent}>
        {`${type} ${amount} ${contractId} @ ${formatToUSD(price)} ${orderType.toLowerCase()}`}
        <br />
        {type !== 'Bid' && getDateStrForContent(date)}
      </Typography>
    </div>
  );
};
