import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const CashMultipleIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18" fill="currentColor" width="32" height="32">
    <path
      d="M3.75 4.5H17.25V13.5H3.75V4.5ZM10.5 6.75C11.0967 6.75 11.669 6.98705 12.091 7.40901C12.5129 7.83097 12.75 8.40326 12.75 9C12.75 9.59674 12.5129 10.169 12.091 10.591C11.669 11.0129 11.0967 11.25 10.5 11.25C9.90326 11.25 9.33097 11.0129 8.90901 10.591C8.48705 10.169 8.25 9.59674 8.25 9C8.25 8.40326 8.48705 7.83097 8.90901 7.40901C9.33097 6.98705 9.90326 6.75 10.5 6.75ZM6.75 6C6.75 6.39783 6.59196 6.77936 6.31066 7.06066C6.02936 7.34196 5.64782 7.5 5.25 7.5V10.5C5.64782 10.5 6.02936 10.658 6.31066 10.9393C6.59196 11.2206 6.75 11.6022 6.75 12H14.25C14.25 11.6022 14.408 11.2206 14.6893 10.9393C14.9706 10.658 15.3522 10.5 15.75 10.5V7.5C15.3522 7.5 14.9706 7.34196 14.6893 7.06066C14.408 6.77936 14.25 6.39783 14.25 6H6.75ZM0.75 7.5H2.25V15H14.25V16.5H0.75V7.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
