import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import styles from './attention-message.module.scss';

export interface AttentionMessagePropsI {
  state: 'alert' | 'info';
}

export const AttentionMessage: FC<AttentionMessagePropsI> = ({ state }) => {
  const { t } = useTranslation('talent-application');

  if (!state) {
    return null;
  }

  return (
    <Box className={state === 'alert' ? styles.backAlert : styles.backInfo}>
      <WarningIcon
        fontSize="small"
        className={state === 'alert' ? styles.textAlert : styles.textInfo}
      />
      {state === 'alert' && (
        <Typography variant="subtitle2" className={styles.textAlert}>
          {t('alert-the-ipo-profile-section-must-be-completed')}
        </Typography>
      )}
      {state === 'info' && (
        <Box display="flex">
          <Typography variant="subtitle2" mr={4} className={styles.textInfo}>
            {t('confirmation-pending')}
          </Typography>
          <Typography variant="subtitle2" mr={2} className={styles.textInfo}>
            {t('you-have')}
          </Typography>
          {1}
          <Typography variant="subtitle2" ml={2} className={styles.textInfo}>
            {t('sponsor-confirmation-pending.')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
