import { FC } from 'react';
import { Typography } from '@mui/material';
import { formatNumber } from 'utils/number-formatter';

import { MOCK_CHART_DATA } from './mock-data';

import styles from './iho-or-cho-chart.module.scss';
import classNames from 'classnames';

interface IhoOrChoChartPropsI {
  className?: string;
}

const IhoOrChoChart: FC<IhoOrChoChartPropsI> = ({ className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <table className={styles.chart}>
        <tbody>
          {MOCK_CHART_DATA.map(({ value, width }) => (
            <tr key={`${value} ${width}`}>
              <>
                <th>
                  <Typography variant="body1">
                    {formatNumber(value, { minimumFractionDigits: 2 })}
                  </Typography>
                </th>
                <td>
                  <div className={styles.rate} style={{ width: `${width}` }} />
                </td>
              </>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IhoOrChoChart;
