import classNames from 'classnames';
import type { FC } from 'react';

import { Box } from '@mui/material';

import { useAthletes } from 'apolloClient/hooks';
import { ChartLineVectorIcon } from 'components/icons/chart-line-vector-icon';
import { BarIconWrapper } from 'components/bar-icon-wrapper';

import { Ticker, TickerAthlete } from './components';
import { MOCK_TICKERS } from './data.model';

import styles from './ticker-bar.module.scss';

export const TickerBar: FC = () => {
  const athletes = useAthletes();

  return (
    <Box className={classNames('TickerBar', styles.root)}>
      <Box className={styles.tickers}>
        <BarIconWrapper className={styles.icon}>
          <ChartLineVectorIcon />
        </BarIconWrapper>
        <Box className={styles.symbolContainer}>
          {MOCK_TICKERS.map(({ symbol, gain }) => {
            return <Ticker key={`${symbol}-${gain}`} symbol={symbol} gain={gain} />;
          })}
        </Box>
        <Box className={styles.symbolContainer}>
          {athletes.slice(0, 6).map((athlete) => {
            return <TickerAthlete key={athlete.id} athlete={athlete} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};
