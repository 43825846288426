import { useAtom } from 'jotai';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { WalletE } from 'blockchain-api/ewallets-api';
import { placeBidOrder } from 'blockchain-api/bid-orders';
import { useAthleteContractQuery } from 'generated/graphql';
import { HCXCardBid } from 'features/hcx-card/hcx-card-bid';
import { SubmitDataI } from 'features/hcx-card/hcx-card-bid/hcx-card-bid';
import { getAuctionStatus } from 'pages/human-capital-mint/helpers';
import { selectedAthleteIAtom } from 'store/athletes.store';

export const HCXCardOfferingBid: FC = () => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  const { data } = useAthleteContractQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { id: `${athlete!.id}` },
    skip: !athlete,
  });

  const auctionStats = useMemo(() => {
    if (data && athlete) {
      const auction = data.athleteContract?.dutchAuction || null;

      if (!auction) {
        return null;
      }

      return {
        blockchainId: auction.blockchainId,
        status: getAuctionStatus(
          Number(auction.blockchainId),
          auction.status,
          auction.startDate,
          auction.durationDays
        ),
        floorPrice: auction.floorPrice,
        recommendedBid: auction.stats?.recommendedBid ?? 0,
        numBidders: auction.stats?.numBidders ?? 0,
        highBid: auction.stats?.highBid ?? 0,
        lowBid: auction.stats?.lowBid ?? 0,
      };
    }
  }, [data, athlete]);

  const handleSubmit = useCallback(
    async (submitData: SubmitDataI, wallet: WalletE) => {
      console.table('===>>Offering:');
      console.table(submitData);

      const { amount, price } = submitData;

      try {
        return await placeBidOrder(
          wallet,
          Number(auctionStats ? auctionStats.blockchainId : 0),
          amount,
          price
        );
      } catch (error) {
        console.error(error);
        return 'Sorry, something went wrong';
      }
    },
    [auctionStats]
  );

  if (!athlete?.id || !auctionStats) {
    return null;
  }

  return <HCXCardBid athlete={athlete} auctionStats={auctionStats} onSubmit={handleSubmit} />;
};
