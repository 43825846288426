import classNames from 'classnames';
import { useAtom } from 'jotai';
import type { FC } from 'react';
import { useMemo, useEffect } from 'react';

import { LatestTradingPairStatsSubscription, TradingPairStatsQuery } from 'generated/graphql';
import {
  AthleteTradingInfo,
  AthleteTradingInfoConfigTypeE,
  getAthleteTradingInfoProps,
  getEmptyStats,
} from 'components/athlete-trading-info';
import { AthleteI } from 'types';

import { latestTradingStatsAtom, updateLatestTradingStatsAtom } from 'store/trading-stats.store';

import styles from './athlete-card-price-line.module.scss';

export interface AthleteCardPriceLinePropsI {
  athleteById: AthleteI;
  tradingPairStats: TradingPairStatsQuery | undefined;
  tradingPairStatsSub: LatestTradingPairStatsSubscription | undefined;
  className?: string;
}

export const AthleteCardPriceLine: FC<AthleteCardPriceLinePropsI> = ({
  athleteById,
  tradingPairStats,
  tradingPairStatsSub,
  className,
}) => {
  const { tradingPairId } = athleteById.tradingInfo;
  const [tradingStats] = useAtom(latestTradingStatsAtom);
  const [, updateLatestStats] = useAtom(updateLatestTradingStatsAtom);

  useEffect(() => {
    if (tradingStats && tradingStats[tradingPairId]) {
      return;
    }
    const tradingPair = tradingPairStats?.tradingPairs[0];
    if (tradingPair?.stats) {
      updateLatestStats({
        [tradingPair.id]: tradingPair.stats,
      });
    }
  }, [tradingStats, tradingPairId, tradingPairStats?.tradingPairs, updateLatestStats]);

  useEffect(() => {
    const latestTradingStats = tradingPairStatsSub?.latestTradinPairStats;
    if (latestTradingStats && tradingPairId) {
      updateLatestStats({
        [tradingPairId]: latestTradingStats,
      });
    }
  }, [tradingPairStatsSub?.latestTradinPairStats, tradingPairId, updateLatestStats]);

  const athleteTradingInfoProps = useMemo(
    () =>
      getAthleteTradingInfoProps(
        athleteById,
        !tradingStats || !tradingStats[tradingPairId]
          ? getEmptyStats()
          : tradingStats[tradingPairId],
        [
          AthleteTradingInfoConfigTypeE.Volume24,
          AthleteTradingInfoConfigTypeE.FloatValue,
          AthleteTradingInfoConfigTypeE.MarketCap,
        ]
      ),
    [athleteById, tradingStats, tradingPairId]
  );

  if (!athleteTradingInfoProps) return null;

  return (
    <div className={classNames(styles.root, className)}>
      <AthleteTradingInfo {...athleteTradingInfoProps} />
    </div>
  );
};
