import { atom } from 'jotai';
import type { SponsorApplication } from 'generated/graphql';
import { atomWithReset } from 'jotai/utils';
import { FormTypeE } from 'enums/forms';

export interface SponsorApplicationI extends SponsorApplication {
  formType: FormTypeE.SponsorApplication;
}

export const sponsorPanelView = atom<boolean>(false);
export const sponsorApplicationModalView = atom<boolean>(false);

export const sponsorApplicationFormAtom = atomWithReset<SponsorApplicationI | null>(null);

export const sponsorInformationFormAtom = atom<SponsorApplicationI | null>(null);

type ErrorStateT = {
  isTouched: undefined | boolean;
  errMessage: undefined | string;
};

export const sponsorErrorForm: Record<string, Record<string, ErrorStateT>> = {
  sponsorInformation: {
    corporateName: {
      isTouched: undefined,
      errMessage: undefined,
    },
    corporateType: {
      isTouched: undefined,
      errMessage: undefined,
    },
    description: {
      isTouched: undefined,
      errMessage: undefined,
    },
    streetAddress: {
      isTouched: undefined,
      errMessage: undefined,
    },
    apartment: {
      isTouched: undefined,
      errMessage: undefined,
    },
    city: {
      isTouched: undefined,
      errMessage: undefined,
    },
    country: {
      isTouched: undefined,
      errMessage: undefined,
    },
    state: {
      isTouched: undefined,
      errMessage: undefined,
    },
    websiteUrl: {
      isTouched: undefined,
      errMessage: undefined,
    },
    // agreementUrl: {
    //   isTouched: undefined,
    //   errMessage: undefined,
    // },
    ein: {
      isTouched: undefined,
      errMessage: undefined,
    },
    phoneNumber: {
      isTouched: undefined,
      errMessage: undefined,
    },
    zip: {
      isTouched: undefined,
      errMessage: undefined,
    },
    logoUrlForDarkBackground: {
      isTouched: undefined,
      errMessage: undefined,
    },
    logoUrlForLightBackground: {
      isTouched: undefined,
      errMessage: undefined,
    },
  },
  sponsorOwnerInformation: {
    firstName: {
      isTouched: undefined,
      errMessage: undefined,
    },
    lastName: {
      isTouched: undefined,
      errMessage: undefined,
    },
    streetAddress: {
      isTouched: undefined,
      errMessage: undefined,
    },
    description: {
      isTouched: undefined,
      errMessage: undefined,
    },
    apartment: {
      isTouched: undefined,
      errMessage: undefined,
    },
    city: {
      isTouched: undefined,
      errMessage: undefined,
    },
    state: {
      isTouched: undefined,
      errMessage: undefined,
    },
    phoneNumber: {
      isTouched: undefined,
      errMessage: undefined,
    },
    zip: {
      isTouched: undefined,
      errMessage: undefined,
    },
    emailAddress: {
      isTouched: undefined,
      errMessage: undefined,
    },
  },
};

export const sponsorFormValidationAtom = atomWithReset(sponsorErrorForm);
