import type { FC } from 'react';
import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { formatToUSD } from 'utils/currency-formatter';
import {
  formatNumber,
  NormalizationDecimalsE,
  NormalizationLogicE,
  normalizeDecimals,
} from 'utils/number-formatter';

import { AuctionRowDataI } from '../active-mints-table/types';

import styles from './grid.module.scss';

interface SummaryGridPropsI {
  auctionData: AuctionRowDataI;
}

const auctionSummaryTitles = [
  { title: 'Closing Bid', key: 'closingBid' },
  { title: 'Total Bids', key: 'totalBids' },
  { title: 'Avg. Bid Size', key: 'avgBidSize' },
  { title: 'Cummulative Bids', key: 'cummulativeBids' },
];

export const SummaryGrid: FC<SummaryGridPropsI> = ({ auctionData }) => {
  const gridDataModel = useMemo(() => {
    return Object.values({
      closingBid: formatToUSD(
        normalizeDecimals(auctionData.endPrice, NormalizationDecimalsE.Six)[
          NormalizationLogicE.NonDecimal
        ],
        { minimumFractionDigits: 2 }
      ),
      totalBids: formatNumber(auctionData.numBidders),
      avgBidSize: formatToUSD(
        normalizeDecimals(auctionData.averageBid, NormalizationDecimalsE.Six)[
          NormalizationLogicE.NonDecimal
        ],
        { minimumFractionDigits: 2 }
      ),
      cummulativeBids: formatToUSD(12000),
    });
  }, [auctionData]);

  return (
    <div className={styles.root}>
      {auctionSummaryTitles.map(({ title, key }, i) => (
        <Box key={key}>
          <Typography variant="body2">{title}</Typography>
          <Typography variant="body1">{gridDataModel[i]}</Typography>
        </Box>
      ))}
    </div>
  );
};
