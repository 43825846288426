import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format';
import { useTranslation } from 'react-i18next';

import { WalletE } from 'blockchain-api/ewallets-api';
import { AthleteCardPriceLineApollo } from 'components/athlete/athlete-card/athlete-card-price-line/athlete-card-price-line.apollo';
import { Button } from 'components/button';
import { HCXTooltip } from 'components/tooltip';
import { Athlete, AthleteContract, useAllAthletesQuery } from 'generated/graphql';
import { AthleteTradingInfoI, AthleteI } from 'types/athlete';
import { OrderStatusE, OrderTypeE } from 'enums';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';

import {
  HCXCardConfirmationModal,
  HCXCardConfirmationModalBuyContent,
  ModalStateI,
  useConfirmationModal,
} from 'features/hcx-card/hcx-card-confirmation-modal';

import {
  Box,
  Input,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  TextField,
  Typography,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker, { MobileDateTimePickerProps } from '@mui/lab/MobileDateTimePicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import styles from './buy_sell-tab-template.module.scss';

interface AthleteContractI {
  id: Athlete['id'];
  contractId: AthleteContract['id'];
}

interface StateI {
  contractId: AthleteTradingInfoI['contractId'] | '';
  orderType: OrderTypeE | '';
  amount?: number | '';
  price?: number | '';
  post: boolean;
  ioc: boolean;
  orderGoodUntil: { type: OrderStatusE.Canceled | 'Date' | ''; date: Date };
}

interface SubmitDataI {
  contractId: AthleteTradingInfoI['contractId'];
  orderType: OrderTypeE;
  amount: number;
  price: number;
  post: boolean;
  ioc: boolean;
  orderGoodUntil: OrderStatusE.Canceled | string;
  date: string;
}

const getDefaultState = (athlete?: AthleteI | null): StateI => ({
  contractId: athlete?.tradingInfo.contractId || '',
  orderType: '',
  amount: athlete?.tradingInfo.lastPrice || 0,
  price: athlete?.tradingInfo.price || 0,
  post: false,
  ioc: false,
  orderGoodUntil: { type: '', date: new Date() },
});

export interface BuySellTemplatePropsI {
  athlete: AthleteI;
  onSubmit: (data: SubmitDataI) => void;
  handleResetWallet: () => void;
  handleWalletSelectChange: (wallet: WalletE) => void;
  submitButtonTitle: string;
  modalState: ModalStateI;
}

export const BuySellTemplate: FC<BuySellTemplatePropsI> = ({
  athlete,
  onSubmit,
  handleResetWallet,
  handleWalletSelectChange,
  submitButtonTitle,
  modalState,
}) => {
  const { data } = useAllAthletesQuery();

  const [formData, setFormData] = useState<StateI>(getDefaultState(athlete));

  const contractIds: AthleteContractI[] = useMemo(
    () =>
      data && data.athletes
        ? data.athletes.data
            .filter((entry): entry is Athlete => !!entry && !!entry.tradingPair)
            .map(({ id, tradingPair }) => ({
              id,
              contractId: tradingPair?.symbol ?? '',
            }))
        : [],
    [data]
  );

  const handleSelectChange: SelectProps['onChange'] = useCallback((e) => {
    const {
      target: { name, value },
    } = e;

    setFormData((prev) => {
      if (name === 'orderGoodUntil') {
        return {
          ...prev,
          orderGoodUntil: {
            ...prev.orderGoodUntil,
            type: value,
          },
        };
      }
      return {
        ...prev,
        [name]: value,
      };
    });
  }, []);

  const handleInputNumberChange = useCallback(
    ({ floatValue }: NumberFormatValues, { event, source }: SourceInfo) => {
      if (!event || source === 'prop') return;
      const { name } = event.target;
      setFormData((prev) =>
        prev[name as keyof typeof prev] !== floatValue
          ? {
              ...prev,
              [name]: floatValue,
            }
          : prev
      );
    },
    []
  );

  const handleSwitchChange: SwitchProps['onChange'] = useCallback((e, value) => {
    const {
      target: { name },
    } = e;
    setFormData((prev) => {
      let postValue = prev.post;
      let iocValue = prev.ioc;

      if (name === 'post') {
        postValue = value;
        if (postValue) iocValue = false;
      } else if (name === 'ioc') {
        iocValue = value;
        if (iocValue) postValue = false;
      }

      return {
        ...prev,
        post: postValue,
        ioc: iocValue,
      };
    });
  }, []);

  const handleUntilDateChange: MobileDateTimePickerProps['onChange'] = useCallback((date) => {
    setFormData((prev) => ({
      ...prev,
      orderGoodUntil: {
        ...prev.orderGoodUntil,
        date: date as Date,
      },
    }));
  }, []);

  const submitDisabled =
    !formData.contractId ||
    !formData.orderType ||
    (formData.orderType === OrderTypeE.Limit && !formData.price) ||
    !formData.amount ||
    !formData.orderGoodUntil.type ||
    (formData.orderGoodUntil.type === 'Date' && !formData.orderGoodUntil.date) ||
    modalState.loading;

  const {
    data: confirmationModalData,
    openModal,
    closeModal: handleCloseConfirmation,
    confirmModal: handleSubmit,
  } = useConfirmationModal<SubmitDataI>({
    confirmDisabled: submitDisabled,
    onConfirm: onSubmit,
    onClose: handleResetWallet,
  });

  const handleOpenConfirmation = useCallback(() => {
    const {
      orderGoodUntil: { type, date },
      ...rest
    } = formData;
    const result = {
      ...rest,
      orderGoodUntil: type === 'Date' ? date.toUTCString() : type,
      date: new Date().toUTCString(),
      contractId: athlete.tradingInfo.contractId,
    } as SubmitDataI;
    openModal(result);
  }, [openModal, formData, athlete.tradingInfo.contractId]);

  const { t } = useTranslation('hcx-card');

  return (
    <>
      <FormControl variant="standard" fullWidth className={styles.contractSelect}>
        <InputLabel id="contractId-label" shrink>
          {t('contract-purchase-options')}:
        </InputLabel>
        <Select
          labelId="contractId-label"
          id="contractId"
          name="contractId"
          value={contractIds.length > 0 ? formData.contractId : ''}
          onChange={handleSelectChange}
        >
          {contractIds.map(({ id, contractId }) => (
            <MenuItem key={id} value={contractId} data-athlete-id={id}>
              {contractId}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!!formData.contractId && (
        <>
          <Typography className={styles.contractId}>
            {athlete.tradingInfo.contractId}
            <HCXTooltip title={t('some-info')} placement="top">
              <InfoOutlinedIcon className={styles.infoIcon} />
            </HCXTooltip>
          </Typography>
          <AthleteCardPriceLineApollo athlete={athlete} className={styles.athletePriceLine} />
        </>
      )}
      <Box className={styles.formWrapper}>
        <Box className={styles.formRow}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="order-type-label" shrink>
              {t('order-type')}
            </InputLabel>
            <Select
              labelId="order-type-label"
              id="order-type"
              size="small"
              value={formData.orderType}
              name="orderType"
              onChange={handleSelectChange}
            >
              <MenuItem value={OrderTypeE.Limit}>
                {OrderTypeE.Limit} {t('order')}
              </MenuItem>
              <MenuItem value={OrderTypeE.Market}>{OrderTypeE.Market}</MenuItem>
            </Select>
          </FormControl>
          {formData.orderType === OrderTypeE.Limit ? (
            <FormControl variant="standard" fullWidth>
              <InputLabel id="price-label" shrink>
                {t('price')}
              </InputLabel>
              <NumberFormat
                className={styles.amount}
                id="price-label"
                size="small"
                value={formData.price}
                renderSuffix={() => '$'}
                name="price"
                decimalScale={2}
                customInput={Input}
                type="text"
                thousandSeparator=","
                onValueChange={handleInputNumberChange}
                isNumericString={typeof formData.price === 'string'}
                allowNegative={false}
              />
            </FormControl>
          ) : (
            <div />
          )}
        </Box>
        <Box className={styles.formRow}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="amount-label" shrink>
              {t('amount-qty')}
            </InputLabel>
            <NumberFormat
              className={styles.amount}
              id="amount-label"
              size="small"
              value={formData.amount}
              name="amount"
              decimalScale={2}
              customInput={Input}
              type="text"
              thousandSeparator=","
              onValueChange={handleInputNumberChange}
              isNumericString={typeof formData.amount === 'string'}
              allowNegative={false}
            />
          </FormControl>
          <div />
        </Box>
        <Box className={styles.formRow}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="order-good-until-label" shrink>
              {t('order-good-until')}
            </InputLabel>
            <Select
              labelId="order-good-until-label"
              id="order-good-until"
              size="small"
              value={formData.orderGoodUntil.type}
              name="orderGoodUntil"
              onChange={handleSelectChange}
            >
              <MenuItem value={OrderStatusE.Canceled}>{OrderStatusE.Canceled}</MenuItem>
              <MenuItem value="Date">{t('date')}</MenuItem>
            </Select>
          </FormControl>
          {formData.orderGoodUntil.type === 'Date' ? (
            <FormControl variant="standard" fullWidth>
              <InputLabel id="date-label" shrink>
                {t('date')}
              </InputLabel>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDateTimePicker
                  value={formData.orderGoodUntil.date}
                  onChange={handleUntilDateChange}
                  renderInput={(params) => {
                    const value = `${dateFormatter(
                      formData.orderGoodUntil.date,
                      DateFormatE.M_D_YYYY_h_mm_ss_UTC
                    )
                      .replaceAll(' ', '')
                      .replace(',', ' @ ')
                      .toLowerCase()} GMT`;

                    return (
                      <TextField
                        {...params}
                        value={value}
                        inputProps={{
                          ...params.inputProps,
                          value,
                        }}
                        id="date-label"
                        size="small"
                        variant="standard"
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          ) : (
            <div />
          )}
        </Box>
        <Box className={styles.formRow}>
          <Box className={classNames(styles.switchersBlock)}>
            <FormControlLabel
              control={<Switch onChange={handleSwitchChange} name="post" checked={formData.post} />}
              label={t('post')}
              labelPlacement="top"
            />
            <FormControlLabel
              control={<Switch onChange={handleSwitchChange} name="ioc" checked={formData.ioc} />}
              label={t('ioc')}
              labelPlacement="top"
            />
          </Box>
          <div />
        </Box>
      </Box>
      <Box className={styles.submitBlock}>
        <Button
          variant="contained"
          size="small"
          disabled={submitDisabled}
          onClick={handleOpenConfirmation}
        >
          {submitButtonTitle}
        </Button>
      </Box>
      <HCXCardConfirmationModal
        open={!!confirmationModalData}
        onClose={handleCloseConfirmation}
        onCancel={handleCloseConfirmation}
        onConfirm={handleSubmit}
        title={t('order-confirmation')}
        modalState={modalState}
      >
        <HCXCardConfirmationModalBuyContent
          type={submitButtonTitle}
          data={confirmationModalData}
          handleWalletSelectChange={handleWalletSelectChange}
          modalState={modalState}
        />
      </HCXCardConfirmationModal>
    </>
  );
};
