import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { SponsorLevel, SponsorUserList, SponsorUserRole } from 'generated/graphql';

export interface SponsorUserInvitationI {
  message: string;
  role: SponsorUserRole;
}

export interface InitialValidationInvitationValueI {
  emailAddress: {
    isTouched: boolean;
    errMessage: string | undefined;
  };
}

export const invitePanel = atom<boolean>(false);
export const sponsorManagementView = atom<boolean>(false);
export const sponsorLevel = atom<SponsorLevel | null>(null);

export const sponsorInvitation = atom<SponsorUserInvitationI>({
  message: '',
  role: SponsorUserRole.Member,
});

export const storeSponsorUserList = atom<SponsorUserList>({ data: [] });

export const invitationsBlock = atom(false);
export const sortMembersPermissions = atom({ field_name: 'name', direction: true });

export const sponsorInvitationEmail = atomWithReset<string>('');
export const sponsorInvitationEmailList = atom<Array<string> | null>(null);

const initialValidationInvitationValue = {
  emailAddress: {
    isTouched: false,
    errMessage: undefined,
  },
};

export const sponsorInvitationValidation = atomWithReset<InitialValidationInvitationValueI>(
  initialValidationInvitationValue
);

export const queryPagingParameters = atom({ limit: 7, offset: 0 });
