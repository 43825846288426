import { useAtom } from 'jotai';
import type { Dispatch, SetStateAction, FC, ReactNode } from 'react';
import { useState, useEffect, useMemo, createContext, useContext } from 'react';
import { Trans } from 'react-i18next';

import { useAthleteOrders } from 'components/athlete/athlete-orders/athlete-orders-hooks';
import { AthleteBidStatusE, HCXCardViewE } from 'enums';
import { openAthleteCardViewAtom, selectedAthleteIAtom } from 'store/athletes.store';

import { tabLabels } from './hcx-card.helpers';

export type GetDefaultTabByViewT = (view: HCXCardViewE) => number;

export const useHCXCardCurrentTab = (
  getDefaultTabByView: GetDefaultTabByViewT
): [number, Dispatch<SetStateAction<number>>] => {
  const [defaultView] = useAtom(openAthleteCardViewAtom);

  const [tabIdx, setTabIdx] = useState(getDefaultTabByView(defaultView));

  useEffect(() => {
    setTabIdx(getDefaultTabByView(defaultView));
  }, [defaultView, getDefaultTabByView]);

  return [tabIdx, setTabIdx];
};

export const useHCXCardTabLabelsByBidStatus = (bidStatus: AthleteBidStatusE) =>
  useMemo(
    () =>
      tabLabels.map((label) => (
        <Trans
          key={label}
          i18nKey={label !== AthleteBidStatusE.Offering ? label : bidStatus.toLowerCase()}
          ns="hcx-card"
        />
      )),
    [bidStatus]
  );

export const useTabLabelsWithOrdersQty = (
  tabs: { label: ReactNode; component: FC | null }[],
  ordersTabIdx: number
): ReactNode[] => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  const { total } = useAthleteOrders(athlete!.id); // TODO: VOV: Revisit useAthleteCard hook to return proper value
  const ordersQty = total;

  return useMemo(
    () =>
      tabs.map(({ label }, idx) =>
        !ordersQty || idx !== ordersTabIdx ? (
          label
        ) : (
          <>
            {label} ({ordersQty})
          </>
        )
      ),
    [tabs, ordersTabIdx, ordersQty]
  );
};

export const RotatedContext = createContext<boolean>(false);

export const useInfoRotatedContext = () => useContext(RotatedContext);
