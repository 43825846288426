import { OrderTypeT } from '../../../order-book.types';

const getColorByOrderType = (orderType: OrderTypeT, suffix?: string) =>
  `var(--hcx-alert-${orderType === 'bid' ? 'up' : 'down'}${suffix})`;

const getRowFill = (fill: number, orderType: OrderTypeT) => {
  const color = getColorByOrderType(orderType, '-alpha-15');
  return `linear-gradient(to ${
    orderType === 'bid' ? 'left' : 'right'
  }, ${color}, ${color} ${fill}%, rgba(0, 0, 0, 0) ${fill}%)`;
};

export const getRowStyles = (fill: number, orderType: OrderTypeT) => ({
  backgroundImage: getRowFill(fill, orderType),
});
