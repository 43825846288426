import { SectionI, FieldTypeE } from './types';

export const SECTIONS: SectionI[] = [
  {
    title: 'Classification',
    fields: [
      [
        {
          label: 'Asset Class',
          field: 'assetClass',
          type: FieldTypeE.Select,
          options: [{ title: 'All', value: 'all' }],
        },
      ],
      [
        {
          label: 'Asset Sub-class',
          field: 'assetSubClass',
          type: FieldTypeE.Select,
          options: [{ title: 'All', value: 'all' }],
        },
      ],
      [
        {
          label: 'Position',
          field: 'position',
          type: FieldTypeE.Select,
          options: [{ title: 'All', value: 'all' }],
        },
      ],
    ],
  },
  {
    title: 'Demographics',
    fields: [
      [
        {
          label: 'Age',
          field: 'age',
          type: FieldTypeE.Select,
          options: [{ title: '0-100', value: '0-100' }],
        },
        {
          label: 'Gender',
          field: 'gender',
          type: FieldTypeE.Select,
          options: [
            { title: 'All', value: 'all' },
            { title: 'Male', value: 'male' },
            { title: 'Female', value: 'female' },
          ],
        },
      ],
      [
        {
          label: 'Ethnicity',
          field: 'ethnicity',
          type: FieldTypeE.Select,
          options: [{ title: 'African American', value: '02' }],
        },
      ],
    ],
  },
  {
    title: 'Trade',
    fields: [
      [
        {
          label: '52 Week low',
          field: 'weekLow',
          type: FieldTypeE.Select,
          options: [{ title: '0-100', value: '0-100' }],
        },
        {
          label: '52 Week High',
          field: 'weekHigh',
          type: FieldTypeE.Select,
          options: [{ title: '0-100', value: '0-100' }],
        },
      ],
      [
        {
          label: '24hr Volume',
          field: 'dayVolume',
          type: FieldTypeE.Text,
        },
      ],
    ],
  },
];
