import type { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import styles from './hcx-invitations-block.module.scss';
import { useAtom } from 'jotai';
import { invitationsBlock } from 'store/sponsor-management.store';

export const InvitationsBlock: FC = () => {
  const { t } = useTranslation('sponsor-management');
  const [viewBlock, setViewBlock] = useAtom(invitationsBlock);
  const counterInvitations = 3;

  const closeBlock = () => {
    setViewBlock(false);
  };

  if (!viewBlock) {
    return null;
  }

  return (
    <Box className={styles.root}>
      <Box gap={0.5} display="flex">
        <Typography variant="body1">{t('you-have')}</Typography>
        <Typography variant="body1">{counterInvitations}</Typography>
        <Typography variant="body1">{t('invitations-waiting-for-you')}</Typography>
      </Box>
      <Box gap={6} display="flex">
        <Typography variant="body1" className={styles.colorBlue}>
          {t('accept')}
        </Typography>
        <Typography variant="body1" className={styles.colorBlue}>
          {t('decline')}
        </Typography>
      </Box>
      <IconButton onClick={closeBlock} className={styles.buttonClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
