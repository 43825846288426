import { FC, ReactNode } from 'react';
import { HCXCardViewE } from 'enums';
import { HCXCardOfferingTerms } from './hcx-card-offering-terms';
import { HCXCardOfferingBid } from './hcx-card-offering-bid';
import { HCXCardOfferingOrders } from './hcx-card-offering-orders';
import { GetDefaultTabByViewT } from '../hcx-card.hooks';
import { Trans } from 'react-i18next';

export const TABS: { label: ReactNode; component: FC | null }[] = [
  { label: <Trans i18nKey="terms" ns="hcx-card" />, component: HCXCardOfferingTerms },
  { label: <Trans i18nKey="bid" ns="hcx-card" />, component: HCXCardOfferingBid },
  { label: <Trans i18nKey="orders" ns="hcx-card" />, component: HCXCardOfferingOrders },
];

export const getHCXCardOfferingDefaultTabByView: GetDefaultTabByViewT = (view) => {
  switch (view) {
    case HCXCardViewE.Terms:
      return 0;
    case HCXCardViewE.Bid:
      return 1;
    case HCXCardViewE.BidOrders:
      return 2;
    default:
      return 0;
  }
};
