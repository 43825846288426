import { BigNumber } from '@0x/utils';
import { ContractWrappers, ExchangeContract } from '@0x/contract-wrappers';
import { MetamaskSubprovider } from '@0x/subproviders';

import { config } from 'config';
import { HARDCODED_TRADING_PAIR_ADDRESS } from '../constants';
import { getMetamaskProvider } from '../ewallets-api';
import { ResponseLimitOrderI } from './types';

export interface CancelLimitOrderPropsI {
  order: ResponseLimitOrderI;
}

// TODO: VOV: Might be used, but was unused.
// export type OrderToCancelT = Parameters<ExchangeContract['cancelOrder']>[0];

export async function cancelLimitOrder({ order }: CancelLimitOrderPropsI) {
  const provider = getMetamaskProvider();
  const contract = new ExchangeContract(HARDCODED_TRADING_PAIR_ADDRESS, provider);
  // TODO: VOV: Variable was not used. But new Wrappers might be created.
  // const contractWrappers =
  new ContractWrappers(new MetamaskSubprovider(provider), {
    chainId: config.chainId,
  });

  const preparedOrder = {
    makerAddress: order.maker,
    takerAddress: order.taker,
    feeRecipientAddress: order.feeRecipient,
    senderAddress: order.sender,
    makerAssetAmount: new BigNumber(order.makerAmount),
    takerAssetAmount: new BigNumber(order.takerAmount),
    makerFee: new BigNumber(0),
    takerFee: new BigNumber(order.takerTokenFeeAmount),
    expirationTimeSeconds: new BigNumber(order.expiry),
    salt: new BigNumber(order.salt),
  } as never;

  return contract.cancelOrder(preparedOrder).sendTransactionAsync({ from: order.maker });
}
