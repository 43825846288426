import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import StarIcon from '@mui/icons-material/Star';

import { WidgetNoItems } from '../widget-noItems';
import { WidgetWrapper } from '../widget-wrapper';
import { FavoritesWidgetContext } from './context';
import { FavoritesList } from './favorites-list';
import { HcxCircleSmallIcon } from 'components/icons/hcx-circle-small-icon';
import { Typography } from '@mui/material';

export const Favorites: FC<{ onCloseWidget: () => void }> = ({ onCloseWidget }) => {
  const { t } = useTranslation('trade-desk');
  return (
    <WidgetWrapper
      title={t('favorites')}
      titleIcon={<StarIcon />}
      onCloseWidget={onCloseWidget}
      context={FavoritesWidgetContext}
    >
      <FavoritesList
        noItems={
          <WidgetNoItems
            text={<Typography variant="body2">{t('no-favorites-found')}</Typography>}
            icon={<HcxCircleSmallIcon sx={{ width: '60px', height: '60px' }} />}
          />
        }
      />
    </WidgetWrapper>
  );
};
