import { FC, ReactNode } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { CustomSwitcher } from 'components/custom-switcher';
import { TrendingUp } from 'components/design';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'routes/enums';

import styles from './hcx-card-header.module.scss';

interface HCXCardHeaderPropsI {
  tabLabels: (string | ReactNode)[];
  onTabChange: (tabIdx: number) => void;
  currentTabIdx: number;
  onClose?: () => void;
  onRotate: () => void;
}

const HCXCardHeader: FC<HCXCardHeaderPropsI> = ({
  tabLabels,
  onTabChange,
  currentTabIdx,
  onClose,
  onRotate,
}) => {
  const navigate = useNavigate();

  const navigateToTradeDesc = () => {
    navigate(ROUTE.TradeDesc);
  };

  return (
    <div className={styles.root}>
      <CustomSwitcher
        labels={tabLabels}
        value={currentTabIdx}
        onChange={onTabChange}
        className={styles.switcher}
      />
      <IconButton aria-label="rotate" size="small" onClick={onRotate}>
        <ThreeSixtyIcon />
      </IconButton>
      <IconButton onClick={navigateToTradeDesc}>
        <TrendingUp />
      </IconButton>
      {!!onClose && (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};

export default HCXCardHeader;
