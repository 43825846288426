import type { FC } from 'react';

interface PropsI {
  className?: string;
  color?: string;
}

export const OrdersIcon: FC<PropsI> = ({ className, color = 'currentColor' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.2505 1.50024C11.763 1.50024 12.2154 1.75727 12.486 2.14945L12.5498 2.25024H13.5005C14.2913 2.25024 14.9391 2.86215 14.9964 3.6383L15.0005 3.75024V12.7502C15.0005 14.7668 13.4087 16.4116 11.4132 16.4968L11.2505 16.5002H4.50049C3.70972 16.5002 3.06186 15.8884 3.0046 15.1122L3.00049 15.0002V3.75024C3.00049 2.95947 3.6124 2.31162 4.38854 2.25436L4.50049 2.25024H5.45116C5.69056 1.83638 6.12203 1.54752 6.62368 1.50553L6.75049 1.50024H11.2505ZM5.25049 3.75024H4.50049V15.0002H11.2505C12.4932 15.0002 13.5005 13.9929 13.5005 12.7502V3.75024H12.7505C12.7505 4.57867 12.0789 5.25024 11.2505 5.25024H6.75049C5.92206 5.25024 5.25049 4.57867 5.25049 3.75024ZM9.00049 10.5002C9.41471 10.5002 9.75049 10.836 9.75049 11.2502C9.75049 11.6349 9.46097 11.9519 9.08796 11.9952L9.00049 12.0002H6.75049C6.33628 12.0002 6.00049 11.6645 6.00049 11.2502C6.00049 10.8656 6.29002 10.5486 6.66302 10.5053L6.75049 10.5002H9.00049ZM11.2505 7.50024C11.6647 7.50024 12.0005 7.83602 12.0005 8.25024C12.0005 8.66447 11.6647 9.00024 11.2505 9.00024H6.75049C6.33628 9.00024 6.00049 8.66447 6.00049 8.25024C6.00049 7.83602 6.33628 7.50024 6.75049 7.50024H11.2505ZM11.2505 3.00024H6.75049V3.75024H11.2505V3.00024Z"
      fill={color}
    />
  </svg>
);
