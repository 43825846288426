import { FC } from 'react';
import { Typography } from '@mui/material';
import { Panel } from 'components/panel';
import AuctionDataGrid from './auction-data-grid';
import IhoOrChoChart from './iho-or-cho-chart';
import { Countdown } from 'pages/human-capital-mint/countdown';
import styles from './iho-or-cho.module.scss';

const IhoOrCho: FC = () => {
  return (
    <Panel className={styles.root}>
      <span className={styles.title}>IHO or CHO XXX</span>
      <div className={styles.header}>
        <div className={styles.totalBids}>
          <Typography variant="body2" className={styles.prefix}>
            Total Bids:
          </Typography>
          <div className={styles.bid}>
            <div className={styles.bidPrefix}></div>
            <Typography variant="subtitle2">35</Typography>
          </div>
        </div>
        <div className={styles.countdown}>
          <Typography variant="body2" className={styles.prefix}>
            Time Left:
          </Typography>
          <Countdown date={new Date('2022-08-21')} />
        </div>
        <div className={styles.auctionGridWrapper}>
          <AuctionDataGrid totalBids={35} bidSizeAvg={25000} weightedAvgBidPrice={10.5} />
        </div>
      </div>
      <IhoOrChoChart />
    </Panel>
  );
};

export default IhoOrCho;
