import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CashMultipleIcon } from 'components/icons/cash-multiple-icon';

import { WidgetNoItems } from '../widget-noItems';
import { WidgetWrapper } from '../widget-wrapper';
import { OrdersWidgetContext } from './orders-widget-context';
import { OrdersList } from './orders-list';
import { OrdersWidgetContextProvider } from './orders-list-context';
import OrdersFilter from './orders-filter/orders-filter';
import { HcxCircleSmallIcon } from 'components/icons/hcx-circle-small-icon';
import { Typography } from '@mui/material';

export const Orders: FC<{ onCloseWidget: () => void }> = ({ onCloseWidget }) => {
  const { t } = useTranslation('trade-desk');
  return (
    <OrdersWidgetContextProvider>
      <WidgetWrapper
        title={t('my-orders')}
        titleIcon={<CashMultipleIcon />}
        onCloseWidget={onCloseWidget}
        context={OrdersWidgetContext}
        additionalTitleActions={<OrdersFilter />}
        hideSizer
      >
        <OrdersList
          noItems={
            <WidgetNoItems
              text={<Typography variant="body2">{t('no-orders-found')}</Typography>}
              icon={<HcxCircleSmallIcon sx={{ width: '60px', height: '60px' }} />}
            />
          }
        />
      </WidgetWrapper>
    </OrdersWidgetContextProvider>
  );
};
