import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback, useEffect } from 'react';

import {
  Athlete,
  AthleteSearchInput,
  AthletesQuery,
  Pagination,
  useAthletesQuery,
} from 'generated/graphql';
import { AthleteI } from 'types';
import { convertAthleteToAthleteI } from 'utils/convertAthleteToAthleteI';

import {
  athleteFinancialCardClosedVar,
  athleteFinancialCardRotatedVar,
  isAccountProfileOpened,
} from './cache';

const DEFAULT_PAGINATION = { limit: 20, offset: 0, total: 0 };

export const tradingAthletesVar = makeVar<AthleteI[]>([]);
export const tradingAthletesPaginationVar = makeVar<Pagination>(DEFAULT_PAGINATION);

export const athletesVar = makeVar<AthleteI[]>([]);

function convertAthleteData(data: AthletesQuery | undefined): AthleteI[] {
  if (data && data.athletes) {
    return data.athletes.data
      .filter((entry): entry is Athlete => !!entry)
      .map((entry) => {
        return convertAthleteToAthleteI(entry);
      });
  }
  return [];
}

export const useTradingAthletes = (
  input: AthleteSearchInput | undefined = {
    limit: 20,
    offset: 0,
  }
): AthleteI[] => {
  const { data } = useAthletesQuery({
    variables: {
      input: {
        ...input,
        hasTradingPair: true,
      },
    },
  });

  const tradingAthletes = convertAthleteData(data);

  useEffect(() => {
    tradingAthletesVar(tradingAthletes);
    tradingAthletesPaginationVar(data?.athletes?.pagination ?? DEFAULT_PAGINATION);
  }, [tradingAthletes, data?.athletes?.pagination]);

  return tradingAthletes;
};

export const useTradingAthletesVar = () => {
  return { athletes: useReactiveVar(tradingAthletesVar) };
};

export const useAthletes = (
  input: AthleteSearchInput | undefined = {
    limit: 50,
    offset: 0,
  }
): AthleteI[] => {
  const { data } = useAthletesQuery({ variables: { input } });

  const athletes = convertAthleteData(data);
  athletesVar(athletes);

  return athletes;
};

export const useTradingAthletesPagination = () => {
  const pagination = useReactiveVar(tradingAthletesPaginationVar);
  return { pagination };
};

export const useIsAccountProfileOpened = () => useReactiveVar(isAccountProfileOpened);

export const useOpenAccountProfile = () =>
  useCallback(() => {
    isAccountProfileOpened(true);
  }, []);

export const useCloseAccountProfile = () =>
  useCallback(() => {
    isAccountProfileOpened(false);
  }, []);

export const useRotateAthleteFinancialCard = (
  id: AthleteI['id']
): { isRotated: boolean; rotate: () => void } => {
  const data = useReactiveVar(athleteFinancialCardRotatedVar);

  const rotate = useCallback(() => {
    const oldData = athleteFinancialCardRotatedVar();
    athleteFinancialCardRotatedVar({ ...oldData, [id]: !oldData[id] });
  }, [id]);

  return {
    isRotated: !!data[id],
    rotate,
  };
};

export const useRotateAllFinancialCards = () => {
  return useCallback(() => {
    const oldData = athleteFinancialCardRotatedVar();
    const oldKeys = Object.keys(oldData);

    if (oldKeys.length > 0) {
      // Return all rotated cards to initial state
      athleteFinancialCardRotatedVar({});
    } else {
      const athletes = athletesVar();
      const newData: Record<number, boolean> = {};
      athletes.forEach((athlete) => {
        newData[athlete.id] = true;
      });
      // Rotate all available cards
      athleteFinancialCardRotatedVar(newData);
    }
  }, []);
};

export const useCloseAthleteFinancialCard = (
  id: AthleteI['id']
): { isClosed: boolean; close: () => void } => {
  const data = useReactiveVar(athleteFinancialCardClosedVar);

  const close = useCallback(() => {
    const oldData = athleteFinancialCardClosedVar();
    athleteFinancialCardClosedVar({ ...oldData, [id]: true });
  }, [id]);

  return {
    isClosed: !!data[id],
    close,
  };
};
