export enum HCXCardViewE {
  Info,
  Stats,
  Activity,
  Finance,
  Buy,
  Sell,
  BuySellOrders,
  Terms,
  Bid,
  BidOrders,
}
