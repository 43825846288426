import classNames from 'classnames';
import { FC, useRef, useEffect } from 'react';
import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars-2';

import styles from './custom-scrollbars.module.scss';

export type CustomScrollbarsPropsT = ScrollbarProps & {
  resetScrollTrigger?: number | string | null;
};

const renderThumb =
  (
    type: 'vertical' | 'horizontal'
  ):
    | CustomScrollbarsPropsT['renderThumbVertical']
    | CustomScrollbarsPropsT['renderThumbHorizontal'] =>
  // eslint-disable-next-line react/display-name
  (props) =>
    (
      <div
        {...props}
        style={{ ...(props.style || {}), [type === 'vertical' ? 'width' : 'height']: '4px' }}
        className={classNames(styles.thumb, styles[type])}
      />
    );

const renderThumbVertical: CustomScrollbarsPropsT['renderThumbVertical'] = renderThumb('vertical');
const renderThumbHorizontal: CustomScrollbarsPropsT['renderThumbHorizontal'] =
  renderThumb('horizontal');

const renderView: CustomScrollbarsPropsT['renderView'] = (props) => (
  <div {...props} style={{ ...(props.style || {}) }} className={styles.view} />
);

const CustomScrollbars: FC<CustomScrollbarsPropsT> = ({
  children,
  resetScrollTrigger,
  ...props
}) => {
  const scrollBarsRef = useRef<Scrollbars>(null);

  useEffect(() => {
    scrollBarsRef.current?.scrollToTop();
  }, [resetScrollTrigger]);

  return (
    <Scrollbars
      ref={scrollBarsRef}
      autoHide
      autoHideTimeout={500}
      autoHideDuration={200}
      hideTracksWhenNotNeeded
      renderThumbVertical={renderThumbVertical}
      renderThumbHorizontal={renderThumbHorizontal}
      renderView={renderView}
      {...props}
    >
      {children}
    </Scrollbars>
  );
};

CustomScrollbars.displayName = 'CustomScrollbars';

export default CustomScrollbars;
