import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format';

import { Box, Divider, FormControl, FormHelperText, Input, InputLabel } from '@mui/material';

import { Button } from 'components/button';

import {
  DEFAULT_WALLET,
  FinalStateE,
  getDefaultModalState,
  HCXCardConfirmationModal,
  HCXCardConfirmationModalBuyContent,
  ModalStateI,
  useConfirmationModal,
} from '../hcx-card-confirmation-modal';

import { formatToUSD } from 'utils/currency-formatter';
import {
  formatNumber,
  NormalizationDecimalsE,
  NormalizationLogicE,
  normalizeDecimals,
} from '../../../utils/number-formatter';

import { DutchAuctionStatus } from 'generated/graphql';
import { WalletE } from 'blockchain-api/ewallets-api';
import { AthleteBidStatusE, OrderTypeE } from 'enums';
import { AthleteI, AthleteTradingInfoI } from 'types';

import styles from './hcx-card-bid.module.scss';

interface FormStateI {
  orderType: OrderTypeE | '';
  amount?: number | '';
  price: number;
}

export interface SubmitDataI {
  contractId: AthleteTradingInfoI['contractId'];
  orderType: OrderTypeE;
  amount: number;
  price: number;
  date: string;
}

interface AthleteCardAuctionStatsI {
  status: DutchAuctionStatus;
  floorPrice: number;
  recommendedBid: number;
  numBidders: number;
  highBid: number;
  lowBid: number;
}

export interface HCXCardBidPropsI {
  athlete: AthleteI;
  auctionStats: AthleteCardAuctionStatsI;
  onSubmit: (data: SubmitDataI, wallet: WalletE) => Promise<unknown | string>;
}

const getDefaultState = (auctionStats: AthleteCardAuctionStatsI): FormStateI => ({
  orderType: '',
  amount: 0,
  price:
    auctionStats.numBidders === 0
      ? auctionStats.floorPrice
      : normalizeDecimals(auctionStats.recommendedBid, NormalizationDecimalsE.Six)[
          NormalizationLogicE.NonDecimal
        ],
});

const formatPricePoint = (value: number) =>
  formatToUSD(
    value
      ? normalizeDecimals(value, NormalizationDecimalsE.Six)[NormalizationLogicE.NonDecimal]
      : 0,
    {
      minimumFractionDigits: 2,
    }
  );

export const HCXCardBid: FC<HCXCardBidPropsI> = ({ athlete, auctionStats, onSubmit }) => {
  const bidType = athlete.tradingInfo.bidStatus;

  const [formData, setFormData] = useState<FormStateI>(getDefaultState(auctionStats));
  const [selectedWallet, setSelectedWallet] = useState<WalletE>(DEFAULT_WALLET);
  const [modalState, setModalState] = useState<ModalStateI>(getDefaultModalState());

  useEffect(() => {
    setFormData(getDefaultState(auctionStats));
  }, [athlete, auctionStats]);

  const handleInputNumberChange = useCallback(
    ({ floatValue }: NumberFormatValues, { event, source }: SourceInfo) => {
      if (!event || source === 'prop') return;
      const { name } = event.target;
      setFormData((prev) =>
        prev[name as keyof typeof prev] !== floatValue
          ? {
              ...prev,
              [name]: floatValue,
            }
          : prev
      );
    },
    []
  );

  const handleConfirmModal = async (data: SubmitDataI) => {
    setModalState((prev) => ({
      ...prev,
      loading: true,
    }));
    const response = await onSubmit(data, selectedWallet);
    setModalState(() => ({
      loading: false,
      finalState: typeof response === 'string' ? FinalStateE.Error : FinalStateE.Success,
    }));
    setSelectedWallet(DEFAULT_WALLET);
  };

  const handleResetWallet = () => {
    setModalState(getDefaultModalState());
    setSelectedWallet(DEFAULT_WALLET);
  };

  const submitDisabled =
    !formData.price ||
    !formData.amount ||
    auctionStats.floorPrice > formData.price ||
    auctionStats.status !== DutchAuctionStatus.Active;

  const {
    data: confirmationModalData,
    openModal,
    closeModal: handleCloseConfirmation,
    confirmModal: handleSubmit,
  } = useConfirmationModal<SubmitDataI>({
    confirmDisabled: submitDisabled,
    onConfirm: handleConfirmModal,
    onClose: handleResetWallet,
  });

  const handleOpenConfirmation = useCallback(() => {
    if (submitDisabled) {
      return;
    }
    openModal({
      ...formData,
      date: new Date().toUTCString(),
      contractId: athlete.tradingInfo.contractId,
    } as SubmitDataI);
  }, [openModal, formData, athlete.tradingInfo.contractId, submitDisabled]);

  const onWalletSelectChange = useCallback((wallet: WalletE) => {
    setSelectedWallet(wallet);
  }, []);

  const { t } = useTranslation('hcx-card');

  return (
    <>
      <div className={styles.bidInfo}>
        <table className={styles.bidInfoTable}>
          <tbody>
            <tr>
              <th>{t('status')}:</th>
              <td>{auctionStats.status}</td>
            </tr>
            <tr>
              <th>{t('bidders')}:</th>
              <td>{formatNumber(auctionStats.numBidders)}</td>
            </tr>
          </tbody>
        </table>
        <table className={styles.bidInfoTable}>
          <tbody>
            <tr>
              <th>{t('time-remaining')}:</th>
              {/* TODO: MJO: Rework this once graph returns correct status */}
              <td>{'--.--'}</td>
            </tr>
            <tr>
              {bidType === AthleteBidStatusE.Offering ? (
                <>
                  <th>{t('max-bid')}:</th>
                  <td>{formatPricePoint(auctionStats.highBid)}</td>
                </>
              ) : (
                <>
                  <th>{t('min-max-bid')}:</th>
                  <td>
                    {formatPricePoint(auctionStats.lowBid)} /{' '}
                    {formatPricePoint(auctionStats.highBid)}
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <Divider />
      <Box className={styles.formWrapper}>
        <Box className={styles.rowWrapper}>
          <Box className={styles.formRow}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="price-label" shrink>
                {t('price')}
              </InputLabel>
              <NumberFormat
                className={styles.amount}
                id="price-label"
                size="small"
                renderSuffix={() => '$'}
                name="price"
                value={formData.price}
                decimalScale={2}
                customInput={Input}
                type="text"
                thousandSeparator=","
                onValueChange={handleInputNumberChange}
                isNumericString={typeof formData.price === 'string'}
                allowNegative={false}
              />
              <FormHelperText>
                {t('recommended-bid')}: {formatPricePoint(auctionStats.recommendedBid)}
              </FormHelperText>
            </FormControl>
          </Box>
          <Box className={styles.formRow}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="amount-label" shrink>
                {t('amount')}
              </InputLabel>
              <NumberFormat
                className={styles.amount}
                id="amount-label"
                size="small"
                value={formData.amount}
                name="amount"
                decimalScale={2}
                customInput={Input}
                type="text"
                thousandSeparator=","
                onValueChange={handleInputNumberChange}
                isNumericString={typeof formData.amount === 'string'}
                allowNegative={false}
              />
            </FormControl>
            <div />
          </Box>
        </Box>
        <Box className={styles.submitBlock}>
          <Button
            variant="contained"
            size="small"
            disabled={submitDisabled}
            onClick={handleOpenConfirmation}
          >
            {t('bid')}
          </Button>
        </Box>
      </Box>
      <HCXCardConfirmationModal
        open={!!confirmationModalData}
        onClose={handleCloseConfirmation}
        onCancel={handleCloseConfirmation}
        onConfirm={handleSubmit}
        title={t('order-confirmation')}
        modalState={modalState}
      >
        <HCXCardConfirmationModalBuyContent
          type="Bid"
          data={confirmationModalData}
          handleWalletSelectChange={onWalletSelectChange}
          modalState={modalState}
        />
      </HCXCardConfirmationModal>
    </>
  );
};
