import type { FC, ReactNode } from 'react';
import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './financial-model.module.scss';

export const FileRow: FC<{
  icon?: ReactNode;
  url?: string;
  fileName?: string;
  financialProforma: string;
  updatedAt: string;
  createdBy?: string;
  className?: string;
}> = ({ icon, url, fileName, updatedAt, createdBy, className, financialProforma }) => {
  const { t } = useTranslation('talent-application');

  const date = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(Number(updatedAt.toString().slice(0, 13)));

  return (
    <Box className={className}>
      {!!icon && icon}
      <Typography variant="body1" mr="150px">
        {financialProforma}
      </Typography>
      <Link mr="50px" href={url} target="_blank" rel="noreferrer">
        {!!fileName && fileName}
      </Link>
      {!!updatedAt && (
        <Typography className={styles.secondaryText}>
          {`${t('uloaded-by')} ${createdBy} ${t('on')} ${date}`}
        </Typography>
      )}
    </Box>
  );
};
