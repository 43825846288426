import { NetworkStatus } from '@apollo/client';
import { apolloClient } from 'apolloClient';
import {
  Bar,
  ChartingLibraryWidgetOptions,
  IDatafeedChartApi,
  LibrarySymbolInfo,
} from 'charting_library';
import {
  TradingPairCandlesticksDocument,
  TradingPairCandlesticksQuery,
  TradingPairCandlesticksQueryVariables,
} from 'generated/graphql';
import { useRef } from 'react';
import { dataFeedConfigurationData1 } from './constants';
import { parseNewBar } from './helpers';
import { SubscribeOnStreamT, useStreaming } from './streaming';
import {
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
} from 'utils/number-formatter';

const getLeadingZero = (number: number) => (number < 10 ? `0${number}` : number);

const getDateStrForBarsRequest = (dateInSeconds: number): string => {
  const date = new Date(dateInSeconds * 1000);
  return `${date.getUTCFullYear()}-${getLeadingZero(date.getUTCMonth() + 1)}-${getLeadingZero(
    date.getUTCDate()
  )} ${getLeadingZero(date.getUTCHours())}:${getLeadingZero(date.getUTCMinutes())}:00`;
};

const datafeed = (
  lastBarsCache: Map<string, Bar>,
  subscribeOnStream: SubscribeOnStreamT,
  unsubscribeFromStream: IDatafeedChartApi['unsubscribeBars']
): ChartingLibraryWidgetOptions['datafeed'] => ({
  onReady: (callback) => {
    setTimeout(() => callback(dataFeedConfigurationData1));
  },
  searchSymbols: async () => {},
  resolveSymbol: async (symbolName, onSymbolResolvedCallback) => {
    setTimeout(() => {
      const [exchange, pair, tradingPairId] = symbolName.split(':');

      const symbolInfo = {
        ticker: symbolName,
        name: pair,
        description: pair,
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange,
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        has_no_volume: false,
        has_weekly_and_monthly: true,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        supported_resolutions: dataFeedConfigurationData1.supported_resolutions!,
        volume_precision: 2,
        data_status: 'streaming',
        unit_id: tradingPairId,
      } as LibrarySymbolInfo;

      onSymbolResolvedCallback(symbolInfo);
    });
  },
  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams;
    try {
      const response = await apolloClient.query<
        TradingPairCandlesticksQuery,
        TradingPairCandlesticksQueryVariables
      >({
        query: TradingPairCandlesticksDocument,
        variables: {
          tradingPairId: symbolInfo.unit_id as string,
          fromTimestamp: getDateStrForBarsRequest(from),
          toTimestamp: getDateStrForBarsRequest(to),
          resolution: +resolution || 5,
        },
      });

      if (
        response.networkStatus === NetworkStatus.error ||
        !response.data?.tradingPairCandlesticks?.length
      ) {
        // "noData" should be set if there is no data in the requested period.
        onHistoryCallback([], { noData: true });
        return;
      }

      const bars = response.data.tradingPairCandlesticks.reduce<Bar[]>((acc, bar) => {
        const newBar = parseNewBar(bar);
        if (newBar) {
          if (newBar.time >= from * 1000 && newBar.time < to * 1000) {
            newBar.volume = newBar.volume
              ? normalizeDecimals(newBar.volume, NormalizationDecimalsE.Six)[
                  NormalizationLogicE.NonDecimal
                ]
              : 0;
            acc.push(newBar);
          }
        }
        return acc.sort((a, b) => (a.time > b.time ? 1 : -1));
      }, []);

      if (!bars.length) {
        onHistoryCallback([], { noData: true });
        return;
      }
      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
      }

      onHistoryCallback(bars, { noData: false });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      onErrorCallback((error?.message || error) as string);
    }
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lastBarsCache.get(symbolInfo.full_name)!
    );
  },

  unsubscribeBars: (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },
});

const useDatafeed = () => {
  const lastBarsCache = useRef<Map<string, Bar>>(new Map()).current;
  const { subscribeOnStream, unsubscribeFromStream } = useStreaming();
  return useRef(datafeed(lastBarsCache, subscribeOnStream, unsubscribeFromStream)).current;
};

export default useDatafeed;
