import type { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import InputBase from '@mui/material/InputBase/InputBase';
import styles from './input-field.module.scss';
import React from 'react';

const inputComponentProps = {
  input: { className: styles.input },
};

const errorComponentProps = {
  input: { className: styles.error },
};

interface InputFieldPropsI {
  id?: string;
  tName?: string;
  name?: string;
  icon?: ReactNode;
  className: string;
  errorValidation?: string | boolean;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  value?: string | number;
  defaultValue?: string | number;
  multiline?: boolean;
  maxRows?: string;
}

export const InputField: FC<InputFieldPropsI> = (props) => {
  const {
    id,
    tName,
    icon,
    errorValidation,
    className,
    onChange,
    onBlur,
    disabled,
    value,
    defaultValue,
    name,
    multiline,
    maxRows,
    placeholder,
  } = props;

  return (
    <Box className={className}>
      {tName && (
        <Box className={styles.inputLabel}>
          <Typography variant="body2">{tName}</Typography>
          {icon}
        </Box>
      )}
      <InputBase
        componentsProps={errorValidation ? errorComponentProps : inputComponentProps}
        id={id}
        name={name}
        className={className}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
        multiline={multiline}
        maxRows={maxRows}
        placeholder={placeholder}
      />
      {errorValidation && <p className={styles.errorText}>{errorValidation}</p>}
    </Box>
  );
};
