import { Card } from './card';
import { Input } from './input';
import { Button } from '../../components/button';
import { FormEventHandler, useState } from 'react';
import { useAuth } from 'auth/use-auth';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../routes/enums';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { LoggedOutAuthContextI } from 'auth/auth-context';
import { useTranslation } from 'react-i18next';
import { Snackbar } from 'components/snackbar';

const mt4 = { marginTop: 4 };

export const RestorePassword = () => {
  const { restorePassword } = useAuth() as LoggedOutAuthContextI;

  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get('email') as string | null;
    if (email) {
      restorePassword(email).then(() => setShowSnackbar(true));
    }
  };
  const { t } = useTranslation('auth');

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" mt={4} mb={3}>
            <Input placeholder={t('email')} name="email" />
            <Button variant="contained" type="submit" sx={mt4}>
              {t('send-message-to-reset-password')}
            </Button>
            <Box display="flex" justifyContent="center" mt={2}>
              <Link to={ROUTE.SignIn} className={classNames(styles.link, styles.terms)}>
                {t('go-back')}
              </Link>
            </Box>
          </Box>
        </form>
      </Card>
      <Snackbar
        open={showSnackbar}
        message="We've just sent you an email to reset your password."
      />
    </>
  );
};
