import { AthleteI } from 'types/athlete';
import {
  PriceI,
  InfoI,
  AthleteTradingInfoPropsI,
  TradingPairStatsT,
} from './athlete-trading-info.types';

import {
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
  formatNumber,
} from 'utils/number-formatter';

export const GET_ATHLETE_LAST_PRICE_CONFIG = (
  latestStats: TradingPairStatsT<string | number>
): PriceI => {
  return {
    value: latestStats.lastPrice,
    progressAmount: latestStats.change24Amount,
    progressPercentage: latestStats.change24Percent,
  };
};

export enum AthleteTradingInfoConfigTypeE {
  Volume24,
  Term,
  FloatValue,
  MarketCap,
}

export const getEmptyStats = (): TradingPairStatsT<string> => ({
  volume24: '$ --.--',
  lastPrice: '$ --.--',
  change24Percent: '--.--',
  change24Amount: '$ --.--',
  marketCap: '--.--',
});

const configs: Record<
  AthleteTradingInfoConfigTypeE,
  (athlete: AthleteI, latestStats: TradingPairStatsT<string | number>) => InfoI
> = {
  [AthleteTradingInfoConfigTypeE.Volume24]: (_, { volume24 }) => ({
    title: '24h Volume',
    isCurrency: true,
    value:
      typeof volume24 === 'string'
        ? volume24
        : formatNumber(
            normalizeDecimals(volume24, NormalizationDecimalsE.Six)[NormalizationLogicE.NonDecimal]
          ),
  }),
  [AthleteTradingInfoConfigTypeE.Term]: (athlete) => ({
    title: 'Term',
    value: `${new Date(athlete.tradingInfo.contractTerm.start).getFullYear()}/${new Date(
      athlete.tradingInfo.contractTerm.end
    ).getFullYear()}`,
  }),
  [AthleteTradingInfoConfigTypeE.FloatValue]: (_, { marketCap }) => ({
    title: 'Float Value',
    value:
      typeof marketCap === 'string'
        ? marketCap
        : `${formatNumber(
            normalizeDecimals(marketCap, NormalizationDecimalsE.Two)[NormalizationLogicE.NonDecimal]
          )} (100%)`,
  }),
  [AthleteTradingInfoConfigTypeE.MarketCap]: (_, { marketCap }) => ({
    title: 'Market Cap',
    value:
      typeof marketCap === 'string'
        ? marketCap
        : formatNumber(
            normalizeDecimals(marketCap, NormalizationDecimalsE.Two)[NormalizationLogicE.NonDecimal]
          ),
  }),
};

export const getAthleteTradingInfoProps = (
  athlete: AthleteI,
  latestStats: TradingPairStatsT<string | number>,
  configTypes: AthleteTradingInfoConfigTypeE[]
): Omit<AthleteTradingInfoPropsI, 'priceSize'> => ({
  price: GET_ATHLETE_LAST_PRICE_CONFIG(latestStats),
  info: configTypes.map((item) => configs[item](athlete, latestStats)),
});
