import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from 'components/button';
import { useAtom } from 'jotai';
import { SponsorApplicationI, sponsorPanelView } from 'store/sponsor-application.store';
import { sponsorApplicationFormAtom } from 'store/sponsor-application.store';
import {
  SponsorApplicationStatus,
  useInitSponsorApplicationMutation,
  useSponsorApplicationStatusQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { SponsorPending } from '../account-sponsor-pending';
import styles from './account-sponsor-flow.module.scss';
import { SponsorApproved } from '../account-sponsor-approved';
import { FormTypeE } from 'enums/forms';

export const BecomeSponsor: FC = () => {
  const [, setSponsorForm] = useAtom(sponsorApplicationFormAtom);
  const [, setSponsorApplicationOpen] = useAtom(sponsorPanelView);

  const { t } = useTranslation('account-profile');

  const { data: sponsorStatus } = useSponsorApplicationStatusQuery();

  const [initSponsorApplication, { loading }] = useInitSponsorApplicationMutation();

  const isSubmitted =
    sponsorStatus?.sponsorApplication.status === SponsorApplicationStatus.Submitted;

  const isApproved = sponsorStatus?.sponsorApplication.status === SponsorApplicationStatus.Approved;

  const handleSponsorForm = async () => {
    try {
      const res = await initSponsorApplication({ refetchQueries: ['SponsorApplicationStatus'] });
      if (res?.data?.initSponsorApplication) {
        const sponsorApplication: SponsorApplicationI = {
          ...res.data.initSponsorApplication,
          formType: FormTypeE.SponsorApplication,
        };
        setSponsorForm(sponsorApplication);
      }
    } catch (err) {
      console.warn(err);
    }
    setSponsorApplicationOpen(true);
  };

  return (
    <>
      {!isApproved && (
        <>
          {!isSubmitted && (
            <Box className={styles.root}>
              <Typography variant="body2">{t('register-as-sponsor')}:</Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={handleSponsorForm}
                disabled={loading}
              >
                {t('become-sponsor')}
              </Button>
            </Box>
          )}
          {isSubmitted && <SponsorPending />}
        </>
      )}
      {isApproved && <SponsorApproved />}
    </>
  );
};
