import { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import styles from './account-profile-affiliate-link.module.scss';
import { IconButton } from '@mui/material';

const AccountProfileAffiliateLink: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation('account-profile');
  const [copied, setCopied] = useState(false);

  const link = `${window.location.origin}/home/#a=34555321`;

  const onCopyLink = () => {
    if (copied) return;
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <div className={classNames(styles.root, className)}>
      <label className={styles.profileBlockLabel}>{t('affiliate-link')}</label>
      <div className={styles.linkWrapper}>
        <span className={styles.link} title={link}>
          {link}
        </span>
        <IconButton
          size="small"
          className={classNames(styles.copyBtn, copied && styles.copied)}
          onClick={onCopyLink}
        >
          <ContentCopyIcon className={styles.copyIcon} />
          <DoneIcon className={styles.doneIcon} />
        </IconButton>
      </div>
    </div>
  );
};

export default AccountProfileAffiliateLink;
