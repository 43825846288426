import type { ChangeEvent, FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Card } from './card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles.module.scss';
import { Button } from '../../components/button';
import { GoogleLogo } from './google-logo';
import { PasswordAuthDataI } from '../../auth';
import { Input } from './input';
import { useAuth } from '../../auth/use-auth';
import { LoggedOutAuthContextI } from '../../auth/auth-context';
import classNames from 'classnames';
import { insertSeparator } from 'utils/insert-separator';
import { AuthDataShapeI, allValid, validateAuthData } from './utils';
import { ValidationResultT } from 'utils/validation';
import { useTranslation } from 'react-i18next';

interface PropsI {
  onSubmit: (data: PasswordAuthDataI) => void;
  onGoogleAuthClick: () => void;
  middlePart: ReactNode;
  bottomPart: ReactNode;
  submitButtonText: ReactNode | string;
  disableValidation?: boolean;
}

const renderValidationResult = (result: ValidationResultT) =>
  !result.valid && (
    <div className={styles.error}>
      {insertSeparator(
        result.errors.map((x) => <span dangerouslySetInnerHTML={{ __html: x }} key={x} />),
        <br />
      )}
    </div>
  );

export const AuthFormTemplate: FC<PropsI> = ({
  onSubmit,
  onGoogleAuthClick,
  middlePart,
  bottomPart,
  submitButtonText,
  disableValidation,
}) => {
  const auth = useAuth() as LoggedOutAuthContextI;
  const [data, setData] = useState<AuthDataShapeI<string>>({ email: '', password: '' });
  const [validationErrors, setValidationErrors] = useState<AuthDataShapeI<ValidationResultT>>({
    email: { valid: true },
    password: { valid: true },
  });
  const [validationEnabled, setValidationEnabled] = useState(false);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (disableValidation) {
        onSubmit(data);
        return;
      }
      const validationResult = validateAuthData(data);
      if (allValid(validationResult)) {
        onSubmit(data);
        return;
      }
      setValidationEnabled(true);
      setValidationErrors(validationResult);
    },
    [data, disableValidation, onSubmit]
  );

  const handleChange = useCallback(({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setData((d) => ({ ...d, [name]: value }));
  }, []);
  const { t } = useTranslation('auth');

  useEffect(() => {
    if (validationEnabled) {
      setValidationErrors(validateAuthData(data));
    }
  }, [data, validationEnabled]);

  return (
    <>
      <Card>
        <Box mt={5} display="flex" flexDirection="column">
          <form onSubmit={handleSubmit} className={styles.form}>
            <Input
              placeholder={t('email')}
              type="email"
              name="email"
              error={!(validationErrors.email?.valid ?? true)}
              value={data.email}
              onChange={handleChange}
            />
            {renderValidationResult(validationErrors.email)}
            <Input
              placeholder={t('password')}
              autoComplete="current-password"
              className={styles.mt2}
              type="password"
              name="password"
              value={data.password}
              onChange={handleChange}
            />
            {renderValidationResult(validationErrors.password)}
            {middlePart}
            <Button
              variant="contained"
              className={styles.mt2}
              onClick={handleSubmit}
              disabled={auth.isLoading}
            >
              {submitButtonText}
            </Button>
          </form>
          <Typography className={styles.textLine}>{t('or')}</Typography>
          <Button
            variant="contained"
            className={classNames(styles.googleButton, styles.mt2)}
            startIcon={<GoogleLogo className={styles.googleLogo} />}
            type="submit"
            onClick={onGoogleAuthClick}
          >
            {t('sign-in-with-google')}
          </Button>
          {bottomPart}
        </Box>
      </Card>
    </>
  );
};
