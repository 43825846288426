import { ChangeEvent, FC } from 'react';
import { CreditCardI } from './types';
import { useTranslation } from 'react-i18next';
import { FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material';
import { Button } from '../../components/button';
import styles from './add-credit-card.module.scss';
import { useFormState } from '../../pages/onboarding/steps/use-form-state';
import {
  validateEmpty,
  validateCvvNumberFormat,
  validateCcNumberFormat,
  validatePair,
} from 'utils/validation';
import { renderValidationResult } from '../../pages/auth/render-validation-result';

interface PropsI {
  onSubmit: (data: CreditCardI) => void;
}

const validate = (data: CreditCardI) => {
  return {
    number: validatePair<string>(data.number, validateEmpty, validateCcNumberFormat),
    month: validateEmpty(data.month),
    year: validateEmpty(data.year),
    cvv: validatePair<number>(data.cvv, validateEmpty, validateCvvNumberFormat),
  };
};

const initialState: CreditCardI = { number: '', month: '', year: 0, cvv: 0 };

const NUMERIC_MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const AddCreditCard: FC<PropsI> = ({ onSubmit }) => {
  const currentYear = new Date().getFullYear();
  const { handleChange, handleSelectChange, handleSubmit, errors } = useFormState(
    initialState,
    onSubmit,
    validate
  );
  const { t } = useTranslation('wallet-card');

  const handleCreditCardNumChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
  };

  return (
    <div className={styles.root}>
      <div className={styles.gridContainer}>
        <FormControl variant="standard" fullWidth className={styles.creditCard}>
          <InputLabel htmlFor="credit-card">{t('credit-card')}</InputLabel>
          <Input
            id="credit-card"
            name="number"
            type="text"
            placeholder="xxxx xxxx xxxx xxxx"
            autoComplete="off"
            onChange={handleCreditCardNumChange}
            inputProps={{ maxLength: '19' }}
          />
          {renderValidationResult(errors?.number)}
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="mm">{t('MM')}</InputLabel>
          <Select id="mm" name="month" onChange={handleSelectChange}>
            {NUMERIC_MONTHS.map((numMonth: string) => (
              <MenuItem key={numMonth} value={numMonth}>
                {numMonth}
              </MenuItem>
            ))}
          </Select>
          {renderValidationResult(errors?.month)}
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="yyyy">{t('YYYY')}</InputLabel>
          <Select id="yyyy" name="year" onChange={handleSelectChange}>
            {Array(10)
              .fill(1)
              .map((_e: string, i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={currentYear + i} value={currentYear + i}>
                  {currentYear + i}
                </MenuItem>
              ))}
          </Select>
          {renderValidationResult(errors?.year)}
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="cvv">{t('CVV')}</InputLabel>
          <Input id="cvv" type="number" name="cvv" onChange={handleChange} />
          {renderValidationResult(errors?.cvv)}
        </FormControl>
      </div>

      <Button variant="contained" size="small" onClick={handleSubmit} className={styles.button}>
        {t('add-credit-card')}
      </Button>
    </div>
  );
};
