import { AvatarProps } from '@mui/material';

export const avatarSx = { width: 32, height: 32 };

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const getNameAbbreviation = (name: string) =>
  name.split(' ').reduce<string>((acc, item) => {
    if (item?.length && acc.length < 2) {
      return acc + item[0];
    }
    return acc;
  }, '');

export const getAvatarProps = (name: string, src?: string, size?: number): Partial<AvatarProps> => {
  const sx = !size ? avatarSx : { width: size, height: size };
  if (src) {
    return {
      sx,
      src,
      alt: '',
    };
  }
  return {
    sx: {
      backgroundColor: stringToColor(name),
      textTransform: 'uppercase',
      ...sx,
      fontSize: Math.round(sx.width * (12 / 32)),
    },
    children: getNameAbbreviation(name),
  };
};
