import { FC } from 'react';
import { TradeDesk } from 'pages/trade-desk';
import { Portfolio } from 'pages/portfolio';
import { HumanCapitalMint } from 'pages/human-capital-mint';
import { Wallet } from 'pages/wallet';
import { ROUTE } from './enums';
import { RestorePassword, SignIn, SignUp } from 'pages/auth';
import { Overview } from 'pages/overview';
import { Onboarding } from 'pages/onboarding';
import { TalentPortal } from 'pages/talent-portal';
import { MyOrdersApollo } from 'pages/my-orders';

interface AppRoutesConfigItemI {
  path: ROUTE;
  element?: FC;
  title: string;
  altTitle?: string;
  showInNavbar?: false;
  isPrivate?: boolean;
}

export const appNavPanelRoutesConfig: AppRoutesConfigItemI[] = [
  {
    path: ROUTE.TradeDesc,
    element: TradeDesk,
    title: 'HCX Trade Desk',
    altTitle: 'Trade Desk',
    isPrivate: true,
  },
  {
    path: ROUTE.Overview,
    element: Overview,
    title: 'Overview',
    showInNavbar: false,
    isPrivate: true,
  },
  {
    path: ROUTE.Portfolio,
    element: Portfolio,
    title: 'Portfolio',
    isPrivate: true,
  },
  {
    path: ROUTE.Markets,
    element: undefined,
    title: 'Markets',
    isPrivate: true,
  },
  {
    path: ROUTE.HumanCapitalMint,
    element: HumanCapitalMint,
    title: 'Human Capital Auction',
    altTitle: 'Human Capital Auction',
    isPrivate: true,
  },
  {
    path: ROUTE.News,
    element: undefined,
    title: 'News',
    isPrivate: true,
  },
  {
    path: ROUTE.Wallet,
    element: Wallet,
    title: 'Wallet',
    isPrivate: true,
  },
  {
    path: ROUTE.MyOrders,
    element: MyOrdersApollo,
    title: 'My Orders',
    isPrivate: true,
  },
  {
    path: ROUTE.Account,
    element: undefined,
    title: 'Account',
    isPrivate: true,
  },
  {
    path: ROUTE.Onboarding,
    element: Onboarding,
    title: 'Onboarding',
    altTitle: 'Onboarding',
  },
];

export const privateRoutesConfig: AppRoutesConfigItemI[] = [
  ...appNavPanelRoutesConfig,
  {
    path: ROUTE.TalentPortal,
    element: TalentPortal,
    title: 'Talent Portal',
    isPrivate: true,
  },
];

export const authRoutesConfig: AppRoutesConfigItemI[] = [
  {
    path: ROUTE.SignIn,
    element: SignIn,
    title: 'SingIn',
  },
  {
    path: ROUTE.SignUp,
    element: SignUp,
    title: 'SignUp',
  },
  {
    path: ROUTE.RestorePassword,
    element: RestorePassword,
    title: 'RestorePassword',
  },
];

export const publicRoutesConfig: AppRoutesConfigItemI[] = authRoutesConfig;

export const allRoutesConfig = [...privateRoutesConfig, ...publicRoutesConfig];
