import classNames from 'classnames';
import type { FC } from 'react';

import { Box } from '@mui/material';

import { HCXFinancialCardListBlock } from './hcx-financial-card-list-block';

import styles from './hcx-financial-card-list.module.scss';

interface HCXFinancialCardListPropsI {
  className?: string;
}

export const HCXFinancialCardList: FC<HCXFinancialCardListPropsI> = ({ className }) => {
  return (
    <Box className={classNames(styles.root, className)}>
      <HCXFinancialCardListBlock />
    </Box>
  );
};
