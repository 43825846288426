import Web3 from 'web3';

import { AbiType } from 'ethereum-types';
import { AbiItem } from 'web3-utils';

import { getWalletProvider, WalletE } from 'blockchain-api/ewallets-api';
import { config } from 'config';

const POST_BOND_ABI: AbiItem[] = [
  {
    type: AbiType.Function,
    name: 'postBond',
    inputs: [{ name: 'tokenId', type: 'uint256' }],
  },
];

export const postBond = async (wallet: WalletE, nftTokenId: string) => {
  const provider = await getWalletProvider(wallet);
  const accounts = await provider.request({
    method: 'eth_requestAccounts',
  });

  const currentAccount = accounts[0];
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(POST_BOND_ABI, config.ihoContractAddress);

  return contract.methods
    .postBond(Number(nftTokenId))
    .send({ from: currentAccount })
    .on('sending', (payload: unknown) => {
      console.log('===> Sending fired ', payload);
    })
    .on('sent', (payload: unknown) => {
      console.log('===> Sent fired ', payload);
    })
    .on('transactionHash', (hash: string) => {
      console.log('===> Hash fired ', hash);
    })
    .on('receipt', (receipt: unknown) => {
      console.log('===> Receipt received ', receipt);
    })
    .on('error', (error: Error) => {
      console.log(error);
    });
};
