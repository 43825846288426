import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Page404 } from 'pages/404';
import { InviteRoute } from 'pages/invite-route';

import { useAuth } from '../auth/use-auth';
import { PrivateRoute } from './private-route';
import { PublicRoute } from './public-route';
import { privateRoutesConfig, publicRoutesConfig } from './routes-config';
import { ROUTE } from './enums';

export const AppRoutes: FC = () => {
  const { isLoggedIn } = useAuth();

  return (
    <Routes>
      {publicRoutesConfig.map(({ path, element: Component, title }) =>
        Component ? (
          <Route
            key={`${title}-${path}`}
            path={path}
            element={<PublicRoute element={<Component />} isLoggedIn={isLoggedIn} />}
          />
        ) : null
      )}
      {privateRoutesConfig.map(({ path, element: Component, title }) => (
        <Route
          key={`${title}-${path}`}
          path={path}
          element={
            <PrivateRoute element={Component ? <Component /> : null} isLoggedIn={isLoggedIn} />
          }
        />
      ))}
      <Route path={ROUTE.InviteRoute} element={<InviteRoute />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
