import type { FC } from 'react';
import { Fragment } from 'react';
import { Divider } from '@mui/material';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';
import { AthleteTradingOrderI } from 'types';
import MenuIcon, { MenuIconOptionI } from './menu-icon';

import { OrderStatusE } from 'enums';

import styles from './athlete-orders.module.scss';

export type AthleteOrderMenuOptionT = MenuIconOptionI;

export interface AthleteOrdersPropsI {
  orders?: AthleteTradingOrderI[];
  getMenuOptions?: (order: AthleteTradingOrderI) => AthleteOrderMenuOptionT[] | undefined;
}

interface SubmitTypeI extends AthleteTradingOrderI {
  cancellationDate: string;
}

export const getFilledInfo = (data?: AthleteTradingOrderI | SubmitTypeI | null) =>
  data && data.status === OrderStatusE.Filled && data.filledDescription
    ? data.filledDescription
    : '';

const AthleteOrders: FC<AthleteOrdersPropsI> = ({ orders, getMenuOptions }) => {
  if (!orders?.length) return null;

  return (
    <>
      {orders.map((order, idx) => {
        const { id, number, status, date, buyDescription } = order;
        const options = getMenuOptions?.(order);

        const filledDesc = getFilledInfo(order);
        return (
          <Fragment key={id}>
            {!!idx && <Divider />}
            <div className={styles.order}>
              <div className={styles.orderNumberWrapper}>
                <span className={styles.orderNumber}>Order #{number}</span>
                {!!getMenuOptions && <MenuIcon options={options} />}
              </div>
              <span className={styles.info}>
                Status: <span className={styles[status.toLowerCase()]}>{status}</span>
              </span>
              <span className={styles.info}>
                TimeStamp:{' '}
                {`${dateFormatter(date, DateFormatE.M_D_YY_HH_MM_SS_UTC).replace(', ', ' - ')} GMT`}
              </span>
              <span className={styles.info}>{buyDescription}</span>
              {!!filledDesc && <span className={styles.info}>{filledDesc}</span>}
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default AthleteOrders;
