import { FC } from 'react';
import { UserAvatar, UserAvatarPropsI } from 'components/user-avatar';
import { useAuth } from 'auth/use-auth';
import { LoggedInAuthContextI } from 'auth/auth-context';

export type ProfileAvatarPropsT = Omit<UserAvatarPropsI, 'name'>;

const ProfileAvatar: FC<ProfileAvatarPropsT> = ({ photo: preview, ...rest }) => {
  const { user } = useAuth() as LoggedInAuthContextI;

  const { username: userName, picture } = user || {};
  return <UserAvatar name={userName} photo={preview || picture} {...rest} />;
};

export default ProfileAvatar;
