import { FC } from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CreateIcon from '@mui/icons-material/Create';
import { Panel } from 'components/panel';
import { dateFormatter, DateFormatE } from 'utils/date-formatter';
import styles from './talent-portal-account.module.scss';
import { MOC_ATHLETES_FOR_HCX_CARD } from 'moc-data/moc-athlete';
import { FormControlLabel, IconButton, Link, Switch } from '@mui/material';
import classNames from 'classnames';
import { Button } from 'components/button';

const mocData = MOC_ATHLETES_FOR_HCX_CARD[0].bio;

const getLastActivityStr = (date: string | number): string => {
  const today = new Date();
  const lastActivityDate = new Date(date);
  const hoursPast = Math.round((today.getTime() - lastActivityDate.getTime()) / (60 * 60 * 1000));
  if (
    lastActivityDate.getFullYear() < today.getFullYear() ||
    lastActivityDate.getMonth() < today.getMonth() ||
    hoursPast > 24
  ) {
    return dateFormatter(lastActivityDate, DateFormatE.M_D_YYYY_h_mm);
  }
  return `${hoursPast} hours ago`;
};

const TalentPortalAccount: FC = () => {
  return (
    <Panel className={styles.root}>
      <header className={styles.header}>
        <PersonOutlineIcon />
        Account
        <span>Last account activity: {getLastActivityStr(mocData.lastActivity)}</span>
      </header>
      <div className={styles.bio}>
        <div className={styles.imgWrapper}>
          <img src={mocData.photo} alt="" />
          <div className={styles.editBtnWrapper}>
            <IconButton className={styles.editBtn} size="small">
              <CreateIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.info}>
          <span className={styles.name} title={mocData.name}>
            {mocData.name}
          </span>
          <span className={styles.email} title={mocData.email}>
            {mocData.email}
          </span>
          <Link className={styles.link}>Reset Password</Link>
        </div>
      </div>
      <p className={classNames(styles.commonText, styles.descriptionTitle)}>
        Add ways to verify it's you
      </p>
      <p className={classNames(styles.commonText, styles.textSecondary)}>
        This can be used to make sure it's really you signing in or if there's suspicious activity
        in your account.
      </p>
      <div className={styles.actions}>
        <span className={styles.commonText}>Recovery email</span>
        <Button variant="contained" className={styles.addRecoveryEmailBtn} size="small">
          Add
        </Button>
        <FormControlLabel
          control={<Switch name="twoFactorAuth" size="small" />}
          label="Two-factor Authentication"
          labelPlacement="start"
        />
      </div>
    </Panel>
  );
};

export default TalentPortalAccount;
