import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { InputField } from 'components/input-field';
import { useTranslation } from 'react-i18next';
import styles from './form-section-emergency.module.scss';
import { useAtom } from 'jotai';
import { talentProfileFormValidationAtom } from 'store/talent-application.store';
import {
  TalentApplicationProfileEmergencyContact,
  TalentApplicationProfileQuery,
  useUpdateTalentApplicationProfileEmergenciesMutation,
} from 'generated/graphql';
import { ValidationError } from 'yup';
import { talentEmergencyReferenceSchema } from 'yup/talentFormSchema';
import { ApolloError, ApolloQueryResult } from '@apollo/client';

const NAME_MAP: Record<string, string> = {
  firstName: 'emergency-',
  lastName: 'last',
  emailAddress: 'email-address',
  phone: 'phone',
  relationship: 'relationship',
};

interface FormSectionEmergencyPropsI {
  emergencies: TalentApplicationProfileEmergencyContact[];
  refetchProfile: () => Promise<ApolloQueryResult<TalentApplicationProfileQuery>>;
}

export const FormSectionEmergency: FC<FormSectionEmergencyPropsI> = ({
  emergencies,
  refetchProfile,
}) => {
  const { t } = useTranslation('talent-application');

  const [errorState, setErrorState] = useAtom(talentProfileFormValidationAtom);

  const { emergencyContacts: errors } = errorState;

  const [updateEmergency] = useUpdateTalentApplicationProfileEmergenciesMutation();

  const onBlurHandler = async (event: React.FocusEvent, index: number) => {
    const { name, value } = event.target as HTMLInputElement;

    try {
      await talentEmergencyReferenceSchema[name].validate(value);
    } catch (err) {
      setErrorState((prev) => {
        const updatedContactError = {
          ...prev.emergencyContacts[index],
          [name]: { errMessage: (err as ValidationError).errors[0], isTouched: true },
        };
        const newEmergenciesErrorState = [...prev.emergencyContacts];
        newEmergenciesErrorState[index] = updatedContactError;
        return {
          ...prev,
          emergencyContacts: newEmergenciesErrorState,
        };
      });
      return;
    }
    setErrorState((prev) => {
      const updatedContactError = {
        ...prev.emergencyContacts[index],
        [name]: { errMessage: undefined, isTouched: true },
      };
      const newEmergenciesErrorState = [...prev.emergencyContacts];
      newEmergenciesErrorState[index] = updatedContactError;
      return {
        ...prev,
        emergencyContacts: newEmergenciesErrorState,
      };
    });

    try {
      await updateEmergency({
        variables: {
          parameters: {
            emergencyContact: {
              ...(emergencies[index]?.id
                ? {
                    id: emergencies[index].id.toString(),
                  }
                : {}),
              [name]: value,
              condition: {
                field: name,
                state: emergencies[index]?.condition?.[name]?.state ?? 0,
              },
            },
          },
        },
        refetchQueries: ['TalentApplicationState'],
      });
    } catch (error) {
      console.warn({ error });
      setErrorState((prev) => {
        const updatedContactError = {
          ...prev.emergencyContacts[index],
          [name]: { errMessage: (error as ApolloError).message, isTouched: true },
        };
        const newEmergenciesErrorState = [...prev.emergencyContacts];
        newEmergenciesErrorState[index] = updatedContactError;
        return {
          ...prev,
          emergencyContacts: newEmergenciesErrorState,
        };
      });
    }
    await refetchProfile();
  };

  return (
    <Box component="form" autoComplete="off">
      {new Array(2)
        .fill(null)
        .map((_, indexId) => ({ id: indexId }))
        .map((val, i) => {
          return (
            <Box key={val.id} display="flex" justifyContent="space-between" mb={2}>
              {Object.keys(NAME_MAP).map((name) => {
                return (
                  <InputField
                    key={name}
                    tName={t(`${NAME_MAP[name]}${name === 'firstName' ? i + 1 : ''}`)}
                    className={styles.widthInput}
                    name={name}
                    onBlur={(event) => onBlurHandler(event, i)}
                    defaultValue={
                      emergencies[i]?.[name as keyof TalentApplicationProfileEmergencyContact] || ''
                    }
                    errorValidation={
                      errors[i][name as keyof typeof errors[typeof i]].isTouched &&
                      errors[i][name as keyof typeof errors[typeof i]].errMessage
                    }
                  />
                );
              })}
            </Box>
          );
        })}
    </Box>
  );
};
