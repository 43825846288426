import classNames from 'classnames';
import { InfoTablePropsI } from './info-table.types';
import styles from './info-table.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InfoTable = <T extends Record<string, any>>({
  title,
  data,
  columns,
  className,
}: InfoTablePropsI<T>): JSX.Element => {
  return (
    <table className={classNames(styles.root, className)}>
      <caption>{title}</caption>
      <tbody>
        {columns.map(({ dataKey, title: rowTitle, renderValue, jsxKey }) => {
          const resultValue = renderValue ? renderValue(data, { styles }) : data[dataKey];
          return (
            <tr key={jsxKey || dataKey}>
              <th>{rowTitle}:</th>
              <td>{resultValue}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default InfoTable;
