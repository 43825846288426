import MuiSelect from '@mui/material/Select';
import { SelectProps } from '@mui/material/Select/Select';
import styles from './select.module.scss';
import classNames from 'classnames';
import { ArrowDropDown } from '@mui/icons-material';

const CLASSES = {
  filled: classNames(styles.filled, 'SELECT_FILLED'),
  icon: styles.icon,
};

const MENU_CLASSES = {
  paper: styles.menuPaper,
  list: styles.menuList,
};

export type SelectPropsT<T> = SelectProps<T>;

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const Select = <T,>({ variant, className, ...props }: SelectPropsT<T>): JSX.Element => (
  <MuiSelect
    className={classNames(variant === 'filled' && styles.rootFilled, className)}
    variant={variant}
    classes={CLASSES}
    MenuProps={{ classes: MENU_CLASSES }}
    IconComponent={ArrowDropDown}
    {...props}
  />
);
