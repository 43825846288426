import type { FC } from 'react';
import { Fragment, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { Box, Divider } from '@mui/material';

import { CustomSwitcher } from 'components/custom-switcher';
import { AthleteCareerI } from 'types';

import { ATHLETE_SUMMARY_STATS_COLUMNS_CONFIG, StatsTable } from './stats-table';

import styles from './hcx-card-summary-stats.module.scss';

const tabLabels = [
  <Trans i18nKey="career" key="career" ns="hcx-card" />,
  <Trans i18nKey="season" key="season" ns="hcx-card" />,
];

export const HCXCardSummaryStats: FC<{ stats: AthleteCareerI['stats'] }> = ({ stats }) => {
  const [tabIdx, setTabIdx] = useState(0);

  const currentStatsData = useMemo(
    () => (!tabIdx ? stats?.career : stats?.season),
    [tabIdx, stats]
  );

  if (!currentStatsData) {
    return null;
  }

  return (
    <>
      <Box className={styles.switcherWrapper}>
        <CustomSwitcher labels={tabLabels} value={tabIdx} onChange={setTabIdx} />
      </Box>
      {Object.entries(currentStatsData).map(([key, data], idx) => (
        <Fragment key={key}>
          {!!idx && <Divider />}
          <Box className={styles.infoTable}>
            <StatsTable title={key} data={data} columns={ATHLETE_SUMMARY_STATS_COLUMNS_CONFIG} />
          </Box>
        </Fragment>
      ))}
    </>
  );
};
