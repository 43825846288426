import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const MicrosoftExcelIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 40">
    <path
      d="M24.168 5H13.1213C12.3188 5 11.668 5.65083 11.668 6.45333V12.5025H24.168V5Z"
      fill="#169154"
    />
    <path
      d="M11.668 27.5449V33.5466C11.668 34.3491 12.3188 34.9999 13.1205 34.9999H24.168V27.5449H11.668Z"
      fill="#18482A"
    />
    <path d="M11.668 12.5024H24.168V20.0041H11.668V12.5024Z" fill="#0C8045" />
    <path d="M11.668 20.0044H24.168V27.5461H11.668V20.0044Z" fill="#17472A" />
    <path
      d="M35.2146 5H24.168V12.5025H36.668V6.45333C36.668 5.65083 36.0171 5 35.2146 5Z"
      fill="#29C27F"
    />
    <path
      d="M24.168 27.5449V34.9999H35.2155C36.0171 34.9999 36.668 34.3491 36.668 33.5474V27.5458H24.168V27.5449Z"
      fill="#27663F"
    />
    <path d="M24.168 12.5024H36.668V20.0041H24.168V12.5024Z" fill="#19AC65" />
    <path d="M24.168 20.0044H36.668V27.5461H24.168V20.0044Z" fill="#129652" />
    <path
      d="M18.5979 28.3332H4.73286C3.95953 28.3332 3.33203 27.7057 3.33203 26.9323V13.0673C3.33203 12.294 3.95953 11.6665 4.73286 11.6665H18.5979C19.3712 11.6665 19.9987 12.294 19.9987 13.0673V26.9323C19.9987 27.7057 19.3712 28.3332 18.5979 28.3332Z"
      fill="#0C7238"
    />
    <path
      d="M8.17167 15.8335H10.16L11.7733 18.9618L13.4783 15.8335H15.3358L12.7767 20.0002L15.3942 24.1668H13.435L11.6767 20.8918L9.92583 24.1668H7.9375L10.5983 19.9852L8.17167 15.8335Z"
      fill="white"
    />
  </SvgIcon>
);
