import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Panel } from 'components/panel';
import moment from 'moment';
import { ActivityHeader } from './activity-header';
import { Box, Typography, Slide } from '@mui/material';
import {
  SponsorActivity,
  SponsorActivityListType,
  TalentApplicationActivity,
  useTalentActivityListLazyQuery,
} from 'generated/graphql';
import { useActivityListLazyQuery } from 'generated/graphql';
import styles from './activity-panel.module.scss';
import { Paginator } from 'components/paginator';
import { QueryPagingI } from 'types';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { sponsorPanelView } from 'store/sponsor-application.store';
import { sponsorManagementView } from 'store/sponsor-management.store';

export interface ActivityPanelPropsI {
  viewConfig: boolean;
  closeActivityPanel: () => void;
  sponsorActivityType?: SponsorActivityListType;
}

const TEXT_MAP: Record<string, string> = {
  InvitationAcceptedAction: 'Your invitation accepted by',
  InvitationCanceledAction: 'Cancel invitation of',
  InvitationDeclinedAction: 'Your invitation declined by',
  InvitationResentAction: 'Resend invitation for',
  InvitationSentAction: 'Send invitation for',
  RoleChangedAction: 'Change permission of ',
  StatusChangedAction: 'Change status of',
};

const COUNTER_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const ActivityPanel: FC<ActivityPanelPropsI> = ({
  viewConfig,
  closeActivityPanel,
  sponsorActivityType,
}) => {
  const [activity, setActivity] = useState<
    Array<SponsorActivity | TalentApplicationActivity | null>
  >([]);
  const [pageTotal, setPageTotal] = useState(0);
  const [queryPaging, setQueryPaging] = useState<QueryPagingI>({ limit: 5, offset: 0 });
  const [isSponsorOpen] = useAtom(sponsorPanelView);
  const [isManagementOpen] = useAtom(sponsorManagementView);
  const handlePaginationChange = useCallback(
    (newQueryPaging) => setQueryPaging(newQueryPaging),
    []
  );

  const isPanelOpen = isSponsorOpen || isManagementOpen;

  const [querySponsorActivity, { data: sponsorData, loading: sponsorLoading }] =
    useActivityListLazyQuery();

  const [queryTalentActivity, { data: talentData, loading: talentLoading }] =
    useTalentActivityListLazyQuery();

  const getSponsorActivityList = useCallback(() => {
    querySponsorActivity({
      variables: {
        activityType: sponsorActivityType || SponsorActivityListType.ApplicationActivity,
        offset: queryPaging.offset,
        limit: queryPaging.limit,
      },
    });
  }, [sponsorActivityType, queryPaging.limit, queryPaging.offset, querySponsorActivity]);

  useEffect(() => {
    setActivity(sponsorData?.sponsorActivityList.data || []);
    setPageTotal(sponsorData?.sponsorActivityList.pagination?.total || 0);
  }, [sponsorData]);

  const getTalentActivityList = useCallback(() => {
    queryTalentActivity({
      variables: { offset: queryPaging.offset, limit: queryPaging.limit },
    });
  }, [queryPaging.limit, queryPaging.offset, queryTalentActivity]);

  useEffect(() => {
    setActivity(talentData?.talentApplicationActivityList.data || []);
    setPageTotal(talentData?.talentApplicationActivityList.pagination?.total || 0);
  }, [talentData]);

  useEffect(() => {
    if (sponsorActivityType) {
      getSponsorActivityList();
    } else {
      getTalentActivityList();
    }
  }, [getSponsorActivityList, getTalentActivityList, sponsorActivityType]);

  const containerRef = useRef(null);

  const loading = sponsorActivityType ? sponsorLoading : talentLoading;

  return (
    <Box
      ref={containerRef}
      className={classNames(styles.rootContainer, { [styles.rootContainerPanel]: isPanelOpen })}
    >
      <Slide
        direction="left"
        in={viewConfig}
        container={containerRef.current}
        timeout={400}
        easing={{
          enter: 'linear',
          exit: 'linear',
        }}
      >
        <Panel className={styles.root}>
          <ActivityHeader closePanel={closeActivityPanel} />
          <Box className={styles.sectionWrapper}>
            {loading
              ? 'Loading...'
              : activity.map((act) => {
                  if (act !== null) {
                    return (
                      <Box key={act?.createdAt} className={styles.sectionRoot}>
                        <Typography variant="body1" className={styles.rowDate}>
                          {moment(act?.createdAt).format('MM/DD/YY @ h:mm a')}
                        </Typography>
                        <Box display="inline">
                          <Typography variant="subtitle2">
                            {('userName' in act && act?.userName) ||
                              ('sponsorName' in act && act?.sponsorName)}
                          </Typography>
                        </Box>
                        <Box className={styles.rowAction}>
                          <Typography variant="body2" display="inline" mr={0.5}>
                            {'action' in act && !!TEXT_MAP[act.action] && `${TEXT_MAP[act.action]}`}
                            {'action' && 'actionDetail' in act && `${act.action}:`}
                            {'field' in act && `Change the ${act?.field.split('.').join(' ')}:`}
                          </Typography>
                          <Typography variant="body2" display="inline" mr={0.5}>
                            {'sponsorUserName' in act && `${act?.sponsorUserName || 'email-name'}`}
                          </Typography>
                          <Typography variant="body2" display="inline">
                            {'value' in act && !!act.value && `to ${act.value}`}
                            {'fieldValue' in act && act.fieldValue}
                            {'actionDetail' in act && act.actionDetail}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                  return null;
                })}
          </Box>
          <Box className={styles.paginationBar}>
            <Paginator
              pagination={{
                limit: queryPaging.limit,
                total: pageTotal,
                offset: queryPaging.offset,
              }}
              handlePaginationChange={handlePaginationChange}
              rowsPerPageOptions={COUNTER_LIST}
              refreshList={sponsorActivityType ? getSponsorActivityList : getTalentActivityList}
            />
          </Box>
        </Panel>
      </Slide>
    </Box>
  );
};
