type CallbackT<E, A> = (err: E, args: A) => void;

export const promisify =
  <D, E, R, T>(
    fn: (args: D, cb: CallbackT<E, R>) => void,
    thisArg?: T
  ): ((args: D) => Promise<R>) =>
  (args: D) =>
    new Promise((resolve, reject) => {
      fn.call(thisArg, args, (err, callbackArgs) => {
        if (err) {
          reject(err);
        }
        resolve(callbackArgs);
      });
    });
