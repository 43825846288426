import { FormControl } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { FC } from 'react';

import { TextFieldI } from '../types';
import { CommonPropsI } from './types';

export const TextField: FC<TextFieldI & CommonPropsI<string>> = ({
  label,
  field,
  value,
  onChange,
}) => {
  return (
    <FormControl variant="standard" fullWidth>
      <MuiTextField
        variant="standard"
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        name={field}
      />
    </FormControl>
  );
};
