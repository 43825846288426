import { TimeFrameItem, ResolutionString, DatafeedConfiguration } from 'charting_library';

export const timeFrames = [
  { text: '5y', resolution: '5', description: '5 Years', realResolution: '60M' },
  { text: '1y', resolution: '5', description: '1 Year', realResolution: '12M' },
  { text: '6m', resolution: '5', description: '6 Months', realResolution: '6M' },
  { text: '3m', resolution: '5', description: '3 Months', realResolution: '3M' },
  { text: '1m', resolution: '5', description: '1 Months', realResolution: '1M' },
  { text: '5d', resolution: '5', description: '5 Days', realResolution: '5D' },
  { text: '1d', resolution: '5', description: '1 Day', realResolution: '1D' },
  { text: '20y', resolution: '5', description: 'All', title: 'All', realResolution: 'All' },
] as (TimeFrameItem & { realResolution: string | ResolutionString })[];

export const resolutions = ['5'] as ResolutionString[]; // TODO: add resolutions supported by BE

export const dataFeedConfigurationData: DatafeedConfiguration = {
  supported_resolutions: resolutions,
  supports_marks: true,
  supports_time: true,
  supports_timescale_marks: true,
  exchanges: [
    {
      value: 'Bitfinex',
      name: 'Bitfinex',
      desc: 'Bitfinex',
    },
    {
      // `exchange` argument for the `searchSymbols` method, if a user selects this exchange
      value: 'Kraken',

      // filter name
      name: 'Kraken',

      // full exchange name displayed in the filter popup
      desc: 'Kraken bitcoin exchange',
    },
  ],
  symbols_types: [
    {
      name: 'crypto',

      // `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
      value: 'crypto',
    },
    // ...
  ],
};

export const dataFeedConfigurationData1: DatafeedConfiguration = {
  supported_resolutions: resolutions,
  supports_marks: true,
  supports_time: true,
  supports_timescale_marks: true,
  exchanges: [
    {
      value: 'HCX',
      name: 'HCX',
      desc: 'Human Capital Exchange',
    },
  ],
  symbols_types: [
    {
      name: 'crypto',
      value: 'crypto',
    },
  ],
};
