import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DragIndicator from '@mui/icons-material/DragIndicator';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { ArrowDropDown } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import styles from './edit-column-view.module.scss';
import { move } from '../../../utils/array-move';
import { Button } from '../../../components/button';
import { TableColumnsIcon } from '../../../components/icons/table-columns-icon';
import { mapObjectWithOrder } from '../../../utils/map-object-with-order';
import { ColumnConfigurationI, ColumnsConfigurationI } from './types';

interface PropsI {
  id: string;
  title: string;
  fields: ColumnConfigurationI[];
  onDelete?: (id: string) => void;
  onSave: (config: ColumnsConfigurationI) => void;
  onSaveAsNew: (config: ColumnsConfigurationI) => void;
}

const prepareFields = (fields: ColumnConfigurationI[]) =>
  fields.reduce<Record<string, ColumnConfigurationI>>(
    (acc, cur) => ({ ...acc, [cur.field]: cur }),
    {}
  );

export const EditColumnView: FC<PropsI> = ({
  id,
  title: titleFromProps,
  fields: fieldsFromProps,
  onDelete,
  onSave,
  onSaveAsNew,
}) => {
  const [fields, setFields] = useState(() => prepareFields(fieldsFromProps));
  const [order, setOrder] = useState(() => fieldsFromProps.map((c) => c.field));
  const [title, setTitle] = useState(titleFromProps);
  const { t } = useTranslation('human-capital-mint');
  return (
    <Box className={styles.wrapper}>
      <Box className={styles.topLine}>
        <TableColumnsIcon className={styles.columnIcon} />
        <TextField
          variant="standard"
          placeholder={t('column-view-name')}
          className={styles.titleInput}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Box>
      <Box className={styles.table}>
        <Box className={styles.headerRow}>
          <Box>
            <Typography className={styles.heading} pl="46px">
              {t('name')}
            </Typography>
          </Box>
          <Typography className={styles.heading}>{t('sort')}</Typography>
          <Typography className={styles.heading}>{t('description')}</Typography>
        </Box>
        <DragDropContext
          onDragEnd={(result) =>
            // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
            setOrder((data) => move(data, result.source.index, result.destination!.index!))
          }
        >
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {mapObjectWithOrder(fields, order, (field, index) => (
                  <Draggable key={field.field} draggableId={field.field} index={index}>
                    {(
                      providedDraggable: DraggableProvided,
                      snapshotDraggable: DraggableStateSnapshot
                    ) => (
                      <div
                        className={classNames(
                          styles.row,
                          snapshotDraggable.isDragging && styles.rowDragging
                        )}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        style={providedDraggable.draggableProps.style}
                      >
                        <Box className={styles.rowLeft}>
                          <div {...providedDraggable.dragHandleProps} className={styles.dragHandle}>
                            <DragIndicator className={styles.dragIcon} />
                          </div>
                          <Checkbox
                            checked={fields[field.field].enabled}
                            onChange={(_, checked) =>
                              setFields((data) => ({
                                ...data,
                                [field.field]: { ...data[field.field], enabled: checked },
                              }))
                            }
                          />
                          <Typography className={styles.titleField}>{field.title}</Typography>
                        </Box>
                        <IconButton
                          onClick={() =>
                            setFields((data) => ({
                              ...data,
                              [field.field]: {
                                ...data[field.field],
                                sort: data[field.field].sort === 'asc' ? 'desc' : 'asc',
                              },
                            }))
                          }
                        >
                          <ArrowDropDown
                            className={classNames(
                              styles.sort,
                              fields[field.field].sort === 'desc' && styles.desc
                            )}
                          />
                        </IconButton>
                        <TextField
                          variant="standard"
                          size="small"
                          className={styles.descriptionInput}
                          value={fields[field.field].description}
                          onChange={(e) =>
                            setFields((data) => ({
                              ...data,
                              [field.field]: { ...data[field.field], description: e.target.value },
                            }))
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box className={styles.bottomLine}>
        <IconButton
          onClick={() => onDelete?.(id)}
          className={classNames(styles.deleteButton, onDelete && styles.deleteButtonVisible)}
        >
          <DeleteOutlineIcon />
        </IconButton>
        <Box>
          <Button
            variant="text"
            size="small"
            onClick={() => {
              onSaveAsNew({ title, id, fields: order.map((x) => fields[x]) });
            }}
          >
            {t('save-as-new-view')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onSave({ title, id, fields: order.map((x) => fields[x]) });
            }}
          >
            {t('save-view')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
