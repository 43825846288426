import type { FC } from 'react';

interface PropsI {
  className?: string;
  color?: string;
}

export const WalletIcon: FC<PropsI> = ({ className, color = 'currentColor' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.75 2.25C2.9175 2.25 2.25 2.925 2.25 3.75V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H14.25C14.6478 15.75 15.0294 15.592 15.3107 15.3107C15.592 15.0294 15.75 14.6478 15.75 14.25V12.54C16.1925 12.2775 16.5 11.805 16.5 11.25V6.75C16.5 6.195 16.1925 5.7225 15.75 5.46V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25H3.75ZM3.75 3.75H14.25V5.25H9.75C9.35218 5.25 8.97065 5.40804 8.68934 5.68934C8.40804 5.97064 8.25 6.35218 8.25 6.75V11.25C8.25 11.6478 8.40804 12.0294 8.68934 12.3107C8.97065 12.592 9.35218 12.75 9.75 12.75H14.25V14.25H3.75V3.75ZM9.75 6.75H15V11.25H9.75V6.75ZM12 7.875C11.7016 7.875 11.4155 7.99353 11.2045 8.2045C10.9935 8.41548 10.875 8.70163 10.875 9C10.875 9.29837 10.9935 9.58452 11.2045 9.7955C11.4155 10.0065 11.7016 10.125 12 10.125C12.2984 10.125 12.5845 10.0065 12.7955 9.7955C13.0065 9.58452 13.125 9.29837 13.125 9C13.125 8.70163 13.0065 8.41548 12.7955 8.2045C12.5845 7.99353 12.2984 7.875 12 7.875Z"
      fill={color}
    />
  </svg>
);
