import { FC } from 'react';
import { CustomScrollbars } from 'components/custom-scrollbars';

import styles from './hcx-card.module.scss';

const HcxCardTabViewSpacing: FC<{ resetScrollTrigger?: number | string | null }> = ({
  children,
  resetScrollTrigger,
}) => {
  return (
    <CustomScrollbars className={styles.tabViewSpacingY} resetScrollTrigger={resetScrollTrigger}>
      <div className={styles.tabViewSpacingX}>{children}</div>
    </CustomScrollbars>
  );
};

export default HcxCardTabViewSpacing;
