import type { FC } from 'react';
import { useState, useCallback, useRef } from 'react';
import { Panel } from 'components/panel';
import { ActivityPanel } from 'components/activity-panel';
import { SponsorPanelHeader } from './sponsor-panel-header';
import { SponsorPanelDescription } from './sponsor-panel-description';
import { SponsorPanelAccordion } from './sponsor-panel-accordion';
import styles from './sponsor-application.module.scss';
import Slide from '@mui/material/Slide';
import { useAtom } from 'jotai';
import { sponsorPanelView } from 'store/sponsor-application.store';
import { SponsorActivityListType } from 'generated/graphql';

export const SponsorApplication: FC = () => {
  const [activityView, setActivityView] = useState(false);
  const [isSponsorPanelOpened] = useAtom(sponsorPanelView);
  const closeActivityPanel = useCallback(() => setActivityView(false), []);
  const openActivityPanel = useCallback(() => setActivityView(true), []);
  const wrapperRef = useRef(null);

  return (
    <>
      {activityView && (
        <ActivityPanel
          viewConfig={activityView}
          closeActivityPanel={closeActivityPanel}
          sponsorActivityType={SponsorActivityListType.ApplicationActivity}
        />
      )}
      <Slide
        direction="left"
        in={isSponsorPanelOpened}
        container={wrapperRef.current}
        timeout={300}
        easing={{
          enter: 'ease-out',
          exit: 'linear',
        }}
      >
        <Panel className={styles.root}>
          <SponsorPanelHeader openActivityPanel={openActivityPanel} />
          <SponsorPanelDescription />
          <SponsorPanelAccordion />
        </Panel>
      </Slide>
    </>
  );
};
