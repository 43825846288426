import type { FC } from 'react';

import { AthleteCardPriceLine } from './athlete-card-price-line';

import { useLatestTradingPairStatsSubscription, useTradingPairStatsQuery } from 'generated/graphql';
import { AthleteI } from 'types';

export interface AthleteCardPriceLineApolloPropsI {
  athlete: AthleteI;
  className?: string;
}

export const AthleteCardPriceLineApollo: FC<AthleteCardPriceLineApolloPropsI> = ({
  athlete,
  className,
}) => {
  const tradingPairId = athlete?.tradingInfo.tradingPairId ?? '';

  const { data: tradingPairStats } = useTradingPairStatsQuery({
    variables: {
      input: {
        id: tradingPairId,
      },
    },
    skip: !tradingPairId,
  });

  const { data: tradingPairStatsSub } = useLatestTradingPairStatsSubscription({
    fetchPolicy: 'network-only',
    variables: { tradingPairId },
    skip: !tradingPairId,
  });

  if (!athlete) {
    return null;
  }

  return (
    <AthleteCardPriceLine
      athleteById={athlete}
      tradingPairStats={tradingPairStats}
      tradingPairStatsSub={tradingPairStatsSub}
      className={className}
    />
  );
};
