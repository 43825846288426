import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './tab-terms-of-service.module.scss';

export const TermsOfService: FC = () => {
  return (
    <Box className={styles.wrapper}>
      <Typography variant="caption" className={styles.text}>
        STRICTLY PRIVATE AND CONFIDENTIAL
      </Typography>
      <Typography variant="caption" className={styles.text}>
        This HCX Sponsor Agreement (the “Agreement”) is entered into this __ day of __________,
        202__ (“Effective Date”) by Human Capital Finance, LLC, (“Company”), a Delaware limited
        liability company with its principal place of business at [HCFC Company address], and
        [Sponsor Company Name] (“Partner”), a [state] [company type] with its principal place of
        business at [address, city, state].
      </Typography>
      <Typography variant="caption" className={styles.title}>
        RECITALS:
      </Typography>
      <Typography variant="caption" className={styles.text}>
        WHEREAS, Company desires to appoint qualified partners to help the Company to identify,
        recruit, evaluate, qualify, and obtain commitments from individuals (each, a “Candidate”)
        suitable for Initial Person Offerings (each, an “IPO”) on a digital assets platform owned
        and operated by Company and its Affiliates called Human Capital Exchange or HCX (the
        “Platform”); WHEREAS, Partner has an independent organization and business relationships
        that will allow it to help the Company to identify, recruit, evaluate, qualify and complete
        IPOs with Candidates, and to help the Company to support a Candidate following completion of
        the IPO; and
      </Typography>
      <Typography variant="caption" className={styles.text}>
        WHEREAS, Partner desires to become a partner of Company to help Company to facilitate IPOs
        on the Platform and to provide services to Candidates following their IPO.
      </Typography>
      <Typography variant="caption" className={styles.title}>
        AGREEMENT:
      </Typography>
      <Typography variant="caption" className={styles.text}>
        NOW, THEREFORE, in consideration of the foregoing Recitals, which are by this reference
        incorporated into this Agreement, and the mutual covenants, terms and conditions set forth
        below, and intending to be legally bound, the parties agree as follows:
      </Typography>
      <Typography variant="caption" className={styles.title}>
        SECTION 1. DEFINITIONS.
      </Typography>
      <Typography variant="caption" className={styles.text}>
        “Candidate” means a qualified individual who is first identified by Partner as a potential
        candidate for an IPO on the Platform by notice to Company. “Exchange” means a post-IPO peer
        to peer discovery platform or other means of purchase and sale of HCX-sponsored and
        supported digital assets (each a “Share” and together the “Shares”) operated by Company or
        its Affiliates. “Fees” shall have the meaning provided in Section 3.4 of this Agreement.
        “Recourse Deposit” means a deposit by Partner into an account designated by Company or its
        Affiliate of United States Dollars in an amount not to exceed twenty percent (20%) of the
        total capital raised in an IPO by a Candidate, which deposit shall be held by Company
        subject to the terms and conditions for similar deposits adopted by Company or its
        Affiliates from time to time. “Sponsor” means the status of a qualified partner with that
        designation pursuant to Section 2.1 hereof, which shall include Partner for so long as
        Partner shall maintain that status during the Term. “Talent” means the individual
        participating in the “Initial Person Offering” (“IPO”) as the seller of the interests in
        his, her or their future income. "Term" shall be as defined in Section 6 of this Agreement.
      </Typography>
      <Typography variant="caption" className={styles.title}>
        SECTION 2. APPOINTMENT AS SPONSOR.
      </Typography>
      <Typography variant="caption" className={styles.text}>
        2.1 Appointment. Subject to the terms and conditions of this Agreement, Company hereby
        appoints Partner as a non-exclusive Sponsor. 2.2 Acceptance. Partner hereby accepts
        Company’s appointment to be a non-exclusive Sponsor of Company, with Sponsor status
        contingent on Partner’s ongoing compliance with its duties and responsibilities under
        Section 3.2 and Section 3.3 hereof. 2.3 Partner’s Best Efforts. Partner agrees to diligently
        and faithfully fulfill the duties of a Sponsor of Company to the best of its ability. 2.4
        Nature of Relationship. The relationship established between Company and Partner by this
        Agreement is that of Candidate referral source and service provider to Company for
        Candidates referred to Company by Partner. Partner is an independent contractor under this
        Agreement and shall not have the right to assume or create any obligation of any kind,
        either express or implied, on behalf of Company, except as expressly provided for in this
        Agreement. Nothing in this Agreement shall be deemed to establish or otherwise create a
        relationship of principal and agent, employer and employee, or otherwise between Company and
        Partner. Unless specifically authorized by Company in writing in each instance, Partner
        shall not enter into a direct contractual relationship with any Candidate without the
        expressed prior written approval of Company in each instance.
      </Typography>
      <Typography variant="caption" className={styles.title}>
        SECTION 3. DUTIES & RESPONSIBILITIES/FEES.
      </Typography>
      <Typography variant="caption" className={styles.text}>
        3.1 Of Company. Subject to compliance by Partner with its obligations hereunder, Company,
        shall, on a non-exclusive basis, assist Partner with the evaluation, qualification, and
        appointment of Candidates, and in each Candidate’s participation in and completion of an IPO
        that complies with the terms and conditions related to an IPO adopted by Company and its
        Affiliates from time to time. Company shall provide such training, marketing, scope of
        service and other materials to Partner and its Candidates as Company shall deem useful in
        its reasonable discretion, and shall provide Partner and its Candidates with electronic
        access to the Platform and such other electronic systems as Company shall make available to
        its business partners generally, subject to compliance by Partner and its Candidates with
        any terms and conditions that may be applied to such access by Company from time to time.
        Company may, directly or through an Affiliate, take commercially reasonable steps to
        maintain a Candidate’s Shares on one or more Exchanges following successful completion by
        that Candidate of an IPO, subject to compliance by the Candidate, the Shares, and Sponsor
        with all applicable requirements, rules and regulations at all times. 3.2 Partner Duties.
        Partner shall solely and exclusively assist the Company to identify, recruit, evaluate,
        qualify and contract with Candidates during the Term, shall deposit with Company at least
        five (5) business days prior to an IPO by a Candidate the Recourse Deposit for that
        Candidate’s IPO, shall comply with the then current Terms & Conditions of Company and its
        Affiliates related to the IPO process (the current version of which is attached as Exhibit A
        to this Agreement), shall make commercially reasonable best efforts as requested by Company
        to help Company (directly or through an Affiliate) to maintain a Candidate’s Shares on one
        or more Exchanges following successful completion by that Candidate of an IPO, and shall
        take such other actions as shall be requested of Partner by Company or its Affiliates from
        time to time hereunder in order to facilitate successful IPOs and post-IPO availability of
        Candidate’s Shares on one or more Exchanges. 3.4 Fees. As compensation for each Candidate
        first introduced to Company by Partner who completes an IPO, Company agrees to issue to
        Partner a one time fee (each a “Fee”) for that IPO and post-IPO services to HCX, pursuant a
        selected Level and the corresponding Fees for that Level set forth on Exhibit B to this
        Agreement. 3.5 Payment. The amount of any Fee due to Partner pursuant to the Schedule of
        Fees shall be delivered to Partner by Company or its Affiliate within thirty (30) business
        days of completion of an IPO to which a Fee relates, and at regular intervals determined by
        Company for post-IPO services (if any). 3.6 Statements. Company agrees to furnish Partner
        with a statement with each Fee paid or delivered to Partner setting forth in reasonable
        detail the calculation of that Fee and the Candidate and service to which it relates. 3.7
        Suspension. If in Company’s reasonable discretion Partner is at any time unable to fulfill
        its duties hereunder, Company may, in lieu of termination of this Agreement, suspend Partner
        (including its Sponsor status) and cease performance under this Agreement. Company shall
        notify Partner of the
      </Typography>
    </Box>
  );
};
