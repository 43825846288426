import type { FC } from 'react';
import { Box, Typography, Link, SelectChangeEvent } from '@mui/material';
import { InputField } from 'components/input-field';
import { SelectField } from 'components/select-field';
import { useTranslation } from 'react-i18next';
import { AddPhotoInput } from 'components/add-photo-input';
import { useAtom } from 'jotai';
import { sponsorFormValidationAtom } from 'store/sponsor-application.store';
import styles from './sponsor-form-information.module.scss';
import {
  SponsorApplicationDataQuery,
  SponsorApplicationInformation,
  useLogoDarkLazyQuery,
  useLogoLightLazyQuery,
  useUpdateSponsorApplicationMutation,
} from 'generated/graphql';
import { COUNTRIES_AND_COMPANIES } from 'features/sponsor-application/sponsor-form-information/constants';
import React from 'react';
import { sponsorFormSchema } from 'yup/sponsorFormSchema';
import { ValidationError } from 'yup';
import { ApolloError, ApolloQueryResult } from '@apollo/client';

type UrlT = { logoUrlForDarkBackground: string } | { logoUrlForLightBackground: string };

interface SponsorInformationFormPropsI {
  sponsorInformation: SponsorApplicationInformation;
  refetchApplication: () => Promise<ApolloQueryResult<SponsorApplicationDataQuery>>;
  updateQuery: (
    mapFn: (previousQueryResult: SponsorApplicationDataQuery) => SponsorApplicationDataQuery
  ) => void;
  isSubmitted: boolean;
}

export const SponsorInformationForm: FC<SponsorInformationFormPropsI> = ({
  sponsorInformation,
  refetchApplication,
  updateQuery,
  isSubmitted,
}) => {
  const [errors, setErrors] = useAtom(sponsorFormValidationAtom);
  const { t } = useTranslation('sponsor-application');

  const { sponsorInformation: formErrors } = errors;

  const [updateApplication] = useUpdateSponsorApplicationMutation();

  const onChangeHandler = async (
    event: React.ChangeEvent | SelectChangeEvent,
    cb: ((value: string) => string) | null = null
  ) => {
    const { name } = event.target as HTMLInputElement;
    let { value } = event.target as HTMLInputElement;
    if (cb) {
      value = cb(value);
    }

    try {
      await sponsorFormSchema[name].validate(value, { context: { sponsorInformation } });
    } catch (err) {
      setErrors((prev) => {
        return {
          ...prev,
          sponsorInformation: {
            ...prev?.sponsorInformation,
            [name]: {
              errMessage: (err as ValidationError).errors[0],
              isTouched: true,
            },
          },
        };
      });
      return console.warn({ err });
    }
    setErrors((prev) => {
      return {
        ...prev,
        sponsorInformation: {
          ...prev?.sponsorInformation,
          [name]: {
            errMessage: undefined,
            isTouched: true,
          },
        },
      };
    });
    if (name === 'country') {
      updateQuery((prev) => ({
        ...prev,
        sponsorApplication: {
          ...prev.sponsorApplication,
          sponsorInformation: {
            ...prev.sponsorApplication.sponsorInformation,
            country: value,
          },
        },
      }));
    }
    if (name === 'corporateType') {
      try {
        await updateApplication({
          variables: {
            parameters: {
              sponsorInformation: {
                country: sponsorInformation.country,
                corporateType: value,
              },
            },
          },
        });
        await refetchApplication();
      } catch (err) {
        setErrors((prev) => {
          return {
            ...prev,
            sponsorInformation: {
              ...prev?.sponsorInformation,
              [name]: {
                errMessage: (err as ApolloError).message,
                isTouched: true,
              },
            },
          };
        });
        return console.warn({ err });
      }
    }
  };
  const onBlurHandler = async (event: React.FocusEvent) => {
    const { value, name } = event.target as HTMLInputElement;
    try {
      await sponsorFormSchema[name].validate(value);
    } catch (err) {
      setErrors((prev) => {
        return {
          ...prev,
          sponsorInformation: {
            ...prev?.sponsorInformation,
            [name]: {
              errMessage: (err as ValidationError).errors[0],
              isTouched: true,
            },
          },
        };
      });
      return console.warn(err);
    }
    setErrors((prev) => {
      return {
        ...prev,
        sponsorInformation: {
          ...prev?.sponsorInformation,
          [name]: {
            errMessage: undefined,
            isTouched: true,
          },
        },
      };
    });
    try {
      await updateApplication({
        variables: {
          parameters: {
            sponsorInformation: {
              [name]: value,
            },
          },
        },
      });
      await refetchApplication();
    } catch (err) {
      console.warn({ err });
      setErrors((prev) => {
        return {
          ...prev,
          sponsorInformation: {
            ...prev?.sponsorInformation,
            [name]: {
              errMessage: (err as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
    }
  };

  const [queryDarkPhoto] = useLogoDarkLazyQuery();
  const [queryLightPhoto] = useLogoLightLazyQuery();

  const photoHandler = async (
    event: React.ChangeEvent,
    photoQuery: typeof queryDarkPhoto | typeof queryLightPhoto
  ) => {
    const name = (event.target as HTMLInputElement).name;

    setErrors((prev) => {
      return {
        ...prev,
        sponsorInformation: {
          ...prev?.sponsorInformation,
          [name]: {
            errMessage: undefined,
            isTouched: undefined,
          },
        },
      };
    });

    const photoMutationField = name.replace('Url', '');
    const photoList = (event.target as HTMLInputElement).files;
    if (!photoList) return;
    const photo = photoList[0];
    try {
      await updateApplication({
        variables: {
          parameters: {
            sponsorInformation: {
              [photoMutationField]: photo,
            },
          },
        },
      });

      const photoResp = photoQuery && (await photoQuery());

      if (!photoResp.data) return;

      const url = photoResp.data.sponsorApplication.sponsorInformation as Partial<UrlT>;

      if (photoResp.data) {
        updateQuery((prev) => ({
          ...prev,
          sponsorApplication: {
            ...prev.sponsorApplication,
            sponsorInformation: {
              ...prev.sponsorApplication.sponsorInformation,
              [name]: photoResp.data?.sponsorApplication[name as keyof typeof url],
            },
          },
        }));
        setErrors((prev) => {
          return {
            ...prev,
            sponsorInformation: {
              ...prev?.sponsorInformation,
              [name]: {
                errMessage: undefined,
                isTouched: true,
              },
            },
          };
        });
      }
    } catch (err) {
      setErrors((prev) => {
        return {
          ...prev,
          sponsorInformation: {
            ...prev?.sponsorInformation,
            [name]: {
              errMessage: (err as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
    }
  };

  const setPhotoError = (error: boolean, name: string) => {
    setErrors((prev) => {
      return {
        ...prev,
        sponsorInformation: {
          ...prev?.sponsorInformation,
          [name]: {
            errMessage: error ? 'Photo is mandatory' : undefined,
            isTouched: true,
          },
        },
      };
    });
  };

  return (
    <Box component="form" autoComplete="off" className={styles.root}>
      <Box className={styles.rowWrapper}>
        <InputField
          className={styles.corporateWidth}
          tName={t('corporate-name')}
          name={'corporateName'}
          disabled={isSubmitted}
          placeholder={t('corporate-name')}
          onBlur={onBlurHandler}
          errorValidation={
            formErrors.corporateName?.isTouched && formErrors.corporateName.errMessage
          }
          defaultValue={sponsorInformation.corporateName}
        />
        <SelectField
          tName={t('country')}
          name={'country'}
          disabled={isSubmitted}
          className={styles.corporateTypeWidth}
          onChangeHandler={(e) => {
            onChangeHandler(e, (value) => value.toLowerCase());
          }}
          value={
            sponsorInformation.country.toUpperCase() ||
            Object.keys(COUNTRIES_AND_COMPANIES)[0].toUpperCase()
          }
          errorValidation={formErrors.country.isTouched && formErrors.country.errMessage}
          menuItems={Object.keys(COUNTRIES_AND_COMPANIES).map((country) => country.toUpperCase())}
        />
      </Box>
      <Box className={styles.rowWrapper}>
        <InputField
          tName={t('street-address')}
          name={'streetAddress'}
          className={styles.corporateWidth}
          disabled={isSubmitted}
          onBlur={onBlurHandler}
          errorValidation={
            formErrors.streetAddress.isTouched && formErrors.streetAddress.errMessage
          }
          defaultValue={sponsorInformation.streetAddress}
        />
        <InputField
          tName={t('apt-suite')}
          name={'apartment'}
          className={styles.aptWidth}
          disabled={isSubmitted}
          onBlur={onBlurHandler}
          errorValidation={formErrors.apartment.isTouched && formErrors.apartment.errMessage}
          defaultValue={sponsorInformation.apartment}
        />
      </Box>
      <Box className={styles.rowWrapper}>
        <InputField
          tName={t('city')}
          name={'city'}
          disabled={isSubmitted}
          className={styles.corporateWidth}
          onBlur={onBlurHandler}
          errorValidation={formErrors.city.isTouched && formErrors.city.errMessage}
          defaultValue={sponsorInformation.city}
        />
        <InputField
          tName={t('state')}
          name={'state'}
          disabled={isSubmitted}
          className={styles.stateWidth}
          onBlur={onBlurHandler}
          errorValidation={formErrors.state.isTouched && formErrors.state.errMessage}
          defaultValue={sponsorInformation.state}
        />
        <InputField
          tName={t('zipcode')}
          name={'zip'}
          disabled={isSubmitted}
          className={styles.stateWidth}
          onBlur={onBlurHandler}
          errorValidation={formErrors.zip.isTouched && formErrors.zip.errMessage}
          defaultValue={sponsorInformation.zip}
        />
        <SelectField
          tName={t('corporation-type')}
          name={'corporateType'}
          disabled={isSubmitted}
          className={styles.countryWidth}
          onChangeHandler={(e) => {
            onChangeHandler(e, (value) => {
              return (
                COUNTRIES_AND_COMPANIES[
                  sponsorInformation.country as keyof typeof COUNTRIES_AND_COMPANIES
                ]?.find(([, val]) => val === value)?.[0] || ''
              );
            });
          }}
          value={
            COUNTRIES_AND_COMPANIES[
              sponsorInformation.country as keyof typeof COUNTRIES_AND_COMPANIES
            ]?.find(([key]) => key === sponsorInformation.corporateType)?.[1] || ''
          }
          errorValidation={
            formErrors.corporateType.isTouched && formErrors.corporateType.errMessage
          }
          menuItems={
            COUNTRIES_AND_COMPANIES[
              sponsorInformation.country as keyof typeof COUNTRIES_AND_COMPANIES
            ]?.map((company) => {
              return company[1];
            }) ?? []
          }
        />
      </Box>
      <Box className={styles.rowWrapper}>
        <InputField
          tName={t('description')}
          name={'description'}
          className={styles.descriptionWidth}
          placeholder={t('description-placeholder')}
          multiline
          maxRows="3"
          disabled={isSubmitted}
          onBlur={onBlurHandler}
          errorValidation={formErrors.description.isTouched && formErrors.description.errMessage}
          defaultValue={sponsorInformation.description}
        />
      </Box>
      <Box className={styles.rowWrapper}>
        <InputField
          tName={t('phone-number')}
          name={'phoneNumber'}
          disabled={isSubmitted}
          className={styles.phoneWidth}
          onBlur={onBlurHandler}
          errorValidation={formErrors.phoneNumber.isTouched && formErrors.phoneNumber.errMessage}
          defaultValue={sponsorInformation.phoneNumber}
        />
        <InputField
          tName={t('website-url')}
          name={'websiteUrl'}
          disabled={isSubmitted}
          className={styles.webWidth}
          onBlur={onBlurHandler}
          errorValidation={formErrors.websiteUrl.isTouched && formErrors.websiteUrl.errMessage}
          defaultValue={sponsorInformation.websiteUrl || ''}
        />
      </Box>
      <Box className={styles.rowWrapper}>
        <InputField
          tName={t('ein')}
          name={'ein'}
          disabled={isSubmitted}
          className={styles.corporateWidth}
          onBlur={onBlurHandler}
          errorValidation={formErrors.ein.isTouched && formErrors.ein.errMessage}
          defaultValue={sponsorInformation.ein}
        />
      </Box>
      <Box className={styles.wrapper}>
        <Typography className={styles.companyLogo}>{t('company-logo')}</Typography>
        <Typography className={styles.description}>{t('provide-two-logos')}</Typography>
        <Box className={styles.row}>
          <Box className={styles.inputWrapper}>
            <AddPhotoInput
              disabled={isSubmitted}
              fieldName={'logoUrlForDarkBackground'}
              state={sponsorInformation.logoUrlForDarkBackground ?? ''}
              onChange={(e) => photoHandler(e, queryDarkPhoto)}
              setError={setPhotoError}
              errorValidation={
                formErrors.logoUrlForDarkBackground.isTouched &&
                formErrors.logoUrlForDarkBackground.errMessage
              }
            />
            <Typography variant="body1">{t('dark-backgrounds')}</Typography>
          </Box>
          <Box className={styles.inputWrapper}>
            <AddPhotoInput
              disabled={isSubmitted}
              fieldName={'logoUrlForLightBackground'}
              state={sponsorInformation.logoUrlForLightBackground}
              onChange={(e) => photoHandler(e, queryLightPhoto)}
              setError={setPhotoError}
              errorValidation={
                formErrors.logoUrlForLightBackground.isTouched &&
                formErrors.logoUrlForLightBackground.errMessage
              }
            />
            <Typography variant="body1">{t('light-backgrounds')}</Typography>
          </Box>
          <Box className={styles.wrapper}>
            <Typography className={styles.headerDescription}>
              {t('tips-effective-photo')}
            </Typography>
            <Typography className={styles.description}>{t('we-require-that-logo')}</Typography>
            <Link href="#" variant="body1">
              {t('click-to-see-list')}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
