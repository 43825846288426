import { createContext } from 'react';
import type { PasswordAuthDataI, UserI } from './types';
import { noop } from '../utils/noop';

interface BaseAuthContextI {
  isLoading: boolean;
  initialLoading: boolean;
}

export interface LoggedInAuthContextI extends BaseAuthContextI {
  user: UserI;
  accessToken: string;
  isLoggedIn: true;
  logOut: () => void;
  restorePassword: () => Promise<void>;
}

export interface LoggedOutAuthContextI extends BaseAuthContextI {
  isLoggedIn: false;
  isFailed: boolean;
  error?: string;
  logIn: (data: PasswordAuthDataI) => void;
  logInViaGoogle: () => void;
  signUp: (data: PasswordAuthDataI) => void;
  clearError: () => void;
  restorePassword: (email: string) => Promise<void>;
}

export type AuthContextT = LoggedInAuthContextI | LoggedOutAuthContextI;

const defaultValue: AuthContextT = {
  isLoggedIn: false,
  isLoading: false,
  initialLoading: true,
  isFailed: false,
  logIn: noop,
  logInViaGoogle: noop,
  signUp: noop,
  clearError: noop,
  restorePassword: () => Promise.resolve(),
};

export const AuthContext = createContext<AuthContextT>(defaultValue);
