import type { FC } from 'react';

import { useCloseAthleteFinancialCard } from 'apolloClient/hooks';
import { useInView } from 'react-intersection-observer';

import { AthleteI } from 'types';

import { HCXFinancialCard } from './hcx-financial-card';
import { HCXFinancialCardProvider } from './hcx-financial-card-context';

export const HCXFinancialCardContainer: FC<{
  athlete: AthleteI;
}> = ({ athlete }) => {
  const { isClosed } = useCloseAthleteFinancialCard(athlete.id);
  const { ref, inView } = useInView({ triggerOnce: true });

  if (!athlete.id || isClosed) return null;

  return (
    <div ref={ref}>
      <HCXFinancialCardProvider athlete={athlete} inView={inView}>
        <HCXFinancialCard />
      </HCXFinancialCardProvider>
    </div>
  );
};
