import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { AddPhotoInput } from 'components/add-photo-input';
import { useTranslation } from 'react-i18next';
import styles from './form-section-verification.module.scss';
import { talentProfileFormValidationAtom } from 'store/talent-application.store';
import {
  TalentApplicationProfileBasicInformation,
  TalentApplicationProfileQuery,
  useUpdateTalentApplicationProfileMutation,
} from 'generated/graphql';
import { useAtom } from 'jotai';
import React from 'react';
import { ApolloError, ApolloQueryResult } from '@apollo/client';

interface FormSectionVerificationPropsI {
  basicInformation: TalentApplicationProfileBasicInformation;
  refetchProfile: () => Promise<ApolloQueryResult<TalentApplicationProfileQuery>>;
}

export const FormSectionVerification: FC<FormSectionVerificationPropsI> = ({
  basicInformation,
  refetchProfile,
}) => {
  const { t } = useTranslation('talent-application');
  const [errorState, setErrorState] = useAtom(talentProfileFormValidationAtom);

  const [updateForm] = useUpdateTalentApplicationProfileMutation();

  const { basicInformation: errors } = errorState;

  const photoHandler = async (event: React.ChangeEvent) => {
    const { name } = event.target as HTMLInputElement;

    const photoMutationField = name.replace('Url', '');
    const photoList = (event.target as HTMLInputElement).files;
    if (!photoList) return;
    const photo = photoList[0];
    (event.target as HTMLInputElement).value = '';
    try {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: undefined,
              isTouched: undefined,
            },
          },
        };
      });
      await updateForm({
        variables: {
          parameters: {
            basicInformation: {
              [photoMutationField]: photo,
              condition: {
                field: photoMutationField,
                state: basicInformation.condition[photoMutationField].state,
              },
            },
          },
        },
        refetchQueries: ['TalentApplicationState'],
      });
      await refetchProfile();
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: undefined,
              isTouched: true,
            },
          },
        };
      });
    } catch (err) {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: (err as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
    }
  };

  const setPhotoError = (error: boolean, name: string) => {
    setErrorState((prev) => {
      return {
        ...prev,
        basicInformation: {
          ...prev?.basicInformation,
          [name]: {
            errMessage: error ? 'Photo is mandatory' : undefined,
            isTouched: true,
          },
        },
      };
    });
  };

  return (
    <Box component="form" autoComplete="off">
      <Typography className={styles.descriptionText}>
        {t('upload-a-picture-of-your-passport-for-id-verification')}
      </Typography>
      <Box className={styles.photoInput}>
        <AddPhotoInput
          fieldName={'passportPhotoUrl'}
          onChange={photoHandler}
          setError={setPhotoError}
          state={basicInformation.passportPhotoUrl}
          errorValidation={errors.passportPhotoUrl.isTouched && errors.passportPhotoUrl.errMessage}
        />
      </Box>
    </Box>
  );
};
