import { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const HandCoinOutlineIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M12 1.5C9.93 1.5 8.25 3.18 8.25 5.25C8.25 7.32 9.93 9 12 9C14.07 9 15.75 7.32 15.75 5.25C15.75 3.18 14.07 1.5 12 1.5ZM12 7.5C10.755 7.5 9.75 6.495 9.75 5.25C9.75 4.005 10.755 3 12 3C13.245 3 14.25 4.005 14.25 5.25C14.25 6.495 13.245 7.5 12 7.5ZM14.25 12H12.75C12.75 11.1 12.1875 10.29 11.3475 9.975L6.7275 8.25H0.75V16.5H5.25V15.42L10.5 16.875L16.5 15V14.25C16.5 13.005 15.495 12 14.25 12ZM3.75 15H2.25V9.75H3.75V15ZM10.4775 15.3075L5.25 13.875V9.75H6.4575L10.8225 11.3775C11.0775 11.475 11.25 11.7225 11.25 12C11.25 12 9.75 11.9625 9.525 11.8875L7.74 11.295L7.2675 12.72L9.0525 13.3125C9.435 13.44 9.8325 13.5 10.2375 13.5H14.25C14.5425 13.5 14.805 13.68 14.925 13.9275L10.4775 15.3075Z"
      fill="white"
    />
  </SvgIcon>
);
