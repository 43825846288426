import type { FC } from 'react';
import { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccordionSection } from 'components/accordion-section';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { DiscordIcon } from 'components/icons/discord-icon';
import { Button } from 'components/button';
import styles from './form-section-social.module.scss';

export const FormSectionSocial: FC = () => {
  const { t } = useTranslation('talent-application');
  const [isTwitter, setIsTwitter] = useState(false);
  const handleTwitterClicked = () => setIsTwitter((prev) => !prev);
  const [isFacebook, setIsFacebook] = useState(false);
  const handleFacebookClicked = () => setIsFacebook((prev) => !prev);
  const [isLinkedin, setIsLinkedin] = useState(false);
  const handleLinkedinClicked = () => setIsLinkedin((prev) => !prev);
  const [isDiscord, setIsDiscord] = useState(false);
  const handleDiscordClicked = () => setIsDiscord((prev) => !prev);

  return (
    <Box component="form" autoComplete="off">
      <AccordionSection
        expand={isTwitter}
        summary={
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1">Twitter</Typography>
            <Box display="flex" gap={2}>
              <TwitterIcon fontSize="medium" className={styles.iconColor} />
              <Switch size="small" />
            </Box>
          </Box>
        }
        handleChangeExpanded={handleTwitterClicked}
        details={
          <Box>
            <Box>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t('share-with-friends')}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t('share-with-followers')}
              />
            </Box>
            <Typography variant="body1" my="30px">
              {t('share-your-trades-with-a-delay')}
            </Typography>
            <Box display="flex" gap="10px" alignItems="center">
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <Button variant="outlined" size="small" onClick={() => {}} disabled>
                {t('save')}
              </Button>
            </Box>
          </Box>
        }
      />
      <AccordionSection
        expand={isFacebook}
        summary={
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1">Facebook</Typography>
            <Box display="flex" gap={2}>
              <FacebookIcon fontSize="medium" className={styles.iconColor} />
              <Switch size="small" />
            </Box>
          </Box>
        }
        handleChangeExpanded={handleFacebookClicked}
        details={
          <Box>
            <Box>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t('share-with-friends')}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t('share-with-followers')}
              />
            </Box>
            <Typography variant="body1" my="30px">
              {t('share-your-trades-with-a-delay')}
            </Typography>
            <Box display="flex" gap="10px" alignItems="center">
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <Button variant="outlined" size="small" onClick={() => {}} disabled>
                {t('save')}
              </Button>
            </Box>
          </Box>
        }
      />
      <AccordionSection
        expand={isLinkedin}
        summary={
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1">LinkedIn</Typography>
            <Box display="flex" gap={2}>
              <LinkedInIcon fontSize="medium" className={styles.iconColor} />
              <Switch size="small" />
            </Box>
          </Box>
        }
        handleChangeExpanded={handleLinkedinClicked}
        details={
          <Box>
            <Box>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t('share-with-friends')}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t('share-with-followers')}
              />
            </Box>
            <Typography variant="body1" my="30px">
              {t('share-your-trades-with-a-delay')}
            </Typography>
            <Box display="flex" gap="10px" alignItems="center">
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <Button variant="outlined" size="small" onClick={() => {}} disabled>
                {t('save')}
              </Button>
            </Box>
          </Box>
        }
      />
      <AccordionSection
        expand={isDiscord}
        summary={
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="body1">Discord</Typography>
            <Box display="flex" gap={2}>
              <DiscordIcon className={styles.iconColor} />
              <Switch size="small" />
            </Box>
          </Box>
        }
        handleChangeExpanded={handleDiscordClicked}
        details={
          <Box>
            <Box>
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t('share-with-friends')}
              />
              <FormControlLabel
                control={<Checkbox size="small" />}
                label={t('share-with-followers')}
              />
            </Box>
            <Typography variant="body1" my="30px">
              {t('share-your-trades-with-a-delay')}
            </Typography>
            <Box display="flex" gap="10px" alignItems="center">
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <Button variant="outlined" size="small" onClick={() => {}} disabled>
                {t('save')}
              </Button>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};
