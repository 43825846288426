import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { useNavigate, matchPath } from 'react-router-dom';

import { Box, IconButton, Typography } from '@mui/material';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';

import { useOpenAccountProfile } from 'apolloClient/hooks';
import { HcxLogo } from 'components/design';
import { ProfileAvatar } from 'components/profile-avatar';
import { useCurrentRouteConfig } from 'hooks/useCurrentRouteConfig';
import { ROUTE } from 'routes/enums';

import { MenuIcon } from '../../design/navigation';
import { LanguagePicker } from '../language-picker';
import { useRenderMenu } from '../trading-info/header-trading-info';

import styles from './header.module.scss';

export const Header: FC = memo(() => {
  const openAccountProfile = useOpenAccountProfile();
  const { isMenuOpen, setMenuOpen } = useRenderMenu();
  const routeConfig = useCurrentRouteConfig();
  const isTalentPortal =
    !!routeConfig && !!matchPath({ path: ROUTE.TalentPortal }, routeConfig.path);
  const navigate = useNavigate();
  const pageTitle =
    (!isTalentPortal ? routeConfig?.altTitle || routeConfig?.title : 'Athlete full name') || '';

  const handleClick = useCallback(() => {
    setMenuOpen(!isMenuOpen);
  }, [setMenuOpen, isMenuOpen]);

  const handleMenuBlur = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const goHome = () => {
    navigate(ROUTE.TradeDesc);
  };

  if (!routeConfig?.isPrivate) return null;

  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.leftSection}>
          <IconButton
            className={`${styles.menuButtonWrapper} ${
              isMenuOpen ? styles.menuButtonWrapper_active : ''
            }`}
            onClick={handleClick}
            onBlur={handleMenuBlur}
          >
            <MenuIcon />
          </IconButton>
          <Box ml={2} mt={1} mr={2} onClick={goHome} className={styles.logo}>
            <HcxLogo className={styles.headerLogo} withDescription />
          </Box>
          <Typography ml={2.75} variant="subtitle1">
            {pageTitle}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <LanguagePicker className={styles.languagePicker} />
          <IconButton className={styles.menuButtonWrapper}>
            <AppsOutlinedIcon />
          </IconButton>
          <ProfileAvatar onClick={openAccountProfile} className={styles.avatar} />
        </Box>
      </Box>
    </>
  );
});
