import { FC } from 'react';
import { PropsI } from './types';
import { TitleRow } from './title-row';
import commonStyles from './common.module.scss';
import { Input } from '../../auth/input';
import { Button } from 'components/button';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import styles from './third-step.module.scss';
import Typography from '@mui/material/Typography';

const SELECT_CLASSES = {
  select: styles.selectSelect,
  icon: styles.selectIcon,
};

const SELECT_MENU_PROPS = {
  classes: {
    paper: styles.selectPaperRoot,
    list: styles.selectMenuList,
  },
};

export const ThirdStep: FC<PropsI<never>> = ({ onSubmit, onBack }) => {
  return (
    <Box>
      <form>
        <Box className={commonStyles.wrapper}>
          <TitleRow title="Phone Number" onBack={onBack} />
          <Box display="flex" className={commonStyles.mt2}>
            <Select
              variant="filled"
              disableUnderline
              className={styles.selectRoot}
              MenuProps={SELECT_MENU_PROPS}
              classes={SELECT_CLASSES}
              value={'ua'}
            >
              <MenuItem value="ua" className={styles.selectMenuItem}>
                +380
              </MenuItem>
              <MenuItem value="us" className={styles.selectMenuItem}>
                +1
              </MenuItem>
              <MenuItem value="ch" className={styles.selectMenuItem}>
                +41
              </MenuItem>
            </Select>
            <Box className={styles.phoneWrapper}>
              <Input placeholder="Phone number" name="phone" className={styles.phoneInput} />
              <Button variant="contained" size="small" className={styles.sendCode}>
                Send Code
              </Button>
            </Box>
          </Box>
          <Typography variant="body2" className={commonStyles.mt2}>
            Message and data rates may apply
          </Typography>
          <Typography variant="subtitle2" className={commonStyles.mt4}>
            SMS Verification code
          </Typography>
          <Typography variant="body2">Enter 6 digit code we sent to your phone number</Typography>
          <Input
            placeholder="Verification code"
            name="phone"
            className={classNames(commonStyles.mt2, commonStyles.wa)}
          />
          <Button onClick={onSubmit} variant="contained" className={commonStyles.mt4}>
            Send
          </Button>
        </Box>
      </form>
    </Box>
  );
};
