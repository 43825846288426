import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { CircularProgress } from '@mui/material';
import Rating, { RatingProps } from '@mui/material/Rating';

import { useFavorizer } from './athlete-favorite-star.hooks';

import { AthleteI } from 'types';

import styles from './athlete-favorite-star.module.scss';

const onClickFavorite: RatingProps['onClick'] = (e) => {
  e.stopPropagation();
};

export const AthleteFavoriteStar: FC<{
  id: AthleteI['id'];
  className?: string;
  size?: RatingProps['size'];
}> = ({ id, className, size = 'small' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { favoriteAthletes, unfavoriteAthlete, favoriteAthlete } = useFavorizer(`${id}`);

  const isFavorite = useMemo(() => favoriteAthletes.includes(`${id}`), [favoriteAthletes, id]);

  const handleToggleFavorite: RatingProps['onChange'] = useCallback(
    async (e) => {
      setIsLoading(true);
      e.stopPropagation();
      if (isFavorite) {
        await unfavoriteAthlete();
      } else {
        await favoriteAthlete();
      }
      setIsLoading(false);
    },
    [isFavorite, unfavoriteAthlete, favoriteAthlete]
  );

  return (
    <>
      {isLoading ? (
        <CircularProgress size={18} className={styles.loader} />
      ) : (
        <Rating
          max={1}
          value={isFavorite ? 1 : 0}
          className={className}
          onChange={handleToggleFavorite}
          onClick={onClickFavorite}
          size={size}
        />
      )}
    </>
  );
};
