import { FC } from 'react';
import { BaseModal, BaseModalPropsI } from 'components/modals/base-modal';
import { Link } from '@mui/material';
import { Button } from 'components/button';

import styles from './talent-portal-welcome-modal.module.scss';

const modalClasses = {
  paper: styles.root,
};

const backdropProps: BaseModalPropsI['BackdropProps'] = {
  classes: {
    root: styles.backdrop,
  },
};

type TalentPortalWelcomeModalPropsT = Pick<BaseModalPropsI, 'open' | 'onClose'>;

const TalentPortalWelcomeModal: FC<TalentPortalWelcomeModalPropsT> = ({ open, onClose }) => {
  return (
    <BaseModal
      onClose={onClose}
      open={open}
      classes={modalClasses}
      BackdropProps={backdropProps}
      className={styles.modal}
      disableScrollLock={false}
      fullWidth
      actions={
        <div className={styles.actions}>
          <Link href="#">Learn more</Link>
        </div>
      }
    >
      <div className={styles.contentWrapper}>
        <header className={styles.title}>Welcome</header>
        <p className={styles.text}>As a HCX Member, you can buy and sell the talent you love!</p>
        <ul className={styles.text}>
          <li>Lorem ipsum dolor sit amet, consectetur elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur elit.</li>
          <li>Lorem ipsum dolor sit amet, consectetur elit.</li>
        </ul>
        <div className={styles.goPublicWrapper}>
          <span className={styles.text}>
            Take your self public on HCX - so others can invest in your success.
          </span>
          <Button variant="contained" size="small" className={styles.goPublicBtn}>
            Go Public
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default TalentPortalWelcomeModal;
