import { FC } from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { formatToUSD } from 'utils/currency-formatter';

import { AthleteTradingInfoPropsI } from './athlete-trading-info.types';

import styles from './athlete-trading-info.module.scss';

const AthleteTradingInfo: FC<AthleteTradingInfoPropsI> = ({ price, info, priceSize }) => {
  return (
    <Box className={styles.root}>
      <Box className={styles.infoItem}>
        <Typography
          className={classNames(styles.price, { [styles.priceSmall]: priceSize === 'small' })}
        >
          {typeof price.value === 'string' ? price.value : formatToUSD(price.value)}
        </Typography>
        <Typography
          className={classNames(styles.progress, {
            [styles.progressUp]: price.progressAmount > 0,
            [styles.progressDown]: price.progressAmount < 0,
          })}
        >
          {typeof price.progressAmount === 'string'
            ? price.progressAmount
            : formatToUSD(price.progressAmount, { signDisplay: 'exceptZero' })}
        </Typography>
      </Box>
      {info.map(({ title, value, isCurrency }) => (
        <Box key={`${title}-${value}`} className={styles.infoItem}>
          <Typography className={styles.infoValue}>
            {!isCurrency && value}
            {isCurrency && (typeof value === 'string' ? value : formatToUSD(+value))}
          </Typography>
          <Typography className={styles.infoTitle}>{title}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default AthleteTradingInfo;
