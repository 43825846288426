import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

import { WidgetNoItems } from '../widget-noItems';
import { WidgetWrapper } from '../widget-wrapper';
import { TrendingWidgetContext } from './context';
import { TrendingList } from './trending-list';
import { HcxCircleSmallIcon } from 'components/icons/hcx-circle-small-icon';
import { Typography } from '@mui/material';

export const Trending: FC<{ onCloseWidget: () => void }> = ({ onCloseWidget }) => {
  const { t } = useTranslation('trade-desk');
  return (
    <WidgetWrapper
      title={t('trending')}
      titleIcon={<LocalFireDepartmentIcon />}
      onCloseWidget={onCloseWidget}
      context={TrendingWidgetContext}
    >
      <TrendingList
        noItems={
          <WidgetNoItems
            text={<Typography variant="body2">{t('no-trending-found')}</Typography>}
            icon={<HcxCircleSmallIcon sx={{ width: '60px', height: '60px' }} />}
          />
        }
      />
    </WidgetWrapper>
  );
};
