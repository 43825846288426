import type { FC } from 'react';

interface PropsI {
  className?: string;
  color?: string;
}

export const MenuIcon: FC<PropsI> = ({ className, color = 'currentColor' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.25 4.5H15.75V6H2.25V4.5ZM2.25 8.25H15.75V9.75H2.25V8.25ZM2.25 12H15.75V13.5H2.25V12Z"
      fill={color}
    />
  </svg>
);
