import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const HcxCircleSmallIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 60 60">
    <circle cx="30" cy="30" r="27" fill="#161A24" stroke="#1B202D" strokeWidth="6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.3753 18.0096L41.3438 18H39.7732L23.4203 41.3993L23.4198 41.4H25.0285L25.029 41.3993L41.3753 18.0096ZM37.2424 20.7611H35.6335L21.2104 41.3993L21.2099 41.4H22.8186L22.8191 41.3993L37.2424 20.7611ZM34.2125 19.6317H35.8212L20.6087 41.3993L20.6083 41.4L19 41.3993L34.2125 19.6317ZM29.9893 24.814L25.5552 18H19.5084L27.0456 29.0261L29.9893 24.814ZM30.7847 34.0245L33.736 29.8016L41.68 41.4H35.6333L30.7847 34.0245Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.021 24.814L25.5869 18H19.54L27.0773 29.0261L30.021 24.814Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7677 29.8018L30.8164 34.0247L35.665 41.4002H41.7116L33.7677 29.8018Z"
      fill="white"
    />
  </SvgIcon>
);
