import { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const BadgeNotCheckedIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <circle cx="8" cy="8" r="7.5" fill="white" stroke="#555F7C" />
    <circle cx="8" cy="8" r="5" fill="white" />
  </SvgIcon>
);
