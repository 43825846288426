import { AthleteCareerStatI, TableColumnConfigI } from 'types';
import { formatNumber } from 'utils/number-formatter';
import { Trans } from 'react-i18next';

export const ATHLETE_SUMMARY_STATS_COLUMNS_CONFIG: TableColumnConfigI<AthleteCareerStatI>[] = [
  {
    title: <Trans i18nKey="gp" ns="hcx-card" />,
    dataKey: 'gp',
    renderValue: (data) => formatNumber(data.gp),
  },
  {
    title: <Trans i18nKey="rec" ns="hcx-card" />,
    dataKey: 'rec',
    renderValue: (data) => formatNumber(data.rec),
  },
  {
    title: <Trans i18nKey="yds" ns="hcx-card" />,
    dataKey: 'yds',
    renderValue: (data) => formatNumber(data.yds),
  },
  {
    title: <Trans i18nKey="avg" ns="hcx-card" />,
    dataKey: 'avg',
    renderValue: (data) => formatNumber(data.avg),
  },
  {
    title: <Trans i18nKey="td" ns="hcx-card" />,
    dataKey: 'td',
    renderValue: (data) => formatNumber(data.td),
  },
  {
    title: <Trans i18nKey="fum" ns="hcx-card" />,
    dataKey: 'fum',
    renderValue: (data) => formatNumber(data.fum),
  },
  {
    title: <Trans i18nKey="lst" ns="hcx-card" />,
    dataKey: 'lst',
    renderValue: (data) => formatNumber(data.lst),
  },
];
