import type { FC } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Button } from 'components/button';
import { ActivityPanelIcon } from 'components/icons/activity-panel-icon';
import { Close } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { sponsorManagementView, invitePanel } from 'store/sponsor-management.store';
import { useTranslation } from 'react-i18next';
import { useResetAtom } from 'jotai/utils';
import styles from './sponsor-management-header.module.scss';
import { sponsorFormValidationAtom } from 'store/sponsor-application.store';

export const SponsorManagementHeader: FC<{
  openInvite: () => void;
  openActivity: () => void;
  viewIndex: number;
}> = ({ openInvite, openActivity, viewIndex }) => {
  const [, setPanelOpen] = useAtom(sponsorManagementView);
  const [, setInviteView] = useAtom(invitePanel);
  const resetFormErrors = useResetAtom(sponsorFormValidationAtom);
  const { t } = useTranslation('sponsor-management');

  const handleClosePanel = () => {
    setPanelOpen(false);
    resetFormErrors();
    setInviteView(false);
  };
  const isTabMembers = viewIndex === 0;

  return (
    <Box className={styles.root}>
      <Typography className={styles.headerTitle}>{t('sponsor-management')}</Typography>
      <Box>
        {isTabMembers && (
          <Button onClick={openInvite} variant="contained" size="small">
            {t('invite-member')}
          </Button>
        )}
        <IconButton className={styles.activityButton} onClick={openActivity}>
          <ActivityPanelIcon />
        </IconButton>
        <IconButton className={styles.closeButton} onClick={handleClosePanel}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
