import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { HCXTooltip } from 'components/tooltip';
import styles from './account-profile-settings-header.module.scss';

const AccountProfileSettingsHeader: FC<{
  title: string;
  info?: string;
  onClose: () => void;
  infoContainer?: Element | null;
}> = ({ title, info, onClose, infoContainer }) => {
  const { t } = useTranslation('account-profile');
  return (
    <div className={styles.root}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{t(title)}</span>
        {!!info && (
          <HCXTooltip
            title={t(info)}
            placement="bottom-start"
            PopperProps={{ container: infoContainer }}
          >
            <HelpOutlineIcon className={styles.helpIcon} />
          </HCXTooltip>
        )}
      </div>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default AccountProfileSettingsHeader;
