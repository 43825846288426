import { LocaleE } from 'enums';

interface NumberFormatterOptionsI {
  locale?: LocaleE;
  compact?: boolean;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
  minimumFractionDigits?: number;
}

export enum NormalizationLogicE {
  NonDecimal,
  Decimal,
}

export enum NormalizationDecimalsE {
  Two,
  Six,
}

export const compactConfig: Intl.NumberFormatOptions = {
  notation: 'compact',
  compactDisplay: 'short',
};

const generateNumberFormatter = ({
  locale = LocaleE.en_US,
  compact,
  signDisplay = 'auto',
  minimumFractionDigits = 0,
}: NumberFormatterOptionsI): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits,
    signDisplay,
    ...(compact ? compactConfig : {}),
  });

export const formatNumber = (value: number, options: NumberFormatterOptionsI = {}) =>
  generateNumberFormatter(options).format(value);

export const round = (num: number, signs = 2) => {
  const signsDelta = Math.pow(10, signs);
  const m = Number((Math.abs(num) * signsDelta).toPrecision(15));
  return (Math.round(m) / signsDelta) * Math.sign(num);
};

export const normalizeDecimals = (value: number, decimals: NormalizationDecimalsE) => {
  if (decimals === NormalizationDecimalsE.Six) {
    return {
      [NormalizationLogicE.NonDecimal]: value / 10 ** 6,
      [NormalizationLogicE.Decimal]: value * 10 ** 6,
    };
  }
  return {
    [NormalizationLogicE.NonDecimal]: value / 10 ** 2,
    [NormalizationLogicE.Decimal]: value * 10 ** 2,
  };
};
