import { FC, ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import styles from './base-modal.module.scss';

export interface BaseModalPropsI extends Omit<DialogProps, 'title' | 'onClose'> {
  title?: ReactNode;
  onClose?: () => void;
  actions?: ReactNode;
  titleClassName?: string;
  disableCloseOnBackdrop?: boolean;
}

const BaseModal: FC<BaseModalPropsI> = ({
  children,
  title,
  onClose,
  actions,
  titleClassName,
  className,
  disableCloseOnBackdrop = true,
  ...props
}) => {
  const ariaLabelledby = props['aria-labelledby'] || 'dialog-title';
  const ariaDescribedby = props['aria-describedby'] || 'dialog-description';

  const resultPaperProps: DialogProps['PaperProps'] = useMemo(
    () => ({
      className: classNames(styles.root, className),
    }),
    [className]
  );

  const handleClose: DialogProps['onClose'] = useCallback(
    (_, reason) => {
      if (onClose && reason === 'backdropClick' && disableCloseOnBackdrop) return;
      onClose?.();
    },
    [onClose, disableCloseOnBackdrop]
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
      PaperProps={resultPaperProps}
      {...props}
    >
      <DialogTitle id={ariaLabelledby} className={styles.title}>
        {title}
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} className={styles.close}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={styles.content} id={ariaDescribedby}>
        {children}
      </DialogContent>
      {!!actions && <DialogActions className={styles.actions}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default BaseModal;
