import { getAthleteTokenBalance, WalletE } from 'blockchain-api/ewallets-api';

import { TradingPair } from 'generated/graphql';
import { TradingPairWithBalanceI } from './types';

export const constructPortfolioViewModel = async (
  tradingPairs: TradingPair[]
): Promise<TradingPairWithBalanceI[]> => {
  const tradingPairsWithBalance: TradingPairWithBalanceI[] = [];

  for (let i = 0; i < tradingPairs.length; i++) {
    const tradingPair = tradingPairs[i];
    const balance = await getAthleteTokenBalance(tradingPair.tokenAddress, WalletE.Metamask);
    if (balance > 0) {
      tradingPairsWithBalance.push({
        ...tradingPair,
        quantity: balance,
        currentValue: balance * (tradingPair.stats?.lastPrice ?? 0),
      });
    }
  }

  return tradingPairsWithBalance;
};
