import { useEffect, useState } from 'react';

import { DutchAuctionStatus } from 'generated/graphql';

const DAYS_CONSTANT = 1000 * 60 * 60 * 24;
const HOURS_CONSTANT = 1000 * 60 * 60;
const MINUTES_CONSTANT = 1000 * 60;
const SECONDS_CONSTANT = 1000;

export const useTimeDiffString = (endDate: Date, mintingStatus: DutchAuctionStatus) => {
  const [remainingTime, setRemainingTime] = useState('0 days 00 hours 00 mins');

  useEffect(() => {
    let interval: NodeJS.Timer | null = null;
    if (mintingStatus === DutchAuctionStatus.Active) {
      interval = setInterval(() => {
        const diffMS = endDate.getTime() - new Date().getTime();
        const days = Math.floor(diffMS / DAYS_CONSTANT);
        const hours = Math.floor((diffMS % DAYS_CONSTANT) / HOURS_CONSTANT);
        const minutes = Math.floor((diffMS % HOURS_CONSTANT) / MINUTES_CONSTANT);
        const seconds = Math.floor((diffMS % MINUTES_CONSTANT) / SECONDS_CONSTANT);
        setRemainingTime(`${days} days ${hours} hours ${minutes} mins ${seconds}`);
      }, 1000);
    } else {
      setRemainingTime('0 days 00 hours 00 mins');
    }
    return () => {
      if (interval) {
        return clearInterval(interval);
      }
    };
  }, [endDate, mintingStatus, setRemainingTime]);

  return [remainingTime];
};
