import type { FC } from 'react';
import { useMemo, useContext, ReactNode } from 'react';

import { useAtom } from 'jotai';

import {
  WidgetAthleteListLarge,
  WidgetAthleteListMedium,
  WidgetAthleteListSmall,
} from 'pages/trade-desk/widget-athlete-list';

import { useAthletes } from 'apolloClient/hooks';

import { favoriteAthleteIdsAtom } from 'store/favorites.store';
import { FavoritesWidgetContext } from '../context';

import { WidgetCardSizeE } from 'pages/trade-desk/widget-wrapper';
import { AthleteI } from 'types';

export const FavoritesList: FC<{ noItems?: ReactNode }> = ({ noItems }) => {
  const [favoriteAthletesState] = useAtom(favoriteAthleteIdsAtom);
  const athletes = useAthletes();
  const { cardSize, progressType } = useContext(FavoritesWidgetContext);

  const favoriteAthletes = useMemo(() => {
    const filteredAthletes: AthleteI[] = [];

    if (!athletes.length) {
      return filteredAthletes;
    }

    favoriteAthletesState.forEach((athleteId) => {
      const tempAthlete = athletes.filter((athlete) => athlete.id === +athleteId);

      filteredAthletes.push(tempAthlete[0]);
    });
    return filteredAthletes;
  }, [favoriteAthletesState, athletes]);

  return favoriteAthletes.length && athletes.length ? (
    <>
      {cardSize === WidgetCardSizeE.Large && (
        <WidgetAthleteListLarge athletes={favoriteAthletes} progressType={progressType} />
      )}
      {cardSize === WidgetCardSizeE.Small && <WidgetAthleteListSmall athletes={favoriteAthletes} />}
      {cardSize === WidgetCardSizeE.Medium && (
        <WidgetAthleteListMedium athletes={favoriteAthletes} progressType={progressType} />
      )}
    </>
  ) : (
    <>{noItems}</>
  );
};
