import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountProfileChangePassword } from './account-profile-change-password';
import { AccountProfileSecuritySection } from './account-profile-security-section';
import { AccountProfileTFA } from './account-profile-tfa';

const SECTIONS: {
  title: string;
  disabled?: true;
  component?: FC;
}[] = [
  {
    title: 'change-password',
    component: AccountProfileChangePassword,
  },
  {
    title: 'two-factor-authentication',
    disabled: true,
    component: AccountProfileTFA,
  },
  {
    title: 'enable-third-party-authentication-providers',
  },
  {
    title: 'withdrawal-password',
    disabled: true,
  },
  {
    title: 'withdrawal-destination-whitelisting',
    disabled: true,
  },
  {
    title: 'custom-logins',
  },
  {
    title: 'login-history',
  },
  {
    title: 'require-email-approval-on-new-device',
  },
  {
    title: 'logout-of-all-sessions',
  },
  {
    title: 'perform-account-security-reset',
  },
  {
    title: 'close-account',
  },
];

const AccountProfileSecurity: FC = () => {
  const { t } = useTranslation('account-profile');
  const [openedId, setOpenedId] = useState('');

  return (
    <>
      {SECTIONS.map(({ title, disabled, component: Component }) => (
        <AccountProfileSecuritySection
          key={title}
          title={t(title)}
          disabled={disabled}
          onChangeExpand={setOpenedId}
          id={title}
          expandedId={openedId}
        >
          {Component ? <Component /> : null}
        </AccountProfileSecuritySection>
      ))}
    </>
  );
};

export default AccountProfileSecurity;
