import type { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { GET_ATHLETE_LAST_PRICE_CONFIG } from 'components/athlete-trading-info';
import { AthleteBidBuySellBtn } from 'components/athlete/athlete-bid_buy_sell-btn';
import { useTradingPairStatsQuery } from 'generated/graphql';
import { WidgetProgressE } from 'pages/trade-desk/widget-wrapper';
import { AthleteI } from 'types';
import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';

import styles from './widget-athlete-list-large-buy_sell-block.module.scss';

export const WidgetAthleteListLargeBuySellBlock: FC<{
  athlete: AthleteI;
  progressType: WidgetProgressE;
}> = ({ athlete, progressType }) => {
  const { data: tradingPairStats } = useTradingPairStatsQuery({
    variables: { input: { id: athlete.tradingInfo.tradingPairId ?? '0' } },
  });

  if (!tradingPairStats?.tradingPairs[0]?.stats) {
    return null;
  }

  const {
    contractTerm: { start, end },
  } = athlete.tradingInfo;

  const {
    value: price,
    progressAmount,
    progressPercentage,
  } = GET_ATHLETE_LAST_PRICE_CONFIG(tradingPairStats.tradingPairs[0].stats);

  return (
    <Box className={styles.root}>
      <span>{typeof price === 'string' ? price : formatToUSD(price)}</span>
      <span
        className={classNames({
          [styles.up]: progressAmount > 0,
          [styles.down]: progressAmount < 0,
        })}
      >
        {typeof progressAmount === 'number' &&
          typeof progressPercentage === 'number' &&
          (progressType === WidgetProgressE.Currency
            ? formatToUSD(progressAmount, { signDisplay: 'exceptZero' })
            : `${formatNumber(progressPercentage, { signDisplay: 'exceptZero' })}%`)}

        {typeof progressAmount === 'string' &&
          typeof progressPercentage === 'string' &&
          (progressType === WidgetProgressE.Currency ? progressAmount : progressPercentage)}
      </span>
      <span className={styles.term}>
        Term: {new Date(start).getFullYear()} - {new Date(end).getFullYear()}
      </span>
      <AthleteBidBuySellBtn athlete={athlete} className={styles.action} />
    </Box>
  );
};
