import type { FC, SyntheticEvent } from 'react';
import { useRef, useState } from 'react';
import { Box, Slide } from '@mui/material';
import { Panel } from 'components/panel';
import { Tabs } from 'components/tabs';
import { useAtom } from 'jotai';
import { talentPanelView } from 'store/talent-application.store';
import { TalentHeader } from './talent-header';
import { SponsorSearch } from './tab-sponsor-search';
import {
  TalentApplicationProfileState,
  TalentApplicationState,
  TalentApplicationStatus,
  useTalentApplicationStateQuery,
  useTalentApplicationStatusQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { TalentLearnMore } from './talent-learn-more';
import { TABS_LIST } from './tabs-list-config';
import styles from './talent-application.module.scss';

const badgeState = (props: string[], state: TalentApplicationState | undefined = undefined) => {
  if (state === undefined) return false;
  if (props.length === 1) {
    return !!state[props[0] as keyof TalentApplicationState];
  }
  return props.reduce(
    (prev, prop) => prev && !!state.profile?.[prop as keyof TalentApplicationProfileState],
    true
  );
};

export const TalentApplication: FC = () => {
  const [isTalentPanelOpen] = useAtom(talentPanelView);
  const [viewIndex, setViewIndex] = useState(0);

  const { data: talentState } = useTalentApplicationStateQuery();
  const { data: talentStatus } = useTalentApplicationStatusQuery();

  const { t } = useTranslation('talent-application');

  const wrapperRef = useRef(null);

  const setTab = (e: SyntheticEvent, ind: number) => setViewIndex(ind);

  const isStatusInitiated =
    talentStatus?.talentApplication.status === TalentApplicationStatus.Initiated;

  const isStatusUndefined =
    talentStatus?.talentApplication.status === undefined ||
    talentStatus?.talentApplication.status === TalentApplicationStatus.Canceled;
  const Component = TABS_LIST[viewIndex].component;

  return (
    <Slide
      direction="left"
      in={isTalentPanelOpen}
      container={wrapperRef.current}
      timeout={300}
      easing={{
        enter: 'ease-out',
        exit: 'linear',
      }}
    >
      <Box className={styles.root}>
        {isStatusUndefined && <TalentLearnMore />}
        {isStatusInitiated && (
          <>
            <TalentHeader />
            <Box className={styles.headerWrapper}>
              <Tabs
                value={viewIndex}
                onChange={setTab}
                indicatorClassName={styles.indicatorSpan}
                tabs={TABS_LIST.map((tab) => ({
                  name: tab.name,
                  filled: badgeState(tab.filled, talentState?.talentApplication.state),
                }))}
                tabsStyles={styles.tabStyles}
                tabStyle={styles.tab}
                transformLabel={t}
              />
              {viewIndex === 2 && <SponsorSearch />}
            </Box>
            <Panel height="100%" p="15px">
              {Component ? <Component /> : null}
            </Panel>
          </>
        )}
      </Box>
    </Slide>
  );
};
