import { FC } from 'react';
import { Box } from '@mui/material';
import { AthleteI } from 'types';
import {
  AthleteCardContainer,
  AthleteCardGradient,
  AthleteCardResultsLine,
} from 'components/athlete/athlete-card';
import { WidgetProgressE } from 'pages/trade-desk/widget-wrapper';
import { WidgetAthleteListLargeBuySellBlock } from './widget-athlete-list-large-buy_sell-block';
import styles from './widget-athlete-list-large.module.scss';

export const WidgetAthleteListSmall: FC<{
  athletes: AthleteI[];
  progressType: WidgetProgressE;
}> = ({ athletes, progressType }) => {
  return (
    <Box className={styles.root}>
      {athletes.map((athlete) => (
        <Box key={athlete.id} className={styles.card}>
          <AthleteCardGradient>
            <AthleteCardContainer athlete={athlete} />
          </AthleteCardGradient>
          <WidgetAthleteListLargeBuySellBlock athlete={athlete} progressType={progressType} />
          <AthleteCardResultsLine />
        </Box>
      ))}
    </Box>
  );
};
