import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { AccordionSection } from 'components/accordion-section';
import { ActivityPanelIcon } from 'components/icons/activity-panel-icon';
import { Button } from 'components/button';
import { InputField } from 'components/input-field';
import moment from 'moment';
import { useTalentApplicationOfferingParametersQuery } from 'generated/graphql';
import styles from './offering-parameters.module.scss';

export const OfferingParameters = () => {
  const { t } = useTranslation('talent-application');
  const [isExpanded, setIsExpanded] = useState({
    schedule: true,
    percent: true,
    source: false,
    party: false,
    commisions: false,
  });

  const { data: offeringData } = useTalentApplicationOfferingParametersQuery();

  const handleChangeExpanded = (
    name: 'schedule' | 'percent' | 'source' | 'party' | 'commisions'
  ) => {
    setIsExpanded((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  };

  return (
    <Box>
      <Typography className={styles.description}>
        {t('the-offering-parameters-define-your-ipo-and-answer-key-questions')}
      </Typography>
      <Box>
        <AccordionSection
          expand={isExpanded.schedule}
          handleChangeExpanded={() => handleChangeExpanded('schedule')}
          summary={<Typography>{t('offering-schedule')}</Typography>}
          details={
            <Box>
              <Box display="flex" alignItems="flex-start" gap={2}>
                <ActivityPanelIcon />
                <Box>
                  <Typography variant="body1">{t('start-date-and-time')}</Typography>
                  <Typography variant="body2" mt={0.5}>
                    {t('this-is-the-date-and-time-that-shares-of-your-offering')}
                  </Typography>
                  <Button variant="outlined" size="small" className={styles.button}>
                    <Typography variant="body2">
                      {moment(offeringData?.talentApplication.offeringParameters.startDate).format(
                        'DD/MM/YYYY h:mm a'
                      )}
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box display="flex" alignItems="flex-start" gap={2}>
                <ActivityPanelIcon />
                <Box>
                  <Typography variant="body1">{t('offering-duration')}</Typography>
                  <Typography variant="body2" mt={0.5}>
                    {t('this-is-the-date-and-time-that-the-offering-will-close')}
                  </Typography>
                  <Box display="flex">
                    <Button variant="outlined" size="small" className={styles.button}>
                      <Typography variant="body2">
                        {moment(
                          offeringData?.talentApplication.offeringParameters.startDate
                        ).format('DD/MM/YYYY h:mm a')}
                      </Typography>
                    </Button>
                    <Button variant="outlined" size="small" disabled className={styles.button}>
                      <Typography variant="body2">
                        {moment(offeringData?.talentApplication.offeringParameters.endDate).format(
                          'DD/MM/YYYY h:mm a'
                        )}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        />
        <AccordionSection
          expand={isExpanded.percent}
          handleChangeExpanded={() => handleChangeExpanded('percent')}
          summary={
            <Typography>
              {t('percent-of-future-income-share-count-pricing-andp-proceeds')}
            </Typography>
          }
          details={
            <Box>
              <Box className={styles.inputRow}>
                <Typography variant="body2" mr="20px">
                  {t('future-income-projected-in-your-financial-model')}
                </Typography>
                <InputField
                  tName={''}
                  value={offeringData?.talentApplication.offeringParameters.futureIncome}
                  className={styles.widthIncome}
                />
                <InfoIcon className={styles.infoIcon} />
              </Box>
              <Box className={styles.inputRow}>
                <Typography variant="body2" mr="20px">
                  {t('future-income-shared-under-this-offering')}
                </Typography>
                <InputField
                  tName={''}
                  value={offeringData?.talentApplication.offeringParameters.futureIncomeShared}
                  className={styles.widthPersent}
                />
                <Typography className={styles.infoDescription}>
                  {t('projected to be $ 1,255,200 in future dividends')}
                </Typography>
                <InfoIcon className={styles.infoIcon} />
              </Box>
              <Box className={styles.inputRow}>
                <Typography variant="body2" mr="20px">
                  {t('minimum-gross-proceeds-of-this-offering')}
                </Typography>
                <InputField
                  tName={''}
                  value={offeringData?.talentApplication.offeringParameters.minimumGross}
                  className={styles.widthNumber}
                />
                <InfoIcon className={styles.infoIcon} />
              </Box>
              <Box className={styles.inputRow}>
                <Typography variant="body2" mr="20px">
                  {t('number-of-shares-being-offered')}
                </Typography>
                <InputField
                  tName={''}
                  value={offeringData?.talentApplication.offeringParameters.numberOfShares}
                  className={styles.widthNumber}
                />
                <InfoIcon className={styles.infoIcon} />
              </Box>
              <Box className={styles.inputRow}>
                <Typography variant="body2" mr="20px">
                  {t('minimum-share-price')}
                </Typography>
                <InputField
                  tName={''}
                  value={offeringData?.talentApplication.offeringParameters.minimumSharePrice}
                  className={styles.widthNumber}
                />
                <InfoIcon className={styles.infoIcon} />
              </Box>
            </Box>
          }
        />
        <AccordionSection
          expand={isExpanded.source}
          handleChangeExpanded={() => handleChangeExpanded('source')}
          summary={<Typography>{t('sources-and-uses-of-funds')}</Typography>}
        />
        <AccordionSection
          expand={isExpanded.party}
          handleChangeExpanded={() => handleChangeExpanded('party')}
          summary={<Typography>{t('3rd-party-exchange-allocations')}</Typography>}
        />
        <AccordionSection
          expand={isExpanded.commisions}
          handleChangeExpanded={() => handleChangeExpanded('commisions')}
          summary={<Typography>{t('ipo-commisions-party-fees')}</Typography>}
        />
      </Box>
    </Box>
  );
};
