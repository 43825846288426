import { FC, useEffect } from 'react';
import { useCurrentRouteConfig } from 'hooks/useCurrentRouteConfig';
import { useIsAccountProfileOpened } from 'apolloClient/hooks';
import AccountProfile from './account-profile';

const AccountProfileContainer: FC = () => {
  const isOpened = useIsAccountProfileOpened();
  const routeConfig = useCurrentRouteConfig();
  const isVisible = isOpened && !!routeConfig?.isPrivate;

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isVisible]);

  if (!isOpened) {
    return null;
  }
  return <AccountProfile isOpened={isOpened} />;
};

export default AccountProfileContainer;
