import auth0, { Auth0Callback } from 'auth0-js';
import { config } from 'config';
import { promisify } from '../utils/promisify';
import type { PasswordAuthDataI } from './types';

const authConfig = {
  domain: config.auth0Domain,
  clientID: config.auth0ClientId,
  audience: config.auth0Audience,
};

if (!authConfig.domain || !authConfig.clientID || !authConfig.audience) {
  throw new Error(
    'Please configure REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_AUDIENCE and REACT_APP_AUTH0_CLIENT_ID env vars and restart the app'
  );
}

const PASSWORD_AUTH_CONNECTION = 'Username-Password-Authentication';
const GOOGLE_AUTH_CONNECTION = 'google-oauth2';

export const createAuthApi = () => {
  const auth = new auth0.WebAuth(authConfig);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateToken = promisify((token: string, cb: Auth0Callback<any>) =>
    auth.validateToken(token, '', cb)
  );
  const getUserInfo = promisify(auth.client.userInfo, auth.client);

  const logIn = promisify(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ email, password }: PasswordAuthDataI, cb: Auth0Callback<any>) =>
      auth.client.login(
        {
          username: email,
          password,
          realm: PASSWORD_AUTH_CONNECTION,
          scope: 'openid',
        },
        cb
      ),
    auth.client
  );

  const logInWithGoogle = () =>
    auth.authorize({
      domain: authConfig.domain,
      audience: authConfig.audience,
      connection: GOOGLE_AUTH_CONNECTION,
      responseType: 'token',
      redirectUri: window.location.origin,
    });

  const logOut = () => {
    auth.logout({ returnTo: window.location.origin });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const signUp = promisify((data: PasswordAuthDataI, cb: Auth0Callback<any>) =>
    auth.signup({ ...data, connection: PASSWORD_AUTH_CONNECTION }, cb)
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const restorePassword = promisify((email: string, cb: Auth0Callback<any>) => {
    auth.changePassword({ email, connection: PASSWORD_AUTH_CONNECTION }, cb);
  });

  return {
    validateToken,
    getUserInfo,
    logIn,
    logInWithGoogle,
    logOut,
    signUp,
    restorePassword,
  };
};

export type AuthApiT = ReturnType<typeof createAuthApi>;
