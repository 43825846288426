import { FC } from 'react';
import { HcxLogo, Icon404 } from 'components/design';
import { EmptyStateBlock } from 'components/empty-state-block';
import { useNavigate } from 'react-router-dom';

import styles from './404.module.scss';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Page404: FC = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate('/', { replace: true });
  };
  const { t } = useTranslation('404');
  return (
    <Box className={styles.root}>
      <HcxLogo className={styles.logo} />
      <EmptyStateBlock
        icon={<Icon404 />}
        description={t('not-found')}
        actionBtnTitle={t('go-back-home')}
        onActionBtnClick={goHome}
      />
    </Box>
  );
};

export default Page404;
