import type { FC } from 'react';

import { LifetimeRevenue } from './components';

export const TABS: { label: string; component: FC | null }[] = [
  { label: 'Investors', component: null },
  { label: 'Lifetime Revenue', component: LifetimeRevenue },
  { label: 'Volume', component: null },
  { label: 'Tokens', component: null },
  { label: 'Market Cap', component: null },
];

export const tabLabels = TABS.map(({ label }) => label);
