import { LimitOrder, SignatureType } from '@0x/protocol-utils';
import { MetamaskSubprovider } from '@0x/subproviders';
import { BigNumber } from '@0x/utils';
import { ApolloCache, DefaultContext, MutationFunctionOptions } from '@apollo/client';

import { Exact, InputMaybe, NewOrderMutation } from 'generated/graphql';

import { config } from 'config';
import { NULL_ADDRESS } from '../constants';
import { WalletE, getWalletProvider } from '../ewallets-api';
import { requestApprove } from '../request-approve';
import { getSubprovider } from './get-subprovider';

const getExpiry = (date: Date) => new BigNumber(Math.floor(+date / 1000).toString());

interface CreateLimitOrderPropsI {
  wallet: WalletE;
  stableCoin: string;
  tokenCoin: string;
  makerToken: string;
  takerToken: string;
  makerAmount: number;
  takerAmount: number;
  expiry: Date;
  newOrder: (
    options?:
      | MutationFunctionOptions<
          NewOrderMutation,
          Exact<{ order?: InputMaybe<LimitOrder> | undefined }>,
          DefaultContext,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ApolloCache<any>
        >
      | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>;
}

export const createLimitOrder = async (data: CreateLimitOrderPropsI) => {
  const provider = await getWalletProvider(data.wallet);
  const accounts = await provider.request({
    method: 'eth_requestAccounts',
  });
  const maker = accounts[0];

  const makerAmount = new BigNumber(data.makerAmount);
  const takerAmount = new BigNumber(data.takerAmount);

  const makerToken = data.makerToken;
  const takerToken = data.takerToken;

  const approveProps = {
    provider,
    maker,
    amount: makerAmount,
    contractAddress: makerToken,
  };
  console.log({ approveProps });

  await requestApprove(approveProps);

  console.log({ expire: data.expiry });

  const expiry = getExpiry(data.expiry);
  const salt = new BigNumber(Date.now());

  const order = new LimitOrder({
    makerToken,
    takerToken,
    makerAmount,
    takerAmount,
    maker,
    sender: NULL_ADDRESS,
    expiry,
    salt,
    chainId: config.chainId,
    verifyingContract: config.zeroXVerifyingContractAddress,
  });

  console.log({ order });

  const SubProvider = getSubprovider<MetamaskSubprovider>(data.wallet);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const supportedProvider = new SubProvider(provider);

  const signature = await order.getSignatureWithProviderAsync(
    supportedProvider,
    SignatureType.EIP712 // Optional
  );

  const limitOrder = {
    stablecoin: data.stableCoin,
    tokencoin: data.tokenCoin,
    order: {
      takerTokenFeeAmount: 0,
      makerToken,
      takerToken,
      makerAmount: makerAmount.toNumber(),
      takerAmount: takerAmount.toNumber(),
      maker,
      taker: NULL_ADDRESS,
      sender: NULL_ADDRESS,
      feeRecipient: NULL_ADDRESS,
      pool: NULL_ADDRESS,
      expiry,
      salt: salt.toString(),
      signature,
    },
  };

  console.log({ limitOrder });

  const response = await data.newOrder({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      order: limitOrder as any,
    },
  });

  console.log({ response });
};
