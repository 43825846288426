export const COUNTRIES_AND_COMPANIES: Record<string, string[][]> = {
  us: [
    ['ccorp', 'C-Corp'],
    ['scorp', 'S-Corp'],
    ['llp', 'Limited Liability Partnerships'],
    ['llc', 'Limited Liability Corp'],
    ['sp', 'Sole Proprietor'],
  ],
  uk: [
    ['plc', 'Public Limited Company (PLC)'],
    ['ltd', 'Private Limited Company by shares (LTD)'],
    ['cbg', 'Company by guarantee'],
    ['uc', 'Unlimited Company'],
    ['llp', 'Limited Liability Partnership'],
    ['rc', 'Royal Charter'],
  ],
  au: [
    ['st', 'Sole Trader'],
    ['plc', 'Proprietary Limited Company'],
    ['asx', 'Public Company'],
    ['p', 'Partnership'],
  ],
  ca: [
    ['sp', 'Sole Proprietor'],
    ['p', 'Partnership'],
    ['corp', 'Corporation'],
  ],
};
