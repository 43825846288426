import { parseTimeLeft } from 'utils/parse-time-left';
import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';
import { AthleteIcoStatusI, AthleteOfferingTermsI, TableColumnConfigI } from 'types';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';
import { Trans } from 'react-i18next';

export const ATHLETE_ICO_STATUS_COLUMNS_CONFIG: TableColumnConfigI<AthleteIcoStatusI>[] = [
  {
    title: <Trans i18nKey="status" ns="hcx-card" />,
    dataKey: 'status',
  },
  {
    title: <Trans i18nKey="time-remaining" ns="hcx-card" />,
    dataKey: 'status',
    renderValue: (data) => {
      const today = new Date().getTime();
      const start = new Date(data.opening).getTime();
      const end = new Date(data.closing).getTime();
      const correctStart = Math.max(today, start);
      if (end < correctStart) return `0 days 0 hours 0 minutes`;
      const { days, hours, minutes } = parseTimeLeft(end - correctStart);
      return `${days} days ${hours} hours ${minutes} minutes`;
    },
  },
  {
    title: <Trans i18nKey="ico-opening" ns="hcx-card" />,
    dataKey: 'opening',
    renderValue: (data) => {
      return dateFormatter(data.opening, DateFormatE.MMMM_D_YYYY_h_mm_UTC) + ' GMT';
    },
  },
  {
    title: <Trans i18nKey="ico-closing" ns="hcx-card" />,
    dataKey: 'closing',
    renderValue: (data) => {
      return dateFormatter(data.closing, DateFormatE.MMMM_D_YYYY_h_mm_UTC) + ' GMT';
    },
  },
];

export const ATHLETE_OFFERING_TERMS_COLUMNS_CONFIG: TableColumnConfigI<AthleteOfferingTermsI>[] = [
  {
    title: <Trans i18nKey="percent-of-future-revenue" ns="hcx-card" />,
    dataKey: 'revenue',
    renderValue: (data) => {
      return data.revenue + '%';
    },
  },
  {
    title: <Trans i18nKey="revenue-type" ns="hcx-card" />,
    dataKey: 'revenueType',
  },
  {
    title: <Trans i18nKey="number-of-fractional-tokens-offered" ns="hcx-card" />,
    dataKey: 'fractionalTokensOffered',
    renderValue: (data) => {
      return formatNumber(data.fractionalTokensOffered);
    },
  },
  {
    title: <Trans i18nKey="percent-of-revenue-per-token" ns="hcx-card" />,
    dataKey: 'revenue',
    renderValue: (data) => {
      return data.revenue / data.fractionalTokensOffered + '%';
    },
  },
  {
    title: <Trans i18nKey="minimum-bid-per-token" ns="hcx-card" />,
    dataKey: 'maxBidPerToken',
    renderValue: (data) => {
      return formatToUSD(data.maxBidPerToken);
    },
  },
];
