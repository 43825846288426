import type { FC } from 'react';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Search } from 'components/search';
import { useTradingAthletesVar } from 'apolloClient/hooks';
import { ResultItemI } from 'components/search/results';
import { SearchResultI } from 'features/trade-bar/trade-bar.interfaces';

import { RenderToHeaderContext } from './context';

import styles from './header-trading-info.module.scss';
import { HeaderButtons } from 'pages/trade-desk/header-buttons';
import { useAtom } from 'jotai';
import { enabledPanelsHeaderButtons } from 'store/trading-stats.store';
import { PanelNameE } from 'pages/trade-desk/types';
import { SideBarWrapper } from 'components/layout/sidebar-wrapper';

export const HeaderTradingInfo: FC = memo(() => {
  const { t } = useTranslation('common');
  const { content, leftContent } = useContext(RenderToHeaderContext);
  const [search, setSearch] = useState('');
  const [enabledPanels, setEnabledPanels] = useAtom(enabledPanelsHeaderButtons);
  const { athletes } = useTradingAthletesVar();

  const searchItems = useMemo(() => {
    const searchResult = search.toLowerCase();
    return athletes.reduce<SearchResultI[]>((acc, athlete) => {
      const checkStr = (str: string) => str.toLowerCase().includes(searchResult);
      if (
        !search ||
        checkStr(athlete.bio.name) ||
        checkStr(athlete.tradingInfo.contractId) ||
        checkStr(athlete.career.title) ||
        checkStr(athlete.career.positionShort) ||
        checkStr(athlete.career.team)
      ) {
        acc.push({
          id: athlete.id,
          photo: athlete.bio.photo,
          name: athlete.bio.name,
          ticker: athlete.tradingInfo.contractId,
          title: athlete.career.title,
          position: athlete.career.positionShort,
          team: athlete.career.team,
          athlete,
        });
      }
      return acc;
    }, []);
  }, [athletes, search]);

  const changeSearch = useCallback((str: string) => {
    setSearch(str);
  }, []);

  const handleResultClick = useCallback((id: string | number, data: ResultItemI) => {
    console.log('id', data);
    throw new Error('Function not implemented.');
  }, []);

  const switchPanel = useCallback(
    (panel: PanelNameE) => {
      setEnabledPanels((current) => {
        if (current.includes(panel)) {
          return current.filter((x) => x !== panel);
        }
        return [...current, panel];
      });
    },
    [setEnabledPanels]
  );

  return (
    <Box className={styles.root}>
      <Box className={styles.left}>{leftContent}</Box>
      <Box className={styles.actions}>
        {content}
        <Search
          placeholder={t('search')}
          results={searchItems}
          inputClassName={styles.searchInput}
          iconClassName={styles.searchIcon}
          containerClassName={styles.searchInputContainer}
          dropdownClassName={styles.searchResults}
          onChange={changeSearch}
          onResultClick={handleResultClick}
        />
        <SideBarWrapper>
          <HeaderButtons switchPanel={switchPanel} enabledPanels={enabledPanels} />
        </SideBarWrapper>
      </Box>
    </Box>
  );
});
