import { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const SelectBadgeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 154 37">
    <g filter="url(#filter0_d_4517_35938)">
      <path
        d="M8.4627 6C-1.53731 6 153.962 6 145.463 6C140.857 6 138.306 12.1668 136.936 17.8168C135.699 22.9153 131.398 27 126.152 27H28.4627C22.9398 27 18.5385 22.4724 17.3458 17.0799C16.1435 11.6439 13.6469 6 8.4627 6Z"
        fill="customColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4517_35938"
        x="0"
        y="0"
        width="153.801"
        height="37"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4517_35938" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4517_35938"
          result="shape"
        />
      </filter>
    </defs>
  </SvgIcon>
);
