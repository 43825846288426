import { Provider as JotaiProvider } from 'jotai';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import { apolloClient } from 'apolloClient';
import { TransparentOverlayProvider } from 'components/transparent-overlay';
import { RenderToHeaderTradingInfoContextProvider } from 'components/layout/trading-info/header-trading-info';
import { HCXFinancialCardListResizerContextProvider } from 'features/hcx-financial-card-list/hcx-financial-card-list-resizer';
import theme from 'styles/theme/theme';

import { App } from './App';
import { AuthProvider } from './auth';
import i18n from './i18n/config';
import reportWebVitals from './reportWebVitals';

import './styles/index.scss';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Suspense fallback={<CircularProgress />}>
        <I18nextProvider i18n={i18n}>
          <JotaiProvider>
            <AuthProvider>
              <ApolloProvider client={apolloClient}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <TransparentOverlayProvider>
                      <HCXFinancialCardListResizerContextProvider>
                        <RenderToHeaderTradingInfoContextProvider>
                          <App />
                        </RenderToHeaderTradingInfoContextProvider>
                      </HCXFinancialCardListResizerContextProvider>
                    </TransparentOverlayProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </ApolloProvider>
            </AuthProvider>
          </JotaiProvider>
        </I18nextProvider>
      </Suspense>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
