import { FC } from 'react';

const SortDescIcon: FC = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0834 4.08333H12.8334L10.5001 1.75L8.16675 4.08333H9.91675V12.25H11.0834V4.08333ZM6.41675 7.58333V8.75L4.47425 11.0833H6.41675V12.25H2.91675V11.0833L4.85925 8.75H2.91675V7.58333H6.41675ZM5.25008 1.75H4.08342C3.44175 1.75 2.91675 2.275 2.91675 2.91667V6.41667H4.08342V5.25H5.25008V6.41667H6.41675V2.91667C6.41675 2.275 5.89758 1.75 5.25008 1.75ZM5.25008 4.08333H4.08342V2.91667H5.25008V4.08333Z"
      fill="currentColor"
    />
  </svg>
);

export default SortDescIcon;
