import { FC, useState, Context } from 'react';
import { IconButton, IconButtonProps, Menu, MenuProps } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuContent from './menu-content/menu-content';
import styles from './menu-icon.module.scss';
import { WidgetContextI } from '../widget-context/widget-context';

export const defaultContext = {};

interface MenuIconPropsI {
  context: Context<WidgetContextI>;
}

const MenuListProps: MenuProps['MenuListProps'] = {
  'aria-labelledby': 'button',
  className: styles.menuList,
};
const MenuPaperProps: MenuProps['PaperProps'] = {
  className: styles.menuPaper,
};

const anchorOrigin: MenuProps['anchorOrigin'] = { vertical: 'bottom', horizontal: 'right' };
const transformOrigin: MenuProps['transformOrigin'] = { vertical: 'top', horizontal: 'right' };

const MenuIcon: FC<MenuIconPropsI> = ({ context }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleOpen: IconButtonProps['onClick'] = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleOpen} className={open ? styles.activeBtn : ''} size="small">
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={MenuListProps}
        PaperProps={MenuPaperProps}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuContent context={context} />
      </Menu>
    </>
  );
};

export default MenuIcon;
