import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import { TableHeadCell, TableHeadRow, TableCell, TableRow } from 'components/table';
import { UserAvatar } from 'components/user-avatar';

import { formatToUSD } from 'utils/currency-formatter';
import { PortfolioWidgetContext } from '../../context';
import { WidgetSortE } from 'pages/trade-desk/widget-wrapper';

import { PlayerDataI, MOCK_PLAYERS_DATA, getProgress } from '../data.model';

import styles from './body-table.module.scss';

interface TableHeadingI {
  title: string;
  key: keyof PlayerDataI;
}

const TABLE_HEADINGS: TableHeadingI[] = [
  { title: '', key: 'name' },
  { title: 'units', key: 'units' },
  { title: 'avg-price', key: 'avgPrice' },
  { title: 'value', key: 'value' },
];

const BodyTable: FC = () => {
  const { sort, progressType } = useContext(PortfolioWidgetContext);

  const { t } = useTranslation('common');

  const athletes = useMemo(
    () =>
      [...MOCK_PLAYERS_DATA].sort((a, b) => {
        let result = 0;
        if (a.name > b.name) result = 1;
        if (a.name < b.name) result = -1;
        return result * (sort === WidgetSortE.Asc ? 1 : -1);
      }),
    [sort]
  );

  return (
    <Box className={styles.root}>
      <Table>
        <TableHead>
          <TableHeadRow>
            {TABLE_HEADINGS.map((heading: TableHeadingI) => (
              <TableHeadCell key={heading.key} className={styles.headCell}>
                <Typography variant="caption">{t(heading.title)}</Typography>
              </TableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {athletes.map((e) => (
            <TableRow key={e.id} className={styles.bodyRow}>
              <TableCell className={styles.cell}>
                <Box>
                  <UserAvatar name={e.name} className={styles.headshotPlaceholder} size={24} />
                  <Typography variant="body1">{e.name}</Typography>
                </Box>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography variant="body1">{e.units}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography variant="body1">
                  {formatToUSD(e.avgPrice, { minimumFractionDigits: 2 })}
                </Typography>
                <Typography
                  className={styles.caption}
                  color={e.gain >= 0 ? 'var(--hcx-alert-up)' : 'var(--hcx-alert-down)'}
                >
                  {getProgress(e, progressType)}
                </Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography variant="body1">{formatToUSD(e.value)}</Typography>
                <Typography
                  className={styles.caption}
                  color={e.gain >= 0 ? 'var(--hcx-alert-up)' : 'var(--hcx-alert-down)'}
                >
                  {getProgress(e, progressType)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default BodyTable;
