import { Box, Link, Typography } from '@mui/material';
import { Button } from 'components/button';
import {
  SponsorLevel,
  TalentSponsorInvitationStatus,
  useCancelTalentSponsorInvitationMutation,
  useSendTalentSponsorInvitationMutation,
} from 'generated/graphql';
import { useAtom } from 'jotai';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageAtom } from 'store/message-alert.store';
import styles from './sponsor-company.module.scss';
import { useTalentSponsorsList } from '../tab-sponsor.hooks';

export interface SponsorCompanyPropsI {
  id?: string;
  logo?: string;
  name?: string;
  description?: string;
  emailAddress?: string;
  phoneNumber?: string;
  level?: SponsorLevel;
  status?: TalentSponsorInvitationStatus;
  disabled?: boolean;
}

export const SponsorCompany: FC<SponsorCompanyPropsI> = ({
  id,
  logo,
  name,
  description,
  emailAddress,
  phoneNumber,
  level,
  status,
  disabled,
}) => {
  const { t } = useTranslation('talent-application');
  const [, setMessage] = useAtom(MessageAtom);
  const [sendTalentSponsorInvitation] = useSendTalentSponsorInvitationMutation();

  const [cancelTalentSponsorInvitation] = useCancelTalentSponsorInvitationMutation();

  const { refetch } = useTalentSponsorsList();

  const sendTalentSponsorInvite = async () => {
    try {
      const res = id
        ? await sendTalentSponsorInvitation({ variables: { sponsorId: id } })
        : { data: { sendTalentSponsorInvitation: 'no' } };
      if (res.data?.sendTalentSponsorInvitation === 'ok') {
        setMessage({ message: 'Invitation send ', type: 'success' });
      }
    } catch (err) {
      console.warn(err);
    }
    await refetch();
  };

  const cancelTalentSponsorInvite = async () => {
    try {
      const result = id
        ? await cancelTalentSponsorInvitation({ variables: { sponsorId: id } })
        : { data: { cancelTalentSponsorInvitation: 'no' } };
      if (result.data?.cancelTalentSponsorInvitation === 'ok') {
        setMessage({ message: 'Invitation cancelled', type: 'success' });
      }
    } catch (err) {
      console.warn(err);
    }
    await refetch();
  };

  return (
    <Box className={styles.root}>
      <Box display="inline-flex">
        <Box mr="2vw">
          <img src={logo} alt={name} className={styles.logoImage} loading="lazy" />
          {/* <Box display="flex" gap={1}>
            <Rating
              value={3.5}
              precision={0.5}
              size="small"
              readOnly
              classes={{ icon: styles.iconColor }}
            />
            <Typography className={styles.raitingText}>{t('(320)')}</Typography>
          </Box> //TODO: show raiting when this property will be provided from backend */}
        </Box>
        <Box className={styles.textWrapper}>
          <Box className={styles.flexRowBaseline}>
            <Typography variant="subtitle2">{name}</Typography>
            {status === TalentSponsorInvitationStatus.Created && (
              <Typography variant="subtitle2" className={styles.colorInvitation}>
                {t('invitation-pending')}
              </Typography>
            )}
            {/* {!status && (
              <Typography variant="subtitle2" className={styles.colorIConfirmation}>
                {t('sponsor-confirmation-pending')}
              </Typography>
            )} //TODO: show label when this status will be provided from backend */}
          </Box>
          <Typography variant="body1">{description || 'here is your description'}</Typography>
          <Box className={styles.flexRowBaseline}>
            <Link variant="body1" href={`mailto:${emailAddress}`}>
              {emailAddress}
            </Link>
            <Link variant="body1" href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </Link>
            {/* <Link variant="body1" href="#">
              {'View All HCX Sponsorships'}
            </Link> //TODO: show Link when this property will be provided from backend */}
          </Box>
        </Box>
        {level && (
          <Box display="flex" alignItems="center" gap={1} ml="5vw">
            <Typography variant="body1">{t('referrel-sponsor-level')}: </Typography>
            <Box className={styles.radialBadge}>{level.slice(5)}</Box>
          </Box>
        )}
      </Box>
      <Box>
        {!status && (
          <Button
            variant="outlined"
            size="small"
            onClick={sendTalentSponsorInvite}
            disabled={disabled}
            className={styles.inviteButton}
          >
            <Typography>{t('invite')}</Typography>
          </Button>
        )}
        {status === TalentSponsorInvitationStatus.Created && (
          <>
            {/* <Button
              variant="outlined"
              size="small"
              onClick={acceptTalentSponsorInvite}
              disabled={disabled}
              className={styles.inviteButton}
            >
              <Typography>{t('confirm-sponsor')}</Typography>
            </Button> //TODO: unhide when this will be ready on backend */}
            <Button
              variant="outlined"
              size="small"
              onClick={cancelTalentSponsorInvite}
              disabled={disabled}
              className={styles.inviteButton}
            >
              <Typography>{t('cancel-invitation')}</Typography>
            </Button>
          </>
        )}
        {status === TalentSponsorInvitationStatus.Accepted && (
          <Button
            variant="outlined"
            size="small"
            onClick={cancelTalentSponsorInvite}
            disabled={disabled}
            className={styles.inviteButton}
          >
            <Typography>{t('terminate-sponsor')}</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
};
