import type { FC } from 'react';
import { Box } from '@mui/material';
import { InputField } from 'components/input-field';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { sponsorFormValidationAtom } from 'store/sponsor-application.store';
import styles from './sponsor-form-owner.module.scss';
import {
  SponsorApplicationDataQuery,
  SponsorApplicationOwnerInformation,
  SponsorManagementInfoQuery,
  useUpdateSponsorApplicationMutation,
} from 'generated/graphql';
import React from 'react';
import { sponsorFormSchema } from 'yup/sponsorFormSchema';
import { ValidationError } from 'yup';
import { ApolloError, ApolloQueryResult } from '@apollo/client';

export interface OwnerInformationPropsI {
  sponsorOwnerInformation: SponsorApplicationOwnerInformation;
  refetchApplication:
    | (() => Promise<ApolloQueryResult<SponsorApplicationDataQuery>>)
    | (() => Promise<ApolloQueryResult<SponsorManagementInfoQuery>>);
  inReview: boolean;
}

export const OwnerInformation: FC<OwnerInformationPropsI> = ({
  sponsorOwnerInformation,
  refetchApplication,
  inReview,
}) => {
  const [errors, setErrors] = useAtom(sponsorFormValidationAtom);
  const { t } = useTranslation('sponsor-application');

  const [updateApplication] = useUpdateSponsorApplicationMutation();

  const onBlurHandler = async (event: React.FocusEvent) => {
    const { value, name } = event.target as HTMLInputElement;
    try {
      await sponsorFormSchema[name].validate(value);
    } catch (err) {
      setErrors((prev) => {
        return {
          ...prev,
          sponsorOwnerInformation: {
            ...prev?.sponsorOwnerInformation,
            [name]: {
              errMessage: (err as ValidationError).errors[0],
              isTouched: true,
            },
          },
        };
      });
      return console.warn(err);
    }
    setErrors((prev) => {
      return {
        ...prev,
        sponsorOwnerInformation: {
          ...prev?.sponsorOwnerInformation,
          [name]: {
            errMessage: undefined,
            isTouched: true,
          },
        },
      };
    });
    try {
      await updateApplication({
        variables: {
          parameters: {
            sponsorOwnerInformation: {
              [name]: value,
            },
          },
        },
      });
      await refetchApplication();
    } catch (err) {
      setErrors((prev) => {
        return {
          ...prev,
          sponsorOwnerInformation: {
            ...prev?.sponsorOwnerInformation,
            [name]: {
              errMessage: (err as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
      return console.warn({ err });
    }
  };

  return (
    <Box component="form" className={styles.root}>
      <div className={styles.line}>
        <InputField
          tName={t('owner-first-name')}
          name={'firstName'}
          disabled={inReview}
          className={styles.elementFirst}
          defaultValue={sponsorOwnerInformation?.firstName || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.firstName.isTouched &&
            errors.sponsorOwnerInformation.firstName.errMessage
          }
        />
        <InputField
          tName={t('owner-last-name')}
          name={'lastName'}
          disabled={inReview}
          className={styles.elementLast}
          defaultValue={sponsorOwnerInformation?.lastName || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.lastName.isTouched &&
            errors.sponsorOwnerInformation.lastName.errMessage
          }
        />
      </div>
      <div className={styles.line}>
        <InputField
          tName={t('owner-street-address')}
          name={'streetAddress'}
          disabled={inReview}
          className={styles.elementStreet}
          // onChange={onChangeHandler}
          defaultValue={sponsorOwnerInformation?.streetAddress || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.streetAddress.isTouched &&
            errors.sponsorOwnerInformation.streetAddress.errMessage
          }
        />
        <InputField
          tName={t('owner-apt-suite')}
          name={'apartment'}
          disabled={inReview}
          className={styles.elementApt}
          // onChange={onChangeHandler}
          defaultValue={sponsorOwnerInformation?.apartment || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.apartment.isTouched &&
            errors.sponsorOwnerInformation.apartment.errMessage
          }
        />
      </div>
      <div className={styles.line}>
        <InputField
          tName={t('owner-city')}
          name={'city'}
          disabled={inReview}
          className={styles.elementCity}
          defaultValue={sponsorOwnerInformation?.city || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.city.isTouched &&
            errors.sponsorOwnerInformation.city.errMessage
          }
        />
        <InputField
          tName={t('owner-state')}
          name={'state'}
          disabled={inReview}
          className={styles.elementState}
          defaultValue={sponsorOwnerInformation?.state || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.state.isTouched &&
            errors.sponsorOwnerInformation.state.errMessage
          }
        />
        <InputField
          tName={t('owner-zipcode')}
          name={'zip'}
          disabled={inReview}
          className={styles.elementState}
          // onChange={onChangeHandler}
          defaultValue={sponsorOwnerInformation?.zip || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.zip.isTouched &&
            errors.sponsorOwnerInformation.zip.errMessage
          }
        />
      </div>
      <div className={styles.line}>
        <InputField
          tName={t('owner-phone-number')}
          name={'phoneNumber'}
          disabled={inReview}
          className={styles.elementFirst}
          // onChange={onChangeHandler}
          defaultValue={sponsorOwnerInformation?.phoneNumber || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.phoneNumber.isTouched &&
            errors.sponsorOwnerInformation.phoneNumber.errMessage
          }
        />
        <InputField
          tName={t('owner-email-address')}
          name={'emailAddress'}
          disabled={inReview}
          className={styles.elementLast}
          // onChange={onChangeHandler}
          defaultValue={sponsorOwnerInformation?.emailAddress || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.sponsorOwnerInformation.emailAddress.isTouched &&
            errors.sponsorOwnerInformation.emailAddress.errMessage
          }
        />
      </div>
    </Box>
  );
};
