import type { FC } from 'react';
import { useState } from 'react';
import { AccordionSection } from 'components/accordion-section';
import { BadgeIncomplete } from 'components/badge-incomplete';
import { FormSectionPersonal } from '../form-section-personal';
import { FormSectionVerification } from '../form-section-verification';
import { FormSectionBackground } from '../form-section-background';
import { FormSectionReferences } from '../form-section-references';
import { FormSectionEmergency } from '../form-section-emergency';
import { FormSectionResume } from '../form-section-resume';
import { FormSectionSocial } from '../form-section-social';
import { Box, Typography } from '@mui/material';
import { MarkedLabel } from 'components/icons/marked-label-icon';
import { useTranslation } from 'react-i18next';
import {
  useTalentApplicationProfileQuery,
  useTalentApplicationStateQuery,
} from 'generated/graphql';

export const TabHcxAccordion: FC = () => {
  const { t } = useTranslation('talent-application');
  const [isExpanded, setIsExpanded] = useState({
    profile: true,
    verify: true,
    background: true,
    emergency: true,
    references: true,
    resume: true,
    social: false,
  });

  const handleChangeExpanded = (
    name: 'profile' | 'verify' | 'background' | 'emergency' | 'references' | 'resume' | 'social'
  ) => {
    setIsExpanded((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  };

  const { data: talentProfileState } = useTalentApplicationStateQuery();
  const { data: talentApplicationProfile, refetch: refetchProfile } =
    useTalentApplicationProfileQuery({ notifyOnNetworkStatusChange: true });

  const isBackAuthFilled =
    !!talentProfileState?.talentApplication.state.profile?.authorizationCheck;

  const isVerificationFilled = !!talentProfileState?.talentApplication.state.profile?.verification;

  const isPersonalSectionFilled =
    !!talentProfileState?.talentApplication.state.profile?.personalProfile;

  const isEmergencyFilled = !!talentProfileState?.talentApplication.state.profile?.emergencyContact;

  const isReferencesFilled = !!talentProfileState?.talentApplication.state.profile?.references;

  const isResumeFilled = !!talentProfileState?.talentApplication.state.profile?.resume;

  //const isSocialMediaFilled = !!talentProfileState?.talentApplication.state.profile?.socialMedia; //TODO: un-hide when social media will be working

  if (!talentApplicationProfile) {
    return null;
  }

  return (
    <>
      <AccordionSection
        expand={isExpanded.profile}
        handleChangeExpanded={() => handleChangeExpanded('profile')}
        details={
          <FormSectionPersonal
            basicInformation={talentApplicationProfile.talentApplication.profile.basicInformation}
            refetchProfile={refetchProfile}
          />
        }
        summary={
          <>
            <Typography variant="subtitle2">{t('personal-profile')}</Typography>
            <BadgeIncomplete complete={isPersonalSectionFilled} />
          </>
        }
      />
      <AccordionSection
        expand={isExpanded.verify}
        handleChangeExpanded={() => handleChangeExpanded('verify')}
        details={
          <FormSectionVerification
            basicInformation={talentApplicationProfile.talentApplication.profile.basicInformation}
            refetchProfile={refetchProfile}
          />
        }
        summary={
          <>
            <Typography variant="subtitle2">{t('verification')}</Typography>
            <BadgeIncomplete complete={isVerificationFilled} />
          </>
        }
      />
      <AccordionSection
        expand={isExpanded.background}
        handleChangeExpanded={() => handleChangeExpanded('background')}
        details={
          <FormSectionBackground
            basicInformation={talentApplicationProfile.talentApplication.profile.basicInformation}
            refetchProfile={refetchProfile}
          />
        }
        summary={
          <>
            <Typography variant="subtitle2">{t('background-check-authorization')}</Typography>
            <BadgeIncomplete complete={isBackAuthFilled} />
          </>
        }
      />
      <AccordionSection
        expand={isExpanded.emergency}
        handleChangeExpanded={() => handleChangeExpanded('emergency')}
        details={
          <FormSectionEmergency
            emergencies={talentApplicationProfile.talentApplication.profile.emergencyContacts}
            refetchProfile={refetchProfile}
          />
        }
        summary={
          <>
            <Typography variant="subtitle2">{t('emergency-contact-information')}</Typography>
            <BadgeIncomplete complete={isEmergencyFilled} />
          </>
        }
      />
      <AccordionSection
        expand={isExpanded.references}
        handleChangeExpanded={() => handleChangeExpanded('references')}
        details={
          <FormSectionReferences
            references={talentApplicationProfile.talentApplication.profile.references}
            refetchProfile={refetchProfile}
          />
        }
        summary={
          <>
            <Typography variant="subtitle2">{t('references')}</Typography>
            <BadgeIncomplete complete={isReferencesFilled} />
          </>
        }
      />
      <AccordionSection
        expand={isExpanded.resume}
        handleChangeExpanded={() => handleChangeExpanded('resume')}
        details={
          <FormSectionResume
            basicInformation={talentApplicationProfile.talentApplication.profile.basicInformation}
            refetchProfile={refetchProfile}
          />
        }
        summary={
          <>
            <Box display="flex">
              <Typography mr={1} variant="subtitle2">
                {t('resume')}
              </Typography>
              <MarkedLabel />
            </Box>
            <BadgeIncomplete complete={isResumeFilled} />
          </>
        }
      />
      <AccordionSection
        expand={isExpanded.social}
        //handleChangeExpanded={() => handleChangeExpanded('social')} //TODO: un-hide when it will be ready
        details={<FormSectionSocial />}
        summary={
          <>
            <Box display="flex">
              <Typography mr={1} variant="subtitle2">
                {t('social-media')}
              </Typography>
              <MarkedLabel />
            </Box>
            <BadgeIncomplete complete={true} />
          </>
        }
      />
    </>
  );
};
