import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format';

import { MenuItem, Select, SelectProps, TextFieldProps, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker, { DesktopDatePickerProps } from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { useAthletes } from 'apolloClient/hooks';
import { BarIconWrapper } from 'components/bar-icon-wrapper';
import { TradeIcon } from 'components/icons/trade-icon';
import { CustomSwitcher } from 'components/custom-switcher';
import { Button } from 'components/button';
import { Search, SearchPropsI } from 'components/search';
import { OrderStatusE, OrderTradingTypeE, OrderTypeE } from 'enums';
import { AthleteI } from 'types';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';
import { noop } from 'utils/noop';

import { MOCK_WALLETS, MockWalletT } from './mock-wallets';
import { TradeBarFieldWithLabel } from './trade-bar-field-with-label';
import { TradeBarTimeLeft } from './trade-bar-time-left';
import {
  BUY_SELL_LABELS,
  getEndOfDay,
  GOOD_UNTIL_MENU_PROPS,
  GOOD_UNTIL_OPTIONS,
  MARKET_LIMIT_LABELS,
  SELECT_CLASSES,
  StyledInput,
  StyledInputForDatePicker,
  WALLET_MENU_PROPS,
  datePickerProps,
} from './trade-bar.helpers';
import { GoodUntilI, SearchResultI } from './trade-bar.interfaces';

import styles from './trade-bar.module.scss';

export const TradeBar: FC = () => {
  const { t } = useTranslation('common');

  const [buySell, setBuySell] = useState(0);
  const [marketLimit, setMarketLimit] = useState(0);
  const [shares, setShares] = useState<number>();
  const [selectedAthlete, setSelectedAthlete] = useState<AthleteI | null>(null);
  const [selectedWallet, setSelectedWallet] = useState<MockWalletT | null>({
    id: 1,
    name: 'MetaMask',
  });
  const [selectedGoodUntil, setSelectedGoodUntil] = useState<GoodUntilI | null>(null);
  const [search, setSearch] = useState('');

  const handleInputNumberChange = useCallback(
    ({ floatValue }: NumberFormatValues, { event, source }: SourceInfo) => {
      if (!event || source === 'prop') return;
      setShares(floatValue);
    },
    []
  );
  const athletes = useAthletes();

  const searchItems = useMemo(() => {
    const searchResult = search.toLowerCase();
    return athletes.reduce<SearchResultI[]>((acc, athlete) => {
      const checkStr = (str: string) => str.toLowerCase().includes(searchResult);
      if (
        !search ||
        checkStr(athlete.bio.name) ||
        checkStr(athlete.tradingInfo.contractId) ||
        checkStr(athlete.career.title) ||
        checkStr(athlete.career.positionShort) ||
        checkStr(athlete.career.team)
      ) {
        acc.push({
          id: athlete.id,
          photo: athlete.bio.photo,
          name: athlete.bio.name,
          ticker: athlete.tradingInfo.contractId,
          title: athlete.career.title,
          position: athlete.career.positionShort,
          team: athlete.career.team,
          athlete: athlete,
        });
      }
      return acc;
    }, []);
  }, [athletes, search]);

  const getSearchOnSelect: SearchPropsI['getSearchOnSelect'] = (data) => {
    return (data as SearchResultI).athlete.tradingInfo.contractId;
  };

  const selectAthlete: SearchPropsI['onResultClick'] = (_, data) => {
    setSelectedAthlete((data as SearchResultI).athlete);
    setSearch(getSearchOnSelect(data));
  };

  const changeSearch = (str: string) => {
    setSearch(str);
    setSelectedAthlete(null);
  };

  const handleChangeWallet: SelectProps<MockWalletT['id']>['onChange'] = (e) => {
    const { value: id } = e.target;
    const wallet = MOCK_WALLETS.find((item) => item.id === Number(id)) || null;
    setSelectedWallet(wallet);
  };
  const handleChangeGoodUntil: SelectProps<GoodUntilI['type'] | 0>['onChange'] = (e) => {
    const { value } = e.target;

    if (!value) {
      setSelectedGoodUntil(null);
      return;
    }

    const option = GOOD_UNTIL_OPTIONS.find((item) => item.label === value);

    setSelectedGoodUntil(
      !option
        ? null
        : {
            type: option.label,
            date: option.getDefaultDate(),
          }
    );
  };

  const handleUntilDateChange: DesktopDatePickerProps['onChange'] = useCallback((date) => {
    setSelectedGoodUntil({
      type: 'GTD',
      date: getEndOfDay(date as Date),
    });
  }, []);

  const renderSelectedWalletValue = useCallback(
    () =>
      !selectedWallet?.name ? (
        <Typography className={styles.walletSelectPlaceholder}>{t('select')}</Typography>
      ) : (
        <Typography className={styles.walletSelectValue}>{selectedWallet.name}</Typography>
      ),
    [selectedWallet?.name, t]
  );

  const renderSelectedGoodUntilValue = useCallback(
    () =>
      !selectedGoodUntil?.type ? (
        <Typography className={styles.walletSelectPlaceholder}>{t('select')}</Typography>
      ) : (
        <Typography className={styles.walletSelectValue}>{selectedGoodUntil.type}</Typography>
      ),
    [selectedGoodUntil?.type, t]
  );

  const buySellDisabled =
    !selectedWallet?.id ||
    !selectedAthlete?.tradingInfo.contractId ||
    !shares ||
    !selectedGoodUntil?.type;

  const handleBuySell = () => {
    if (buySellDisabled) return;
    const data = {
      wallet: selectedWallet.id,
      walletName: selectedWallet.name,
      ticker: selectedAthlete.tradingInfo.contractId,
      athleteId: selectedAthlete.id,
      shares,
      goodUntil:
        selectedGoodUntil?.type === 'GTC'
          ? OrderStatusE.Canceled
          : selectedGoodUntil.date?.toISOString(),
      orderTradingType: buySell,
      orderTradingTypeName: OrderTradingTypeE[buySell],
      orderType: marketLimit,
      orderTypeName: !marketLimit ? OrderTypeE.Market : OrderTypeE.Limit,
    };

    console.table(data);
  };

  const renderDatePickerInput = useCallback(
    (params: TextFieldProps) => {
      if (selectedGoodUntil?.type !== 'GTD' || !selectedGoodUntil?.date) return <></>;

      const dateValue = selectedGoodUntil.date as Date;
      const isToday = getEndOfDay().getTime() === dateValue.getTime();
      const value = isToday
        ? t('today')
        : dateFormatter(selectedGoodUntil.date as Date, DateFormatE.MM_DD_YYYY);

      return (
        <StyledInputForDatePicker
          endAdornment={params.InputProps?.endAdornment || undefined}
          inputProps={{
            ...(params.inputProps || {}),
            onChange: noop,
            value,
            readOnly: true,
          }}
          inputRef={params.inputRef}
          size="small"
          disabled={params.disabled}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={params.InputProps?.endAdornment?.props?.children?.props?.onClick}
        />
      );
    },
    [selectedGoodUntil, t]
  );

  return (
    <div className={styles.root}>
      <BarIconWrapper className={styles.icon}>
        <TradeIcon />
      </BarIconWrapper>
      <TradeBarFieldWithLabel label={t('wallet')} className={styles.walletBlock}>
        <Select
          className={classNames(styles.selectRoot)}
          renderValue={renderSelectedWalletValue}
          value={selectedWallet?.id || 1}
          onChange={handleChangeWallet}
          MenuProps={WALLET_MENU_PROPS}
          classes={SELECT_CLASSES}
          IconComponent={KeyboardArrowDownIcon}
          fullWidth
        >
          {MOCK_WALLETS.map(({ id, name }) => (
            <MenuItem value={id} key={id} className={styles.selectMenuItem}>
              <Typography variant="body1">{name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </TradeBarFieldWithLabel>
      <TradeBarFieldWithLabel label={t('talent')} className={styles.talentBlock}>
        <Search
          placeholder={t('search')}
          results={searchItems}
          onResultClick={selectAthlete}
          onChange={changeSearch}
          inputClassName={styles.searchInput}
          containerClassName={styles.searchInputContainer}
          iconClassName={classNames(
            styles.searchIcon,
            selectedAthlete && styles.searchIcon_selected
          )}
          dropdownClassName={styles.searchResults}
          getSearchOnSelect={getSearchOnSelect}
          icon={
            !selectedAthlete ? undefined : (
              <img src={selectedAthlete.bio.photo} alt="" className={styles.photo} />
            )
          }
        />
      </TradeBarFieldWithLabel>
      <CustomSwitcher
        labels={BUY_SELL_LABELS}
        value={buySell}
        onChange={setBuySell}
        className={styles.switcher}
      />
      <TradeBarFieldWithLabel label={t('shares')} className={styles.sharesBlock}>
        <NumberFormat
          className={styles.amount}
          id="shares"
          size="small"
          value={shares}
          name="shares"
          decimalScale={2}
          customInput={StyledInput}
          type="text"
          thousandSeparator=","
          onValueChange={handleInputNumberChange}
          allowNegative={false}
          autoComplete="off"
        />
      </TradeBarFieldWithLabel>
      <CustomSwitcher
        labels={MARKET_LIMIT_LABELS}
        value={marketLimit}
        onChange={setMarketLimit}
        className={styles.switcher}
      />
      <Select
        className={classNames(styles.selectRoot, styles.goodUntilSelect)}
        renderValue={renderSelectedGoodUntilValue}
        value={selectedGoodUntil?.type || 'GTC'}
        onChange={handleChangeGoodUntil}
        MenuProps={GOOD_UNTIL_MENU_PROPS}
        classes={SELECT_CLASSES}
        fullWidth
        IconComponent={KeyboardArrowDownIcon}
      >
        {GOOD_UNTIL_OPTIONS.map(({ label }) => (
          <MenuItem value={label} key={label} className={styles.selectMenuItem}>
            <Typography variant="body1">{label}</Typography>
          </MenuItem>
        ))}
      </Select>
      {selectedGoodUntil?.type === 'GT 24h' && (
        <TradeBarTimeLeft endDate={selectedGoodUntil.date as Date} />
      )}
      {selectedGoodUntil?.type === 'GTD' && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            value={selectedGoodUntil.date}
            onChange={handleUntilDateChange}
            renderInput={renderDatePickerInput}
            {...datePickerProps}
            disablePast
          />
        </LocalizationProvider>
      )}
      <Button
        variant="contained"
        size="small"
        className={styles.buySellBtn}
        disabled={buySellDisabled}
        onClick={handleBuySell}
      >
        {BUY_SELL_LABELS[buySell]}
      </Button>
    </div>
  );
};
