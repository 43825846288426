import type { FC } from 'react';
import { TermsOfService } from './tab-terms-of-service';
import { TabSponsorLevel } from './tab-sponsor-level';
import { TabSponsorInfo } from './tab-sponsor-info';
import { TabMembersPermission } from './tab-members-permissions';

export interface SponsorManagementTabsI {
  name: string;
  component: FC;
}

export const TAB_ROUTE: SponsorManagementTabsI[] = [
  { name: 'members-permissions', component: TabMembersPermission },
  { name: 'sponsor-info', component: TabSponsorInfo },
  { name: 'sponsor-level', component: TabSponsorLevel },
  { name: 'terms-service', component: TermsOfService },
];
