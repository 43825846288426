import type { FC } from 'react';
import React, { useRef } from 'react';
import { AddPhotoIcon } from 'components/icons/add-photo-icon';
import { Avatar, Badge, Box, IconButton } from '@mui/material';
import styles from './add-photo-input.module.scss';
import classNames from 'classnames';

export interface AccountProfilePhotoSelectorPropsI {
  onChange?: (e: React.ChangeEvent) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  setError?: (error: boolean, name: string) => void;
  state: string;
  fieldName: string;
  errorValidation?: string | boolean;
  disabled?: boolean;
}

const CLASSES = {
  anchorOriginTopLeft: styles.anchorOriginTopLeft,
};

const AVATAR_CLASSES = {
  root: styles.avatarRoot,
  colorDefault: styles.avatarBackground,
};

const ICON_BUTTON_CLASSES = {
  root: styles.iconButton,
};

export const AddPhotoInput: FC<AccountProfilePhotoSelectorPropsI> = ({
  fieldName,
  onChange,
  setError,
  state,
  errorValidation,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const checkIsFilled = () => {
    if (setError) {
      setError(true, fieldName);
      window.removeEventListener('focus', checkIsFilled);
    }
  };

  const selectPhoto = async () => {
    inputRef.current?.click();
    window.addEventListener('focus', checkIsFilled);
  };

  const hideButton = state?.[fieldName as keyof typeof state] || disabled;

  return (
    <Box className={styles.root}>
      {/* //TODO: here is state of error to check */}
      <Box className={classNames(styles.input, { [styles.error]: errorValidation })}>
        <Badge
          classes={CLASSES}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={
            <label htmlFor={fieldName} className={styles.selectPhoto}>
              <input
                name={fieldName}
                ref={inputRef}
                accept="image/*"
                disabled={disabled}
                id={fieldName}
                type="file"
                onChange={onChange}
              />
              <IconButton
                size="large"
                onClick={selectPhoto}
                disabled={disabled}
                classes={ICON_BUTTON_CLASSES}
              >
                {!hideButton && <AddPhotoIcon />}
              </IconButton>
            </label>
          }
        >
          <Avatar
            classes={AVATAR_CLASSES}
            alt="company logo"
            src={state}
            variant="square"
            className={styles.sizeAvatar}
          />
        </Badge>
      </Box>
      {errorValidation && (
        <Box alignSelf="left">
          <p className={styles.errorText}>{errorValidation}</p>
        </Box>
      )}
    </Box>
  );
};
