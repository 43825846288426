import { FC } from 'react';
import Box from '@mui/material/Box';
import { useFormState } from './use-form-state';
import commonStyles from './common.module.scss';
import { Input } from '../../auth/input';
import { Button } from 'components/button';
import classNames from 'classnames';
import { TitleRow } from './title-row';
import { PropsI } from './types';

interface DataI {
  country: string;
  address: string;
  apt: string;
  region: string;
  city: string;
  index: string;
}

const initialState: DataI = {
  country: '',
  address: '',
  apt: '',
  region: '',
  city: '',
  index: '',
};

export const SecondStep: FC<PropsI<DataI>> = ({ onSubmit, onBack }) => {
  const { state, handleChange, handleSubmit } = useFormState(initialState, onSubmit);
  return (
    <Box>
      <form>
        <Box className={commonStyles.wrapper}>
          <TitleRow title="Home Address" onBack={onBack} />
          <Input
            type="text"
            name="country"
            placeholder="Country"
            onChange={handleChange}
            value={state.country}
            className={classNames(commonStyles.mt2, commonStyles.wa)}
          />
          <Input
            type="text"
            name="country"
            placeholder="Address"
            onChange={handleChange}
            value={state.address}
            className={classNames(commonStyles.mt2, commonStyles.wa)}
          />
          <Input
            type="text"
            name="apt"
            placeholder="Apt, unit, building, floor, etc"
            onChange={handleChange}
            value={state.apt}
            className={classNames(commonStyles.mt2, commonStyles.wa)}
          />
          <Box className={commonStyles.mt2} display="flex">
            <Input
              type="text"
              name="region"
              placeholder="Region"
              onChange={handleChange}
              value={state.region}
              className={classNames(commonStyles.wa, commonStyles.flex1)}
            />
            <Input
              type="text"
              name="city"
              placeholder="City"
              onChange={handleChange}
              value={state.city}
              className={classNames(commonStyles.ml1, commonStyles.wa, commonStyles.flex1)}
            />
            <Input
              type="text"
              name="index"
              placeholder="Index"
              onChange={handleChange}
              value={state.index}
              className={classNames(commonStyles.ml1, commonStyles.wa, commonStyles.flex1)}
            />
          </Box>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className={commonStyles.mt4}
            size={'medium'}
          >
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  );
};
