import type { FC } from 'react';

import classNames from 'classnames';

import { AthleteCardContainer, AthleteCardPropsI } from 'components/athlete/athlete-card';

import { useHCXFinancialCardContext } from '../hcx-financial-card-context';

import styles from './hcx-financial-card-athlete-info.module.scss';

export const HCXFinancialCardAthleteInfo: FC<{
  variant?: AthleteCardPropsI['variant'];
}> = ({ variant = 'medium' }) => {
  const { athlete } = useHCXFinancialCardContext();

  if (!athlete) {
    return null;
  }

  return (
    <div className={classNames(styles.root, styles[variant])}>
      <AthleteCardContainer athlete={athlete} className={styles.athleteCard} variant={variant} />
      <div className={styles.actions}>
        {/* variant !== 'small' && <AthleteBidBuySellBtn id={id} /> */}
        {/* <Link className={styles.link} onClick={handleNavigateToOverview}>
          {t('view-details')}
        </Link> */}
      </div>
    </div>
  );
};
