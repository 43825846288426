import { FC } from 'react';
import { TalentPortalAccount } from './components/talent-portal-account';
import { TalentPortalRevenue } from './components/talent-portal-revenue';
import { TalentPortalConnectSocial } from './components/talent-portal-connect-social-media';
import {
  TalentPortalWelcomeModal,
  useTalentPortalWelcomeModal,
} from './components/talent-portal-welcome-modal';
import {
  TalentPortalTabs,
  TalentPortalTabsContextProvider,
  TalentPortalTabContent,
} from './components/talent-portal-tabs';
import styles from './talent-portal.module.scss';

const TalentPortal: FC = () => {
  const { isOpen, onClose } = useTalentPortalWelcomeModal();
  return (
    <>
      <div className={styles.root}>
        <TalentPortalTabsContextProvider>
          <TalentPortalTabs />
          <div className={styles.grid}>
            <div className={styles.tabContentWrapper}>
              <TalentPortalTabContent />
            </div>
            <div className={styles.sideBlock}>
              <TalentPortalAccount />
              <TalentPortalRevenue />
              <TalentPortalConnectSocial />
            </div>
          </div>
        </TalentPortalTabsContextProvider>
      </div>
      <TalentPortalWelcomeModal open={isOpen} onClose={onClose} />
    </>
  );
};

export default TalentPortal;
