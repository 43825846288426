import { useAtom } from 'jotai';
import type { FC } from 'react';

import { selectedAthleteIAtom } from 'store/athletes.store';

import { HCXCardSummaryFinance } from './hcx-card-summary-finance';

export const HCXCardSummaryFinanceContainer: FC = () => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  const ticker = athlete?.tradingInfo?.contractId;
  const tradingPairId = athlete?.tradingInfo.tradingPairId;

  if (!ticker || !tradingPairId) {
    return null;
  }

  return <HCXCardSummaryFinance ticker={ticker} tradingPairId={tradingPairId} />;
};
