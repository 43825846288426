import type { FC } from 'react';

interface PropsI {
  className?: string;
  color?: string;
}

export const TradeDeskIcon: FC<PropsI> = ({ className, color = 'currentColor' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.25 12.125L2 14.33V8H4.25V12.125ZM8 10.745L6.8225 9.74L5.75 10.73V5H8V10.745ZM11.75 9.5L9.5 11.75V2H11.75V9.5ZM13.8575 9.3575L12.5 8H16.25V11.75L14.9075 10.4075L9.5 15.77L6.8975 13.505L4.0625 16.25H2L6.8525 11.495L9.5 13.73"
      fill={color}
    />
  </svg>
);
