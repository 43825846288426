import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';

import { a11yProps } from 'utils/a11y';

import Tab from '@mui/material/Tab';
import { Tabs as MuiTabs } from '@mui/material';
import type { TabsProps as MUITabsProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import classNames from 'classnames';

import styles from './tabs.module.scss';

export interface TabsPropsI extends MUITabsProps {
  tabs?: { name: string; filled: boolean }[];
  labels?: string[];
  tabsStyles?: string;
  tabStyle?: string;
  indicatorClassName?: string;
  transformLabel?: (label: string | ReactNode) => string | ReactNode;
}

export const Tabs: FC<TabsPropsI> = ({
  tabsStyles,
  tabStyle,
  indicatorClassName,
  tabs,
  labels,
  transformLabel = (label: string | ReactNode) => label,
  ...props
}) => {
  const tabIndicatorProps = useMemo(
    () => ({
      children: <span className={classNames(indicatorClassName, styles.indicatorSpan)} />,
    }),
    [indicatorClassName]
  );

  return (
    <MuiTabs
      className={classNames(styles.tabsBase, tabsStyles)}
      TabIndicatorProps={tabIndicatorProps}
      aria-label="tabs"
      {...props}
    >
      {(tabs || labels)?.map((el, index) => {
        return (
          <Tab
            className={classNames(styles.tabBase, tabStyle)}
            key={typeof el === 'string' ? el : el.name}
            icon={
              tabs && (
                <CheckCircleIcon
                  fontSize="small"
                  className={classNames(styles.iconGray, {
                    [styles.iconChecked]: typeof el !== 'string' && el.filled,
                  })}
                />
              )
            }
            disableRipple
            label={transformLabel(typeof el === 'string' ? el : el.name)}
            {...a11yProps(index)}
          />
        );
      })}
    </MuiTabs>
  );
};
