import { FC } from 'react';
import { AthleteI } from 'types';
import AthleteOrders, { AthleteOrdersPropsI } from './athlete-orders';
import { useAthleteOrders } from './athlete-orders-hooks';

export interface AthleteOrdersContainerPropsI extends Pick<AthleteOrdersPropsI, 'getMenuOptions'> {
  athleteId: AthleteI['id'];
}

const AthleteOrdersContainer: FC<AthleteOrdersContainerPropsI> = ({
  athleteId: id,
  getMenuOptions,
}) => {
  const { orders } = useAthleteOrders(id);
  return <AthleteOrders getMenuOptions={getMenuOptions} orders={orders} />;
};

export default AthleteOrdersContainer;
