import { FC } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';
import styles from './snackbar.module.scss';
import classNames from 'classnames';

const DEFAULT_ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'center' } as const;
const CONTENT_PROPS = { className: styles.snackbar };

export const Snackbar: FC<SnackbarProps> = ({ anchorOrigin, className, ...props }) => (
  <MuiSnackbar
    ContentProps={CONTENT_PROPS}
    className={classNames(styles.snackbar, className)}
    anchorOrigin={anchorOrigin ?? DEFAULT_ANCHOR_ORIGIN}
    {...props}
  />
);
