import { createContext, Dispatch, SetStateAction } from 'react';
import { noop } from 'utils/noop';
import { WidgetProgressE, WidgetSortE, WidgetCardSizeE } from './enums';

export interface WidgetContextDataI {
  progressType: WidgetProgressE;
  sort: WidgetSortE;
  cardSize: WidgetCardSizeE;
  hideSizer?: boolean;
}

export interface WidgetContextI extends WidgetContextDataI {
  setContextData: Dispatch<SetStateAction<WidgetContextDataI>>;
  changeProgressType: (value: WidgetProgressE) => void;
  changeSortType: (value: WidgetSortE) => void;
  changeCardSize: (value: WidgetCardSizeE) => void;
}

export const defaultContextData: WidgetContextDataI = {
  progressType: WidgetProgressE.Currency,
  sort: WidgetSortE.Asc,
  cardSize: WidgetCardSizeE.Small,
};

export const generateWidgetContext = () =>
  createContext<WidgetContextI>({
    ...defaultContextData,
    setContextData: noop,
    changeProgressType: noop,
    changeSortType: noop,
    changeCardSize: noop,
  });
