import classNames from 'classnames';
import { t as translate } from 'i18next';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Chip, Typography } from '@mui/material';

import { TableCell, TableRow } from 'components/table';
import { UserAvatar } from 'components/user-avatar';

import { LocaleE } from 'enums';
import {
  DutchAuction,
  DutchAuctionStatus,
  useLatestAuctionStatsSubscription,
} from 'generated/graphql';
import { Countdown } from 'pages/human-capital-mint/countdown';
import { getAuctionRowData, getAuctionStatus, getEndDate } from 'pages/human-capital-mint/helpers';
import { formatToUSD } from 'utils/currency-formatter';
import {
  formatNumber,
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
} from 'utils/number-formatter';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';

import { MINTING_CHIP_CLASSNAME, MINTING_STATUS_TRANSLATE } from './constants';
import { AuctionRowDataI } from './types';

import styles from './active-mints-table.module.scss';

interface ActiveMintsTableRowPropsI {
  entry: DutchAuction;
  selectedRow: string | null;
  onRowClick: (auctionData: AuctionRowDataI) => void;
}

export const ActiveMintsTableRow: FC<ActiveMintsTableRowPropsI> = ({
  entry,
  selectedRow,
  onRowClick,
}) => {
  const { t } = useTranslation('human-capital-mint');

  const { data: auctionStatsSub } = useLatestAuctionStatsSubscription({
    variables: { auctionId: Number(entry.id) },
    fetchPolicy: 'network-only',
  });

  const actionStatus = useMemo(
    () =>
      getAuctionStatus(
        Number(entry.blockchainId),
        entry.status,
        entry.startDate,
        entry.durationDays
      ),
    [entry.blockchainId, entry.startDate, entry.durationDays, entry.status]
  );

  const entryEndDate = useMemo(
    () => getEndDate(entry.startDate, entry.durationDays),
    [entry.startDate, entry.durationDays]
  );

  const entryStats = useMemo(() => {
    if (auctionStatsSub?.latestAuctionStats) {
      return auctionStatsSub.latestAuctionStats;
    }
    return entry.stats;
  }, [entry.stats, auctionStatsSub?.latestAuctionStats]);

  const counterDate = useMemo(() => {
    if (actionStatus === DutchAuctionStatus.Closed) {
      return new Date();
    }
    return new Date(entryEndDate);
  }, [actionStatus, entryEndDate]);

  const translatedStatus = translate(MINTING_STATUS_TRANSLATE[actionStatus]);
  const statusClassname = MINTING_CHIP_CLASSNAME[actionStatus];

  // TODO: VOV: Uncomment if it would be used
  // const MINTING_TYPE = {
  //   [MintTypeE.IHO]: translate('IHO'),
  //   [MintTypeE.CHO]: translate('CHO'),
  // };

  return (
    <TableRow
      key={entry.id}
      className={classNames(
        styles.row,
        entry.athleteContract.athlete.id === selectedRow && styles.selectedRow
      )}
      onClick={() => onRowClick(getAuctionRowData(entry))}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          {entry.athleteContract.athlete.id === selectedRow && <Box className={styles.prefixDot} />}
          <UserAvatar
            photo={entry.athleteContract.athlete.imageUrl}
            name={entry.athleteContract.athlete.fullName}
            className={styles.userAvatar}
          />
          <Box ml={2.5}>
            <Typography variant="subtitle2" whiteSpace="nowrap">
              {entry.athleteContract.athlete.fullName}
            </Typography>
            <Typography variant="subtitle2">{entry.athleteContract.athlete.nftSymbol}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Typography>{entry.auctionType}</Typography>
      </TableCell>
      <TableCell>
        <Chip label={translatedStatus} className={classNames(statusClassname, styles.chip)} />
      </TableCell>
      <TableCell>
        <Typography>{entryStats?.numBidders ?? 0}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {formatToUSD(
            entryStats?.highBid
              ? normalizeDecimals(entryStats.highBid, NormalizationDecimalsE.Six)[
                  NormalizationLogicE.NonDecimal
                ]
              : 0,
            {
              minimumFractionDigits: 2,
            }
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {formatToUSD(
            normalizeDecimals(entry.endPrice, NormalizationDecimalsE.Six)[
              NormalizationLogicE.NonDecimal
            ],
            {
              minimumFractionDigits: 2,
            }
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {formatToUSD(
            entryStats?.thresholdPrice
              ? normalizeDecimals(entryStats.thresholdPrice, NormalizationDecimalsE.Six)[
                  NormalizationLogicE.NonDecimal
                ]
              : 0,
            {
              minimumFractionDigits: 2,
            }
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {formatNumber(entry.athleteContract.revenuePercentage, {
            signDisplay: 'never',
          })}
          %
        </Typography>
      </TableCell>
      <TableCell>
        <Typography maxWidth="90px">
          {entry.startDate
            ? dateFormatter(entry.startDate, DateFormatE.MM_DD_YYYY_HH_MM, LocaleE.en_US)
            : t('scheduled')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography maxWidth="90px">
          {actionStatus === DutchAuctionStatus.Scheduled ? t('scheduled') : entryEndDate}
        </Typography>
      </TableCell>
      <TableCell>
        {actionStatus === DutchAuctionStatus.Scheduled ? (
          <Countdown />
        ) : (
          <Countdown date={counterDate} />
        )}
      </TableCell>
    </TableRow>
  );
};
