import { useAtom } from 'jotai';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WalletE } from 'blockchain-api/ewallets-api';
import { createLimitOrder } from 'blockchain-api/limit-orders';
import { OrderStatusE, OrderTypeE } from 'enums';
import { addDateMonths } from 'utils/addDateMonths';
import {
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
} from 'utils/number-formatter';
import { selectedAthleteIAtom } from 'store/athletes.store';

import { useNewOrderMutation } from 'generated/graphql';

import {
  DEFAULT_WALLET,
  FinalStateE,
  getDefaultModalState,
  ModalStateI,
} from '../../hcx-card-confirmation-modal';
import { BuySellTemplate, BuySellTemplatePropsI } from '../buy_sell-tab-template';

export const HCXCardBuySellSell: FC = () => {
  const [selectedWallet, setSelectedWallet] = useState<WalletE>(DEFAULT_WALLET);
  const [modalState, setModalState] = useState<ModalStateI>(getDefaultModalState());

  const [athlete] = useAtom(selectedAthleteIAtom);
  const [newOrder] = useNewOrderMutation();
  const { t } = useTranslation('hcx-card');

  if (!athlete?.id || !athlete.tradingInfo.tokenAddress || !athlete.tradingInfo.stableCoinAddress) {
    return null;
  }

  const stableCoin = athlete.tradingInfo.stableCoinAddress;
  const tokenCoin = athlete.tradingInfo.tokenAddress;

  const handleSubmit: BuySellTemplatePropsI['onSubmit'] = (data) => {
    setModalState((prev) => ({
      ...prev,
      loading: true,
    }));

    console.table('===>>SELL:');
    console.table(data);

    if (data.orderType === OrderTypeE.Limit) {
      createLimitOrder({
        wallet: selectedWallet,
        stableCoin,
        tokenCoin,
        makerToken: tokenCoin,
        takerToken: stableCoin,
        makerAmount: data.amount * 100,
        takerAmount:
          normalizeDecimals(data.price, NormalizationDecimalsE.Six)[NormalizationLogicE.Decimal] *
          data.amount,
        expiry:
          data.orderGoodUntil === OrderStatusE.Canceled
            ? new Date(addDateMonths(new Date().toISOString(), 2))
            : new Date(data.orderGoodUntil),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newOrder: newOrder as any,
      })
        .then(() => {
          setModalState(() => ({
            loading: false,
            finalState: FinalStateE.Success,
          }));
          console.log('order created');
        })
        .catch((error) => {
          setModalState(() => ({
            loading: false,
            finalState: FinalStateE.Error,
          }));
          console.error(error);
        });
    } else {
      setModalState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleResetWallet = () => {
    setModalState(getDefaultModalState());
    setSelectedWallet(DEFAULT_WALLET);
  };

  const onWalletSelectChange = (wallet: WalletE) => {
    setSelectedWallet(wallet);
  };

  return (
    <BuySellTemplate
      athlete={athlete}
      onSubmit={handleSubmit}
      handleResetWallet={handleResetWallet}
      handleWalletSelectChange={onWalletSelectChange}
      submitButtonTitle={t('sell')}
      modalState={modalState}
    />
  );
};
