import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const AlertBoxIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M3.75 2.25H14.25C14.6478 2.25 15.0294 2.40804 15.3107 2.68934C15.592 2.97064 15.75 3.35218 15.75 3.75V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25M9.75 9.75V5.25H8.25V9.75H9.75ZM9.75 12.75V11.25H8.25V12.75H9.75Z"
      fill="#CB8804"
    />
  </SvgIcon>
);
