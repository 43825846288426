export const MOCK_WALLETS = [
  {
    id: 1,
    name: 'MetaMask',
  },
  {
    id: 2,
    name: 'Mycelium',
  },
  {
    id: 3,
    name: 'Exodus',
  },
  {
    id: 4,
    name: 'Cash App',
  },
  {
    id: 5,
    name: 'Wasabi',
  },
  {
    id: 6,
    name: 'Electrum',
  },
  {
    id: 7,
    name: 'Google Wallet',
  },
  {
    id: 8,
    name: 'Coinbase',
  },
];

export type MockWalletT = typeof MOCK_WALLETS[0];
