import { atom } from 'jotai';

export type FavoriteIdsT = string[];

export const favoriteAthleteIdsAtom = atom<FavoriteIdsT>([]);

export const addFavoriteAthleteIdAtom = atom(null, (get, set, newAthleteId: string) =>
  set(favoriteAthleteIdsAtom, [...get(favoriteAthleteIdsAtom), newAthleteId])
);

export const removeFavoriteAthleteIdAtom = atom(null, (get, set, athleteIdToRemove: string) => {
  const newFavoriteIds = [...get(favoriteAthleteIdsAtom)].filter((id) => id !== athleteIdToRemove);
  set(favoriteAthleteIdsAtom, newFavoriteIds);
});
