import { string, number } from 'yup';
import { COUNTRIES_AND_COMPANIES } from 'features/sponsor-application/sponsor-form-information/constants';
import { RequiredStringSchema } from 'yup/lib/string';
import { RequiredNumberSchema } from 'yup/lib/number';

export const sponsorFormSchema: Record<
  string,
  RequiredStringSchema<string | undefined> | RequiredNumberSchema<number | undefined>
> = {
  corporateName: string().required(),
  corporateType: string()
    .when('$sponsorInformation', (sponsorInformation, schema) => {
      if (!sponsorInformation.country) {
        return schema.createError({ message: 'No country chosen!' });
      }
      return schema;
    })
    .required('Please fill out this field'),
  streetAddress: string().required(),
  apartment: string().required(),
  city: string().required(),
  state: string().required(),
  zip: number().required(),
  country: string()
    .lowercase()
    .test('is-included', 'Country is not in range', function (value: string | undefined) {
      return Object.keys(COUNTRIES_AND_COMPANIES).includes(value as string);
    })

    .required('Please fill out this field'),
  phoneNumber: string().required(),
  websiteUrl: string().url().required(),
  agreementUrl: string().required(),
  ein: string().required(),
  emailAddress: string().email().required(),
  logoUrlForDarkBackground: string().required(),
  logoUrlForLightBackground: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  description: string().required(),
};
