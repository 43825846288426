import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatusE } from 'enums';
import { AthleteTradingOrderI } from 'types';

import { cancelLimitOrder, fetchLimitOrder } from 'blockchain-api/limit-orders';
import { fillOrder } from 'blockchain-api/limit-orders/fill-order';
import { AthleteOrders, AthleteOrdersPropsI } from 'components/athlete/athlete-orders';

import {
  FinalStateE,
  getDateStrForContent,
  getDefaultModalState,
  HCXCardConfirmationModal,
  ModalStateI,
  useConfirmationModal,
} from '../hcx-card-confirmation-modal';

type HCXCardOrdersPropsT = Pick<AthleteOrdersPropsI, 'athleteId'>;

interface SubmitTypeI extends AthleteTradingOrderI {
  cancellationDate: string;
}

const getFilledInfo = (data?: AthleteTradingOrderI | SubmitTypeI | null) =>
  data && data.status === OrderStatusE.Filled && data.filledDescription
    ? data.filledDescription
    : '';

export const HCXCardOrders: FC<HCXCardOrdersPropsT> = ({ athleteId }) => {
  const [modalState, setModalState] = useState<ModalStateI>(getDefaultModalState());

  const onSubmit = (data: SubmitTypeI) => {
    setModalState((prev) => ({
      ...prev,
      loading: true,
    }));

    fetchLimitOrder(`${data.id}`)
      .then((response) => {
        setModalState(() => ({
          loading: false,
          finalState: FinalStateE.Success,
        }));

        return cancelLimitOrder({ order: response.order as never });
      })
      .then(console.log)
      .catch((error) => {
        setModalState(() => ({
          loading: false,
          finalState: FinalStateE.Error,
        }));
        console.error(error);
      });
  };

  const {
    data: confirmationModalData,
    openModal,
    closeModal: handleCloseConfirmation,
    confirmModal: handleSubmit,
  } = useConfirmationModal<SubmitTypeI>({
    onConfirm: onSubmit,
  });

  const { t } = useTranslation('hcx-card');

  const getMenuOptions: AthleteOrdersPropsI['getMenuOptions'] = useCallback(
    (order) =>
      order.status === OrderStatusE.Canceled
        ? undefined
        : [
            {
              label: t('cancel'),
              action: () => openModal({ ...order, cancellationDate: new Date().toUTCString() }),
            },
            {
              label: 'fill',
              action: () => {
                fillOrder(order.id).then(console.log).catch(console.error);
              },
            },
          ],
    [t, openModal]
  );

  const modalFilledText = getFilledInfo(confirmationModalData);

  return (
    <>
      <AthleteOrders athleteId={athleteId} getMenuOptions={getMenuOptions} />
      <HCXCardConfirmationModal
        open={!!confirmationModalData}
        onClose={handleCloseConfirmation}
        onCancel={handleCloseConfirmation}
        onConfirm={handleSubmit}
        title={t('cancel-order')}
        modalState={modalState}
      >
        {!!confirmationModalData && (
          <>
            {`${t('cancel-order')} # ${confirmationModalData.number}`}
            <br />
            {confirmationModalData.buyDescription}
            <br />
            {modalFilledText ? (
              <>
                {modalFilledText}
                <br />
              </>
            ) : null}
            {getDateStrForContent(confirmationModalData.cancellationDate)}
          </>
        )}
      </HCXCardConfirmationModal>
    </>
  );
};
