import { Box, Typography } from '@mui/material';
import { MarkedLabel } from 'components/icons/marked-label-icon';
import { useTranslation } from 'react-i18next';
import styles from './hcx-profile.module.scss';
import { TabHcxAccordion } from '../tab-hcx-accordion';

export const HcxProfile = () => {
  const { t } = useTranslation('talent-application');

  return (
    <>
      <Box className={styles.description}>
        <Typography variant="subtitle2" component="span">
          {t('the-first-step-in-the-HCX-offering-process-is')}{' '}
        </Typography>
        <Box className={styles.dashedRow}>
          <Typography className={styles.textColor} variant="subtitle2" component="span">
            {t('unless-expressly-marked-with-a-public-icon')}{' '}
          </Typography>
          <MarkedLabel />
          {'.'}
        </Box>
      </Box>
      <TabHcxAccordion />
    </>
  );
};
