import { FC } from 'react';
import { AthleteI } from 'types';
import { WidgetProgressE } from 'pages/trade-desk/widget-wrapper';
import { WidgetAthleteListMediumRow } from './widget-athlete-list-medium-row';
import { useTranslation } from 'react-i18next';
import styles from './widget-athlete-list-medium.module.scss';

const WidgetAthleteListMedium: FC<{ athletes: AthleteI[]; progressType: WidgetProgressE }> = ({
  athletes,
  progressType,
}) => {
  const { t } = useTranslation('common');
  return (
    <table className={styles.root}>
      <thead>
        <tr>
          <th />
          <th />
          <th>{t('price')}</th>
          <th>{t('change')}</th>
        </tr>
      </thead>
      <tbody>
        {athletes.map((athlete) => (
          <WidgetAthleteListMediumRow
            key={athlete.id}
            athlete={athlete}
            progressType={progressType}
          />
        ))}
      </tbody>
    </table>
  );
};

export default WidgetAthleteListMedium;
