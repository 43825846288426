export interface ValidationSuccessI {
  valid: true;
}

export interface ValidationErrorI {
  valid: false;
  errors: string[];
}

export type ValidationResultT = ValidationSuccessI | ValidationErrorI;

export const validateEmpty = (value: unknown): ValidationResultT => {
  if (!value) {
    return {
      valid: false,
      errors: ["Can't be empty"],
    };
  }
  return {
    valid: true,
  };
};

export const validateCvvNumberFormat = (value: number): ValidationResultT => {
  if (value < 100 || value > 9999) {
    return {
      valid: false,
      errors: ['CVV has wrong format'],
    };
  }
  return {
    valid: true,
  };
};

export const validateCcNumberFormat = (value: string): ValidationResultT => {
  const splitArr = value.split(' ');
  if (!value || splitArr.length < 4) {
    return {
      valid: false,
      errors: ['Credit Card number has wrong format'],
    };
  }
  for (let i = 0; i < splitArr.length; i++) {
    if (splitArr[i].length < 4) {
      return {
        valid: false,
        errors: ['Credit Card number has wrong format'],
      };
    }
  }
  return { valid: true };
};

export const validatePair = <T>(
  value: T,
  firstCheck: (value: T) => ValidationResultT,
  secondCheck: (value: T) => ValidationResultT
) => {
  const firstCheckResult = firstCheck(value);
  const secondCheckResult = secondCheck(value);
  if (!firstCheckResult.valid && !secondCheckResult.valid) {
    return {
      valid: false,
      errors: firstCheckResult.errors.concat(secondCheckResult.errors),
    };
  }
  if (!firstCheckResult.valid) {
    return firstCheckResult;
  }
  if (!secondCheckResult.valid) {
    return secondCheckResult;
  }
  return { valid: true } as ValidationResultT;
};
