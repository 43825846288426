import type { FC } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';
import styles from './sponsor-form-sign.module.scss';

export const LegalInformation: FC = () => {
  const { t } = useTranslation('sponsor-application');
  return (
    <Box>
      <Typography className={styles.description}>{t('click-docusign-link-below')}</Typography>
      <Button variant="outlined" size="small" disabled>
        DocuSign
      </Button>
    </Box>
  );
};
