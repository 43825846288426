import { FC, useMemo, useContext, ReactNode } from 'react';
import { useTradingAthletesVar } from 'apolloClient/hooks';
import { TrendingWidgetContext } from '../context';
import { WidgetCardSizeE, WidgetSortE } from 'pages/trade-desk/widget-wrapper';
import {
  WidgetAthleteListLarge,
  WidgetAthleteListMedium,
  WidgetAthleteListSmall,
} from 'pages/trade-desk/widget-athlete-list';

const TrendingList: FC<{ noItems?: ReactNode }> = ({ noItems }) => {
  const { athletes } = useTradingAthletesVar();
  const { sort, cardSize, progressType } = useContext(TrendingWidgetContext);

  const sortedAthletes = useMemo(() => {
    return [...athletes].sort((a, b) => {
      let result = 0;
      if (a.bio.name > b.bio.name) result = 1;
      if (a.bio.name < b.bio.name) result = -1;
      return result * (sort === WidgetSortE.Asc ? 1 : -1);
    });
  }, [athletes, sort]);

  return sortedAthletes.length ? (
    <>
      {cardSize === WidgetCardSizeE.Large && (
        <WidgetAthleteListLarge athletes={sortedAthletes} progressType={progressType} />
      )}
      {cardSize === WidgetCardSizeE.Small && <WidgetAthleteListSmall athletes={sortedAthletes} />}
      {cardSize === WidgetCardSizeE.Medium && (
        <WidgetAthleteListMedium athletes={sortedAthletes} progressType={progressType} />
      )}
    </>
  ) : (
    <>{noItems}</>
  );
};

export default TrendingList;
