import type { FC } from 'react';

import { TalentPortalIhoCho } from '../talent-portal-iho-cho';
import { TalentPortalTradeDesk } from '../talent-portal-trade-desk';

export const TABS: { label: string; component: FC | null }[] = [
  { label: 'HCX Trade Desk', component: TalentPortalTradeDesk },
  { label: 'Rank', component: null },
  { label: 'Discord', component: null },
  { label: 'ICO/CCO', component: TalentPortalIhoCho },
];

export const tabLabels = TABS.map(({ label }) => label);
