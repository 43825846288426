import type { FC } from 'react';

import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import { IconButton } from '@mui/material';

import { ArrowBold } from 'components/design';
import { Panel } from 'components/panel';
import { TVChartContainer } from 'components/tv-chart-container';
import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';

import styles from './talent-portal-trade-desk-chart.module.scss';

const mocData = {
  currentPrice: 704.12,
  currentPriceGainPercentage: 0.18,
  tokensOutstanding: 7892,
  revenueSharedPercentage: 3,
  avgDailyRevenue: 400,
  shareHolders: 5500,
  volume24: 450000,
};

export const TalentPortalTradeDeskChart: FC = () => {
  const hasTrending = mocData.currentPriceGainPercentage !== 0;
  const isTrendingUp = mocData.currentPriceGainPercentage > 0;
  const trendingPriceStyle = mocData.currentPriceGainPercentage > 0 ? styles.green : styles.red;

  return (
    <Panel className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <table className={styles.headerInfoTable}>
            <tbody>
              <tr>
                <td>{hasTrending && <ArrowBold direction={isTrendingUp ? 'up' : 'down'} />}</td>
                <td>
                  <span className={hasTrending ? trendingPriceStyle : ''}>
                    {formatToUSD(mocData.currentPrice)}
                  </span>
                  {hasTrending && (
                    <span className={styles.percentage}>
                      (
                      {formatNumber(mocData.currentPriceGainPercentage, {
                        signDisplay: 'exceptZero',
                      })}
                      %)
                    </span>
                  )}
                </td>
                <td>{formatNumber(mocData.tokensOutstanding)}</td>
                <td>{formatNumber(mocData.revenueSharedPercentage)}%</td>
                <td>{formatToUSD(mocData.avgDailyRevenue, { minimumFractionDigits: 2 })}</td>
                <td>{formatNumber(mocData.shareHolders, { compact: true })}</td>
                <td>{formatToUSD(mocData.volume24)}</td>
              </tr>
              <tr>
                <th></th>
                <th>Current Price</th>
                <th>Tokens Outstanding</th>
                <th>% Revenue Shared</th>
                <th>Avg Daily Revenue</th>
                <th># Share Holders</th>
                <th>24h Volume</th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.infoItem}>
            <span className={styles.infoItemValue}>{}</span>
            <span className={styles.infoItemLabel}>{}</span>
          </div>
        </div>
        <div className={styles.headerActions}>
          <IconButton>
            <FileDownloadOutlinedIcon />
          </IconButton>
          <IconButton>
            <CameraAltOutlinedIcon />
          </IconButton>
          <IconButton>
            <ZoomOutMapOutlinedIcon />
          </IconButton>
        </div>
      </div>
      <TVChartContainer
        className={styles.chart}
        height={277}
        disableLeftToolbar
        symbol={''}
        tradingPairId=""
      />
    </Panel>
  );
};
