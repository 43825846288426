import { TickerPropsI } from './components';

export const MOCK_TICKERS: TickerPropsI[] = [
  { symbol: '$PORT', gain: 0.6 },
  { symbol: '$HCX', gain: -0.15 },
  { symbol: '$NFL', gain: 0.5 },
  { symbol: '$NBA', gain: -0.4 },
  { symbol: '$MLB', gain: -0.3 },
  { symbol: '$NCAA', gain: 0.7 },
];

export const MOCK_PLAYERS: TickerPropsI[] = [
  { symbol: 'T.BRA', gain: -0.4 },
  { symbol: 'M.SHI', gain: 0.15 },
  { symbol: 'S.RUI', gain: 0.75 },
  { symbol: 'F.MAR', gain: -0.1 },
  { symbol: 'L.TAY', gain: 0.2 },
];
