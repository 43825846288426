import type { FC } from 'react';

interface PropsI {
  className?: string;
}

export const GoogleLogo: FC<PropsI> = ({ className }) => (
  <svg className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1999 8.17599C15.1999 7.67999 15.1519 7.16799 15.0719 6.68799H8.14392V9.51999H12.1119C11.9519 10.432 11.4239 11.232 10.6399 11.744L13.0079 13.584C14.3999 12.288 15.1999 10.4 15.1999 8.17599Z"
      fill="#4280EF"
    />
    <path
      d="M8.14398 15.344C10.128 15.344 11.792 14.688 13.008 13.568L10.64 11.744C9.98398 12.192 9.13598 12.448 8.14398 12.448C6.22398 12.448 4.60798 11.152 4.01598 9.42395L1.58398 11.2959C2.83198 13.7759 5.35998 15.344 8.14398 15.344Z"
      fill="#34A353"
    />
    <path
      d="M4.01596 9.40798C3.71196 8.49598 3.71196 7.50398 4.01596 6.59198L1.58396 4.70398C0.543955 6.78398 0.543955 9.23198 1.58396 11.296L4.01596 9.40798Z"
      fill="#F6B704"
    />
    <path
      d="M8.14398 3.56798C9.18398 3.55198 10.208 3.95198 10.96 4.67198L13.056 2.55998C11.728 1.31198 9.96799 0.63998 8.14398 0.65598C5.35998 0.65598 2.83198 2.22398 1.58398 4.70398L4.01598 6.59198C4.60798 4.84798 6.22398 3.56798 8.14398 3.56798Z"
      fill="#E54335"
    />
  </svg>
);
