import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { Panel } from 'components/panel';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Tabs } from 'components/tabs';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TabPanel } from 'components/tab-panel';
import { CreditCardI, WalletViewModelI } from './types';
import { EWallets } from './e-wallets';
import { AddCreditCard } from './add-credit-card';
import styles from './base-wallet-card.module.scss';

interface PropsI {
  description: string;
  items: WalletViewModelI[];
  onClose: () => void;
  onItemClick: (id: string) => void;
  onAddCreditCard: (data: CreditCardI) => void;
}

export const BaseWalletCard: FC<PropsI> = ({
  onClose,
  description,
  onItemClick,
  items,
  onAddCreditCard,
}) => {
  const { t } = useTranslation('wallet-card');
  const LABELS = useMemo(() => [t('ewallet'), t('credit-card')], [t]);
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <Panel className={styles.root}>
      <Box className={styles.titleRow}>
        <Typography variant="subtitle2" fontWeight="600">
          {t('payment')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={styles.description}>
        <Typography variant="body1">{description}</Typography>
      </Box>
      <Tabs className={styles.tabs} value={activeTab} onChange={handleTabChange} labels={LABELS} />
      <TabPanel value={activeTab} index={0}>
        <EWallets onClick={onItemClick} items={items} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <AddCreditCard onSubmit={onAddCreditCard} />
      </TabPanel>
    </Panel>
  );
};
