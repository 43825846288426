import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Box, MenuItem, Typography, styled, Tooltip } from '@mui/material';

import { MenuPinIcon } from '../../design/navigation';
import { useRenderMenu } from '../trading-info/header-trading-info';
import { appMenuItemsConfig } from './menu-items';

import styles from './menu-bar.module.scss';
import { useAtom } from 'jotai';
import { tickerBarView } from 'store/trading-stats.store';
import { useCurrentRouteConfig } from 'hooks/useCurrentRouteConfig';
import { closeAthleteCardAtom } from 'store/athletes.store';

const enum PathTypesE {
  MINT = '/human-capital-mint',
}

const linkActiveClassName = 'active';

const getLinkClassName: NavLinkProps['className'] = ({ isActive }) =>
  isActive ? linkActiveClassName : '';

const StyledNavLink = styled(NavLink)({
  display: 'flex',
  width: '100%',
  height: '46px',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit',
  [`&.${linkActiveClassName}`]: {
    color: '#6AD1FF',
    [`& .${styles.menuItem}`]: {
      color: '#6AD1FF',
    },
  },
});

export const MenuBar: FC = () => {
  const { isMenuOpen, isMenuPinned, setMenuPinned } = useRenderMenu();
  const [isTickerBarOpened] = useAtom(tickerBarView);
  const [, closeAthleteCard] = useAtom(closeAthleteCardAtom);
  const routeConfig = useCurrentRouteConfig();

  const handleMenuPin = useCallback(() => {
    setMenuPinned(!isMenuPinned);
  }, [isMenuPinned, setMenuPinned]);

  const handleClickMenuItem = useCallback(
    (path) => {
      switch (path) {
        case PathTypesE.MINT:
          closeAthleteCard();
          break;
        default:
          break;
      }
    },
    [closeAthleteCard]
  );

  const isExpanded = isMenuOpen || isMenuPinned;

  if (!routeConfig?.isPrivate) {
    return null;
  }

  return (
    <Box
      className={classNames(styles.root, {
        [styles.root_open]: isExpanded,
        [styles.root_ticker_bar_opened]: isTickerBarOpened,
      })}
    >
      <Box className={styles.menuItems}>
        {appMenuItemsConfig.map(({ path, title, Icon }) => (
          <StyledNavLink
            onClick={() => handleClickMenuItem(path)}
            key={title}
            to={path}
            className={getLinkClassName}
          >
            <MenuItem
              className={classNames(styles.menuItem, { [styles.menuItem_expanded]: isExpanded })}
            >
              <Tooltip
                disableHoverListener={isExpanded}
                title={
                  <Typography color="var(--hcx-font-primary)" variant="body2">
                    {title}
                  </Typography>
                }
                placement={'right'}
              >
                <Box className={styles.iconHolder}>
                  <Icon />
                </Box>
              </Tooltip>
              <Typography variant="subtitle2" className={styles.menuTitle}>
                {title}
              </Typography>
            </MenuItem>
          </StyledNavLink>
        ))}
      </Box>
      <Box className={styles.actions}>
        <Box
          key="Pin Menu Open"
          className={classNames(styles.actionItem, { [styles.actionItem_active]: isMenuPinned })}
        >
          <MenuItem
            className={classNames(styles.menuItem, { [styles.menuItem_expanded]: isExpanded })}
            onClick={handleMenuPin}
          >
            <Box className={styles.iconHolder}>
              <MenuPinIcon />
            </Box>
            <Typography variant="subtitle2" className={styles.menuTitle}>
              Pin Menu Open
            </Typography>
          </MenuItem>
        </Box>
      </Box>
    </Box>
  );
};
