import { FC, ReactNode, Context } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import classNames from 'classnames';
import { RemoveCardIcon } from 'components/design';
import { CustomScrollbars } from 'components/custom-scrollbars';
import { Panel } from 'components/panel';
import styles from './widget-wrapper.module.scss';
import MenuIcon from './menu-icon/menu-icon';
import { WidgetContextProvider } from './widget-context';
import { WidgetContextI } from './widget-context/widget-context';

export interface WidgetWrapperPropsI {
  title: ReactNode;
  titleIcon?: ReactNode;
  additionalTitleActions?: ReactNode;
  additionalHeaderInfo?: ReactNode;
  className?: string;
  onCloseWidget?: () => void;
  context: Context<WidgetContextI>;
  hideSizer?: boolean;
}

const WidgetWrapper: FC<WidgetWrapperPropsI> = ({
  children,
  title,
  titleIcon,
  additionalTitleActions,
  additionalHeaderInfo,
  className,
  onCloseWidget,
  context,
  hideSizer,
}) => {
  return (
    <WidgetContextProvider context={context} hideSizer={hideSizer}>
      <Panel className={classNames(styles.root, className)}>
        <Box className={styles.header} component="header">
          {!!titleIcon && <Box className={styles.headerIcon}>{titleIcon}</Box>}
          <Typography variant="subtitle2" fontWeight={600} className={styles.title}>
            {title}
          </Typography>
          <Box className={styles.headerActions}>
            {additionalTitleActions}
            <MenuIcon context={context} />
            <IconButton size="small" onClick={onCloseWidget}>
              <RemoveCardIcon />
            </IconButton>
          </Box>
        </Box>
        {!!additionalHeaderInfo && (
          <Box className={styles.additionalHeaderInfo}>{additionalHeaderInfo}</Box>
        )}
        <CustomScrollbars>
          <Box className={styles.content}>{children}</Box>
        </CustomScrollbars>
      </Panel>
    </WidgetContextProvider>
  );
};

export default WidgetWrapper;
