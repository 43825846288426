import type { FC } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { AthleteI } from 'types';

interface HCXFinancialCardContextPropsI {
  athlete: AthleteI | null;
  inView: boolean;
}

const HCXFinancialCardContext = createContext<HCXFinancialCardContextPropsI>({
  athlete: null,
  inView: false,
});

export const useHCXFinancialCardContext = () => useContext(HCXFinancialCardContext);

interface HCXFinancialCardProviderPropsI {
  athlete: AthleteI;
  inView: boolean;
}

export const HCXFinancialCardProvider: FC<HCXFinancialCardProviderPropsI> = ({
  athlete,
  inView,
  children,
}) => {
  const value = useMemo(() => ({ athlete, inView }), [athlete, inView]);
  return (
    <HCXFinancialCardContext.Provider value={value}>{children}</HCXFinancialCardContext.Provider>
  );
};
