import { FC, ReactNode } from 'react';
import { Divider, Tab, Tabs, TabsProps } from '@mui/material';
import { a11yProps } from 'utils/a11y';

import styles from './hcx-card-tabs.module.scss';

interface HCXCardTabsPropsI {
  tabLabels: (string | ReactNode)[];
  currentTabIdx: number;
  onChangeTab: (tabIdx: number) => void;
}

const HCXCardTabs: FC<HCXCardTabsPropsI> = ({ tabLabels, onChangeTab, currentTabIdx }) => {
  const handleChange: TabsProps['onChange'] = (_, newValue: number) => {
    onChangeTab(newValue);
  };

  return (
    <>
      <div>
        <Tabs
          className={styles.root}
          value={currentTabIdx}
          onChange={handleChange}
          aria-label="hcx-card-tabs"
        >
          {tabLabels.map((label, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tab key={idx} label={label} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </div>
      <Divider />
    </>
  );
};

export default HCXCardTabs;
