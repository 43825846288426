import { WidgetProgressE } from 'pages/trade-desk/widget-wrapper';
import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';

export interface HeaderDataI {
  name: string;
  units: number;
  talent: number;
  value: number;
  gain: number;
  gainP: number;
}

export interface PlayerDataI extends Omit<HeaderDataI, 'talent'> {
  id: number | string;
  avgPrice: number;
}

export const MOCK_HEADER_DATA: HeaderDataI[] = [
  { name: '$PORT', units: 270, talent: 4, value: 240100, gain: 20.07, gainP: 0.07 },
];

export const MOCK_PLAYERS_DATA: PlayerDataI[] = [
  {
    id: 1,
    name: 'Patrick Mahomes',
    units: 27,
    avgPrice: 12.25,
    value: 140.18,
    gain: 2.07,
    gainP: 1.17,
  },
  {
    id: 2,
    name: 'Odel Beckahm Jr.',
    units: 38,
    avgPrice: 10,
    value: 163.18,
    gain: -4.12,
    gainP: -1.17,
  },
  { id: 3, name: 'Lexi Thompson', units: 29, avgPrice: 15, value: 140.18, gain: 5.07, gainP: 1.17 },
  {
    id: 4,
    name: 'Carmelo Anthony',
    units: 30,
    avgPrice: 8,
    value: 140.18,
    gain: -4.88,
    gainP: -1.17,
  },
  {
    id: 5,
    name: 'Carmelo Anthony',
    units: 30,
    avgPrice: 8,
    value: 140.18,
    gain: -4.88,
    gainP: -1.17,
  },
  {
    id: 6,
    name: 'Carmelo Anthony',
    units: 30,
    avgPrice: 8,
    value: 140.18,
    gain: 4.88,
    gainP: 1.17,
  },
  {
    id: 7,
    name: 'Carmelo Anthony',
    units: 30,
    avgPrice: 8,
    value: 140.18,
    gain: 4.88,
    gainP: 1.17,
  },
];

export const getProgress = (data: HeaderDataI | PlayerDataI, progressType: WidgetProgressE) =>
  progressType === WidgetProgressE.Currency
    ? formatToUSD(data.gain, { signDisplay: 'exceptZero' })
    : `${formatNumber(data.gainP, { signDisplay: 'exceptZero' })}%`;
