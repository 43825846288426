import type { FC } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ActivityPanelIcon } from 'components/icons/activity-panel-icon';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styles from './activity-header.module.scss';

export const ActivityHeader: FC<{ closePanel: () => void }> = ({ closePanel }) => {
  const { t } = useTranslation('sponsor-application');

  return (
    <Box className={styles.root}>
      <Box display="flex" justifyContent="space-between">
        <Box className={styles.row}>
          <ActivityPanelIcon />
          <Typography className={styles.headerTitle}>{t('activity')}</Typography>
        </Box>
        <IconButton onClick={closePanel}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
