const createKey = (id: string) => `${id}-grid-sizes`;

export const saveSizes = (gridId: string, sizes: Record<string, number>) => {
  try {
    const key = createKey(gridId);
    const savedJson = localStorage.getItem(key) ?? '{}';
    const savedSizes = JSON.parse(savedJson);
    const result = JSON.stringify({ ...savedSizes, ...sizes });
    localStorage.setItem(key, result);
  } catch (e) {
    console.error(e);
  }
};

export const restoreSizes = (gridId: string): Record<string, number> => {
  try {
    const key = createKey(gridId);
    const savedJson = localStorage.getItem(key) ?? '{}';
    return JSON.parse(savedJson);
  } catch (e) {
    console.error(e);
    return {};
  }
};
