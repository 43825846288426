import { ReactNode, useCallback, useContext, useEffect } from 'react';
import { RenderToHeaderContext } from './context';

export const useRenderToHeaderTradingInfo = (
  content: ReactNode | null,
  leftContent: ReactNode | null
) => {
  const { setContent, setLeftContent } = useContext(RenderToHeaderContext);

  useEffect(() => {
    setContent(content);
    setLeftContent(leftContent);
  }, [content, setContent, leftContent, setLeftContent]);

  const clear = useCallback(() => {
    setContent(null);
    setLeftContent(null);
  }, [setContent, setLeftContent]);

  return { clear };
};
