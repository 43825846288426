import { config } from 'config';
import { LimitOrderResponseRecordI } from './types';

const FETCH_ORDER_ENDPOINT = `${config.zeroXApiBaseUrl}/orderbook/v1/order`;

export const fetchLimitOrder = async (orderHash: string): Promise<LimitOrderResponseRecordI> => {
  const r = await fetch(`${FETCH_ORDER_ENDPOINT}/${orderHash}`, {
    headers: {
      'content-type': 'application/json',
    },
  });

  if (r.status === 200) {
    return r.json();
  }

  throw new Error(await r.json());
};
