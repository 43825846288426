import type { FC } from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';

import { Typography } from '@mui/material';

import { formatNumber } from 'utils/number-formatter';

import { OrderBookItemI, OrderTypeT } from '../../../order-book.types';
import { getRowStyles } from './order-book-table-row.helpers';

import styles from './order-book-table-row.module.scss';

interface TableRowWithAnimationPropsI {
  data: OrderBookItemI;
  orderType: OrderTypeT;
}

export const TableRowWithAnimation: FC<TableRowWithAnimationPropsI> = ({
  data: { fill, total: notFormattedTotal, size, price: notFormattedPrice },
  orderType,
}) => {
  const price = formatNumber(notFormattedPrice);
  const total = formatNumber(notFormattedTotal);
  const style = useMemo(() => getRowStyles(fill, orderType), [fill, orderType]);

  const isBidOrder = orderType === 'bid';

  const [resultTotal, resultSize, resultPrice] = isBidOrder
    ? [total, size, price]
    : [price, size, total];

  return (
    <tr key={`${fill}`} style={style} className={classNames(styles.root, styles[orderType])}>
      <td>
        <Typography variant="body1" color="inherit">
          {resultTotal}
        </Typography>
      </td>
      <td>
        <Typography variant="body1">{resultSize}</Typography>
      </td>
      <td>
        <Typography variant="body1" color="inherit">
          {resultPrice}
        </Typography>
      </td>
    </tr>
  );
};
