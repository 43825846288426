import { FC } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';

import styles from './athlete-card-gradient.module.scss';

const AthleteCardGradient: FC<{ className?: string }> = ({ children, className }) => (
  <Box className={classNames(styles.root, className)}>{children}</Box>
);

export default AthleteCardGradient;
