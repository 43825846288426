import { IconButton, IconButtonProps, Menu, MenuProps, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { FC, useMemo, useState } from 'react';

import styles from './athlete-orders.module.scss';

export interface MenuIconOptionI {
  label: string;
  action: () => void;
}

interface MenuIconPropsI {
  options?: MenuIconOptionI[];
}

const MenuListProps: MenuProps['MenuListProps'] = {
  'aria-labelledby': 'button',
};

const anchorOrigin: MenuProps['anchorOrigin'] = { vertical: 'bottom', horizontal: 'right' };
const transformOrigin: MenuProps['transformOrigin'] = { vertical: 'top', horizontal: 'right' };

const MenuIcon: FC<MenuIconPropsI> = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const optionsExists = Boolean(options?.length);

  const handleClick: IconButtonProps['onClick'] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptions = useMemo(() => options || [], [options]);

  return (
    <>
      <IconButton className={styles.more} onClick={!optionsExists ? undefined : handleClick}>
        <MoreHorizIcon />
      </IconButton>
      {optionsExists && (
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={MenuListProps}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {menuOptions.map(({ label, action }) => (
            <MenuItem key={label} onClick={action}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default MenuIcon;
