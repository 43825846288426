import { FC, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './filters.module.scss';
import { Button } from 'components/button';
import { SECTIONS } from './constants';
import { Section } from './section';
import { useTranslation } from 'react-i18next';

interface PropsI {
  onChange: () => void;
  onClose: () => void;
}

const initialState: Record<string, number | string | boolean> = {
  assetClass: '',
  assetSubClass: '',
  position: '',
  age: '',
  gender: '',
  ethnicity: '',
  weekLow: '',
  weekHigh: '',
  dayVolume: '',
};

export const Filters: FC<PropsI> = ({ onClose }) => {
  const [state, setState] = useState(initialState);
  const clearFilters = useCallback(() => {
    setState(initialState);
  }, []);
  const { t } = useTranslation('human-capital-mint');
  return (
    <Box className={styles.container}>
      <Box className={styles.titleRow}>
        <Box pl="8px">
          <Typography variant="subtitle2">{t('filters')}</Typography>
        </Box>
        <Box>
          <Button variant="text" onClick={clearFilters}>
            {t('clear-all-filters')}
          </Button>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className={styles.filters}>
        {SECTIONS.map(({ title, fields }) => (
          <Section title={title} key={title} fields={fields} data={state} onChange={setState} />
        ))}
      </Box>
    </Box>
  );
};
