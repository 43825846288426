import { WalletE } from './types';
import { EventEmitter } from 'events';

export const emitter = new EventEmitter();
const KEY = 'hcx-wallets';
const DEFAULT_KEY = 'hcx-default-wallet';

export const getConnectedWallets = (): WalletE[] => {
  const string = localStorage.getItem(KEY) ?? '[]';
  return JSON.parse(string);
};

export const saveConnectedWallet = (wallet: WalletE) => {
  const newValue = Array.from(new Set([...getConnectedWallets(), wallet]));
  localStorage.setItem(KEY, JSON.stringify(newValue));
  emitter.emit('change', newValue);
};

export const deleteConnectedWallet = (wallet: WalletE) => {
  const newValue = getConnectedWallets().filter((w) => w !== wallet);
  localStorage.setItem(KEY, JSON.stringify(newValue));
  emitter.emit('change', newValue);
};

export const saveDefaultWallet = (wallet: WalletE) => {
  localStorage.setItem(DEFAULT_KEY, wallet);
};

export const getDefaultWallet = (): WalletE | null =>
  localStorage.getItem(DEFAULT_KEY) as WalletE | null;
