import type { ReactNode } from 'react';
import type { TFunction } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { UserAvatar } from 'components/user-avatar';

import {
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
} from 'utils/number-formatter';

import { OrderT } from 'types/orders';

import { formatToUSD } from 'utils/currency-formatter';
import { OrderStatusE, OrderTypeE } from 'enums';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';

interface UserOrdersConfigItemI {
  title: string;
  getContent: (order: OrderT, t: TFunction) => ReactNode;
}

export const userOrdersConfig: UserOrdersConfigItemI[] = [
  {
    title: 'talent',
    getContent: (order) => {
      return (
        <Box display="flex" alignItems="center">
          <UserAvatar photo={order.tradingPair.athlete.imageUrl} />
          <Box ml={2.5}>
            <Typography variant="body1" whiteSpace="nowrap">
              {order.tradingPair.athlete.fullName}
            </Typography>
            <Typography variant="body1">{order.tradingPair.athlete.nftSymbol}</Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    title: 'order',
    getContent: (order, t) => {
      return <Typography variant="body1">{t(order.isBuy ? 'Buy' : 'Sell')}</Typography>;
    },
  },
  {
    title: 'number-of-shares',
    getContent: (order) => {
      return (
        <Typography variant="body1">
          {order.isBuy
            ? normalizeDecimals(order.takerAmount, NormalizationDecimalsE.Two)[
                NormalizationLogicE.NonDecimal
              ]
            : normalizeDecimals(order.amountOfTokens, NormalizationDecimalsE.Two)[
                NormalizationLogicE.NonDecimal
              ]}
        </Typography>
      );
    },
  },
  {
    title: 'price',
    getContent: (order) => {
      return (
        <Typography variant="body1">
          {order.isBuy
            ? formatToUSD(order.amountOfTokens / order.takerAmount / Math.pow(10, 4), {
                minimumFractionDigits: 2,
              })
            : formatToUSD(order.takerAmount / order.amountOfTokens / Math.pow(10, 4), {
                minimumFractionDigits: 2,
              })}
        </Typography>
      );
    },
  },
  {
    title: 'type',
    getContent: (_, t) => {
      return <Typography variant="body1">{t(OrderTypeE.Limit)}</Typography>;
    },
  },
  {
    title: 'expire',
    getContent: (order) => {
      return (
        <Typography variant="body1">
          {dateFormatter(order.expiry, DateFormatE.M_D_YY_HH_MM_SS_UTC)}
        </Typography>
      );
    },
  },
  {
    title: 'order-timestamp',
    getContent: (order) => {
      return (
        <Typography variant="body1">
          {dateFormatter(order.date, DateFormatE.M_D_YY_HH_MM_SS_UTC)}
        </Typography>
      );
    },
  },
  {
    title: 'status',
    getContent: (_, t) => {
      return <Typography variant="body1">{t(OrderStatusE.Open)}</Typography>;
    },
  },
  {
    title: 'value',
    getContent: (order) => {
      return (
        <Typography variant="body1">
          {order.isBuy
            ? formatToUSD(order.amountOfTokens / order.takerAmount / Math.pow(10, 3), {
                minimumFractionDigits: 2,
              })
            : formatToUSD(order.takerAmount / order.amountOfTokens / Math.pow(10, 3), {
                minimumFractionDigits: 2,
              })}
        </Typography>
      );
    },
  },
];
