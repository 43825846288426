import { MetamaskSubprovider, Subprovider } from '@0x/subproviders';

import { WalletE } from '../ewallets-api';

const subproviders = {
  [WalletE.Metamask]: MetamaskSubprovider,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getSubprovider = <T extends Subprovider>(wallet: WalletE): T => subproviders[wallet];
