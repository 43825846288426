import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  IconButton,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { HandCoinOutlineIcon } from 'components/icons/hand-coin-outline';
import { Paginator } from 'components/paginator';
import { Panel } from 'components/panel';
import { TableHeadCell, TableHeadRow } from 'components/table';
import { Select } from 'components/select';
import { TableColumnsIcon } from 'components/icons/table-columns-icon';
import { MenuItem } from 'components/select/menu-item';
import {
  ActiveMintsQuery,
  DutchAuction,
  DutchAuctionOrderByField,
  OrderDirection,
} from 'generated/graphql';
import { QueryPagingI } from 'types';

import { ColumnConfiguration } from '../../column-configuration';
import { Filters } from '../../filters';
import { ActiveMintsTableRow } from './active-mints-table-row';
import { AuctionRowDataI } from './types';

import styles from './active-mints-table.module.scss';

interface TableHeadEntryI {
  title: string;
  key: string;
  orderKey: DutchAuctionOrderByField;
}

const TABLE_HEAD_CELLS: TableHeadEntryI[] = [
  { title: 'Talent', key: 'name', orderKey: DutchAuctionOrderByField.Empty },
  { title: 'Type', key: 'type', orderKey: DutchAuctionOrderByField.Empty },
  { title: 'Minting Status', key: 'mintingStatus', orderKey: DutchAuctionOrderByField.Empty },
  { title: '# Bidders', key: 'numberOfBidders', orderKey: DutchAuctionOrderByField.Empty },
  { title: 'High Bid', key: 'highBid', orderKey: DutchAuctionOrderByField.Empty },
  { title: 'Closing Bid', key: 'closingBid', orderKey: DutchAuctionOrderByField.EndPrice },
  { title: 'Threshold Price', key: 'thresholdPrice', orderKey: DutchAuctionOrderByField.Empty },
  { title: '% Revenue', key: 'revenue', orderKey: DutchAuctionOrderByField.Empty },
  {
    title: 'Auction Start Time',
    key: 'auctionStartTime',
    orderKey: DutchAuctionOrderByField.StartDate,
  },
  { title: 'Auction End Time', key: 'auctionEndTime', orderKey: DutchAuctionOrderByField.Empty },
  { title: 'Time Remaining', key: 'timeRemaining', orderKey: DutchAuctionOrderByField.Empty },
];

const arrowStyles = { width: '20px', height: '20px' };

const rowsPerPageOptions = [10, 20, 50, 100];

interface ActiveMintsTablePropsI {
  tableData: ActiveMintsQuery;
  selectedRow: string | null;
  tableHeight: number;
  onRowClick: (auctionData: AuctionRowDataI) => void;
  handlePaginationChange: (pagination: QueryPagingI) => void;
  setOrderProps: (orderKey: DutchAuctionOrderByField, orderDirection: OrderDirection) => void;
}

export const ActiveMintsTable: FC<ActiveMintsTablePropsI> = ({
  tableData,
  selectedRow,
  tableHeight,
  onRowClick,
  handlePaginationChange,
  setOrderProps,
}) => {
  const [columnConfig, setColumnConfig] = useState('default');
  const [columnConfigOpen, setColumnConfigOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [order, setOrder] = useState<OrderDirection>(OrderDirection.Asc);
  const [orderBy, setOrderBy] = useState('');

  const { t } = useTranslation('human-capital-mint');

  const handleRequestSort = (property: TableHeadEntryI) => {
    if (property.orderKey) {
      const isAsc = orderBy === property.key && order === OrderDirection.Asc;
      setOrder(isAsc ? OrderDirection.Desc : OrderDirection.Asc);
      setOrderBy(property.key);
      setOrderProps(property.orderKey, isAsc ? OrderDirection.Desc : OrderDirection.Asc);
    }
  };

  const orderArrowDecider = (isAvailableForOrdering: boolean, isAsc: boolean) => {
    if (isAvailableForOrdering) {
      if (isAsc) {
        return <ArrowDropUp sx={arrowStyles} />;
      } else {
        return <ArrowDropDown sx={arrowStyles} />;
      }
    }
    return null;
  };

  return (
    <>
      <Box className={classNames(styles.panelWrapper)}>
        <Panel
          className={classNames(
            styles.mintWrapper,
            (columnConfigOpen || filtersOpen) && styles.mintWrapperViewConfigOpen
          )}
        >
          <Box className={styles.heading}>
            <Box className={styles.headingFlexWrapper}>
              <HandCoinOutlineIcon />
              <Typography variant="subtitle1" className={styles.headingTitle}>
                {t('active-mints')}
              </Typography>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                <Select<string>
                  variant="filled"
                  renderValue={() => (
                    <Box display="flex" alignItems="center">
                      <TableColumnsIcon />
                      <Typography fontSize="10px" ml="8px">
                        {t('default-view')}
                      </Typography>
                    </Box>
                  )}
                  value={columnConfig}
                  onChange={(e) => setColumnConfig(e.target.value)}
                >
                  <MenuItem value="default" divider>
                    {t('default-view')}
                  </MenuItem>
                  <MenuItem value="custom-1">Custom view 1</MenuItem>
                  <MenuItem value="custom-2">Custom 2</MenuItem>
                </Select>
                <IconButton onClick={() => setColumnConfigOpen((s) => !s)}>
                  <TableColumnsIcon />
                </IconButton>
                <IconButton onClick={() => setFiltersOpen((s) => !s)}>
                  <FilterListIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box className={styles.table}>
            <TableContainer sx={{ maxHeight: tableHeight }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableHeadRow>
                    {TABLE_HEAD_CELLS.map((cell) => (
                      <TableHeadCell onClick={() => handleRequestSort(cell)} key={cell.key}>
                        <Box height="40px" display="flex" alignItems="flex-end">
                          <Typography variant="caption" display="block">
                            {cell.title}
                          </Typography>
                          {orderArrowDecider(
                            cell.orderKey !== ('' as DutchAuctionOrderByField),
                            order === OrderDirection.Asc && orderBy === cell.key
                          )}
                        </Box>
                      </TableHeadCell>
                    ))}
                  </TableHeadRow>
                </TableHead>
                <TableBody>
                  {tableData.dutchAuctions.data
                    .filter((entry): entry is DutchAuction => !!entry)
                    .map((entry) => (
                      <ActiveMintsTableRow
                        key={entry.id}
                        entry={entry}
                        selectedRow={selectedRow}
                        onRowClick={onRowClick}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Paginator
              pagination={tableData.dutchAuctions.pagination}
              handlePaginationChange={handlePaginationChange}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </Box>
        </Panel>
        {columnConfigOpen && (
          <Panel className={styles.config}>
            <ColumnConfiguration onClose={() => setColumnConfigOpen(false)} />
          </Panel>
        )}
        {filtersOpen && (
          <Panel className={styles.config}>
            <Filters onChange={() => {}} onClose={() => setFiltersOpen(false)} />
          </Panel>
        )}
      </Box>
    </>
  );
};
