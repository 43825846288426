import { useAtom } from 'jotai';
import type { FC } from 'react';

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';

import { useCloseAthleteFinancialCard, useRotateAthleteFinancialCard } from 'apolloClient/hooks';
import { BookOpenOutline } from 'components/design';
import { openAthleteCardAtom } from 'store/athletes.store';

import { useHCXFinancialCardContext } from '../hcx-financial-card-context';

import styles from './hcx-financial-card-header.module.scss';

export const HCXFinancialCardHeader: FC = () => {
  const { athlete } = useHCXFinancialCardContext();
  const { rotate } = useRotateAthleteFinancialCard(athlete?.id ?? 0);
  const { close } = useCloseAthleteFinancialCard(athlete?.id ?? 0);

  const [, openAthleteCard] = useAtom(openAthleteCardAtom);

  const handleOpenAthleteCard = () => {
    if (athlete) {
      openAthleteCard({ athlete });
    }
  };

  return (
    <div className={styles.root}>
      <IconButton aria-label="rotate" onClick={rotate} size="small">
        <ThreeSixtyIcon />
      </IconButton>
      <IconButton
        aria-label="info"
        onClick={handleOpenAthleteCard}
        className={styles.infoIcon}
        size="small"
      >
        <BookOpenOutline />
      </IconButton>
      <IconButton aria-label="close" onClick={close} size="small">
        <CloseIcon />
      </IconButton>
    </div>
  );
};
