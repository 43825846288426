import { Tab, Tabs, TabsProps } from '@mui/material';
import { FC } from 'react';
import { a11yProps } from 'utils/a11y';
import { useTalentPortalTabsContext } from './talent-portal-tabs.context';
import { tabLabels } from './talent-portal-tabs.helpers';

const TalentPortalTabs: FC = () => {
  const { tab, setTab } = useTalentPortalTabsContext();

  const handleChange: TabsProps['onChange'] = (_, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Tabs value={tab} onChange={handleChange} aria-label="talent-portal-tabs">
      {tabLabels.map((label, idx) => (
        <Tab key={label} label={label} {...a11yProps(idx)} />
      ))}
    </Tabs>
  );
};

export default TalentPortalTabs;
