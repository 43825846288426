import { BidI } from './components/active-mints-chart/active-mints-chart';

import {
  AuctionAllBidsQuery,
  DutchAuction,
  DutchAuctionBidFieldsFragment,
  DutchAuctionStatus,
  SponsorStatus,
} from 'generated/graphql';
import { AuctionRowDataI } from './components/active-mints-table/types';
import { LocaleE } from 'enums';

import { getConnectedWallets, getWalletProvider, WalletE } from 'blockchain-api/ewallets-api';

import { addDateDays } from 'utils/addDateDays';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';
import { convertAthleteToAthleteI } from 'utils/convertAthleteToAthleteI';

export const getDefaultState = (): AuctionRowDataI => ({
  athleteId: '',
  dutchAuctionId: '',
  blockchainId: 0,
  lowestPrice: 0,
  endPrice: 0,
  mintingStatus: DutchAuctionStatus.Scheduled,
  revenuePercentage: 0,
  numBidders: 0,
  averageBid: 0,
  fractions: 0,
  sponsorStatus: SponsorStatus.Pending,
  minimumTotalIhoProceeds: 0,
  startDate: new Date(),
  endDate: new Date(),
});

export const constructModel = (data: Array<DutchAuctionBidFieldsFragment | null>): BidI[] => {
  const bids: BidI[] = [];
  let total = 0;
  data.forEach((bid) => {
    if (bid) {
      total += bid.tokenAmount;
    }
  });
  data.forEach((bid) => {
    if (bid) {
      const i = bids.findIndex((e) => e.price === bid.tokenPrice);
      if (i > -1) {
        bids[i].amounts.push(bid.tokenAmount);
      } else {
        bids.push({
          bidderAddress: bid.bidderAddress,
          price: bid.tokenPrice,
          total: total,
          amounts: [bid.tokenAmount],
        });
      }
    }
  });
  return bids.sort(({ price: a }, { price: b }) => b - a);
};

export const addBidToModel = (bids: BidI[], latestBid: DutchAuctionBidFieldsFragment): BidI[] => {
  const prevTotal = bids[0]?.total ?? 0;
  // TODO: VOV: Apply logic for bid remove

  bids.forEach((e) => (e.total = prevTotal - latestBid.tokenAmount));
  return bids;
};

export const removeBidFromModel = (
  bids: BidI[],
  latestBid: DutchAuctionBidFieldsFragment
): BidI[] => {
  const prevTotal = bids[0]?.total ?? 0;

  const i = bids.findIndex((e) => e.price === latestBid.tokenPrice);
  if (i > -1) {
    bids[i].amounts.push(latestBid.tokenAmount);
  } else {
    bids.push({
      bidderAddress: latestBid.bidderAddress,
      price: latestBid.tokenPrice,
      total: 0,
      amounts: [latestBid.tokenAmount],
    });
  }
  bids.forEach((e) => (e.total = prevTotal - latestBid.tokenAmount));
  return bids;
};

export const getEndDate = (startDate: string, duration: number) => {
  const endDate = addDateDays(startDate, duration);
  return dateFormatter(endDate, DateFormatE.MM_DD_YYYY_HH_MM, LocaleE.en_US);
};

export const getAuctionStatus = (
  blockchainId: number,
  status: DutchAuctionStatus,
  startDate: string,
  durationDays: number
) => {
  if (status === DutchAuctionStatus.Closed) {
    return status;
  }

  const parsedCurrentDate = Date.parse(new Date().toUTCString());
  const parsedStartDate = Date.parse(startDate);
  if (parsedStartDate > parsedCurrentDate) {
    return DutchAuctionStatus.Scheduled;
  }

  const parsedEndDate = Date.parse(getEndDate(startDate, durationDays));
  if (
    blockchainId > 0 &&
    parsedStartDate < parsedCurrentDate &&
    parsedEndDate > parsedCurrentDate
  ) {
    return DutchAuctionStatus.Active;
  }

  if (blockchainId === 0 && parsedEndDate > parsedCurrentDate) {
    return DutchAuctionStatus.Scheduled;
  }

  return DutchAuctionStatus.Closed;
};

export const getAuctionRowData = (rowData: DutchAuction): AuctionRowDataI => ({
  athleteId: rowData.athleteContract.athlete.id,
  athlete: convertAthleteToAthleteI(rowData.athleteContract.athlete),
  dutchAuctionId: rowData.id,
  blockchainId: Number(rowData.blockchainId),
  lowestPrice: rowData.floorPrice,
  endPrice: rowData.endPrice,
  mintingStatus: getAuctionStatus(
    Number(rowData.blockchainId),
    rowData.status,
    rowData.startDate,
    rowData.durationDays
  ),
  revenuePercentage: rowData.athleteContract.revenuePercentage,
  numBidders: rowData.stats?.numBidders ?? 0,
  averageBid: rowData.stats?.averageBid ?? 0,
  fractions: rowData.fractions,
  sponsorStatus: rowData.sponsorStatus, // TODO: MJO: Deprecated. Remove in future commit.
  minimumTotalIhoProceeds: rowData.minimumTotalProceeds,
  startDate: new Date(rowData.startDate),
  endDate: new Date(addDateDays(rowData.startDate, rowData.durationDays)),
});

export const getUserBids = async (data: AuctionAllBidsQuery) => {
  const wallet = getConnectedWallets()[0] || WalletE.Metamask;
  const provider = getWalletProvider(wallet);
  const accounts = await provider.request({
    method: 'eth_requestAccounts',
  });
  const userWalletAddress: string = accounts[0];
  if (!userWalletAddress) {
    return [];
  }
  return data.bids
    .filter((bid): bid is DutchAuctionBidFieldsFragment => !!bid)
    .filter((bid) => {
      return bid.bidderAddress.toLowerCase() === userWalletAddress;
    });
};
