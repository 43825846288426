import { config } from 'config';

import { createClient } from 'graphql-ws';
import { split, ApolloLink, from } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createUploadLink } from 'apollo-upload-client';
import { getAuthToken } from 'utils/getAuthToken';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let activeSocket: any, timedOut: NodeJS.Timeout;

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.graphQlSubscriptionApi,
    keepAlive: 10_000,
    connectionParams: () => ({
      authorization: `Bearer ${getAuthToken()}`,
    }),
    on: {
      opened: (socket) => (activeSocket = socket),
      ping: (received) => {
        if (!received /* sent */) {
          timedOut = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN)
              activeSocket.close(4408, 'Request Timeout');
          }, 5_000); // wait 5 seconds for the pong and then close the connection
        }
      },
      pong: (received) => {
        if (received) {
          clearTimeout(timedOut); // pong is received, clear connection close timeout
        }
      },
    },
  })
);

const httpLink = createUploadLink({
  uri: config.graphQlApi,
  headers: {
    authorization: `Bearer ${getAuthToken()}`,
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${getAuthToken()}`,
    },
  }));

  return forward(operation);
});

export const clientLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  from([authMiddleware, httpLink])
);
