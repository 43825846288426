import { useState, useCallback, useRef, useEffect } from 'react';

interface UseConfirmationModalPropsI<T> {
  confirmDisabled?: boolean;
  onConfirm: (data: T) => void;
  onClose?: () => void;
}
interface UseConfirmationModalReturnTypeI<T> {
  data: T | null;
  openModal: (data: T) => void;
  closeModal: () => void;
  confirmModal: () => void;
}

export const useConfirmationModal = <T>({
  confirmDisabled,
  onConfirm,
  onClose,
}: UseConfirmationModalPropsI<T>): UseConfirmationModalReturnTypeI<T> => {
  const scrollTimeoutRef = useRef<NodeJS.Timeout>();
  const [confirmationModalData, setConfirmationModalData] = useState<T | null>(null);

  useEffect(
    () => () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    },
    []
  );

  const open = useCallback(
    (data: T) => {
      if (confirmDisabled) return;
      setConfirmationModalData(data);
      scrollTimeoutRef.current = setTimeout(
        () => window.scrollTo({ top: 0, behavior: 'smooth' }),
        0
      );
    },
    [confirmDisabled]
  );

  const close = useCallback(() => {
    setConfirmationModalData(null);
    onClose && onClose();
  }, [onClose]);

  const confirm = useCallback(() => {
    if (!confirmationModalData) return;
    onConfirm(confirmationModalData);
  }, [confirmationModalData, onConfirm]);

  return {
    data: confirmationModalData,
    openModal: open,
    closeModal: close,
    confirmModal: confirm,
  };
};
