import type { FC, ReactNode } from 'react';
import { Accordion, AccordionSummary } from 'components/accordion';
import { AccordionDetails, Box } from '@mui/material';
import styles from './accordion-section.module.scss';

const ACCORDION_CLASSES = {
  root: styles.accordion,
  expanded: styles.expanded,
  disabled: styles.accordionDisabled,
};

const ACCORDION_SUMMARY_CLASSES = {
  root: styles.summary,
  content: styles.summaryContent,
  expandIconWrapper: styles.summaryIcon,
  disabled: styles.summaryDisabled,
};

const ACCORDION_DETAILS_CLASSES = {
  root: styles.details,
};

export const AccordionSection: FC<{
  expand: boolean;
  handleChangeExpanded?: () => void;
  summary: ReactNode;
  details?: ReactNode;
  children?: ReactNode;
}> = ({ expand, handleChangeExpanded, summary, details, children }) => {
  return (
    <Accordion expanded={expand} onChange={handleChangeExpanded} classes={ACCORDION_CLASSES}>
      <AccordionSummary classes={ACCORDION_SUMMARY_CLASSES}>{summary}</AccordionSummary>
      <AccordionDetails classes={ACCORDION_DETAILS_CLASSES}>
        <Box className={styles.wrapper}>
          {details}
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
