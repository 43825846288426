import type { FC } from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { Alert, Box, Slide } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAtom } from 'jotai';
import { MessageAtom } from 'store/message-alert.store';
import styles from './message-alert.module.scss';

const ALERT_CLASSES = {
  root: styles.errorContainer,
  icon: styles.iconWrapper,
  action: styles.action,
  message: styles.message,
  outlinedError: styles.outlinedError,
  outlinedSuccess: styles.outlinedSuccess,
};

export const MessageAlert: FC = () => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useAtom(MessageAtom);

  const closePopup = useCallback(() => {
    setOpen(false);
    setError({ message: '' });
  }, [setError]);

  const openPopup = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    if (error.message !== '') {
      openPopup();
      setTimeout(closePopup, 5000);
    }
  }, [closePopup, error, openPopup]);

  if (!open) {
    return null;
  }

  return (
    <Box ref={containerRef} className={styles.root}>
      <Slide direction="up" in={open} container={containerRef.current} className={styles.root}>
        <Alert
          severity={error.type}
          variant="outlined"
          classes={ALERT_CLASSES}
          action={
            <IconButton size="small" onClick={closePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {error.message}
        </Alert>
      </Slide>
    </Box>
  );
};
