import { FC } from 'react';
import { Stack } from '@mui/material';
import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'auth/use-auth';
import { LoggedInAuthContextI } from 'auth/auth-context';
import styles from './account-profile-change-password.module.scss';

const AccountProfileChangePassword: FC = () => {
  const { t } = useTranslation('account-profile');
  const { restorePassword } = useAuth() as LoggedInAuthContextI;

  return (
    <div>
      <span className={styles.description}>{t('change-password-description')}</span>
      <Stack direction="row" spacing="10px" className={styles.actions}>
        <Button
          variant="outlined"
          size="small"
          className={styles.actionBtn}
          onClick={restorePassword}
        >
          {t('reset-password')}
        </Button>
      </Stack>
    </div>
  );
};

export default AccountProfileChangePassword;
