import MuiMenuItem from '@mui/material/MenuItem';
import styles from './menu-item.module.scss';
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem';
import { FC } from 'react';

const CLASSES = {
  selected: styles.selected,
  root: styles.root,
  divider: styles.divider,
};

export const MenuItem: FC<MenuItemProps> = (props) => {
  return <MuiMenuItem classes={CLASSES} disableRipple {...props} />;
};
