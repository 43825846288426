export enum AthleteRosterStatusE {
  Active = 'Active',
}

export enum AthleteBidStatusE {
  Offering = 'Offering',
  CCO = 'CCO',
  ICO = 'ICO',
}

export enum AthleteIcoStatusE {
  Active = 'Active',
}
