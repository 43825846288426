/* eslint-disable @typescript-eslint/no-explicit-any */

import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface ThemeOptions {
    custom?: {
      background?: string;
      header?: string;
      dividers?: string;
      cards?: string;
      panels?: string;
      buttons?: string;
      links?: string;
    };
    icons?: {
      primary?: string;
      secondary?: string;
    };
  }
}

const theme = createTheme({
  custom: {
    background: '#0C0C18',
    header: '#0C0C18',
    dividers: '#0C0C18',
    cards: '#161A24',
    panels: '#161A24',
    buttons: '#6AD1FF',
    links: '#6AD1FF',
  },
  icons: {
    primary: '#6D7B99',
    secondary: '#6AD1FF',
  },
  palette: {
    text: {
      primary: '#FFFFFF',
      secondary: '#AFB8CB',
    },
    success: {
      main: '#01FF39',
    },
    warning: {
      main: '#FF2B2B',
    },
  },
  typography: (palette) => ({
    fontFamily: ['Nunito Sans'].join(),
    subtitle1: {
      fontSize: '16px',
      color: 'var(--hcx-font-primary)',
    },
    subtitle2: {
      fontSize: '14px',
      color: 'var(--hcx-font-primary)',
    },
    body1: {
      fontSize: '12px',
      color: 'var(--hcx-font-primary)',
    },
    body2: {
      fontSize: '11px',
      color: 'var(--hcx-font-secondary)',
    },
    caption: {
      fontSize: '10px',
      color: 'var(--hcx-font-secondary)',
    },
    allVariants: {
      color: palette.text.primary,
    },
  }),
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme: appTheme }) => ({
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: (appTheme as any).custom.buttons,
          height: '1px',
          '&>span': {
            maxWidth: '40px',
            width: '100%',
            backgroundColor: (appTheme as any).custom.buttons,
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'var(--hcx-font-primary-alpha-6)',
          transition: 'color 0.3s',
          textTransform: 'none',
          fontSize: '14px',
          lineHeight: '18px',
          '&.Mui-selected': {
            color: 'var(--hcx-button)',
          },
          '&:hover:not(.Mui-selected)': {
            color: 'var(--hcx-font-primary)',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme: appTheme }) => ({
          color: (appTheme as any).icons.primary,
          '&:hover, &:active': {
            backgroundColor: '#232938',
          },
        }),
        sizeSmall: {
          '&>svg': {
            height: '18px',
            width: '18px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'var(--hcx-background)',
          borderBottomWidth: '1px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          fontSize: '11px',
          lineHeight: '14px',
          padding: '6px 15px !important',
        },
        root: ({ theme: appTheme }) => ({
          borderRadius: '2px',
          backgroundColor: 'var(--hcx-input-background)',
          '&::before, &:hover:not(.Mui-disabled)::before': {
            borderColor: 'var(--hcx-input-border)',
          },
          '&::after, &:hover': {
            borderColor: 'var(--hcx-input-border-hover)',
          },
          '& .MuiSvgIcon-root > path': {
            fill: (appTheme as any).icons.primary,
          },
          '& .MuiSvgIcon-root.MuiSelect-iconOpen > path': {
            fill: (appTheme as any).icons.secondary,
          },
          '& .MuiButtonBase-root, & .MuiIconButton-root, &.MuiSvgIcon-root, & .MuiSvgIcon-fontSizeMedium, & svg > path':
            {
              background: 'transparent !important',
            },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedSizeSmall: {
          padding: '2px 15px',
          textDecoration: 'Capitalize',
          color: 'var(--hcx-font-secondary)',
          borderColor: 'var(--hcx-blue)',
          borderRadius: '100px',
          fontSize: '11px',
          textTransform: 'capitalize',
          '&:hover': {
            backgroundColor: 'var(--hcx-button-hover-outline-background)',
            borderColor: 'var(--hcx-button-hover)',
          },
          '&.Mui-disabled': {
            backgroundColor: 'var(--hcx-button-hover-outline-background)',
            borderColor: 'var(--hcx-button-hover)',
            color: 'var(--hcx-font-secondary)',
            opacity: '0.4',
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {},
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'var(--hcx-font-secondary) !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme: appTheme }) => ({
          color: (appTheme as any).palette.text.secondary,
          fontWeight: 400,
          fontSize: ' 12px',
          lineHeight: '16px',
          transform: 'none',
        }),
        sizeSmall: {
          fontSize: '11px',
          lineHeight: '15p',
          position: 'relative',
          marginBottom: '7px',
        },
      },
      variants: [
        {
          props: { variant: 'filled' },
          style: {},
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '18px',
        },
        inputSizeSmall: {
          fontSize: '14px',
          lineHeight: '19px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          fontSize: '11px',
          lineHeight: '15px',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          padding: '5px 15px 6px',
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            border: '1px solid var(--hcx-input-border)',
            background: 'var(--hcx-input-background)',
            borderRadius: '2px',
            colorScheme: 'dark',
            //::-webkit-calendar-picker-indicator
            'input::-webkit-calendar-picker-indicator': {
              filter: 'contrast(0)',
              fontSize: '16px',
            },
            '&:hover': { border: '1px solid var(--hcx-input-border-hover)' },
            '&.Mui-error': {
              background: 'var(--hcx-input-background-error)',
              border: '1px solid var(--hcx-input-border-error)',
              color: 'var(--hcx-input-text-error)',
            },
            '&.MuiFormHelperText-root > path': {
              color: 'var(--hcx-input-text-error)',
            },
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid var(--hcx-divider)',
          backgroundColor: '#202633',
          boxShadow: '0px 0px 14px #000000',
          '& .MuiList-root': {
            padding: '8px 0 0 0',
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: ({ theme: appTheme }) => ({
          height: 'max-content',
          color: (appTheme as any).custom.buttons,
        }),
        iconFilled: ({ theme: appTheme }) => ({
          color: (appTheme as any).icons.secondary,
        }),
        iconHover: ({ theme: appTheme }) => ({
          color: (appTheme as any).icons.secondary,
        }),
        iconEmpty: ({ theme: appTheme }) => ({
          color: (appTheme as any).icons.primary,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: 'var(--hcx-font-secondary)',
        },
        switchBase: {
          color: 'var(--hcx-font-secondary)',
          '&.Mui-checked': {
            color: 'var(--hcx-button)',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: 'var(--hcx-button)',
          },
          '&.Mui-disabled': {
            color: 'var(--hcx-input-border-disabled)',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        label: ({ theme: appTheme }) => ({
          color: (appTheme as any).palette.text.secondary,
          fontWeight: 400,
          fontSize: ' 12px',
          lineHeight: '16px',
          '&.Mui-disabled': {
            color: (appTheme as any).palette.text.secondary,
          },
        }),
        labelPlacementTop: {
          alignItems: 'flex-start',
          '.MuiSwitch-root + .MuiFormControlLabel-label': {
            paddingLeft: '12px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'var(--hcx-button)',
        },
        sizeSmall: {
          color: 'var(--hcx-input-text-error) !important',
          maxHeight: '16px',
          overflow: 'hidden',
          position: 'absolute',
          bottom: '-15px',
          left: '0',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&.MuiTypography-root': {
            color: 'var(--hcx-link)',
          },
        },
      },
      defaultProps: {
        underline: 'none',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '14px',
      },
      variants: [
        {
          props: { variant: 'subtitle2' },
          style: {
            fontSize: '14px',
            fontWeight: 400,
            color: 'var(--hcx-link)',
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: '12px',
            color: 'var(--hcx-link)',
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            fontSize: '11px',
            color: 'var(--hcx-link)',
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          'label + .MuiInput-root, label + .MuiFormControl-root': {
            marginTop: '25px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'var(--hcx-field)',
          padding: '7px 11px',
          borderRadius: '8px',
        },
      },
    },
  },
});

export default theme;
