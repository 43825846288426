import { FC, useMemo, useState } from 'react';
import { TransparentOverlayContext } from './transparent-overlay-context';
import { TransparentOverlay } from './transparent-overlay';

export const TransparentOverlayProvider: FC = ({ children }) => {
  const [enabled, setEnabled] = useState(false);
  const value = useMemo(() => ({ enabled, setEnabled }), [enabled]);
  return (
    <TransparentOverlayContext.Provider value={value}>
      {children}
      <TransparentOverlay />
    </TransparentOverlayContext.Provider>
  );
};
