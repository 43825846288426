import { createContext, Dispatch, SetStateAction } from 'react';

import { noop } from 'utils/noop';

import type { OrderT } from 'types/orders';
import { OrderStatusE } from 'enums';

export interface OrdersWidgetContextDataI {
  orders: OrderT[];
  filteredOrders: OrderT[];
  filter: OrderStatusE | 'all';
}

export interface OrdersWidgetContextI extends OrdersWidgetContextDataI {
  setFilter: Dispatch<SetStateAction<OrdersWidgetContextDataI['filter']>>;
}

export const defaultContextData: OrdersWidgetContextDataI = {
  orders: [],
  filteredOrders: [],
  filter: 'all',
};

export const OrdersListContext = createContext<OrdersWidgetContextI>({
  ...defaultContextData,
  setFilter: noop,
});
