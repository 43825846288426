import { Trans } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Input } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import styles from './trade-bar.module.scss';
import { GoodUntilI } from './trade-bar.interfaces';

export const BUY_SELL_LABELS = [
  <Trans key="buy" i18nKey="buy" ns="common" />,
  <Trans key="sell" i18nKey="sell" ns="common" />,
];

export const MARKET_LIMIT_LABELS = [
  <Trans key="market" i18nKey="market" ns="common" />,
  <Trans key="limit" i18nKey="limit" ns="common" />,
];

export const StyledInput = styled(Input)({
  height: '30px',
  background: 'var(--hcx-card)',
  borderRadius: '100px',
  '&::after, &::before': {
    display: 'none',
  },
  '&>input': {
    width: '100%',
    padding: '7px 15px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: 'var(--hcx-font-primary)',
    textOverflow: 'ellipsis',
  },
});

export const StyledInputForDatePicker = styled(StyledInput)({
  paddingRight: '5px',
  minWidth: '120px',
  maxWidth: '120px',

  '&>input': {
    paddingRight: 0,
    cursor: 'pointer',
  },
});

export const WALLET_MENU_PROPS = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  classes: { paper: styles.selectPaper, list: styles.selectList },
} as const;

export const GOOD_UNTIL_MENU_PROPS = {
  anchorOrigin: { ...WALLET_MENU_PROPS.anchorOrigin },
  classes: { ...WALLET_MENU_PROPS.classes, paper: styles.goodUntilSelectPaper },
} as const;

export const SELECT_CLASSES = {
  outlined: styles.select,
  icon: styles.selectIcon,
};

export const getEndOfDay = (date?: Date) => {
  const endOfDay = date || new Date();
  endOfDay.setHours(23);
  endOfDay.setMinutes(59);
  endOfDay.setSeconds(59);
  endOfDay.setMilliseconds(0);
  return endOfDay;
};

export const GOOD_UNTIL_OPTIONS: {
  label: GoodUntilI['type'];
  getDefaultDate: () => GoodUntilI['date'];
}[] = [
  {
    label: 'GTC',
    getDefaultDate: () => null,
  },
  {
    label: 'GTD',
    getDefaultDate: getEndOfDay,
  },
  {
    label: 'GT 24h',
    getDefaultDate: getEndOfDay,
  },
];

export const datePickerProps = {
  OpenPickerButtonProps: {
    size: 'small',
  },
  components: {
    OpenPickerIcon: DateRangeIcon,
  },
} as const;
