import { atom } from 'jotai';

import { AthleteI, OpenAthleteCardI } from 'types';
import { HCXCardViewE } from '../enums';

export const selectedAthleteIAtom = atom<AthleteI | null>(null);
export const openAthleteCardDataAtom = atom<OpenAthleteCardI | null>(null);
export const isListView = atom<boolean>(false);

export const openAthleteCardViewAtom = atom((get) => {
  const currentState = get(openAthleteCardDataAtom);
  return currentState?.view || HCXCardViewE.Info;
});

export const openAthleteCardAtom = atom(
  null,
  (get, set, { athlete, view = HCXCardViewE.Info }: { athlete: AthleteI; view?: HCXCardViewE }) => {
    const currentState = get(openAthleteCardDataAtom);

    if (currentState?.id === athlete.id && currentState?.view === view) {
      return;
    }

    set(openAthleteCardDataAtom, { id: athlete.id, view });
    set(selectedAthleteIAtom, athlete);
  }
);

export const closeAthleteCardAtom = atom(null, (get, set) => {
  set(selectedAthleteIAtom, null);
  set(openAthleteCardDataAtom, null);
});

export const listGridViewChangerAtom = atom(null, (get, set) => {
  const currentIsListViewState = get(isListView);
  set(isListView, !currentIsListViewState);
});

export const listGridViewStatusAtom = atom((get) => {
  const currentIsListViewState = get(isListView);
  return currentIsListViewState;
});
