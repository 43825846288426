import { FC } from 'react';

type DirectionT = 'up' | 'down' | 'right' | 'left';

interface ArrowBoldPropsI {
  direction?: DirectionT;
  color?: string;
  className?: string;
}

const defaultColors: Record<DirectionT, string> = {
  up: 'var(--hcx-alert-up-alt)',
  right: 'var(--hcx-alert-up-alt)',
  left: 'var(--hcx-alert-up-alt)',
  down: 'var(--hcx-alert-down)',
};

const defaultStyles: Record<DirectionT, Record<'transform', string>> = {
  up: { transform: 'rotate(0)' },
  right: { transform: 'rotate(-90deg)' },
  left: { transform: 'rotate(90deg)' },
  down: { transform: 'rotate(180deg)' },
};

const ArrowBold: FC<ArrowBoldPropsI> = ({
  direction = 'up',
  color = 'var(--hcx-alert-up-alt)',
  className,
}) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={defaultStyles[direction]}
  >
    <path
      d="M11.2501 15.0001H6.75012V9.00012H3.12012L9.00012 3.12012L14.8801 9.00012H11.2501V15.0001Z"
      fill={color || defaultColors[direction]}
    />
  </svg>
);

export default ArrowBold;
