import { FC } from 'react';
import Box from '@mui/material/Box';
import { Input } from '../../auth/input';
import { Button } from 'components/button';
import commonStyles from './common.module.scss';
import { useFormState } from './use-form-state';
import classNames from 'classnames';
import { PropsI } from './types';
import { TitleRow } from './title-row';
import { validateEmpty } from '../../../utils/validation';
import { renderValidationResult } from '../../auth/render-validation-result';

interface DataI {
  name: string;
  lastName: string;
  dateOfBirth: string;
}

const validate = (data: DataI) => {
  return {
    name: validateEmpty(data.name),
    lastName: validateEmpty(data.lastName),
    dateOfBirth: validateEmpty(data.dateOfBirth),
  };
};

const initialState = { name: '', lastName: '', dateOfBirth: '' };

export const FirstStep: FC<PropsI<DataI>> = ({ onSubmit }) => {
  const { handleChange, handleSubmit, state, errors } = useFormState(
    initialState,
    onSubmit,
    validate
  );
  return (
    <Box>
      <form>
        <Box className={commonStyles.wrapper}>
          <TitleRow title="What is your name?" />
          <Input
            type="text"
            name="name"
            placeholder="Name"
            onChange={handleChange}
            value={state.name}
            className={classNames(commonStyles.mt2, commonStyles.wa)}
          />
          {renderValidationResult(errors?.name)}
          <Input
            type="text"
            name="lastName"
            placeholder="Last name"
            onChange={handleChange}
            value={state.lastName}
            className={classNames(commonStyles.mt2, commonStyles.wa)}
          />
          {renderValidationResult(errors?.lastName)}
          <Input
            type="text"
            name="dateOfBirth"
            placeholder="Date of birth"
            onChange={handleChange}
            value={state.dateOfBirth}
            className={classNames(commonStyles.mt2, commonStyles.wa)}
          />
          {renderValidationResult(errors?.dateOfBirth)}
          <Button onClick={handleSubmit} variant="contained" className={commonStyles.mt4}>
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  );
};
