import { FC } from 'react';
import { IhoChoRegister, IhoOrCho } from './components';

const TalentPortalIhoCho: FC = () => {
  return (
    <div>
      <IhoChoRegister></IhoChoRegister>
      <IhoOrCho></IhoOrCho>
    </div>
  );
};

export default TalentPortalIhoCho;
