import { FC, memo } from 'react';
import classNames from 'classnames';
import { ChartContainerPropsI, useTvWidget } from './useTvWidget';
import styles from './styles.module.scss';

export const TVChartContainer: FC<
  Partial<ChartContainerPropsI> & { symbol: string; tradingPairId: string }
> = memo(({ children, className, customHeader, height, ...props }) => {
  const { containerRef, tvWidgetRef } = useTvWidget(props);

  return (
    <>
      {customHeader ? customHeader(tvWidgetRef) : null}
      <div
        className={classNames(styles.TVChartContainerWrapper, className)}
        style={{ height: height ? `${height}px` : '100%' }}
      >
        <div>
          <div
            ref={containerRef}
            className={classNames(
              styles.TVChartContainer,
              props.disableTopToolbar && styles.noHeader
            )}
          />
        </div>
      </div>
    </>
  );
});
