import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { insertResizer } from './utils';
import { DirectionE, Resizer } from './resizer';
import styles from './styles.module.scss';
import { debounce } from '../../utils/debounce';
import { restoreSizes, saveSizes } from './storage';
import type { RefObject } from 'react';

const saveSizesDebounced = debounce(saveSizes, 100);

const calcPercentage = (part: number, full: number) => (part * 100) / full;

export const useResizableContainer = (
  children: ReactNode,
  id: string,
  defaultSizes: Record<string, number>,
  wrapperRef: RefObject<HTMLElement>,
  resizerDirection: DirectionE = DirectionE.Horizontal
) => {
  const calcRestoredSizes = useCallback(
    () => ({ ...defaultSizes, ...restoreSizes(id) }),
    [id, defaultSizes]
  );
  const [sizes, setSizes] = useState(calcRestoredSizes);
  const elements = useRef<Record<string, HTMLElement>>({});
  const getWrapperSize = useCallback(() => wrapperRef.current!.offsetHeight, []); // eslint-disable-line
  const onDragEnd = useCallback(() => {
    const entries = Object.entries(elements.current);
    const result = entries.reduce(
      (acc, [elementId, element]) => ({
        ...acc,
        [elementId]: calcPercentage(element.offsetHeight, getWrapperSize()),
      }),
      {}
    );
    setSizes(result);
  }, [getWrapperSize]);

  const result = useMemo(
    () =>
      insertResizer(children, (currentId, nextId) => (
        <Resizer
          key={`${currentId}-${nextId}`}
          className={styles.resizer}
          direction={resizerDirection}
          onDragEnd={onDragEnd}
          onDrag={(diff) => {
            const percentageDiff = (diff * 100) / getWrapperSize();
            setSizes((oldSizes) => ({
              ...oldSizes,
              [currentId]: oldSizes[currentId] - percentageDiff,
              [nextId]: oldSizes[nextId] + percentageDiff,
            }));
          }}
        />
      )),
    [children, getWrapperSize, onDragEnd, resizerDirection]
  );
  const registerElement = useCallback((elementId: string, element: HTMLElement) => {
    elements.current[elementId] = element;
    return () => {
      delete elements.current[elementId];
    };
  }, []);
  useEffect(() => {
    saveSizesDebounced(id, sizes);
  }, [id, sizes]);
  return {
    children: result,
    contextValue: { sizes, registerElement },
  };
};
