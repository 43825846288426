export enum FieldTypeE {
  Select,
  Text,
}

interface BaseFieldI<T extends FieldTypeE> {
  label: string;
  field: string;
  type: T;
}

export interface SelectFieldI extends BaseFieldI<FieldTypeE.Select> {
  type: FieldTypeE.Select;
  options: { title: string; value: string | number }[];
}

export interface TextFieldI extends BaseFieldI<FieldTypeE.Text> {
  type: FieldTypeE.Text;
}

export type FieldT = SelectFieldI | TextFieldI;

export interface SectionI {
  title: string;
  fields: FieldT[][];
}
