import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { RenderToHeaderContext } from './context';

export const RenderToHeaderTradingInfoContextProvider: FC = (props) => {
  const [content, setContent] = useState<ReactNode>(null);
  const [leftContent, setLeftContent] = useState<ReactNode>(null);
  const [isMenuPinned, setMenuPinned] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  return (
    <RenderToHeaderContext.Provider
      value={{
        content,
        setContent,
        leftContent,
        setLeftContent,
        isMenuPinned,
        setMenuPinned,
        isMenuOpen,
        setMenuOpen,
      }}
      {...props}
    />
  );
};
