import type { FC } from 'react';
import { useCallback } from 'react';

import { Button } from 'components/button';
import { useAtom } from 'jotai';
import {
  sponsorApplicationFormAtom,
  sponsorApplicationModalView,
  sponsorFormValidationAtom,
} from 'store/sponsor-application.store';
import { areAllFieldsFilled } from 'utils/check-form-filled';
import {
  SponsorApplicationStatus,
  useSponsorApplicationStatusLazyQuery,
  useSubmitSponsorApplicationMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';

export const SubmitButton: FC = () => {
  const [sponsorForm, setSponsorForm] = useAtom(sponsorApplicationFormAtom);
  const [, setModalView] = useAtom(sponsorApplicationModalView);
  const [errors] = useAtom(sponsorFormValidationAtom);
  const isSponsorFilled = sponsorForm
    ? areAllFieldsFilled(sponsorForm.sponsorInformation, errors.sponsorInformation, [
        'agreementUrl',
      ])
    : null;
  const isSponsorOwnerFilled = sponsorForm
    ? areAllFieldsFilled(sponsorForm.sponsorOwnerInformation, errors.sponsorOwnerInformation)
    : null;
  const isAllFilled = isSponsorFilled && isSponsorOwnerFilled;

  const [submitSponsorApp] = useSubmitSponsorApplicationMutation();
  const [queryApplicationStatus] = useSponsorApplicationStatusLazyQuery();

  const modalOpen = useCallback(() => setModalView(true), [setModalView]);

  const { t } = useTranslation('sponsor-application');

  const handleSubmit = async () => {
    await submitSponsorApp();
    const statusQueried = await queryApplicationStatus();
    const status = statusQueried.data?.sponsorApplication.status as SponsorApplicationStatus;

    setSponsorForm((prev) =>
      prev
        ? {
            ...prev,
            status,
          }
        : null
    );
    modalOpen();
  };

  return (
    <Button onClick={handleSubmit} disabled={!isAllFilled} variant="contained" size="small">
      {t('submit-application')}
    </Button>
  );
};
