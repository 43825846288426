import {
  TableColumnConfigI,
  AthleteBioI,
  AthleteCareerI,
  AthleteCareerHistoryI,
  AthleteI,
} from 'types';
import { dateFormatter, resolveDate, DateFormatE } from 'utils/date-formatter';
import { Trans } from 'react-i18next';

const getAthleteOld = (born: Date): number => {
  const todayYear = new Date().getFullYear();
  const bornYear = born.getFullYear();
  return todayYear - bornYear;
};

export const ATHLETE_TAB_SUMMARY_BIO_COLUMNS_CONFIG: TableColumnConfigI<AthleteBioI>[] = [
  {
    title: <Trans i18nKey="born" ns="hcx-card" />,
    dataKey: 'born',
    renderValue: (data) => {
      const bornDateObj = resolveDate(data.born);
      if (!bornDateObj) return '';
      return `${dateFormatter(bornDateObj, DateFormatE.MMMM_DD_YYYY)} (age ${getAthleteOld(
        bornDateObj
      )})`;
    },
  },
  {
    title: <Trans i18nKey="sex" ns="hcx-card" />,
    dataKey: 'sex',
  },
  {
    title: <Trans i18nKey="height" ns="hcx-card" />,
    dataKey: 'height',
  },
  {
    title: <Trans i18nKey="education" ns="hcx-card" />,
    dataKey: 'education',
  },
];
export const ATHLETE_TAB_SUMMARY_CAREER_COLUMNS_CONFIG: TableColumnConfigI<AthleteCareerI>[] = [
  {
    title: <Trans i18nKey="league" ns="hcx-card" />,
    dataKey: 'league',
    renderValue: (data) => `${data.league.name} (${data.league.shortName})`,
  },
  {
    title: <Trans i18nKey="team" ns="hcx-card" />,
    dataKey: 'team',
  },
  {
    title: <Trans i18nKey="position" ns="hcx-card" />,
    dataKey: 'position',
  },
  {
    title: <Trans i18nKey="number" ns="hcx-card" />,
    dataKey: 'jerseyNumber',
  },
  {
    title: <Trans i18nKey="roaster-status" ns="hcx-card" />,
    dataKey: 'rosterStatus',
    renderValue: (data) => <span style={{ color: '#1AA504' }}>{data.rosterStatus}</span>,
  },
  {
    title: <Trans i18nKey="drafted" ns="hcx-card" />,
    dataKey: 'drafted',
  },
];
export const GET_ATHLETE_TAB_SUMMARY_CAREER_HISTORY_COLUMNS_CONFIG = (
  athlete: AthleteI
): TableColumnConfigI<AthleteCareerHistoryI>[] =>
  athlete.career.history.map(({ place, position, description }) => ({
    jsxKey: `${place}-${position}-${description}`,
    title: place,
    dataKey: 'place',
    renderValue: (_, options) => (
      <>
        {position}
        {description && (
          <>
            <br />
            <span className={options?.styles?.valueDescription}>{description}</span>
          </>
        )}
      </>
    ),
  }));
