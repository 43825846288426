import { FC, useEffect } from 'react';
import { TabPanel } from 'components/tab-panel';
import { HCXCardTabs } from '../hcx-card-tabs';
import HcxCardTabViewSpacing from '../hcx-card-tab-view-spacing';
import { useHCXCardCurrentTab, useInfoRotatedContext } from '../hcx-card.hooks';
import { tabLabels, TABS, getHCXCardSummaryDefaultTabByView } from './hcx-card-summary.helpers';

const HCXSummary: FC = () => {
  const [tabIdx, setTabIdx] = useHCXCardCurrentTab(getHCXCardSummaryDefaultTabByView);
  const rotated = useInfoRotatedContext();

  useEffect(() => {
    if (rotated) {
      setTabIdx(0);
    }
  }, [rotated, setTabIdx]);

  return (
    <>
      {!rotated && (
        <HCXCardTabs tabLabels={tabLabels} currentTabIdx={tabIdx} onChangeTab={setTabIdx} />
      )}
      <HcxCardTabViewSpacing resetScrollTrigger={tabIdx}>
        {TABS.map(({ jsxKey, component: Component, keepMounted }, idx) => {
          return (
            <TabPanel key={jsxKey} value={tabIdx} index={idx} keepMounted={keepMounted}>
              {Component ? <Component setTab={setTabIdx} /> : null}
            </TabPanel>
          );
        })}
      </HcxCardTabViewSpacing>
    </>
  );
};

export default HCXSummary;
