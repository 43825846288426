import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { sponsorPanelView } from 'store/sponsor-application.store';
import styles from './account-sponsor-pending.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useSponsorApplicationDataQuery,
  useSponsorApplicationStatusQuery,
} from 'generated/graphql';

export const SponsorPending: FC = () => {
  const { data: sponsorData } = useSponsorApplicationDataQuery();
  const { data: sponsorStatus } = useSponsorApplicationStatusQuery();
  const { t } = useTranslation('account-profile');
  const [, setOpenPanel] = useAtom(sponsorPanelView);

  return (
    <Box className={styles.root}>
      <Typography variant="subtitle1">
        {sponsorData?.sponsorApplication.sponsorInformation.corporateName}
      </Typography>
      <Box display="flex" gap={3} alignItems="baseline">
        <Box gap={1} display="flex" alignItems="baseline">
          <Typography variant="body2">{t('status')}:</Typography>
          <Typography variant="body1"> {sponsorStatus?.sponsorApplication.status}</Typography>
        </Box>
        <Typography className={styles.colorBlue} onClick={() => setOpenPanel(true)} variant="body1">
          {t('cancel-application')}
        </Typography>
      </Box>
    </Box>
  );
};
