import type { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './search-field.module.scss';
import { InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export interface SearchFieldPropsI {
  containerClassName?: string;
  icon?: ReactNode;
  value: string;
  handleChange: (e: { target: { value: string } }) => void;
  placeholder: string;
  inputClassName?: string;
}

export const SearchField: FC<SearchFieldPropsI> = ({
  containerClassName,
  icon = <SearchIcon className={styles.iconColor} />,
  value,
  handleChange,
  placeholder,
  inputClassName,
}) => {
  return (
    <div className={classNames(containerClassName, styles.container)}>
      <div className={classNames(styles.iconWrapper)}>{icon}</div>
      <InputBase
        value={value}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : ''}
        componentsProps={{ input: { className: classNames(inputClassName, styles.input) } }}
      />
    </div>
  );
};
