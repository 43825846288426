import {
  Exchange,
  ResolutionString,
  LanguageCode,
  IChartingLibraryWidget,
  DropdownItem,
  Bar,
  SetVisibleTimeRange,
} from 'charting_library';

import { TradingPairCandlestick } from 'generated/graphql';
import { AthleteTradingInfoI } from 'types';

import { timeFrames } from './constants';

// Make requests to CryptoCompare API
export async function makeApiRequest<T>(path: string): Promise<T> {
  try {
    const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
    return await response.json();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new Error(`CryptoCompare request error: ${error?.status}`);
  }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange: Exchange['value'], fromSymbol: string, toSymbol: string) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

export function parseFullSymbol(fullSymbol: string) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}

export const getTimeFrameByResolution = (resolution: ResolutionString | string) =>
  timeFrames.find((item) => item.realResolution === resolution) ||
  timeFrames[timeFrames.length - 2];

export const getVisibleRange = (
  resolution: ResolutionString | string,
  to?: number
): SetVisibleTimeRange => {
  const today = to ? new Date(to * 1000) : new Date();
  console.log(resolution);
  console.log(today);

  const [year, month, day, hours, minutes, seconds] = [
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate(),
    today.getUTCHours(),
    today.getUTCMinutes(),
    today.getUTCSeconds(),
  ];
  switch (resolution) {
    case '5D':
      return {
        from: new Date(year, month, day - 5, hours, minutes, seconds).getTime() / 1000,
        to,
      };
    case '1M':
      return {
        from: new Date(year, month - 1, day, hours, minutes, seconds).getTime() / 1000,
        to,
      };
    case '3M':
      return {
        from: new Date(year, month - 3, day, hours, minutes, seconds).getTime() / 1000,
        to,
      };
    case '6M':
      return {
        from: new Date(year, month - 6, day, hours, minutes, seconds).getTime() / 1000,
        to,
      };
    case '12M':
      return {
        from: new Date(year - 1, month, day, hours, minutes, seconds).getTime() / 1000,
        to,
      };
    case '60M':
      return {
        from: new Date(year - 5, month, day, hours, minutes, seconds).getTime() / 1000,
        to,
      };
    case 'All':
      return {
        from: new Date(year - 20, month, day, hours, minutes, seconds).getTime() / 1000,
        to,
      };
    default:
      return {
        from: new Date(year, month, day - 1, hours, minutes, seconds).getTime() / 1000,
        to,
      };
  }
};

export const getCurrentTimezone = (): string => {
  const date = new Date();
  const offset = (date.getTimezoneOffset() / 60) * -1;
  return `UTC${offset > 0 ? '+' : ''}${offset}`;
};

export const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode);
};

export const downloadImage = (data: string, filename = 'untitled.jpeg') => {
  const a = document.createElement('a');
  a.href = data;
  a.download = filename;
  a.click();
};

export const getFileNameToSave = (symbol?: string) => {
  const date = new Date();
  const dateString = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  )
    .toISOString()
    .slice(0, -5)
    .replaceAll(':', '-')
    .replace('T', '_');
  return `${symbol ? `${symbol}_` : ''}${dateString}.png`;
};

export const createButton = (
  tvWidget: IChartingLibraryWidget,
  settings: { description: string; onClick: () => void; content: string }
) => {
  const button = tvWidget.createButton({ align: 'right' });
  button.setAttribute('title', settings.description);
  button.setAttribute(
    'style',
    'cursor: pointer; height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;'
  );
  button.innerHTML = settings.content;
  button.addEventListener('click', settings.onClick);
  return () => button.removeEventListener('click', settings.onClick);
};
export const createDropdown = (
  tvWidget: IChartingLibraryWidget,
  settings: { title: string; description?: string; options: DropdownItem[]; icon?: string }
) => {
  tvWidget.createDropdown({
    title: settings.title,
    items: settings.options,
    tooltip: settings.description,
    icon: settings.icon,
    align: 'left',
  });
};

export const getSymbolFromAthleteContract = (
  contractId: AthleteTradingInfoI['contractId'],
  tradingPairId: string
) => `HCX:${contractId}/USD:${tradingPairId}`;

export const getUTCTimeFromISOString = (str: string): number => new Date(str).getTime();

export const parseNewBar = (bar?: TradingPairCandlestick | null): Bar | null => {
  if (bar?.openTimestamp) {
    const barTime = getUTCTimeFromISOString(bar.openTimestamp);
    return {
      time: barTime,
      low: bar.low,
      high: bar.high,
      open: bar.open,
      close: bar.close,
      volume: bar.volume,
    };
  }
  return null;
};
