import classNames from 'classnames';
import type { FC } from 'react';

import { Box } from '@mui/material';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import IconButton from '@mui/material/IconButton';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import StarIcon from '@mui/icons-material/Star';

import { CashMultipleIcon } from 'components/icons/cash-multiple-icon';
import { ChartLineVectorIcon } from 'components/icons/chart-line-vector-icon';
import { TradeIcon } from 'components/icons/trade-icon';
import { PanelNameE } from '../types';

import styles from './header-buttons.module.scss';
import { useAtom } from 'jotai';
import { tickerBarView, tradeBarView } from 'store/trading-stats.store';

interface HeaderButtonsPropsI {
  enabledPanels: PanelNameE[];
  switchPanel: (panel: PanelNameE) => void;
  disabled?: boolean;
}

export const HeaderButtons: FC<HeaderButtonsPropsI> = ({ switchPanel, enabledPanels }) => {
  const [isTradeBarOpened, setTradeBarOpened] = useAtom(tradeBarView);
  const [isTickerBarOpened, setTickerBarOpened] = useAtom(tickerBarView);

  return (
    <Box display="flex" className={styles.root}>
      <IconButton
        onClick={() => setTradeBarOpened((prev) => !prev)}
        className={classNames(isTradeBarOpened && styles.enabled)}
        size="small"
      >
        <TradeIcon />
      </IconButton>
      <IconButton
        onClick={() => switchPanel(PanelNameE.Snapshot)}
        className={classNames(enabledPanels.includes(PanelNameE.Snapshot) && styles.enabled)}
        size="small"
      >
        <DataSaverOffIcon />
      </IconButton>
      <IconButton
        onClick={() => switchPanel(PanelNameE.Trending)}
        className={classNames(enabledPanels.includes(PanelNameE.Trending) && styles.enabled)}
        size="small"
      >
        <LocalFireDepartmentIcon />
      </IconButton>
      <IconButton
        onClick={() => switchPanel(PanelNameE.Favorites)}
        className={classNames(enabledPanels.includes(PanelNameE.Favorites) && styles.enabled)}
        size="small"
      >
        <StarIcon />
      </IconButton>
      <IconButton
        onClick={() => switchPanel(PanelNameE.Orders)}
        className={classNames(enabledPanels.includes(PanelNameE.Orders) && styles.enabled)}
        size="small"
      >
        <CashMultipleIcon />
      </IconButton>
      <IconButton
        onClick={() => setTickerBarOpened((prev) => !prev)}
        className={classNames(isTickerBarOpened && styles.enabled)}
        size="small"
      >
        <ChartLineVectorIcon />
      </IconButton>
    </Box>
  );
};
