import type { FC, ReactNode } from 'react';
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import styles from './select-field.module.scss';
import classNames from 'classnames';
import React from 'react';

const SELECT_CLASSES = {
  select: styles.select,
  disabled: styles.disabled,
  nativeInput: styles.nativeInput,
  icon: styles.icon,
};

const MENUITEM_CLASSES = {
  selected: styles.selectedItem,
  root: styles.rootItem,
};

const MENU_CLASSES = {
  paper: styles.menuPaper,
  list: styles.menuList,
};

export interface SelectPropsI {
  tName?: string;
  name?: string;
  disabled?: boolean;
  icon?: ReactNode;
  onChangeHandler?: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  onBlurHandler?: (event: React.FocusEvent) => void;
  value: string;
  errorValidation?: string | boolean;
  menuItems: string[];
  className: string;
}

export const SelectField: FC<SelectPropsI> = (props) => {
  const {
    tName,
    name,
    disabled,
    icon,
    onChangeHandler,
    onBlurHandler,
    value,
    errorValidation,
    menuItems,
    className,
  } = props;
  return (
    <Box>
      {tName && (
        <Box className={styles.inputLabel}>
          <Typography variant="body2">{tName}</Typography>
          {icon}
        </Box>
      )}
      <Select
        classes={SELECT_CLASSES}
        size="small"
        name={name}
        variant="filled"
        disableUnderline
        disabled={disabled || false}
        MenuProps={{ classes: MENU_CLASSES }}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        value={value}
        className={classNames(styles.selectRoot, className, {
          [styles.errorRoot]: errorValidation,
        })}
      >
        {menuItems.map((item) => {
          return (
            <MenuItem key={item} classes={MENUITEM_CLASSES} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
      {!!errorValidation && <p className={styles.errorText}>{errorValidation}</p>}
    </Box>
  );
};
