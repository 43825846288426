import { FC, useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useResizableCell } from './use-resizable-cell';

interface PropsI {
  id: string;
  className?: string;
}

export const ResizableCell: FC<PropsI> = ({ children, className, id }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { size } = useResizableCell(id, wrapperRef.current);
  return (
    <div
      className={classNames(styles.row, className)}
      style={{ height: `${size}%` }}
      ref={wrapperRef}
    >
      {children}
    </div>
  );
};
