import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MyOrdersContainer } from './my-orders.container';

import type { OrderT } from 'types/orders';
import {
  DutchAuctionBid,
  Order,
  useAuctionUserBidsQuery,
  useOrdersByUserIdQuery,
} from 'generated/graphql';

import { WidgetNoItems } from 'pages/trade-desk/widget-noItems';
import { HcxCircleIcon } from 'components/icons/hcx-circle-icon';

import styles from './my-orders-view.module.scss';

import { OrderStatusE } from 'enums';
import { Typography } from '@mui/material';

export const MyOrdersApollo: FC = () => {
  const { data: bidsData } = useAuctionUserBidsQuery();
  const { t } = useTranslation('my-orders');
  const { data } = useOrdersByUserIdQuery({
    variables: { input: {} },
  });

  console.log(data, bidsData); //TODO: check why there is no data?

  const userBids: DutchAuctionBid[] = useMemo(() => {
    if (bidsData) {
      return bidsData.userBids.filter((entry): entry is DutchAuctionBid => !!entry);
    }
    return [];
  }, [bidsData]);

  const userOrders: OrderT[] = useMemo(() => {
    if (data) {
      return data.orders.data
        .filter((order): order is Order => !!order)
        .map((order) => ({ ...order, status: OrderStatusE.Open }));
    }
    return [];
  }, [data]);

  if (!userOrders.length || !userBids.length) {
    return (
      <WidgetNoItems
        text={<Typography variant="subtitle1">{t('no-orders-found')}</Typography>}
        icon={<HcxCircleIcon className={styles.nullStateIcon} />}
      />
    );
  }

  return <MyOrdersContainer userOrders={userOrders} userBids={userBids} />;
};
