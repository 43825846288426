interface ErrorObjI {
  [key: string]: {
    errMessage: string | undefined;
  };
}

type ErrorObjT = ErrorObjI | null;

export function areAllFieldsFilled(
  objectToCheck: Record<string, unknown>,
  errorObj: ErrorObjT = null,
  fieldsToExclude: string[] | null = null,
  fieldsToInclude: string[] | null = null
) {
  if (!objectToCheck) return false;

  const properties = fieldsToInclude ?? Object.keys(objectToCheck);

  let accum = true;

  for (let i = 0; i < properties.length; i++) {
    if (fieldsToExclude?.length && fieldsToExclude.includes(properties[i] as string)) {
      continue;
    }
    accum =
      accum &&
      !!objectToCheck[properties[i]] &&
      (errorObj && errorObj[properties[i] as keyof ErrorObjT]?.errMessage) === undefined;
    if (!accum) return accum;
  }
  return accum;
}
