import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { FC } from 'react';

import styles from './countdown.module.scss';

interface CountdownCellPropsI {
  number: string;
  label: string;
  numberClassName?: string;
  className?: string;
}

export const CountdownCell: FC<CountdownCellPropsI> = ({
  number,
  label,
  numberClassName,
  className,
}) => (
  <div className={classNames(styles.cell, className)}>
    <Typography className={classNames(styles.number, numberClassName)}>{number}</Typography>
    <Typography className={styles.label}>{label}</Typography>
  </div>
);
