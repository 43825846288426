import { FC, useRef } from 'react';
import classNames from 'classnames';
import { Panel } from 'components/panel';
import { CustomScrollbars } from 'components/custom-scrollbars';
import { AccountProfileSettingsHeader } from '../account-profile-settings-header';
import { SettingsViewConfigI } from '../account-profile.types';
import styles from './account-profile-settings-view.module.scss';

export interface AccountProfileSettingsViewPropsI {
  isAccountOpened: boolean;
  viewConfig?: SettingsViewConfigI | null;
  closeView: () => void;
}

const AccountProfileSettingsView: FC<AccountProfileSettingsViewPropsI> = ({
  isAccountOpened,
  viewConfig,
  closeView,
}) => {
  const viewRef = useRef<HTMLDivElement>(null);
  const ContentComponent = viewConfig?.component;
  return (
    <Panel
      className={classNames(
        styles.root,
        !!viewConfig && styles.opened,
        isAccountOpened && styles.accountOpened
      )}
      ref={viewRef}
    >
      {viewConfig ? (
        <>
          <AccountProfileSettingsHeader
            title={viewConfig.title}
            info={viewConfig.info}
            infoContainer={viewRef.current}
            onClose={closeView}
          />
          {ContentComponent && (
            <CustomScrollbars>
              <div className={styles.content}>{<ContentComponent />}</div>
            </CustomScrollbars>
          )}
        </>
      ) : null}
    </Panel>
  );
};

export default AccountProfileSettingsView;
