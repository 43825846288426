import { useAtom } from 'jotai';
import type { FC, ChangeEvent } from 'react';
import { useEffect, useMemo, useState, useCallback } from 'react';

import { Typography, Box } from '@mui/material';

import { Button } from 'components/button';
import { CustomScrollbars } from 'components/custom-scrollbars';
import { useRenderToHeaderTradingInfo } from 'components/layout/trading-info/header-trading-info';
import {
  closeAthleteCardAtom,
  openAthleteCardAtom,
  selectedAthleteIAtom,
} from 'store/athletes.store';

import { ActiveMintsTable } from './components/active-mints-table/active-mints-table';
import { AuctionRowDataI } from './components/active-mints-table/types';
import { ActiveMintsAthleteCard } from './components/active-mints-athlete-card/active-mints-athlete-card';
import { ActiveMintsTabs } from './components/active-mints-tabs/active-mints-tabs';

import { HCXCardContainer } from 'features/hcx-card';

import { HCXCardViewE } from 'enums';
import { QueryPagingI } from 'types';

import { useWindowSize } from './hooks';

import { approveERC20, postBond } from 'blockchain-api/bid-orders';
import { ApproveTargetE } from 'blockchain-api/bid-orders/helpers';
import { WalletE } from 'blockchain-api/ewallets-api';

import {
  ActiveMintsQuery,
  DutchAuctionOrderByField,
  OrderDirection,
  useActiveMintsQuery,
} from 'generated/graphql';
import { getDefaultState } from './helpers';

import styles from './human-capital-mint.module.scss';
import { useTranslation } from 'react-i18next';

const defaultPagination = {
  limit: 50,
  offset: 0,
  total: 0,
};

const MIN_TABLE_HEIGHT = 400;
const GAPS_HEIGHT = 250;
const CHART_HEIGHT = 460;
const CHART_HEIGHT_BID_OPEN = 250;

export const HumanCapitalMint: FC = () => {
  const { t } = useTranslation('human-capital-mint');
  const [, height] = useWindowSize();
  const [queryPaging, setQueryPaging] = useState<QueryPagingI>({ limit: 50, offset: 0 });
  const [orderProps, setOrderProps] = useState({
    field: DutchAuctionOrderByField.StartDate,
    direction: OrderDirection.Desc,
  });
  const [isAthleteCardShown, setIsAthleteCardShown] = useState(false);
  const [auctionRowData, setAuctionRowData] = useState<AuctionRowDataI>(getDefaultState());
  const [inputValue, setInputValue] = useState('');

  const [athlete] = useAtom(selectedAthleteIAtom);
  const [, openAthleteCard] = useAtom(openAthleteCardAtom);
  const [, closeAthleteCard] = useAtom(closeAthleteCardAtom);

  const { data, refetch, startPolling, stopPolling } = useActiveMintsQuery({
    variables: { input: { ...queryPaging, order: { ...orderProps } } },
    fetchPolicy: 'cache-and-network',
  });

  const isAthleteLoaded = !!athlete?.id;

  const tableData: ActiveMintsQuery = useMemo(() => {
    return data ?? { dutchAuctions: { data: [], pagination: defaultPagination } };
  }, [data]);

  useEffect(() => {
    startPolling(2000);

    return () => stopPolling();
  });

  useEffect(() => {
    if (!isAthleteLoaded) {
      setIsAthleteCardShown(false);
      setAuctionRowData(getDefaultState());
    }
  }, [isAthleteLoaded]);

  const handlePaginationChange = useCallback(
    (newQueryPaging) => setQueryPaging(newQueryPaging),
    []
  );
  const handleSetOrder = (orderKey: DutchAuctionOrderByField, orderDirection: OrderDirection) => {
    setOrderProps({
      field: orderKey,
      direction: orderDirection,
    });
  };
  const handleRowClicked = useCallback(
    (auctionData: AuctionRowDataI) => {
      if (auctionData.athlete) {
        if (auctionData.athleteId === auctionRowData?.athleteId) {
          closeAthleteCard();
        } else {
          openAthleteCard({ athlete: auctionData.athlete, view: HCXCardViewE.Bid });
          setAuctionRowData({
            ...auctionData,
          });
        }
      }
    },
    [openAthleteCard, closeAthleteCard, auctionRowData]
  );

  const handleBidClicked = useCallback(() => {
    setIsAthleteCardShown(true);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const web3Functions = useMemo(
    () => ({
      approve: approveERC20,
      postBond: postBond,
    }),
    []
  );

  const { clear: clearHeader } = useRenderToHeaderTradingInfo(
    null,
    useMemo(
      () => (
        <>
          <Typography variant="body1">
            {isAthleteLoaded ? `${athlete.bio.name} - ${t('iho')}` : t('no-athlete-selected')}
          </Typography>
          <Box className={styles.buttonWrapper}>
            <Button
              variant="contained"
              onClick={() => web3Functions.approve(WalletE.Metamask, ApproveTargetE.IHO_CONTRACT)}
            >
              {t('approve-iho')}
            </Button>
            <Button
              variant="contained"
              onClick={() => web3Functions.postBond(WalletE.Metamask, inputValue)}
            >
              {'Post Bond'}
            </Button>
            <input
              type="text"
              placeholder={t('enter-nfttoken')}
              value={inputValue}
              onChange={handleChange}
            />
          </Box>
        </>
      ),
      [isAthleteLoaded, athlete?.bio.name, t, inputValue, web3Functions]
    )
  );

  useEffect(() => {
    closeAthleteCard(null);
    return () => {
      clearHeader();
      closeAthleteCard(null);
    };
  }, [clearHeader, closeAthleteCard]);

  // TODO: Keep commented out for the moment
  // const counterEndDate = useMemo(() => {
  //   if (auctionRowData.mintingStatus === DutchAuctionStatus.Closed) {
  //     return new Date();
  //   }
  //   return auctionRowData.endDate;
  // }, [auctionRowData.mintingStatus, auctionRowData.endDate]);

  // TODO: VOV: This is not perfect way... Should be improved.
  const tableHeight = useMemo(() => {
    if (isAthleteCardShown) {
      return Math.max(height - CHART_HEIGHT_BID_OPEN - GAPS_HEIGHT, MIN_TABLE_HEIGHT);
    } else if (isAthleteLoaded) {
      return Math.max(height - CHART_HEIGHT - GAPS_HEIGHT, MIN_TABLE_HEIGHT);
    }
    return Math.max(height - GAPS_HEIGHT, MIN_TABLE_HEIGHT);
  }, [height, isAthleteLoaded, isAthleteCardShown]);

  return (
    <CustomScrollbars>
      <Box className={isAthleteCardShown ? styles.setFlex : ''}>
        <Box className={isAthleteCardShown ? styles.stretchFlex : ''}>
          {isAthleteLoaded && (
            <Box className={styles.topSection}>
              <ActiveMintsTabs
                refetchAuctions={refetch}
                onBidClick={handleBidClicked}
                dutchAuctionId={auctionRowData.dutchAuctionId}
                auctionBlockchainId={auctionRowData.blockchainId}
                auctionEndPrice={auctionRowData.endPrice}
                mintingStatus={auctionRowData.mintingStatus}
                disableButton={isAthleteCardShown}
              />
              {!isAthleteCardShown && (
                <ActiveMintsAthleteCard athlete={athlete} auctionData={auctionRowData} />
              )}
            </Box>
          )}
          <ActiveMintsTable
            onRowClick={handleRowClicked}
            setOrderProps={handleSetOrder}
            tableData={tableData}
            tableHeight={tableHeight}
            selectedRow={auctionRowData.athleteId}
            handlePaginationChange={handlePaginationChange}
          />
        </Box>
        {isAthleteCardShown && <HCXCardContainer disableClose={false} />}
      </Box>
    </CustomScrollbars>
  );
};
