import { useCallback, MutableRefObject } from 'react';
import { IChartingLibraryWidget } from 'charting_library';
import { downloadImage, getFileNameToSave } from './helpers';
import { noop } from 'utils/noop';

export type NullableWidgetT = IChartingLibraryWidget | null;
export type WidgetRefT = MutableRefObject<NullableWidgetT>;

export const saveChartAsImg = (widget?: NullableWidgetT) => {
  if (!widget) return;
  widget.headerReady().then(() => {
    widget.takeClientScreenshot().then((canvas) => {
      const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      downloadImage(image, getFileNameToSave(widget.activeChart().symbol()));
    });
  });
};

export const useSaveChartAsImg = (widgetRef?: WidgetRefT) => {
  return useCallback(() => {
    saveChartAsImg(widgetRef?.current);
  }, [widgetRef]);
};

export const takeChartScreenshot = (widget?: NullableWidgetT) => {
  if (!widget) return;
  widget.headerReady().then(() => {
    widget.takeClientScreenshot().then((canvas) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const data = [new ClipboardItem({ [blob.type]: blob })];
          navigator.clipboard.write(data).then(() => {
            widget.showNoticeDialog({
              title: 'Copying',
              body: 'Chart image copied to clipboard',
              callback: noop,
            });
          });
        }
      });
    });
  });
};

export const useTakeChartScreenshot = (widgetRef?: WidgetRefT) => {
  return useCallback(() => {
    takeChartScreenshot(widgetRef?.current);
  }, [widgetRef]);
};

export const openChartSettings = (widget?: NullableWidgetT) => {
  if (!widget) return;
  widget.headerReady().then(() => {
    widget.chart().executeActionById('chartProperties');
  });
};

export const useOpenChartSettings = (widgetRef?: WidgetRefT) => {
  return useCallback(() => {
    openChartSettings(widgetRef?.current);
  }, [widgetRef]);
};

export const makeChartFullScreen = (widget?: NullableWidgetT) => {
  if (!widget) return;
  widget.headerReady().then(() => {
    widget.startFullscreen();
  });
};

export const useMakeChartFullScreen = (widgetRef?: WidgetRefT) => {
  return useCallback(() => {
    makeChartFullScreen(widgetRef?.current);
  }, [widgetRef]);
};

export const setNewSymbolToChart = (newSymbol: string, widget?: NullableWidgetT) => {
  if (!widget || !newSymbol) return;
  const { symbol, interval } = widget.symbolInterval();

  if (!symbol.toLowerCase().endsWith(newSymbol.toLowerCase())) {
    widget.setSymbol(newSymbol, interval, noop);
  }
};

// Not sure but I think we can delete this as we don't work with mocks anymore and I can't find any reference of it being used

// const setMocSymbolToChart = (widget?: NullableWidgetT) => {
//   if (!widget) return;
//   const newSymbol = mocSymbols[randomIntFromInterval(0, mocSymbols.length - 1)];

//   setNewSymbolToChart(newSymbol, widget);
// };

// export const useSetNewSymbolToChart = (widgetRef?: WidgetRefT) => {
//   return useCallback(
//     (newSymbol?: string) => {
//       if (!newSymbol) {
//         setMocSymbolToChart(widgetRef?.current);
//       } else {
//         setNewSymbolToChart(newSymbol, widgetRef?.current);
//       }
//     },
//     [widgetRef]
//   );
// };
