import type { ReactNode, Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import { noop } from 'utils/noop';

interface RenderToHeaderContextI {
  content: ReactNode | null;
  setContent: Dispatch<SetStateAction<ReactNode>>;
  leftContent: ReactNode | null;
  setLeftContent: Dispatch<SetStateAction<ReactNode>>;
  isMenuPinned: boolean;
  setMenuPinned: Dispatch<SetStateAction<boolean>>;
  isMenuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultValue: RenderToHeaderContextI = {
  content: null,
  setContent: noop,
  leftContent: null,
  setLeftContent: noop,
  isMenuPinned: false,
  setMenuPinned: noop,
  isMenuOpen: false,
  setMenuOpen: noop,
};

export const RenderToHeaderContext = createContext<RenderToHeaderContextI>(defaultValue);
