export enum AuthOriginE {
  Google = 'google',
  Password = 'password',
}

const KEY = 'auth';

interface StoredGoogleAuthDataI {
  accessToken: string;
  origin: AuthOriginE.Google;
}

interface StoredPasswordAuthDataI {
  accessToken: string;
  idToken: string;
  origin: AuthOriginE.Password;
}

type StoredAuthDataT = StoredGoogleAuthDataI | StoredPasswordAuthDataI;

export const saveAuthData = (data: StoredAuthDataT) => {
  localStorage.setItem(KEY, JSON.stringify(data));
};

export const restoreAuthData = (): StoredAuthDataT | undefined => {
  const json = localStorage.getItem(KEY);
  if (!json) return undefined;
  return JSON.parse(json);
};

export const clearAuthData = () => {
  localStorage.removeItem(KEY);
};
