import { FC, useMemo } from 'react';
import { useTimeLeft } from 'hooks/useTimeLeft';
import { parseTimeLeft } from 'utils/parse-time-left';
import styles from './trade-bar-time-left.module.scss';

interface TradeBarTimeLeftPropsI {
  endDate: Date;
  onTimeOut?: () => void;
}

const TradeBarTimeLeft: FC<TradeBarTimeLeftPropsI> = ({ endDate, onTimeOut }) => {
  const timeLeftMS = useTimeLeft(endDate);

  const { hours, minutes, seconds } = useMemo(() => {
    if (!timeLeftMS) {
      onTimeOut?.();
    }
    return parseTimeLeft(timeLeftMS);
  }, [timeLeftMS, onTimeOut]);

  return <span className={styles.root}>{`${hours}hr ${minutes}m ${seconds}s`}</span>;
};

export default TradeBarTimeLeft;
