import type { FC } from 'react';
import { useEffect, useMemo, useRef } from 'react';

import classNames from 'classnames';

import { makeUniqueId } from '@apollo/client/utilities';

import { Typography } from '@mui/material';

import {
  formatNumber,
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
} from 'utils/number-formatter';
import { addBidToModel, removeBidFromModel } from '../../helpers';
import {
  DutchAuctionBidSubscriptionBidStatus,
  useLatestBidsReceivedSubscription,
} from 'generated/graphql';

import styles from './active-mints-chart.module.scss';

export interface BidI {
  bidderAddress: string;
  price: number;
  total: number;
  amounts: number[];
}

export interface ActiveMintsChartPropsI {
  thresholdPrice: number;
  userBidPrice: number;
  userBidAmount: number;
  chartData: BidI[];
  dutchAuctionId: number;
  onUserBid: () => void;
}

const getRandomID = () => {
  return makeUniqueId('ID');
};

export const ActiveMintsChart: FC<ActiveMintsChartPropsI> = ({
  thresholdPrice,
  userBidPrice,
  userBidAmount,
  chartData,
  dutchAuctionId,
  onUserBid,
}) => {
  const { data } = useLatestBidsReceivedSubscription({
    variables: { auctionId: dutchAuctionId },
  });

  const latestBidRef = useRef(data?.latestBidRecievedOnAuction.bidData);

  const latestBidData = useMemo(() => {
    if (
      data?.latestBidRecievedOnAuction?.bidData &&
      data.latestBidRecievedOnAuction.bidData !== latestBidRef.current
    ) {
      const { latestBidRecievedOnAuction } = data;
      latestBidRef.current = latestBidRecievedOnAuction.bidData;
      if (latestBidRecievedOnAuction.status === DutchAuctionBidSubscriptionBidStatus.Added) {
        return addBidToModel(chartData, latestBidRecievedOnAuction.bidData);
      } else if (
        latestBidRecievedOnAuction.status === DutchAuctionBidSubscriptionBidStatus.Refunded
      ) {
        return removeBidFromModel(chartData, latestBidRecievedOnAuction.bidData);
      }
    }
    return chartData;
  }, [data, chartData]);

  useEffect(() => {
    onUserBid();
  }, [data, onUserBid]);

  return (
    <div className={styles.root}>
      <table className={styles.chart}>
        <tbody>
          {latestBidData.map(({ price, total, amounts }) => (
            <tr
              key={getRandomID()}
              className={thresholdPrice === price ? styles.thresholdBackground : ''}
            >
              <th>
                <div
                  className={classNames(
                    styles.defaultInfoArrow,
                    thresholdPrice > userBidPrice && userBidPrice > 0 ? styles.redInfoArrow : ''
                  )}
                >
                  <Typography variant="body1">
                    {formatNumber(
                      normalizeDecimals(price, NormalizationDecimalsE.Six)[
                        NormalizationLogicE.NonDecimal
                      ],
                      { minimumFractionDigits: 2 }
                    )}
                  </Typography>
                </div>
              </th>
              <td
                className={classNames(
                  thresholdPrice === price ? styles.bottomBorder : '',
                  thresholdPrice > userBidPrice && userBidPrice > 0 ? styles.redBottomBorder : ''
                )}
              >
                <div className={styles.cellWrapper}>
                  {amounts.map((amount) => (
                    <div
                      key={getRandomID()}
                      className={classNames(
                        styles.rate,
                        userBidPrice === price && userBidAmount === amount ? styles.magenta : ''
                      )}
                      style={{ width: `${(amount / total) * 100}%` }}
                    />
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
