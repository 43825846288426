import { FC } from 'react';
import styles from './results.module.scss';
import Typography from '@mui/material/Typography';
import CustomScrollbars from 'components/custom-scrollbars/custom-scrollbars';
import { CircularProgress } from '@mui/material';
import { ResultsWrapper } from './results-wrapper';

export interface ResultItemI {
  id: string | number;
  name: string;
  photo: string;
  ticker: string;
  title: string;
  position: string;
  team: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface PropsI {
  className?: string;
  items: ResultItemI[];
  onItemClick: (id: string | number, data: ResultItemI) => void;
  loading?: boolean;
  onClose: () => void;
  anchorEl?: Element;
}

export const Results: FC<PropsI> = ({ className, items, onItemClick, loading, onClose }) => {
  if (loading) {
    return (
      <ResultsWrapper onClose={onClose} className={className}>
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      </ResultsWrapper>
    );
  }

  if (!items.length) {
    return (
      <ResultsWrapper onClose={onClose} className={className}>
        <div className={styles.notFound}>
          <Typography variant="body1">No Results Found</Typography>
        </div>
      </ResultsWrapper>
    );
  }

  return (
    <ResultsWrapper onClose={onClose} title={`${items.length} Results Found`} className={className}>
      <CustomScrollbars autoHeight autoHeightMin={137} autoHeightMax={350}>
        <table className={styles.table}>
          <tbody>
            {items.map((item) => {
              const { id, photo, name, ticker, title, position, team } = item;
              const onClick = () => {
                onItemClick(id, item);
              };
              return (
                <tr key={id} onClick={onClick} className={styles.row}>
                  <td className={styles.image}>
                    <img className={styles.photo} src={photo} alt="" />
                  </td>
                  <td className={styles.name} title={name}>
                    <span>{name}</span>
                  </td>
                  <td className={styles.ticker} title={ticker}>
                    <span>{ticker}</span>
                  </td>
                  <td className={styles.description} title={`${title}  ${position}  ${team}`}>
                    <span>
                      {title}&nbsp;&nbsp;{position}&nbsp;&nbsp;{team}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CustomScrollbars>
    </ResultsWrapper>
  );
};
