import styles from './button.module.scss';
import type { FC } from 'react';
import { Button as MuiButton } from '@mui/material';
import type { ButtonProps } from '@mui/material/Button/Button';

const BUTTON_CLASSES: ButtonProps['classes'] = {
  root: styles.root,
  containedPrimary: styles.containedPrimary,
  containedSizeSmall: styles.containedSizeSmall,
  outlinedSizeSmall: styles.outlinedSizeSmall,
  disabled: styles.disabled,
  sizeMedium: styles.sizeMedium,
  sizeSmall: styles.sizeSmall,
  textPrimary: styles.textPrimary,
  text: styles.text,
};

export const Button: FC<ButtonProps> = ({ children, ...props }) => (
  <MuiButton classes={BUTTON_CLASSES} disableFocusRipple {...props}>
    {children}
  </MuiButton>
);
