enum IHOUnderwriterStatusE {
  Completed = 'Completed',
}

enum IHOStatusE {
  Scheduled = 'Scheduled',
  Pending = 'Pending',
  Success = 'Success',
}

export { IHOUnderwriterStatusE, IHOStatusE };
