import { Athlete } from '../generated/graphql';
import { AthleteI } from '../types';
import {
  AthleteBidStatusE,
  AthleteIcoStatusE,
  AthleteRosterStatusE,
  MintingStatusE,
  SexE,
} from '../enums';
import { createMocOrder, generateItemsList } from '../moc-data/moc-athlete';

const qbText = 'QB';
const longText = 'LONGTEXT';

export function convertAthleteToAthleteI(athlete: Athlete): AthleteI {
  const entry = {
    ...athlete,
  };

  if (!entry.currentJob) {
    entry.currentJob = {
      league: 'TestLeague',
      team: 'TestTeam',
      drafted: 'TestNumber',
      rosterStatus: false,
    };
  }

  return {
    id: Number(entry.id),
    favorite: false,
    bio: {
      name: entry.fullName,
      born: entry.dob as number,
      sex: SexE.Male,
      height: entry.height.toString(),
      education: entry.education,
      photo: entry.imageUrl,
      email: 'test@test.com',
      lastActivity: (() => {
        const lastActivity = new Date();
        lastActivity.setHours(lastActivity.getHours() - 10);
        return lastActivity.toString();
      })(),
    },
    career: {
      league: { name: entry.currentJob.league, shortName: 'testleagueshortname' },
      team: entry.currentJob.team,
      position: entry.currentJob.position as string,
      positionShort: 'TestShortName',
      jerseyNumber: entry.currentJob.number ?? 0,
      rosterStatus: AthleteRosterStatusE.Active,
      drafted: entry.currentJob.drafted,
      history: [
        {
          place: 'Professional' + qbText,
          position: '2021 Free Agent, Los Angeles Rams' + qbText,
          description: 'Contract  3 of 3' + qbText,
        },
        {
          place: 'Professional',
          position: '2019 Free Agent, Cleveland Browns',
          description: 'Contract  2 of 3',
        },
        {
          place: 'Professional',
          position: '2014 Drafted (R1:P12), New York Giants',
          description: 'Contract  1 of 3',
        },
        {
          place: 'College',
          position: 'LSU (2011–2013)',
        },
        {
          place: 'High School',
          position: 'Isidore Newman (All Freshman Team)',
        },
      ],
      stats: {
        career: {
          receiving: [
            {
              gp: 71,
              rec: 433,
              yds: 5517,
              avg: 12.7,
              td: 40,
              fum: 4,
              lst: 1,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          rushing: [
            {
              gp: 71,
              rec: 433,
              yds: 5517,
              avg: 12.7,
              td: 40,
              fum: 4,
              lst: 1,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          passing: [
            {
              gp: 71,
              rec: 433,
              yds: 5517,
              avg: 12.7,
              td: 40,
              fum: 4,
              lst: 1,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          returning: [
            {
              gp: 71,
              rec: 433,
              yds: 5517,
              avg: 12.7,
              td: 40,
              fum: 4,
              lst: 1,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
        },
        season: {
          receiving: [
            {
              gp: 33,
              rec: 20,
              yds: 1023,
              avg: 3,
              td: 35634,
              fum: 34,
              lst: 4,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          rushing: [
            {
              gp: 33,
              rec: 20,
              yds: 1023,
              avg: 3,
              td: 35634,
              fum: 34,
              lst: 4,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          passing: [
            {
              gp: 33,
              rec: 20,
              yds: 1023,
              avg: 3,
              td: 35634,
              fum: 34,
              lst: 4,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          returning: [
            {
              gp: 33,
              rec: 20,
              yds: 1023,
              avg: 3,
              td: 35634,
              fum: 34,
              lst: 4,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
        },
      },
      feed: [
        {
          text: 'Touchdown, 30 yard pass from T. Brady',
          date: 'Sunday 11/29/2022 10:40',
        },
        {
          text: '30yard run, pass incomplete - pulled hamstring',
          date: 'Sunday 11/29/2022 10:40',
        },
        {
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          date: 'Sunday 11/29/2022 10:40',
        },
      ],
      // activity: generateItemsList(10, createMocActivity), // TODO: GraphQL will provide this eventually.
      activity: [],
      title: 'TEST TITLE TEST TITLE',
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum omnis ratione animi corporis dolorem veritatis eum blanditiis, repellendus earum ullam a nemo, quos optio! Fuga animi aperiam repudiandae asperiores cupiditate nesciunt magnam sunt, adipisci ut, odio dignissimos! Similique velit recusandae molestiae sequi laudantium debitis autem assumenda quis animi, corporis laboriosam totam consequatur iusto quae dolorum blanditiis vero impedit molestias rerum unde suscipit. Officiis pariatur facere aperiam possimus adipisci! Tempora consequuntur debitis itaque culpa reprehenderit nesciunt nulla rerum. Veniam adipisci, cum corrupti blanditiis quam perferendis, soluta numquam vitae dolores qui consequuntur asperiores nulla delectus totam doloremque quo! Voluptas ratione quam odio?',
    },
    tradingInfo: {
      contractId: entry.nftSymbol,
      tokenAddress: entry.tradingPair?.tokenAddress,
      tradingPairId: entry.tradingPair?.id ?? '',
      stableCoinAddress: entry.tradingPair?.stableCoinAddress,
      bidStatus: AthleteBidStatusE.ICO,
      contractTerm: {
        start: 'November 5, 2020',
        end: 'November 5, 2023',
      },
      costBasis: 59968.62,
      price: 0,
      units: 10,
      marketCap: 12500000,
      normalizedMarketCap: 1500000,
      lastPrice: 0,
      lastPriceProgress: 0,
      volume24: 450000,
      floatValue: 2500000,
      todayProgress: -7608.22,
      totalProgress: 286309.55,
      mintingStatus: MintingStatusE.Active,
      orders: generateItemsList(15, createMocOrder),
      offeringTerms: {
        revenue: 1.5,
        revenueType: 'Professional Sports Career Salary & Bonuses' + longText,
        fractionalTokensOffered: 1000,
        maxBidPerToken: 100,
      },
      icoStatus: {
        status: AthleteIcoStatusE.Active,
        opening: 'October 3, 2022 8:00 GMT',
        closing: 'October 10, 2022 12:00 GMT',
      },
    },
  };
}
