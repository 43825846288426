import type { FC } from 'react';
import { useState, useCallback } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { SearchField } from 'components/search-field';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SelectField } from 'components/select-field';
import { useAtom } from 'jotai';
import {
  talentSponsorsListFilter,
  talentSponsorsListOrderDirection,
  talentSponsorsListOrderType,
} from 'store/talent-application.store';
import styles from './sponsor-search.module.scss';
import { OrderDirection, TalentSponsorOrderType } from 'generated/graphql';

export const SponsorSearch: FC = () => {
  const [orderType, setOrderType] = useAtom(talentSponsorsListOrderType);
  const [buttonState, setButtonState] = useState(true);
  const [, setOrderDirection] = useAtom(talentSponsorsListOrderDirection);
  const [filter, setFilter] = useAtom(talentSponsorsListFilter);

  const changeButton = useCallback(() => {
    setButtonState((prev) => !prev);
    if (buttonState) {
      setOrderDirection(OrderDirection.Asc);
    }
    if (!buttonState) {
      setOrderDirection(OrderDirection.Desc);
    }
  }, [buttonState, setOrderDirection]);

  return (
    <Box className={styles.root}>
      <SearchField
        value={filter}
        handleChange={(e: { target: { value: string } }) => setFilter(e.target.value)}
        placeholder={'Search'}
      />
      <Typography variant="body2">Sort by:</Typography>
      <SelectField
        value={orderType}
        onChangeHandler={(e) => setOrderType(e.target.value as TalentSponsorOrderType)}
        menuItems={[
          TalentSponsorOrderType.Alphabetical,
          // TalentSponsorOrderType.MostIho, //TODO: unhide this row when it will be ready on backend
          TalentSponsorOrderType.MostRecent,
        ]}
        className={styles.sortDirection}
      />
      <IconButton onClick={changeButton}>
        <FilterListIcon
          fontSize="small"
          className={buttonState ? styles.iconUp : styles.iconDown}
        />
      </IconButton>
    </Box>
  );
};
