import type { FC } from 'react';

import { useCurrentRouteConfig } from 'hooks/useCurrentRouteConfig';
import { ROUTE } from 'routes/enums';

import { TickerBar } from './ticker-bar';
import { useAtom } from 'jotai';
import { tickerBarView } from 'store/trading-stats.store';

export const TickerBarContainer: FC = () => {
  const [isTickerBarOpened] = useAtom(tickerBarView);
  const routeConfig = useCurrentRouteConfig();

  if (
    !isTickerBarOpened ||
    !routeConfig?.isPrivate ||
    (routeConfig.path !== ROUTE.TradeDesc &&
      routeConfig.path !== ROUTE.Portfolio &&
      routeConfig.path !== ROUTE.MyOrders &&
      routeConfig.path !== ROUTE.HumanCapitalMint &&
      routeConfig.path !== ROUTE.Wallet)
  ) {
    return null;
  }

  return <TickerBar />;
};
