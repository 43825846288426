import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'auth/use-auth';
import { LoggedInAuthContextI } from 'auth/auth-context';
import { AccountProfilePhotoSelector } from '../account-profile-photo-selector';
import { useAtom } from 'jotai';
import { sponsorManagementView } from 'store/sponsor-management.store';
import { Button } from 'components/button';
import {
  SponsorApplicationStatus,
  TalentApplicationStatus,
  useTalentApplicationStatusQuery,
  useSponsorApplicationStatusQuery,
} from 'generated/graphql';
import classNames from 'classnames';
import styles from './account-profile-info.module.scss';

export const AccountProfileInfo: FC = () => {
  const [, setSponsorManagement] = useAtom(sponsorManagementView);

  const { t } = useTranslation('account-profile');
  const { user, logOut } = useAuth() as LoggedInAuthContextI;
  const { email } = user || {};

  const { data: talentStatus } = useTalentApplicationStatusQuery();

  const { data: sponsorStatus } = useSponsorApplicationStatusQuery();

  const showTalentBadge =
    talentStatus?.talentApplication.status === TalentApplicationStatus.Approved;

  const showSponsorBadge =
    sponsorStatus?.sponsorApplication.status === SponsorApplicationStatus.Approved;

  return (
    <div className={styles.root}>
      <AccountProfilePhotoSelector />
      {!!email && (
        <>
          <label className={classNames(styles.profileBlockLabel, styles.emailLabel)}>
            {t('currently-logged-in-as')}:
          </label>
          <span className={styles.email} title={email}>
            {email}
          </span>
        </>
      )}
      <div className={styles.box}>
        <Button variant="contained" size="small" onClick={logOut}>
          {t('logout')}
        </Button>
        {showTalentBadge && (
          <Button variant="contained" size="small" disabled>
            {t('my-tradedesk')}
          </Button>
        )}
        {showSponsorBadge && (
          <Button onClick={() => setSponsorManagement(true)} variant="contained" size="small">
            {t('sponsor')}
          </Button>
        )}
      </div>
    </div>
  );
};
