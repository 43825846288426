import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import { AccountTalentAccepted } from './account-talent-accepted';
import { TalentApplicationStatus, useTalentApplicationStatusQuery } from 'generated/graphql';
import { useAtom } from 'jotai';
import { talentPanelView } from 'store/talent-application.store';
import classNames from 'classnames';
import styles from './account-talent-flow.module.scss';

export const AccountTalentFlow: FC = () => {
  const { t } = useTranslation('account-profile');

  const { data: talentStatus } = useTalentApplicationStatusQuery();

  const [, setTalentPanelOpen] = useAtom(talentPanelView);

  const talentStatusPending =
    talentStatus?.talentApplication.status === TalentApplicationStatus.Submitted;

  const talentStatusApproved =
    talentStatus?.talentApplication.status === TalentApplicationStatus.Approved;

  const styleNonDefault = talentStatusPending && talentStatusApproved;

  return (
    <Box
      className={classNames(
        styles.root,
        { [styles.backgroundPending]: styleNonDefault },
        { [styles.backgroundAccepted]: talentStatusApproved }
      )}
    >
      {talentStatusApproved ? (
        <AccountTalentAccepted />
      ) : (
        <>
          <Box display="flex" gap={0.5} alignItems="baseline">
            <Typography variant="body2">{t('account-status')}</Typography>
            <Typography
              variant="body1"
              className={classNames({ [styles.statusColor]: talentStatusPending })}
            >
              {talentStatusPending ? t('pending-public') : t('non-public')}
            </Typography>
          </Box>
          {talentStatusPending ? (
            <Typography variant="body1" className={styles.colorBlue}>
              {t('what-next')}
            </Typography>
          ) : (
            <Button variant="outlined" size="small" onClick={() => setTalentPanelOpen(true)}>
              {t('go-public')}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
