import { FC, useContext, Context } from 'react';
import { Box, Slider, SliderProps, Typography } from '@mui/material';
import { CustomSwitcher } from 'components/custom-switcher';
import { WidgetCardSizeE } from '../../widget-context';
import { SortDescIcon, SortAscIcon } from 'components/design';
import styles from './menu-content.module.scss';
import { WidgetContextI } from '../../widget-context/widget-context';

const progressTypeLabels = [
  <span key="$" className={styles.toggleText}>
    <span>$</span>
  </span>,
  <span key="%" className={styles.toggleText}>
    <span>%</span>
  </span>,
];
const sortLabels = [
  <span key="desc" className={styles.toggleText}>
    <SortDescIcon />
  </span>,
  <span key="asc" className={styles.toggleText}>
    <SortAscIcon />
  </span>,
];

const classes = {
  root: styles.slider,
  mark: styles.sliderMark,
  markActive: styles.sliderMark,
  track: styles.sliderTrack,
  rail: styles.sliderRail,
  thumb: styles.sliderThumb,
  active: styles.sliderThumb,
};

const MenuContent: FC<{ context: Context<WidgetContextI> }> = ({ context }) => {
  const {
    cardSize,
    progressType,
    sort,
    changeProgressType,
    changeSortType,
    changeCardSize,
    hideSizer,
  } = useContext(context);

  const handleChangeCardSize: SliderProps['onChange'] = (_, value) => {
    changeCardSize(value as WidgetCardSizeE);
  };
  return (
    <Box className={styles.root}>
      <Box className={styles.switchers}>
        <Box>
          <Typography className={styles.label}>Display</Typography>
          <CustomSwitcher
            labels={progressTypeLabels}
            value={progressType}
            onChange={changeProgressType}
            className={styles.switcher}
            size="small"
            activeColor="var(--hcx-alert-up)"
          />
        </Box>
        <Box>
          <Typography className={styles.label}>Sort</Typography>
          <CustomSwitcher
            labels={sortLabels}
            value={sort}
            onChange={changeSortType}
            className={styles.switcher}
            size="small"
            activeColor="var(--hcx-alert-up)"
          />
        </Box>
      </Box>
      {!hideSizer && (
        <Box className={styles.sizerWrapper}>
          <Typography className={styles.label}>Card Size</Typography>
          <Slider
            aria-label="card-type"
            value={cardSize}
            step={1}
            min={WidgetCardSizeE.Small}
            max={WidgetCardSizeE.Large}
            onChange={handleChangeCardSize}
            size="small"
            marks
            classes={classes}
          />
        </Box>
      )}
    </Box>
  );
};

export default MenuContent;
