import { createContext, Dispatch, SetStateAction } from 'react';
import { noop } from 'utils/noop';

export interface TalentPortalTabsContextI {
  tab: number;
  setTab: Dispatch<SetStateAction<number>>;
}

export const TalentPortalTabsContext = createContext<TalentPortalTabsContextI>({
  tab: 0,
  setTab: noop,
});
