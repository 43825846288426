import type { FC } from 'react';

import classNames from 'classnames';

import { useRotateAthleteFinancialCard } from 'apolloClient/hooks';

import { HCXFinancialCardBack } from './hcx-financial-card-back';
import { useHCXFinancialCardContext } from './hcx-financial-card-context';
import { HCXFinancialCardFront } from './hcx-financial-card-front';

import { Box } from '@mui/material';

import styles from './hcx-financial-card.module.scss';

export const HCXFinancialCard: FC = () => {
  const { athlete } = useHCXFinancialCardContext();
  const { isRotated } = useRotateAthleteFinancialCard(athlete?.id ?? 0);

  return (
    <Box className={classNames(styles.root, isRotated && styles.rotated)}>
      <Box className={styles.front}>
        <HCXFinancialCardFront />
      </Box>
      <Box className={styles.back}>
        <HCXFinancialCardBack />
      </Box>
    </Box>
  );
};
