import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/button';

import styles from './order-book-warning.module.scss';

export const OrderBookWarning: FC = () => {
  const { t } = useTranslation('overview');

  return (
    <div className={styles.root}>
      <span className={styles.info}>{t('trading-haulted-active-offering-inprogress')}</span>
      <Button variant="outlined" size="small" color="warning" className={styles.action}>
        {t('view')}
      </Button>
    </div>
  );
};
