import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { InputField } from 'components/input-field';
import { useTranslation } from 'react-i18next';
import styles from './form-section-references.module.scss';
import { useAtom } from 'jotai';
import { talentProfileFormValidationAtom } from 'store/talent-application.store';
import {
  TalentApplicationProfileQuery,
  TalentApplicationProfileReference,
  useUpdateTalentApplicationProfileReferencesMutation,
} from 'generated/graphql';
import { ValidationError } from 'yup';
import { talentEmergencyReferenceSchema } from 'yup/talentFormSchema';
import { ApolloError, ApolloQueryResult } from '@apollo/client';

interface FormSectionReferencesPropsI {
  references: TalentApplicationProfileReference[];
  refetchProfile: () => Promise<ApolloQueryResult<TalentApplicationProfileQuery>>;
}

const NAME_MAP: Record<string, string> = {
  firstName: 'reference-',
  lastName: 'last',
  emailAddress: 'email-address',
  phone: 'phone',
};

export const FormSectionReferences: FC<FormSectionReferencesPropsI> = ({
  references,
  refetchProfile,
}) => {
  const { t } = useTranslation('talent-application');

  const [errorState, setErrorState] = useAtom(talentProfileFormValidationAtom);

  const { references: errors } = errorState;

  const [updateReference] = useUpdateTalentApplicationProfileReferencesMutation();

  const onBlurHandler = async (event: React.FocusEvent, index: number) => {
    const { name, value } = event.target as HTMLInputElement;

    try {
      await talentEmergencyReferenceSchema[name].validate(value);
    } catch (error) {
      setErrorState((prev) => {
        const updatedContactError = {
          ...prev.references[index],
          [name]: { errMessage: (error as ValidationError).errors[0], isTouched: true },
        };
        const newReferencesErrorState = [...prev.references];
        newReferencesErrorState[index] = updatedContactError;
        return {
          ...prev,
          references: newReferencesErrorState,
        };
      });
      return console.warn({ error });
    }
    setErrorState((prev) => {
      const updatedContactError = {
        ...prev.references[index],
        [name]: { errMessage: undefined, isTouched: true },
      };
      const newReferencesErrorState = [...prev.references];
      newReferencesErrorState[index] = updatedContactError;
      return {
        ...prev,
        references: newReferencesErrorState,
      };
    });

    try {
      await updateReference({
        variables: {
          parameters: {
            reference: {
              ...(references[index]?.id
                ? {
                    id: references[index].id.toString(),
                  }
                : {}),
              [name]: value,
              condition: {
                field: name,
                state: references[index]?.condition?.[name]?.state ?? 0,
              },
            },
          },
        },
        refetchQueries: ['TalentApplicationState'],
      });
    } catch (error) {
      setErrorState((prev) => {
        const updatedContactError = {
          ...prev.references[index],
          [name]: { errMessage: (error as ApolloError).message, isTouched: true },
        };
        const newReferencesErrorState = [...prev.references];
        newReferencesErrorState[index] = updatedContactError;
        return {
          ...prev,
          references: newReferencesErrorState,
        };
      });
    }
    await refetchProfile();
  };

  return (
    <Box component="form" autoComplete="off">
      {new Array(5)
        .fill(null)
        .map((_, indexId) => ({ id: indexId }))
        .map((val, i) => {
          return (
            <Box key={val.id} display="flex" gap={3} mb={2}>
              {Object.keys(NAME_MAP).map((name) => {
                return (
                  <InputField
                    key={name}
                    tName={t(`${NAME_MAP[name]}${name === 'firstName' ? i + 1 : ''}`)}
                    className={styles.widthInput}
                    name={name}
                    onBlur={(event) => onBlurHandler(event, i)}
                    defaultValue={
                      references?.[i]?.[name as keyof TalentApplicationProfileReference] || ''
                    }
                    errorValidation={
                      errors[i][name as keyof typeof errors[typeof i]].isTouched &&
                      errors[i][name as keyof typeof errors[typeof i]].errMessage
                    }
                  />
                );
              })}
            </Box>
          );
        })}
    </Box>
  );
};
