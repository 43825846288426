import { FC } from 'react';
import Box from '@mui/material/Box';

type TabPanelPropsT = {
  children: React.ReactNode;
  value: number;
  index: number;
  keepMounted?: boolean;
  className?: string;
  wrapperClassName?: string;
};

export const TabPanel: FC<TabPanelPropsT> = ({
  children,
  value,
  index,
  keepMounted,
  className,
  wrapperClassName,
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={wrapperClassName}
    >
      {(value === index || keepMounted) && <Box className={className}>{children}</Box>}
    </div>
  );
};
