import { LocaleE } from 'enums';

interface DateTimeFormatOptionsI {
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
  weekday?: 'long' | 'short' | 'narrow';
  era?: 'long' | 'short' | 'narrow';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  timeZoneName?: 'long' | 'short';
  formatMatcher?: 'basic' | 'best fit';
  hour12?: boolean;
  timeZone?: 'UTC';
}

export enum DateFormatE {
  MMMM_DD_YYYY = 'MMMM_DD_YYYY',
  WeekDay_DD_YYYY_hh_mm = 'DD_MM_YYYY hh_mm',
  MM_DD_YY = 'MM_DD_YY',
  HH_MM_SS = 'HH_MM_SS',
  MM_DD_YY_HH_MM_SS = 'MM_DD_YY_HH_MM_SS',
  M_D_YY_HH_MM_SS_UTC = 'M_D_YY_HH_MM_SS_UTC',
  M_D_YYYY_h_mm_ss_UTC = 'M_D_YYYY_h_mm_ss_UTC',
  MMMM_D_YYYY_h_mm_UTC = 'MMMM_D_YYYY_h_mm_UTC',
  M_D_YYYY_h_mm = 'M_D_YYYY_h_mm',
  M_D_YYYY_H_MM_UTC = 'M_D_YYYY_H_MM_UTC',
  MM_DD_YYYY = 'MM_DD_YYYY',
  MM_DD_YYYY_HH_MM = 'MM_DD_YYYY_HH_MM',
}

const options: Record<DateFormatE, DateTimeFormatOptionsI> = {
  [DateFormatE.MMMM_DD_YYYY]: { month: 'long', day: '2-digit', year: 'numeric' },
  [DateFormatE.MM_DD_YYYY]: { month: '2-digit', day: '2-digit', year: 'numeric' },
  [DateFormatE.WeekDay_DD_YYYY_hh_mm]: {
    weekday: 'long',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  },
  [DateFormatE.MM_DD_YY_HH_MM_SS]: {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  },
  [DateFormatE.M_D_YY_HH_MM_SS_UTC]: {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  },
  [DateFormatE.M_D_YYYY_h_mm_ss_UTC]: {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  },
  [DateFormatE.M_D_YYYY_h_mm]: {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  },
  [DateFormatE.MM_DD_YY]: {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  },
  [DateFormatE.HH_MM_SS]: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  },
  [DateFormatE.MMMM_D_YYYY_h_mm_UTC]: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  },
  [DateFormatE.M_D_YYYY_H_MM_UTC]: {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: 'UTC',
  },
  [DateFormatE.MM_DD_YYYY_HH_MM]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
};

type DateValueT = number | string | Date;

export const getDateObj = (date: DateValueT): Date =>
  date instanceof Date ? date : new Date(date);

export const resolveDate = (date: DateValueT): Date | null => {
  if (!date) return null;

  const dateObj = getDateObj(date);

  if (dateObj instanceof Date && !Number.isNaN(dateObj.getTime())) return dateObj;

  return null;
};

export const dateFormatter = (
  date: DateValueT,
  format: DateFormatE,
  locale: LocaleE | LocaleE[] = LocaleE.en_US
): string => {
  const resolvedDate = resolveDate(date);

  if (!resolvedDate) return '';

  return new Intl.DateTimeFormat(locale, options[format]).format(resolvedDate);
};
