import { Typography } from '@mui/material';
import type { FC } from 'react';

import { WalletViewModelI } from './types';

import styles from './e-wallets.module.scss';

interface EWalletsPropsI {
  onClick: (id: string) => void;
  items: WalletViewModelI[];
}

export const EWallets: FC<EWalletsPropsI> = ({ onClick, items }) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {items.map(({ id, image, title }) => (
          <div key={id} onClick={() => onClick(id)} className={styles.item}>
            <div className={styles.imageWrapper}>
              <img src={image} alt="" />
            </div>
            <Typography variant="body1" fontWeight="700">
              {title}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
