import type { FC } from 'react';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import styles from './badge-incomplete.module.scss';

export const BadgeIncomplete: FC<{ complete?: boolean | undefined | null }> = ({ complete }) => {
  const { t } = useTranslation('sponsor-application');

  if (complete) return <CheckCircleIcon fontSize="small" className={styles.checkIcon} />;

  return <Box className={styles.incomplete}>{t('incomplete')}</Box>;
};
