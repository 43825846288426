import { FC } from 'react';
import { Stack } from '@mui/material';
import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';
import styles from './account-profile-tfa.module.scss';

const AccountProfileTFA: FC = () => {
  const { t } = useTranslation('account-profile');
  return (
    <div>
      <span className={styles.description}>{t('two-factor-authentication-description')}</span>
      <Stack direction="row" spacing="10px" className={styles.actions}>
        <Button variant="outlined" size="small" className={styles.actionBtn}>
          {t('google-authenticator')}
        </Button>
        <Button variant="outlined" size="small" className={styles.actionBtn}>
          {t('security-key-with-examples')}
        </Button>
        <Button variant="outlined" size="small" className={styles.actionBtn}>
          {t('sms')}
        </Button>
      </Stack>
    </div>
  );
};

export default AccountProfileTFA;
