import { UserI } from './types';

export interface AuthStateI {
  user?: UserI;
  accessToken?: string;
  isLoading: boolean;
  isFailed: boolean;
  error?: string;
  initialLoading: boolean;
}

export const initialState = {
  isLoading: false,
  isFailed: false,
  initialLoading: true,
};

const enum ActionTypesE {
  SetLoading = 'set-loading',
  FinishInitialLoading = 'finish-initial-loading',
  SetFailed = 'set-failed',
  LogIn = 'log-in',
  LogOut = 'log-out',
  ClearFailed = 'clear-failed',
}

export const setLoadingAction = (state: boolean) =>
  ({
    type: ActionTypesE.SetLoading,
    payload: state,
  } as const);

export const finishInitialLoadingAction = () =>
  ({
    type: ActionTypesE.FinishInitialLoading,
  } as const);

export const setFailedAction = (error: string) =>
  ({
    type: ActionTypesE.SetFailed,
    payload: { error },
  } as const);

export const logInAction = (user: UserI, accessToken: string) =>
  ({
    type: ActionTypesE.LogIn,
    payload: { user, accessToken },
  } as const);

export const logOutAction = () =>
  ({
    type: ActionTypesE.LogOut,
  } as const);

export const clearFailedAction = () =>
  ({
    type: ActionTypesE.ClearFailed,
  } as const);

type AuthActionT =
  | ReturnType<typeof setLoadingAction>
  | ReturnType<typeof finishInitialLoadingAction>
  | ReturnType<typeof setFailedAction>
  | ReturnType<typeof logInAction>
  | ReturnType<typeof logOutAction>
  | ReturnType<typeof clearFailedAction>;

export const authReducer = (state: AuthStateI, action: AuthActionT): AuthStateI => {
  if (action.type === ActionTypesE.SetLoading) {
    return { ...state, isLoading: action.payload };
  }
  if (action.type === ActionTypesE.FinishInitialLoading) {
    return { ...state, initialLoading: false };
  }
  if (action.type === ActionTypesE.SetFailed) {
    return { ...state, isFailed: true, error: action.payload.error };
  }
  if (action.type === ActionTypesE.LogIn) {
    const { user, accessToken } = action.payload;
    return { ...state, user, accessToken, isLoading: false, isFailed: false };
  }
  if (action.type === ActionTypesE.ClearFailed) {
    return { ...state, error: '', isFailed: false };
  }
  if (action.type === ActionTypesE.LogOut) {
    return initialState;
  }
  return state;
};
