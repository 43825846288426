import { FC } from 'react';

import { useHCXFinancialCardContext } from 'features/hcx-financial-card/hcx-financial-card-context';
import { AthleteBidBuySellBtn } from 'components/athlete/athlete-bid_buy_sell-btn';

import { Box } from '@mui/material';

import styles from './hcx-financial-card-front-price-line.module.scss';
import { AthleteCardPriceLineApollo } from 'components/athlete/athlete-card/athlete-card-price-line/athlete-card-price-line.apollo';

export const HCXFinancialCardFrontChartPriceLine: FC = () => {
  const { athlete } = useHCXFinancialCardContext();

  if (!athlete) {
    return null;
  }

  return (
    <Box className={styles.root}>
      <AthleteCardPriceLineApollo athlete={athlete} className={styles.priceLine} />
      <AthleteBidBuySellBtn athlete={athlete} className={styles.buyBtn} />
    </Box>
  );
};
