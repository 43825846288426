export const MOCK_CHART_DATA = [
  {
    value: 12.01,
    width: '0%',
  },
  {
    value: 12,
    width: '0%',
  },
  {
    value: 11.5,
    width: '30%',
  },
  {
    value: 11,
    width: '25%',
  },
  {
    value: 10.5,
    width: '5%',
  },
  {
    value: 10,
    width: '0%',
  },
  {
    value: 9.5,
    width: '5%',
  },
  {
    value: 9,
    width: '0%',
  },
  {
    value: 12.01,
    width: '55%',
  },
  {
    value: 12,
    width: '45%',
  },
  {
    value: 11.5,
    width: '0%',
  },
  {
    value: 11,
    width: '18%',
  },
  {
    value: 10.5,
    width: '0%',
  },
];
