import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ApplicationStatus } from '../sponsor-panel-application-status';
import { SponsorInformationForm } from '../sponsor-form-information';
import { OwnerInformation } from '../sponsor-form-owner';
import { LegalInformation } from '../sponsor-form-sign';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { sponsorFormValidationAtom } from 'store/sponsor-application.store';
import { areAllFieldsFilled } from 'utils/check-form-filled';
import styles from './sponsor-panel-accordion.module.scss';
import { SubmitButton } from '../sponsor-submit-button';
import { SponsorApplicationStatus, useSponsorApplicationDataQuery } from 'generated/graphql';
import { BadgeIncomplete } from 'components/badge-incomplete';
import { AccordionSection } from 'components/accordion-section';

export const SponsorPanelAccordion: FC = () => {
  const [expandAccordionSection, setExpandAccordionSection] = useState({
    accountStatus: false,
    sponsorSection: false,
    ownerSection: false,
    legalSection: false,
  });
  const [errors] = useAtom(sponsorFormValidationAtom);
  const { t } = useTranslation('sponsor-application');

  const { data: sponsorApplication, refetch, updateQuery } = useSponsorApplicationDataQuery();

  const isSponsorSubmitted =
    sponsorApplication?.sponsorApplication.status === SponsorApplicationStatus.Submitted;

  const isSponsorInfoFilled =
    sponsorApplication &&
    areAllFieldsFilled(
      sponsorApplication?.sponsorApplication.sponsorInformation,
      errors.sponsorInformation,
      ['agreementUrl']
    );

  const isOwnerFilled =
    sponsorApplication &&
    areAllFieldsFilled(
      sponsorApplication?.sponsorApplication.sponsorOwnerInformation,
      errors.sponsorOwnerInformation
    );

  useEffect(() => {
    setExpandAccordionSection({
      accountStatus: isSponsorSubmitted,
      sponsorSection: !isSponsorSubmitted,
      ownerSection: !isSponsorSubmitted,
      legalSection: false,
    });
  }, [isSponsorSubmitted]);

  const changeExpandSection = (
    section: 'accountStatus' | 'sponsorSection' | 'ownerSection' | 'legalSection'
  ) => {
    setExpandAccordionSection((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  if (!sponsorApplication) {
    return null;
  }

  const statusInitiated =
    sponsorApplication?.sponsorApplication.status === SponsorApplicationStatus.Initiated;

  return (
    <Box className={styles.root}>
      {isSponsorSubmitted && (
        <AccordionSection
          expand={expandAccordionSection.accountStatus}
          handleChangeExpanded={() => changeExpandSection('accountStatus')}
          summary={<Typography variant="subtitle2">{t('application-status')}</Typography>}
          details={<ApplicationStatus />}
        />
      )}
      <AccordionSection
        expand={expandAccordionSection.sponsorSection}
        handleChangeExpanded={() => changeExpandSection('sponsorSection')}
        summary={
          <>
            <Typography variant="subtitle2">{t('sponsor-information')}</Typography>
            <BadgeIncomplete complete={isSponsorInfoFilled} />
          </>
        }
        details={
          <SponsorInformationForm
            sponsorInformation={sponsorApplication.sponsorApplication.sponsorInformation}
            updateQuery={updateQuery}
            refetchApplication={refetch}
            isSubmitted={isSponsorSubmitted}
          />
        }
      />
      <AccordionSection
        expand={expandAccordionSection.ownerSection}
        handleChangeExpanded={() => changeExpandSection('ownerSection')}
        summary={
          <>
            <Typography variant="subtitle2">{t('sponsor-owner-information')}</Typography>
            <BadgeIncomplete complete={isOwnerFilled} />
          </>
        }
        details={
          <OwnerInformation
            sponsorOwnerInformation={sponsorApplication.sponsorApplication.sponsorOwnerInformation}
            refetchApplication={refetch}
            inReview={isSponsorSubmitted}
          />
        }
      />
      <AccordionSection
        expand={expandAccordionSection.legalSection}
        handleChangeExpanded={() => changeExpandSection('legalSection')}
        summary={
          <>
            <Typography variant="subtitle2">{t('legal')}</Typography>
            <BadgeIncomplete complete={true} />
          </>
        }
        details={<LegalInformation />}
      />
      <Box justifyContent="flex-end" display="flex" mt={2}>
        {statusInitiated && <SubmitButton />}
      </Box>
    </Box>
  );
};
