import { useAtom } from 'jotai';
import type { FC } from 'react';

import { selectedAthleteIAtom } from 'store/athletes.store';

import { HCXCard, HCXCardPropsI } from './hcx-card';

export type HCXCardContainerPropsT = Pick<HCXCardPropsI, 'disableClose'>;

export const HCXCardContainer: FC<HCXCardContainerPropsT> = ({ disableClose }) => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  if (!athlete) {
    return null;
  }

  return <HCXCard athlete={athlete} disableClose={disableClose} />;
};
