import type { FC } from 'react';

import { OrderTypeT } from '../../../order-book.types';
import { TableRowWithAnimation } from '../order-book-table-row/order-book-table-row';

import styles from './order-book-table.module.scss';
import { useOrderBookWsContext } from '../../../order-book-ws-context';

interface OrderBookTablePropsI {
  orderType: OrderTypeT;
}

export const OrderBookTable: FC<OrderBookTablePropsI> = ({ orderType }) => {
  const { orderBookAsks, orderBookBids } = useOrderBookWsContext();

  const rows = orderType === 'bid' ? orderBookBids : orderBookAsks;

  return (
    <table className={styles.root}>
      <tbody>
        {rows.map((item) => (
          <TableRowWithAnimation key={`${item.id}`} data={item} orderType={orderType} />
        ))}
      </tbody>
    </table>
  );
};
