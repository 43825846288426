import type { FC } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Button } from 'components/button';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import {
  sponsorInvitationEmailList,
  sponsorInvitation,
  sponsorInvitationEmail,
  sponsorInvitationValidation,
} from 'store/sponsor-management.store';
import { useSendSponsorUserInvitationMutation } from 'generated/graphql';
import { MessageAtom } from 'store/message-alert.store';
import { useMembersPermissionsList } from '../sponsor-management.hooks';
import styles from './sponsor-invite-header.module.scss';
import { useResetAtom } from 'jotai/utils';

export const InviteHeader: FC<{ openActivityPanel: () => void }> = ({ openActivityPanel }) => {
  const [invitation] = useAtom(sponsorInvitation);
  const [emailList, setEmailList] = useAtom(sponsorInvitationEmailList);
  const [emailInput] = useAtom(sponsorInvitationEmail);
  const [, setError] = useAtom(MessageAtom);

  const { t } = useTranslation('sponsor-management');

  const [sendSponsorInvitation] = useSendSponsorUserInvitationMutation();
  const { refetchUserList } = useMembersPermissionsList();
  const resetEmail = useResetAtom(sponsorInvitationEmail);
  const resetError = useResetAtom(sponsorInvitationValidation);

  const sendInvite = async () => {
    try {
      if (emailList) {
        for (const mail of emailList) {
          await sendSponsorInvitation({
            variables: { emailAddress: mail, role: invitation.role, message: invitation.message },
          });
          setEmailList((prev) => {
            if (!prev || !prev.length) return null;
            return prev.filter((el) => el !== mail);
          });
        }
      }
      if (emailInput) {
        await sendSponsorInvitation({
          variables: {
            emailAddress: emailInput,
            role: invitation.role,
            message: invitation.message,
          },
        });
      }
      setError({ message: `Emails sent successfully`, type: `success` });
      setEmailList(null);
      await refetchUserList();
      resetEmail();
      resetError();
    } catch (err) {
      if (err instanceof Error) {
        setError({ message: err.message, type: 'error' });
        console.warn(err);
      }
    }
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.row}>
        <Typography className={styles.headerTitle}>{t('invite-member')}</Typography>
      </Box>
      <Box gap={1}>
        <Button variant="contained" size="small" onClick={sendInvite}>
          {t('invite')}
        </Button>
        <IconButton className={styles.closeButton} onClick={openActivityPanel}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
