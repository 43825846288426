import { FC } from 'react';

const ChatProcessing: FC<{ color?: string }> = ({ color = 'var(--hcx-font-primary)' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 2.25C4.875 2.25 1.5 4.935 1.5 8.25C1.5375 9.8625 2.295 11.3775 3.5625 12.375C3.5625 12.825 3.2475 14.0025 1.5 15.75C3.2775 15.6675 4.98 15 6.3525 13.875C7.2075 14.1225 8.1075 14.25 9 14.25C13.125 14.25 16.5 11.565 16.5 8.25C16.5 4.935 13.125 2.25 9 2.25ZM9 12.75C5.685 12.75 3 10.7325 3 8.25C3 5.7675 5.685 3.75 9 3.75C12.315 3.75 15 5.7675 15 8.25C15 10.7325 12.315 12.75 9 12.75ZM12.75 9V7.5H11.25V9H12.75ZM9.75 9V7.5H8.25V9H9.75ZM6.75 9V7.5H5.25V9H6.75Z"
      fill={color}
    />
  </svg>
);

export default ChatProcessing;
