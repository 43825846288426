import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { t as translate } from 'i18next';

import { last } from 'utils/array-last';

import { Button } from '../../../components/button';
import { ColumnConfigurationListItem } from './column-configuration-list-item';
import { EditColumnView } from './edit-column-view';
import { ColumnsConfigurationI } from './types';
import { COLUMN_CONFIGS } from './constants';
import styles from './column-configuration.module.scss';

type EditingStateT =
  | {
      type: 'none';
    }
  | {
      type: 'add';
      id: string;
      title: string;
    }
  | {
      type: 'edit';
      id: string;
    };

const STATE_LABEL = {
  edit: translate('edit'),
  add: translate('add'),
};

const generateId = () => Date.now().toString(36) + Math.random().toString(36).substring(2);

const DEFAULT_TITLE = translate('custom-view');

const generateTitle = (configs: ColumnsConfigurationI[]) => {
  if (!configs.length) {
    return DEFAULT_TITLE;
  }

  const match = last(configs).title.match(/\s(\d+)$/);
  if (!match) {
    return `${last(configs).title} 1`;
  }

  // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
  return `${match.input?.substring(match.index!, match[1].length - 1)} ${
    parseInt(match[1], 10) + 1
  }`;
};

interface PropsI {
  onClose: () => void;
}

export const ColumnConfiguration: FC<PropsI> = ({ onClose }) => {
  const [active, setActive] = useState('default');
  const [editingState, setEditing] = useState<EditingStateT>({ type: 'none' });
  const handleEdit = useCallback((id: string) => {
    setEditing({ type: 'edit', id });
  }, []);
  const [state, setState] = useState<Record<string, ColumnsConfigurationI>>({});
  const addView = () =>
    setEditing({
      type: 'add',
      id: generateId(),
      title: generateTitle(Object.values(state)),
    });

  const onCreateAsNew = (config: ColumnsConfigurationI) => {
    const id = generateId();
    setState((data) => ({ ...data, [id]: { ...config, id } }));
    addView();
  };

  const onSave = (config: ColumnsConfigurationI) => {
    setState((data) => ({ ...data, [config.id]: { ...config } }));
    setEditing({ type: 'none' });
  };

  const onDelete = (id: string) => {
    setState((data) => {
      const result = { ...data };
      delete result[id];
      return result;
    });
    setEditing({ type: 'none' });
  };
  const { t } = useTranslation('human-capital-mint');

  return (
    <Box className={styles.wrapper}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        pl={1}
      >
        <Typography variant="subtitle2">{t('column-configuration')}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box mt={3}>
        <Typography variant="body1" ml={1}>
          {t('default-column-view')}
        </Typography>
        <Box mt={1}>
          <ColumnConfigurationListItem
            id="default"
            title={t('hcx-default-view')}
            onClick={setActive}
            active={active === 'default'}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" ml={1}>
            {t('custom-column-views')}
          </Typography>
          <Button variant="text" size="small" onClick={addView}>
            {t('add-custom-view')}
          </Button>
        </Box>
        <Box mt={1}>
          {Object.values(state).map(({ title, id }) => (
            <ColumnConfigurationListItem
              key={id}
              id={id}
              title={title}
              onClick={setActive}
              active={active === id}
              onEdit={handleEdit}
            />
          ))}
        </Box>
      </Box>
      {editingState.type !== 'none' && (
        <Box mt={3}>
          <Typography variant="body1" ml={1}>
            {STATE_LABEL[editingState.type]} {t('column-view')}
          </Typography>
          <Box mt={1}>
            {editingState.type === 'add' ? (
              <EditColumnView
                fields={COLUMN_CONFIGS}
                title={editingState.title}
                id={editingState.id}
                onSave={onSave}
                onSaveAsNew={onCreateAsNew}
              />
            ) : (
              <EditColumnView
                fields={state[editingState.id].fields}
                title={state[editingState.id].title}
                id={editingState.id}
                onSave={onSave}
                onSaveAsNew={onCreateAsNew}
                onDelete={onDelete}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
