import { AthleteCareerActivityI, TableColumnConfigI } from 'types';
import { formatNumber } from 'utils/number-formatter';
import { Trans } from 'react-i18next';

export const ATHLETE_SUMMARY_ACTIVITY_COLUMNS_CONFIG: TableColumnConfigI<AthleteCareerActivityI>[] =
  [
    {
      title: '',
      dataKey: 'part',
    },
    {
      title: '',
      dataKey: 'teamLogo',
      renderValue: (data, options) =>
        data.teamLogo ? (
          <img src={data.teamLogo} alt="" className={options?.styles?.teamLogo} />
        ) : null,
    },
    {
      title: <Trans i18nKey="tgt" ns="hcx-card" />,
      dataKey: 'tgt',
      renderValue: (data) => formatNumber(data.tgt),
    },
    {
      title: <Trans i18nKey="rec" ns="hcx-card" />,
      dataKey: 'rec',
      renderValue: (data) => formatNumber(data.rec),
    },
    {
      title: <Trans i18nKey="yds" ns="hcx-card" />,
      dataKey: 'yds',
      renderValue: (data) => formatNumber(data.yds),
    },
    {
      title: <Trans i18nKey="td" ns="hcx-card" />,
      dataKey: 'td',
      renderValue: (data) => formatNumber(data.td),
    },
    {
      title: <Trans i18nKey="lng" ns="hcx-card" />,
      dataKey: 'lng',
      renderValue: (data) => formatNumber(data.lng),
    },
  ];
