import { useAtom } from 'jotai';
import type { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Button } from 'components/button';
import { HCXCardViewE } from 'enums';
import { AthleteI } from 'types';
import { openAthleteCardAtom, selectedAthleteIAtom } from 'store/athletes.store';

import styles from './athlete-bid_buy_sell-btn.module.scss';

export interface AthleteBidBuySellBtnPropsI {
  athlete: AthleteI;
  className?: string;
  disabled?: boolean;
  view?: HCXCardViewE.Bid | HCXCardViewE.Buy | HCXCardViewE.Sell;
}

export const AthleteBidBuySellBtn: FC<AthleteBidBuySellBtnPropsI> = ({
  athlete,
  className,
  children,
  disabled: outerDisabled,
  view = HCXCardViewE.Buy,
}) => {
  const [selectedAthlete] = useAtom(selectedAthleteIAtom);
  const [, openAthleteCard] = useAtom(openAthleteCardAtom);

  const disabled = athlete.id === selectedAthlete?.id || !!outerDisabled;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!disabled) {
      openAthleteCard({ athlete, view });
    }
  };

  return (
    <Button
      variant="contained"
      size="small"
      onClick={handleClick}
      disabled={disabled}
      className={classNames(styles.root, className)}
    >
      {children || (view === HCXCardViewE.Bid ? 'Bid' : 'Buy/Sell')}
    </Button>
  );
};
