import { config } from 'config';

export enum ApproveTargetE {
  IHO_CONTRACT,
  AUCTION_CONTRACT,
}

export const getApproveTarget = () => ({
  [ApproveTargetE.IHO_CONTRACT]: config.ihoContractAddress,
  [ApproveTargetE.AUCTION_CONTRACT]: config.auctionContractAddress,
});
