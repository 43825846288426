import { FC } from 'react';
import classNames from 'classnames';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomScrollbars } from 'components/custom-scrollbars';
import { settingsViewConfigs } from '../account-profile.constants';
import { SettingsViewConfigI } from '../account-profile.types';
import { useTranslation } from 'react-i18next';
import styles from './account-profile-settings-links.module.scss';

export interface AccountProfileSettingsLinksPropsI {
  onChangeConfigView: (config: SettingsViewConfigI) => void;
  selectedConfig?: SettingsViewConfigI | null;
}

const AccountProfileSettingsLinks: FC<AccountProfileSettingsLinksPropsI> = ({
  onChangeConfigView,
  selectedConfig,
}) => {
  const { t } = useTranslation('account-profile');

  const handleChangeConfigView = (view: SettingsViewConfigI) => () => {
    onChangeConfigView(view);
  };

  return (
    <>
      <label className={styles.label}>{t('settings')}</label>
      <CustomScrollbars className={styles.scrollbar}>
        {settingsViewConfigs.map((view) => {
          const { title } = view;
          return (
            <div
              key={title}
              className={classNames(styles.link, title === selectedConfig?.title && styles.active)}
              onClick={handleChangeConfigView(view)}
            >
              <ArrowBackIcon />
              {t(title)}
            </div>
          );
        })}
      </CustomScrollbars>
    </>
  );
};

export default AccountProfileSettingsLinks;
