import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { Select, SelectPropsT } from 'components/select';
import { MenuItem } from 'components/select/menu-item';

import { getAthleteOrderStatusTranslationKey } from 'utils/get-athlete-order-status-translation-key';
import { useOrdersListContext } from '../orders-list-context';

import { OrderStatusE } from 'enums';

const selectSx = {
  width: '125px',
  height: '26px',
  'margin-right': '10px',
};

const selectIconSx = {
  width: '14px',
  height: '14px',
};

const menuItems = [
  {
    value: 'all' as const,
    label: 'cancel-filters',
  },
  {
    value: OrderStatusE.Canceled,
    label: getAthleteOrderStatusTranslationKey(OrderStatusE.Canceled),
  },
  {
    value: OrderStatusE.Filled,
    label: getAthleteOrderStatusTranslationKey(OrderStatusE.Filled),
  },
  {
    value: OrderStatusE.Open,
    label: getAthleteOrderStatusTranslationKey(OrderStatusE.Open),
  },
  {
    value: OrderStatusE.PartialFill,
    label: getAthleteOrderStatusTranslationKey(OrderStatusE.PartialFill),
  },
];

const OrdersFilter: FC = () => {
  const { t } = useTranslation('common');
  const { orders, filter, setFilter } = useOrdersListContext();

  const handleChangeFilter: SelectPropsT<typeof filter>['onChange'] = useCallback(
    ({ target: { value } }) => {
      setFilter(value as typeof filter);
    },
    [setFilter]
  );

  if (!orders?.length) return null;

  return (
    <Select<typeof filter>
      sx={selectSx}
      variant="filled"
      renderValue={(value) => (
        <Box display="flex" alignItems="center">
          <FilterListIcon sx={selectIconSx} />
          <Typography fontSize="10px" ml="8px" fontWeight={600} lineHeight="14px">
            {t(value !== 'all' ? getAthleteOrderStatusTranslationKey(value) : 'all')}
          </Typography>
        </Box>
      )}
      value={filter}
      onChange={handleChangeFilter}
    >
      {menuItems.map(({ value, label }, idx) => (
        <MenuItem key={label} value={value} divider={!idx}>
          <Typography fontWeight={600} fontSize="10px" lineHeight="14px">
            {t(label)}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default OrdersFilter;
