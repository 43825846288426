import type { FC } from 'react';

interface PropsI {
  className?: string;
  color?: string;
}

export const MenuPinIcon: FC<PropsI> = ({ className, color = 'currentColor' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 9V3H12.75V1.5H5.25V3H6V9L4.5 10.5V12H8.4V16.5H9.6V12H13.5V10.5L12 9ZM6.6 10.5L7.5 9.6V3H10.5V9.6L11.4 10.5H6.6Z"
      fill={color}
    />
  </svg>
);
