import type { FC } from 'react';
import { useMemo } from 'react';
import { useAtom } from 'jotai';

import { Box } from '@mui/material';

import { AthleteCardTooltip } from 'components/athlete/athlete-card-tooltip';
import { GET_ATHLETE_LAST_PRICE_CONFIG } from 'components/athlete-trading-info';
import { UserAvatar } from 'components/user-avatar';

import { AthleteI } from 'types';

import { latestTradingStatsAtom } from 'store/trading-stats.store';

import styles from './widget-athlete-list-small-card.module.scss';

export const WidgetAthleteListSmallCard: FC<{ athlete: AthleteI }> = ({ athlete }) => {
  const [tradingStats] = useAtom(latestTradingStatsAtom);
  const { tradingPairId } = athlete.tradingInfo;

  const { progressPercentage } =
    tradingStats && tradingStats[tradingPairId]
      ? GET_ATHLETE_LAST_PRICE_CONFIG(tradingStats[tradingPairId])
      : { progressPercentage: 0 };

  const {
    bio: { name, photo },
    tradingInfo: { contractId },
  } = athlete;

  const style = useMemo(() => {
    if (typeof progressPercentage === 'number') {
      const lineProgress = Math.abs(progressPercentage);
      return {
        borderBottom: `2px solid ${
          progressPercentage < 0 ? 'var(--hcx-alert-down)' : 'var(--hcx-alert-up)'
        }`,
        width: `${lineProgress > 100 ? 100 : lineProgress}%`,
        marginTop: '3px',
      };
    }
    return {};
  }, [progressPercentage]);

  return (
    <AthleteCardTooltip athlete={athlete} placement="bottom-start">
      <Box className={styles.root}>
        <UserAvatar name={name} photo={photo} />
        <Box className={styles.contractWrapper}>
          <span className={styles.contract}>{contractId}</span>
          <div style={style} />
        </Box>
      </Box>
    </AthleteCardTooltip>
  );
};
