import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Button } from 'components/button';
import { IhoChoRegisterEntryDataI, IhoChoRegisterEntryI } from '../interfaces';
import { IHOStatusE } from '../enums';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';
import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';

import styles from './iho-cho-register-entry.module.scss';

const GRID_TITLES = [
  'Underwriter Status',
  'Scheduled Date',
  'Minimum $ per Token',
  'Total Tokens',
  'Minimum Total IHO Proceeds',
  'Status',
];

interface IhoChoRegisterEntryPropsI extends IhoChoRegisterEntryI {
  cancleOrder: () => void;
}
const IhoChoRegisterEntry: FC<IhoChoRegisterEntryPropsI> = ({
  title,
  filedDate,
  entryData,
  cancleOrder,
}) => {
  const [gridValues, setGridValues] = useState<IhoChoRegisterEntryDataI>();
  useEffect(() => {
    const { scheduledDate, minPerToken, totalTokens } = entryData;
    setGridValues({
      ...entryData,
      scheduledDate: dateFormatter(scheduledDate, DateFormatE.MM_DD_YYYY),
      minPerToken: formatToUSD(minPerToken as number, { minimumFractionDigits: 2 }),
      totalTokens: formatNumber(totalTokens as number),
    });
  }, [entryData]);
  return (
    <div className={styles.root}>
      <Typography variant="subtitle2" className={styles.title}>{`${title} - Filed ${dateFormatter(
        filedDate,
        DateFormatE.MMMM_DD_YYYY
      )}`}</Typography>
      {gridValues && (
        <div className={styles.content}>
          <div className={styles.gridContainer}>
            {Object.values(gridValues).map((value, index) => (
              <div key={value}>
                <Typography variant="body2" className={styles.gridItemTitle}>
                  {GRID_TITLES[index]}
                </Typography>
                <Typography variant="body1">{value}</Typography>
              </div>
            ))}
          </div>
          {entryData.status !== IHOStatusE.Success && (
            <Button
              variant="contained"
              size="small"
              className={styles.cancelButtonWrapper}
              onClick={cancleOrder}
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default IhoChoRegisterEntry;
