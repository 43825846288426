import React, { FC, useCallback } from 'react';
import { useState } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import styles from './menu-dropdown.module.scss';
import {
  SponsorUserStatus,
  useCancelSponsorUserInvitationMutation,
  useChangeSponsorUserStatusMutation,
  useResendSponsorUserInvitationMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { MessageAtom } from 'store/message-alert.store';
import { useMembersPermissionsList } from '../../sponsor-management.hooks';

export const MenuDropdown: FC<{
  status?: SponsorUserStatus;
  userId?: number;
}> = ({ status, userId }) => {
  const { refetchUserList } = useMembersPermissionsList();

  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLElement>();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { t } = useTranslation('sponsor-management');

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const [cancelSponsorUserInvitation] = useCancelSponsorUserInvitationMutation();

  const [resendSponsorUserInvitation] = useResendSponsorUserInvitationMutation();

  const [changeSponsorUserStatus] = useChangeSponsorUserStatusMutation();

  const [, setError] = useAtom(MessageAtom);

  const cancelInvite = async () => {
    await cancelSponsorUserInvitation({ variables: { userId: Number(userId) } });
    await refetchUserList();
    handleClose();
  };

  const resendInvite = () => {
    resendSponsorUserInvitation({ variables: { userId: Number(userId) } }).catch((err) =>
      setError({ message: err.message, type: 'error' })
    );
    handleClose();
  };

  const disableUser = async () => {
    await changeSponsorUserStatus({
      variables: { userId: Number(userId), status: SponsorUserStatus.Disabled },
    });
    await refetchUserList();
    handleClose();
  };

  const enableUser = async () => {
    await changeSponsorUserStatus({
      variables: { userId: Number(userId), status: SponsorUserStatus.Active },
    });
    await refetchUserList();
    handleClose();
  };

  const deleteUser = async () => {
    await changeSponsorUserStatus({
      variables: { userId: Number(userId), status: SponsorUserStatus.Deleted },
    });
    await refetchUserList();
    handleClose();
  };

  const isInvited = status === SponsorUserStatus.Invited;

  const isDisabled = status === SponsorUserStatus.Disabled;

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        className={styles.menuList}
      >
        {!isInvited ? (
          <Box>
            <MenuItem onClick={isDisabled ? enableUser : disableUser} className={styles.menuItem}>
              {isDisabled ? t('enable') : t('disable')}
            </MenuItem>
            <MenuItem onClick={deleteUser} className={styles.menuItem}>
              {t('delete')}
            </MenuItem>
          </Box>
        ) : (
          <Box>
            <MenuItem onClick={resendInvite} className={styles.menuItem}>
              {t('resend-invite')}
            </MenuItem>
            <MenuItem onClick={cancelInvite} className={styles.menuItem}>
              {t('cancel-invite')}
            </MenuItem>
          </Box>
        )}
      </Menu>
    </>
  );
};
