import { FC, useMemo } from 'react';
import classNames from 'classnames';

import { Avatar } from '@mui/material';

import { getAvatarProps } from './user-avatar.helpers';

import { useTalentImage } from 'hooks/useTalentImage';

import styles from './user-avatar.module.scss';

export interface UserAvatarPropsI {
  photo?: string;
  name?: string;
  size?: number;
  onClick?: () => void;
  className?: string;
}

const UserAvatar: FC<UserAvatarPropsI> = ({ photo, name = '', size, onClick, className }) => {
  const { img } = useTalentImage(photo ?? '');

  const avatarProps = useMemo(() => getAvatarProps(name, img, size), [name, img, size]);

  return (
    <Avatar
      {...avatarProps}
      className={classNames(className, !!onClick && styles.pointer)}
      onClick={onClick}
    />
  );
};

export default UserAvatar;
