import type { FC } from 'react';

const AthleteCardResultsLine: FC<{ textOnly?: boolean; className?: string }> = () => {
  // TODO: Uncomment when needed.

  // const text = (
  //   <>25 tokens @ $110.24&nbsp;&nbsp;&nbsp;+$1.35&nbsp;&nbsp;&nbsp;+1.25%&nbsp;&nbsp;&nbsp;$2,520</>
  // );
  return null;

  // TODO: Uncomment when needed. Depends on current IHO / CHO status.

  // !textOnly ? (
  //   <div className={classNames(styles.root, className)}>
  //     <div className={styles.iconWrapper}>
  //       <HcxSnapshotDonut />
  //     </div>
  //     <Typography component="span" className={styles.text}>
  //       {text}
  //     </Typography>
  //   </div>
  // ) : (
  //   <span className={className}>{text}</span>
  // );
};

export default AthleteCardResultsLine;
