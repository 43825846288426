import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import { Divider, Typography } from '@mui/material';

import { BodyTable, HeaderTable } from 'pages/trade-desk/portfolio-snapshot';

import { WidgetWrapper } from '../widget-wrapper';
import { PortfolioWidgetContext } from './context';

import styles from './portfolio-snapshot.module.scss';
import { WidgetNoItems } from '../widget-noItems';
import { HcxCircleSmallIcon } from 'components/icons/hcx-circle-small-icon';

export const PortfolioSnapshot: FC<{ onCloseWidget: () => void }> = ({ onCloseWidget }) => {
  const { t } = useTranslation('trade-desk');

  const noData = true; //TODO: remove with actual data-state of UserTradingPairsQuery or other

  if (!noData) {
    return (
      <WidgetNoItems
        text={<Typography variant="body2">{t('no-favorites-found')}</Typography>}
        icon={<HcxCircleSmallIcon sx={{ width: '60px', height: '60px' }} />}
      />
    );
  }

  return (
    <WidgetWrapper
      title={t('portfolio')}
      titleIcon={<DataSaverOffIcon />}
      onCloseWidget={onCloseWidget}
      context={PortfolioWidgetContext}
      additionalHeaderInfo={
        <>
          <HeaderTable />
          <Divider className={styles.divider} />
        </>
      }
      hideSizer
    >
      <BodyTable />
    </WidgetWrapper>
  );
};
