import { FC, useState } from 'react';

import { TabPanel } from 'components/tab-panel';

import { HCXCardTabs } from 'features/hcx-card/hcx-card-tabs';
import { HCXSummaryTabTabLabels, HCXSummaryTabTabs } from 'features/hcx-card/hcx-card-summary';
import HcxCardTabViewSpacing from 'features/hcx-card/hcx-card-tab-view-spacing';

export const News: FC = () => {
  const [tabIdx, setTabIdx] = useState(0);

  return (
    <>
      <HCXCardTabs
        currentTabIdx={tabIdx}
        onChangeTab={setTabIdx}
        tabLabels={HCXSummaryTabTabLabels}
      />
      <HcxCardTabViewSpacing resetScrollTrigger={tabIdx}>
        {HCXSummaryTabTabs.map(({ jsxKey, component: Component, keepMounted }, idx) => {
          return (
            <TabPanel key={jsxKey} value={tabIdx} index={idx} keepMounted={keepMounted}>
              {Component ? <Component setTab={setTabIdx} /> : null}
            </TabPanel>
          );
        })}
      </HcxCardTabViewSpacing>
    </>
  );
};
