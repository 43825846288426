import { useState } from 'react';
import { Box, Typography, CircularProgress, Link, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import { MicrosoftExcelIcon } from 'components/icons/microsoft-excel-icon';
import { AccordionSection } from 'components/accordion-section';
import { DottedAngleLineIcon } from 'components/icons/dotted-angle-line-icon';
import {
  useTalentApplicationFinancialModelsQuery,
  useUploadTalentApplicationFinancialModelMutation,
} from 'generated/graphql';
import { FileRow } from './file-row';
import { useAtom } from 'jotai';
import { MessageAtom } from 'store/message-alert.store';
import styles from './financial-model.module.scss';

export const FinancialModel = () => {
  const { t } = useTranslation('talent-application');
  const [expandAccordion, setExpandAccordion] = useState(true);
  const [, setMessage] = useAtom(MessageAtom);

  const { data: modelHistory, loading } = useTalentApplicationFinancialModelsQuery();

  const [uploadFinancialModel] = useUploadTalentApplicationFinancialModelMutation();

  const financialCurrent = modelHistory?.talentApplication.financialModels.current;
  const financialHistory = modelHistory?.talentApplication.financialModels.history;
  const templateFileUrl = modelHistory?.talentApplication.financialModels.templateUrl;

  const handleFileUpload = async (event: React.ChangeEvent) => {
    const fileList = (event.target as HTMLInputElement).files;
    if (!fileList) return;
    const file = fileList[0];

    (event.target as HTMLInputElement).value = '';

    try {
      const res = await uploadFinancialModel({
        variables: {
          file: file,
        },
        refetchQueries: ['TalentApplicationState', 'TalentApplicationFinancialModels'],
      });
      if (res.data?.uploadTalentApplicationFinancialModel === 'ok') {
        setMessage({ message: 'Successfully send', type: 'success' });
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <Box>
      <Typography variant="subtitle2" className={styles.description}>
        {t('now-its-time-to-build-a-financial-model')}
      </Typography>
      <Box display="flex" alignItems="center" mb="21px">
        <MicrosoftExcelIcon className={styles.excelIcon} />
        <Link href={templateFileUrl} target="_blank" rel="noreferrer">
          <Button variant="outlined" size="small" className={styles.button}>
            {t('download-template-model')}
          </Button>
        </Link>
      </Box>
      <AccordionSection
        expand={expandAccordion}
        handleChangeExpanded={() => setExpandAccordion((prev) => !prev)}
        summary={<Typography variant="body1">{t('financial-proforma-upload')}</Typography>}
        details={
          <Box>
            <Button variant="outlined" size="small" component="label">
              {t('financial-proforma-upload')}
              <input hidden type="file" accept=".xlsx,.xls" onChange={handleFileUpload} />
            </Button>
            {financialCurrent && (
              <Box>
                <FileRow
                  icon={<CheckCircleIcon fontSize="small" className={styles.checkboxColor} />}
                  url={financialCurrent.fileUrl}
                  financialProforma={t('current-financial-proforma')}
                  fileName={financialCurrent.fileName}
                  updatedAt={financialCurrent.createdAt}
                  createdBy={financialCurrent.createdBy}
                  className={styles.currentFileRow}
                />
                {financialHistory && (
                  <Box mt={3} ml={5}>
                    <Typography className={styles.secondaryText}>
                      {t('previously-uploaded')}
                    </Typography>
                  </Box>
                )}
                <Box ml={4}>
                  {financialHistory &&
                    financialHistory.map((row) => {
                      if (row === null) {
                        return null;
                      }
                      return (
                        <FileRow
                          key={row.createdAt}
                          icon={<DottedAngleLineIcon />}
                          url={row.fileUrl}
                          financialProforma={t('history-financial-proforma')}
                          fileName={row.fileName}
                          updatedAt={row.createdAt}
                          createdBy={row.createdBy}
                          className={styles.fileRow}
                        />
                      );
                    })}
                  {loading && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        }
      />
    </Box>
  );
};
