import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PasswordAuthDataI } from '../../auth';
import { LoggedOutAuthContextI } from '../../auth/auth-context';
import { useAuth } from '../../auth/use-auth';
import { ROUTE } from '../../routes/enums';
import { AuthFormTemplate } from './auth-form-template';
import { renderValidationResult } from './render-validation-result';

import { isDomainORG } from './utils';

import classNames from 'classnames';
import styles from './styles.module.scss';

export const SignUp: FC = () => {
  const auth = useAuth() as LoggedOutAuthContextI;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [shouldShowTermsError, setShouldShowTermsError] = useState(false);
  const onTermsClick = useCallback((_, checked) => {
    setTermsAccepted(checked);
    setShouldShowTermsError(!checked);
  }, []);
  const onSubmit = useCallback(
    (data: PasswordAuthDataI) => {
      if (!termsAccepted) {
        setShouldShowTermsError(true);
        return;
      }
      auth.signUp(data);
    },
    [auth, termsAccepted]
  );
  const { t } = useTranslation('auth');
  const publicDomain = isDomainORG();

  if (publicDomain) {
    return <Navigate to={ROUTE.SignIn} />;
  }

  if (auth.isLoggedIn) {
    return <Navigate to={ROUTE.TradeDesc} />;
  }

  return (
    <AuthFormTemplate
      onSubmit={onSubmit}
      onGoogleAuthClick={auth.logInViaGoogle}
      middlePart={
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Checkbox value={termsAccepted} onChange={onTermsClick} />
              <Typography className={styles.terms}>
                {t('i-agree-to-the-hcx')}&nbsp;
                {/* TODO: VOV: Change href url to proper one */}
                <a href="/#" className={styles.link}>
                  {t('terms-of-service')}
                </a>
              </Typography>
            </Box>
            {shouldShowTermsError && (
              <Box>
                {renderValidationResult({
                  valid: false,
                  errors: ['Please accept the terms to proceed'],
                })}
              </Box>
            )}
          </Box>
        </Box>
      }
      submitButtonText={t('create-account')}
      bottomPart={
        <Typography className={classNames(styles.textLine, styles.mt4)}>
          {t('already-have-an-account?')}&nbsp;
          <Link to={ROUTE.SignIn} className={styles.link}>
            {t('signin')}
          </Link>
        </Typography>
      }
    />
  );
};
