import type { FC } from 'react';
import { useMemo, useState } from 'react';

import {
  HCXFinancialCardListResizerContext,
  HCXFinancialCardListResizerContextI,
} from './hcx-financial-card-list-resizer.context';

export const HCXFinancialCardListResizerContextProvider: FC = ({ children }) => {
  const [divider, setDivider] = useState(1);

  const value: HCXFinancialCardListResizerContextI = useMemo(
    () => ({ divider, setDivider }),
    [divider]
  );

  return (
    <HCXFinancialCardListResizerContext.Provider value={value}>
      {children}
    </HCXFinancialCardListResizerContext.Provider>
  );
};
