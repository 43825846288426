const hostname = window.location.hostname.toLowerCase();
const devHostnames = ['dev', 'localhost'];
const isDev = devHostnames.some((host) => hostname.includes(host));

const {
  REACT_APP_GRAPHQL_API = `https://${isDev ? 'dev' : 'stage'}-api.hcx.us/graphql`,
  REACT_APP_GRAPHQL_SUBSCRIPTIONS_API = `wss://${isDev ? 'dev' : 'stage'}-api.hcx.us/live`,
  REACT_APP_ORDER_BOOK_WS_API = `wss://${isDev ? 'dev' : 'stage'}-api-order.hcx.us/orders`,
  REACT_APP_S3_API = 'https://hcx-dev-file-upload.s3.us-east-2.amazonaws.com',
  REACT_APP_AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_CLIENT_ID = '',
  REACT_APP_AUTH0_AUDIENCE = '',
  REACT_APP_CHAIN_ID = '80001',
  REACT_APP_STABLECOIN_ADDRESS = '0x2C1722Deab63d20A83fcaaeF0Cc45f6CC41048bc',
  REACT_APP_IHO_CONTRACT_ADDRESS = '0x66601B18847a0348673bf8692B89E4022ebCA568',
  REACT_APP_AUCTION_CONTRACT_ADDRESS = '0x4b770B427Ed73f87e131923A0D7A73D383DE4F1A',
  REACT_APP_ZEROX_API_BASE_URL = 'https://ropsten.api.0x.org',
  REACT_APP_ZEROX_VERIFYING_CONTRACT_ADDRESS = '0x0000000000000000000000000000000000000000',
  REACT_APP_ZEROX_EXCHANGE_PROXY_ADDRESS = '0x2A7D9784DD4671f4120a07060D8F89aaBaD09f39',
  //REACT_APP_USER_ID = '2',
} = process.env;

export const config = {
  auth0Domain: REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: REACT_APP_AUTH0_CLIENT_ID,
  auth0Audience: REACT_APP_AUTH0_AUDIENCE,
  chainId: Number(REACT_APP_CHAIN_ID),
  graphQlApi: REACT_APP_GRAPHQL_API,
  graphQlSubscriptionApi: REACT_APP_GRAPHQL_SUBSCRIPTIONS_API,
  orderBookWebSocket: REACT_APP_ORDER_BOOK_WS_API,
  s3Api: REACT_APP_S3_API,
  stableCoinAddress: REACT_APP_STABLECOIN_ADDRESS,
  ihoContractAddress: REACT_APP_IHO_CONTRACT_ADDRESS,
  auctionContractAddress: REACT_APP_AUCTION_CONTRACT_ADDRESS,
  zeroXApiBaseUrl: REACT_APP_ZEROX_API_BASE_URL,
  zeroXVerifyingContractAddress: REACT_APP_ZEROX_VERIFYING_CONTRACT_ADDRESS,
  zeroXExchangeProxyAddress: REACT_APP_ZEROX_EXCHANGE_PROXY_ADDRESS,
  //userId: Number(REACT_APP_USER_ID),
};
