import type { FC } from 'react';

import { TabPanel } from 'components/tab-panel';

import { HCXCardTabs } from '../hcx-card-tabs';
import HcxCardTabViewSpacing from '../hcx-card-tab-view-spacing';
import { useHCXCardCurrentTab, useTabLabelsWithOrdersQty } from '../hcx-card.hooks';
import { TABS, getHCXCardOfferingDefaultTabByView } from './hcx-card-offering.helpers';

const HCXCardOffering: FC = () => {
  const [tabIdx, setTabIdx] = useHCXCardCurrentTab(getHCXCardOfferingDefaultTabByView);
  const tabLabels = useTabLabelsWithOrdersQty(TABS, 2);

  return (
    <>
      <HCXCardTabs tabLabels={tabLabels} currentTabIdx={tabIdx} onChangeTab={setTabIdx} />
      <HcxCardTabViewSpacing resetScrollTrigger={tabIdx}>
        {TABS.map(({ component: Component }, idx) => {
          return (
            <TabPanel
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              value={tabIdx}
              index={idx}
            >
              {Component ? <Component /> : null}
            </TabPanel>
          );
        })}
      </HcxCardTabViewSpacing>
    </>
  );
};

export default HCXCardOffering;
