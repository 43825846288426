import type { FC } from 'react';

import { Box, Typography, Table, TableHead, TableBody } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import { TableHeadCell, TableRow, TableHeadRow, TableCell } from 'components/table';
import { UserAvatar } from 'components/user-avatar';

import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';

import { TradingPair, TradingPairStats } from 'generated/graphql';
import { TradingPairWithBalanceI } from '../types';

import styles from './portfolio-table.module.scss';

interface TableHeadingI {
  title: string;
  key: keyof TradingPair | keyof TradingPairStats | CustomStringLiteralsT;
}

type CustomStringLiteralsT = 'quantity' | 'currentValue';

const TABLE_HEADINGS: Array<TableHeadingI> = [
  { title: 'Name', key: 'name' },
  { title: 'Last Price', key: 'lastPrice' },
  { title: 'Quantity', key: 'quantity' },
  { title: 'Current Value', key: 'currentValue' },
  { title: 'Market Cap', key: 'marketCap' },
];

const getDiffValueClassName = (value: number) => (value > 0 ? styles.green : styles.red);

export interface PortfolioTablePropsI {
  userTradingPairs: TradingPairWithBalanceI[];
}

export const PortfolioTable: FC<PortfolioTablePropsI> = ({ userTradingPairs }) => (
  <Table>
    <TableHead>
      <TableHeadRow>
        {TABLE_HEADINGS.map((heading) => (
          <TableHeadCell key={heading.key} className={styles.headCell}>
            <Box className={styles.headingCellWrapper}>
              <Typography variant="caption" display="block">
                {heading.title}
              </Typography>
              <ArrowDropDown className={styles.headingArrow} />
            </Box>
          </TableHeadCell>
        ))}
      </TableHeadRow>
    </TableHead>
    <TableBody>
      {userTradingPairs.map((userTradingPair) => (
        <TableRow key={userTradingPair.id} className={styles.clickableRow}>
          <TableCell>
            <Box display="flex">
              <UserAvatar
                photo={userTradingPair.athlete.imageUrl}
                name={userTradingPair.athlete.fullName}
              />
              <Box ml={2.5}>
                <Typography variant="subtitle2" whiteSpace="nowrap">
                  {userTradingPair.athlete.fullName}
                </Typography>
                <Typography variant="body2" color="var(--hcx-font-secondary)">
                  NFL
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box>
                <Typography>{formatToUSD(userTradingPair.stats?.lastPrice ?? 0)}</Typography>
                <Typography
                  textAlign="right"
                  variant="body2"
                  className={getDiffValueClassName(userTradingPair.stats?.change24Amount ?? 0)}
                >
                  {formatNumber(userTradingPair.stats?.change24Amount ?? 0, {
                    signDisplay: 'exceptZero',
                  })}
                  %
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box>
              <Typography>{userTradingPair.quantity}</Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Box>
              <Typography>{formatToUSD(userTradingPair.currentValue)}</Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Box>
              <Typography>
                {formatNumber(
                  userTradingPair.stats?.marketCap
                    ? userTradingPair.stats.marketCap / Math.pow(10, 2)
                    : 0,
                  { compact: true }
                )}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
