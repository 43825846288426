import { ChangeEventHandler, FC, useState, useRef } from 'react';
import { Badge, IconButton } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import { ProfileAvatar } from 'components/profile-avatar';
import { toBase64 } from 'utils/toBase64';

import { badgeAnchorOrigin } from './account-profile-photo-selector.helpers';
import styles from './account-profile-photo-selector.module.scss';

export interface AccountProfilePhotoSelectorPropsI {
  onFileSelected?: (file: File, base64: string) => void;
}

const CLASSES = {
  root: styles.root,
};

const AccountProfilePhotoSelector: FC<AccountProfilePhotoSelectorPropsI> = ({ onFileSelected }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState('');

  const selectPhoto = () => {
    inputRef.current?.click();
  };

  const onAvatarChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files?.[0];

    if (!file || !file.type.startsWith('image/')) {
      return;
    }

    try {
      const base64 = await toBase64(file);
      setPreview(base64);
      onFileSelected?.(file, base64);
    } catch {
      return;
    }
  };

  return (
    <Badge
      classes={CLASSES}
      overlap="circular"
      anchorOrigin={badgeAnchorOrigin}
      badgeContent={
        <label htmlFor="account-profile-photo" className={styles.selectPhoto}>
          <input
            ref={inputRef}
            accept="image/*"
            id="account-profile-photo"
            type="file"
            onChange={onAvatarChange}
          />
          <IconButton size="small" onClick={selectPhoto}>
            <CameraAltOutlinedIcon />
          </IconButton>
        </label>
      }
    >
      <ProfileAvatar photo={preview} size={84} />
    </Badge>
  );
};

export default AccountProfilePhotoSelector;
