import { FC, useRef } from 'react';
import { Slider, SliderProps } from '@mui/material';
import { useHCXFinancialCardListResizerContext } from './hcx-financial-card-list-resizer.context';
import styles from './hcx-financial-card-list-resizer.module.scss';

const classes = {
  root: styles.root,
};

const getCardWidthDividerValue = (value: number): number => {
  if (!value) return 0;
  if (value < 0.25) return 0.25;
  if (value < 0.5) return 0.5;
  if (value < 0.75) return 0.75;
  return 1;
};

export const HCXFinancialCardListResizer: FC<{ className?: string }> = ({ className }) => {
  const { divider, setDivider } = useHCXFinancialCardListResizerContext();
  // INFO: need to prevent errors about changing uncontrolled state to controlled
  const defaultDividerValue = useRef(divider);

  const handleChange: SliderProps['onChange'] = (_, newValue) => {
    setDivider(getCardWidthDividerValue(newValue as number));
  };

  return (
    <Slider
      aria-label="card-width-multiplier"
      defaultValue={defaultDividerValue.current}
      step={0.05}
      min={0}
      max={1}
      onChange={handleChange}
      size="small"
      classes={classes}
      className={className}
    />
  );
};
