import { useAtom } from 'jotai';
import { FC } from 'react';

import { HCXCardOrders } from 'features/hcx-card/hcx-card-orders';
import { AthleteOrders } from 'components/athlete/athlete-orders';
import { AthleteBidStatusE } from 'enums';
import { selectedAthleteIAtom } from 'store/athletes.store';

export const HCXCardOfferingOrders: FC = () => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  if (!athlete?.id) {
    return null;
  }

  const OrdersComponent =
    athlete.tradingInfo.bidStatus === AthleteBidStatusE.Offering ? HCXCardOrders : AthleteOrders;

  return <OrdersComponent athleteId={athlete.id} />;
};
