import { FC, useMemo, useState } from 'react';
import { TalentPortalTabsContext, TalentPortalTabsContextI } from './talent-portal-tabs.context';

const TalentPortalTabsContextProvider: FC = ({ children }) => {
  const [tab, setTab] = useState(0);
  const value: TalentPortalTabsContextI = useMemo(() => ({ tab, setTab }), [tab]);
  return (
    <TalentPortalTabsContext.Provider value={value}>{children}</TalentPortalTabsContext.Provider>
  );
};

export default TalentPortalTabsContextProvider;
