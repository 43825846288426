import { useAtom } from 'jotai';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider } from '@mui/material';

import { InfoTable } from 'features/hcx-card/hcx-card-summary/hcx-card-summary-info/info-table';
import { AthleteIcoStatusI, AthleteOfferingTermsI } from 'types';
import { selectedAthleteIAtom } from 'store/athletes.store';

import {
  ATHLETE_ICO_STATUS_COLUMNS_CONFIG,
  ATHLETE_OFFERING_TERMS_COLUMNS_CONFIG,
} from './hcx-card-offering-terms.helpers';
import styles from './hcx-card-offering-terms.module.scss';

export const HCXCardOfferingTerms: FC = () => {
  const [athlete] = useAtom(selectedAthleteIAtom);
  const { t } = useTranslation('hcx-card');

  if (!athlete?.id) {
    return null;
  }

  return (
    <>
      <Box className={styles.infoTable}>
        <InfoTable<AthleteIcoStatusI>
          title={t('ico-status')}
          data={athlete.tradingInfo.icoStatus}
          columns={ATHLETE_ICO_STATUS_COLUMNS_CONFIG}
          className={styles.table}
        />
      </Box>
      <Divider />
      <Box className={styles.infoTable}>
        <InfoTable<AthleteOfferingTermsI>
          title={t('career')}
          data={athlete.tradingInfo.offeringTerms}
          columns={ATHLETE_OFFERING_TERMS_COLUMNS_CONFIG}
          className={styles.table}
        />
      </Box>
    </>
  );
};
