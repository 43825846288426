import { FC, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { HCXCardTabs } from 'features/hcx-card/hcx-card-tabs';

import styles from './order-book.module.scss';
import { TabPanel } from 'components/tab-panel';
import { OrderBookView, OrderBookOrders, OrderBookICO } from './components';
import { CustomScrollbars } from 'components/custom-scrollbars';
import { useTranslation } from 'react-i18next';

const TABS: { label: string; component: FC<{ tradingPairId: string }> | null }[] = [
  { label: 'orderbook', component: OrderBookView },
  { label: 'orders', component: OrderBookOrders },
  { label: 'ico', component: OrderBookICO },
];

interface OrderBookPropsI {
  tradingPairId: string;
}

export const OrderBook: FC<OrderBookPropsI> = ({ tradingPairId }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const { t } = useTranslation('overview');
  const tabLabels = useMemo(() => TABS.map(({ label }) => t(label)), [t]);

  return (
    <Box className={styles.container}>
      <HCXCardTabs tabLabels={tabLabels} currentTabIdx={tabIdx} onChangeTab={setTabIdx} />
      <CustomScrollbars className={styles.scrollWrapper}>
        <Box className={styles.tabViewWrapper}>
          {TABS.map(({ label, component: Component }, idx) => {
            return (
              <TabPanel key={label} value={tabIdx} index={idx}>
                {Component ? <Component tradingPairId={tradingPairId} /> : null}
              </TabPanel>
            );
          })}
        </Box>
      </CustomScrollbars>
    </Box>
  );
};
