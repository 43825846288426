import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Athlete, useAthletesQuery } from 'generated/graphql';
import { AthleteI } from 'types';
import {
  closeAthleteCardAtom,
  openAthleteCardAtom,
  selectedAthleteIAtom,
} from 'store/athletes.store';
import { convertAthleteToAthleteI } from 'utils/convertAthleteToAthleteI';

export const useOverviewPage = (): {
  loading: boolean;
  loadedId?: AthleteI['id'];
  contractId: string;
  tradingPairId: string;
} => {
  const { id } = useParams();
  const idFromParams = Number.isInteger(+(id || 0)) ? +(id || 0) : 0;

  const [selectedAthlete] = useAtom(selectedAthleteIAtom);
  const [, openAthleteCard] = useAtom(openAthleteCardAtom);
  const [, closeAthleteCard] = useAtom(closeAthleteCardAtom);

  const navigate = useNavigate();

  const { data, loading, error } = useAthletesQuery({ variables: { input: { id, limit: 1 } } });

  const athlete = useMemo(() => {
    if (!loading && !error && data && data.athletes.data[0]) {
      // TODO: VOV: Remove casting. Need to find proper way without casting.
      return convertAthleteToAthleteI(data.athletes.data[0] as Athlete);
    }
    return null;
  }, [data, error, loading]);

  useEffect(
    () => () => {
      closeAthleteCard();
    },
    [closeAthleteCard]
  );

  useEffect(() => {
    if (!idFromParams) {
      navigate('/404', { replace: true });
    }
  }, [idFromParams, navigate]);

  useEffect(() => {
    if (athlete && athlete.id !== selectedAthlete?.id) {
      openAthleteCard({ athlete });
    }
  }, [athlete, selectedAthlete, openAthleteCard]);

  return {
    loading,
    loadedId: athlete?.id,
    contractId: athlete?.tradingInfo?.contractId || '',
    tradingPairId: athlete?.tradingInfo?.tradingPairId || '',
  };
};
