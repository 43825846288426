import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCloseAccountProfile } from 'apolloClient/hooks';
import { IconButton } from '@mui/material';
import { HideIcon } from 'components/icons/hide-icon';
import styles from './account-profile-header.module.scss';

const AccountProfileHeader: FC = () => {
  const { t } = useTranslation('account-profile');
  const closeAccountProfile = useCloseAccountProfile();
  return (
    <div className={styles.root}>
      <span className={styles.title}>{t('account-profile')}</span>
      <IconButton onClick={closeAccountProfile}>
        <HideIcon />
      </IconButton>
    </div>
  );
};

export default AccountProfileHeader;
