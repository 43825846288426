import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Box, IconButton, TablePagination } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import { QueryPagingI } from 'types';

import { Pagination } from 'generated/graphql';

import styles from './paginator.module.scss';
import classNames from 'classnames';

const defaultRowsPerPageOptions = [10, 20, 50];

type PaginatorPropsT = {
  pagination: Pagination;
  className?: string;
  handlePaginationChange: (pagination: QueryPagingI) => void;
  rowsPerPageOptions?: (number | { value: number; label: string })[];
  refreshList?: () => void;
};

export const Paginator: FC<PaginatorPropsT> = ({
  pagination: { limit, total, offset },
  handlePaginationChange,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  refreshList,
  className,
}) => {
  const currentPage = useMemo(() => Math.floor(offset / limit), [limit, offset]);
  const { t } = useTranslation('common');

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      handlePaginationChange({
        limit,
        offset: page * limit,
      });
    },
    [handlePaginationChange, limit]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handlePaginationChange({
        limit: parseInt(event.target.value, 10),
        offset: 0,
      });
    },
    [handlePaginationChange]
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <TablePagination
        component="span"
        count={total}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          root: classNames(className, { [styles.refreshRow]: refreshList }),
          toolbar: classNames(styles.toolbar, { [styles.padding]: refreshList }),
          spacer: styles.spacer,
          actions: styles.actions,
          select: styles.select,
          selectIcon: styles.selectIcon,
        }}
        labelRowsPerPage={`${t('rows-per-page')}:`}
      />
      {refreshList && (
        <IconButton onClick={refreshList} className={styles.refreshIcon}>
          <RefreshIcon fontSize="medium" />
        </IconButton>
      )}
    </Box>
  );
};
