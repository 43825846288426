import { atom } from 'jotai';
import {
  OrderDirection,
  PromotedTalent,
  TalentApplicationFinancialModels,
  TalentApplicationProfile,
  TalentApplicationState,
  TalentSponsorOrderType,
} from 'generated/graphql';
import { FormTypeE } from 'enums/forms';
import { atomWithReset } from 'jotai/utils';

export interface TalentApplicationProfileI extends TalentApplicationProfile {
  formType: FormTypeE.TalentApplicationProfile;
}

export const talentPanelView = atom<boolean>(false);

export const featuredTalents = atom<Array<PromotedTalent> | null>(null);

export const talentProfileErrorForm = {
  basicInformation: {
    firstName: { isTouched: undefined, errMessage: undefined },
    middleName: { isTouched: undefined, errMessage: undefined },
    lastName: { isTouched: undefined, errMessage: undefined },
    streetAddress: { isTouched: undefined, errMessage: undefined },
    apartment: { isTouched: undefined, errMessage: undefined },
    city: { isTouched: undefined, errMessage: undefined },
    state: { isTouched: undefined, errMessage: undefined },
    zip: { isTouched: undefined, errMessage: undefined },
    country: { isTouched: undefined, errMessage: undefined },
    emailAddress: { isTouched: undefined, errMessage: undefined },
    dateOfBirth: { isTouched: undefined, errMessage: undefined },
    sex: { isTouched: undefined, errMessage: undefined },
    nationality: { isTouched: undefined, errMessage: undefined },
    maritalStatus: { isTouched: undefined, errMessage: undefined },
    ssn: { isTouched: undefined, errMessage: undefined },
    placeOfBirthCity: { isTouched: undefined, errMessage: undefined },
    placeOfBirthState: { isTouched: undefined, errMessage: undefined },
    placeOfBirthCountry: { isTouched: undefined, errMessage: undefined },
    passportPhotoUrl: { isTouched: undefined, errMessage: undefined },
    profilePictureUrl: { isTouched: undefined, errMessage: undefined },
    resumeUrl: { isTouched: undefined, errMessage: undefined },
    resumeFileName: { isTouched: undefined, errMessage: undefined },
    linkedinProfileUrl: { isTouched: undefined, errMessage: undefined },
    description: { isTouched: undefined, errMessage: undefined },
    backgroundCheckAuthorization: { isTouched: undefined, errMessage: undefined },
    condition: { isTouched: undefined, errMessage: undefined },
  },
  emergencyContacts: [
    ...Array(2)
      .fill(null)
      .map(() => ({
        firstName: { isTouched: undefined, errMessage: undefined },
        lastName: { isTouched: undefined, errMessage: undefined },
        emailAddress: { isTouched: undefined, errMessage: undefined },
        phone: { isTouched: undefined, errMessage: undefined },
        relationship: { isTouched: undefined, errMessage: undefined },
      })),
  ],
  references: [
    ...Array(5)
      .fill(null)
      .map(() => ({
        firstName: { isTouched: undefined, errMessage: undefined },
        lastName: { isTouched: undefined, errMessage: undefined },
        emailAddress: { isTouched: undefined, errMessage: undefined },
        phone: { isTouched: undefined, errMessage: undefined },
      })),
  ],
};

export const talentProfileFormValidationAtom = atomWithReset(talentProfileErrorForm);

export const talentSponsorsListOrderType = atom<TalentSponsorOrderType>(
  TalentSponsorOrderType.Alphabetical
);

export const talentSponsorsListOrderDirection = atom<OrderDirection>(OrderDirection.Asc);

export const talentSponsorsListFilter = atom('');

export const TalentApplicationFinancialModelsAtom = atom<TalentApplicationFinancialModels | null>(
  null
);

export const talentActivityView = atom<boolean>(false);

export const talentApplicationState = atom<TalentApplicationState | null>(null);
