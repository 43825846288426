import { useAtom } from 'jotai';
import { queryPagingParameters, sortMembersPermissions } from 'store/sponsor-management.store';
import { useCallback } from 'react';
import { useSponsorUserListQuery } from 'generated/graphql';

export const useMembersPermissionsList = () => {
  const [sort, setSort] = useAtom(sortMembersPermissions);
  const [queryPaging, setQueryPaging] = useAtom(queryPagingParameters);

  const handlePaginationChange = useCallback(
    (newQueryPaging) => setQueryPaging(newQueryPaging),
    [setQueryPaging]
  );

  const order = `${sort.field_name} ${sort.direction ? 'asc' : 'desc'}`;

  const { limit, offset } = queryPaging;

  const {
    error,
    data: userList,
    refetch,
  } = useSponsorUserListQuery({
    variables: {
      offset,
      limit,
      order,
    },
  });

  const setSortDirection = (newField_name: string) => {
    if (newField_name === 'phone') {
      return;
    }
    if (newField_name === '') {
      return;
    }
    setSort((prev) => ({ field_name: newField_name, direction: !prev.direction }));
  };

  return {
    userList,
    refetchUserList: refetch,
    error,
    sort,
    setSortDirection,
    handlePaginationChange,
    queryPaging,
  };
};
