import { useRef } from 'react';
import {
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
  Bar,
  IDatafeedChartApi,
} from 'charting_library/charting_library';
import { parseNewBar } from './helpers';
import { apolloClient } from 'apolloClient';
import {
  LatestTradingPairCandleDocument,
  LatestTradingPairCandleSubscription,
  LatestTradingPairCandleSubscriptionVariables,
} from 'generated/graphql';

import {
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
} from 'utils/number-formatter';

export type SubscribeOnStreamT = (
  ...props: [...Parameters<IDatafeedChartApi['subscribeBars']>, Bar]
) => void;

export const useStreaming = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subscriptionObserver = useRef<any>(null);
  const lastBar = useRef<Bar | null>(null);
  const subscribeOnStream: SubscribeOnStreamT = useRef(
    (
      symbolInfo: LibrarySymbolInfo,
      resolution: ResolutionString,
      onRealtimeCallback: SubscribeBarsCallback,
      subscribeUID: string,
      onResetCacheNeededCallback: () => void,
      lastDailyBar: Bar
    ) => {
      lastBar.current = lastDailyBar;
      const subscription = apolloClient
        .subscribe<
          LatestTradingPairCandleSubscription,
          LatestTradingPairCandleSubscriptionVariables
        >({
          query: LatestTradingPairCandleDocument,
          variables: {
            tradingPairId: symbolInfo.unit_id as string,
            resolution: 5,
          },
        })
        .subscribe({
          next(response) {
            const newBar = parseNewBar(response.data?.latestTradingPairCandle);

            if (newBar) {
              newBar.volume = newBar.volume
                ? normalizeDecimals(newBar.volume, NormalizationDecimalsE.Six)[
                    NormalizationLogicE.NonDecimal
                  ]
                : 0;
              lastBar.current = newBar;
              onRealtimeCallback(newBar);
            }
          },
          error(err) {
            console.error('err', err);
          },
        });
      subscriptionObserver.current = subscription;
    }
  ).current;

  const unsubscribeFromStream: IDatafeedChartApi['unsubscribeBars'] = useRef(() => {
    if (subscriptionObserver.current) {
      subscriptionObserver.current.unsubscribe();
    }
  }).current;

  return { subscribeOnStream, unsubscribeFromStream };
};
