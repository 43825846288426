import { AccountProfileSecurity } from './account-profile-security';
import { SettingsViewConfigI } from './account-profile.types';

export const settingsViewConfigs: SettingsViewConfigI[] = [
  { title: 'security', component: AccountProfileSecurity },
  { title: 'identity-verification', component: null, info: 'Info for identity verification' },
  { title: 'fees', component: null, info: 'Info for fees' },
  { title: 'referrals', component: null },
  { title: 'api', contentTitle: 'api-getting-started', component: null },
  { title: 'leaderboard', component: null },
  { title: 'display', component: null },
  { title: 'social-media', component: null },
];
