import { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const TableColumnsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <rect x="12" y="4" width="2" height="10" />
    <rect x="8" y="4" width="2" height="10" />
    <rect x="4" y="4" width="2" height="10" />
  </SvgIcon>
);
