import { FC } from 'react';

interface PropsI {
  className?: string;
}

const Icon404: FC<PropsI> = ({ className }) => {
  return (
    <svg
      className={className}
      width="181"
      height="79"
      viewBox="0 0 181 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.21 77V59.29H0V49.83C3.00667 44.6966 5.75667 39.5266 8.25 34.32C10.7433 29.1133 13.0167 23.6866 15.07 18.04C17.1233 12.32 18.8467 6.37997 20.24 0.219971H32.23C31.2767 4.17997 30.0667 8.24997 28.6 12.43C27.2067 16.5366 25.63 20.57 23.87 24.53C22.1833 28.49 20.4967 32.1566 18.81 35.53C17.1233 38.9033 15.5467 41.8366 14.08 44.33C12.6133 46.8233 11.44 48.6566 10.56 49.83H34.21V28.16C34.7233 26.9133 35.2367 25.5933 35.75 24.2C36.3367 22.7333 36.8867 21.2666 37.4 19.8C37.9133 18.26 38.39 16.7933 38.83 15.4C39.27 14.0066 39.6733 12.6866 40.04 11.44H45.65V49.83H55.33V59.29H45.65V77H34.21Z"
        fill="#66D0FE"
      />
      <path
        d="M90.5747 78.32C86.5414 78.32 82.8747 77.7333 79.5747 76.56C76.348 75.3133 73.598 73.2233 71.3247 70.29C69.1247 67.3566 67.4014 63.3966 66.1547 58.41C64.9814 53.35 64.3947 46.97 64.3947 39.27C64.3947 31.57 64.9814 25.2266 66.1547 20.24C67.4014 15.18 69.1247 11.1833 71.3247 8.24997C73.598 5.31664 76.348 3.2633 79.5747 2.08997C82.8747 0.843302 86.5414 0.219971 90.5747 0.219971C94.6814 0.219971 98.348 0.843302 101.575 2.08997C104.801 3.2633 107.515 5.31664 109.715 8.24997C111.988 11.1833 113.711 15.18 114.885 20.24C116.131 25.2266 116.755 31.57 116.755 39.27C116.755 46.97 116.131 53.35 114.885 58.41C113.711 63.3966 111.988 67.3566 109.715 70.29C107.515 73.2233 104.801 75.3133 101.575 76.56C98.348 77.7333 94.6814 78.32 90.5747 78.32ZM90.5747 68.86C94.4613 68.86 97.3947 67.87 99.3747 65.89C101.428 63.8366 102.858 60.83 103.665 56.87C104.471 52.91 104.875 47.9966 104.875 42.13V36.74C104.875 30.7266 104.471 25.74 103.665 21.78C102.858 17.7466 101.428 14.74 99.3747 12.76C97.3947 10.7066 94.4613 9.67997 90.5747 9.67997C86.7614 9.67997 83.828 10.7066 81.7747 12.76C79.7214 14.74 78.2914 17.71 77.4847 21.67C76.678 25.63 76.2747 30.58 76.2747 36.52V41.91C76.2747 47.7766 76.678 52.7266 77.4847 56.76C78.2914 60.7933 79.7214 63.8366 81.7747 65.89C83.828 67.87 86.7614 68.86 90.5747 68.86Z"
        fill="#66D0FE"
      />
      <path
        d="M159.679 77V59.29H125.469V49.83C128.475 44.6966 131.225 39.5266 133.719 34.32C136.212 29.1133 138.485 23.6866 140.539 18.04C142.592 12.32 144.315 6.37997 145.709 0.219971H157.699C156.745 4.17997 155.535 8.24997 154.069 12.43C152.675 16.5366 151.099 20.57 149.339 24.53C147.652 28.49 145.965 32.1566 144.279 35.53C142.592 38.9033 141.015 41.8366 139.549 44.33C138.082 46.8233 136.909 48.6566 136.029 49.83H159.679V28.16C160.192 26.9133 160.705 25.5933 161.219 24.2C161.805 22.7333 162.355 21.2666 162.869 19.8C163.382 18.26 163.859 16.7933 164.299 15.4C164.739 14.0066 165.142 12.6866 165.509 11.44H171.119V49.83H180.799V59.29H171.119V77H159.679Z"
        fill="#66D0FE"
      />
    </svg>
  );
};

export default Icon404;
