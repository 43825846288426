import { useContext, useEffect } from 'react';

import { ResizeContext } from './context';

export const useResizableCell = (id: string, element?: HTMLElement | null) => {
  const { sizes, registerElement } = useContext(ResizeContext);

  useEffect(() => {
    if (element) {
      registerElement(id, element);
    }
  }, [element, id, registerElement]);

  return {
    size: sizes[id],
  };
};
