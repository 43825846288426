import { HcxProfile } from './tab-hcx-profile';
import { ProfessionForm } from './tab-profession';
import { TabSponsor } from './tab-sponsor';
import { FinancialModel } from './tab-financial-model';
import { OfferingParameters } from './tab-offering-parameters';
import { TabLegal } from './tab-legal';
import { TabLaunch } from './tab-launch';

export interface TabI {
  name: string;
  component?: () => JSX.Element;
  filled: string[];
}

export const TABS_LIST: TabI[] = [
  {
    name: 'hcx-profile',
    component: HcxProfile,
    filled: [
      'resume',
      'references',
      'verification',
      'personalProfile',
      'emergencyContact',
      'authorizationCheck',
    ],
  },
  {
    name: 'profession',
    component: ProfessionForm,
    filled: ['profession'],
  },
  {
    name: 'sponsor',
    component: TabSponsor,
    filled: ['sponsors'],
  },
  {
    name: 'financial-model',
    component: FinancialModel,
    filled: ['financialModels'],
  },
  {
    name: 'offering-parameters',
    component: OfferingParameters,
    filled: ['offeringParameters'],
  },
  {
    name: 'legal',
    component: TabLegal,
    filled: ['legalClauses'],
  },
  {
    name: 'launch',
    component: TabLaunch,
    filled: ['launch'],
  },
];
