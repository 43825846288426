import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { allRoutesConfig } from 'routes/routes-config';

export const useCurrentRouteConfig = () => {
  const { pathname } = useLocation();

  return useMemo(
    () => allRoutesConfig.find(({ path }) => matchPath({ path }, pathname)),
    [pathname]
  );
};
