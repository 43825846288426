export enum OrderTypeE {
  Limit = 'Limit',
  Market = 'Market',
  ICOBid = 'ICO Bid',
  IHOBid = 'IHO Bid',
}

export enum OrderTradingTypeE {
  Buy,
  Sell,
}

export enum OrderStatusE {
  Open = 'Open',
  Canceled = 'Canceled',
  Filled = 'Filled',
  PartialFill = 'Partial Fill',
}
