import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import styles from './wallet-tab.module.scss';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { TableCell, TableHeadCell, TableHeadRow, TableRow } from 'components/table';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { useEffect, useMemo, useState } from 'react';
import {
  getConnectedWallets,
  emitter,
  getDefaultWallet,
  saveDefaultWallet,
  deleteConnectedWallet,
} from 'blockchain-api/ewallets-api/connected-wallets-storage';
import { Avatar, FormControlLabel } from '@mui/material';
import { assets } from 'features/wallet-card/assets';
import { formatToUSD } from 'utils/currency-formatter';
import { WalletE } from 'blockchain-api/ewallets-api';
import { Button } from 'components/button';
import Checkbox from '@mui/material/Checkbox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { getBalance } from 'blockchain-api/ewallets-api/get-balance';
import classNames from 'classnames';
import { capitalize } from 'utils/capitalize';

const TABLE_HEAD_CELLS: string[] = ['type', 'balance', 'available-balance', 'usd-value'];

export const WalletTab = () => {
  const { t } = useTranslation('wallet-page');
  const [wallets, setWallets] = useState(() => getConnectedWallets());
  useEffect(() => {
    emitter.on('change', setWallets);
    return () => {
      emitter.off('change', setWallets);
    };
  }, []);

  const [defaultWallet, setDefaultWallet] = useState(() => getDefaultWallet() ?? wallets[0]);
  const changeDefaultWallet = (wallet: WalletE) => {
    setDefaultWallet(wallet);
    saveDefaultWallet(wallet);
  };

  const [balances, setBalances] = useState<Partial<Record<WalletE, number>>>({});

  useEffect(() => {
    wallets.forEach((wallet) => {
      getBalance(wallet).then((value) => {
        setBalances((current) => ({ ...current, [wallet]: value }));
      });
    });
  }, [wallets]);

  const totalBalance = useMemo(
    () => Object.values(balances).reduce((acc, cur) => acc + cur, 0),
    [balances]
  );

  return (
    <div>
      <div className={styles.titleRow}>
        <Typography variant="subtitle1">{t('total-net-usd-value')}</Typography>
        <Typography className={styles.totalValue}>{formatToUSD(totalBalance)}</Typography>
      </div>
      <Table>
        <TableHead>
          <TableHeadRow>
            {TABLE_HEAD_CELLS.map((label) => (
              <TableHeadCell key={label}>
                <Typography variant="caption" display="block">
                  {t(label)}
                  <ArrowDropDown className={styles.tableTitleArrow} />
                </Typography>
              </TableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {wallets.map((wallet) => (
            <TableRow key={wallet} className={styles.row}>
              <TableCell className={styles.cell}>
                <div className={styles.firstCell}>
                  <Avatar src={assets[wallet]} className={styles.avatar} />
                  <Typography variant="body1">{capitalize(wallet)}</Typography>
                </div>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography variant="body1">{formatToUSD(balances[wallet] ?? 0)}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography variant="body1">{formatToUSD(0)}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography variant="body1">{formatToUSD(0)}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <div className={styles.actionsCell}>
                  <Button variant="contained" size="small">
                    {t('add-funds')}
                  </Button>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={defaultWallet === wallet}
                        onChange={() => changeDefaultWallet(wallet)}
                      />
                    }
                    className={classNames(
                      defaultWallet !== wallet && styles.defaultCBFormControlDisabled
                    )}
                    label={t('default')}
                  />
                  <IconButton onClick={() => deleteConnectedWallet(wallet)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
