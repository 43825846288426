import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './account-talent-flow.module.scss';

export const AccountTalentAccepted: FC = () => {
  return (
    <Box display="flex" alignItems="baseline" justifyContent="space-between" width="100%">
      <Box>
        <Typography className={styles.firstPrice}>{'$163.18'}</Typography>
        <Typography variant="body2" className={styles.greenPrice}>
          {'+$4.12'}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">{'$450,000'}</Typography>
        <Typography variant="body2">24h Volume</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">{'2.5M (2%)'}</Typography>
        <Typography variant="body2">Float Value</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">{'12.5M'}</Typography>
        <Typography variant="body2">Market Cap</Typography>
      </Box>
    </Box>
  );
};
