import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import styles from './widget-noItems.module.scss';

interface WidgetNoItemsPropsI {
  text: ReactNode;
  icon?: ReactNode;
}

export const WidgetNoItems: FC<WidgetNoItemsPropsI> = ({ text, icon }) => {
  return (
    <Box className={styles.root}>
      {!!icon && <Box className={styles.iconWrapper}>{icon}</Box>}
      {text}
    </Box>
  );
};
