import { FC, useMemo } from 'react';
import classNames from 'classnames';
import MuiAccordion from '@mui/material/Accordion';
import type { AccordionProps } from '@mui/material/Accordion/Accordion';
import styles from './styles.module.scss';

const CLASSES = {
  root: styles.accordion,
};

export type AccordionPropsT = AccordionProps;

export const Accordion: FC<AccordionPropsT> = ({ classes: vendorClasses, ...props }) => {
  const classes = useMemo(() => {
    const { root, ...rest } = vendorClasses || {};

    return !vendorClasses
      ? CLASSES
      : {
          root: classNames(CLASSES.root, root),
          expandIconWrapper: classNames(CLASSES.root, root),
          ...rest,
        };
  }, [vendorClasses]);
  return <MuiAccordion disableGutters elevation={0} classes={classes} square {...props} />;
};
