import type { FC } from 'react';
import { useState } from 'react';

import { Rating } from '@mui/material';

import { CustomScrollbars } from 'components/custom-scrollbars';
import { MOC_ATHLETES_FOR_HCX_CARD } from 'moc-data/moc-athlete';
import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';
import { randomIntFromInterval } from 'utils/randomIntFromInterval';

import styles from './lifetime-revenue.module.scss';

let maxPrice = 0;

const mocData = MOC_ATHLETES_FOR_HCX_CARD.map(({ bio, favorite, tradingInfo, id }) => {
  if (maxPrice < tradingInfo.lastPrice) maxPrice = tradingInfo.lastPrice;
  return {
    id,
    name: bio.name,
    photo: bio.photo,
    favorite,
    rank: randomIntFromInterval(30000, 40000),
    price: tradingInfo.lastPrice,
  };
}).sort((a, b) => b.price - a.price);

const maxPriceValue = maxPrice * 1.2;

const getWidthStyle = (price: number) => ({ width: `${(price / maxPriceValue) * 100}%` });

export const LifetimeRevenue: FC = () => {
  const [selected, setSelected] = useState<number>(mocData[3].id);
  return (
    <CustomScrollbars className={styles.scrollbar}>
      <div className={styles.root}>
        <table className={styles.table}>
          <tbody>
            {mocData.map(({ id, name, photo, favorite, rank, price }) => (
              <tr
                key={id}
                onClick={() => setSelected(id)}
                className={id === selected ? styles.selectedRow : ''}
              >
                <th>
                  <div className={styles.info}>
                    {!!photo && <img src={photo} className={styles.photo} alt="" />}
                    <span className={styles.name} title={name}>
                      {name}
                    </span>
                    {favorite && (
                      <Rating max={1} value={1} className={styles.star} size="small" disabled />
                    )}
                  </div>
                </th>
                <td>
                  <div className={styles.rate} style={getWidthStyle(price)} />
                  <span className={styles.text}>
                    <span className={styles.rank}>Rank: {formatNumber(rank)}</span>
                    {formatToUSD(price)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </CustomScrollbars>
  );
};
