import { ColumnConfigurationI } from './types';

export const COLUMN_CONFIGS: Array<ColumnConfigurationI> = [
  {
    field: 'name',
    title: 'Player name',
    description: 'Player name',
    sort: 'asc',
    enabled: false,
  },
  {
    field: 'league',
    title: 'League',
    description: 'League',
    sort: 'asc',
    enabled: false,
  },
  {
    field: 'team',
    title: 'Team',
    description: 'Team',
    sort: 'asc',
    enabled: false,
  },
  {
    field: 'age',
    title: 'Age',
    description: 'Age',
    sort: 'asc',
    enabled: false,
  },
];
