import { FC, useMemo, useContext, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetSortE } from 'pages/trade-desk/widget-wrapper';
import { OrdersWidgetContext } from '../orders-widget-context';
import { useOrdersListContext } from '../orders-list-context';
import { ordersListConfig } from './orders-list.config';
import styles from './orders-list.module.scss';

const OrdersList: FC<{ noItems?: ReactNode }> = ({ noItems }) => {
  const { t } = useTranslation('common');
  const { sort } = useContext(OrdersWidgetContext);
  const { filteredOrders: orders } = useOrdersListContext();

  const sortedOrders = useMemo(() => {
    return [...orders].sort((a, b) => {
      let result = 0;
      if (a.tradingPair.athlete.fullName > b.tradingPair.athlete.fullName) result = 1;
      if (a.tradingPair.athlete.fullName < b.tradingPair.athlete.fullName) result = -1;
      return result * (sort === WidgetSortE.Asc ? 1 : -1);
    });
  }, [orders, sort]);

  return sortedOrders.length ? (
    <table className={styles.root}>
      <thead>
        <tr className={styles.headerRow}>
          {ordersListConfig.map(({ title }) => {
            const result = t(title);
            return <th key={result}>{result}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {sortedOrders.map((order) => (
          <tr className={styles.bodyRow} key={order.id}>
            {ordersListConfig.map(({ title, getContent }) => (
              <td key={`${order.id}-${title}`}>{getContent(order, t)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <>{noItems}</>
  );
};

export default OrdersList;
