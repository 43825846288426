import { FC } from 'react';

const RemoveCardIcon: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3075 14.25L16.905 15.84L15.84 16.905L14.25 15.3075L12.66 16.905L11.6025 15.84L13.1925 14.25L11.6025 12.66L12.66 11.6025L14.25 13.1925L15.84 11.6025L16.905 12.66L15.3075 14.25ZM9.8175 13.5H3V4.5H15V9.8175C15.54 9.9075 16.0425 10.095 16.5 10.3575V4.5C16.5 3.6675 15.8325 3 15 3H3C2.175 3 1.5 3.6675 1.5 4.5V13.5C1.5 14.3325 2.175 15 3 15H9.8175C9.78 14.7525 9.75 14.505 9.75 14.25C9.75 13.995 9.78 13.7475 9.8175 13.5Z"
      fill="currentColor"
    />
  </svg>
);

export default RemoveCardIcon;
