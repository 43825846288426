import { useAtom } from 'jotai';
import { FC } from 'react';
import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Tooltip, TooltipProps } from '@mui/material';

import { AthleteI } from 'types';
import { ROUTE } from 'routes/enums';
import { openAthleteCardAtom } from 'store/athletes.store';

import { AthleteCardGradient, AthleteCardContainer, AthleteCardResultsLine } from '../athlete-card';
import { AthleteCardPriceLineApollo } from '../athlete-card/athlete-card-price-line/athlete-card-price-line.apollo';

import styles from './athlete-card-tooltip.module.scss';

export interface TickerAthletePropsI {
  athlete: AthleteI;
  children: TooltipProps['children'];
  placement?: TooltipProps['placement'];
}

const classes: TooltipProps['classes'] = {
  tooltip: styles.root,
};

export const AthleteCardTooltip: FC<TickerAthletePropsI> = ({ athlete, children, placement }) => {
  const [open, setOpen] = useState(false);

  const [, openAthleteCard] = useAtom(openAthleteCardAtom);

  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClick = () => {
    setOpen(false);
    openAthleteCard({ athlete });
    navigate(generatePath(ROUTE.Overview, { id: `${athlete.id}` }));
  };
  return (
    <Tooltip
      open={open}
      classes={classes}
      disableFocusListener
      onOpen={handleOpen}
      onClose={handleClose}
      placement={placement}
      title={
        <div className={styles.pointer} onClick={handleClick}>
          <AthleteCardGradient>
            <AthleteCardContainer athlete={athlete} />
          </AthleteCardGradient>
          <AthleteCardResultsLine />
          <AthleteCardPriceLineApollo athlete={athlete} className={styles.priceLine} />
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
