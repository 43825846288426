import { createContext } from 'react';
import { noop } from 'utils/noop';

interface TransparentOverlayContextI {
  enabled: boolean;
  setEnabled: (state: boolean) => void;
}

const defaultValue = { enabled: false, setEnabled: noop };

export const TransparentOverlayContext = createContext<TransparentOverlayContextI>(defaultValue);
