import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { parseTimeLeft } from 'utils/parse-time-left';

import { CountdownCell } from './countdown-cell';

import styles from './countdown.module.scss';

interface CountdownPropsI {
  date?: Date;
}

const EMPTY_DATE = {
  seconds: '--',
  minutes: '--',
  hours: '--',
  days: '--',
};

const getTimeLeft = (date?: Date) => {
  if (!date) {
    return {
      ...EMPTY_DATE,
      closed: false as const,
    };
  }
  const now = new Date();
  const difference = date.getTime() - now.getTime();
  if (difference < 0) {
    return { closed: true } as const;
  }

  return {
    ...parseTimeLeft(difference),
    closed: false as const,
  };
};

export const Countdown: FC<CountdownPropsI> = ({ date }) => {
  const [state, setState] = useState(getTimeLeft(date));

  useEffect(() => {
    const handle = () => {
      setState(getTimeLeft(date));
    };
    const timer = window.setInterval(handle, 1000);
    return () => clearInterval(timer);
  }, [date]);

  const { t } = useTranslation('human-capital-mint');

  if (state.closed) {
    return (
      <div className={styles.container}>
        <Typography className={styles.empty}>{t('minting-closed')}</Typography>
      </div>
    );
  }

  const { seconds, minutes, hours, days } = state;

  return (
    <div className={styles.container}>
      <CountdownCell number={days.toString()} label={t('days')} />
      <CountdownCell number={hours.toString()} label={t('hours')} />
      <CountdownCell number={minutes.toString()} label={t('minutes')} />
      <CountdownCell
        number={seconds.toString()}
        label={t('seconds')}
        numberClassName={styles.red}
      />
    </div>
  );
};
