import { createContext } from 'react';
import { noop } from 'utils/noop';

type SizesT = Record<string, number>;

interface ResizeContextI {
  sizes: SizesT;
  registerElement: (id: string, ref: HTMLElement) => () => void;
}

const initialState = {
  sizes: {},
  registerElement: () => noop,
};

export const ResizeContext = createContext<ResizeContextI>(initialState);
