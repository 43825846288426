import Web3 from 'web3';

import { AbiType } from 'ethereum-types';
import { AbiItem } from 'web3-utils';

import { getWalletProvider, WalletE } from 'blockchain-api/ewallets-api';
import {
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
} from 'utils/number-formatter';

import { config } from 'config';

const BID_ABI: AbiItem[] = [
  {
    type: AbiType.Function,
    name: 'bid',
    inputs: [
      { name: '_auctionId', type: 'uint256' },
      { name: '_tokenAmount', type: 'uint256' },
      { name: '_tokenPrice', type: 'uint256' },
    ],
  },
];

const APPROVE_ABI: AbiItem[] = [
  {
    type: AbiType.Function,
    name: 'approve',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
  },
];

export const placeBidOrder = async (
  wallet: WalletE,
  blockchainId: number,
  tokenAmount: number,
  tokenPrice: number
) => {
  const provider = await getWalletProvider(wallet);
  const accounts = await provider.request({
    method: 'eth_requestAccounts',
  });
  const currentAccount = accounts[0];
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(BID_ABI, config.auctionContractAddress);
  const erc20Token = new web3.eth.Contract(APPROVE_ABI, config.stableCoinAddress);

  const decimalizedPrice = normalizeDecimals(tokenPrice, NormalizationDecimalsE.Six)[
    NormalizationLogicE.Decimal
  ];

  await erc20Token.methods
    .approve(config.auctionContractAddress, tokenAmount * decimalizedPrice)
    .send({ from: currentAccount });

  return contract.methods
    .bid(blockchainId, tokenAmount, decimalizedPrice)
    .send({ from: currentAccount })
    .on('sending', (payload: unknown) => {
      console.log('===> Sending fired ', payload);
    })
    .on('sent', (payload: unknown) => {
      console.log('===> Sent fired ', payload);
    })
    .on('transactionHash', (hash: string) => {
      console.log('===> Hash fired ', hash);
    })
    .on('error', (error: Error) => {
      console.error(error);
    });
};
