import { useAtom } from 'jotai';
import type { FC } from 'react';

import { selectedAthleteIAtom } from 'store/athletes.store';

import { HCXCardSummaryStats } from './hcx-card-summary-stats';

export const HCXCardSummaryStatsContainer: FC = () => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  if (!athlete?.career?.stats) {
    return null;
  }

  return <HCXCardSummaryStats stats={athlete.career.stats} />;
};
