import type { FC } from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, TabsProps } from '@mui/material';

import { useRenderToHeaderTradingInfo } from 'components/layout/trading-info/header-trading-info';
import { DutchAuctionBid } from 'generated/graphql';

import { MyOrdersView } from './my-orders.view';

import type { OrderT } from 'types/orders';

interface MyOrdersContainerI {
  userOrders: OrderT[];
  userBids: DutchAuctionBid[];
}

export const MyOrdersContainer: FC<MyOrdersContainerI> = ({ userOrders, userBids }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const { t } = useTranslation('my-orders');
  const handleTabChange: TabsProps['onChange'] = (_, newValue: number) => {
    setTabIdx(newValue);
  };

  const { clear: clearHeader } = useRenderToHeaderTradingInfo(
    null,
    useMemo(() => <Typography variant="body1">{t('my-orders')}</Typography>, [t])
  );
  useEffect(() => () => clearHeader(), [clearHeader]);

  return (
    <MyOrdersView
      tabIdx={tabIdx}
      onTabChange={handleTabChange}
      userBids={userBids}
      userOrders={userOrders}
    />
  );
};
