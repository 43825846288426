import { useAtom } from 'jotai';
import { FC } from 'react';

import { selectedAthleteIAtom } from 'store/athletes.store';

import HCXCardSummaryActivity from './hcx-card-summary-activity';

export const HCXCardSummaryActivityContainer: FC = () => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  if (!athlete?.career?.activity?.length) {
    return null;
  }

  return <HCXCardSummaryActivity activity={athlete.career.activity} />;
};
