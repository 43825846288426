import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import { noop } from 'utils/noop';

export interface HCXFinancialCardListResizerContextI {
  divider: number;
  setDivider: Dispatch<SetStateAction<number>>;
}

export const HCXFinancialCardListResizerContext =
  createContext<HCXFinancialCardListResizerContextI>({
    divider: 1,
    setDivider: noop,
  });
