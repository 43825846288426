import classNames from 'classnames';
import type { FC } from 'react';
import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import styles from '../ticker-bar.module.scss';

export interface TickerPropsI {
  symbol: string;
  gain: number;
  className?: string;
}

export const Ticker: FC<TickerPropsI> = ({ gain, symbol, className }) => {
  const sx = useMemo(
    () => ({
      borderBottom: `2px solid ${gain < 0 ? 'var(--hcx-alert-down)' : 'var(--hcx-alert-up)'}`,
      width: `${Math.abs(gain * 100)}%`,
    }),
    [gain]
  );
  return (
    <Box className={classNames(styles.item, className)}>
      <Typography variant="body1">{symbol}</Typography>
      <Box sx={sx} />
    </Box>
  );
};
