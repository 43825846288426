import { LimitOrder, SignatureType } from '@0x/protocol-utils';
import { BigNumber } from '@0x/utils';
import { MetamaskSubprovider } from '@0x/subproviders';
import { ContractWrappers } from '@0x/contract-wrappers';

import { config } from 'config';

import { WalletE, getDefaultWallet, getWalletProvider } from '../ewallets-api';
import { requestApprove } from '../request-approve';
import { fetchLimitOrder } from './fetch-limit-order';
import { getSubprovider } from './get-subprovider';

export const fillOrder = async (orderHash: string) => {
  const provider = await getWalletProvider(WalletE.Metamask);
  const accounts = await provider.request({
    method: 'eth_requestAccounts',
  });
  const currentAccount = accounts[0];

  const orderData = await fetchLimitOrder(orderHash);
  const order = orderData.order;

  await requestApprove({
    provider,
    maker: currentAccount,
    amount: new BigNumber(order.takerAmount),
    contractAddress: order.takerToken,
  });

  const mappedOrder = new LimitOrder({
    ...order,
    takerTokenFeeAmount: new BigNumber(0),
    expiry: new BigNumber(order.expiry),
    salt: new BigNumber(order.salt),
    makerAmount: new BigNumber(order.makerAmount),
    takerAmount: new BigNumber(order.takerAmount),
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const Subprovider = getSubprovider<MetamaskSubprovider>(getDefaultWallet()!); // TODO: VOV: Get rid from '!'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const supportedProvider = new Subprovider(provider);

  const signature = await mappedOrder.getSignatureWithProviderAsync(
    supportedProvider,
    SignatureType.EIP712, // Optional,
    currentAccount
  );

  const contractWrappers = new ContractWrappers(supportedProvider, {
    chainId: config.chainId,
  });

  const takerAmount = new BigNumber(order.takerAmount);
  await contractWrappers.exchangeProxy
    .fillLimitOrder(mappedOrder, signature, takerAmount)
    .sendTransactionAsync({
      from: currentAccount,
      value: takerAmount,
    });
};
