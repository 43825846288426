import { FC } from 'react';

import { TVChartContainer } from 'components/tv-chart-container';

import { useHCXFinancialCardContext } from '../hcx-financial-card-context';

import styles from './hcx-financial-card-chart.module.scss';

export const HCXFinancialCardFrontChart: FC = () => {
  const { athlete } = useHCXFinancialCardContext();

  if (!athlete) {
    return null;
  }

  return (
    <TVChartContainer
      className={styles.root}
      disableTopToolbar
      disableLeftToolbar
      symbol={athlete.tradingInfo.contractId}
      tradingPairId={athlete.tradingInfo.tradingPairId}
    />
  );
};
