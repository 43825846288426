import type { FC } from 'react';

import { useAthletes } from 'apolloClient/hooks';
import { AthleteOrders } from 'components/athlete/athlete-orders';

export const OrderBookOrders: FC = () => {
  const athletes = useAthletes();
  const id = athletes[0]?.id;

  if (!id) return null;

  return <AthleteOrders athleteId={id} />;
};
