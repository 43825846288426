import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  useCancelSponsorApplicationMutation,
  useSponsorApplicationStatusQuery,
} from 'generated/graphql';
import styles from './sponsor-panel-application-status.module.scss';
import { useAtom } from 'jotai';
import {
  sponsorApplicationFormAtom,
  SponsorApplicationI,
  sponsorPanelView,
} from 'store/sponsor-application.store';

export const ApplicationStatus: FC = () => {
  const [, setSponsorPanelOpen] = useAtom(sponsorPanelView);
  const [, setSponsorForm] = useAtom(sponsorApplicationFormAtom);
  const closePanel = () => setSponsorPanelOpen(false);

  const { data: sponsorStatus } = useSponsorApplicationStatusQuery();

  const { t } = useTranslation('sponsor-application');

  const [cancelApplication] = useCancelSponsorApplicationMutation();

  const handleCancelApp = async () => {
    try {
      await cancelApplication({ refetchQueries: ['SponsorApplicationData'] });
      setSponsorForm({
        sponsorInformation: {},
        sponsorOwnerInformation: {},
      } as SponsorApplicationI);
      closePanel();
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.row}>
        <Typography variant="body2">Application Submission:</Typography>
        {sponsorStatus?.sponsorApplication.createdAt && (
          <span className={styles.date}>
            {moment(sponsorStatus?.sponsorApplication.createdAt).format('DD/MM/YYYY h:mm a')}
          </span>
        )}
      </Box>
      <Box className={styles.block}>
        <Box className={styles.row}>
          <Typography variant="body2">Reviewed by HCFC:</Typography>
          {sponsorStatus?.sponsorApplication.reviewedAt && (
            <span className={styles.date}>
              {moment(sponsorStatus?.sponsorApplication.reviewedAt).format('YYYY-MM-DD h:mm a')}
            </span>
          )}
        </Box>
        <Box className={styles.rowTwo}>
          <Typography variant="body2">Application Status:</Typography>
          <Box className={styles.badge}>{sponsorStatus?.sponsorApplication.status}</Box>
          <Typography onClick={handleCancelApp} className={styles.link}>
            {t('cancel-application')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
