import type { FC } from 'react';
import { Box, Typography, TextField, SelectChangeEvent } from '@mui/material';
import { InputField } from 'components/input-field';
import { AddPhotoInput } from 'components/add-photo-input';
import { SelectField } from 'components/select-field';
import { MarkedLabel } from 'components/icons/marked-label-icon';
import { useTranslation } from 'react-i18next';
import styles from './form-section-personal.module.scss';
import {
  TalentApplicationProfileBasicInformation,
  TalentApplicationProfileQuery,
  useUpdateTalentApplicationProfileMutation,
} from 'generated/graphql';
import { talentProfileFormValidationAtom } from 'store/talent-application.store';
import React from 'react';
import { useAtom } from 'jotai';
import { COUNTRIES_LIST } from '../countries';
import { ValidationError } from 'yup';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { talentFormSchema } from 'yup/talentFormSchema';

interface FormSectionPersonalPropsI {
  basicInformation: TalentApplicationProfileBasicInformation;
  refetchProfile: () => Promise<ApolloQueryResult<TalentApplicationProfileQuery>>;
}

export const FormSectionPersonal: FC<FormSectionPersonalPropsI> = ({
  basicInformation,
  refetchProfile,
}) => {
  const [errorState, setErrorState] = useAtom(talentProfileFormValidationAtom);

  const { basicInformation: errors } = errorState;

  const { t } = useTranslation('talent-application');

  const [updateForm] = useUpdateTalentApplicationProfileMutation();

  const setTouched = (name: string) => {
    setErrorState((prev) => {
      return {
        ...prev,
        basicInformation: {
          ...prev?.basicInformation,
          [name]: {
            errMessage: undefined,
            isTouched: true,
          },
        },
      };
    });
  };

  const apolloHandler = async (name: string, value: string) => {
    setTouched(name);
    try {
      await updateForm({
        variables: {
          parameters: {
            basicInformation: {
              [name]: value,
              condition: {
                field: name,
                state: basicInformation?.condition[name].state,
              },
            },
          },
        },
        refetchQueries: ['TalentApplicationState'],
      });
      await refetchProfile();
    } catch (err) {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: (err as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
      return console.warn({ err });
    }
  };

  const onChangeHandler = async (event: React.ChangeEvent | SelectChangeEvent) => {
    const { name, value } = event.target as HTMLInputElement;

    try {
      await talentFormSchema[name].validate(value);
    } catch (error) {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: (error as ValidationError).errors[0],
              isTouched: true,
            },
          },
        };
      });
      return console.warn({ error });
    }
    setErrorState((prev) => {
      return {
        ...prev,
        basicInformation: {
          ...prev?.basicInformation,
          [name]: {
            errMessage: undefined,
            isTouched: undefined,
          },
        },
      };
    });
    await apolloHandler(name, value);
  };

  const onBlurHandler = async (event: React.FocusEvent) => {
    const { value, name } = event.target as HTMLInputElement;
    try {
      await talentFormSchema[name].validate(value);
    } catch (error) {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: (error as ValidationError).errors[0],
              isTouched: true,
            },
          },
        };
      });
      return console.warn({ error });
    }
    await apolloHandler(name, value);
  };

  const photoHandler = async (event: React.ChangeEvent) => {
    const { name } = event.target as HTMLInputElement;

    const photoMutationField = name.replace('Url', '');
    const photoList = (event.target as HTMLInputElement).files;
    if (!photoList) return;
    const photo = photoList[0];

    (event.target as HTMLInputElement).value = '';

    try {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: undefined,
              isTouched: undefined,
            },
          },
        };
      });
      await updateForm({
        variables: {
          parameters: {
            basicInformation: {
              [photoMutationField]: photo,
              condition: {
                field: photoMutationField,
                state: basicInformation?.condition[photoMutationField].state,
              },
            },
          },
        },
        refetchQueries: ['TalentApplicationState'],
      });
      await refetchProfile();
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: undefined,
              isTouched: true,
            },
          },
        };
      });
    } catch (error) {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: (error as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
    }
  };

  const setPhotoError = (error: boolean, name: string) => {
    setErrorState((prev) => {
      return {
        ...prev,
        basicInformation: {
          ...prev?.basicInformation,
          [name]: {
            errMessage: error ? 'Photo is mandatory' : undefined,
            isTouched: true,
          },
        },
      };
    });
  };

  return (
    <Box component="form" autoComplete="off" className={styles.root}>
      <Box className={styles.sideBlock}>
        <Box className={styles.row}>
          <InputField
            tName={t('first-name')}
            name={'firstName'}
            icon={<MarkedLabel />}
            placeholder={t('first')}
            className={styles.legalName}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.firstName}
            errorValidation={errors.firstName.isTouched && errors.firstName.errMessage}
          />
          <InputField
            tName={t('middle-name')}
            name={'middleName'}
            className={styles.middleName}
            placeholder={t('middle')}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.middleName}
            errorValidation={errors.middleName.isTouched && errors.middleName.errMessage}
          />
          <InputField
            tName={t('last-name')}
            name={'lastName'}
            className={styles.middleName}
            placeholder={t('last')}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.lastName}
            errorValidation={errors.lastName.isTouched && errors.lastName.errMessage}
          />
        </Box>
        <Box className={styles.row}>
          <InputField
            tName={t('street-address')}
            name={'streetAddress'}
            className={styles.streetInput}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.streetAddress}
            errorValidation={errors.streetAddress.isTouched && errors.streetAddress.errMessage}
          />
          <InputField
            tName={t('apt-suite')}
            name={'apartment'}
            className={styles.aptInput}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.apartment}
            errorValidation={errors.apartment.isTouched && errors.apartment.errMessage}
          />
        </Box>
        <Box className={styles.row}>
          <InputField
            tName={t('city')}
            name={'city'}
            className={styles.streetInput}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.city}
            errorValidation={errors.city.isTouched && errors.city.errMessage}
          />
          <InputField
            tName={t('state')}
            name={'state'}
            className={styles.stateInput}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.state}
            errorValidation={errors.state.isTouched && errors.state.errMessage}
          />
          <InputField
            tName={t('zipcode')}
            name={'zip'}
            className={styles.stateInput}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.zip}
            errorValidation={errors.zip.isTouched && errors.zip.errMessage}
          />
          <SelectField
            tName={t('country')}
            name={'country'}
            className={styles.countryInput}
            value={basicInformation.country}
            menuItems={Object.values(COUNTRIES_LIST).map((country) => country)}
            errorValidation={errors.country.isTouched && errors.country.errMessage}
            onChangeHandler={onChangeHandler}
            onBlurHandler={onBlurHandler}
          />
        </Box>
        <Box className={styles.row}>
          <InputField
            tName={t('email-address')}
            name={'emailAddress'}
            className={styles.streetInput}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.emailAddress}
            errorValidation={errors.emailAddress.isTouched && errors.emailAddress.errMessage}
          />
        </Box>
        <Box className={styles.row}>
          <TextField
            type="date"
            name={'dateOfBirth'}
            label={
              <Box display="flex" alignItems="center" gap={1}>
                {t('date-of-birth')}
                <MarkedLabel />
              </Box>
            }
            className={styles.dateInput}
            size="small"
            onChange={onChangeHandler}
            value={
              basicInformation.dateOfBirth.slice(0, 10) !== '0001-01-01'
                ? basicInformation.dateOfBirth.slice(0, 10)
                : ''
            }
            InputProps={{ disableUnderline: true }}
            variant="filled"
            error={errors.dateOfBirth.isTouched && !!errors.dateOfBirth.errMessage}
            helperText={errors.dateOfBirth.errMessage}
          />
          <SelectField
            tName={t('sex')}
            name={'sex'}
            icon={<MarkedLabel />}
            className={styles.countryInput}
            onChangeHandler={onChangeHandler}
            onBlurHandler={onBlurHandler}
            menuItems={['Male', 'Female']}
            value={basicInformation.sex}
            errorValidation={errors.sex.isTouched && errors.sex.errMessage}
          />
          <SelectField
            tName={t('nationality')}
            name={'nationality'}
            className={styles.aptInput}
            value={basicInformation.nationality}
            menuItems={Object.values(COUNTRIES_LIST).map((nationality) => nationality)}
            errorValidation={errors.nationality.isTouched && errors.nationality.errMessage}
            onChangeHandler={onChangeHandler}
            onBlurHandler={onBlurHandler}
          />
        </Box>
        <Box className={styles.row}>
          <SelectField
            tName={t('marital-status')}
            name={'maritalStatus'}
            className={styles.dateInput}
            onChangeHandler={onChangeHandler}
            onBlurHandler={onBlurHandler}
            menuItems={['Single', 'Married']}
            value={basicInformation.maritalStatus}
            errorValidation={errors.maritalStatus.isTouched && errors.maritalStatus.errMessage}
          />
          <InputField
            tName={t('social-security-number')}
            name={'ssn'}
            className={styles.aptInput}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.ssn}
            errorValidation={errors.ssn.isTouched && errors.ssn.errMessage}
          />
        </Box>
        <Box className={styles.row}>
          <InputField
            tName={t('place-of-birth')}
            name={'placeOfBirthCity'}
            className={styles.legalName}
            placeholder={t('city')}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.placeOfBirthCity}
            errorValidation={
              errors.placeOfBirthCity.isTouched && errors.placeOfBirthCity.errMessage
            }
          />
          <InputField
            tName={t('state-province')}
            name={'placeOfBirthState'}
            className={styles.middleName}
            placeholder={t('state-province')}
            onBlur={onBlurHandler}
            defaultValue={basicInformation.placeOfBirthState}
            errorValidation={
              errors.placeOfBirthState.isTouched && errors.placeOfBirthState.errMessage
            }
          />
          <SelectField
            tName={t('select-country')}
            name={'placeOfBirthCountry'}
            className={styles.dateInput}
            value={basicInformation.placeOfBirthCountry}
            menuItems={Object.values(COUNTRIES_LIST).map((country) => country)}
            onChangeHandler={onChangeHandler}
            onBlurHandler={onBlurHandler}
            errorValidation={
              errors.placeOfBirthCountry.isTouched && errors.placeOfBirthCountry.errMessage
            }
          />
        </Box>
      </Box>
      <Box className={styles.sideBlock}>
        <Box mb={3}>
          <Box className={styles.labelRow}>
            <Typography variant="body2">{t('profile-picture')}</Typography>
            <MarkedLabel />
          </Box>
          <Box className={styles.row}>
            <Box className={styles.photoInput}>
              <AddPhotoInput
                fieldName={'profilePictureUrl'}
                state={basicInformation.profilePictureUrl}
                onChange={photoHandler}
                setError={setPhotoError}
                errorValidation={
                  errors.profilePictureUrl.isTouched && errors.profilePictureUrl.errMessage
                }
              />
            </Box>
            <Box width="333px">
              <Typography variant="body1" mb={1}>
                {t('tips-for-an-effective-photo')}
              </Typography>
              <Typography variant="body2">
                {t('your-profile-picture-will-be-the-one-that')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <InputField
          tName={t('describe-yourself')}
          name={'description'}
          icon={<MarkedLabel />}
          className={styles.textAreaInput}
          multiline={true}
          maxRows="5"
          onBlur={onBlurHandler}
          defaultValue={basicInformation.description}
          errorValidation={errors.description.isTouched && errors.description.errMessage}
        />
      </Box>
    </Box>
  );
};
