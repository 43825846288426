import { FC } from 'react';
import styles from './results.module.scss';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import classNames from 'classnames';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface PropsI {
  className?: string;
  title?: string;
  onClose: () => void;
}

export const ResultsWrapper: FC<PropsI> = ({ className, onClose, title, children }) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classNames(styles.root, className)}>
        <div className={styles.titleRow}>
          <Typography variant="caption">{title ?? ''}</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        {children}
      </div>
    </ClickAwayListener>
  );
};
