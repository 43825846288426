import { FC, useMemo } from 'react';
import classNames from 'classnames';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { AccordionSummaryProps } from '@mui/material/AccordionSummary/AccordionSummary';
import styles from './styles.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CLASSES = {
  root: styles.summary,
  expandIconWrapper: styles.expandIconWrapper,
};

export const AccordionSummary: FC<AccordionSummaryProps> = ({
  classes: vendorClasses,
  ...props
}) => {
  const classes = useMemo(() => {
    const { root, expandIconWrapper, ...rest } = vendorClasses || {};

    return !vendorClasses
      ? CLASSES
      : {
          root: classNames(CLASSES.root, root),
          expandIconWrapper: classNames(CLASSES.expandIconWrapper, expandIconWrapper),
          ...rest,
        };
  }, [vendorClasses]);

  return <MuiAccordionSummary classes={classes} expandIcon={<ExpandMoreIcon />} {...props} />;
};
