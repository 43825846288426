import { FC, MouseEvent } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import styles from './delay-field.module.scss';

type NameT = 'days' | 'hours' | 'minutes' | 'seconds';

export interface DelayFieldOnChangeDataI {
  value: number;
  name: NameT;
}

export interface DelayFieldPropsI extends DelayFieldOnChangeDataI {
  min?: number;
  max: number;
  onChange: (data: DelayFieldOnChangeDataI) => void;
  label: string;
}

const inputProps: TextFieldProps['inputProps'] = {
  className: styles.input,
};

const InputPropsClasses = {
  root: styles.inputWrapper,
};

const DelayField: FC<DelayFieldPropsI> = ({ value, name, onChange, min = 0, max, label }) => {
  const changeValue = (newValue: number) => {
    if (newValue < min || newValue > max) return;
    onChange({ name, value: newValue });
  };

  const handleChange: TextFieldProps['onChange'] = ({ target: { value: newValue } }) => {
    if (/[^0-9]/g.test(newValue)) return;
    const valueNumber = +newValue;
    changeValue(valueNumber);
  };

  const increase = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    changeValue(value + 1);
  };

  const decrease = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    changeValue(value - 1);
  };

  const InputProps: TextFieldProps['InputProps'] = {
    classes: InputPropsClasses,
    endAdornment: (
      <InputAdornment position="end">
        <div className={styles.inputArrows}>
          <svg
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={increase}
          >
            <path d="M8 4L4 3.49691e-07L0 4L8 4Z" fill="currentColor" />
          </svg>
          <svg
            width="8"
            height="4"
            viewBox="0 0 8 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={decrease}
          >
            <path d="M0 0L4 4L8 0H0Z" fill="currentColor" />
          </svg>
        </div>
      </InputAdornment>
    ),
  };

  return (
    <div className={styles.root}>
      <TextField
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
        InputProps={InputProps}
        inputProps={inputProps}
        size="small"
        variant="standard"
      />
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default DelayField;
