// TODO: VOV: Work on commented code.
// import * as contractAddresses from '@0x/contract-addresses';

import { HARDCODED_TRADING_PAIR_ADDRESS } from '../constants';
import { config } from 'config';
import { FetchLimitOrdersPropsI, FetchLimitOrdersResponseI } from './types';

const FETCH_ORDERS_ENDPOINT = `${config.zeroXApiBaseUrl}/orderbook/v1`;

export const fetchLimitOrders = async (
  data: FetchLimitOrdersPropsI
): Promise<FetchLimitOrdersResponseI> => {
  // console.log({ contractAddresses });
  // const addresses = contractAddresses.getContractAddressesForChainOrThrow(config.chainId);

  const mappedData = {
    ...data,
    baseToken: data.baseToken ?? config.stableCoinAddress,
    quoteToken: data.quoteToken ?? HARDCODED_TRADING_PAIR_ADDRESS,
  };

  const params = new URLSearchParams();

  Object.entries(mappedData).forEach(([key, value]) => params.append(key, value.toString()));

  const r = await fetch(`${FETCH_ORDERS_ENDPOINT}?${params.toString()}`, {
    headers: {
      'content-type': 'application/json',
    },
  });

  if (r.status === 200) {
    return r.json();
  }

  throw new Error(await r.json());
};
