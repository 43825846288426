import { useAtom } from 'jotai';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTradingAthletes, useTradingAthletesPagination } from 'apolloClient/hooks';
import { CustomScrollbars } from 'components/custom-scrollbars';
import { Paginator } from 'components/paginator';
import { HCXFinancialCard } from 'features/hcx-financial-card';
import { AthleteI, QueryPagingI } from 'types';
import {
  closeTradeItemAtom,
  openTradeItemAtom,
  getTradeItemChartOpenStatus,
  selectedTradeItemIAtom,
} from 'store/trade-desk.store';
import { listGridViewStatusAtom } from 'store/athletes.store';
import { useDynamicHCXFinancialCardListStyle } from './hcx-financial-card-list-block.hooks';
import { Box, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import { TableHeadCell, TableHeadRow } from 'components/table';
import { FinancalCardListTableRow } from '../hcx-financial-card-list-table';
import { TVChartContainer } from 'components/tv-chart-container';
import {
  OverviewChartHeader,
  TypeE,
} from 'pages/overview/overview-chart-header/overview-chart-header';
import { Panel } from 'components/panel';
import { ResizableCell } from 'components/resizable-grid';
import styles from './hcx-financial-card-list-block.module.scss';

interface TableHeadEntryI {
  title: string;
  key: string;
}

const TABLE_HEAD_CELLS: TableHeadEntryI[] = [
  { title: 'Talent', key: 'name' },
  { title: 'Auction Status', key: 'mintingStatus' },
  { title: '# Bidders', key: 'numberOfBidders' },
  { title: 'Threshold Price', key: 'thresholdPrice' },
  { title: '% Revenue', key: 'revenue' },
  { title: 'Contract Term', key: 'contractTerm' },
  { title: 'Auction End Time', key: 'auctionEndTime' },
  { title: 'Age', key: 'age' },
  { title: 'Gender', key: 'gender' },
];

export const HCXFinancialCardListBlock: FC = () => {
  const [queryPaging, setQueryPaging] = useState<QueryPagingI>({ limit: 20, offset: 0 });
  const [isAthleteCardShown, setIsAthleteCardShown] = useState(false);

  const athletes = useTradingAthletes(queryPaging);
  const [athlete] = useAtom(selectedTradeItemIAtom);

  const [tradeItemChartOpenStatus] = useAtom(getTradeItemChartOpenStatus);
  const [, openAthleteCard] = useAtom(openTradeItemAtom);
  const [, closeAthleteCard] = useAtom(closeTradeItemAtom);
  const [auctionRowData, setAuctionRowData] = useState<AthleteI>();
  const { pagination } = useTradingAthletesPagination();
  const style = useDynamicHCXFinancialCardListStyle();
  const [isListView] = useAtom(listGridViewStatusAtom);
  const handlePaginationChange = useCallback(
    (newQueryPaging) => setQueryPaging(newQueryPaging),
    []
  );

  const handleRowClicked = useCallback(
    (auctionData: AthleteI) => {
      if (auctionData) {
        if (auctionData.id === auctionRowData?.id && tradeItemChartOpenStatus) {
          closeAthleteCard();
          setIsAthleteCardShown(false);
        } else {
          openAthleteCard({ athlete: auctionData });
        }
      }
      setAuctionRowData({
        ...auctionData,
      });
      setIsAthleteCardShown(true);
    },
    [openAthleteCard, closeAthleteCard, auctionRowData, tradeItemChartOpenStatus]
  );

  const isAthleteLoaded = !!athlete?.id;

  return (
    <div className={styles.root}>
      <CustomScrollbars className={styles.scrollbar}>
        <div className={styles.listHolder} style={style}>
          {isListView ? (
            <Box className={isAthleteCardShown ? styles.stretchFlex : ''}>
              {isAthleteLoaded && (
                <Box className={styles.container}>
                  <ResizableCell id="chart" className={styles.chartRow}>
                    <Panel className={styles.chartContainer}>
                      <TVChartContainer
                        className={styles.chart}
                        customHeader={(chartWidgetRef) => (
                          <OverviewChartHeader chartWidgetRef={chartWidgetRef} type={TypeE.Trade} />
                        )}
                        symbol={athlete.tradingInfo.contractId}
                        tradingPairId={athlete.tradingInfo.tradingPairId}
                      />
                    </Panel>
                  </ResizableCell>
                </Box>
              )}
              <Box className={styles.table}>
                <TableContainer sx={{ maxHeight: 1000 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableHeadRow>
                        {TABLE_HEAD_CELLS.map((cell) => (
                          <TableHeadCell key={cell.key}>
                            <Box height="40px" display="flex" alignItems="flex-end">
                              <Typography variant="caption" display="block">
                                {cell.title}
                              </Typography>
                            </Box>
                          </TableHeadCell>
                        ))}
                      </TableHeadRow>
                    </TableHead>
                    <TableBody>
                      {athletes
                        .filter((entry: AthleteI) => !!entry)
                        .map((entry: AthleteI) => (
                          <FinancalCardListTableRow
                            key={entry.id}
                            entry={entry}
                            selectedRow={athlete?.id.toString() || ''}
                            onRowClick={handleRowClicked}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          ) : (
            athletes.map((athleteItem) => (
              <HCXFinancialCard key={athleteItem.id} athlete={athleteItem} />
            ))
          )}
        </div>
      </CustomScrollbars>
      <div className={styles.pagination}>
        <Paginator pagination={pagination} handlePaginationChange={handlePaginationChange} />
      </div>
    </div>
  );
};
