import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './account-sponsor-approved.module.scss';
import { useSponsorApplicationDataQuery } from 'generated/graphql';

export const SponsorApproved: FC = () => {
  const { data: sponsorData } = useSponsorApplicationDataQuery();

  return (
    <Box className={styles.root}>
      <Box mr={1}>
        <Typography variant="subtitle1" className={styles.title}>
          {sponsorData?.sponsorApplication.sponsorInformation.corporateName}
        </Typography>
        {/* <Rating value={4} size="small" readOnly classes={{ icon: styles.iconColor }} /> //TODO: un-hide when 'Rating' will be ready as feature */}
      </Box>
      <picture>
        <source
          srcSet={sponsorData?.sponsorApplication.sponsorInformation.logoUrlForLightBackground}
          media="(prefers-color-scheme: light)"
        />
        <img
          className={styles.image}
          alt={sponsorData?.sponsorApplication.sponsorInformation.corporateName}
          src={sponsorData?.sponsorApplication.sponsorInformation.logoUrlForDarkBackground}
        />
      </picture>
    </Box>
  );
};
