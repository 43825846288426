import type { FC, SyntheticEvent } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  TalentApplicationProfileBasicInformation,
  TalentApplicationProfileQuery,
  useUpdateTalentApplicationProfileMutation,
} from 'generated/graphql';
import { ApolloQueryResult } from '@apollo/client';

interface FormSectionBackgroundPropsI {
  basicInformation: TalentApplicationProfileBasicInformation;
  refetchProfile: () => Promise<ApolloQueryResult<TalentApplicationProfileQuery>>;
}

export const FormSectionBackground: FC<FormSectionBackgroundPropsI> = ({
  basicInformation,
  refetchProfile,
}) => {
  const { t } = useTranslation('talent-application');

  const [updateForm] = useUpdateTalentApplicationProfileMutation();

  const onChangeHandler = async (event: SyntheticEvent<Element, Event>) => {
    const { name, checked: value } = event.target as HTMLInputElement;
    try {
      await updateForm({
        variables: {
          parameters: {
            basicInformation: {
              [name]: value,
              condition: {
                field: name,
                state: basicInformation.condition[name].state,
              },
            },
          },
        },
        refetchQueries: ['TalentApplicationState'],
      });
      await refetchProfile();
    } catch (err) {
      console.warn({ err });
    }

    (event.target as HTMLInputElement).value = '';
  };

  return (
    <Box component="form" autoComplete="off" display="flex" alignItems="center">
      <FormControlLabel
        control={<Checkbox size="small" />}
        label={t('i-authorize-hcx-to-conduct-a-full-background')}
        onChange={onChangeHandler}
        checked={basicInformation.backgroundCheckAuthorization}
        name={'backgroundCheckAuthorization'}
      />
    </Box>
  );
};
