import { ValidationErrorI, ValidationResultT } from 'utils/validation';
import styles from './styles.module.scss';
import { insertSeparator } from 'utils/insert-separator';

const hasError = (result: ValidationResultT | undefined): result is ValidationErrorI =>
  !(result?.valid ?? true);

export const renderValidationResult = (result?: ValidationResultT) =>
  hasError(result) && (
    <div className={styles.error}>
      {insertSeparator(
        result.errors.map((x) => <span dangerouslySetInnerHTML={{ __html: x }} key={x} />),
        <br />
      )}
    </div>
  );
