import { FC } from 'react';

import { TVChartContainer } from 'components/tv-chart-container';

export const HCXCardSummaryFinance: FC<{ ticker: string; tradingPairId: string }> = ({
  ticker,
  tradingPairId,
}) => {
  return (
    <TVChartContainer
      height={277}
      disableTopToolbar
      disableLeftToolbar
      symbol={ticker}
      tradingPairId={tradingPairId}
    />
  );
};
