import { ValidationResultT } from 'utils/validation';

export interface AuthDataShapeI<T> {
  email: T;
  password: T;
}

const validateEmail = (email: string): ValidationResultT => {
  const regex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
  );
  if (email.match(regex)) {
    return { valid: true };
  }
  return {
    valid: false,
    errors: ['Wrong email format'],
  };
};

const validatePasswordCharacters = (password: string): ValidationResultT => {
  const errors: string[] = [];

  if (!password.match(/[a-z]/)) {
    errors.push('* lower case letters (a-z)');
  }

  if (!password.match(/[A-Z]/)) {
    errors.push('* upper case letters (A-Z)');
  }

  if (!password.match(/[0-9]/)) {
    errors.push('* numbers (i.e. 0-9)');
  }

  if (!password.match(/[!@#$%^&*?_~]/)) {
    errors.push('* special characters (e.g. !@#$%^&*)');
  }

  if (errors.length < 2) {
    return { valid: true };
  }

  return {
    valid: false,
    errors: ['* At least 3 of the following 4 types of characters:'].concat(
      errors.map((err) => `&nbsp;${err}`)
    ),
  };
};

const validatePassword = (password: string): ValidationResultT => {
  const errors: string[] = [];

  if (password.length < 9) {
    errors.push('* At least 8 characters in length');
  }

  const charactersValidation = validatePasswordCharacters(password);
  if (!charactersValidation.valid) {
    errors.push(...charactersValidation.errors);
  }

  if (!errors.length) {
    return {
      valid: true,
    };
  }

  return {
    valid: false,
    errors: ['Your password must contain: '].concat(errors.map((err) => `&nbsp;${err}`)),
  };
};

// could be generalized with HKT
export const validateAuthData = (
  data: AuthDataShapeI<string>
): AuthDataShapeI<ValidationResultT> => {
  return {
    email: validateEmail(data.email),
    password: validatePassword(data.password),
  };
};

export const allValid = (data: AuthDataShapeI<ValidationResultT>): boolean =>
  Object.values(data).every((x) => x.valid);

export const isDomainORG = () => {
  const pathArray = window.location.host.split('.');
  const domain = pathArray[pathArray.length - 1];
  console.log({ domain });
  if (domain === 'org') {
    return true;
  }
  return false;
};
