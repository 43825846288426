// import { t as translate } from 'i18next';
import { FC, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { HCXCardViewE } from 'enums';

import { GetDefaultTabByViewT } from '../hcx-card.hooks';
import { HCXCardBuySellBuy } from './hcx-card-buy_sell-buy';
import { HCXCardBuySellSell } from './hcx-card-buy_sell-sell';
import { HCXCardBuySellOrders } from './hcx-card-buy_sell-orders';

// TODO: VOV: Should it be used? Need to check
// const t = (key: string) => translate(key, { ns: 'hcx-card' });

export const TABS: { label: ReactNode; component: FC | null }[] = [
  { label: <Trans i18nKey="buy" ns="hcx-card" />, component: HCXCardBuySellBuy },
  { label: <Trans i18nKey="sell" ns="hcx-card" />, component: HCXCardBuySellSell },
  { label: <Trans i18nKey="orders" ns="hcx-card" />, component: HCXCardBuySellOrders },
];

export const getHCXCardBuySellDefaultTabByView: GetDefaultTabByViewT = (view) => {
  switch (view) {
    case HCXCardViewE.Buy:
      return 0;
    case HCXCardViewE.Sell:
      return 1;
    case HCXCardViewE.BuySellOrders:
      return 2;
    default:
      return 0;
  }
};
