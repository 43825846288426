import { useTranslation } from 'react-i18next';
import { BaseWalletCard } from './base-wallet-card';
import { FC } from 'react';
import { assets } from './assets';
import { capitalize } from 'utils/capitalize';
import { connectCoinbase, connectMetamask, WalletE } from 'blockchain-api/ewallets-api';

const WALLETS = Object.entries(assets).map(([id, image]) => ({ id, image, title: capitalize(id) }));

interface PropsI {
  onClose: () => void;
  onConnect: (wallet: WalletE) => void;
}

export const AddWalletCard: FC<PropsI> = ({ onClose, onConnect }) => {
  const { t } = useTranslation('wallet-card');
  const handleEWalletClick = (id: string) => {
    if (id === WalletE.Metamask) {
      connectMetamask()
        .then(() => onConnect(WalletE.Metamask))
        .catch(console.log);
    }
    if (id === WalletE.Coinbase) {
      connectCoinbase()
        .then(() => onConnect(WalletE.Coinbase))
        .catch(console.log);
    }
  };
  return (
    <BaseWalletCard
      onClose={onClose}
      description={t('add-wallet-description')}
      onItemClick={handleEWalletClick}
      items={WALLETS}
      onAddCreditCard={() => {}}
    />
  );
};
