import type { FC } from 'react';

import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';

import { Accordion, AccordionSummary } from 'components/accordion';

import { FIELDS } from './fields';
import { FieldT } from './types';

import styles from './section.module.scss';

type DataT = Record<string, string | number | boolean>;

interface PropsI {
  title: string;
  fields: FieldT[][];
  data: DataT;
  onChange: (data: DataT) => void;
}

export const Section: FC<PropsI> = ({ title, fields, data, onChange }) => {
  return (
    <Accordion>
      <AccordionSummary>
        <Typography variant="subtitle2">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {fields.map((row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={i} className={styles.row}>
              {row.map((field, index) => {
                const Field = FIELDS[field.type];
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box key={index} className={styles.field}>
                    <Field
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      {...(field as any)} // FIXME: VOV: Should be used correct type, without casting
                      value={data[field.field]}
                      onChange={(value: never) => onChange({ ...data, [field.field]: value })}
                    />
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
