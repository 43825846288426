import type { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';
import styles from './talent-header.module.scss';
import {
  TalentApplicationStatus,
  useCancelTalentApplicationMutation,
  useTalentApplicationStatusQuery,
} from 'generated/graphql';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import {
  talentActivityView,
  talentPanelView,
  talentProfileErrorForm,
  talentProfileFormValidationAtom,
} from 'store/talent-application.store';
import { useAtom } from 'jotai';
import { ActivityPanelIcon } from 'components/icons/activity-panel-icon';

export const TalentHeader: FC = () => {
  const { t } = useTranslation('talent-application');
  const [, setActivityView] = useAtom(talentActivityView);
  const [, setIsTalentPanelOpened] = useAtom(talentPanelView);

  const [cancelTalentApplication] = useCancelTalentApplicationMutation();
  const { data: talentStatus, updateQuery } = useTalentApplicationStatusQuery();

  const [, setErrorState] = useAtom(talentProfileFormValidationAtom);

  const closeActivityPanel = () => setActivityView(true);

  const closeTalentPanel = () => {
    setErrorState(talentProfileErrorForm);
    setIsTalentPanelOpened(false);
  };

  const cancelApplication = async () => {
    try {
      await cancelTalentApplication();
      updateQuery(() => ({
        talentApplication: {
          updatedAt: '',
          updatedBy: '',
          status: TalentApplicationStatus.Canceled,
        },
      }));
      closeTalentPanel();
    } catch (err) {
      updateQuery(() => ({
        talentApplication: {
          updatedAt: '',
          updatedBy: '',
          status: TalentApplicationStatus.Canceled,
        },
      }));
      closeTalentPanel();
      console.warn(err);
    }
  };

  return (
    <Box className={styles.root}>
      <Typography variant="subtitle1">{t('hcx-public-offering-details-ipo')}</Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="body2">{`last updated ${moment(
          talentStatus?.talentApplication.updatedAt
        ).fromNow()} by ${talentStatus?.talentApplication.updatedBy || '""'}`}</Typography>
        <Button
          onClick={cancelApplication}
          variant="contained"
          size="small"
          className={styles.cancelButton}
        >
          {t('cancel-ipo-process')}
        </Button>
        <IconButton onClick={closeActivityPanel}>
          <ActivityPanelIcon />
        </IconButton>
        <IconButton onClick={closeTalentPanel}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
