import { FC } from 'react';
import { AccordionDetails, Typography } from '@mui/material';
import { Accordion, AccordionPropsT, AccordionSummary } from 'components/accordion';
import styles from './account-profile-security-section.module.scss';
import { useTranslation } from 'react-i18next';

const ACCORDION_CLASSES = {
  root: styles.accordion,
  expanded: styles.expanded,
  disabled: styles.accordionDisabled,
};

const ACCORDION_SUMMARY_CLASSES = {
  root: styles.summary,
  content: styles.summaryContent,
  expandIconWrapper: styles.summaryIcon,
  disabled: styles.summaryDisabled,
};

const ACCORDION_DETAILS_CLASSES = {
  root: styles.details,
};

export interface AccountProfileSecuritySectionPropsI {
  title: string;
  id?: string;
  expandedId?: string;
  onChangeExpand?: (id: string) => void;
  disabled?: boolean;
}

const AccountProfileSecuritySection: FC<AccountProfileSecuritySectionPropsI> = ({
  title,
  id,
  disabled,
  expandedId,
  onChangeExpand,
  children,
}) => {
  const { t } = useTranslation('account-profile');
  const handleChange: AccordionPropsT['onChange'] | undefined =
    onChangeExpand && id
      ? (_, isExpanded) => {
          onChangeExpand(isExpanded ? id || '' : '');
        }
      : undefined;
  return (
    <Accordion
      expanded={handleChange && id ? id === expandedId : undefined}
      onChange={handleChange}
      classes={ACCORDION_CLASSES}
      // disabled={disabled}
    >
      <AccordionSummary classes={ACCORDION_SUMMARY_CLASSES}>
        <Typography variant="subtitle2">{title}</Typography>
        {disabled && <span className={styles.chip}>{t('not-enabled')}</span>}
      </AccordionSummary>
      <AccordionDetails classes={ACCORDION_DETAILS_CLASSES}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccountProfileSecuritySection;
