import { FC, useCallback } from 'react';
import { useEffect } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import styles from './tab-sponsor-level.module.scss';
import { SelectBadgeIcon } from 'components/icons/select-badge-icon';
import { BadgeCheckedIcon } from 'components/icons/select-checked-icon';
import { BadgeNotCheckedIcon } from 'components/icons/select-notchecked-icon';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { sponsorLevel } from 'store/sponsor-management.store';
import {
  SponsorLevel,
  useSponsorInformationLazyQuery,
  useUpdateSponsorInformationMutation,
} from 'generated/graphql';

export const TabSponsorLevel: FC = () => {
  const [level, setLevel] = useAtom(sponsorLevel);

  const { t } = useTranslation('sponsor-management');

  const [updateSponsorInformation] = useUpdateSponsorInformationMutation();
  const [getSponsorInfo] = useSponsorInformationLazyQuery();

  const fetchSponsorList = useCallback(() => {
    getSponsorInfo()
      .then((res) => setLevel(res.data?.sponsorInformation.level as SponsorLevel))
      .catch(console.log);
  }, [getSponsorInfo, setLevel]);

  const handleChangeLevel = async (e: { target: HTMLInputElement }) => {
    try {
      await updateSponsorInformation({
        variables: { info: { level: e.target.value as SponsorLevel } },
      });
    } catch (err) {
      console.log(err);
      return;
    }
    setLevel(e.target.value as SponsorLevel);
  };

  useEffect(() => {
    fetchSponsorList();
  }, [fetchSponsorList]);

  const RADIO_CONTROL_LIST = [
    {
      value: SponsorLevel.Level1,
      name: 'referral-sponsor',
      badge: 'level-1',
      commission: '1-commission',
      text: (
        <Box>
          <Typography className={styles.description}>
            {t('sponsor-relationship-manager-to')}
          </Typography>
          <Typography className={styles.description}>
            {t('for-such-services-sponsor-shall')}
          </Typography>
        </Box>
      ),
    },
    {
      value: SponsorLevel.Level2,
      name: 'advocate-sponsor',
      badge: 'level-2',
      commission: '3-commission',
      text: (
        <Box>
          <Typography className={styles.description}>
            {t('in-addition-to-the-serices-provided')}
          </Typography>
          <Typography className={styles.description}>
            {t('sponsor-shall-be-required-to-put-up')}
          </Typography>
          <Typography className={styles.description}>
            {t('for-such-services-sponsor-shall-be')}
          </Typography>
        </Box>
      ),
    },
    {
      value: SponsorLevel.Level3,
      name: 'champion-sponsor',
      badge: 'level-3',
      commission: '4-commission',
      text: (
        <Box>
          <Typography className={styles.description}>
            {t('in-addition-to-the-serices-provided-as')}
          </Typography>
          <Typography className={styles.description}>
            {t('for-such-services-sponsor-shall-be-paid')}
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box className={styles.root}>
      <Typography variant="body1" className={styles.description}>
        {t('sponsor-level-descriptions')}
      </Typography>
      <FormControl fullWidth>
        <RadioGroup name="sponsor-levels" value={level} onChange={handleChangeLevel}>
          {RADIO_CONTROL_LIST.map((item) => {
            return (
              <Box key={item.value} className={styles.wrapper}>
                <Box className={styles.border}>
                  <Box className={styles.rowHeading}>
                    <FormControlLabel
                      value={item.value}
                      control={
                        <Radio
                          size="small"
                          className={styles.radio}
                          checkedIcon={<BadgeCheckedIcon />}
                          icon={<BadgeNotCheckedIcon />}
                        />
                      }
                      label={
                        <Typography variant="subtitle2" className={styles.radioLabel}>
                          {t(item.name)}
                        </Typography>
                      }
                    />
                    <Box className={styles.iconWrapper}>
                      <SelectBadgeIcon
                        className={classNames(styles.badgeIcon, {
                          [styles.checkedIcon]: level === item.value,
                        })}
                      />
                      <Typography
                        variant="button"
                        className={classNames(styles.badgeText, {
                          [styles.checkedText]: level === item.value,
                        })}
                      >
                        {t(item.badge)}
                      </Typography>
                    </Box>
                    <Typography variant="subtitle2">{t(item.commission)}</Typography>
                  </Box>
                  <Box className={styles.textWrapper}>{item.text}</Box>
                </Box>
              </Box>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
