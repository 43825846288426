import type { FC, MouseEvent } from 'react';
import styles from './resizer.module.scss';
import classNames from 'classnames';
import { useCallback, useRef } from 'react';
import { useTransparentOverlay } from '../transparent-overlay';

export enum DirectionE {
  Vertical,
  Horizontal,
}

interface PropsI {
  direction?: DirectionE;
  className?: string;
  onDrag: (diff: number) => void;
  onDragEnd: () => void;
}

export const Resizer: FC<PropsI> = ({
  direction = DirectionE.Vertical,
  onDrag,
  className,
  onDragEnd,
}) => {
  const state = useRef({ dragging: false, lastCoordinate: 0 });
  const { setEnabled: setOverlayEnabled } = useTransparentOverlay();
  const getMouseCoordinate = useCallback(
    (e: MouseEvent) => (direction === DirectionE.Vertical ? e.clientX : e.clientY),
    [direction]
  );
  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      state.current.dragging = true;
      state.current.lastCoordinate = getMouseCoordinate(event);
      setOverlayEnabled(true);
      document.body.classList.add(styles.noSelect);

      const moveHandler = (e: Event) => {
        const current = getMouseCoordinate(e as unknown as MouseEvent);
        onDrag(state.current.lastCoordinate - current);
        state.current.lastCoordinate = current;
      };

      const mouseUpHandler = () => {
        state.current.lastCoordinate = 0;
        state.current.dragging = false;
        setOverlayEnabled(false);
        document.body.classList.remove(styles.noSelect);
        onDragEnd();
        window.removeEventListener('mousemove', moveHandler);
        window.removeEventListener('mouseup', mouseUpHandler);
      };

      window.addEventListener('mousemove', moveHandler);
      window.addEventListener('mouseup', mouseUpHandler);
    },
    [getMouseCoordinate, onDrag, setOverlayEnabled, onDragEnd]
  );
  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
        direction === DirectionE.Horizontal && styles.horizontal
      )}
      onMouseDown={onMouseDown}
    >
      <div className={styles.resizer}>
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
      </div>
    </div>
  );
};
