import type { FC } from 'react';
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import { tickerBarView, tradeBarView } from 'store/trading-stats.store';

import styles from './content-wrapper.module.scss';

export const ContentWrapper: FC = ({ children }) => {
  const [isTradeBarOpened] = useAtom(tradeBarView);
  const [isTickerBarOpened] = useAtom(tickerBarView);

  const heightStyle = useMemo(() => {
    let heightGap = 120; // Header height + padding
    if (isTradeBarOpened) {
      heightGap += 60;
    }
    if (isTickerBarOpened) {
      heightGap += 50;
    }
    return { height: `calc(100vh - ${heightGap}px)` };
  }, [isTradeBarOpened, isTickerBarOpened]);

  return (
    <Box className={styles.wrapper} style={heightStyle}>
      {children}
    </Box>
  );
};
