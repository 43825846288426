import { AthleteCareerStatI, TableColumnConfigI } from 'types';
import { formatNumber } from 'utils/number-formatter';
import { Trans } from 'react-i18next';

export const ATHLETE_SUMMARY_HIGHLIGHTS_COLUMNS_CONFIG: TableColumnConfigI<AthleteCareerStatI>[] = [
  {
    title: <Trans i18nKey="year" ns="hcx-card" />,
    dataKey: 'date',
    renderValue: (data) => new Date(data.date).getFullYear(),
  },
  {
    title: <Trans i18nKey="team" ns="hcx-card" />,
    dataKey: 'team',
  },
  {
    title: <Trans i18nKey="G" ns="hcx-card" />,
    dataKey: 'gp',
    renderValue: (data) => formatNumber(data.gp),
  },
  {
    title: <Trans i18nKey="rec" ns="hcx-card" />,
    dataKey: 'rec',
    renderValue: (data) => formatNumber(data.rec),
  },
  {
    title: <Trans i18nKey="yds" ns="hcx-card" />,
    dataKey: 'yds',
    renderValue: (data) => formatNumber(data.yds),
  },
];
