import type { FC } from 'react';

import { useCurrentRouteConfig } from 'hooks/useCurrentRouteConfig';
import { ROUTE } from 'routes/enums';

export const SideBarWrapper: FC = ({ children }) => {
  const routeConfig = useCurrentRouteConfig();

  if (
    routeConfig?.path !== ROUTE.TradeDesc &&
    routeConfig?.path !== ROUTE.Portfolio &&
    routeConfig?.path !== ROUTE.MyOrders &&
    routeConfig?.path !== ROUTE.HumanCapitalMint &&
    routeConfig?.path !== ROUTE.Wallet
  ) {
    return null;
  }

  return <>{children}</>;
};
