export enum ROUTE {
  TradeDesc = '/',
  Overview = '/overview/:id',
  Portfolio = '/portfolio',
  HumanCapitalMint = '/human-capital-mint',
  Wallet = '/wallet',
  Markets = '/markets',
  News = '/news',
  MyOrders = '/my-orders',
  Account = '/account',
  SignIn = '/signin',
  SignUp = '/signup',
  RestorePassword = '/restore',
  Onboarding = '/onboarding',
  TalentPortal = '/talent-portal',
  InviteRoute = '/invitations/:id',
}
