import { atom } from 'jotai';

import { AthleteI, OpenAthleteCardI } from 'types';

export const selectedTradeItemIAtom = atom<AthleteI | null>(null);
export const openTradeItemDataAtom = atom<OpenAthleteCardI | null>(null);

export const getTradeItemChartOpenStatus = atom((get) => {
  const currentState = get(openTradeItemDataAtom);
  return currentState;
});

export const openTradeItemAtom = atom(null, (get, set, { athlete }: { athlete: AthleteI }) => {
  const currentState = get(openTradeItemDataAtom);

  if (currentState?.id === athlete.id) {
    return;
  }

  set(openTradeItemDataAtom, { id: athlete.id });
  set(selectedTradeItemIAtom, athlete);
});

export const closeTradeItemAtom = atom(null, (get, set) => {
  set(selectedTradeItemIAtom, null);
  set(openTradeItemDataAtom, null);
});
