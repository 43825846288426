import { FC } from 'react';

const TrendingUp: FC<{ color?: string }> = ({ color = 'var(--hcx-icon-primary)' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 12.375L2.25 14.58V8.25H4.5V12.375ZM8.25 10.995L7.0725 9.99L6 10.98V5.25H8.25V10.995ZM12 9.75L9.75 12V2.25H12V9.75ZM14.1075 9.6075L12.75 8.25H16.5V12L15.1575 10.6575L9.75 16.02L7.1475 13.755L4.3125 16.5H2.25L7.1025 11.745L9.75 13.98"
      fill={color}
    />
  </svg>
);

export default TrendingUp;
