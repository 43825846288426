import { createContext } from 'react';

import { OrderBookItemI } from '../order-book.types';

export interface OrderBookWsContextI {
  orderBookAsks: OrderBookItemI[];
  orderBookBids: OrderBookItemI[];
  tradingPairGroupings: number[];
  tradingPairGrouping?: number;
  setTradingPairGrouping: (tradingPaidGrouping: number) => void;
}

export const OrderBookWsContext = createContext<OrderBookWsContextI>({
  orderBookAsks: [],
  orderBookBids: [],
  tradingPairGroupings: [],
  setTradingPairGrouping: () => {},
});
