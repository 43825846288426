import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './sponsor-panel-description.module.scss';

export const SponsorPanelDescription: FC = () => {
  const { t } = useTranslation('sponsor-application');
  return (
    <Box className={styles.box}>
      <Typography variant="body1" className={styles.annotationTextHeading}>
        {t('what-sponsor')}
      </Typography>
      <Typography variant="body2">
        {t('hcx-sponsor-description')}
        <a href="mailto: sponsor@hcx.com" className={styles.emailText}>
          SPONSOR@HCX.COM
        </a>
        {t('or-contact-us-by-phone-at')}
        +18008008000
      </Typography>
    </Box>
  );
};
