import type { ReactNode } from 'react';
import type { TFunction } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { UserAvatar } from 'components/user-avatar';

import {
  normalizeDecimals,
  NormalizationLogicE,
  NormalizationDecimalsE,
  formatNumber,
} from 'utils/number-formatter';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';
import { formatToUSD } from 'utils/currency-formatter';

import { DutchAuctionBid } from 'generated/graphql';

interface UserBidsConfigItemI {
  title: string;
  getContent: (bid: DutchAuctionBid, t: TFunction) => ReactNode;
}

export const userBidsConfig: UserBidsConfigItemI[] = [
  {
    title: 'talent',
    getContent: (bid) => {
      return (
        <Box display="flex" alignItems="center">
          <UserAvatar photo={bid.dutchAuction.athleteContract.athlete.imageUrl} />
          <Box ml={2.5}>
            <Typography variant="body1" whiteSpace="nowrap">
              {bid.dutchAuction.athleteContract.athlete.fullName}
            </Typography>
            <Typography variant="body1">
              {bid.dutchAuction.athleteContract.athlete.nftSymbol}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    title: 'order',
    getContent: (bid, t) => {
      return <Typography variant="body1">{t(bid.orderType)}</Typography>;
    },
  },
  {
    title: 'number-of-shares',
    getContent: (bid) => {
      return <Typography variant="body1">{formatNumber(bid.tokenAmount)}</Typography>;
    },
  },
  {
    title: 'price',
    getContent: (bid) => {
      return (
        <Typography variant="body1">
          {formatToUSD(
            normalizeDecimals(bid.tokenPrice, NormalizationDecimalsE.Six)[
              NormalizationLogicE.NonDecimal
            ]
          )}
        </Typography>
      );
    },
  },
  {
    title: 'type',
    getContent: (bid, t) => {
      return <Typography variant="body1">{t(bid.orderType)}</Typography>;
    },
  },
  {
    title: 'expire',
    getContent: () => {
      return <Typography variant="body1">GTC</Typography>;
    },
  },
  {
    title: 'order-timestamp',
    getContent: (bid) => {
      return (
        <Typography variant="body1">
          {dateFormatter(bid.orderDate, DateFormatE.M_D_YY_HH_MM_SS_UTC)}
        </Typography>
      );
    },
  },
  {
    title: 'status',
    getContent: (bid, t) => {
      return <Typography variant="body1">{t(bid.status)}</Typography>;
    },
  },
  {
    title: 'value',
    getContent: (bid) => {
      return (
        <Typography variant="body1">
          {formatToUSD(
            bid.tokenAmount *
              normalizeDecimals(bid.tokenPrice, NormalizationDecimalsE.Six)[
                NormalizationLogicE.NonDecimal
              ],
            {
              minimumFractionDigits: 2,
            }
          )}
        </Typography>
      );
    },
  },
];
