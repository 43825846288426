import type { FC } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Button } from 'components/button';
import { useTranslation } from 'react-i18next';
import { HorizontalCarousel } from './horizontal-carousel';
import styles from './talent-learn-more.module.scss';
import { useInitTalentApplicationMutation, usePromotedTalentsListQuery } from 'generated/graphql';

export const TalentLearnMore: FC = () => {
  const { t } = useTranslation('talent-application');

  const { data, loading } = usePromotedTalentsListQuery();

  const [initTalentApplication] = useInitTalentApplicationMutation();

  const goPublic = async () => {
    try {
      await initTalentApplication({
        refetchQueries: ['TalentApplicationStatus', 'TalentApplicationProfile'],
      });
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.headerWrapper}>
        <Typography className={styles.heading}>
          {t('are-you-ready-to-maximize-your-future')}
        </Typography>
        <Button variant="contained" size="small" onClick={goPublic}>
          {t('go-public-now')}
        </Button>
      </Box>
      <Box className={styles.contentWrapper}>
        <Box display="flex" mb="20px">
          <Box className={styles.firstParagraph}>
            <Typography variant="subtitle2" className={styles.fontSecondary}>
              {t('human-capital-exchange-platform-designed')}
            </Typography>
            <Typography variant="subtitle1" className={styles.subtitleStile}>
              {t('what-does-it-mean-to-be-a-public-talent')}
            </Typography>
            <Typography variant="subtitle2" className={styles.fontSecondary}>
              {t('when-a-person-goes-public-on-hcx-they-are-entering')}
            </Typography>
            <Typography variant="subtitle2" className={styles.fontSecondary}>
              {t('the-public-person-sells-a-percentage-from-1')}
            </Typography>
            <Typography variant="subtitle2" className={styles.fontSecondary}>
              {t('every-year-on-the-ipo-anniversary-the-public-person')}
            </Typography>
            <Box className={styles.buttonRow}>
              <Button variant="outlined" size="small" disabled onClick={() => {}}>
                Learn More
              </Button>
              <Typography className={styles.colorBlue} onClick={() => {}}>
                Frequently Asked Questions
              </Typography>
            </Box>
          </Box>
          <Box className={styles.videoWarpper}></Box>
        </Box>
        <Typography variant="subtitle1" className={styles.subtitleStile}>
          {t('Why go public?')}
        </Typography>
        <Typography variant="subtitle2" className={styles.fontSecondary}>
          {t('there-are-many-reasons-a-person-would-want-to-go-public')}
        </Typography>
        <Typography variant="subtitle2" className={styles.fontSecondary}>
          {t('the-reality-is-there-are-too-many-reasons-to-list-why-a-person')}
        </Typography>
        <Typography variant="subtitle1" className={styles.subtitleStile}>
          {t('Who’s public now?')}
        </Typography>
        <Typography variant="subtitle2" className={styles.fontSecondary}>
          {t('hcx-is-launching-in-2023-and-has-an-unbeliebale-schdule-of-upcoming')}
        </Typography>
      </Box>
      {loading && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
          <CircularProgress />
        </Box>
      )}
      {data?.promotedTalents && <HorizontalCarousel />}
    </Box>
  );
};
