import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { StatsTablePropsI } from './stats-table.types';
import styles from './stats-table.module.scss';
import { AthleteCareerStatI } from 'types';

const StatsTable: FC<StatsTablePropsI> = ({ title, data, columns, className }) => {
  const { thArray, tdArray } = useMemo(() => {
    const totalData = data.reduce<AthleteCareerStatI>(
      (acc, item) => {
        (Object.keys(item) as Array<keyof AthleteCareerStatI>).forEach((key) => {
          if (key !== 'date' && key !== 'team') {
            acc[key] += item[key] || 0;
          } else {
            acc[key] = item[key] as string;
          }
        });
        return acc;
      },
      {
        gp: 0,
        rec: 0,
        yds: 0,
        avg: 0,
        td: 0,
        fum: 0,
        lst: 0,
        date: '',
        team: '',
      }
    );
    return columns.reduce<{ thArray: ReactNode[]; tdArray: ReactNode[] }>(
      (acc, { title: rowTitle, renderValue, dataKey, jsxKey }) => {
        const key = jsxKey || `${rowTitle}-${dataKey}`;
        acc.thArray.push(<th key={`${key}-th`}>{rowTitle}</th>);
        const resultValue = renderValue ? renderValue(totalData, { styles }) : totalData[dataKey];
        acc.tdArray.push(<td key={`${key}-td`}>{resultValue}</td>);
        return acc;
      },
      {
        thArray: [],
        tdArray: [],
      }
    );
  }, [columns, data]);
  return (
    <table className={classNames(styles.root, className)}>
      <caption>{title}</caption>
      <thead>
        <tr>{thArray}</tr>
      </thead>
      <tbody>
        <tr>{tdArray}</tr>
      </tbody>
    </table>
  );
};

export default StatsTable;
