import type { FC } from 'react';
import { useRef } from 'react';
import { Panel } from 'components/panel';
import { InviteHeader } from '../sponsor-invite-header';
import { InviteForm } from '../sponsor-invite-form';
import { Box, Slide } from '@mui/material';
import styles from './sponsor-invite.module.scss';

export interface InvitePropsI {
  viewConfig?: boolean;
  closePanel: () => void;
}

export const InvitePanel: FC<InvitePropsI> = ({ viewConfig, closePanel }) => {
  const containerRef = useRef(null);

  return (
    <Box ref={containerRef} className={styles.rootContainer}>
      <Slide
        direction="left"
        in={viewConfig}
        container={containerRef.current}
        timeout={400}
        easing={{
          enter: 'linear',
          exit: 'linear',
        }}
      >
        <Panel className={styles.root}>
          <InviteHeader openActivityPanel={closePanel} />
          <Box className={styles.sectionWrapper}>
            <InviteForm />
          </Box>
        </Panel>
      </Slide>
    </Box>
  );
};
