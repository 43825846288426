import type { FC } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';
import { useAuth } from '../../auth/use-auth';
import { ROUTE } from '../../routes/enums';
import { Navigate } from 'react-router-dom';
import { AuthFormTemplate } from './auth-form-template';
import { useTranslation } from 'react-i18next';
import { PasswordAuthDataI } from 'auth';
import { Snackbar } from 'components/snackbar';
import { LoggedOutAuthContextI } from 'auth/auth-context';
import { isDomainORG } from './utils';

import classNames from 'classnames';
import styles from './styles.module.scss';

export const SignIn: FC = () => {
  const auth = useAuth() as LoggedOutAuthContextI;
  const onSubmit = useCallback(
    (data: PasswordAuthDataI) => {
      auth.logIn(data);
    },
    [auth]
  );
  const { t } = useTranslation('auth');
  const publicDomain = isDomainORG();

  useEffect(() => auth.clearError, [auth.clearError]);

  if (auth.isLoggedIn) {
    return <Navigate to={ROUTE.TradeDesc} />;
  }

  return (
    <>
      <AuthFormTemplate
        disableValidation
        onSubmit={onSubmit}
        onGoogleAuthClick={auth.logInViaGoogle}
        middlePart={
          <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2} mb={2}>
            <Typography className={styles.terms}>
              <Link to={ROUTE.RestorePassword} className={styles.link}>
                {t('forgot-password')}
              </Link>
            </Typography>
          </Box>
        }
        submitButtonText={t('login')}
        bottomPart={
          <Typography className={classNames(styles.textLine, styles.mt4)}>
            {t('no-account')}&nbsp;
            {!publicDomain && (
              <Link to={ROUTE.SignUp} className={styles.link}>
                {t('register')}
              </Link>
            )}
          </Typography>
        }
      />
      <Snackbar open={Boolean(auth.error)} message={auth.error} />
    </>
  );
};
