import { string, number, object, mixed, StringSchema } from 'yup';
import { COUNTRIES_LIST } from '../features/talent-application/countries';
import { RequiredNumberSchema } from 'yup/lib/number';

export const talentFormSchema: Record<
  string,
  StringSchema<string | null | undefined> | RequiredNumberSchema<number | undefined>
> = {
  streetAddress: string().required(),
  apartment: string().required(),
  city: string().required(),
  state: string().required(),
  zip: number().required(),
  country: string()
    .test('is-included', 'Country is not in range', function (value: string | undefined) {
      return Object.values(COUNTRIES_LIST).includes(value as string);
    })
    .required('Please fill out this field'),
  ssn: string().required(),
  emailAddress: string().email().required(),
  placeOfBirthCity: string().required(),
  placeOfBirthState: string().required(),
  placeOfBirthCountry: string().required(),
  passportPhotoUrl: string().required(),
  profilePictureUrl: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  middleName: string().required(),
  dateOfBirth: string().nullable().required(),
  sex: string().required(),
  nationality: string().required(),
  maritalStatus: string().required(),
  resumeUrl: object()
    .nullable()
    .shape({
      file: mixed().test('fileSize', 'Resume maximum allowed size is 5MB', (value) => {
        if (!value.length) return false;
        return value[0].size <= 5000;
      }),
    })
    .required(),
  //resumeFileName: string().required(),
  linkedinProfileUrl: string().url(
    'Please, provide url like: https://www.linkedin.com/in/__Your_Profile__'
  ),
  // 'https://www.linkedin.com/in/',
  // 'Please, provide url like: https://www.linkedin.com/in/__Your_Profile__'
  description: string().required(),
  backgroundCheckAuthorization: string().required(),
  condition: string().required(),
};

export const talentEmergencyReferenceSchema: Record<
  string,
  StringSchema<string | null | undefined> | RequiredNumberSchema<number | undefined>
> = {
  firstName: string().required(),
  lastName: string().required(),
  emailAddress: string().email().required(),
  phone: string().required(),
  relationship: string().required(),
};
