import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ArrowBack } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

const mr = { marginRight: 2 };

export const TitleRow: FC<{ onBack?: () => void; title: string }> = ({ onBack, title }) => (
  <Box display="flex" alignItems="center" height="40px">
    {onBack && (
      <IconButton onClick={onBack} sx={mr}>
        <ArrowBack />
      </IconButton>
    )}
    <Typography variant="subtitle1">{title}</Typography>
  </Box>
);
