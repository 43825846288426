import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlertBoxIcon } from 'components/icons/alert-box-icon';
import { Button } from 'components/button';
import {
  TalentApplicationLaunchSection,
  TalentApplicationLaunchStateSection,
  TalentApplicationStateQuery,
  useApproveTalentApplicationLaunchMutation,
  useTalentApplicationLaunchQuery,
  useTalentApplicationStateQuery,
  useUpdateTalentApplicationLaunchSectionMutation,
} from 'generated/graphql';
import styles from './tab-launch.module.scss';
import { InputField } from 'components/input-field';
import moment from 'moment';

const isTalentCheckboxDisabled = (section: string, state: TalentApplicationStateQuery) => {
  const isHCXProfileFilled =
    state?.talentApplication.state.profile?.personalProfile &&
    state?.talentApplication.state.profile?.emergencyContact &&
    state?.talentApplication.state.profile?.references &&
    state?.talentApplication.state.profile?.resume &&
    state?.talentApplication.state.profile?.authorizationCheck &&
    state?.talentApplication.state.profile?.verification;
  //state?.talentApplication.state.profile?.socialMedia && //TODO: unlock when will be ready
  switch (section) {
    case 'profile':
      return !!isHCXProfileFilled;
    case 'profession':
      return !!state?.talentApplication.state.profession;
    case 'sponsorship':
      return !!state?.talentApplication.state.sponsors;
    case 'financialModel':
      return !!state?.talentApplication.state.financialModels;
    case 'offeringParameters':
      return !!state?.talentApplication.state.offeringParameters;
    case 'legal':
      return !!state?.talentApplication.state.legalClauses;
    default:
      return false;
  }
};

const LAUNCH_SECTIONS: Record<string, string> = {
  profile: TalentApplicationLaunchSection.Profile,
  profession: TalentApplicationLaunchSection.Profession,
  sponsorship: TalentApplicationLaunchSection.Sponsorship,
  financialModel: TalentApplicationLaunchSection.FinancialModel,
  offeringParameters: TalentApplicationLaunchSection.OfferingParameters,
  legal: TalentApplicationLaunchSection.Legal,
};

export const TabLaunch = () => {
  const { t } = useTranslation('talent-application');

  const { data: launchData } = useTalentApplicationLaunchQuery();

  const [updateLaunchSection] = useUpdateTalentApplicationLaunchSectionMutation();

  const updateLaunch = async (section: string, checked: boolean) => {
    const sectionType = section as TalentApplicationLaunchSection;
    try {
      await updateLaunchSection({
        variables: { section: sectionType, checked },
        refetchQueries: ['TalentApplicationLaunch', 'TalentApplicationState'],
      });
    } catch (err) {
      console.warn(err);
    }
  };

  const { data: talentAppState } = useTalentApplicationStateQuery();

  const isLaunchTalentFilled =
    Object.values(launchData?.talentApplication.launch.state.talent || []).filter(
      (it) => it !== true
    ).length <= 1;

  const isTabsFilled =
    talentAppState?.talentApplication.state.profile?.personalProfile &&
    talentAppState?.talentApplication.state.profile?.emergencyContact &&
    talentAppState?.talentApplication.state.profile?.references &&
    talentAppState?.talentApplication.state.profile?.resume &&
    talentAppState?.talentApplication.state.profile?.authorizationCheck &&
    talentAppState?.talentApplication.state.profile?.verification &&
    //talentAppState?.talentApplication.state.profile?.socialMedia && //TODO: unlock when will be ready
    talentAppState?.talentApplication.state.profession &&
    talentAppState?.talentApplication.state.sponsors &&
    talentAppState?.talentApplication.state.financialModels &&
    talentAppState?.talentApplication.state.offeringParameters &&
    talentAppState?.talentApplication.state.legalClauses;

  const isDisabled = isLaunchTalentFilled && !!isTabsFilled;

  const handleCheck = async (section: string) => {
    try {
      await updateLaunch(
        LAUNCH_SECTIONS[section],
        !launchData?.talentApplication.launch.state.talent[
          section as keyof TalentApplicationLaunchStateSection
        ]
      );
    } catch (err) {
      console.warn(err);
    }
  };

  const [approveTalentApplicationLaunch] = useApproveTalentApplicationLaunchMutation();

  const approveTalentLaunch = async () => {
    try {
      await approveTalentApplicationLaunch({ refetchQueries: ['TalentApplicationState'] });
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <Box p={2}>
      <Box display="flex" gap="10px">
        <AlertBoxIcon fontSize="small" />
        <Typography className={styles.heading}>
          {t('acknowledgements-needed-for-launch')}
        </Typography>
      </Box>
      <Typography className={styles.description}>
        {t('before-we-can-publicly-announce-your-ipo')}
      </Typography>
      <Typography variant="body1">{t('offering-approvals')}</Typography>
      <Table sx={{ width: 'fit-content' }}>
        <TableHead>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell width="175px" align="left"></TableCell>
            <TableCell width="50px" align="center">
              <Typography>{t('talent')}</Typography>
            </TableCell>
            <TableCell width="50px" align="center">
              <Typography>{t('sponsor')}</Typography>
            </TableCell>
            <TableCell width="50px" align="center">
              <Typography>{t('hcx')}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(LAUNCH_SECTIONS).map((section) => {
            return (
              <TableRow key={section} sx={{ borderBottom: 0 }}>
                <TableCell align="left" sx={{ borderBottom: 0, padding: 0 }}>
                  <Typography>{t(section)}</Typography>
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 0, padding: 0 }}>
                  <Checkbox
                    size="small"
                    id={section + '.talent'}
                    disabled={talentAppState && !isTalentCheckboxDisabled(section, talentAppState)}
                    checked={
                      !!launchData?.talentApplication.launch.state.talent[
                        section as keyof TalentApplicationLaunchStateSection
                      ]
                    }
                    onChange={() => handleCheck(section)}
                  />
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 0, padding: 0 }}>
                  <Checkbox
                    disabled
                    size="small"
                    id={section + '.sponsor'}
                    checked={
                      !!launchData?.talentApplication.launch.state.sponsor[
                        section as keyof TalentApplicationLaunchStateSection
                      ]
                    }
                  />
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 0, padding: 0 }}>
                  <Checkbox
                    disabled
                    size="small"
                    id={section + '.hcx'}
                    checked={
                      !!launchData?.talentApplication.launch.state.hcx[
                        section as keyof TalentApplicationLaunchStateSection
                      ]
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box className={styles.approvals}>
        <Box className={styles.approvalsRow}>
          <Typography variant="body1" width="390px">
            {`${t('i-m')}, ${launchData?.talentApplication.launch.approvals.talent.approvedBy}, ${t(
              'approve-to-launch-my-hcx-offering'
            )}`}
          </Typography>
          <Typography variant="body2">{t('initial')}:</Typography>
          <InputField
            className={styles.initialInput}
            disabled
            value={
              launchData?.talentApplication.launch.approvals.talent.approvedBy
                .slice(0, 2)
                .toUpperCase() || ''
            }
          />
          {launchData?.talentApplication.launch.approvals.talent.approved ? (
            <Typography variant="body2">
              {`${t('approved-by')} ${
                launchData?.talentApplication.launch.approvals.talent.approvedBy
              } ${t('on')} ${moment(
                launchData?.talentApplication.launch.approvals.talent.approvedAt
              ).format('DD/MM/YYYY h:mm a')}`}
            </Typography>
          ) : (
            <Button
              disabled={!isDisabled}
              variant="contained"
              size="small"
              onClick={approveTalentLaunch}
            >
              Approve
            </Button>
          )}
        </Box>
        <Box className={styles.approvalsRow}>
          <Typography variant="body1" width="390px">
            {`${t('the')}, ${
              launchData?.talentApplication.launch.approvals.sponsor.approvedBy || '"Sponsor"'
            }, ${t('approves-this-hcx-offering')}`}
          </Typography>
          <Typography variant="body2">{t('initial')}:</Typography>
          <InputField className={styles.initialInput} disabled />
          {launchData?.talentApplication.launch.approvals.sponsor.approved ? (
            <Typography variant="body2">
              {`${t('approved-by')} ${
                launchData?.talentApplication.launch.approvals.sponsor.approvedBy
              } ${t('on')} ${moment(
                launchData?.talentApplication.launch.approvals.sponsor.approvedAt
              ).format('DD/MM/YYYY h:mm a')}`}
            </Typography>
          ) : (
            <Button variant="contained" size="small" disabled>
              Approve
            </Button>
          )}
        </Box>
        <Box className={styles.approvalsRow}>
          <Typography variant="body1" width="390px">
            {`${t('the')}, ${
              launchData?.talentApplication.launch.approvals.hcx.approvedBy ||
              '"HCF Representative"'
            }, ${t('approves-this-hcx-offering')}`}
          </Typography>
          <Typography variant="body2">{t('initial')}: </Typography>
          <InputField className={styles.initialInput} disabled />
          {launchData?.talentApplication.launch.approvals.hcx.approved ? (
            <Typography variant="body2">
              {`${t('approved-by')} ${
                launchData?.talentApplication.launch.approvals.hcx.approvedBy
              } ${t('on')} ${moment(
                launchData?.talentApplication.launch.approvals.hcx.approvedAt
              ).format('DD/MM/YYYY h:mm a')}`}
            </Typography>
          ) : (
            <Button variant="contained" size="small" disabled>
              Approve
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
