import type { FC } from 'react';
import { useCallback } from 'react';
import { PanelNameE } from 'pages/trade-desk/types';
import { Box, useMediaQuery } from '@mui/material';
import { useAtom } from 'jotai';
import { ResizableCell, ResizableCol } from 'components/resizable-grid';
import { Panel } from 'components/panel';
import { PortfolioSnapshot } from 'pages/trade-desk/portfolio-snapshot';
import { Trending } from 'pages/trade-desk/trending';
import { Orders } from 'pages/trade-desk/orders';
import { Favorites } from 'pages/trade-desk/favorites';
import { selectedAthleteIAtom } from 'store/athletes.store';
import { SideBarWrapper } from 'components/layout/sidebar-wrapper';
import { enabledPanelsHeaderButtons } from 'store/trading-stats.store';
import { ROUTE } from 'routes/enums';

import styles from './right-side-bar.module.scss';
import { useCurrentRouteConfig } from 'hooks/useCurrentRouteConfig';

const DEFAULT_SIZES = {
  snapshot: 43,
  trending: 57,
};

const SECOND_COL_DEFAULT_SIZES = {
  trending: 100,
};

export const RightSideBar: FC = () => {
  const [enabledPanels, setEnabledPanels] = useAtom(enabledPanelsHeaderButtons);
  const routeConfig = useCurrentRouteConfig();
  const onCloseWidget = useCallback(
    (panel: PanelNameE) => () => {
      setEnabledPanels((prev) => prev.filter((item) => item !== panel));
    },
    [setEnabledPanels]
  );

  const [athleteCard] = useAtom(selectedAthleteIAtom);

  const isBigScreen = useMediaQuery('(min-width: 1800px)');
  const isMediumScreen = useMediaQuery('(max-width: 1400px)');
  const isHCXCardOpened = athleteCard?.id;

  const shouldHideFirstCol =
    isBigScreen &&
    enabledPanels.includes(PanelNameE.Trending) &&
    (!enabledPanels.includes(PanelNameE.Snapshot) ||
      !enabledPanels.includes(PanelNameE.Orders) ||
      !enabledPanels.includes(PanelNameE.Favorites));

  const shouldShowFirstCol =
    enabledPanels.includes(PanelNameE.Snapshot) ||
    enabledPanels.includes(PanelNameE.Orders) ||
    enabledPanels.includes(PanelNameE.Favorites) ||
    (enabledPanels.includes(PanelNameE.Trending) && !shouldHideFirstCol);

  if (
    enabledPanels.length === 0 ||
    (routeConfig?.path !== ROUTE.TradeDesc &&
      routeConfig?.path !== ROUTE.Portfolio &&
      routeConfig?.path !== ROUTE.MyOrders &&
      routeConfig?.path !== ROUTE.HumanCapitalMint &&
      routeConfig?.path !== ROUTE.Wallet)
  ) {
    return null;
  }

  return (
    <SideBarWrapper>
      <Box className={styles.root}>
        {shouldShowFirstCol && (!!isHCXCardOpened || !isMediumScreen) && (
          <ResizableCol id="first-col" defaultSizes={DEFAULT_SIZES} className={styles.column}>
            {enabledPanels.includes(PanelNameE.Snapshot) && (
              <ResizableCell id="snapshot" key="snapshot" className={styles.snapshot}>
                <Panel>
                  <PortfolioSnapshot onCloseWidget={onCloseWidget(PanelNameE.Snapshot)} />
                </Panel>
              </ResizableCell>
            )}
            {enabledPanels.includes(PanelNameE.Orders) && (
              <ResizableCell id="orders" key="orders" className={styles.orders}>
                <Panel>
                  <Orders onCloseWidget={onCloseWidget(PanelNameE.Orders)} />
                </Panel>
              </ResizableCell>
            )}
            {enabledPanels.includes(PanelNameE.Favorites) && (
              <ResizableCell id="favorites" key="favorites" className={styles.favorites}>
                <Panel>
                  <Favorites onCloseWidget={onCloseWidget(PanelNameE.Favorites)} />
                </Panel>
              </ResizableCell>
            )}
            {!isBigScreen && enabledPanels.includes(PanelNameE.Trending) && (
              <ResizableCell id="trending" key="trending" className={styles.trending}>
                <Panel>
                  <Trending onCloseWidget={onCloseWidget(PanelNameE.Trending)} />
                </Panel>
              </ResizableCell>
            )}
          </ResizableCol>
        )}
        {isBigScreen && enabledPanels.includes(PanelNameE.Trending) && (
          <ResizableCol
            id="second-col"
            defaultSizes={SECOND_COL_DEFAULT_SIZES}
            className={styles.column}
          >
            <ResizableCell id="trending" key="trending" className={styles.trending}>
              <Panel>
                <Trending onCloseWidget={onCloseWidget(PanelNameE.Trending)} />
              </Panel>
            </ResizableCell>
          </ResizableCol>
        )}
      </Box>
    </SideBarWrapper>
  );
};
