import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const HcxCircleIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="198" height="198" viewBox="0 0 198 198">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99 184.8C146.386 184.8 184.8 146.386 184.8 99C184.8 51.614 146.386 13.2 99 13.2C51.614 13.2 13.2 51.614 13.2 99C13.2 146.386 51.614 184.8 99 184.8ZM99 198C153.676 198 198 153.676 198 99C198 44.3238 153.676 0 99 0C44.3238 0 0 44.3238 0 99C0 153.676 44.3238 198 99 198Z"
      fill="#1B202D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.043 56.1353L138.927 56.1001H133.169L73.2077 141.897L73.2061 141.9H79.1047L79.1063 141.897L139.043 56.1353ZM123.889 66.224H117.99L65.1047 141.897L65.103 141.9H71.0016L71.0033 141.897L123.889 66.224ZM112.779 62.083H118.678L62.8986 141.897L62.897 141.9L57 141.897L112.779 62.083ZM97.2942 81.0846L81.0357 56.1001H58.864L86.5006 96.5291L97.2942 81.0846ZM100.211 114.856L111.032 99.3724L140.16 141.9H117.989L100.211 114.856Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.4106 81.0846L81.1522 56.1001H58.9805L86.617 96.5291L97.4106 81.0846Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.148 99.3724L100.326 114.856L118.104 141.9H140.275L111.148 99.3724Z"
      fill="white"
    />
  </SvgIcon>
);
