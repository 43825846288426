import { useState, useCallback } from 'react';

export const useTalentPortalWelcomeModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleIsOpen = useCallback(() => setIsOpen((prev) => !prev), []);

  const onClose = useCallback(() => setIsOpen(false), []);

  const onOpen = useCallback(() => setIsOpen(true), []);

  return { isOpen, toggleIsOpen, onClose, onOpen };
};
