import type { FC } from 'react';
import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';

import { useTimeDiffString } from './offering-grid.hooks';

import { AuctionRowDataI } from '../active-mints-table/types';

import styles from './grid.module.scss';

interface OfferingGridPropsI {
  auctionData: AuctionRowDataI;
}

const offeringDetailsTitles = [
  { title: 'Sponsor', key: 'sponsor' },
  { title: 'Filed Date', key: 'filedDate' },
  { title: 'Minimum Total IHO Proceeds', key: 'minimumProceeds' },
  { title: 'Auction Time Remaining', key: 'auctionTimeRemaining' },
  { title: 'Minimum $ per Token', key: 'minimumPerToken' },
  { title: 'Revenue share %', key: 'revenueShare' },
  { title: 'Total Tokens', key: 'totalTokens' },
];

export const OfferingGrid: FC<OfferingGridPropsI> = ({ auctionData }) => {
  const [remainingTime] = useTimeDiffString(auctionData.endDate, auctionData.mintingStatus);
  const gridDataModel = useMemo(() => {
    return Object.values({
      sponsor: 'XYZ Sponsor',
      filedDate: auctionData.startDate.toLocaleString(),
      minimumTotalProceeds: formatToUSD(auctionData.minimumTotalIhoProceeds),
      auctionTimeRemaining: remainingTime,
      lowestPrice: formatToUSD(auctionData.lowestPrice),
      revenue: `${auctionData.revenuePercentage}%`,
      totalTokens: formatNumber(auctionData.fractions),
    });
  }, [auctionData, remainingTime]);

  return (
    <div className={styles.root}>
      {offeringDetailsTitles.map(({ title, key }, i) => (
        <Box key={key} className={i === 4 ? styles.spanRow : ''}>
          <Typography variant="body2">{title}</Typography>
          <Typography variant="body1">{gridDataModel[i]}</Typography>
        </Box>
      ))}
    </div>
  );
};
