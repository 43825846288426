import { FC } from 'react';
import { Panel } from 'components/panel';
import IcoCcoRegisterEntry from './iho-cho-register-entry';
import styles from './iho-cho-register.module.scss';
import { IhoChoRegisterEntryI } from '../interfaces';
import { IHOStatusE, IHOUnderwriterStatusE } from '../enums';
import { Divider } from '@mui/material';

const MOCK_ENTRIES: Array<IhoChoRegisterEntryI> = [
  {
    title: 'IHO',
    filedDate: '05/01/2020',
    entryData: {
      uwStatus: IHOUnderwriterStatusE.Completed,
      scheduledDate: '05/05/2022',
      minPerToken: 98,
      totalTokens: 100000,
      minIhoProceeds: 100,
      status: IHOStatusE.Success,
    },
  },
  {
    title: 'IHO',
    filedDate: '05/01/2020',
    entryData: {
      uwStatus: IHOUnderwriterStatusE.Completed,
      scheduledDate: '05/05/2022',
      minPerToken: 98,
      totalTokens: 100000,
      minIhoProceeds: 100,
      status: IHOStatusE.Pending,
    },
  },
  {
    title: 'IHO',
    filedDate: '05/01/2020',
    entryData: {
      uwStatus: IHOUnderwriterStatusE.Completed,
      scheduledDate: '05/05/2022',
      minPerToken: 98,
      totalTokens: 100000,
      minIhoProceeds: 100,
      status: IHOStatusE.Scheduled,
    },
  },
];

const IhoChoRegister: FC = () => {
  const cancleOrder = () => {
    //
    // @emittedBy <IcoCcoRegisterEntry>
    //
    // handle logic for cancling order
  };

  return (
    <Panel className={styles.root}>
      <span className={styles.title}>Register New CHO</span>
      <div>
        {MOCK_ENTRIES.map((entry: IhoChoRegisterEntryI, index) => (
          <>
            <IcoCcoRegisterEntry
              title={entry.title}
              filedDate={entry.filedDate}
              entryData={entry.entryData}
              cancleOrder={cancleOrder}
              key={`${entry.title} ${entry.filedDate}`}
            />
            {index !== MOCK_ENTRIES.length - 1 && <Divider className={styles.divider} />}
          </>
        ))}
      </div>
    </Panel>
  );
};

export default IhoChoRegister;
