import { useAtom } from 'jotai';
import type { FC } from 'react';
import { useMemo, useEffect } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { useRenderToHeaderTradingInfo } from 'components/layout/trading-info/header-trading-info';
import { Panel } from 'components/panel';
import { ResizableCol, ResizableCell } from 'components/resizable-grid';
import { TVChartContainer } from 'components/tv-chart-container';
import { HCXCardContainer } from 'features/hcx-card';
import { closeAthleteCardAtom } from 'store/athletes.store';

import { News } from './news';
import { OrderBook } from './order-book';
import { useOverviewPage } from './overview.hooks';
import { OverviewChartHeader } from './overview-chart-header/overview-chart-header';

import styles from './overview.module.scss';
import { useTranslation } from 'react-i18next';

const DEFAULT_GRID_SIZES = {
  chart: 67,
  'bottom-row': 33,
};

export const Overview: FC = () => {
  const { loading, loadedId, contractId, tradingPairId } = useOverviewPage();
  const { t } = useTranslation('overview');
  const [, closeAthleteCard] = useAtom(closeAthleteCardAtom);

  const { clear: clearHeader } = useRenderToHeaderTradingInfo(
    null,
    useMemo(() => <Typography variant="body1">{t('talent-details')}</Typography>, [t])
  );

  useEffect(() => {
    closeAthleteCard(null);
    return () => {
      clearHeader();
      closeAthleteCard(null);
    };
  }, [clearHeader, closeAthleteCard]);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
        <CircularProgress />
      </Box>
    );
  }
  if (!loadedId || !contractId) return null;

  return (
    <Box className={styles.container}>
      <ResizableCol id="overview" defaultSizes={DEFAULT_GRID_SIZES} className={styles.resizable}>
        <ResizableCell id="chart" className={styles.chartRow}>
          <Panel className={styles.chartContainer}>
            <TVChartContainer
              className={styles.chart}
              disableLeftToolbar
              customHeader={(chartWidgetRef) => (
                <OverviewChartHeader chartWidgetRef={chartWidgetRef} />
              )}
              symbol={contractId}
              tradingPairId={tradingPairId}
            />
          </Panel>
        </ResizableCell>
        <ResizableCell id="bottom-row" className={styles.bottomRow}>
          <Panel className={styles.customPanel}>
            <News />
          </Panel>
          <Panel className={styles.customPanel}>
            <OrderBook tradingPairId={tradingPairId} />
          </Panel>
        </ResizableCell>
      </ResizableCol>
      <Panel className={styles.card}>
        <HCXCardContainer disableClose />
      </Panel>
    </Box>
  );
};
