import type { FC } from 'react';

import { TalentPortalTradeDeskChart, TalentPortalTradeDeskRanking } from './components';

import styles from './talent-portal-trade-desk.module.scss';

export const TalentPortalTradeDesk: FC = () => {
  return (
    <div className={styles.root}>
      <TalentPortalTradeDeskChart />
      <TalentPortalTradeDeskRanking />
    </div>
  );
};
