import type { FC, SyntheticEvent } from 'react';
import { Panel } from 'components/panel';
import { useState, useMemo, useEffect } from 'react';
import { AddWalletCard } from 'features/wallet-card';
import { Button } from '../../components/button';
import { Tabs } from '../../components/tabs';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useTranslation } from 'react-i18next';
import { WalletTab } from './wallets-tab';
import { saveConnectedWallet } from '../../blockchain-api/ewallets-api/connected-wallets-storage';
import { Typography } from '@mui/material';
import { useRenderToHeaderTradingInfo } from 'components/layout/trading-info/header-trading-info';

import styles from './wallet.module.scss';

const TAB_LABELS: string[] = ['wallets', 'deposits', 'withdrawals', 'transactions'];

export const Wallet: FC = () => {
  const [viewIndex, setViewIndex] = useState<number>(0);
  const [addWalletCardOpen, setAddWalletCardOpen] = useState(false);
  const { t } = useTranslation('wallet-page');

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setViewIndex(newValue);
  };

  const { clear: clearHeader } = useRenderToHeaderTradingInfo(
    null,
    useMemo(() => <Typography variant="body1">{t('wallet')}</Typography>, [t])
  );
  useEffect(() => () => clearHeader(), [clearHeader]);

  return (
    <>
      <Panel className={styles.content}>
        <div className={styles.topLine}>
          <Tabs
            className={styles.tabs}
            value={viewIndex}
            onChange={handleChange}
            indicatorClassName={styles.indicatorSpan}
            labels={TAB_LABELS}
            tabStyle={styles.tab}
            transformLabel={t}
          />
          <div>
            <Button
              onClick={() => setAddWalletCardOpen(true)}
              variant="contained"
              size="small"
              className={styles.addWallet}
            >
              {t('add-wallet')}
            </Button>
            <IconButton>
              <FileDownloadOutlinedIcon />
            </IconButton>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
          </div>
        </div>
        {viewIndex === 0 && <WalletTab />}
      </Panel>
      {addWalletCardOpen && (
        <AddWalletCard
          onClose={() => setAddWalletCardOpen(false)}
          onConnect={(wallet) => {
            saveConnectedWallet(wallet);
            setAddWalletCardOpen(false);
          }}
        />
      )}
    </>
  );
};
