import type { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTE } from 'routes/enums';
import {
  useAcceptSponsorUserInvitationMutation,
  useDeclineSponsorUserInvitationMutation,
} from 'generated/graphql';

export const InviteRoute: FC = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const action = urlParams.get('action');
  const token = urlParams.get('token');

  const [acceptSponsorUserInvitationMutation] = useAcceptSponsorUserInvitationMutation();

  const [declineSponsorUserInvitationMutation] = useDeclineSponsorUserInvitationMutation();

  if (action === 'accept' && token) {
    try {
      acceptSponsorUserInvitationMutation({
        variables: { token },
      }).then((data) => console.log('accept', data.data));
    } catch (error) {
      console.log(error);
    }
  }

  if (action === 'decline' && token) {
    try {
      declineSponsorUserInvitationMutation({ variables: { token } }).then((data) =>
        console.log('decline', data.data)
      );
    } catch (error) {
      console.log(error);
    }
  }

  return <Navigate to={ROUTE.TradeDesc} replace />;
};
