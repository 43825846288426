import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Table, TableHead, TableBody, Typography, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

import { TableCell, TableHeadCell, TableHeadRow, TableRow } from 'components/table';
import { BidOrderStatus, DutchAuctionBid } from 'generated/graphql';

import { userOrdersConfig } from './user-orders-config';
import { userBidsConfig } from './user-bids-config';

import type { OrderT } from 'types/orders';
import { OrderStatusE } from 'enums';

const TABLE_HEAD_CELLS = [
  { key: 'talent' },
  { key: 'order' },
  { key: 'number-of-shares' },
  { key: 'price' },
  { key: 'type' },
  { key: 'expire' },
  { key: 'order-timestamp' },
  { key: 'status' },
  { key: 'value' },
];

const arrowStyles = { width: '20px', height: '20px' };

interface MyOrdersTablePropsI {
  userOrders: OrderT[];
  userBids: DutchAuctionBid[];
}

export const MyOrdersTable: FC<MyOrdersTablePropsI> = memo(({ userOrders, userBids }) => {
  const { t } = useTranslation('orders');

  return (
    <Table>
      <TableHead>
        <TableHeadRow>
          {TABLE_HEAD_CELLS.map((cell) => (
            <TableHeadCell key={cell.key}>
              <Box height="40px" display="flex" alignItems="flex-end">
                <Typography variant="caption" display="block">
                  {t(cell.key)}
                </Typography>
                <ArrowDropDown sx={arrowStyles} />
              </Box>
            </TableHeadCell>
          ))}
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {userOrders.map((order) => (
          <TableRow key={order.id}>
            {userOrdersConfig.map(({ title, getContent }) => (
              <TableCell key={`${order.id}-${title}`}>{getContent(order, t)}</TableCell>
            ))}
            <TableCell>
              {order.status === OrderStatusE.Open && (
                <IconButton>
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
        {userBids.map((bid) => (
          <TableRow key={`${bid.blockHash}-${bid.orderDate}`}>
            {userBidsConfig.map(({ title, getContent }) => (
              <TableCell key={`${title}-${bid.bidderAddress}-${bid.orderDate}`}>
                {getContent(bid, t)}
              </TableCell>
            ))}
            <TableCell>
              {bid.status === BidOrderStatus.Open && (
                <IconButton>
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});
