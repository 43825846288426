import { useMemo, useEffect, useCallback, useLayoutEffect, useState } from 'react';

import { useAuctionAllBidsLazyQuery, useDutchAuctionStatsByIdQuery } from 'generated/graphql';

import { constructModel, getUserBids } from './helpers';

interface UserBidDataI {
  userBidPrice: number;
  userBidAmount: number;
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const useBidsAndStats = (dutchAuctionId: string) => {
  const [userBidData, setUserBidData] = useState<UserBidDataI>({
    userBidPrice: 0,
    userBidAmount: 0,
  });
  const [getUpdatedBids, { data: bidsData }] = useAuctionAllBidsLazyQuery({
    variables: { auctionId: Number(dutchAuctionId) },
    fetchPolicy: 'cache-and-network',
  });

  const { data: dutchAuctionStats } = useDutchAuctionStatsByIdQuery({
    variables: { input: { id: dutchAuctionId } },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    getUpdatedBids().then();
  }, [getUpdatedBids]);

  useEffect(() => {
    if (bidsData) {
      getUserBids(bidsData).then((userBids) => {
        if (userBids.length === 0) {
          setUserBidData({ userBidPrice: 0, userBidAmount: 0 });
        } else {
          const { tokenPrice, tokenAmount } = userBids[0];
          setUserBidData({ userBidPrice: tokenPrice, userBidAmount: tokenAmount });
        }
      });
    }
  }, [bidsData]);

  const handleUpdateUserBid = useCallback(() => {
    getUpdatedBids().then();
  }, [getUpdatedBids]);

  const chartData = useMemo(() => {
    return bidsData ? constructModel(bidsData.bids) : [];
  }, [bidsData]);

  const { thresholdPrice } = useMemo(() => {
    if (dutchAuctionStats?.dutchAuctions.data[0]?.stats) {
      return { thresholdPrice: dutchAuctionStats.dutchAuctions.data[0].stats.thresholdPrice };
    }
    return {
      thresholdPrice: 0,
    };
  }, [dutchAuctionStats]);

  const bidCount = useMemo(() => {
    if (bidsData) {
      return bidsData.bids.length;
    }
    return 0;
  }, [bidsData]);

  return {
    chartData,
    thresholdPrice,
    userBidData,
    bidCount,
    handleUpdateUserBid,
  };
};
