import { useMemo } from 'react';
import { useHCXFinancialCardListResizerContext } from '../hcx-financial-card-list-resizer';

const minCardWidth = 505;

const getMinWidth = (value: number) => {
  const divider = !value ? 1 : value;
  return +(minCardWidth / divider).toFixed(2);
};

export const useDynamicHCXFinancialCardListStyle = () => {
  const { divider } = useHCXFinancialCardListResizerContext();
  return useMemo(
    () => ({
      gridTemplateColumns: !divider
        ? ''
        : `repeat(auto-fit, minmax(min(100%, ${getMinWidth(divider)}px), 1fr))`,
    }),
    [divider]
  );
};
