import type { FC } from 'react';

import { OrderBookWsContextProvider } from '../../order-book-ws-context';
import { OrderBookWarning } from '../order-book-warning/order-book-warning';
import { OrderBookTable } from './order-book-table/order-book-table';

import styles from './order-book-view.module.scss';
import { OrderBookGroupings } from '../order-book-groupings/order-book-groupings';

interface OrderBookViewI {
  tradingPairId: string;
}

export const OrderBookView: FC<OrderBookViewI> = ({ tradingPairId }) => {
  return (
    <div className={styles.root}>
      <OrderBookWsContextProvider tradingPairId={tradingPairId}>
        <OrderBookWarning />
        <OrderBookGroupings />
        <div className={styles.ordersWrapper}>
          <OrderBookTable orderType="bid" />
          <OrderBookTable orderType="asc" />
        </div>
      </OrderBookWsContextProvider>
    </div>
  );
};
