import { ApolloClient, InMemoryCache } from '@apollo/client';

import { clientLink } from './link';
import { typeDefs } from './type-defs';

export const apolloClient = new ApolloClient({
  link: clientLink,
  cache: new InMemoryCache(),
  typeDefs,
});
