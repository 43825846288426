import { SupportedProvider } from '@0x/subproviders';
import { BigNumber } from '@0x/utils';
import { ERC20TokenContract } from '@0x/contract-wrappers';

import { config } from 'config';

interface RequestApprovePropsI {
  provider: SupportedProvider;
  contractAddress: string;
  maker: string;
  amount: BigNumber;
}

export const requestApprove = async (data: RequestApprovePropsI) => {
  const erc20Token = new ERC20TokenContract(data.contractAddress, data.provider);
  return erc20Token
    .approve(config.zeroXExchangeProxyAddress, data.amount)
    .sendTransactionAsync({ from: data.maker });
};
