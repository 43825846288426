import { TabPanel } from 'components/tab-panel';
import { FC } from 'react';
import { useTalentPortalTabsContext } from './talent-portal-tabs.context';
import { TABS } from './talent-portal-tabs.helpers';

import styles from './talent-portal-tabs.module.scss';

const TalentPortalTabContent: FC = () => {
  const { tab } = useTalentPortalTabsContext();
  return (
    <>
      {TABS.map(({ label, component: Component }, idx) => {
        return (
          <TabPanel
            key={label}
            value={tab}
            index={idx}
            wrapperClassName={styles.height100}
            className={styles.height100}
          >
            {Component ? <Component /> : null}
          </TabPanel>
        );
      })}
    </>
  );
};

export default TalentPortalTabContent;
