import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectChangeEvent } from '@mui/material';

import { Select } from 'components/select';
import { MenuItem } from 'components/select/menu-item';

import { useOrderBookWsContext } from '../../order-book-ws-context';

import styles from './order-book-groupings.module.scss';

export const OrderBookGroupings: FC = () => {
  const { t } = useTranslation('overview');
  const { tradingPairGroupings, setTradingPairGrouping, tradingPairGrouping } =
    useOrderBookWsContext();

  const onChange = (event: SelectChangeEvent) => {
    setTradingPairGrouping(+event.target.value);
  };

  return (
    <div className={styles.root}>
      <span className={styles.label}>{t('order-book-grouping')}:</span>
      <Select<string>
        variant="filled"
        value={`${tradingPairGrouping || tradingPairGroupings[0] || ''}`}
        onChange={onChange}
      >
        {tradingPairGroupings.map((groupingId) => (
          <MenuItem key={groupingId} value={groupingId}>
            {groupingId}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
