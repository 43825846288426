import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const ActivityPanelIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M9 15C12.3 15 15 12.3 15 9C15 5.7 12.3 3 9 3C5.7 3 3 5.7 3 9C3 12.3 5.7 15 9 15ZM9 1.5C13.125 1.5 16.5 4.875 16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5ZM9.375 9.6L5.775 11.7L5.25 10.65L8.25 8.925V5.25H9.375V9.6Z"
      fill="#ACACAC"
    />
  </SvgIcon>
);
