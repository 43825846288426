import type { FC } from 'react';

import { ROUTE } from '../../../routes/enums';
import {
  HumanCapitalMintIcon,
  OrdersIcon,
  PortfolioIcon,
  TradeDeskIcon,
  WalletIcon,
} from '../../design/navigation';
import { TradeDesk } from '../../../pages/trade-desk';
import { Portfolio } from '../../../pages/portfolio';
import { MyOrdersApollo } from '../../../pages/my-orders';
import { HumanCapitalMint } from '../../../pages/human-capital-mint';
import { Wallet } from '../../../pages/wallet';

interface PropsI {
  className?: string;
  color?: string;
}

interface AppMenuItemI {
  path: ROUTE;
  element?: FC;
  Icon: FC<PropsI>;
  title: string;
}

export const appMenuItemsConfig: AppMenuItemI[] = [
  {
    path: ROUTE.TradeDesc,
    element: TradeDesk,
    Icon: TradeDeskIcon,
    title: 'Trade Desk',
  },
  {
    path: ROUTE.Portfolio,
    element: Portfolio,
    Icon: PortfolioIcon,
    title: 'My Portfolio',
  },
  {
    path: ROUTE.MyOrders,
    element: MyOrdersApollo,
    Icon: OrdersIcon,
    title: 'My Orders',
  },
  {
    path: ROUTE.HumanCapitalMint,
    element: HumanCapitalMint,
    Icon: HumanCapitalMintIcon,
    title: 'Human Capital Auction',
  },
  {
    path: ROUTE.Wallet,
    element: Wallet,
    Icon: WalletIcon,
    title: 'Wallet',
  },
];
