import Web3 from 'web3';

import { AbiType } from 'ethereum-types';
import { AbiItem } from 'web3-utils';

import { config } from 'config';
import { getWalletProvider } from './connect-wallet';
import { WalletE } from './types';

const BALANCE_ABI: AbiItem[] = [
  {
    type: AbiType.Function,
    name: 'balanceOf',
    inputs: [{ name: '_owner', type: 'address' }],
    outputs: [{ name: 'balance', type: 'uint256' }],
  },
];

const DECIMALS_ABI = {
  inputs: [],
  name: 'decimals',
  outputs: [{ name: '', type: 'uint8' }],
  type: AbiType.Function,
};

export const getBalance = async (wallet: WalletE) => {
  const provider = await getWalletProvider(wallet);
  const accounts = await provider.request({
    method: 'eth_requestAccounts',
  });
  const currentAccount = accounts[0];
  const minABI = [BALANCE_ABI, DECIMALS_ABI];
  const web3 = new Web3(provider);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore // TODO: VOV: minABI to be defined with correct typings
  const contract = new web3.eth.Contract(minABI, config.stableCoinAddress);
  const balance = await contract.methods.balanceOf(currentAccount).call();
  const decimals = await contract.methods.decimals().call();

  return balance / Math.pow(10, decimals);
};

export const getAthleteTokenBalance = async (tradingPairTokenAddress: string, wallet: WalletE) => {
  const provider = await getWalletProvider(wallet);
  const accounts = await provider.request({
    method: 'eth_requestAccounts',
  });
  const currentAccount = accounts[0];
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(BALANCE_ABI, tradingPairTokenAddress);
  const balance = await contract.methods.balanceOf(currentAccount).call();

  // @Precondition: Athlete tokens always have 2 decimals
  return balance / Math.pow(10, 2);
};
