import type { FC, SyntheticEvent } from 'react';
import { useRef, useState, useCallback } from 'react';
import { Panel } from 'components/panel';
import { useAtom } from 'jotai';
import { Box, Slide } from '@mui/material';
import { invitePanel } from 'store/sponsor-management.store';
import { InvitePanel } from './sponsor-invite';
import { SponsorManagementHeader } from './sponsor-management-header';
import { sponsorManagementView } from 'store/sponsor-management.store';
import { ActivityPanel } from 'components/activity-panel';
import { SponsorActivityListType } from 'generated/graphql';
import { Tabs } from 'components/tabs';
import { useTranslation } from 'react-i18next';
import { TAB_ROUTE } from './management-tabs-config';
import styles from './sponsor-management.module.scss';

export const SponsorManagement: FC = () => {
  const [activityView, setActivityView] = useState(false);
  const [inviteView, setInviteView] = useAtom(invitePanel);
  const [isOpened] = useAtom(sponsorManagementView);
  const [viewIndex, setViewIndex] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => setViewIndex(newValue);

  const { t } = useTranslation('sponsor-management');

  const closeInvite = useCallback(() => setInviteView(false), [setInviteView]);
  const openInvite = useCallback(() => {
    setActivityView(false);
    setInviteView(true);
  }, [setInviteView]);
  const closeActivity = useCallback(() => setActivityView(false), []);
  const openActivityPanel = useCallback(() => {
    setInviteView(false);
    setActivityView(true);
  }, [setInviteView]);

  const wrapperRef = useRef(null);

  const Component = TAB_ROUTE[viewIndex].component;

  return (
    <>
      {activityView && (
        <ActivityPanel
          viewConfig={activityView}
          closeActivityPanel={closeActivity}
          sponsorActivityType={SponsorActivityListType.SponsorUserActivity}
        />
      )}
      {inviteView && <InvitePanel viewConfig={inviteView} closePanel={closeInvite} />}
      <Slide
        direction="left"
        in={isOpened}
        container={wrapperRef.current}
        timeout={300}
        easing={{
          enter: 'ease-out',
          exit: 'linear',
        }}
      >
        <Panel className={styles.root}>
          <Box className={styles.header}>
            <SponsorManagementHeader
              openInvite={openInvite}
              openActivity={openActivityPanel}
              viewIndex={viewIndex}
            />
            <Tabs
              labels={TAB_ROUTE.map((item) => item.name)}
              value={viewIndex}
              onChange={handleChange}
              indicatorClassName={styles.indicatorSpan}
              tabsStyles={styles.tabStyles}
              tabStyle={styles.tab}
              transformLabel={t}
            />
          </Box>
          <Box className={styles.content}>
            <Component />
          </Box>
        </Panel>
      </Slide>
    </>
  );
};
