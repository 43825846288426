import type { FC } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Table, TableHead, Typography, TableBody, CircularProgress } from '@mui/material';
import { TableHeadCell, TableHeadRow, TableRow, TableCell } from 'components/table';
import { MenuDropdown } from './menu-dropdown';
import { SelectPermission } from './select-permission';
import styles from './tab-members-permission.module.scss';
import { Maybe, SponsorUser, SponsorUserStatus } from 'generated/graphql';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMembersPermissionsList } from '../sponsor-management.hooks';
import { Paginator } from 'components/paginator';

const TABLE_TITLES = ['email', 'name', 'phone', 'role', 'status', ''];

const COUNTER_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const TabMembersPermission: FC = () => {
  const { t } = useTranslation('sponsor-management');

  const { userList, refetchUserList, sort, setSortDirection, handlePaginationChange, queryPaging } =
    useMembersPermissionsList();

  const clickHandler = async () => {
    await refetchUserList();
  };

  return (
    <Box>
      <Typography className={styles.description}>
        {t('members-will-be-notified-automatically')}
      </Typography>
      {userList?.sponsorUserList.data ? (
        <Table>
          <TableHead>
            <TableHeadRow>
              {TABLE_TITLES.map((heading) => {
                return (
                  <TableHeadCell key={heading} className={styles.headCell}>
                    <Box className={styles.cellWrapper} onClick={() => setSortDirection(heading)}>
                      <Typography className={styles.textHeading}>{t(heading)}</Typography>
                      {heading === sort.field_name &&
                        (sort.direction ? <ArrowDropDown /> : <ArrowDropUp />)}
                    </Box>
                  </TableHeadCell>
                );
              })}
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {userList.sponsorUserList.data.map((member: Maybe<SponsorUser>) => {
              return (
                <TableRow
                  key={member?.userId}
                  className={classNames(styles.tableRowBody, {
                    [styles.tableRowDisabled]: member?.status === SponsorUserStatus.Disabled,
                  })}
                >
                  <TableCell className={styles.tableCell}>
                    <Typography variant="body1">{member?.email}</Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Typography variant="body1">{member?.name}</Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Typography variant="body1">{member?.phone}</Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <SelectPermission
                      permission={member?.role}
                      userId={member?.userId}
                      status={member?.status}
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Typography>{member?.status}</Typography>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <MenuDropdown status={member?.status} userId={member?.userId} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}
      <Box className={styles.footer}>
        <Paginator
          pagination={{
            limit: queryPaging.limit,
            total: userList?.sponsorUserList.pagination?.total || 0,
            offset: queryPaging.offset,
          }}
          className={styles.paginator}
          handlePaginationChange={handlePaginationChange}
          rowsPerPageOptions={COUNTER_LIST}
          refreshList={clickHandler}
        />
      </Box>
    </Box>
  );
};
