import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Paginator } from 'components/paginator';
import { SponsorCompany } from './sponsor-company';
import { useTalentSponsorsList } from './tab-sponsor.hooks';
import { AttentionMessage } from '../talent-attention-message';
import { useTalentApplicationStateQuery } from 'generated/graphql';
import styles from './tab-sponsor.module.scss';

const COUNTER = [1, 2, 3, 4, 5, 6, 7, 8];

export const TabSponsor = () => {
  const { t } = useTranslation('talent-application');

  const { sponsorList, refetch, handlePaginationChange, queryPaging } = useTalentSponsorsList();

  const clickHandler = async () => {
    await refetch();
  };

  const { data: talentAppState } = useTalentApplicationStateQuery();

  const isPreviousTabsCompleted =
    talentAppState?.talentApplication.state.profile?.personalProfile &&
    talentAppState?.talentApplication.state.profile?.emergencyContact &&
    talentAppState?.talentApplication.state.profile?.references &&
    talentAppState?.talentApplication.state.profile?.resume &&
    //talentAppState?.talentApplication.state.profile?.socialMedia && //TODO: when social will be
    talentAppState?.talentApplication.state.profession &&
    talentAppState?.talentApplication.state.profile?.authorizationCheck &&
    talentAppState?.talentApplication.state.profile?.verification;

  return (
    <Box className={styles.contentWrapper}>
      {!!isPreviousTabsCompleted || <AttentionMessage state="alert" />}
      <Box className={styles.description}>
        <Typography variant="subtitle2" component="span" className={styles.textSecondary}>
          {t('prospective-public-candidates-work-with-an-hcx-sponsor')}
        </Typography>
        <Link variant="subtitle2" component="i" href="#">
          {t('more-details-on-sponsors-can-be-found-here')}
        </Link>
      </Box>
      <Box className={styles.bodyWrapper}>
        {sponsorList?.talentApplication.sponsors.data ? (
          sponsorList?.talentApplication.sponsors.data.map((sponsor) => (
            <SponsorCompany
              key={sponsor?.id}
              id={sponsor?.id}
              logo={sponsor?.logoUrlForDarkBackground}
              name={sponsor?.name}
              description={sponsor?.description}
              emailAddress={sponsor?.emailAddress}
              phoneNumber={sponsor?.phoneNumber}
              level={sponsor?.level}
              status={sponsor?.status}
              disabled={!isPreviousTabsCompleted}
            />
          ))
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Box className={styles.footerPaginator}>
        <Paginator
          pagination={{
            limit: queryPaging.limit,
            offset: queryPaging.offset,
            total: sponsorList?.talentApplication.sponsors.pagination?.total || 0,
          }}
          handlePaginationChange={handlePaginationChange}
          refreshList={clickHandler}
          rowsPerPageOptions={COUNTER}
          className=""
        />
      </Box>
    </Box>
  );
};
