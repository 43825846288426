export enum WidgetProgressE {
  Currency,
  Percentage,
}

export enum WidgetSortE {
  Desc,
  Asc,
}

export enum WidgetCardSizeE {
  Small,
  Medium,
  Large,
}
