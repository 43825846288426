import { useAtom } from 'jotai';
import classNames from 'classnames';
import type { FC } from 'react';
import { useState } from 'react';

import { Box, Typography } from '@mui/material';

import {
  AthleteCardGradient,
  AthleteCardContainer,
  AthleteCardResultsLine,
} from 'components/athlete/athlete-card';
import { AthleteCardPriceLineApollo } from 'components/athlete/athlete-card/athlete-card-price-line/athlete-card-price-line.apollo';
import { TabPanel } from 'components/tab-panel';
import { TVChartContainer } from 'components/tv-chart-container';
import { AthleteBidStatusE } from 'enums';
import { AthleteI } from 'types';
import { selectedAthleteIAtom } from 'store/athletes.store';

import { HCXCardHeader } from './hcx-card-header';
import {
  useHCXCardCurrentTab,
  useHCXCardTabLabelsByBidStatus,
  RotatedContext,
} from './hcx-card.hooks';
import { getHCXCardDefaultTabByView, TABS } from './hcx-card.helpers';

import styles from './hcx-card.module.scss';

export interface HCXCardPropsI {
  id: AthleteI['id'];
  bidStatus: AthleteBidStatusE;
  disableClose?: boolean;
}

export const HCXCard: FC<{
  athlete: AthleteI;
  disableClose?: boolean;
}> = ({ athlete, disableClose }) => {
  const [tabIdx, setTabIdx] = useHCXCardCurrentTab(getHCXCardDefaultTabByView);
  const tabLabels = useHCXCardTabLabelsByBidStatus(athlete.tradingInfo.bidStatus);
  const [rotated, setRotated] = useState(false);

  const [, setSelectedAthlete] = useAtom(selectedAthleteIAtom);

  const closeCard = () => {
    setSelectedAthlete(null);
  };

  const toggleRotated = () => {
    setRotated((prev) => !prev);
  };

  return (
    <Box id="hcx-card" className={styles.root}>
      <AthleteCardGradient>
        <HCXCardHeader
          tabLabels={tabLabels}
          currentTabIdx={tabIdx}
          onTabChange={setTabIdx}
          onClose={!disableClose ? closeCard : undefined}
          onRotate={toggleRotated}
        />
        <Box className={classNames(styles.rotationContainer, rotated && styles.rotated)}>
          <Box className={styles.front}>
            <AthleteCardContainer athlete={athlete} />
          </Box>
          <Box className={styles.back}>
            <Typography className={styles.role}>{athlete.career.title || ''}</Typography>
            <AthleteCardContainer
              athlete={athlete}
              className={styles.athleteCard}
              variant="small"
            />
            <TVChartContainer
              className={styles.chart}
              disableTopToolbar
              disableLeftToolbar
              symbol={athlete.tradingInfo.contractId}
              tradingPairId={athlete.tradingInfo.tradingPairId}
            />
          </Box>
        </Box>
      </AthleteCardGradient>
      {!rotated && <AthleteCardResultsLine />}
      <AthleteCardPriceLineApollo athlete={athlete} className={rotated ? styles.priceLine : ''} />
      <RotatedContext.Provider value={rotated}>
        <Box className={styles.tabView}>
          {TABS.map(({ component: Component }, idx) => {
            return (
              <TabPanel
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                value={tabIdx}
                index={idx}
                wrapperClassName={styles.tabPanelWrapper}
                className={styles.tabPanel}
              >
                {Component ? <Component /> : null}
              </TabPanel>
            );
          })}
        </Box>
      </RotatedContext.Provider>
    </Box>
  );
};
