import classNames from 'classnames';
import type { FC } from 'react';

import { AthleteI } from 'types';
import { AthleteCardTooltip } from 'components/athlete/athlete-card-tooltip';

import { Ticker } from './ticker';

import styles from '../ticker-bar.module.scss';

export interface TickerAthletePropsI {
  athlete: AthleteI;
}

export const TickerAthlete: FC<TickerAthletePropsI> = ({ athlete }) => (
  <AthleteCardTooltip athlete={athlete} placement="top-start">
    <div className={classNames(styles.athleteItemWrapper)}>
      <Ticker
        symbol={athlete.tradingInfo.contractId}
        gain={athlete.tradingInfo.lastPriceProgress / 100}
      />
    </div>
  </AthleteCardTooltip>
);
