import type { FC } from 'react';
import { useState, useMemo } from 'react';

import { defaultContextData, OrdersWidgetContextI, OrdersListContext } from './orders-list-context';

import type { OrderT } from 'types/orders';
import { Order, useOrdersByUserIdQuery } from 'generated/graphql';
import { OrderStatusE } from 'enums';

export const OrdersWidgetContextProvider: FC = ({ children }) => {
  const { data } = useOrdersByUserIdQuery({
    variables: { input: {} },
    fetchPolicy: 'network-only',
  });
  const [filter, setFilter] = useState(defaultContextData.filter);

  const orders: OrderT[] = useMemo(() => {
    if (data) {
      return data.orders.data
        .filter((order): order is Order => !!order)
        .map((order) => ({ ...order, status: OrderStatusE.Open }));
    }
    return [];
  }, [data]);

  const filteredOrders: OrderT[] = useMemo(() => {
    if (orders) {
      return filter === 'all' ? orders : orders.filter((item) => item.status === filter);
    }
    return [];
  }, [orders, filter]);

  const contextValue: OrdersWidgetContextI = useMemo(
    () => ({
      orders,
      filteredOrders,
      filter,
      setFilter,
    }),
    [orders, filteredOrders, filter]
  );

  return <OrdersListContext.Provider value={contextValue}>{children}</OrdersListContext.Provider>;
};
