import type { FC } from 'react';
import { useAtom } from 'jotai';
import { talentActivityView } from 'store/talent-application.store';
import { ActivityPanel } from 'components/activity-panel';

export const TalentActivity: FC = () => {
  const [activityView, setActivityView] = useAtom(talentActivityView);

  if (!activityView) {
    return null;
  }

  return (
    <ActivityPanel viewConfig={activityView} closeActivityPanel={() => setActivityView(false)} />
  );
};
