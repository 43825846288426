import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from 'components/button';
import styles from './empty-state-block.module.scss';

interface EmptyStateBlockPropsI {
  description: string;
  icon: ReactNode;
  actionBtnTitle: string;
  onActionBtnClick: () => void;
  className?: string;
}

const EmptyStateBlock: FC<EmptyStateBlockPropsI> = ({
  icon,
  description,
  actionBtnTitle,
  onActionBtnClick,
  className,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.circle}>{icon}</div>
      <p className={styles.description}>{description}</p>
      <Button
        variant="contained"
        size="small"
        className={styles.actionBtn}
        onClick={onActionBtnClick}
      >
        {actionBtnTitle}
      </Button>
    </div>
  );
};

export default EmptyStateBlock;
