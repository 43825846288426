import { DutchAuctionStatus } from 'generated/graphql';

import styles from './active-mints-table.module.scss';

export const MINTING_CHIP_CLASSNAME = {
  [DutchAuctionStatus.Active]: styles.active,
  [DutchAuctionStatus.Scheduled]: styles.scheduled,
  [DutchAuctionStatus.Closed]: styles.closed,
};

export const MINTING_STATUS_TRANSLATE = {
  [DutchAuctionStatus.Active]: 'active',
  [DutchAuctionStatus.Scheduled]: 'scheduled',
  [DutchAuctionStatus.Closed]: 'closed',
};
