import { FC } from 'react';
import { AthleteBidStatusE, HCXCardViewE } from 'enums';
import { HCXBuySell } from './hcx-card-buy_sell';
import { HCXCardOffering } from './hcx-card-offering';
import { HCXSummary } from './hcx-card-summary';
import { GetDefaultTabByViewT } from './hcx-card.hooks';

export const TABS: { label: string; component: FC | null }[] = [
  { label: 'summary', component: HCXSummary },
  { label: 'buy-sell', component: HCXBuySell },
  { label: AthleteBidStatusE.Offering, component: HCXCardOffering },
];

export const tabLabels = TABS.map(({ label }) => label);

export const getHCXCardDefaultTabByView: GetDefaultTabByViewT = (view) => {
  switch (view) {
    case HCXCardViewE.Info:
    case HCXCardViewE.Stats:
    case HCXCardViewE.Activity:
    case HCXCardViewE.Finance:
      return 0;
    case HCXCardViewE.Buy:
    case HCXCardViewE.Sell:
    case HCXCardViewE.BuySellOrders:
      return 1;
    case HCXCardViewE.Terms:
    case HCXCardViewE.Bid:
    case HCXCardViewE.BidOrders:
      return 2;
    default:
      return 0;
  }
};
