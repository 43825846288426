import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { Divider, Typography, Box } from '@mui/material';

import { Panel } from 'components/panel';
import { UserAvatar } from 'components/user-avatar';
import { OfferingGrid } from './offering-grid';
import { SummaryGrid } from './summary-grid';

import { AthleteI } from 'types';
import { AuctionRowDataI } from '../active-mints-table/types';
import { DutchAuctionStatus } from 'generated/graphql';

import styles from './active-mints-athlete-card.module.scss';

interface ActiveMintsAthleteCardPropsI {
  athlete: AthleteI;
  auctionData: AuctionRowDataI;
  className?: string | boolean;
}

export const ActiveMintsAthleteCard: FC<ActiveMintsAthleteCardPropsI> = ({
  athlete,
  auctionData,
  className,
}) => {
  const MINTING_CHIP_CLASSNAME = {
    [DutchAuctionStatus.Active]: styles.active,
    [DutchAuctionStatus.Scheduled]: styles.scheduled,
    [DutchAuctionStatus.Closed]: styles.closed,
  };

  const { t } = useTranslation('human-capital-mint');

  return (
    <Panel className={classNames(styles.root, className)}>
      <Typography variant="subtitle2" className={styles.title}>
        IHO - Filed {auctionData.startDate.toLocaleDateString()}
      </Typography>
      <Box className={styles.athleteWrapper}>
        <UserAvatar photo={athlete.bio.photo} name={athlete.bio.name} size={60} />
        <Box className={styles.nameWrapper}>
          <Typography variant="subtitle1">{athlete.bio.name.split('-')[0]}</Typography>
          <Typography variant="subtitle1" className={styles.contractId}>
            {athlete.tradingInfo.contractId}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.firstRowWrapper}>
        <Typography variant="body2" className={styles.offeringDetails}>
          {t('offering-details')}
        </Typography>
        <Box className={styles.firstRowStatusWrapper}>
          <Typography variant="body2">
            Status:{' '}
            <span className={MINTING_CHIP_CLASSNAME[auctionData.mintingStatus]}>
              {auctionData.mintingStatus}
            </span>
          </Typography>
        </Box>
      </Box>
      <OfferingGrid auctionData={auctionData} />
      <Divider className={styles.divider} />
      <Typography variant="body2" className={styles.auctionSummary}>
        {t('auction-summary')}
      </Typography>
      <SummaryGrid auctionData={auctionData} />
    </Panel>
  );
};
