import Web3 from 'web3';

import { AbiType } from 'ethereum-types';
import { AbiItem } from 'web3-utils';

import { getWalletProvider, WalletE } from 'blockchain-api/ewallets-api';
import { config } from 'config';

import { ApproveTargetE, getApproveTarget } from './helpers';

const APPROVE_ABI: AbiItem[] = [
  {
    type: AbiType.Function,
    name: 'approve',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
  },
];

export const approveERC20 = async (wallet: WalletE, target: ApproveTargetE) => {
  const provider = await getWalletProvider(wallet);
  const accounts = await provider.request({
    method: 'eth_requestAccounts',
  });
  const currentAccount = accounts[0];
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(APPROVE_ABI, config.stableCoinAddress);

  return contract.methods
    .approve(getApproveTarget()[target], 100000000000)
    .send({ from: currentAccount })
    .on('sending', (payload: unknown) => {
      console.log('===> Sending fired ', payload);
    })
    .on('sent', (payload: unknown) => {
      console.log('===> Sent fired ', payload);
    })
    .on('transactionHash', (hash: string) => {
      console.log('===> Hash fired ', hash);
    })
    .on('receipt', (receipt: unknown) => {
      console.log('===> Receipt received ', receipt);
    })
    .on('error', (error: Error) => {
      console.log(error);
    });
};
