import { FC, SetStateAction, useCallback } from 'react';
import { useState } from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './select-permission.module.scss';
import classNames from 'classnames';
import {
  SponsorUser,
  SponsorUserRole,
  SponsorUserStatus,
  useChangeSponsorUserRoleMutation,
} from 'generated/graphql';
import { useAtom } from 'jotai';
import { storeSponsorUserList } from 'store/sponsor-management.store';

const PERMISSION_LIST: SponsorUserRole[] = [
  SponsorUserRole.Owner,
  SponsorUserRole.Admin,
  SponsorUserRole.Manager,
  SponsorUserRole.Member,
];

export const SelectPermission: FC<{
  permission?: SponsorUserRole;
  userId?: number;
  status?: SponsorUserStatus;
}> = ({ permission, userId, status }) => {
  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);

  const [, setUserList] = useAtom(storeSponsorUserList);

  const isOwner = permission === SponsorUserRole.Owner;

  const isDisabled = status === SponsorUserStatus.Disabled;

  const handleClick = (event: {
    currentTarget: SetStateAction<Element | ((element: Element) => Element) | null | undefined>;
  }) => {
    if (isOwner || isDisabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const [changeSponsorUserRoleMutation] = useChangeSponsorUserRoleMutation();

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleCheckPermission = async (newRole: SponsorUserRole) => {
    try {
      await changeSponsorUserRoleMutation({
        variables: { userId: Number(userId), role: newRole },
      });
    } catch (err) {
      console.log(err);
      return;
    }
    setUserList((prev) => ({
      ...prev,
      data: prev.data.map((user) => {
        if (user?.userId === userId) {
          return { ...(user as SponsorUser), role: newRole };
        }
        return user;
      }),
    }));
    handleClose();
  };

  return (
    <>
      <Box
        className={classNames(styles.permissionWrapper, {
          [styles.noPointer]: isOwner || isDisabled,
        })}
        onClick={handleClick}
      >
        <Typography>{permission}</Typography>
        {!(isOwner || isDisabled) && <ExpandMoreIcon className={styles.arrowExpand} />}
      </Box>
      <Menu
        id="permission-list"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        className={styles.menuList}
      >
        {PERMISSION_LIST.filter((it) => it !== permission).map((perm) => {
          return (
            <MenuItem
              key={perm}
              onClick={() => handleCheckPermission(perm)}
              className={styles.menuItem}
            >
              {perm}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
