import { useAtom } from 'jotai';

import { selectedAthleteIAtom } from 'store/athletes.store';

import { HCXCardSummaryTabComponentT } from '../hcx-card-summary.helpers';
import { HCXCardSummaryInfo } from './hcx-card-summary-info';

export const HCXCardSummaryInfoContainer: HCXCardSummaryTabComponentT = ({ setTab }) => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  if (!athlete?.id) {
    return null;
  }

  return <HCXCardSummaryInfo athlete={athlete} setTab={setTab} />;
};
