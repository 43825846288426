import type { FC } from 'react';
import { useState } from 'react';

import { Tab, Tabs, TabsProps } from '@mui/material';

import { Panel } from 'components/panel';
import { TabPanel } from 'components/tab-panel';
import { a11yProps } from 'utils/a11y';

import { tabLabels, TABS } from './talent-portal-trade-desk-ranking.helpers';

import styles from './talent-portal-trade-desk-ranking.module.scss';

export const TalentPortalTradeDeskRanking: FC = () => {
  const [tab, setTab] = useState(1);

  const handleChange: TabsProps['onChange'] = (_, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Panel className={styles.root}>
      <div className={styles.header}>
        <span className={styles.title}>Ranking as Talent</span>
        <Tabs value={tab} onChange={handleChange} aria-label="talent-portal-tabs">
          {tabLabels.map((label, idx) => (
            <Tab key={label} label={label} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </div>
      <div className={styles.content}>
        {TABS.map(({ label, component: Component }, idx) => {
          return (
            <TabPanel
              key={label}
              value={tab}
              index={idx}
              wrapperClassName={styles.hw100}
              className={styles.hw100}
            >
              {Component ? <Component /> : null}
            </TabPanel>
          );
        })}
      </div>
    </Panel>
  );
};
