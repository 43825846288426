import { WalletE } from './types';

const checkGlobalVar = (varName: string, error: string) => {
  if (!(varName in window)) {
    throw new Error(error);
  }
};

export const getMetamaskProvider = () => {
  const noMetaMaskError = 'You need to install metamask browser extension';
  checkGlobalVar('ethereum', noMetaMaskError);
  // eslint-disable-next-line
  const ethereum = (window as any).ethereum;
  // eslint-disable-next-line
  const provider = ethereum.providers?.find((p: any) => p.isMetaMask);
  if (provider) {
    return provider;
  }
  // eslint-disable-next-line
  if (ethereum.isMetaMask) {
    return ethereum;
  }
  throw new Error(noMetaMaskError);
};

export const connectMetamask = async () => {
  return getMetamaskProvider().enable();
};

export const connectExodus = async () => {
  checkGlobalVar('ethereum', 'You need to install exodus browser extension');
  // eslint-disable-next-line
  return (window as any).exodus.solana.connect();
};

export const getCoinbaseProvider = () => {
  const noCoinbaseError = 'You need to install coinbase browser extension';
  checkGlobalVar('ethereum', noCoinbaseError);
  // eslint-disable-next-line
  const provider = (window as any).ethereum.providers?.find((p: any) => 'isCoinbaseWallet' in p);
  if (provider) {
    return provider;
  }
  throw new Error(noCoinbaseError);
};

export const connectCoinbase = () => getCoinbaseProvider().enable();

const connectWalletFunctions = {
  [WalletE.Metamask]: connectMetamask,
  [WalletE.Coinbase]: connectCoinbase,
};

const getProviderFunctions = {
  [WalletE.Metamask]: getMetamaskProvider,
  [WalletE.Coinbase]: getCoinbaseProvider,
};

export const connectWallet = (wallet: WalletE) => connectWalletFunctions[wallet]();
export const getWalletProvider = (wallet: WalletE) => getProviderFunctions[wallet]();
