import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Theme,
  ThemeProvider,
  Typography,
  createTheme,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import { Button } from 'components/button';
import { AccordionSection } from 'components/accordion-section';
import { useTranslation } from 'react-i18next';
import { JsonForms } from '@jsonforms/react';
import { materialRenderers } from '@jsonforms/material-renderers';
import {
  useTalentTypesQuery,
  TalentType,
  useUpdateTalentApplicationProfessionMutation,
  useTalentTypeConfigurationLazyQuery,
  useTalentApplicationProfessionDetailsQuery,
} from 'generated/graphql';
import { SelectField } from 'components/select-field';
import { useAtom } from 'jotai';
import { MessageAtom } from 'store/message-alert.store';
import styles from './profession-form.module.scss';

export const ProfessionForm = () => {
  const { t } = useTranslation('talent-application');
  const [expandProfSection, setExpandProfSection] = useState(true);
  const handleChangeExpand = () => setExpandProfSection((prev) => !prev);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState([]);
  const [, setMessage] = useAtom(MessageAtom);

  const {
    data: professionDetails,
    loading: professionDetailsLoading,
    updateQuery: updateFormDetails,
  } = useTalentApplicationProfessionDetailsQuery();

  const [queryTalentConfig, { data: talentConfig, loading: talentConfigLoading }] =
    useTalentTypeConfigurationLazyQuery();

  const talentType = professionDetails?.talentApplication.profession.occupation.talentType;

  const details = professionDetails?.talentApplication.profession.occupation.details;

  const dropProfession = (event: SelectChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const value = target.value as TalentType;
    updateFormDetails(() => ({
      talentApplication: {
        profession: {
          occupation: {
            talentType: value,
            details: {},
          },
        },
      },
    }));
  };

  useEffect(() => {
    if (talentType) {
      queryTalentConfig({
        variables: {
          talentType,
        },
      }).catch(console.warn);
    }
  }, [queryTalentConfig, talentType]);

  const [updateProfession] = useUpdateTalentApplicationProfessionMutation();

  const handleSubmit = useCallback(async () => {
    try {
      if (talentType) {
        const res = await updateProfession({
          variables: {
            parameters: { occupation: { talentType, details: formData } },
          },
          refetchQueries: ['TalentApplicationState'],
        });
        if (res.data?.updateTalentApplicationProfession === 'ok') {
          setMessage({ message: 'Successfully send', type: 'success' });
        }
      }
    } catch (err) {
      console.warn(err);
    }
  }, [formData, setMessage, talentType, updateProfession]);

  const { data: talentTypes } = useTalentTypesQuery();

  return (
    <Box>
      <AccordionSection
        expand={expandProfSection}
        handleChangeExpanded={handleChangeExpand}
        summary={<Typography variant="body1">{t('professional-occupation')}</Typography>}
      >
        <Typography className={styles.headingLabel}>
          {'Tell us about your professional occupation'}
        </Typography>
        <SelectField
          value={talentType || ''}
          menuItems={talentTypes?.talentTypes || []}
          className={styles.selectType}
          onChangeHandler={(event) => {
            dropProfession(event);
            setFormData({});
          }}
        />
        {(professionDetailsLoading && talentTypes && talentType && <CircularProgress />) ||
          (talentConfigLoading && talentTypes && talentType && <CircularProgress />)}
        {talentType && talentType !== ('' as TalentType) && (
          <Box width="70%" display="flex" flexDirection="column">
            <ThemeProvider
              theme={(theme: Theme) =>
                createTheme({
                  ...theme,
                  components: {
                    ...theme.components,
                    MuiInputAdornment: {
                      styleOverrides: {
                        root: {
                          backgroundColor: 'transparent',
                        },
                      },
                    },
                  },
                })
              }
            >
              {talentConfig && (
                <JsonForms
                  schema={talentConfig?.talentTypeConfiguration.jsonSchema}
                  uischema={talentConfig?.talentTypeConfiguration.uiSchema}
                  config={{
                    trim: false, //if 'false' fields width:'100%'
                    hideRequiredAsterisk: true, //if 'true' hide label asterics
                  }}
                  data={details}
                  renderers={materialRenderers}
                  onChange={({ data, errors }) => {
                    setFormData(data);
                    setFormErrors(errors as []);
                  }}
                  validationMode={'ValidateAndHide'} //ignore UI styles if necessary remove or change 'ValidateAndShow', 'ValidateAndHide', 'NoValidation'
                />
              )}
            </ThemeProvider>
            <Button
              className={styles.submit}
              onClick={handleSubmit}
              disabled={!!formErrors.length}
              variant="contained"
              size="small"
            >
              {t('submit-form')}
            </Button>
          </Box>
        )}
      </AccordionSection>
    </Box>
  );
};
