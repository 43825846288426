import { FC, useState, useMemo, useCallback, Context } from 'react';
import { WidgetProgressE, WidgetSortE, WidgetCardSizeE } from '../menu-icon';
import { defaultContextData, WidgetContextI } from './widget-context';

const WidgetContextProvider: FC<{ context: Context<WidgetContextI>; hideSizer?: boolean }> = ({
  children,
  context: WidgetContext,
  hideSizer,
}) => {
  const [{ cardSize, sort, progressType }, setContextData] = useState(defaultContextData);

  const changeProgressType = useCallback((newProgressType: WidgetProgressE) => {
    setContextData((prev) => ({
      ...prev,
      progressType: newProgressType,
    }));
  }, []);

  const changeSortType = useCallback((newSortType: WidgetSortE) => {
    setContextData((prev) => ({
      ...prev,
      sort: newSortType,
    }));
  }, []);

  const changeCardSize = useCallback((newCardSize: WidgetCardSizeE) => {
    setContextData((prev) => ({
      ...prev,
      cardSize: newCardSize,
    }));
  }, []);

  const contextValue = useMemo(
    () => ({
      cardSize,
      progressType,
      sort,
      setContextData,
      changeProgressType,
      changeSortType,
      changeCardSize,
      hideSizer,
    }),
    [cardSize, progressType, sort, changeProgressType, changeSortType, changeCardSize, hideSizer]
  );

  return <WidgetContext.Provider value={contextValue}>{children}</WidgetContext.Provider>;
};

export default WidgetContextProvider;
