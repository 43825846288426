import type { FC } from 'react';

import { useTalentImage } from 'hooks/useTalentImage';
import { AthleteI } from 'types';

import AthleteCardTemplate, { AthleteCardPropsI } from './athlete-card-template';

interface AthleteCardContainerPropsI extends Pick<AthleteCardPropsI, 'className' | 'variant'> {
  athlete: AthleteI;
}

export const AthleteCardContainer: FC<AthleteCardContainerPropsI> = ({ athlete, ...rest }) => {
  const { img } = useTalentImage(athlete.bio.photo ?? '');

  const {
    bio: { name },
    tradingInfo: { contractId },
    career: { league, team, position, positionShort, jerseyNumber, title },
  } = athlete;

  return (
    <AthleteCardTemplate
      id={athlete.id}
      name={name}
      photo={img}
      contractId={contractId}
      league={league.shortName}
      team={team}
      position={position}
      positionShort={positionShort}
      jerseyNumber={jerseyNumber}
      title={title}
      {...rest}
    />
  );
};
