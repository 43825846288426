import type { FC } from 'react';
import styles from './transparent-overlay.module.scss';
import { useContext } from 'react';
import { TransparentOverlayContext } from './transparent-overlay-context';

export const TransparentOverlay: FC = () => {
  const { enabled } = useContext(TransparentOverlayContext);
  if (!enabled) {
    return null;
  }
  return <div className={styles.overlay} />;
};
