import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import {
  useTalentIpoLegalClausesQuery,
  useUpdateTalentApplicationLegalClausesMutation,
  TalentApplicationLegalClause,
  useTalentApplicationLegalClausesQuery,
} from 'generated/graphql';
import styles from './tab-legal.module.scss';

export const TabLegal = () => {
  const { data: legalClausesView } = useTalentIpoLegalClausesQuery();
  const { data: storedLegalData } = useTalentApplicationLegalClausesQuery();
  const [updateLegalClauses] = useUpdateTalentApplicationLegalClausesMutation();

  let legalClauses: undefined | Array<TalentApplicationLegalClause> = undefined;

  if (storedLegalData) {
    legalClauses = storedLegalData.talentApplication.legalClauses;
  }

  const isSectionFilled = (num: number) =>
    legalClauses === undefined
      ? false
      : legalClauses[legalClauses.map((it) => Number(it.id)).indexOf(num)].checked;

  const updateLegal = async (legalClause: number, checked: boolean) => {
    try {
      await updateLegalClauses({
        variables: { legalClauses: legalClause, checked },
        refetchQueries: ['TalentApplicationState', 'TalentApplicationLegalClauses'],
      });
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <Box>
      <Typography className={styles.description}>
        {
          'Lorem ipsum dolor sit amet, consect etur adipi scing elit. Scelerisque bibendum sed viverra pellentesque sit eget mauris euismod id. Posuere bibendum libero nibh diam netus tempus ut. Enim, odio condi mentum eget vulputate id aenean ipsum risus. Urna, maecenas in nunc, quis commodo elementum, pulvinar. Elit vulputate id in in. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque bibendum sed viverra pellentesque'
        }
      </Typography>
      {legalClausesView?.talentIPOLegalClauses &&
        legalClausesView?.talentIPOLegalClauses.map((section) => {
          const idOfLegalClauseView = Number(section.id);
          return (
            <FormControlLabel
              key={section.id}
              className={styles.checkboxRow}
              checked={isSectionFilled(idOfLegalClauseView)}
              onChange={() =>
                updateLegal(idOfLegalClauseView, !isSectionFilled(idOfLegalClauseView))
              }
              control={<Checkbox size="small" />}
              label={
                <>
                  <Typography variant="subtitle2" mt={1}>
                    {section.title}
                  </Typography>
                  {legalClauses &&
                    !legalClauses[
                      legalClauses.findIndex((legalClause) => legalClause.id === section.id)
                    ]?.checked && (
                      <Typography className={styles.descriptionSecondary}>
                        {section.text}
                      </Typography>
                    )}
                </>
              }
            />
          );
        })}
    </Box>
  );
};
