import type { FC } from 'react';

import { HeaderTradingInfo } from './header-trading-info';
import { matchPath } from 'react-router-dom';
import { ROUTE } from '../../../routes/enums';
import { useCurrentRouteConfig } from '../../../hooks/useCurrentRouteConfig';

export const TradingInfo: FC = () => {
  const routeConfig = useCurrentRouteConfig();
  const isTalentPortal =
    !!routeConfig && !!matchPath({ path: ROUTE.TalentPortal }, routeConfig.path);

  if (isTalentPortal || !routeConfig?.isPrivate) {
    return null;
  }

  return <HeaderTradingInfo />;
};
