import MuiTableRow, { TableRowProps } from '@mui/material/TableRow';
import classNames from 'classnames';
import type { FC } from 'react';

import styles from './styles.module.scss';

type TableRowPropsT = TableRowProps & {
  active?: boolean;
};

export const TableRow: FC<TableRowPropsT> = ({ className, active, ...props }) => (
  <MuiTableRow className={classNames(className, styles.row, active && styles.active)} {...props} />
);
