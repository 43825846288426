import type { FC } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { useAuth } from 'auth/use-auth';
import { MenuBar } from 'components/layout/menu-bar';
import { Header } from 'components/layout/header';
import { TradingInfo } from 'components/layout/trading-info';
import { MessageAlert } from 'components/message-alert';
import { AccountProfile } from 'features/account-profile';
import { TickerBar } from 'features/ticker-bar';
import { TradeBar } from 'features/trade-bar';
import { AppRoutes } from 'routes/app-routes';
import { InvitationsBlock } from 'features/hcx-invitations-block';
import { TalentActivity } from 'features/talent-activity';
import { RightSideBar } from 'features/right-side-bar';
import { ContentWrapper } from 'components/layout/content-wrapper';

import styles from './App.module.scss';

import packageInfo from '../package.json';

export const App: FC = () => {
  const { initialLoading, isLoggedIn } = useAuth();

  return (
    <>
      {!initialLoading ? (
        <>
          {isLoggedIn && (
            <>
              <Header />
              <InvitationsBlock />
              <Box className={styles.bodyBlock}>
                <Box className={styles.menuBlock}>
                  <MenuBar />
                </Box>
                <Box className={styles.contentBlock}>
                  <TradingInfo />
                  <TradeBar />
                  <ContentWrapper>
                    <AppRoutes />
                    <RightSideBar />
                  </ContentWrapper>
                  <TickerBar />
                </Box>
              </Box>
              <AccountProfile />
              <TalentActivity />
              <MessageAlert />
            </>
          )}
          {!isLoggedIn && (
            <Box className={styles.contentWrapper}>
              <AppRoutes />
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100vw"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      )}
      <Typography variant="body2" style={{ position: 'absolute', right: '20px', bottom: '20px' }}>
        {packageInfo.name} {packageInfo.version}
      </Typography>
    </>
  );
};
