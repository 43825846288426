import type { FC } from 'react';

import { AthleteCardResultsLine } from 'components/athlete/athlete-card';

import { HCXFinancialCardHeader } from '../hcx-financial-card-header';
import { HCXFinancialCardAthleteInfo } from '../hcx-financial-card-athlete-info';
import { HCXFinancialCardFrontChart } from './hcx-financial-card-chart';
import { HCXFinancialCardFrontChartPriceLine } from './hcx-financial-card-front-price-line';

export const HCXFinancialCardFront: FC = () => {
  return (
    <>
      <HCXFinancialCardHeader />
      <HCXFinancialCardAthleteInfo variant="small" />
      <HCXFinancialCardFrontChart />
      <AthleteCardResultsLine />
      <HCXFinancialCardFrontChartPriceLine />
    </>
  );
};
