import { useAtom } from 'jotai';
import type { FC } from 'react';

import { HCXCardOrders } from 'features/hcx-card/hcx-card-orders';
import { selectedAthleteIAtom } from 'store/athletes.store';

export const HCXCardBuySellOrders: FC = () => {
  const [athlete] = useAtom(selectedAthleteIAtom);

  if (!athlete?.id) {
    return null;
  }
  return <HCXCardOrders athleteId={athlete.id} />;
};
