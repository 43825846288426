import type { FC } from 'react';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Backdrop, Divider, Slide } from '@mui/material';
import { Panel } from 'components/panel';
import { AccountProfileHeader } from './account-profile-header';
import { AccountProfileInfo } from './account-profile-info';
import { AccountProfileAffiliateLink } from './account-profile-affiliate-link';
import { SettingsViewConfigI } from './account-profile.types';
import { AccountProfileSettingsView } from './account-profile-settings-view';
import { BecomeSponsor } from './account-sponsor-flow';
import { AccountTalentFlow } from './account-talent-flow';
import { AccountProfileSettingsLinks } from './account-profile-settings-links';
import { useAtom } from 'jotai';
import { sponsorPanelView } from 'store/sponsor-application.store';
import { sponsorManagementView } from 'store/sponsor-management.store';
import { talentPanelView } from 'store/talent-application.store';
import { SponsorApplication } from '../sponsor-application';
import { SponsorManagement } from 'features/sponsor-management';
import { TalentApplication } from 'features/talent-application';
import classNames from 'classnames';
import styles from './account-profile.module.scss';

const AccountProfile: FC<{ isOpened: boolean }> = ({ isOpened }) => {
  const [settingsView, setSettingsView] = useState<SettingsViewConfigI | null>(null);
  const [isSponsorPanelOpened] = useAtom(sponsorPanelView);
  const [isManagementPanelOpened] = useAtom(sponsorManagementView);
  const [isTalentPanelOpened] = useAtom(talentPanelView);
  const closeSettings = useCallback(() => setSettingsView(null), []);

  useEffect(() => {
    closeSettings();
  }, [isOpened, closeSettings]);

  const wrapperRef = useRef(null);

  return (
    <>
      <Backdrop open={isOpened} className={styles.backdrop} />
      <AccountProfileSettingsView
        isAccountOpened={isOpened}
        viewConfig={settingsView}
        closeView={closeSettings}
      />
      <Slide
        direction="left"
        in={isOpened}
        container={wrapperRef.current}
        timeout={300}
        easing={{
          enter: 'ease-out',
          exit: 'linear',
        }}
      >
        <Panel className={classNames(styles.root, isOpened && styles.opened)}>
          <AccountProfileHeader />
          <Divider className={styles.customDivider} />
          <AccountProfileInfo key={`${isOpened}`} />
          <AccountTalentFlow />
          <Divider className={styles.customDivider} />
          <BecomeSponsor />
          <Divider className={styles.customDivider} />
          <div className={styles.linksWrapper}>
            <AccountProfileAffiliateLink />
            <AccountProfileSettingsLinks
              onChangeConfigView={setSettingsView}
              selectedConfig={settingsView}
            />
          </div>
        </Panel>
      </Slide>
      {isSponsorPanelOpened && <SponsorApplication />}
      {isManagementPanelOpened && <SponsorManagement />}
      {isTalentPanelOpened && <TalentApplication />}
    </>
  );
};

export default AccountProfile;
