import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from './common/en.json';
import pageNotFound from './404/en.json';
import auth from './auth/en.json';
import hcm from './human-capital-mint/en.json';
import portfolio from './portfolio/en.json';
import overview from './overview/en.json';
import orders from './orders/en.json';
import hcxCard from './hcx-card/en.json';
import hcxFinancialCard from './hcx-financial-card/en.json';
import tradeDesk from './trade-desk/en.json';
import walletPage from './wallet-page/en.json';
import walletCard from './wallet-card/en.json';
import accountProfile from './account-profile/en.json';
import sponsorApplication from './sponsor-application/en.json';
import { AVAILABLE_LANGUAGES } from './constants';
import sponsorManagement from './sponsor-management/en.json';
import talentApplication from './talent-application/en.json';
import myOrders from './my-orders/en.json';

export const resources = {
  en: {
    common,
    '404': pageNotFound,
    auth,
    'human-capital-mint': hcm,
    overview,
    'hcx-card': hcxCard,
    'hcx-financial-card': hcxFinancialCard,
    'trade-desk': tradeDesk,
    orders,
    'wallet-page': walletPage,
    'wallet-card': walletCard,
    'account-profile': accountProfile,
    'sponsor-application': sponsorApplication,
    'sponsor-management': sponsorManagement,
    'talent-application': talentApplication,
    portfolio,
    'my-orders': myOrders,
  },
  es: {},
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  supportedLngs: AVAILABLE_LANGUAGES,
  ns: Object.keys(resources.en),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;
