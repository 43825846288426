import { FC, ReactNode } from 'react';
import { HCXCardViewE } from 'enums';
import { HCXCardSummaryInfoContainer } from './hcx-card-summary-info';
import { HCXCardSummaryStatsContainer } from './hcx-card-summary-stats';
import { HCXCardSummaryActivityContainer } from './hcx-card-summary-activity';
import { HCXCardSummaryFinanceContainer } from './hcx-card-summary-finance';
import { GetDefaultTabByViewT } from '../hcx-card.hooks';
import { Trans } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HCXCardSummaryTabComponentT<P = Record<string, any>> = FC<
  { setTab: (idx: number) => void } & P
>;

export const TABS: {
  label: ReactNode;
  component: FC<{ setTab: (idx: number) => void }> | null;
  keepMounted?: boolean;
  jsxKey: 'info' | 'stats' | 'activity' | 'finance';
}[] = [
  {
    jsxKey: 'info',
    label: <Trans i18nKey="info" ns="hcx-card" />,
    component: HCXCardSummaryInfoContainer,
  },
  {
    jsxKey: 'stats',
    label: <Trans i18nKey="stats" ns="hcx-card" />,
    component: HCXCardSummaryStatsContainer,
  },
  {
    jsxKey: 'activity',
    label: <Trans i18nKey="activity" ns="hcx-card" />,
    component: HCXCardSummaryActivityContainer,
  },
  {
    jsxKey: 'finance',
    label: <Trans i18nKey="finance" ns="hcx-card" />,
    component: HCXCardSummaryFinanceContainer,
    keepMounted: true,
  },
];

export const tabLabels = TABS.map(({ label }) => label);

export const getHCXCardSummaryDefaultTabByView: GetDefaultTabByViewT = (view) => {
  switch (view) {
    case HCXCardViewE.Info:
      return 0;
    case HCXCardViewE.Stats:
      return 1;
    case HCXCardViewE.Activity:
      return 2;
    case HCXCardViewE.Finance:
      return 3;
    default:
      return 0;
  }
};
