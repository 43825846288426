import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';

import { AthleteI, AthleteBioI, AthleteTradingInfoI, AthleteCareerI } from 'types';

import { AthleteFavoriteStar } from '../athlete-favorite-star';

import styles from './athlete-card.module.scss';

export interface AthleteCardPropsI
  extends Pick<AthleteI, 'id'>,
    Pick<AthleteBioI, 'name' | 'photo'>,
    Pick<AthleteTradingInfoI, 'contractId'>,
    Pick<AthleteCareerI, 'team' | 'position' | 'jerseyNumber' | 'title' | 'positionShort'> {
  league: string;
  className?: string;
  variant?: 'small' | 'medium';
}

const AthleteCardTemplate: FC<AthleteCardPropsI> = ({
  id,
  name,
  photo,
  contractId,
  title,
  league,
  team,
  position,
  positionShort,
  jerseyNumber,
  className,
  variant = 'medium',
}) => {
  const isSmall = variant === 'small';

  return (
    <div className={classNames(styles.root, styles[variant], className)}>
      <div className={styles.photoWrapper}>
        {!!photo && <img src={photo} alt="athlete" className={styles.photo} />}
        {!isSmall && <AthleteFavoriteStar id={id} className={styles.rating} />}
      </div>
      <div className={styles.info}>
        <div className={classNames('AthleteCardNameWrapper', styles.nameWrapper)}>
          <span title={name} className={styles.name}>
            {name}
          </span>
          {isSmall && <AthleteFavoriteStar id={id} className={styles.rating} />}
        </div>
        <Typography className={styles.contractId}>
          {isSmall ? (
            <>
              {title}&nbsp;&nbsp;{positionShort}&nbsp;&nbsp;{team}
            </>
          ) : (
            contractId
          )}
        </Typography>
        {!isSmall && (
          <>
            <Typography className={styles.careerRow} title={title}>
              <span>{title}</span>
            </Typography>
            <Typography className={styles.careerRow} title={league}>
              <span>League: </span>
              {league}
            </Typography>
            <Typography className={styles.careerRow} title={team}>
              <span>Team: </span>
              {team}
            </Typography>
            <Typography className={styles.careerRow} title={position}>
              <span>Position: </span>
              {position}
            </Typography>
            <Typography className={styles.careerRow} title={`${jerseyNumber}`}>
              <span>Jersey Number: </span>
              {jerseyNumber}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default AthleteCardTemplate;
