import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AthleteCareerActivityI } from 'types';
import { ATHLETE_SUMMARY_ACTIVITY_COLUMNS_CONFIG } from './helpers';

import styles from './hcx-card-summary-activity.module.scss';

export interface HCXCardSummaryActivityPropsI {
  activity: AthleteCareerActivityI[];
}

const HCXCardSummaryActivity: FC<HCXCardSummaryActivityPropsI> = ({ activity }) => {
  const { t } = useTranslation('hcx-card');
  return (
    <table className={styles.root}>
      <caption>{t('regular-season')}</caption>
      <thead>
        <tr>
          {ATHLETE_SUMMARY_ACTIVITY_COLUMNS_CONFIG.map(({ title, dataKey }) => (
            <th key={dataKey}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {activity.map((activityItem) => {
          const rowKey = `row-${activityItem.id}`;
          return (
            <tr key={rowKey}>
              {ATHLETE_SUMMARY_ACTIVITY_COLUMNS_CONFIG.map(({ dataKey, renderValue }) => (
                <td key={`${rowKey}-${dataKey}`}>
                  {renderValue ? renderValue(activityItem, { styles }) : activityItem[dataKey]}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default HCXCardSummaryActivity;
