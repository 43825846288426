import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { AVAILABLE_LANGUAGES } from 'i18n/constants';
import { useTranslation } from 'react-i18next';

import enFlag from './assets/US.png';
import cnFlag from './assets/CN.png';
import deFlag from './assets/DE.png';
import mxFlag from './assets/MX.png';
import frFlag from './assets/FR.png';
import esFlag from './assets/ES.png';

import styles from './language-picker.module.scss';

const MENU_PROPS = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  classes: { paper: styles.selectPaper, list: styles.selectList },
} as const;

const SELECT_CLASSES = {
  outlined: styles.select,
  icon: styles.selectIcon,
};

const countryFlags: Record<string, string> = {
  en: enFlag,
  cn: cnFlag,
  de: deFlag,
  mx: mxFlag,
  fr: frFlag,
  es: esFlag,
};

export const LanguagePicker = ({ className }: { className?: string }) => {
  const { i18n, t } = useTranslation('common');

  const handleChange = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      className={classNames(styles.selectRoot, className)}
      renderValue={() => <Typography variant="body2">{t('current-language')}</Typography>}
      value={i18n.language}
      onChange={handleChange}
      MenuProps={MENU_PROPS}
      classes={SELECT_CLASSES}
    >
      {AVAILABLE_LANGUAGES.map((lang) => (
        <MenuItem value={lang} key={lang} className={styles.selectMenuItem}>
          <img src={countryFlags[lang]} alt={lang} className={styles.flag} />
          <Typography variant="body1">{t(`language-${lang}`)}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
