import type { FC } from 'react';
import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OwnerInformation } from 'features/sponsor-application/sponsor-form-owner';
import styles from './tab-sponsor-info.module.scss';
import { AddPhotoInput } from 'components/add-photo-input';
import { InputField } from 'components/input-field';
import { COUNTRIES_AND_COMPANIES } from 'features/sponsor-application/sponsor-form-information/constants';
import { useAtom } from 'jotai';
import { sponsorFormValidationAtom } from 'store/sponsor-application.store';
import { areAllFieldsFilled } from 'utils/check-form-filled';
import { BadgeIncomplete } from 'components/badge-incomplete';
import { AccordionSection } from 'components/accordion-section';
import {
  SponsorApplicationStatus,
  useSponsorApplicationStatusQuery,
  useSponsorManagementInfoQuery,
  useUpdateSponsorApplicationMutation,
} from 'generated/graphql';
import { sponsorFormSchema } from 'yup/sponsorFormSchema';
import { ValidationError } from 'yup';
import { ApolloError } from '@apollo/client';

export const TabSponsorInfo: FC = () => {
  const { t } = useTranslation('sponsor-management');
  const [isSponsorExpanded, setIsSponsorExpanded] = useState(true);
  const [isOwnerExpanded, setIsOwnerExpanded] = useState(true);
  const [errorState, setErrorState] = useAtom(sponsorFormValidationAtom);
  const { sponsorInformation: errors } = errorState;

  const { data: fetchedSponsorManagementInfo, refetch } = useSponsorManagementInfoQuery();
  const { data: sponsorApplicationStatus } = useSponsorApplicationStatusQuery();

  const [updateApplication] = useUpdateSponsorApplicationMutation();

  if (!fetchedSponsorManagementInfo) return null;
  const sponsorInformation = fetchedSponsorManagementInfo.sponsorApplication.sponsorInformation;

  const isSponsorInfoFilled = areAllFieldsFilled(
    fetchedSponsorManagementInfo.sponsorApplication.sponsorInformation,
    errorState.sponsorInformation
  );

  const isApproved =
    sponsorApplicationStatus?.sponsorApplication.status === SponsorApplicationStatus.Approved;

  const isOwnerFilled = areAllFieldsFilled(
    fetchedSponsorManagementInfo.sponsorApplication.sponsorOwnerInformation,
    errorState.sponsorOwnerInformation
  );

  const handleChangeSponsorExpanded = () => {
    setIsSponsorExpanded((prevState) => !prevState);
  };

  const handleChangeOwnerExpanded = () => {
    setIsOwnerExpanded((prevState) => !prevState);
  };

  const onBlurHandler = async (event: React.FocusEvent) => {
    const { value, name } = event.target as HTMLInputElement;
    try {
      await sponsorFormSchema[name].validate(value);
    } catch (error) {
      setErrorState((prev) => {
        return {
          ...prev,
          sponsorInformation: {
            ...prev?.sponsorInformation,
            [name]: {
              errMessage: (error as ValidationError).errors[0],
              isTouched: true,
            },
          },
        };
      });
      return console.warn({ error });
    }
    setErrorState((prev) => {
      return {
        ...prev,
        sponsorInformation: {
          ...prev?.sponsorInformation,
          [name]: {
            errMessage: undefined,
            isTouched: true,
          },
        },
      };
    });
    try {
      await updateApplication({
        variables: {
          parameters: {
            sponsorInformation: {
              [name]: value,
            },
          },
        },
      });
      await refetch();
    } catch (error) {
      setErrorState((prev) => {
        return {
          ...prev,
          sponsorInformation: {
            ...prev?.sponsorInformation,
            [name]: {
              errMessage: (error as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
      return console.warn({ error });
    }
  };

  return (
    <Box>
      <AccordionSection
        expand={isSponsorExpanded}
        handleChangeExpanded={handleChangeSponsorExpanded}
        summary={
          <>
            <Typography variant="subtitle2">{t('sponsor-information')}</Typography>
            <BadgeIncomplete complete={isSponsorInfoFilled} />
          </>
        }
        details={
          <Box component="form" autoComplete="off">
            <Box className={styles.row}>
              <InputField
                className={styles.corporateWidth}
                tName={t('corporate-name')}
                disabled
                value={sponsorInformation.corporateName}
              />
              <InputField
                tName={t('country')}
                disabled
                className={styles.corporateTypeWidth}
                value={sponsorInformation.country.toUpperCase()}
              />
              <InputField
                tName={t('corporation-type')}
                disabled
                className={styles.countryWidth}
                value={
                  COUNTRIES_AND_COMPANIES[
                    sponsorInformation.country as keyof typeof COUNTRIES_AND_COMPANIES
                  ]?.find(([key]) => key === sponsorInformation.corporateType)?.[1] || ''
                }
              />
            </Box>
            <Box mb="26px">
              <InputField
                tName={t('ein')}
                disabled
                className={styles.corporateWidth}
                value={sponsorInformation.ein}
              />
            </Box>
            <Box className={styles.wrapper}>
              <Typography variant="body1" className={styles.companyLogo}>
                {t('company-logo')}
              </Typography>
              <Typography variant="body2" className={styles.description}>
                {t('provide-two-logos')}
              </Typography>
              <Box className={styles.row}>
                <Box>
                  <Box className={styles.inputPhotoWrapper}>
                    <AddPhotoInput
                      fieldName={'logoUrlForDarkBackground'}
                      state={sponsorInformation.logoUrlForDarkBackground}
                      disabled={isApproved}
                    />
                  </Box>
                  <Typography mt={1} variant="body2">
                    {t('dark-backgrounds')}
                  </Typography>
                </Box>
                <Box>
                  <Box className={styles.inputPhotoWrapper}>
                    <AddPhotoInput
                      fieldName={'logoUrlForLightBackground'}
                      state={sponsorInformation.logoUrlForLightBackground}
                      disabled={isApproved}
                    />
                  </Box>
                  <Typography mt={1} variant="body2">
                    {t('light-backgrounds')}
                  </Typography>
                </Box>
                <Box className={styles.wrapper}>
                  <Typography variant="body1" className={styles.headerDescription}>
                    {t('tips-effective-photo')}
                  </Typography>
                  <Typography variant="body2" className={styles.description}>
                    {t('we-require-that-logo')}
                  </Typography>
                  <Link href="#" variant="body1">
                    {t('click-to-see-list')}
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box>
              <InputField
                tName={t('description')}
                name={'description'}
                className={styles.descriptionWidth}
                placeholder={t('description-placeholder')}
                multiline
                maxRows="3"
                onBlur={onBlurHandler}
                disabled={isApproved}
                errorValidation={errors.description.isTouched && errors.description.errMessage}
                defaultValue={sponsorInformation.description || ''}
              />
            </Box>
          </Box>
        }
      />
      <AccordionSection
        expand={isOwnerExpanded}
        handleChangeExpanded={handleChangeOwnerExpanded}
        summary={
          <>
            <Typography variant="subtitle2">{t('sponsor-owner-information')}</Typography>
            <BadgeIncomplete complete={isOwnerFilled} />
          </>
        }
        details={
          <OwnerInformation
            sponsorOwnerInformation={
              fetchedSponsorManagementInfo.sponsorApplication.sponsorOwnerInformation
            }
            refetchApplication={refetch}
            inReview={isApproved}
          />
        }
      />
    </Box>
  );
};
