import type { FC } from 'react';

import { Typography } from '@mui/material';

import { useAthleteCareerDescription } from './hcx-financial-card-back-description.hooks';

import styles from './hcx-financial-card-back-description.module.scss';

export const HCXFinancialCardBackDescription: FC = () => {
  const { description } = useAthleteCareerDescription();
  return (
    <Typography className={styles.root} title={description}>
      {description}
    </Typography>
  );
};
