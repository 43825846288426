import { useState, useEffect } from 'react';

const getTimeLeft = (endDate: Date): number => {
  const now = new Date().getTime();
  const timeLeft = endDate.getTime() - now;
  return now < 0 ? 0 : timeLeft;
};

export const useTimeLeft = (endDate: Date): number => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(endDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (!prev) {
          clearInterval(timer);
          return prev;
        }
        const newTimeLeft = getTimeLeft(endDate);
        if (!newTimeLeft) {
          clearInterval(timer);
          return 0;
        }
        return newTimeLeft;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  return timeLeft;
};
