import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const DiscordIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M16.5 18L12.5625 14.25L13.035 15.75H3.375C2.87772 15.75 2.40081 15.5525 2.04917 15.2008C1.69754 14.8492 1.5 14.3723 1.5 13.875V2.625C1.5 2.12772 1.69754 1.65081 2.04917 1.29917C2.40081 0.947544 2.87772 0.75 3.375 0.75H14.625C15.1223 0.75 15.5992 0.947544 15.9508 1.29917C16.3025 1.65081 16.5 2.12772 16.5 2.625V18ZM9 5.1C6.99 5.1 5.58 5.9625 5.58 5.9625C6.3525 5.2725 7.7025 4.875 7.7025 4.875L7.575 4.7475C6.3075 4.77 5.16 5.6475 5.16 5.6475C3.87 8.34 3.9525 10.665 3.9525 10.665C5.0025 12.0225 6.5625 11.925 6.5625 11.925L7.095 11.25C6.1575 11.0475 5.565 10.215 5.565 10.215C5.565 10.215 6.975 11.175 9 11.175C11.025 11.175 12.435 10.215 12.435 10.215C12.435 10.215 11.8425 11.0475 10.905 11.25L11.4375 11.925C11.4375 11.925 12.9975 12.0225 14.0475 10.665C14.0475 10.665 14.13 8.34 12.84 5.6475C12.84 5.6475 11.6925 4.77 10.425 4.7475L10.2975 4.875C10.2975 4.875 11.6475 5.2725 12.42 5.9625C12.42 5.9625 11.01 5.1 9 5.1ZM7.4475 7.9425C7.935 7.9425 8.3325 8.37 8.325 8.895C8.325 9.4125 7.935 9.8475 7.4475 9.8475C6.9675 9.8475 6.5775 9.4125 6.5775 8.895C6.5775 8.37 6.96 7.9425 7.4475 7.9425ZM10.575 7.9425C11.0625 7.9425 11.4525 8.37 11.4525 8.895C11.4525 9.4125 11.0625 9.8475 10.575 9.8475C10.095 9.8475 9.705 9.4125 9.705 8.895C9.705 8.37 10.0875 7.9425 10.575 7.9425Z"
      fill="currentColor"
    />
  </SvgIcon>
);
