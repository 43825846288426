import type { FC } from 'react';

interface PropsI {
  className?: string;
  color?: string;
}

export const PortfolioIcon: FC<PropsI> = ({ className, color = 'currentColor' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.75 1.5376V3.8101C12.2925 4.1776 14.25 6.3526 14.25 9.0001C14.25 9.6751 14.115 10.3126 13.875 10.9051L15.84 12.0526C16.26 11.1226 16.5 10.0876 16.5 9.0001C16.5 5.1151 13.5375 1.9126 9.75 1.5376ZM9 14.2501C7.60761 14.2501 6.27225 13.697 5.28769 12.7124C4.30312 11.7278 3.75 10.3925 3.75 9.0001C3.75 6.3526 5.7075 4.1776 8.25 3.8101V1.5376C4.455 1.9126 1.5 5.1076 1.5 9.0001C1.5 10.9892 2.29018 12.8969 3.6967 14.3034C4.39314 14.9998 5.21993 15.5523 6.12987 15.9292C7.03982 16.3061 8.01509 16.5001 9 16.5001C11.475 16.5001 13.6725 15.2926 15.0375 13.4326L13.0875 12.2851C12.1275 13.5001 10.6575 14.2501 9 14.2501Z"
      fill={color}
    />
    <path
      d="M9.75 3.8101V1.5376C13.5375 1.9126 16.5 5.1151 16.5 9.0001C16.5 10.0876 16.26 11.1226 15.84 12.0526L13.875 10.9051C14.115 10.3126 14.25 9.6751 14.25 9.0001C14.25 6.3526 12.2925 4.1776 9.75 3.8101Z"
      fill={color}
    />
  </svg>
);
