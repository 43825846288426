import { atom } from 'jotai';
import { TradingPair, TradingPairStats } from 'generated/graphql';
import { PanelNameE } from 'pages/trade-desk/types';

export const latestTradingStatsAtom = atom<Record<TradingPair['id'], TradingPairStats> | null>(
  null
);

export const updateLatestTradingStatsAtom = atom(
  null,
  (get, set, latestStats: Record<TradingPair['id'], TradingPairStats>) =>
    set(latestTradingStatsAtom, { ...get(latestTradingStatsAtom), ...latestStats })
);

export const enabledPanelsHeaderButtons = atom<PanelNameE[]>([PanelNameE.Trending]);

export const tickerBarView = atom<boolean>(false);

export const tradeBarView = atom<boolean>(true);
