import type { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const MarkedLabel: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 14" sx={{ width: '14px', height: '14px' }}>
    <path
      d="M10.2842 3.4065C10.0742 3.109 9.72417 2.9165 9.33333 2.9165H2.91667C2.60725 2.9165 2.3105 3.03942 2.09171 3.25821C1.87292 3.47701 1.75 3.77375 1.75 4.08317V9.9165C1.75 10.2259 1.87292 10.5227 2.09171 10.7415C2.3105 10.9603 2.60725 11.0832 2.91667 11.0832H9.33333C9.72417 11.0832 10.0742 10.8848 10.2842 10.5873L12.8333 6.99984L10.2842 3.4065Z"
      fill="#FFCC6A"
    />
  </SvgIcon>
);
