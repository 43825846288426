import { Auth0UserProfile } from 'auth0-js';
import { FC, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

import { parseQueryString } from '../utils/parse-query-string';
import { AuthApiT, createAuthApi } from './auth-api';
import { AuthContext, AuthContextT } from './auth-context';
import {
  authReducer,
  clearFailedAction,
  finishInitialLoadingAction,
  initialState,
  logInAction,
  logOutAction,
  setFailedAction,
  setLoadingAction,
} from './reducer';
import type { PasswordAuthDataI, UserI } from './types';
import { clearAuthData, restoreAuthData, saveAuthData, AuthOriginE } from './storage';

const parseAuthOUserprofile = (data: Auth0UserProfile): UserI => ({
  id: data.sub,
  username: data.username || data.name,
  picture: data.picture,
  email: data.email,
});

export const AuthProvider: FC = ({ children }) => {
  const authRef = useRef<AuthApiT>();
  const [state, dispatch] = useReducer(authReducer, initialState);
  useEffect(() => {
    const auth = createAuthApi();
    authRef.current = auth;

    const queryParams = parseQueryString(window.location.hash);
    const { access_token: queryParamsAccessToken } = queryParams;
    if (queryParamsAccessToken) {
      window.location.hash = '';
      auth
        .getUserInfo(queryParamsAccessToken)
        .then((data) => {
          saveAuthData({ origin: AuthOriginE.Google, accessToken: queryParamsAccessToken });
          dispatch(logInAction(parseAuthOUserprofile(data), queryParamsAccessToken));
        })
        .catch((error) => {
          dispatch(setFailedAction(error.toString()));
        })
        .finally(() => dispatch(finishInitialLoadingAction()));
      return;
    }

    const restoredData = restoreAuthData();
    if (restoredData?.origin === AuthOriginE.Password) {
      auth
        .validateToken(restoredData.idToken)
        .then((data) => {
          dispatch(logInAction(parseAuthOUserprofile(data), queryParamsAccessToken));
        })
        .catch(() => {
          dispatch(setFailedAction('token expired'));
        })
        .finally(() => dispatch(finishInitialLoadingAction()));
      return;
    }

    if (restoredData?.origin === AuthOriginE.Google) {
      auth
        .getUserInfo(restoredData.accessToken)
        .then((data) => {
          dispatch(logInAction(parseAuthOUserprofile(data), restoredData.accessToken));
          dispatch(finishInitialLoadingAction());
        })
        .catch(() => {
          dispatch(setFailedAction('token expired'));
        })
        .finally(() => dispatch(finishInitialLoadingAction()));
      return;
    }
    dispatch(finishInitialLoadingAction());
    return;
  }, []);

  const logIn = useCallback(async (data: PasswordAuthDataI) => {
    try {
      setLoadingAction(true);
      const loginResult = await authRef.current?.logIn(data);
      const userInfo = await authRef.current?.getUserInfo(loginResult.accessToken);
      if (!userInfo) {
        return;
      }
      saveAuthData({
        accessToken: loginResult.accessToken,
        idToken: loginResult.idToken,
        origin: AuthOriginE.Password,
      });
      dispatch(logInAction(parseAuthOUserprofile(userInfo), loginResult.accessToken));
      dispatch(setLoadingAction(false));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      dispatch(setFailedAction(e.description));
    }
  }, []);

  const logInViaGoogle = useCallback(() => {
    setLoadingAction(true);
    authRef.current?.logInWithGoogle();
  }, []);

  const logOut = useCallback(() => {
    authRef.current?.logOut();
    clearAuthData();
    dispatch(logOutAction());
  }, []);

  const signUp = useCallback(
    (data: PasswordAuthDataI) => {
      dispatch(setLoadingAction(true));
      return authRef.current
        ?.signUp(data)
        .then(() => logIn(data))
        .then(() => {})
        .catch((e) => {
          console.log(e);
          dispatch(setFailedAction(e.description));
        })
        .finally(() => {
          dispatch(setLoadingAction(false));
        });
    },
    [logIn]
  );

  const restorePassword = useCallback((email: string) => {
    dispatch(setLoadingAction(true));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return authRef.current!.restorePassword(email).finally(() => dispatch(setLoadingAction(false)));
  }, []);

  const clearError = useCallback(() => {
    dispatch(clearFailedAction());
  }, []);

  const contextValue = useMemo<AuthContextT>(() => {
    const { user, isLoading, initialLoading, accessToken, isFailed, error } = state;
    return user
      ? {
          isLoggedIn: true,
          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
          user: user!, // TODO: VOV: Better validation/typings required to not use `!`
          // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
          accessToken: accessToken!, // TODO: VOV: Better validation/typings required to not use `!`
          isLoading,
          initialLoading,
          isFailed,
          error,
          logOut,
          restorePassword: async () => {
            if (user.email) {
              await restorePassword(user.email);
            }
          },
        }
      : {
          logIn,
          logInViaGoogle,
          restorePassword,
          signUp,
          isLoggedIn: false,
          isLoading,
          initialLoading,
          isFailed,
          error,
          clearError,
        };
  }, [logIn, logInViaGoogle, logOut, state, signUp, restorePassword, clearError]);

  //eslint-disable-next-line
  (window as any).authContext = contextValue; // todo: remove this line when sign out is ready

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
