import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC } from 'react';

import { SelectFieldI } from '../types';
import { CommonPropsI } from './types';

export const SelectField: FC<SelectFieldI & CommonPropsI<string>> = ({
  label,
  field,
  options,
  value,
  onChange,
}) => {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id={field} shrink>
        {label}
      </InputLabel>
      <Select
        labelId={field}
        size="small"
        value={value}
        name={field}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map(({ title, value: v }) => (
          <MenuItem value={v} key={v}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
