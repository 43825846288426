import { FC } from 'react';
import classNames from 'classnames';
import styles from './trade-bar-field-with-label.module.scss';

const TradeBarFieldWithLabel: FC<{ label: string; className?: string }> = ({
  label,
  className,
  children,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      <span className={styles.label}>{label}:</span>
      {children}
    </div>
  );
};

export default TradeBarFieldWithLabel;
