import classNames from 'classnames';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { WalletE } from 'blockchain-api/ewallets-api';
import { Button } from 'components/button';
import { BaseModal, BaseModalPropsI } from 'components/modals/base-modal';

import styles from './hcx-card-confirmation-modal.module.scss';

const getModalContainer = () => document.getElementById('hcx-card');

// TODO: VOV: Find better place for this data
export const DEFAULT_WALLET = WalletE.Metamask;

export enum FinalStateE {
  Success = 1,
  Error = 0,
}

export interface ModalStateI {
  loading: boolean;
  finalState: FinalStateE | null;
}

export const getDefaultModalState = (): ModalStateI => ({
  loading: false,
  finalState: null,
});

const modalClasses = {
  root: styles.positionAbsolute,
};

const backdropProps = {
  classes: modalClasses,
};

interface HCXCardConfirmationModalPropsI
  extends Pick<BaseModalPropsI, 'open' | 'onClose' | 'title'> {
  onConfirm: () => void;
  onCancel: () => void;
  modalState: ModalStateI;
}

export const HCXCardConfirmationModal: FC<HCXCardConfirmationModalPropsI> = ({
  children,
  open,
  onClose,
  onConfirm,
  onCancel,
  title,
  modalState: { loading, finalState },
}) => {
  const { t } = useTranslation('hcx-card');

  return (
    <BaseModal
      onClose={onClose}
      open={open}
      title={title}
      container={getModalContainer}
      classes={modalClasses}
      BackdropProps={backdropProps}
      className={styles.modal}
      disableScrollLock={false}
      actions={
        <div className={classNames(styles.actions, { [styles.justifyEnd]: finalState != null })}>
          {finalState != null ? (
            <Button
              variant="contained"
              size="small"
              onClick={onCancel}
              className={styles.actionBtn}
            >
              {t('ok')}
            </Button>
          ) : (
            <>
              <Button
                variant="text"
                size="small"
                onClick={onCancel}
                className={classNames(styles.actionBtn, styles.cancel)}
                disabled={loading}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={onConfirm}
                className={styles.actionBtn}
                disabled={loading}
              >
                {t('confirm')}
              </Button>
            </>
          )}
        </div>
      }
      fullWidth
    >
      {children}
    </BaseModal>
  );
};
