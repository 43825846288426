import type { FC } from 'react';

import { AthleteCardGradient, AthleteCardResultsLine } from 'components/athlete/athlete-card';

import { HCXFinancialCardAthleteInfo } from '../hcx-financial-card-athlete-info';
// import { useHCXFinancialCardContext } from '../hcx-financial-card-context';
import { HCXFinancialCardFrontChartPriceLine } from '../hcx-financial-card-front/hcx-financial-card-front-price-line';
import { HCXFinancialCardHeader } from '../hcx-financial-card-header';
import { HCXFinancialCardBackDescription } from './hcx-financial-card-back-description';

// import styles from './hcx-financial-card-back.module.scss';

export const HCXFinancialCardBack: FC = () => {
  // const { athleteId: id } = useHCXFinancialCardContext();
  return (
    <>
      <AthleteCardGradient>
        <HCXFinancialCardHeader />
        <HCXFinancialCardAthleteInfo />
      </AthleteCardGradient>
      <AthleteCardResultsLine />
      <HCXFinancialCardFrontChartPriceLine />
      <HCXFinancialCardBackDescription />
    </>
  );
};
