// TODO: VOV: This code might be removed. We don't use data 0x any more...
import { BigNumber } from '@0x/utils';
import { useEffect, useState } from 'react';

import { AthleteI, AthleteTradingOrderI } from 'types';
import { fetchLimitOrders, LimitOrderResponseRecordI } from 'blockchain-api/limit-orders';
import { OrderStatusE, OrderTradingTypeE } from 'enums';

import { formatToUSD } from '../../../utils/currency-formatter';

const formatDate = (date: Date) =>
  date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }) + ' EST';

// eslint-disable-next-line
const mapOrder = (data: LimitOrderResponseRecordI): AthleteTradingOrderI => ({
  id: data.metaData.orderHash,
  number: data.metaData.orderHash.slice(2, 6),
  date: data.metaData.createdAt,
  statusDate: '',
  status: OrderStatusE.Open,
  buyDescription: `Buy ${formatToUSD(
    Number(new BigNumber(data.order.makerAmount).div(Math.pow(10, 6)))
  )} | ${formatDate(new Date(data.order.expiry * 1000))}`,
  type: OrderTradingTypeE.Buy,
});

export const useAthleteOrders = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  id: AthleteI['id']
): {
  orders: AthleteTradingOrderI[];
  total: number;
} => {
  const [state, setState] = useState<AthleteTradingOrderI[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchLimitOrders({}).then((response) => {
      setState(response.asks.records.map(mapOrder));
      setTotal(response.asks.total);
    });
  }, []);

  return {
    orders: state,
    total,
  };
};
