import type { FC } from 'react';
import { Box, IconButton, Link, Typography, Button } from '@mui/material';
import { InputField } from 'components/input-field';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import {
  TalentApplicationProfileBasicInformation,
  TalentApplicationProfileQuery,
  useDeleteTalentApplicationProfileResumeMutation,
  useUpdateTalentApplicationProfileMutation,
} from 'generated/graphql';
import { talentProfileFormValidationAtom } from 'store/talent-application.store';
import styles from './form-section-resume.module.scss';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { talentFormSchema } from 'yup/talentFormSchema';
import { ValidationError } from 'yup';
import React from 'react';

interface FormSectionResumePropsI {
  basicInformation: TalentApplicationProfileBasicInformation;
  refetchProfile: () => Promise<ApolloQueryResult<TalentApplicationProfileQuery>>;
}

export const FormSectionResume: FC<FormSectionResumePropsI> = ({
  basicInformation,
  refetchProfile,
}) => {
  const { t } = useTranslation('talent-application');
  const [errorState, setErrorState] = useAtom(talentProfileFormValidationAtom);
  const { basicInformation: errors } = errorState;

  const [deleteResumeFile] = useDeleteTalentApplicationProfileResumeMutation();

  const [updateForm] = useUpdateTalentApplicationProfileMutation();

  const handleDeleteResume = async () => {
    try {
      await deleteResumeFile({
        refetchQueries: ['TalentApplicationProfile', 'TalentApplicationState'],
      });
    } catch (err) {
      console.warn(err);
    }
    await refetchProfile();
  };

  const onBlurHandler = async (event: React.FocusEvent) => {
    const { value, name } = event.target as HTMLInputElement;

    try {
      await talentFormSchema[name].validate(value);
    } catch (error) {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: (error as ValidationError).errors[0],
              isTouched: true,
            },
          },
        };
      });
      return console.log({ error });
    }
    setErrorState((prev) => {
      return {
        ...prev,
        basicInformation: {
          ...prev?.basicInformation,
          [name]: {
            errMessage: undefined,
            isTouched: true,
          },
        },
      };
    });
    try {
      await updateForm({
        variables: {
          parameters: {
            basicInformation: {
              [name]: value,
              condition: {
                field: name,
                state: basicInformation.condition[name].state,
              },
            },
          },
        },
        refetchQueries: ['TalentApplicationState'],
      });
    } catch (error) {
      console.warn({ error });
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: (error as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
    }
    await refetchProfile();
  };

  const fileHandler = async (event: React.ChangeEvent) => {
    const { name } = event.target as HTMLInputElement;
    const fileMutationField = name.replace('Url', '');
    const fileList = (event.target as HTMLInputElement).files;
    if (!fileList) return;
    const file = fileList[0];

    (event.target as HTMLInputElement).value = '';

    try {
      await updateForm({
        variables: {
          parameters: {
            basicInformation: {
              [fileMutationField]: file,
              condition: {
                field: fileMutationField,
                state: basicInformation.condition[fileMutationField].state,
              },
            },
          },
        },
        refetchQueries: ['TalentApplicationState'],
      });

      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: undefined,
              isTouched: true,
            },
          },
        };
      });
    } catch (error) {
      setErrorState((prev) => {
        return {
          ...prev,
          basicInformation: {
            ...prev?.basicInformation,
            [name]: {
              errMessage: (error as ApolloError).message,
              isTouched: true,
            },
          },
        };
      });
    }
    await refetchProfile();
  };

  return (
    <Box component="form" autoComplete="off">
      <Box className={styles.firstRow}>
        <Button variant="outlined" size="small" component="label">
          {t('upload-pdf')}
          <input hidden type="file" name={'resumeUrl'} accept=".pdf" onChange={fileHandler} />
        </Button>
        <Typography variant="body2">
          {t('alternatively-you-can-provide-your-linkedIn-profile')}
        </Typography>
        <InputField
          placeholder={t('linkedin-profile-url')}
          className={styles.widthLinkedin}
          tName={''}
          name={'linkedinProfileUrl'}
          defaultValue={basicInformation.linkedinProfileUrl || ''}
          onBlur={onBlurHandler}
          errorValidation={
            errors.linkedinProfileUrl.isTouched && errors.linkedinProfileUrl.errMessage
          }
        />
      </Box>
      {basicInformation.resumeUrl && (
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Link
            href={basicInformation.resumeUrl || ''}
            target="_blank"
            rel="noreferrer"
            display="flex"
            alignItems="center"
            gap={2}
            mr="40px"
          >
            <PictureAsPdfIcon fontSize="small" />
            {basicInformation.resumeFileName || ''}
          </Link>
          <IconButton onClick={handleDeleteResume}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
