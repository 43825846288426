import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { AthleteCareerStatI, TableColumnConfigI } from 'types';

import styles from './career-highlights-table.module.scss';

export interface CareerHighlightsTablePropsI {
  title: string;
  action?: ReactNode;
  className?: string;
  columns: TableColumnConfigI<AthleteCareerStatI>[];
  rows: AthleteCareerStatI[];
}

const CareerHighlightsTable: FC<CareerHighlightsTablePropsI> = ({
  title,
  action,
  className,
  columns,
  rows,
}) => {
  return (
    <table className={classNames(styles.root, className)}>
      <caption>
        <div>
          {title} {action}
        </div>
      </caption>
      <thead>
        <tr>
          {columns.map(({ title: colTitle }, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <th key={index}>{colTitle}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, idx) => {
          const rowKey = `row-${idx}`;
          return (
            <tr key={rowKey}>
              {columns.map(({ renderValue, dataKey }) => {
                const resultValue = renderValue ? renderValue(row, { styles }) : row[dataKey];
                return <td key={`${rowKey}-${dataKey}`}>{resultValue}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CareerHighlightsTable;
