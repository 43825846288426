import { CurrencyE, LocaleE } from 'enums';
import { compactConfig } from './number-formatter';

//INFO: signDisplay = 'exceptZero' need to provide leading "+" if needed
interface CurrencyFormatterOptionsI {
  currency?: CurrencyE;
  locale?: LocaleE;
  compact?: boolean;
  signDisplay?: 'accounting' | 'standard' | 'exceptZero';
  minimumFractionDigits?: number;
}

const generateCurrencyFormatter = ({
  currency = CurrencyE.USD,
  locale = LocaleE.en_US,
  compact,
  signDisplay = 'standard',
  minimumFractionDigits = 0,
}: CurrencyFormatterOptionsI): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits,
    currencySign: signDisplay !== 'exceptZero' ? signDisplay : 'standard',
    ...(compact ? compactConfig : {}),
  });

// INFO: for currency no leading "+" so need to provide it directly if needed
const getDiffSign = (n: number, signDisplay?: CurrencyFormatterOptionsI['signDisplay']) =>
  n > 0 && signDisplay === 'exceptZero' ? '+' : '';

export const formatToUSD = (value: number, options: CurrencyFormatterOptionsI = {}) =>
  getDiffSign(value, options.signDisplay) + generateCurrencyFormatter(options).format(value);

export const formatToNaN = (): string => {
  return '--.--';
};
