import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { config } from 'config';

import { OrderBookWsContext, OrderBookWsContextI } from './order-book-ws-context';
import {
  AvailableGroupingsI,
  OrderBookItemI,
  OrderBookRowI,
  SelectedGroupingI,
  TradingPairsI,
} from '../order-book.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTradingPairsMessage(message: any): message is TradingPairsI {
  return message.tradingPairs;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAvailableGroupingsMessage(message: any): message is AvailableGroupingsI {
  return message.available_groupings;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSelectedGroupingMessage(message: any): message is SelectedGroupingI {
  return message.selected_grouping;
}

interface WsDataParamsI {
  tradingPairId: string;
}

export const OrderBookWsContextProvider: FC<WsDataParamsI> = ({ tradingPairId, children }) => {
  const [isReady, setReady] = useState(false);
  const [wsMessage, setWsMessage] = useState<string>('');

  const [availableTradingPairIds, setAvailableTradingPairIds] = useState<number[]>([]);
  const [tradingPairGroupings, setTradingPairGroupings] = useState<number[]>([]);
  const [tradingPairGrouping, setTradingPairGrouping] = useState<number | undefined>();
  const [asksData, setAsksData] = useState<OrderBookItemI[]>([]);
  const [bidsData, setBidsData] = useState<OrderBookItemI[]>([]);

  const isReadyRef = useRef(false);
  const wsRef = useRef<WebSocket>();

  useEffect(() => {
    if (wsRef.current) {
      return;
    }

    const socket = new WebSocket(config.orderBookWebSocket);

    socket.onopen = () => {
      setReady(true);
      isReadyRef.current = true;
    };
    socket.onclose = () => {
      setReady(false);
      isReadyRef.current = false;
    };
    socket.onmessage = (event) => setWsMessage(event.data);

    wsRef.current = socket;

    return () => {
      wsRef.current = undefined;
      isReadyRef.current = false;
      socket.close();
    };
  }, []);

  const send = useCallback((message: string) => {
    if (wsRef.current && isReadyRef.current) {
      wsRef.current.send(message);
    }
  }, []);

  useEffect(() => {
    if (!isReady || !wsMessage) {
      return;
    }

    const parsedMessage = JSON.parse(wsMessage);
    if (isTradingPairsMessage(parsedMessage)) {
      setAvailableTradingPairIds(parsedMessage.tradingPairs);
    } else if (isAvailableGroupingsMessage(parsedMessage)) {
      setTradingPairGroupings(parsedMessage.available_groupings);
      setTradingPairGrouping(parsedMessage.available_groupings[0]);
    } else if (isSelectedGroupingMessage(parsedMessage)) {
      const asks: OrderBookRowI[] = parsedMessage.asks_levels.map((level, index) => ({
        level,
        volume: parsedMessage.asks_volumes[index],
      }));
      const bids: OrderBookRowI[] = parsedMessage.bids_levels.map((level, index) => ({
        level,
        volume: parsedMessage.bids_volumes[index],
      }));

      const asksTotal = asks.reduce((total, current) => total + current.volume, 0);
      const bidsTotal = bids.reduce((total, current) => total + current.volume, 0);

      setAsksData(
        asks.map(({ level, volume }, index) => ({
          id: index,
          total: asksTotal,
          size: asksTotal,
          price: volume,
          fill: level,
        }))
      );
      setBidsData(
        bids.map(({ level, volume }, index) => ({
          id: index,
          total: asksTotal,
          size: bidsTotal,
          price: volume,
          fill: level,
        }))
      );
    }
  }, [isReady, wsMessage]);

  useEffect(() => {
    setTradingPairGroupings([]);
    setAsksData([]);
    setBidsData([]);
  }, [tradingPairId]);

  useEffect(() => {
    if (isReady && availableTradingPairIds.includes(+tradingPairId)) {
      send &&
        send(JSON.stringify({ trading_pair_id: tradingPairId, Grouping: tradingPairGrouping }));
    }
  }, [isReady, send, tradingPairId, tradingPairGrouping, availableTradingPairIds]);

  const contextValue: OrderBookWsContextI = useMemo(
    () => ({
      orderBookAsks: asksData,
      orderBookBids: bidsData,
      tradingPairGroupings: tradingPairGroupings,
      tradingPairGrouping,
      setTradingPairGrouping,
    }),
    [asksData, bidsData, tradingPairGroupings, tradingPairGrouping]
  );

  return <OrderBookWsContext.Provider value={contextValue}>{children}</OrderBookWsContext.Provider>;
};
