import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState, useCallback } from 'react';

import { Box, Typography } from '@mui/material';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';

import { Panel } from 'components/panel';
import { Paginator } from 'components/paginator';
import { PortfolioTable } from './portfolio-table';
import { WidgetNoItems } from 'pages/trade-desk/widget-noItems';
import { HcxCircleIcon } from 'components/icons/hcx-circle-icon';

import { TradingPair, useUsersTradingPairsQuery } from 'generated/graphql';

import { constructPortfolioViewModel } from './helpers';

import { QueryPagingI } from 'types';
import { TradingPairWithBalanceI } from './types';

import { useRenderToHeaderTradingInfo } from 'components/layout/trading-info/header-trading-info';

import styles from './portfolio.module.scss';

export const Portfolio: FC = () => {
  const [userTradingPairs, setUserTradingPairs] = useState<TradingPairWithBalanceI[]>([]);
  const [queryPaging, setQueryPaging] = useState<QueryPagingI>({ limit: 50, offset: 0 });

  const { t } = useTranslation('portfolio');

  const { data: queryResponse } = useUsersTradingPairsQuery({
    variables: { input: queryPaging },
  });

  const handlePaginationChange = useCallback(
    (newQueryPaging) => setQueryPaging(newQueryPaging),
    []
  );

  useEffect(() => {
    if (queryResponse) {
      const tradingPairs = queryResponse.userTradingPairs.data.filter(
        (entry): entry is TradingPair => !!entry
      );
      constructPortfolioViewModel(tradingPairs).then((data) => setUserTradingPairs(data));
    }
  }, [queryResponse]);

  const { clear: clearHeader } = useRenderToHeaderTradingInfo(
    null,
    useMemo(() => <Typography variant="body1">{t('portfolio-performance')}</Typography>, [t])
  );

  useEffect(() => () => clearHeader(), [clearHeader]);

  if (!userTradingPairs.length) {
    return (
      <WidgetNoItems
        text={<Typography variant="subtitle1">{t('there-is-no-data-available')}</Typography>}
        icon={<HcxCircleIcon className={styles.nullStateIcon} />}
      />
    );
  }

  return (
    <Box className={styles.root}>
      <Panel>
        <Box className={styles.header}>
          <DataSaverOffIcon className={styles.icon} />
          <Typography variant="subtitle1">{t('portfolio-assets')}</Typography>
        </Box>
        <PortfolioTable userTradingPairs={userTradingPairs} />
        <Paginator
          pagination={{
            limit: queryPaging.limit,
            offset: queryPaging.offset,
            total: queryResponse?.userTradingPairs.pagination.total || 0,
          }}
          handlePaginationChange={handlePaginationChange}
        />
      </Panel>
    </Box>
  );
};
