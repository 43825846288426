import { FC } from 'react';

const BookOpenOutline: FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.75 0H2.25C1.85218 0 1.47064 0.158035 1.18934 0.43934C0.908035 0.720644 0.75 1.10218 0.75 1.5V11.25C0.75 11.6478 0.908035 12.0294 1.18934 12.3107C1.47064 12.592 1.85218 12.75 2.25 12.75H15.75C16.1478 12.75 16.5294 12.592 16.8107 12.3107C17.092 12.0294 17.25 11.6478 17.25 11.25V1.5C17.25 1.10218 17.092 0.720644 16.8107 0.43934C16.5294 0.158035 16.1478 0 15.75 0V0ZM2.25 11.25V1.5H8.25V11.25H2.25ZM15.75 11.25H9.75V1.5H15.75V11.25ZM10.5 4.125H15V5.25H10.5V4.125ZM10.5 6H15V7.125H10.5V6ZM10.5 7.875H15V9H10.5V7.875Z" />
  </svg>
);

export default BookOpenOutline;
