import styles from './styles.module.scss';
import Box from '@mui/material/Box';
import { HcxLogo } from 'components/design';
import type { FC } from 'react';

export const Card: FC = ({ children }) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.card}>
        <HcxLogo className={styles.logo} withDescription />
        {children}
      </Box>
    </Box>
  );
};
