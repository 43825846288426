import type { FC } from 'react';
import { Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  DoctorType,
  PromotedTalent,
  TypeDetails,
  usePromotedTalentsListQuery,
} from 'generated/graphql';
import { ItemCard } from './item-card';
import styles from './horizontal-carousel.module.scss';

export function medicalTypeValidator(form: TypeDetails): form is DoctorType {
  return !!(form as DoctorType)?.medicalCollege;
}

export const HorizontalCarousel: FC = () => {
  const { data: talentsList } = usePromotedTalentsListQuery();

  const itemsPerPage = 4;

  const array_chunks = (array: PromotedTalent[], chunk_size: number) => {
    if (!array) return [];
    return Array(Math.ceil(array.length / chunk_size))
      .fill('')
      .map((_, index) => index * chunk_size)
      .map((begin) => array.slice(begin, begin + chunk_size));
  };

  const cardChunks = array_chunks(
    talentsList === undefined ? [] : talentsList.promotedTalents,
    itemsPerPage
  );

  return (
    <Box>
      <Carousel
        autoPlay={false}
        PrevIcon={<ArrowBackIosNewIcon className={styles.icons} />}
        NextIcon={<ArrowForwardIosIcon className={styles.icons} />}
        navButtonsProps={{
          style: {
            minWidth: '30px',
            height: '100%',
            padding: '0',
            backgroundColor: 'var(--hcx-accordion-background)',
            borderRadius: '2px',
          },
        }}
        navButtonsWrapperProps={{
          style: {
            top: '-71px',
          },
        }}
        indicators={false}
        swipe
        animation="slide"
        height="181px"
        duration={600}
        navButtonsAlwaysVisible
        cycleNavigation
        fullHeightHover
        className={styles.carousel}
      >
        {talentsList &&
          cardChunks.map((chunk, id) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={id} display="flex">
              {chunk.map((item) => (
                <ItemCard key={item.profileImageUrl} card={item} />
              ))}
            </Box>
          ))}
      </Carousel>
    </Box>
  );
};
