import { useCallback, useState } from 'react';
import { useTalentApplicationSponsorsListQuery } from 'generated/graphql';
import { useAtom } from 'jotai';
import {
  talentSponsorsListFilter,
  talentSponsorsListOrderDirection,
  talentSponsorsListOrderType,
} from 'store/talent-application.store';

export const useTalentSponsorsList = () => {
  const [orderType] = useAtom(talentSponsorsListOrderType);
  const [orderDirection] = useAtom(talentSponsorsListOrderDirection);
  const [filter] = useAtom(talentSponsorsListFilter);

  const [queryPaging, setQueryPaging] = useState({ limit: 4, offset: 0 });

  const handlePaginationChange = useCallback(
    (newQueryPaging) => setQueryPaging(newQueryPaging),
    [setQueryPaging]
  );

  const { limit, offset } = queryPaging;

  const {
    error,
    data: sponsorList,
    refetch,
    loading,
  } = useTalentApplicationSponsorsListQuery({
    variables: {
      offset,
      limit,
      orderType,
      orderDirection,
      filter,
    },
  });

  return { sponsorList, refetch, error, loading, handlePaginationChange, queryPaging };
};
