import type { FC } from 'react';
import { useCallback } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ActivityPanelIcon } from 'components/icons/activity-panel-icon';
import { Close } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import styles from './sponsor-panel-header.module.scss';
import { SponsorPanelModal } from '../sponsor-panel-modal';
import { sponsorPanelView } from 'store/sponsor-application.store';
import { sponsorApplicationFormAtom } from 'store/sponsor-application.store';
import { sponsorApplicationModalView } from 'store/sponsor-application.store';
import { SubmitButton } from '../sponsor-submit-button';
import { SponsorApplicationStatus } from 'generated/graphql';

export const SponsorPanelHeader: FC<{
  openActivityPanel: () => void;
}> = ({ openActivityPanel }) => {
  const { t } = useTranslation('sponsor-application');
  const [openModal, setModalView] = useAtom(sponsorApplicationModalView);
  const [sponsorForm] = useAtom(sponsorApplicationFormAtom);
  const [, setSponsorPanelView] = useAtom(sponsorPanelView);

  const modalOpen = useCallback(() => setModalView(true), [setModalView]);
  const closeModal = useCallback(() => setModalView(false), [setModalView]);
  const closePanel = useCallback(() => setSponsorPanelView(false), [setSponsorPanelView]);
  const handleOpenActivity = useCallback(() => {
    openActivityPanel();
  }, [openActivityPanel]);

  const statusInitiated = sponsorForm?.status === SponsorApplicationStatus.Initiated;

  const onClose = () => {
    if (statusInitiated) {
      modalOpen();
    } else {
      closePanel();
      closeModal();
    }
  };

  return (
    <>
      <Box className={styles.root}>
        <Typography className={styles.headerTitle}>{t('sponsor-application')}</Typography>
        <Box>
          {statusInitiated && <SubmitButton />}
          <IconButton className={styles.activityButton} onClick={handleOpenActivity}>
            <ActivityPanelIcon />
          </IconButton>
          <IconButton className={styles.closeButton} onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <SponsorPanelModal openModal={openModal} closeModal={closeModal} />
    </>
  );
};
