import { useState, useEffect, useCallback } from 'react';
import { config } from 'config';

export const useTalentImage = (s3Key: string) => {
  const [img, setImg] = useState('');

  const fetchImage = useCallback(
    async (key: string) => {
      if (s3Key) {
        const res = await fetch(`${config.s3Api}/${key}`);
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImg(imageObjectURL);
      }
    },
    [s3Key]
  );

  useEffect(() => {
    if (s3Key) {
      fetchImage(s3Key).then();
    }
  }, [s3Key, fetchImage]);

  return { img };
};
