import { useCallback, useEffect } from 'react';
import { useAtom } from 'jotai';

import {
  favoriteAthleteIdsAtom,
  FavoriteIdsT,
  addFavoriteAthleteIdAtom,
  removeFavoriteAthleteIdAtom,
} from 'store/favorites.store';

import {
  Athlete,
  useFavoriteAthleteIdsQuery,
  useFavoriteAthleteMutation,
  useUnfavoriteAthleteMutation,
} from 'generated/graphql';

export const useFavorizer = (athleteId: string) => {
  const { data } = useFavoriteAthleteIdsQuery();

  const [handleFavoriteAthlete] = useFavoriteAthleteMutation();
  const [handleUnfavoriteAthlete] = useUnfavoriteAthleteMutation();

  const [favoriteAthleteIds, setFavoriteAthleteIds] = useAtom(favoriteAthleteIdsAtom);
  const [, addFavoriteAthlete] = useAtom(addFavoriteAthleteIdAtom);
  const [, removeFavoriteAthlete] = useAtom(removeFavoriteAthleteIdAtom);

  useEffect(() => {
    if (data?.favoriteAthletes && data.favoriteAthletes.length > 0) {
      const loadedFavoriteAthleteIds: FavoriteIdsT = data.favoriteAthletes
        .filter((entry): entry is Athlete => !!entry)
        .map((athlete) => `${athlete.id}`);
      setFavoriteAthleteIds(loadedFavoriteAthleteIds);
    }
  }, [data?.favoriteAthletes, setFavoriteAthleteIds]);

  const favoriteAthlete = useCallback(async () => {
    await handleFavoriteAthlete({
      variables: {
        athleteId,
      },
    });
    addFavoriteAthlete(athleteId);
  }, [athleteId, handleFavoriteAthlete, addFavoriteAthlete]);

  const unfavoriteAthlete = useCallback(async () => {
    await handleUnfavoriteAthlete({
      variables: {
        athleteId,
      },
    });
    removeFavoriteAthlete(athleteId);
  }, [athleteId, handleUnfavoriteAthlete, removeFavoriteAthlete]);

  return {
    favoriteAthletes: favoriteAthleteIds,
    favoriteAthlete,
    unfavoriteAthlete,
  };
};
