import { Box, Divider, Link, Typography } from '@mui/material';
import {
  ATHLETE_TAB_SUMMARY_BIO_COLUMNS_CONFIG,
  ATHLETE_TAB_SUMMARY_CAREER_COLUMNS_CONFIG,
  InfoTable,
} from './info-table';
import { AthleteBioI, AthleteCareerI /* , AthleteCareerHistoryI */, AthleteI } from 'types';
import { CareerHighlightsTable } from './career-highlights-table';
import { ATHLETE_SUMMARY_HIGHLIGHTS_COLUMNS_CONFIG } from './career-highlights-table/helpers';
import { useTranslation } from 'react-i18next';
import { HCXCardSummaryTabComponentT } from '../hcx-card-summary.helpers';

import styles from './hcx-card-summary-info.module.scss';

export const HCXCardSummaryInfo: HCXCardSummaryTabComponentT<{ athlete: AthleteI }> = ({
  setTab,
  athlete,
}) => {
  const { t } = useTranslation('hcx-card');
  /* const athleteCareerHistoryColumnConfig = useMemo(
    () => (athlete ? GET_ATHLETE_TAB_SUMMARY_CAREER_HISTORY_COLUMNS_CONFIG(athlete) : null),
    [athlete]
  ); */

  if (!athlete?.id) return null;

  const handleViewStats = () => {
    setTab?.(1);
  };

  return (
    <>
      <Box className={styles.infoTable}>
        <InfoTable<AthleteBioI>
          title={t('info')}
          data={athlete.bio}
          columns={ATHLETE_TAB_SUMMARY_BIO_COLUMNS_CONFIG}
        />
      </Box>
      <Divider />
      <Box className={styles.infoTable}>
        <InfoTable<AthleteCareerI>
          title={t('career')}
          data={athlete.career}
          columns={ATHLETE_TAB_SUMMARY_CAREER_COLUMNS_CONFIG}
        />
      </Box>
      <Divider />
      {/* <Box className={styles.infoTable}>
        {!!athleteCareerHistoryColumnConfig && (
          <InfoTable<AthleteCareerHistoryI>
            title="Career History"
            data={athlete.career.history[0]}
            columns={athleteCareerHistoryColumnConfig}
          />
        )}
      </Box> */}
      <Box className={styles.infoTable}>
        <Typography className={styles.highlightsTitle}>Career Highlights</Typography>
        <CareerHighlightsTable
          title={t('receiving')}
          action={
            <Link onClick={handleViewStats} className={styles.pointer}>
              {t('view-complete-stats')}
            </Link>
          }
          rows={athlete.career.stats.career.receiving}
          columns={ATHLETE_SUMMARY_HIGHLIGHTS_COLUMNS_CONFIG}
        />
      </Box>
    </>
  );
};
