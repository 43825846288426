import type { FC } from 'react';
import { useState, useCallback, useMemo, useEffect } from 'react';

import classNames from 'classnames';

import { useTranslation } from 'react-i18next';

import { Box, Divider, IconButton, Tab, Tabs, TabsProps, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { actOnBidAbi } from 'blockchain-api/bid-orders/act-on-bid-abi';
import { WalletE } from 'blockchain-api/ewallets-api';
import { Button } from 'components/button';
import { TabPanel } from 'components/tab-panel';
import { Panel } from 'components/panel';
import {
  BidOrderStatus,
  DutchAuctionBidFieldsFragment,
  DutchAuctionStatus,
  useAuctionAllBidsQuery,
  useFinalizeAuctionMutation,
} from 'generated/graphql';
import { getUserBids } from 'pages/human-capital-mint/helpers';
import { a11yProps } from 'utils/a11y';
import { noop } from 'utils/noop';

import { refundBidAbi } from 'blockchain-api/bid-orders';
import { formatToUSD } from 'utils/currency-formatter';
import {
  NormalizationDecimalsE,
  NormalizationLogicE,
  normalizeDecimals,
} from 'utils/number-formatter';

import { ActiveMintsChart, ActiveMintsChartPropsI } from '../active-mints-chart/active-mints-chart';

import { useBidsAndStats } from 'pages/human-capital-mint/hooks';

import styles from './active-mints-tabs.module.scss';

const TABS: {
  label: string;
  component: FC<ActiveMintsChartPropsI> | null;
}[] = [
  { label: 'Active', component: ActiveMintsChart },
  { label: 'Cancelled', component: null },
  { label: 'Scheduled', component: null },
  { label: 'Completed', component: null },
];

const tabLabels = TABS.map(({ label }) => label);

interface ActiveMintsTabsPropsI {
  refetchAuctions: typeof noop;
  onBidClick: typeof noop;
  disableButton: boolean;
  dutchAuctionId: string;
  auctionBlockchainId: number;
  auctionEndPrice: number;
  mintingStatus: DutchAuctionStatus;
}

export const ActiveMintsTabs: FC<ActiveMintsTabsPropsI> = ({
  onBidClick,
  refetchAuctions,
  disableButton,
  dutchAuctionId,
  auctionBlockchainId,
  auctionEndPrice,
  mintingStatus,
}) => {
  const {
    chartData,
    thresholdPrice,
    userBidData: { userBidPrice, userBidAmount },
    handleUpdateUserBid,
  } = useBidsAndStats(dutchAuctionId);

  const [userBids, setUserBids] = useState<DutchAuctionBidFieldsFragment[]>([]);
  const [tabIdx, setTabIdx] = useState<number>(0);

  const { data, startPolling, stopPolling } = useAuctionAllBidsQuery({
    variables: { auctionId: Number(dutchAuctionId) },
  });

  useEffect(() => {
    startPolling(2000);

    return () => stopPolling();
  });

  const [finalizeAuction, { loading }] = useFinalizeAuctionMutation();

  const { t } = useTranslation('human-capital-mint');

  useEffect(() => {
    if (data) {
      getUserBids(data).then((userBidsData) => {
        setUserBids(userBidsData);
      });
    }
  }, [data]);

  const isBelowThreshold = thresholdPrice > userBidPrice && userBidPrice > 0 ? true : false;

  const isActOnBidAvailable = useMemo(() => {
    if (userBids.length < 1) {
      return false;
    }
    // There should be only one available bid from user
    return userBids[0].status === BidOrderStatus.Open;
  }, [userBids]);

  const isBidActed = useMemo(() => {
    if (userBids.length < 1) {
      return false;
    }
    // There should be only one available bid from user
    return userBids[0].status !== BidOrderStatus.Open;
  }, [userBids]);

  const isUserBidWon = useMemo(() => {
    if (userBids.length < 1) {
      return false;
    }
    // There should be only one available bid from user
    return userBids[0].tokenPrice >= auctionEndPrice;
  }, [userBids, auctionEndPrice]);

  const isUserBidClaimed = useMemo(() => {
    if (userBids.length < 1) {
      return false;
    }
    // There should be only one available bid from user
    return userBids[0].status === BidOrderStatus.Claimed;
  }, [userBids]);

  const web3Functions = useMemo(
    () => ({
      actOnBidAbi,
      refundBidAbi,
    }),
    []
  );

  const handleChange: TabsProps['onChange'] = (_, newValue: number) => {
    setTabIdx(newValue);
  };

  const handleFinalizeAuction = useCallback(async () => {
    if (mintingStatus === DutchAuctionStatus.Closed) {
      return;
    }

    const confirmResult = window.confirm('Do you want to Finalize the Auction?');
    if (confirmResult) {
      await finalizeAuction({
        variables: {
          auctionId: dutchAuctionId,
        },
      });

      await refetchAuctions();
    }
  }, [dutchAuctionId, finalizeAuction, refetchAuctions, mintingStatus]);

  const handleClaimTokens = useCallback(async () => {
    if (mintingStatus !== DutchAuctionStatus.Closed || !isActOnBidAvailable) {
      return;
    }

    const confirmResult = window.confirm(
      `Do you want to ${isUserBidWon ? 'Claim' : 'Return'} your tokens?`
    );
    if (confirmResult) {
      await web3Functions.actOnBidAbi(WalletE.Metamask, auctionBlockchainId);
    }
  }, [web3Functions, mintingStatus, auctionBlockchainId, isActOnBidAvailable, isUserBidWon]);

  const handleBidDelete = useCallback(async () => {
    if (userBidPrice === 0) {
      return;
    }
    const confirmResult = window.confirm('Do you want to remove Your bid?');
    if (confirmResult) {
      await web3Functions.refundBidAbi(WalletE.Metamask, auctionBlockchainId);
    }
  }, [auctionBlockchainId, userBidPrice, web3Functions]);

  return (
    <Panel className={styles.root}>
      <Box className={styles.flexWrapper}>
        <Tabs
          value={tabIdx}
          onChange={handleChange}
          aria-label="talent-portal-tabs"
          className={styles.tabs}
        >
          {tabLabels.map((label, idx) => (
            <Tab key={label} label={label} {...a11yProps(idx)} />
          ))}
        </Tabs>
        <Box className={styles.rightSection}>
          <Box className={styles.personalBidWrapper}>
            <Typography
              variant="body2"
              className={classNames(styles.cellName, { [styles.redCellName]: isBelowThreshold })}
            >
              {t('user-bid')}:
            </Typography>
            <div
              className={classNames(styles.personalBidContainer, {
                [styles.redPersonalBidContainer]: isBelowThreshold,
              })}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={styles.personalBidPrefix} />
                <Typography variant="subtitle2">
                  {userBidAmount} @{' '}
                  {formatToUSD(
                    normalizeDecimals(userBidPrice, NormalizationDecimalsE.Six)[
                      NormalizationLogicE.NonDecimal
                    ],
                    { minimumFractionDigits: 2 }
                  )}
                </Typography>
              </div>
              <IconButton
                className={styles.deleteButton}
                onClick={handleBidDelete}
                disabled={!auctionBlockchainId}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </div>
          </Box>
          <Box>
            {mintingStatus !== DutchAuctionStatus.Closed && (
              <Button
                variant="contained"
                size="small"
                disabled={disableButton || loading}
                onClick={handleFinalizeAuction}
                className={styles.button}
              >
                {t('finalize')}
              </Button>
            )}
            {mintingStatus === DutchAuctionStatus.Closed && isActOnBidAvailable && (
              <Button
                variant="contained"
                size="small"
                disabled={loading}
                onClick={handleClaimTokens}
                className={styles.button}
              >
                {isUserBidWon ? t('claim-tokens') : t('return-tokens')}
              </Button>
            )}
            {mintingStatus === DutchAuctionStatus.Closed && isBidActed && (
              <Box className={styles.tokenClaimText}>
                {isUserBidClaimed ? 'Tokens claimed' : 'Tokens returned'}
              </Box>
            )}
            {tabIdx === 0 && mintingStatus === DutchAuctionStatus.Active && (
              <Button
                variant="contained"
                size="small"
                disabled={disableButton}
                onClick={onBidClick}
                className={styles.button}
              >
                {t('bid')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Divider />
      {TABS.map(({ label }, idx) => {
        return (
          <TabPanel key={label} value={tabIdx} index={idx} className={styles.tabPanel}>
            {label === 'Active' ? (
              <ActiveMintsChart
                chartData={chartData}
                thresholdPrice={thresholdPrice}
                userBidPrice={userBidPrice}
                userBidAmount={userBidAmount}
                dutchAuctionId={Number(dutchAuctionId)}
                onUserBid={handleUpdateUserBid}
              />
            ) : null}
          </TabPanel>
        );
      })}
    </Panel>
  );
};
