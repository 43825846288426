import styles from './styles.module.scss';
import InputBase, { InputBaseProps } from '@mui/material/InputBase/InputBase';
import type { FC } from 'react';

const inputComponentProps = {
  root: { className: styles.inputRoot },
  input: { className: styles.input },
};

export const Input: FC<Omit<InputBaseProps, 'componentsProps'>> = (props) => (
  <InputBase componentsProps={inputComponentProps} {...props} />
);
