import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import { TableHeadCell, TableHeadRow, TableCell, TableRow } from 'components/table';

import { formatToUSD } from 'utils/currency-formatter';

import { HeaderDataI, MOCK_HEADER_DATA, getProgress } from '../data.model';
import { PortfolioWidgetContext } from '../../context';

import styles from './header-table.module.scss';

interface TableHeadingI {
  title: string;
  key: keyof HeaderDataI;
}

const TABLE_HEADINGS: TableHeadingI[] = [
  { title: '', key: 'name' },
  { title: 'units', key: 'units' },
  { title: 'talent', key: 'talent' },
  { title: 'value', key: 'value' },
];

const HeaderTable: FC = () => {
  const { progressType } = useContext(PortfolioWidgetContext);
  const { t } = useTranslation('common');
  return (
    <Table>
      <TableHead>
        <TableHeadRow>
          {TABLE_HEADINGS.map((heading: TableHeadingI) => (
            <TableHeadCell key={heading.key} className={styles.headCell}>
              <Typography variant="caption">{t(heading.title)}</Typography>
            </TableHeadCell>
          ))}
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {MOCK_HEADER_DATA.map((e) => (
          <TableRow key={e.name} className={styles.bodyRow}>
            <TableCell className={styles.cell}>
              <Typography variant="body1">{e.name}</Typography>
              <Typography className={styles.caption} color="var(--hcx-font-secondary)">
                You own 4 assets
              </Typography>
            </TableCell>
            <TableCell className={styles.cell}>
              <Typography variant="body1">{e.units}</Typography>
            </TableCell>
            <TableCell className={styles.cell}>
              <Typography variant="body1">{e.talent}</Typography>
            </TableCell>
            <TableCell className={styles.cell}>
              <Typography variant="body1">{formatToUSD(e.value)}</Typography>
              <Typography
                className={styles.caption}
                color={e.gain >= 0 ? 'var(--hcx-alert-up)' : 'var(--hcx-alert-down)'}
              >
                {getProgress(e, progressType)}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default HeaderTable;
