import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';

import type { OrderT } from 'types/orders';
import { OrderTradingTypeE } from 'enums';

import {
  NormalizationDecimalsE,
  NormalizationLogicE,
  normalizeDecimals,
} from 'utils/number-formatter';
import { formatToUSD } from 'utils/currency-formatter';
import { DateFormatE, dateFormatter } from 'utils/date-formatter';
import { getAthleteOrderStatusTranslationKey } from 'utils/get-athlete-order-status-translation-key';

import styles from './orders-list.module.scss';

interface TableConfigI {
  title: string;
  getContent: (data: OrderT, t: TFunction) => ReactNode;
}

export const ordersListConfig: TableConfigI[] = [
  {
    title: 'type',
    getContent: (data, t) => {
      return (
        <p className={data.isBuy ? styles.up : styles.down}>
          {t(
            OrderTradingTypeE[
              data.isBuy ? OrderTradingTypeE.Buy : OrderTradingTypeE.Sell
            ].toLowerCase()
          )}
        </p>
      );
    },
  },
  {
    title: 'talent',
    getContent: (data) => {
      const { fullName, nftSymbol } = data.tradingPair.athlete;
      return (
        <>
          <p className={styles.athleteName} title={fullName}>
            {fullName}
          </p>
          <p className={styles.athleteContract} title={nftSymbol}>
            {nftSymbol}
          </p>
        </>
      );
    },
  },
  {
    title: 'units',
    getContent: (data) => {
      return data.isBuy
        ? normalizeDecimals(data.takerAmount, NormalizationDecimalsE.Two)[
            NormalizationLogicE.NonDecimal
          ]
        : normalizeDecimals(data.amountOfTokens, NormalizationDecimalsE.Two)[
            NormalizationLogicE.NonDecimal
          ];
    },
  },
  {
    title: 'purch-price',
    getContent: (data) => {
      const takerBaseTwo = normalizeDecimals(data.takerAmount, NormalizationDecimalsE.Two)[
        NormalizationLogicE.NonDecimal
      ];
      const takerBaseSix = normalizeDecimals(data.takerAmount, NormalizationDecimalsE.Six)[
        NormalizationLogicE.NonDecimal
      ];
      const makerBaseTwo = normalizeDecimals(data.amountOfTokens, NormalizationDecimalsE.Two)[
        NormalizationLogicE.NonDecimal
      ];
      const makerBaseSix = normalizeDecimals(data.amountOfTokens, NormalizationDecimalsE.Six)[
        NormalizationLogicE.NonDecimal
      ];
      const unitPrice = data.isBuy ? makerBaseSix / takerBaseTwo : takerBaseSix / makerBaseTwo;
      const result = formatToUSD(unitPrice);
      return <p title={result}>{result}</p>;
    },
  },
  {
    title: 'status',
    getContent: (data: OrderT, t) => {
      const { status, date } = data;
      return (
        <>
          <p className={styles.status}>{t(getAthleteOrderStatusTranslationKey(status))}</p>
          <p>{dateFormatter(date, DateFormatE.MM_DD_YY)}</p>
        </>
      );
    },
  },
];
