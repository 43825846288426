import type { FC } from 'react';
import { BaseModal, BaseModalPropsI } from 'components/modals/base-modal';
import { useAtom } from 'jotai';
import {
  sponsorApplicationFormAtom,
  sponsorApplicationModalView,
  sponsorErrorForm,
  sponsorFormValidationAtom,
  sponsorPanelView,
} from 'store/sponsor-application.store';
import { Button } from 'components/button';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './sponsor-panel-modal.module.scss';
import { SponsorApplicationStatus } from 'generated/graphql';

const modalClasses = {
  paper: styles.root,
};

const backdropProps: BaseModalPropsI['BackdropProps'] = {
  classes: {
    root: styles.backdrop,
  },
};

interface SponsorPanelModalPropsI {
  openModal: boolean;
  closeModal: () => void;
}

export const SponsorPanelModal: FC<SponsorPanelModalPropsI> = ({ openModal, closeModal }) => {
  const [sponsorForm, setSponsorForm] = useAtom(sponsorApplicationFormAtom);
  const [, setErrors] = useAtom(sponsorFormValidationAtom);
  const [, setSponsorPanelOpen] = useAtom(sponsorPanelView);
  const [, setModalView] = useAtom(sponsorApplicationModalView);

  const { t } = useTranslation('sponsor-application');

  const isNotSubmitted = sponsorForm?.status !== SponsorApplicationStatus.Submitted;

  const handleClick = () => {
    if (isNotSubmitted) {
      setSponsorForm(null);
      setErrors(sponsorErrorForm);
    }
    setSponsorPanelOpen(false);
    setModalView(false);
  };

  return (
    <BaseModal
      open={openModal}
      classes={modalClasses}
      BackdropProps={backdropProps}
      className={styles.modal}
      disableScrollLock={false}
      fullWidth
      actions={
        isNotSubmitted && (
          <div className={styles.actions}>
            <Link onClick={closeModal}>{t('go-back')}</Link>
          </div>
        )
      }
    >
      <div className={styles.contentWrapper}>
        <Typography variant="subtitle1">
          {isNotSubmitted ? t('close-sponsor-application') : t('application-submitted')}
        </Typography>
        <Typography variant="body1" className={styles.textSecondary}>
          {isNotSubmitted ? t('you-will-lose-app-data') : t('you-notified-app-approved')}
        </Typography>
        <Button
          variant="contained"
          size="small"
          className={styles.goPublicBtn}
          onClick={handleClick}
        >
          {isNotSubmitted ? t('close-sponsor-app') : t('close')}
        </Button>
      </div>
    </BaseModal>
  );
};
