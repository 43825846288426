import type { FC } from 'react';
import { useAtom } from 'jotai';
import classNames from 'classnames';

import { GET_ATHLETE_LAST_PRICE_CONFIG } from 'components/athlete-trading-info';
import { UserAvatar } from 'components/user-avatar';

import { formatToUSD } from 'utils/currency-formatter';
import { formatNumber } from 'utils/number-formatter';

import { WidgetProgressE } from 'pages/trade-desk/widget-wrapper';
import { AthleteI } from 'types';

import { latestTradingStatsAtom } from 'store/trading-stats.store';

import styles from './widget-athlete-list-medium-row.module.scss';

const WidgetAthleteListMediumRow: FC<{ athlete: AthleteI; progressType: WidgetProgressE }> = ({
  athlete,
  progressType,
}) => {
  const [tradingStats] = useAtom(latestTradingStatsAtom);
  const {
    bio: { name, photo },
    tradingInfo: { tradingPairId },
  } = athlete;

  if (!tradingStats || !tradingStats[tradingPairId]) return null;

  const { value, progressAmount, progressPercentage } = GET_ATHLETE_LAST_PRICE_CONFIG(
    tradingStats[tradingPairId]
  );
  return (
    <tr>
      <td>
        <UserAvatar name={name} photo={photo} />
      </td>
      <td>{name}</td>
      <td>{typeof value === 'string' ? value : formatToUSD(value)}</td>
      <td>
        <span
          className={classNames({
            [styles.up]: progressAmount > 0,
            [styles.down]: progressAmount < 0,
          })}
        >
          {typeof progressAmount === 'number' &&
            typeof progressPercentage === 'number' &&
            (progressType === WidgetProgressE.Currency
              ? formatToUSD(progressAmount, { signDisplay: 'exceptZero' })
              : `${formatNumber(progressPercentage, { signDisplay: 'exceptZero' })}%`)}

          {typeof progressAmount === 'string' &&
            typeof progressPercentage === 'string' &&
            (progressType === WidgetProgressE.Currency ? progressAmount : progressPercentage)}
        </span>
      </td>
    </tr>
  );
};

export default WidgetAthleteListMediumRow;
