import { FC, useMemo } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

import styles from './tooltip.module.scss';
import classNames from 'classnames';

export type HCXTooltipPropsT = TooltipProps;

const defaultClasses: HCXTooltipPropsT['classes'] = {
  tooltip: styles.tooltip,
  arrow: styles.arrow,
};

const HCXTooltip: FC<HCXTooltipPropsT> = ({
  children,
  className,
  classes: customClasses,
  ...props
}) => {
  const classes = useMemo(
    () =>
      customClasses
        ? {
            ...defaultClasses,
            ...customClasses,
          }
        : defaultClasses,
    [customClasses]
  );
  return (
    <Tooltip className={classNames(styles.root, className)} classes={classes} {...props}>
      {children}
    </Tooltip>
  );
};

export default HCXTooltip;
