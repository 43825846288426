import { useAtom } from 'jotai';
import type { FC } from 'react';

import { Box, Typography, IconButton } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { AthleteCardPriceLineApollo } from 'components/athlete/athlete-card/athlete-card-price-line/athlete-card-price-line.apollo';
import { AthleteFavoriteStar } from 'components/athlete/athlete-favorite-star';
import { AthleteBidBuySellBtn } from 'components/athlete/athlete-bid_buy_sell-btn';
import {
  useOpenChartSettings,
  useSaveChartAsImg,
  useTakeChartScreenshot,
  WidgetRefT,
} from 'components/tv-chart-container';
import { selectedAthleteIAtom } from 'store/athletes.store';

import CooperKupp from 'moc-data/img/CooperKupp.png';

import styles from './overview-chart-header.module.scss';
import { selectedTradeItemIAtom } from 'store/trade-desk.store';

export enum TypeE {
  Trade = 'trade',
}

export const OverviewChartHeader: FC<{ chartWidgetRef: WidgetRefT; type?: TypeE }> = ({
  chartWidgetRef,
  type,
}) => {
  const [athleteAtom] = useAtom(selectedAthleteIAtom);
  const [athleteTrade] = useAtom(selectedTradeItemIAtom);
  const saveChartAsImg = useSaveChartAsImg(chartWidgetRef);
  const takeChartScreenshot = useTakeChartScreenshot(chartWidgetRef);
  const openChartSettings = useOpenChartSettings(chartWidgetRef);
  let athlete;
  if (type === TypeE.Trade) {
    athlete = athleteTrade;
  } else {
    athlete = athleteAtom;
  }
  if (!athlete?.id) {
    return null;
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.photoWrapper}>
        <img src={CooperKupp} alt="athlete" className={styles.photo} />
        <AthleteFavoriteStar id={athlete.id} className={styles.rating} />
      </Box>
      <Box className={styles.nameWrapper}>
        <Typography title={athlete.bio.name} className={styles.name}>
          {athlete.bio.name}
        </Typography>
        <Typography className={styles.contractId}>{athlete.tradingInfo.contractId}</Typography>
      </Box>
      <AthleteCardPriceLineApollo athlete={athlete} className={styles.priceLineWrapper} />
      <Box className={styles.rightSection}>
        <Box className={styles.controlsWrapper}>
          <Box className={styles.chartButtonsWrapper}>
            <IconButton onClick={saveChartAsImg}>
              <FileDownloadOutlinedIcon />
            </IconButton>
            <IconButton onClick={takeChartScreenshot}>
              <CameraAltOutlinedIcon />
            </IconButton>
            <IconButton onClick={openChartSettings}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Box>
          <AthleteBidBuySellBtn athlete={athlete} className={styles.buySellBtn} />
        </Box>
      </Box>
    </Box>
  );
};
