import { FC, ReactNode, MouseEventHandler } from 'react';
import classNames from 'classnames';
import {
  AccordionDetails,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Accordion, AccordionSummary } from 'components/accordion';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { Button } from 'components/button';
import { DelayField, DelayFieldPropsI } from './delay-block';

import styles from './talent-portal-connect-social-row.module.scss';

const ACCORDION_CLASSES = {
  root: styles.accordion,
};

const SUMMARY_CLASSES = {
  root: styles.summary,
  content: styles.summaryContent,
  expandIconWrapper: styles.iconColor,
};

const DETAILS_CLASSES = {
  root: styles.details,
};

export interface TalentPortalConnectSocialTradeDelayI {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface TalentPortalConnectSocialRowDataI {
  checked: boolean;
  shareWithFriends: boolean;
  shareWithFollowers: boolean;
  postTradeDelay: TalentPortalConnectSocialTradeDelayI;
}

export interface TalentPortalConnectSocialRowPropsI {
  name: string;
  icon?: ReactNode;
  data: TalentPortalConnectSocialRowDataI;
  onChangeData: (name: string, newData: TalentPortalConnectSocialRowDataI) => void;
}

const stopPropagation: MouseEventHandler = (e) => e.stopPropagation();

const TalentPortalConnectSocialRow: FC<TalentPortalConnectSocialRowPropsI> = ({
  name,
  icon,
  data,
  onChangeData,
}) => {
  const onChangeChecked: SwitchProps['onChange'] = (_, checked) => {
    onChangeData(name, { ...data, checked });
  };
  const onChangeDelay: DelayFieldPropsI['onChange'] = ({ name: fieldName, value }) => {
    onChangeData(name, {
      ...data,
      postTradeDelay: { ...data.postTradeDelay, [fieldName]: value },
    });
  };
  const onChangeShare: CheckboxProps['onChange'] = (e, checked) => {
    const { name: fieldName } = e.target;
    onChangeData(name, { ...data, [fieldName]: checked });
  };
  return (
    <Accordion classes={ACCORDION_CLASSES}>
      <AccordionSummary classes={SUMMARY_CLASSES} expandIcon={<ArrowDropDownIcon />}>
        {icon && <div className={classNames(styles.socialIcon, styles.iconColor)}>{icon}</div>}
        <Typography variant="subtitle2" className={styles.title}>
          {name}
        </Typography>
        <Switch
          size="small"
          className={styles.switcher}
          checked={data.checked}
          onClick={stopPropagation}
          onChange={onChangeChecked}
        />
        <IconButton onClick={stopPropagation}>
          <SettingsOutlinedIcon fontSize="small" className={styles.iconColor} />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails classes={DETAILS_CLASSES}>
        <div className={styles.checkboxes}>
          <FormControlLabel
            control={
              <Checkbox
                name="shareWithFriends"
                size="small"
                onChange={onChangeShare}
                checked={data.shareWithFriends}
              />
            }
            label="Share with friends"
            labelPlacement="end"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="shareWithFollowers"
                size="small"
                onChange={onChangeShare}
                checked={data.shareWithFollowers}
              />
            }
            label="Share with followers"
            labelPlacement="end"
          />
        </div>
        <p className={styles.delayTitle}>Post trades with a delay:</p>

        <div className={styles.delayBlock}>
          <DelayField
            value={data.postTradeDelay.days}
            name="days"
            max={365}
            onChange={onChangeDelay}
            label="Days"
          />
          <DelayField
            value={data.postTradeDelay.hours}
            name="hours"
            max={24}
            onChange={onChangeDelay}
            label="Hours"
          />
          <DelayField
            value={data.postTradeDelay.minutes}
            name="minutes"
            max={60}
            onChange={onChangeDelay}
            label="Minutes"
          />
          <DelayField
            value={data.postTradeDelay.seconds}
            name="seconds"
            max={60}
            onChange={onChangeDelay}
            label="Seconds"
          />
          <Button variant="text" size="small" className={styles.saveBtn}>
            Save
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default TalentPortalConnectSocialRow;
