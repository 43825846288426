import {
  SexE,
  AthleteRosterStatusE,
  OrderStatusE,
  AthleteBidStatusE,
  AthleteIcoStatusE,
  OrderTradingTypeE,
} from 'enums';
import { MintingStatusE } from 'enums/minting-status';
import { AthleteCareerActivityI, AthleteI, AthleteTradingOrderI } from 'types';
import { randomFloatFromInterval, randomIntFromInterval } from 'utils/randomIntFromInterval';
import CooperKupp from './img/CooperKupp.png';
import KlayThompson from './img/KlayThompson.png';
import TeamLogo1 from './img/team-logo-1.png';
import TeamLogo2 from './img/team-logo-2.png';
import TeamLogo3 from './img/team-logo-3.png';

const names = [
  'Cooper Kupp',
  'Kyler Murray',
  'Patrick Mahomes',
  'Lexi Thompson',
  'Carmelo Anthony',
];

const photos = [CooperKupp, KlayThompson];

const teamLogos = [TeamLogo1, TeamLogo2, TeamLogo3];

const sex = [SexE.Male, SexE.Female];

const contracts = ['C.KUPP', 'T.KLAY.11'];

const titles = [
  'Professional Athlete',
  'Singer and Songwriter',
  'Youtube Personality',
  'American football player',
  'American actor and producer',
  'English association football player',
];

const positions = [
  { long: 'Quarterback', short: 'QB' },
  { long: 'Running Back', short: 'RB' },
  { long: 'Fullback', short: 'FB' },
  { long: 'Wide Receiver', short: 'WR' },
  { long: 'Tight End', short: 'TE' },
  { long: 'Left/Right Offensive Tackle', short: 'LT/RT' },
  { long: 'Left/Right Offensive Guard', short: 'LG/RG' },
  { long: 'Center', short: 'C' },
];

const bidStatuses = [AthleteBidStatusE.Offering, AthleteBidStatusE.CCO, AthleteBidStatusE.ICO];

const mintingStatuses = [MintingStatusE.Closed, MintingStatusE.Active, MintingStatusE.Scheduled];

const orderTypes = [
  OrderStatusE.Open,
  OrderStatusE.Canceled,
  OrderStatusE.Filled,
  OrderStatusE.PartialFill,
];

const orderTradingTypes = [OrderTradingTypeE.Buy, OrderTradingTypeE.Sell];

export const getFromArray = <T>(id: number, array: T[]): T => array[id % array.length];

export const generateItemsList = <T>(qty: number, itemCreator: (id: number) => T): T[] =>
  Array.from(new Array(qty), (_, i) => itemCreator(i + 1));

export const createMocOrder = (id: number): AthleteTradingOrderI => {
  const status = getFromArray(id, orderTypes);
  return {
    id,
    number: 232239230023 + id,
    status: getFromArray(id, orderTypes),
    type: getFromArray(id, orderTradingTypes),
    date: '3/4/22 4:15:05 GMT',
    statusDate: '3/4/22 8:15:05 GMT',
    buyDescription: 'Buy $2,500 C.Kupp.16 @ $300.00 limit | GTC',
    filledDescription:
      status === OrderStatusE.Filled ? 'Filled $2,500 @ $295.16 - 3/4/22 - 4:05:00 GMT' : '',
  };
};

export const createMocActivity = (id: number): AthleteCareerActivityI => {
  return {
    id,
    tgt: randomIntFromInterval(0, 15),
    rec: randomIntFromInterval(0, 15),
    yds: randomIntFromInterval(0, 200),
    td: randomIntFromInterval(0, 2),
    lng: randomIntFromInterval(0, 50),
    teamLogo: getFromArray(id, teamLogos),
    part: `${randomIntFromInterval(1, 12)}/${randomIntFromInterval(1, 28)}`,
  };
};

const longText = '';

const createMocAthlete = (id: number): AthleteI => {
  const position = getFromArray(id, positions);
  return {
    id,
    favorite: !!(id % 2),
    bio: {
      name: `${getFromArray(id, names)} - ${id}` + longText,
      born: 'November 5, 1992',
      sex: getFromArray(id, sex),
      height: '5\' 11"',
      education: 'St. Albans School, Isidore Newman School, Louisiana State University',
      photo: getFromArray(id, photos),
      email: 'support@bookingagentinfo.com',
      lastActivity: (() => {
        const lastActivity = new Date();
        lastActivity.setHours(lastActivity.getHours() - 10);
        return lastActivity.toString();
      })(),
    },
    career: {
      league: {
        name: 'National Football League',
        shortName: 'NFL',
      },
      description:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum omnis ratione animi corporis dolorem veritatis eum blanditiis, repellendus earum ullam a nemo, quos optio! Fuga animi aperiam repudiandae asperiores cupiditate nesciunt magnam sunt, adipisci ut, odio dignissimos! Similique velit recusandae molestiae sequi laudantium debitis autem assumenda quis animi, corporis laboriosam totam consequatur iusto quae dolorum blanditiis vero impedit molestias rerum unde suscipit. Officiis pariatur facere aperiam possimus adipisci! Tempora consequuntur debitis itaque culpa reprehenderit nesciunt nulla rerum. Veniam adipisci, cum corrupti blanditiis quam perferendis, soluta numquam vitae dolores qui consequuntur asperiores nulla delectus totam doloremque quo! Voluptas ratione quam odio?',
      title: getFromArray(id, titles),
      team: 'Los Angeles Rams' + longText,
      position: position.long + longText,
      positionShort: position.short,
      jerseyNumber: 10,
      rosterStatus: AthleteRosterStatusE.Active,
      drafted: '2014, Round 1 / Pick 12',
      history: [
        {
          place: 'Professional' + longText,
          position: '2021 Free Agent, Los Angeles Rams' + longText,
          description: 'Contract  3 of 3' + longText,
        },
        {
          place: 'Professional',
          position: '2019 Free Agent, Cleveland Browns',
          description: 'Contract  2 of 3',
        },
        {
          place: 'Professional',
          position: '2014 Drafted (R1:P12), New York Giants',
          description: 'Contract  1 of 3',
        },
        {
          place: 'College',
          position: 'LSU (2011–2013)',
        },
        {
          place: 'High School',
          position: 'Isidore Newman (All Freshman Team)',
        },
      ],
      stats: {
        career: {
          receiving: [
            {
              gp: 71,
              rec: 433,
              yds: 5517,
              avg: 12.7,
              td: 40,
              fum: 4,
              lst: 1,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          rushing: [
            {
              gp: 71,
              rec: 433,
              yds: 5517,
              avg: 12.7,
              td: 40,
              fum: 4,
              lst: 1,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          passing: [
            {
              gp: 71,
              rec: 433,
              yds: 5517,
              avg: 12.7,
              td: 40,
              fum: 4,
              lst: 1,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          returning: [
            {
              gp: 71,
              rec: 433,
              yds: 5517,
              avg: 12.7,
              td: 40,
              fum: 4,
              lst: 1,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
        },
        season: {
          receiving: [
            {
              gp: 33,
              rec: 20,
              yds: 1023,
              avg: 3,
              td: 35634,
              fum: 34,
              lst: 4,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          rushing: [
            {
              gp: 33,
              rec: 20,
              yds: 1023,
              avg: 3,
              td: 35634,
              fum: 34,
              lst: 4,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          passing: [
            {
              gp: 33,
              rec: 20,
              yds: 1023,
              avg: 3,
              td: 35634,
              fum: 34,
              lst: 4,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
          returning: [
            {
              gp: 33,
              rec: 20,
              yds: 1023,
              avg: 3,
              td: 35634,
              fum: 34,
              lst: 4,
              date: '3/4/22 4:15:05 GMT',
              team: 'Los Angeles Rams',
            },
          ],
        },
      },
      feed: [
        {
          text: 'Touchdown, 30 yard pass from T. Brady',
          date: 'Sunday 11/29/2022 10:40',
        },
        {
          text: '30yard run, pass incomplete - pulled hamstring',
          date: 'Sunday 11/29/2022 10:40',
        },
        {
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          date: 'Sunday 11/29/2022 10:40',
        },
      ],
      activity: generateItemsList(10, createMocActivity),
    },
    tradingInfo: {
      tradingPairId: '0',
      contractId: `${getFromArray(id, contracts)}${id}`,
      bidStatus: getFromArray(id, bidStatuses),
      contractTerm: {
        start: 'November 5, 2020',
        end: 'November 5, 2022',
      },
      costBasis: 59968.62,
      price: randomFloatFromInterval(0, 50),
      units: 10,
      marketCap: 12500000,
      normalizedMarketCap: 1500000,
      lastPrice: randomFloatFromInterval(10, 100),
      lastPriceProgress: randomIntFromInterval(-100, 100),
      volume24: 450000,
      floatValue: 2500000,
      todayProgress: -7608.22,
      totalProgress: 286309.55,
      mintingStatus: getFromArray(id, mintingStatuses),
      orders: generateItemsList(15, createMocOrder),
      offeringTerms: {
        revenue: 1.5,
        revenueType: 'Professional Sports Career Salary & Bonuses' + longText,
        fractionalTokensOffered: 1000,
        maxBidPerToken: 100,
      },
      icoStatus: {
        status: AthleteIcoStatusE.Active,
        opening: 'April 2, 2022 8:00 GMT',
        closing: 'April 30, 2022 12:00 GMT',
      },
    },
  };
};

export const MOC_ATHLETES_FOR_HCX_CARD = generateItemsList(11, createMocAthlete);
