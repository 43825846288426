import { FC } from 'react';
import { Box } from '@mui/material';
import { AthleteI } from 'types';
import { WidgetAthleteListSmallCard } from './widget-athlete-list-small-card';
import styles from './widget-athlete-list-small.module.scss';

const WidgetAthleteListSmall: FC<{ athletes: AthleteI[] }> = ({ athletes }) => {
  return (
    <Box className={styles.root}>
      {athletes.map((athlete) => (
        <WidgetAthleteListSmallCard key={athlete.id} athlete={athlete} />
      ))}
    </Box>
  );
};

export default WidgetAthleteListSmall;
