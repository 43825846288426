import { FC, useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ResizeContext } from './context';
import { useResizableContainer } from './use-resizable-container';

interface PropsI {
  id: string;
  className?: string;
  defaultSizes: Record<string, number>;
}

export const ResizableCol: FC<PropsI> = ({ children, className, id, defaultSizes }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { children: result, contextValue } = useResizableContainer(
    children,
    id,
    defaultSizes,
    wrapperRef
  );

  return (
    <ResizeContext.Provider value={contextValue}>
      <div className={classNames(styles.column, className)} ref={wrapperRef}>
        {result}
      </div>
    </ResizeContext.Provider>
  );
};
