import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/material/Box/Box';
import classNames from 'classnames';
import styles from './panel.module.scss';

export const Panel = forwardRef<HTMLDivElement, BoxProps>(({ className, ...props }, ref) => (
  <Box ref={ref} className={classNames(styles.panel, className)} {...props} />
));

Panel.displayName = 'Panel';
