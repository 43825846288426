import type { FC } from 'react';

import { useCurrentRouteConfig } from 'hooks/useCurrentRouteConfig';
import { ROUTE } from 'routes/enums';

import { TradeBar } from './trade-bar';
import { useAtom } from 'jotai';
import { tradeBarView } from 'store/trading-stats.store';

export const TradeBarContainer: FC = () => {
  const [isTradeBarOpened] = useAtom(tradeBarView);
  const routeConfig = useCurrentRouteConfig();

  if (
    !isTradeBarOpened ||
    !routeConfig?.isPrivate ||
    (routeConfig.path !== ROUTE.TradeDesc &&
      routeConfig.path !== ROUTE.Portfolio &&
      routeConfig.path !== ROUTE.MyOrders &&
      routeConfig.path !== ROUTE.HumanCapitalMint &&
      routeConfig.path !== ROUTE.Wallet)
  )
    return null;

  return <TradeBar />;
};
