import { ChangeEvent, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useFormState = <T, E>(
  initialState: T,
  onSubmit: (data: T) => void,
  validate?: (data: T) => E
) => {
  const [state, setState] = useState<T>(initialState);
  const [errors, setErrors] = useState<E | undefined>();
  const [shouldValidate, setShouldValidate] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const data = { ...state, [name]: value };
    setState(data);
    if (shouldValidate) {
      setErrors(validate?.(data));
    }
  };
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    const data = { ...state, [name]: value };
    setState(data);
    if (shouldValidate) {
      setErrors(validate?.(data));
    }
  };
  const handleSubmit = () => {
    const result = validate?.(state);
    // FIXME: VOV: Should be used correct type, without casting
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!result || Object.values(result).every((field: any) => field.valid)) {
      onSubmit(state);
      return;
    }
    setErrors(result);
    setShouldValidate(true);
  };
  return {
    handleChange,
    handleSelectChange,
    handleSubmit,
    state,
    errors,
  };
};
