import type { FC } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './sponsor-invite-form.module.scss';
import { InputField } from 'components/input-field';
import { SelectField } from 'components/select-field';
import { useAtom } from 'jotai';
import ClearIcon from '@mui/icons-material/Clear';
import {
  sponsorInvitation,
  sponsorInvitationEmail,
  sponsorInvitationEmailList,
  sponsorInvitationValidation,
} from 'store/sponsor-management.store';
import { sponsorFormSchema } from 'yup/sponsorFormSchema';
import { ValidationError } from 'yup';
import { useResetAtom } from 'jotai/utils';
import { SponsorUserRole } from 'generated/graphql';

export const InviteForm: FC = () => {
  const [invitation, setInvitation] = useAtom(sponsorInvitation);
  const [emailList, setEmailList] = useAtom(sponsorInvitationEmailList);
  const [emailInput, setEmailInput] = useAtom(sponsorInvitationEmail);
  const [error, setError] = useAtom(sponsorInvitationValidation);
  const { t } = useTranslation('sponsor-management');

  const resetEmail = useResetAtom(sponsorInvitationEmail);
  const resetError = useResetAtom(sponsorInvitationValidation);

  const onChangeHandler = async (fieldName: string, value: string) => {
    if (fieldName === 'emailAddress') {
      try {
        setEmailInput(value);
        await sponsorFormSchema.emailAddress.validate(value);
        setError({
          emailAddress: {
            errMessage: undefined,
            isTouched: true,
          },
        });
      } catch (err) {
        if (err instanceof ValidationError) {
          setError({
            emailAddress: {
              isTouched: true,
              errMessage: err.errors[0],
            },
          });
        }
      }
      return;
    }
    setInvitation((prevState) => {
      return { ...prevState, [fieldName]: value };
    });
  };

  const addEmail = () => {
    if ((error.emailAddress.isTouched && error.emailAddress.errMessage) || !emailInput) {
      return;
    }
    setEmailList((prev) => {
      if (prev === null) {
        return [emailInput];
      }
      return [...prev, emailInput];
    });
    resetEmail();
    resetError();
  };

  const onBlurHandler = () => {
    setError((prev) => ({
      emailAddress: {
        ...prev.emailAddress,
        isTouched: true,
      },
    }));
    if (emailList) {
      if (emailList.length > 0 && emailInput) {
        addEmail();
      }
    }
  };

  const removeEmail = (value: string) =>
    setEmailList((prev) => {
      if (prev === null || !prev.length) return null;
      return [...prev.filter((el) => el !== value)];
    });

  return (
    <>
      <Typography variant="subtitle2">{t('invite-users-to-HCX')}</Typography>
      <Box component="form" noValidate autoComplete="off" className={styles.inviteForm}>
        <InputField
          tName={t('invitation-message')}
          className={styles.invitationField}
          value={invitation?.message || ''}
          multiline={true}
          maxRows="5"
          onChange={(e) => {
            onChangeHandler('message', e.target.value);
          }}
          placeholder={t('invitation')}
        />
        {emailList &&
          emailList.length !== 0 &&
          emailList.map((email) => (
            <Box key={email} display="flex" alignItems="center">
              <Typography variant="body2">{email}</Typography>
              <IconButton onClick={() => removeEmail(email)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        <Box className={styles.row}>
          <InputField
            tName={t('enter-email')}
            className={styles.emailField}
            onChange={(e) => {
              onChangeHandler('emailAddress', e.target.value);
            }}
            value={emailInput}
            onBlur={onBlurHandler}
            errorValidation={error.emailAddress.errMessage}
            placeholder={t('email-address')}
          />
          <SelectField
            tName={t('permission')}
            disabled={false}
            onChangeHandler={(e) => {
              onChangeHandler('role', e.target.value);
            }}
            value={invitation?.role || ''}
            menuItems={[
              SponsorUserRole.Admin,
              SponsorUserRole.Manager,
              SponsorUserRole.Member,
              SponsorUserRole.Owner,
            ]}
            className={styles.selectRole}
          />
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <Typography onClick={addEmail} variant="body2" className={styles.addAnotherUser}>
          {t('add-email')}
        </Typography>
      </Box>
    </>
  );
};
