import { FC, useState } from 'react';
import { Panel } from '../../components/panel';
import Box from '@mui/material/Box';
import styles from './onboarding.module.scss';
import { FirstStep } from './steps/first-step';
import { SecondStep } from './steps/second-step';
import { ThirdStep } from './steps/third-step';

const STEPS = [FirstStep, SecondStep, ThirdStep];

export const Onboarding: FC = () => {
  const [step, setStep] = useState(0);
  const Component = STEPS[step];
  return (
    <Box className={styles.container}>
      <Panel className={styles.panel}>
        <Component onSubmit={() => setStep((s) => s + 1)} onBack={() => setStep((s) => s - 1)} />
      </Panel>
    </Box>
  );
};
