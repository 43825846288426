import { FC, useState } from 'react';
import { Panel } from 'components/panel';
import { ChatProcessing } from 'components/design';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import styles from './talent-portal-connect-social-media.module.scss';
import {
  TalentPortalConnectSocialRow,
  TalentPortalConnectSocialRowDataI,
  TalentPortalConnectSocialRowPropsI,
} from './talent-portal-connect-social-row';

const mocData: TalentPortalConnectSocialRowDataI = {
  checked: true,
  shareWithFriends: true,
  shareWithFollowers: true,
  postTradeDelay: {
    days: 3,
    hours: 3,
    minutes: 4,
    seconds: 55,
  },
};

const TalentPortalConnectSocial: FC = () => {
  const [data, setData] = useState<
    Record<'facebook' | 'twitter' | 'linkedin' | 'discord', TalentPortalConnectSocialRowDataI>
  >({
    facebook: mocData,
    twitter: mocData,
    linkedin: mocData,
    discord: mocData,
  });

  const onChange: TalentPortalConnectSocialRowPropsI['onChangeData'] = (name, newData) => {
    setData((prev) => ({
      ...prev,
      [name.toLowerCase()]: newData,
    }));
  };

  return (
    <Panel className={styles.root}>
      <header className={styles.header}>
        <ChatProcessing />
        Connect Your Social Media
      </header>
      <div className={styles.description}>
        Connect your social media accounts and customize your share settings below.
      </div>
      <TalentPortalConnectSocialRow
        name="Twitter"
        icon={<FacebookRoundedIcon />}
        data={data.twitter}
        onChangeData={onChange}
      />
      <TalentPortalConnectSocialRow
        name="Facebook"
        icon={<FacebookRoundedIcon />}
        data={data.facebook}
        onChangeData={onChange}
      />
      <TalentPortalConnectSocialRow
        name="LinkedIn"
        icon={<FacebookRoundedIcon />}
        data={data.linkedin}
        onChangeData={onChange}
      />
      <TalentPortalConnectSocialRow
        name="Discord"
        icon={<FacebookRoundedIcon />}
        data={data.discord}
        onChangeData={onChange}
      />
    </Panel>
  );
};

export default TalentPortalConnectSocial;
