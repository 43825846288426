import classNames from 'classnames';
import { FC } from 'react';

import { Avatar, Box, Typography } from '@mui/material';

import { TableCell, TableRow } from 'components/table';

import styles from './hcx-financial-card-list-table.module.scss';
import { AthleteI } from 'types';
import moment from 'moment';
import { formatToUSD } from 'utils/currency-formatter';
import {
  NormalizationDecimalsE,
  NormalizationLogicE,
  normalizeDecimals,
} from 'utils/number-formatter';

interface FinancalCardListTableRowPropsI {
  entry: AthleteI;
  selectedRow: string | null;
  onRowClick: (auctionData: AthleteI) => void;
}

export const FinancalCardListTableRow: FC<FinancalCardListTableRowPropsI> = ({
  entry,
  selectedRow,
  onRowClick,
}) => {
  return (
    <TableRow
      key={entry.id}
      className={classNames(styles.row, entry.id.toString() === selectedRow && styles.selectedRow)}
      onClick={() => onRowClick(entry)}
    >
      <TableCell>
        <Box display="flex" alignItems="center">
          {entry.id.toString() === selectedRow && <Box className={styles.prefixDot} />}
          <Avatar src={entry.bio.photo} />
          <Box ml={2.5}>
            <Typography variant="subtitle2" whiteSpace="nowrap">
              {entry.bio.name}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Typography>{entry.tradingInfo.mintingStatus}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{entry.tradingInfo.orders.length}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {formatToUSD(
            entry.tradingInfo.price
              ? normalizeDecimals(entry.tradingInfo.price, NormalizationDecimalsE.Six)[
                  NormalizationLogicE.NonDecimal
                ]
              : 0,
            {
              minimumFractionDigits: 2,
            }
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{entry.tradingInfo.offeringTerms.revenue * 10}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {entry.tradingInfo.contractTerm.start} - {entry.tradingInfo.contractTerm.end}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{entry.tradingInfo.icoStatus.closing}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{Math.floor(moment().diff(entry.bio.born, 'years', true))}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{entry.bio.sex}</Typography>
      </TableCell>
    </TableRow>
  );
};
