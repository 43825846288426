import {
  Children,
  // JSXElementConstructor,
  ReactChild,
  ReactElement,
  ReactFragment,
  ReactNode,
  ReactPortal,
} from 'react';

type ReactElementT = ReactChild | ReactFragment | ReactPortal;

// TODO: VOV: Is this code required ?
// const getChildName = (child: ReactElementT) =>
//   ((child as ReactElement)?.type as JSXElementConstructor<never>)?.name;

const getId = (child: ReactElementT) => (child as ReactElement).props.id;

export const insertResizer = (
  children: ReactNode,
  renderResizer: (currentId: string, nextId: string) => ReactElementT
) =>
  Children.toArray(children).reduce<ReactElementT[]>((acc, child, index, arr) => {
    if (arr?.[index + 1]) {
      return [...acc, child, renderResizer(getId(child), getId(arr?.[index + 1]))];
    }
    return [...acc, child];
  }, []);

export const calcInitialSizes = (
  children: ReactNode,
  defaultSizes: Record<string, number> = {}
) => {
  const childrenArray = Children.toArray(children);
  return childrenArray.reduce<Record<string, number>>(
    (acc, child) => ({
      ...acc,
      [getId(child)]: defaultSizes[getId(child)] ?? 100 / childrenArray.length,
    }),
    {}
  );
};
