import type { FC } from 'react';
import { medicalTypeValidator } from './horizontal-carousel';
import { PromotedTalent } from 'generated/graphql';
import styles from './horizontal-carousel.module.scss';
import { Box, Typography } from '@mui/material';

export const ItemCard: FC<{ card: PromotedTalent }> = ({ card }) => {
  return (
    <Box display="flex" width="360px" height="180px">
      <img src={card.profileImageUrl} alt={card.typeDetails.__typename} className={styles.avatar} />
      <Box className={styles.descriptionWrapper}>
        <Typography className={styles.nameRow}>
          {medicalTypeValidator(card.typeDetails) ? (
            <>{card.typeDetails.graduated}</>
          ) : (
            <>{card.typeDetails.college}</>
          )}
        </Typography>
        <Typography className={styles.badge}>
          {medicalTypeValidator(card.typeDetails) ? (
            <>{card.typeDetails.medicalCollege}</>
          ) : (
            <>{card.typeDetails.team}</>
          )}
        </Typography>
        <Typography variant="body2">
          {medicalTypeValidator(card.typeDetails) ? (
            <>{card.typeDetails.specialty}</>
          ) : (
            <>{card.typeDetails.role}</>
          )}
        </Typography>
        <Typography variant="body2">
          {medicalTypeValidator(card.typeDetails) ? (
            <>{card.typeDetails.undergrad}</>
          ) : (
            <>{card.typeDetails.homeTown}</>
          )}
        </Typography>
        <Typography variant="body2">
          {!medicalTypeValidator(card.typeDetails) && <>{card.typeDetails.arms}</>}
        </Typography>
        <Typography variant="body2">
          {!medicalTypeValidator(card.typeDetails) && <>{card.typeDetails.experience}</>}
        </Typography>
        <Typography variant="body2">
          {!medicalTypeValidator(card.typeDetails) && <>{card.typeDetails.league}</>}
        </Typography>
      </Box>
    </Box>
  );
};
