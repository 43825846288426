import { FC } from 'react';
import { Typography } from '@mui/material';

import styles from './auction-data-grid.module.scss';

interface AuctionDataGridPropsI {
  totalBids: number;
  bidSizeAvg: number;
  weightedAvgBidPrice: number;
}

const AuctionDataGrid: FC<AuctionDataGridPropsI> = ({
  totalBids,
  bidSizeAvg,
  weightedAvgBidPrice,
}) => {
  return (
    <div className={styles.root}>
      <Typography variant="body2" className={styles.title} mr={10}>
        Auction Data
      </Typography>
      <div className={styles.gridContainer}>
        <div className={styles.gridField}>
          <Typography variant="body2" className={styles.prefix}>
            Total Bids:
          </Typography>
          <Typography>{totalBids}</Typography>
        </div>
        <div className={styles.gridField}>
          <Typography variant="body2" className={styles.prefix}>
            Bid Size Avg:
          </Typography>
          <Typography>{bidSizeAvg}</Typography>
        </div>
        <div className={styles.gridField}>
          <Typography variant="body2" className={styles.prefix}>
            Weighted Avg Bid Price:
          </Typography>
          <Typography>{weightedAvgBidPrice}</Typography>
        </div>
      </div>
    </div>
  );
};

export default AuctionDataGrid;
