import { ChangeEventHandler, FC, useEffect, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import classNames from 'classnames';
import styles from './search.module.scss';
import { ResultItemI, Results } from './results';

export interface SearchPropsI {
  placeholder?: string;
  containerClassName?: string;
  inputClassName?: string;
  iconClassName?: string;
  dropdownClassName?: string;
  onChange: (search: string) => void;
  results: ResultItemI[];
  onResultClick: (id: string | number, data: ResultItemI) => void;
  loading?: boolean;
  icon?: React.ReactNode;
  getSearchOnSelect?: (data: ResultItemI) => string;
}

export const Search: FC<SearchPropsI> = ({
  placeholder,
  containerClassName,
  inputClassName,
  iconClassName,
  dropdownClassName,
  onChange,
  results,
  onResultClick,
  loading,
  icon = <SearchIcon className={styles.iconColor} />,
  getSearchOnSelect,
}) => {
  const [resultsOpen, setResultsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (search && !selected) {
      setResultsOpen(true);
    }
  }, [search, selected]);
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    onChange(value);
    setSearch(value);
    setSelected(false);
  };
  const handleResultClick: SearchPropsI['onResultClick'] = (id, data) => {
    onResultClick(id, data);
    if (getSearchOnSelect) {
      setSearch(getSearchOnSelect(data));
      setSelected(true);
    }
    setResultsOpen(false);
  };
  return (
    <div className={classNames(containerClassName, styles.container)}>
      <div className={classNames(styles.iconWrapper, iconClassName)}>{icon}</div>
      <InputBase
        value={search}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : ''}
        componentsProps={{ input: { className: classNames(inputClassName, styles.input) } }}
      />
      {resultsOpen && (
        <Results
          items={results}
          loading={loading}
          onClose={() => setResultsOpen(false)}
          onItemClick={handleResultClick}
          className={dropdownClassName}
        />
      )}
    </div>
  );
};
