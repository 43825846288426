import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, IconButton, Typography } from '@mui/material';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import ListIcon from '@mui/icons-material/List';
import GridIcon from '@mui/icons-material/GridView';

import { useAtom } from 'jotai';
import { useRotateAllFinancialCards, useTradingAthletesPagination } from 'apolloClient/hooks';
import { useRenderToHeaderTradingInfo } from 'components/layout/trading-info/header-trading-info/use-render-to-header-trading-info';
import { HCXCardContainer } from 'features/hcx-card';
import { HCXFinancialCardList } from 'features/hcx-financial-card-list';
import { HCXFinancialCardListResizer } from 'features/hcx-financial-card-list/hcx-financial-card-list-resizer';
import { formatNumber } from 'utils/number-formatter';
import {
  closeAthleteCardAtom,
  listGridViewChangerAtom,
  listGridViewStatusAtom,
} from 'store/athletes.store';

import styles from './trade-desk.module.scss';

export const TradeDesk: FC = () => {
  const {
    pagination: { total },
  } = useTradingAthletesPagination();
  const [, closeAthleteCard] = useAtom(closeAthleteCardAtom);
  const [, changeLayout] = useAtom(listGridViewChangerAtom);
  const [isListView] = useAtom(listGridViewStatusAtom);

  const { t } = useTranslation('trade-desk');

  const rotateAllCards = useRotateAllFinancialCards();

  const { clear: clearHeader } = useRenderToHeaderTradingInfo(
    null,
    useMemo(
      () => (
        <Box display="flex" alignItems="center">
          <Typography variant="body1">
            <Trans
              i18nKey="talent-listed"
              ns="trade-desk"
              values={{ formattedCount: formatNumber(total) }}
            />
          </Typography>
          <IconButton
            aria-label="list-items"
            size="small"
            onClick={changeLayout}
            style={{ marginLeft: '1rem' }}
          >
            {isListView ? <GridIcon /> : <ListIcon />}
          </IconButton>
          <IconButton
            aria-label="rotate"
            size="small"
            onClick={rotateAllCards}
            style={{ marginLeft: '1rem' }}
            title={t('rotate')}
          >
            <ThreeSixtyIcon />
          </IconButton>
          <HCXFinancialCardListResizer className={styles.listResizer} />
        </Box>
      ),
      [total, changeLayout, isListView, rotateAllCards, t]
    )
  );

  useEffect(() => {
    closeAthleteCard(null);
    return () => {
      clearHeader();
      closeAthleteCard(null);
    };
  }, [clearHeader, closeAthleteCard]);

  return (
    <>
      <HCXFinancialCardList className={styles.list} />
      <HCXCardContainer />
    </>
  );
};
