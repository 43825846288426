import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Tab, Tabs, TabsProps } from '@mui/material';

import { Panel } from 'components/panel';
import { TabPanel } from 'components/tab-panel';
import { MyOrdersTable } from './components';

import { DutchAuctionBid } from 'generated/graphql';
import { a11yProps } from 'utils/a11y';

import type { OrderT } from 'types/orders';

import styles from './my-orders-view.module.scss';

const TAB_LABELS = ['all', 'open', 'canceled', 'filled', 'io'];

interface MyOrdersViewPropsI {
  tabIdx: number;
  onTabChange: TabsProps['onChange'];
  userOrders: OrderT[];
  userBids: DutchAuctionBid[];
}

export const MyOrdersView: FC<MyOrdersViewPropsI> = ({
  tabIdx,
  onTabChange,
  userOrders,
  userBids,
}) => {
  const { t } = useTranslation('my-orders');

  return (
    <Panel className={styles.root}>
      <Box>
        <Tabs
          value={tabIdx}
          onChange={onTabChange}
          aria-label="talent-portal-tabs"
          className={styles.tabs}
        >
          {TAB_LABELS.map((label, idx) => (
            <Tab key={t(label)} label={t(label)} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <TabPanel value={tabIdx} index={0}>
        <MyOrdersTable userOrders={userOrders} userBids={userBids} />
      </TabPanel>
    </Panel>
  );
};
