import { FC, useState } from 'react';
import { Panel } from 'components/panel';
import { TrendingUp } from 'components/design';
import styles from './talent-portal-revenue.module.scss';
import { Tabs, TabsPropsI } from 'components/tabs';
import { formatToUSD } from 'utils/currency-formatter';
import { Link } from '@mui/material';

const REVENUE_TABS_CONFIG = [
  {
    label: 'Today',
    chartRange: '1D',
  },
  {
    label: 'Week',
    chartRange: '7D',
  },
  {
    label: 'Month',
    chartRange: '1M',
  },
  {
    label: 'Year',
    chartRange: '12M',
  },
  {
    label: 'All',
    chartRange: 'All',
  },
];

const TAB_LABELS = REVENUE_TABS_CONFIG.map(({ label }) => label);

const TalentPortalRevenue: FC = () => {
  const [tab, setTab] = useState(0);

  const handleChangeTab: TabsPropsI['onChange'] = (_, idx) => setTab(idx);

  return (
    <Panel className={styles.root}>
      <header className={styles.header}>
        <TrendingUp color="var(--hcx-font-primary)" />
        Revenue
        <div className={styles.tabs}>
          <Tabs value={tab} onChange={handleChangeTab} labels={TAB_LABELS} />
        </div>
      </header>
      <div className={styles.totalWrapper}>
        <span>{formatToUSD(408000, { minimumFractionDigits: 2 })}</span>
        <Link className={styles.link}>View History</Link>
      </div>
      <div className={styles.chartWrapper}>{REVENUE_TABS_CONFIG[tab].chartRange}</div>
    </Panel>
  );
};

export default TalentPortalRevenue;
