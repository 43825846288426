import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Tabs, TabsProps, Tab, Typography } from '@mui/material';
import { a11yProps } from 'utils/a11y';

import { CustomSwitcherPropsI } from './custom-switcher.types';
import styles from './custom-switcher.module.scss';

const tabsClasses = {
  root: styles.root,
  indicator: styles.indicator,
};

const tabClasses = {
  root: styles.tab,
  selected: styles.selected,
};

const CustomSwitcher: FC<CustomSwitcherPropsI> = ({
  labels,
  size = 'medium',
  activeColor = 'var(--hcx-font-primary)',
  inActiveColor = 'var(--hcx-font-primary)',
  className,
  value,
  onChange,
}) => {
  const activeLabel = labels[value || 0];

  const indicatorProps = useMemo(
    () => ({
      children: activeLabel ? (
        <Typography
          fontSize="inherit"
          lineHeight="inherit"
          fontWeight="inherit"
          color={activeColor}
          className={styles.indicatorContentWrapper}
        >
          {activeLabel}
        </Typography>
      ) : null,
      className: styles[size],
    }),
    [activeLabel, size, activeColor]
  );

  const handleChange: TabsProps['onChange'] =
    onChange && labels.length ? (_, newValue) => onChange(newValue) : undefined;

  return (
    <Tabs
      classes={tabsClasses}
      aria-label="CustomSwitcher"
      TabIndicatorProps={indicatorProps}
      value={value}
      onChange={handleChange}
      className={classNames(styles[size], className)}
    >
      {labels.map((label, idx) => (
        <Tab
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          classes={tabClasses}
          className={styles[size]}
          label={label}
          color={inActiveColor}
          {...a11yProps(idx)}
        />
      ))}
    </Tabs>
  );
};

export default CustomSwitcher;
