import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Date: any;
  Map: any;
  NetworkAddress: any;
  Object: any;
  Time: any;
  Timestamp: any;
  UUID: any;
  Upload: any;
};

export type Athlete = {
  __typename?: 'Athlete';
  currentJob: AthleteJob;
  dob: Scalars['Date'];
  education: Scalars['String'];
  erc20SmartContractAddress: Scalars['NetworkAddress'];
  ethereumAddress: Scalars['NetworkAddress'];
  fullName: Scalars['String'];
  height: Scalars['Float'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  jobHistory?: Maybe<Array<AthleteJob>>;
  nftName: Scalars['String'];
  nftSmartContractAddress: Scalars['NetworkAddress'];
  nftSymbol: Scalars['String'];
  sex: Scalars['String'];
  tradingPair?: Maybe<TradingPair>;
};

export type AthleteHeightArgs = {
  unit?: InputMaybe<HeightUnit>;
};

export type AthleteContract = {
  __typename?: 'AthleteContract';
  athlete: Athlete;
  bondAmountUSDC: Scalars['Int'];
  buybackMultiplier: Scalars['Int'];
  contractAgreementIPFSHash: Scalars['String'];
  contractAgreementPDFUrl: Scalars['String'];
  dutchAuction: DutchAuction;
  id: Scalars['ID'];
  nftTokenId: Scalars['Int'];
  /**
   * percentage of athlete's revenue that will be distributed to token holders.
   * Must be provided in percent format: 100% -> 100; 1.025% -> 1.025. Up to 4
   * decimal places are allowed.
   */
  revenuePercentage: Scalars['Float'];
  startDate: Scalars['Date'];
  status: ContractStatus;
  /** total supply of tokens for this athlete contract */
  totalTokenSupply: Scalars['Int'];
  underwriter: Underwriter;
};

export type AthleteContractDetails = {
  /**
   * agreementFile is required when creating a new contract, but can be
   * omitted when modifying it.
   */
  agreementFile?: InputMaybe<Scalars['Upload']>;
  /** bond amount can be specified only by the admins */
  bondAmountUSDC?: InputMaybe<Scalars['Int']>;
  /** buy back multiplier can be specified only by the admins */
  buybackMultiplier?: InputMaybe<Scalars['Int']>;
  /**
   * nft name and symbol are provided only in the first AthleteContract.
   * Subsequential contracts will use the values defined in first
   * AthleteContract.
   * Currently, when updating athlete contract, these values are ignored.
   */
  nftTokenName?: InputMaybe<Scalars['String']>;
  nftTokenSymbol?: InputMaybe<Scalars['String']>;
  /**
   * percentage of athlete's revenue that will be distributed to token holders.
   * Must be provided in percent format: 100% -> 100; 1.025% -> 1.025. Up to 4
   * decimal places are allowed.
   */
  revenuePercentage: Scalars['Float'];
  startDate: Scalars['Date'];
  /** status can be specified only be the admins */
  status?: InputMaybe<ContractStatus>;
  /** total token supply that will be created for this agreement */
  totalTokenSupply: Scalars['Int'];
};

/**
 * information about athlete contract that will be created. When creating
 * athlete, either athleteID or athleteInfo must be present. If athleteID
 * is present, no new athlete will be created and existing one will be
 * assigned to the new athlete contract
 */
export type AthleteContractRequest = {
  athleteID?: InputMaybe<Scalars['ID']>;
  athleteInfo?: InputMaybe<AthleteInfo>;
  contractDetails: AthleteContractDetails;
  dutchAuction: DutchAuctionParameters;
};

export type AthleteContractResponse = {
  __typename?: 'AthleteContractResponse';
  athleteContractID: Scalars['ID'];
  message: Scalars['String'];
};

export type AthleteInfo = {
  currentJob: AthleteJobInput;
  dob: Scalars['Date'];
  education: Scalars['String'];
  fullName: Scalars['String'];
  height_feet: Scalars['Float'];
  /** Image is required when creating a new athlete contract */
  image?: InputMaybe<Scalars['Upload']>;
  jobHistory: Array<InputMaybe<AthleteJobInput>>;
  sex: Scalars['String'];
  walletAddress: Scalars['NetworkAddress'];
};

export type AthleteJob = {
  __typename?: 'AthleteJob';
  drafted: Scalars['String'];
  league: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['String']>;
  rosterStatus: Scalars['Boolean'];
  team: Scalars['String'];
};

export type AthleteJobInput = {
  drafted: Scalars['String'];
  league: Scalars['String'];
  number?: InputMaybe<Scalars['Int']>;
  position?: InputMaybe<Scalars['String']>;
  rosterStatus: Scalars['Boolean'];
  team: Scalars['String'];
};

/** any of the properties can be specified */
export type AthleteSearchInput = {
  hasTradingPair?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  /** name will match a part or all athlete's name */
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type AthleteType = {
  __typename?: 'AthleteType';
  arms: Scalars['String'];
  college: Scalars['String'];
  experience: Scalars['String'];
  hands: Scalars['String'];
  height: Scalars['String'];
  homeTown: Scalars['String'];
  jerseyNumber: Scalars['String'];
  league: Scalars['String'];
  role: Scalars['String'];
  team: Scalars['String'];
  weight: Scalars['String'];
};

export type AthletesPaginated = Paginated & {
  __typename?: 'AthletesPaginated';
  data: Array<Maybe<Athlete>>;
  pagination: Pagination;
};

export type AuctionTrade = {
  __typename?: 'AuctionTrade';
  amount: Scalars['Int'];
  auction: DutchAuction;
  dateCreated: Scalars['Date'];
  status: OrderStatus;
};

/** Auth0Configuration represents Auth0 configuration. */
export type Auth0Configuration = {
  __typename?: 'Auth0Configuration';
  audience: Scalars['String'];
  clientId: Scalars['String'];
  domain: Scalars['String'];
};

export enum BidOrderStatus {
  Claimed = 'Claimed',
  Open = 'Open',
  Returned = 'Returned',
}

export type BuybackInfo = {
  __typename?: 'BuybackInfo';
  buybackMultiplier: Scalars['Int'];
  buybackPrice?: Maybe<Scalars['Int']>;
  buybackRewardPerFraction?: Maybe<Scalars['Int']>;
};

/** Configuration represents application configuration. */
export type Configuration = {
  __typename?: 'Configuration';
  auth0: Auth0Configuration;
};

export enum ContractStatus {
  Approved = 'Approved',
  BondDeposited = 'BondDeposited',
  Cancelled = 'Cancelled',
  Denied = 'Denied',
  InBuyBack = 'InBuyBack',
  Pending = 'Pending',
  PendingBuyBack = 'PendingBuyBack',
  Redeemed = 'Redeemed',
}

export type DoctorType = {
  __typename?: 'DoctorType';
  graduated: Scalars['String'];
  medicalCollege: Scalars['String'];
  specialty: Scalars['String'];
  undergrad: Scalars['String'];
};

export type DutchAuction = {
  __typename?: 'DutchAuction';
  amountRaised: Scalars['Int'];
  athleteContract: AthleteContract;
  /**
   * currently auction type is always IHO, once we add CHO flow, this will be
   * dynamic
   */
  auctionType: DutchAuctionType;
  /** blockchain id is the id of dutch auction in smart contract */
  blockchainId: Scalars['ID'];
  discountFrequency: Scalars['Float'];
  discountRate: Scalars['Int'];
  durationDays: Scalars['Int'];
  endPrice: Scalars['Int'];
  /** starting (floor) price of token */
  floorPrice: Scalars['Int'];
  fractions: Scalars['Int'];
  id: Scalars['ID'];
  /**
   * minimal total amount that has to be collected from bids in order for auction
   * to conclude successfully (fractions x floorPrice)
   */
  minimumTotalProceeds: Scalars['Int'];
  smartContractAddress: Scalars['NetworkAddress'];
  /**
   * sponsor status is not the same as DutchAuction.status. It is tied to auction
   * bond
   */
  sponsorStatus: SponsorStatus;
  startDate: Scalars['Date'];
  stats?: Maybe<DutchAuctionStats>;
  status: DutchAuctionStatus;
};

/**
 * Dutch Auction Active Mints The parameters of a Dutch Auction which address FNFT
 * prices over time
 */
export type DutchAuctionActiveMints = {
  __typename?: 'DutchAuctionActiveMints';
  AverageBid: Scalars['Float'];
  Bidders: Scalars['Int'];
  FinishAt: Scalars['Date'];
  HighBid: Scalars['Float'];
  RevenuePercentage: Scalars['Float'];
  StartAt: Scalars['Date'];
  Status: Scalars['String'];
  Talent: Scalars['Int'];
  ThresholdPrice: Scalars['Float'];
  Type: Scalars['String'];
};

/** DutchAuctionBidsRecieved */
export type DutchAuctionBid = {
  __typename?: 'DutchAuctionBid';
  auctionBlockchainId: Scalars['Int'];
  auctionId: Scalars['Int'];
  bidderAddress: Scalars['String'];
  blockHash: Scalars['String'];
  dutchAuction: DutchAuction;
  orderDate: Scalars['Date'];
  orderType: DutchAuctionOrderType;
  status: BidOrderStatus;
  tokenAmount: Scalars['Int'];
  tokenPrice: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
};

export enum DutchAuctionBidSubscriptionBidStatus {
  /** New bid added */
  Added = 'Added',
  /** Bid refunded and removed */
  Refunded = 'Refunded',
}

/** Payload for bid subscriptions. */
export type DutchAuctionBidSubscriptionItem = {
  __typename?: 'DutchAuctionBidSubscriptionItem';
  bidData: DutchAuctionBid;
  status: DutchAuctionBidSubscriptionBidStatus;
};

export type DutchAuctionOrder = {
  direction: OrderDirection;
  field: DutchAuctionOrderByField;
};

export enum DutchAuctionOrderByField {
  CreatedAt = 'CreatedAt',
  EndPrice = 'EndPrice',
  StartDate = 'StartDate',
  Empty = 'Empty',
}

export enum DutchAuctionOrderType {
  ChoBid = 'CHOBid',
  IhoBid = 'IHOBid',
}

export type DutchAuctionPaginated = Paginated & {
  __typename?: 'DutchAuctionPaginated';
  data: Array<Maybe<DutchAuction>>;
  pagination: Pagination;
};

export type DutchAuctionParameters = {
  discountFrequency: Scalars['Float'];
  discountRate: Scalars['Int'];
  durationDays: Scalars['Int'];
  floorPrice: Scalars['Int'];
  /** total supply of tokens that will be provided to auction */
  fractions: Scalars['Int'];
  startDate: Scalars['Date'];
  /** Status can be changed only by the admin */
  status?: InputMaybe<DutchAuctionStatus>;
};

export type DutchAuctionSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<DutchAuctionOrder>;
  status?: InputMaybe<DutchAuctionStatus>;
};

export type DutchAuctionStats = {
  __typename?: 'DutchAuctionStats';
  averageBid: Scalars['Int'];
  highBid: Scalars['Int'];
  lowBid: Scalars['Int'];
  numBidders: Scalars['Int'];
  recommendedBid: Scalars['Int'];
  thresholdPrice: Scalars['Int'];
  weightedAverageBidPrice: Scalars['Int'];
};

export enum DutchAuctionStatus {
  Active = 'Active',
  Closed = 'Closed',
  Scheduled = 'Scheduled',
}

export enum DutchAuctionType {
  Cho = 'CHO',
  Iho = 'IHO',
}

/** is a part of talent application input. */
export type FieldCondition = {
  field: Scalars['String'];
  state: Scalars['Int'];
};

export enum FinancialModelUserType {
  Sponsor = 'Sponsor',
  Talent = 'Talent',
}

export enum HeightUnit {
  Centimeter = 'Centimeter',
  Feet = 'Feet',
  Meter = 'Meter',
}

export type Investor = {
  __typename?: 'Investor';
  activeAuctionTrades?: Maybe<Array<AuctionTrade>>;
  activeOrders?: Maybe<Array<Order>>;
  activePairs?: Maybe<Array<TradingPair>>;
  auctionTradeHistory?: Maybe<Array<AuctionTrade>>;
  dateCreated: Scalars['Date'];
  email: Scalars['String'];
  ethereumAddress: Scalars['NetworkAddress'];
  id: Scalars['ID'];
  isKYCVerified: Scalars['Boolean'];
  name: Scalars['String'];
  tradeHistory?: Maybe<Array<Trade>>;
};

/** LimitOrder wraps 0x order struct with stablecoin and tokencoin information */
export type LimitOrder = {
  /** signed order information */
  order: Order0x;
  /** stablecoin address */
  stablecoin: Scalars['String'];
  /** tokencoin address */
  tokencoin: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** accept existing invitation. */
  acceptSponsorUserInvitation: Scalars['String'];
  /** accept existing invitation. */
  acceptTalentSponsorInvitation: Scalars['String'];
  /**
   * approves athlete contract agreement and starts auction creation sequence. Only
   * admins can issue this mutation call.
   */
  approveAthleteContractAgreement: Scalars['String'];
  /** approve launch. */
  approveTalentApplicationLaunch: Scalars['String'];
  /** cancel existing sponsor application. */
  cancelSponsorApplication: Scalars['String'];
  /** cancel existing invitation. */
  cancelSponsorUserInvitation: Scalars['String'];
  /** cancel existing talent application. */
  cancelTalentApplication: Scalars['String'];
  /** terminate invitation between Talent and particular Sponsor. */
  cancelTalentSponsorInvitation: Scalars['String'];
  /** change the role of requested sponsor user. */
  changeSponsorUserRole: Scalars['String'];
  /** change the status of requested sponsor user. */
  changeSponsorUserStatus: Scalars['String'];
  /** underwriter accessible mutation for creating new athlete contracts */
  createAthleteContract: AthleteContractResponse;
  /** decline existing invitation. */
  declineSponsorUserInvitation: Scalars['String'];
  /** decline existing invitation. */
  declineTalentSponsorInvitation: Scalars['String'];
  /** delete talent application emergency contact information. */
  deleteTalentApplicationProfileEmergencyContact: Scalars['String'];
  /** delete talent application reference information. */
  deleteTalentApplicationProfileReference: Scalars['String'];
  /** delete resume information from talent application. */
  deleteTalentApplicationProfileResume: Scalars['String'];
  /** add provided athlete to favorite athletes list */
  favoriteAthlete: Scalars['String'];
  /**
   * issues auction finalization sc calls. Only admin or underwriter who is the
   * creator of auction can perform this action. On success returns the tx hash of
   * finalize auction call
   */
  finalizeAuction: Scalars['String'];
  /**
   * Grants investor role to given user. If walletAddress is provided, user's
   * wallet address will be changed to it
   */
  grantInvestorRole: Scalars['String'];
  /**
   * initialize sponsor application.
   * on that stage application will be mostly empty.
   */
  initSponsorApplication: SponsorApplication;
  /** initialize talent application. */
  initTalentApplication: TalentApplication;
  /**
   * send a freshly signed order to backend for matching and storing in database.
   * Returns order id
   */
  newOrder: Scalars['String'];
  /** re send an invitation. */
  resendSponsorUserInvitation: Scalars['String'];
  /** is a temporary endpoint for debug purpose. should be removed!!! */
  resetTalentApplicationStatus: Scalars['String'];
  /** send an invitation to the list of sponsor users. */
  sendSponsorUserInvitation: Scalars['String'];
  /** invite particular Sponsor. */
  sendTalentSponsorInvitation: Scalars['String'];
  /** submit sponsor application for review. */
  submitSponsorApplication: Scalars['String'];
  /** remove provided athlete to favorite athletes list */
  unfavoriteAthlete: Scalars['String'];
  /**
   * athletes can be updated by admin or by underwriter if underwriter
   * created the athlete information. Providing job history will remove
   * all previous jobs and create new jobs for the job history.
   */
  updateAthlete: Scalars['String'];
  /**
   * admin and underwriter accessible mutation for updating athlete contract
   * details. Entire athlete contract information object must be passed.
   * updateAthleteContract mutation should only be used for changing values,
   * it does not trigger blockchain interactions,
   */
  updateAthleteContract: AthleteContractResponse;
  /**
   * Returns ok or error string if something is wrong. Updating athlete's
   * contract status via updateAthleteContractStatus mutation triggers
   * blockchain interactions.
   */
  updateAthleteContractStatus: Scalars['String'];
  /**
   * input must be a complete DutchAuctionParameters object. All values will be
   * updated. Underwriter can edit dutch auction details only when the athlete
   * contract is in pending or denied status.
   */
  updateDutchAuction: Scalars['String'];
  /** update sponsor application. */
  updateSponsorApplication: Scalars['String'];
  /** update basic sponsor information. */
  updateSponsorInformation: Scalars['String'];
  /** update a launch section. */
  updateTalentApplicationLaunchSection: Scalars['String'];
  /** update legal clause for current talent application. */
  updateTalentApplicationLegalClause: Scalars['String'];
  /** update profession occupation information. */
  updateTalentApplicationProfession: Scalars['String'];
  /** update talent application profile information. */
  updateTalentApplicationProfile: TalentApplication;
  /** upload new financial model for current talent application. */
  uploadTalentApplicationFinancialModel: Scalars['String'];
};

export type MutationAcceptSponsorUserInvitationArgs = {
  token: Scalars['String'];
};

export type MutationAcceptTalentSponsorInvitationArgs = {
  token: Scalars['String'];
};

export type MutationApproveAthleteContractAgreementArgs = {
  athleteContractId: Scalars['ID'];
};

export type MutationCancelSponsorUserInvitationArgs = {
  userId: Scalars['Int'];
};

export type MutationCancelTalentSponsorInvitationArgs = {
  sponsorId: Scalars['ID'];
};

export type MutationChangeSponsorUserRoleArgs = {
  role: SponsorUserRole;
  userId: Scalars['Int'];
};

export type MutationChangeSponsorUserStatusArgs = {
  status: SponsorUserStatus;
  userId: Scalars['Int'];
};

export type MutationCreateAthleteContractArgs = {
  input: AthleteContractRequest;
};

export type MutationDeclineSponsorUserInvitationArgs = {
  token: Scalars['String'];
};

export type MutationDeclineTalentSponsorInvitationArgs = {
  token: Scalars['String'];
};

export type MutationDeleteTalentApplicationProfileEmergencyContactArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTalentApplicationProfileReferenceArgs = {
  id: Scalars['ID'];
};

export type MutationFavoriteAthleteArgs = {
  athleteID: Scalars['ID'];
};

export type MutationFinalizeAuctionArgs = {
  auctionId: Scalars['ID'];
};

export type MutationGrantInvestorRoleArgs = {
  userId: Scalars['ID'];
  walletAddress?: InputMaybe<Scalars['String']>;
};

export type MutationNewOrderArgs = {
  order?: InputMaybe<LimitOrder>;
};

export type MutationResendSponsorUserInvitationArgs = {
  userId: Scalars['Int'];
};

export type MutationSendSponsorUserInvitationArgs = {
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  role: SponsorUserRole;
};

export type MutationSendTalentSponsorInvitationArgs = {
  sponsorId: Scalars['ID'];
};

export type MutationUnfavoriteAthleteArgs = {
  athleteID: Scalars['ID'];
};

export type MutationUpdateAthleteArgs = {
  id: Scalars['ID'];
  input: AthleteInfo;
};

export type MutationUpdateAthleteContractArgs = {
  id: Scalars['ID'];
  input: AthleteContractDetails;
};

export type MutationUpdateAthleteContractStatusArgs = {
  id: Scalars['ID'];
  status: ContractStatus;
};

export type MutationUpdateDutchAuctionArgs = {
  id: Scalars['ID'];
  input: DutchAuctionParameters;
};

export type MutationUpdateSponsorApplicationArgs = {
  parameters: SponsorApplicationParameters;
};

export type MutationUpdateSponsorInformationArgs = {
  info: SponsorInformationParameters;
};

export type MutationUpdateTalentApplicationLaunchSectionArgs = {
  checked: Scalars['Boolean'];
  section: TalentApplicationLaunchSection;
};

export type MutationUpdateTalentApplicationLegalClauseArgs = {
  checked: Scalars['Boolean'];
  clauseId: Scalars['Int'];
};

export type MutationUpdateTalentApplicationProfessionArgs = {
  parameters: TalentApplicationProfessionParameters;
};

export type MutationUpdateTalentApplicationProfileArgs = {
  parameters: TalentApplicationProfileParameters;
};

export type MutationUploadTalentApplicationFinancialModelArgs = {
  file: Scalars['Upload'];
};

export type Order = {
  __typename?: 'Order';
  amountOfTokens: Scalars['Int'];
  date: Scalars['Date'];
  expiry: Scalars['Date'];
  id: Scalars['ID'];
  isBuy: Scalars['Boolean'];
  lastFillDate: Scalars['Date'];
  makerAddress: Scalars['NetworkAddress'];
  partialFillAmount: Scalars['Int'];
  position: TradePosition;
  price: Scalars['Int'];
  status: OrderStatus;
  takerAddress: Scalars['NetworkAddress'];
  takerAmount: Scalars['Int'];
  tradingPair: TradingPair;
};

/** represents the 0x order structure */
export type Order0x = {
  expiry: Scalars['Timestamp'];
  feeRecipient: Scalars['String'];
  maker: Scalars['String'];
  makerAmount: Scalars['Int'];
  makerToken: Scalars['String'];
  pool: Scalars['String'];
  salt: Scalars['String'];
  sender: Scalars['String'];
  signature: OrderSignature;
  taker: Scalars['String'];
  takerAmount: Scalars['Int'];
  takerToken: Scalars['String'];
  takerTokenFeeAmount: Scalars['Int'];
};

export enum OrderDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type OrderFill = {
  __typename?: 'OrderFill';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  isBuy: Scalars['Boolean'];
  makerTokenFilledAmount: Scalars['Int'];
  order: Order;
  orderId: Scalars['ID'];
  takerTokenFilledAmount: Scalars['Int'];
  unitPrice: Scalars['Int'];
};

/** any of the properties can be specified */
export type OrderFillSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderId?: InputMaybe<Scalars['ID']>;
};

export type OrderFillsPaginated = Paginated & {
  __typename?: 'OrderFillsPaginated';
  data: Array<Maybe<OrderFill>>;
  pagination?: Maybe<Pagination>;
};

/** any of the properties can be specified */
export type OrderSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** order signature */
export type OrderSignature = {
  r: Scalars['String'];
  s: Scalars['String'];
  signatureType: Scalars['Int'];
  v: Scalars['Int'];
};

export enum OrderStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Pending = 'Pending',
}

export type OrdersPaginated = Paginated & {
  __typename?: 'OrdersPaginated';
  data: Array<Maybe<Order>>;
  pagination: Pagination;
};

/** represents an interface which should be implemented by any query that wants to provide pagination logic. */
export type Paginated = {
  pagination?: Maybe<Pagination>;
};

/** is a part of Paginated interface. */
export type Pagination = {
  __typename?: 'Pagination';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

/** is a part of `promotedTalents` query. */
export type PromotedTalent = {
  __typename?: 'PromotedTalent';
  profileImageUrl: Scalars['String'];
  typeDetails: TypeDetails;
};

export type Query = {
  __typename?: 'Query';
  /** active dutch auctions search. Notice here you won't have bids stats. */
  activeDutchAuctions: DutchAuctionPaginated;
  /** athleteContract fetches specified athlete contract */
  athleteContract?: Maybe<AthleteContract>;
  /**
   * athleteContracts paginates all athlete contracts by limit and offset. If
   * status, nftName or nftSymbol is provided, result will be filtered by given
   * status, nftName or nftSymbol respectively.
   */
  athleteContracts: Array<AthleteContract>;
  /**
   * universal search for specific (searched by id) or non-specific athletes.
   * Specifying id will either return 1 athlete or empty array.
   */
  athletes: AthletesPaginated;
  /**
   * bids accepts auctionId and a list of bids received so far.
   * This query completes the subscription called latestBidReceivedOnAuction if
   * user calls this query at time t0, then it should subscribe to
   * latestBidReceivedOnAuction to receive newer bids from ws
   */
  bids: Array<Maybe<DutchAuctionBid>>;
  /** compactAthletes provides basic athletes information. */
  compactAthletes: AthletesPaginated;
  /** compactTradingPairs provides basic trading pairs information. */
  compactTradingPairs: Array<Maybe<TradingPair>>;
  /** configuration returns application configuration */
  configuration: Configuration;
  /**
   * dutch auction search object. Specifying id will either return 1 dutch auction
   * or empty array.
   */
  dutchAuctions: DutchAuctionPaginated;
  /** list of favorite athletes for user */
  favoriteAthletes: Array<Maybe<Athlete>>;
  /**
   * universal search for specific (searched by id) or non-specific order fills.
   * Specifying id will either return 1 order or empty array.
   */
  orderFills: OrderFillsPaginated;
  /**
   * universal search for specific (searched by id) or non-specific orders.
   * Specifying id will either return 1 order or empty array. Only user orders are
   * retrieved.
   */
  orders: OrdersPaginated;
  /** return random promoted list of talents. */
  promotedTalents: Array<PromotedTalent>;
  /** list of different activity types: Application activity, Sponsor User activity. */
  sponsorActivityList: SponsorActivityList;
  /** return current Sponsor Application. */
  sponsorApplication: SponsorApplication;
  /** return current Sponsor information. */
  sponsorInformation: SponsorInformation;
  /** list of users who belong to current Sponsor. */
  sponsorUserList: SponsorUserList;
  /** return current talent application. */
  talentApplication: TalentApplication;
  /** list of talent application activity. */
  talentApplicationActivityList: TalentApplicationActivityList;
  /** return talent application legal clauses */
  talentIPOLegalClauses: Array<TalentIpoLegalClause>;
  /** return talent configuration by its type. */
  talentTypeConfiguration: TalentTypeConfiguration;
  /** return existing/supporting talent types. */
  talentTypes: Array<TalentType>;
  /**
   * simple query for listing all token claims for given auction by its blockchain
   * id
   */
  tokenClaims: Array<Maybe<TokenClaims>>;
  /**
   * trading pair chart data is stored as candlesticks data. Trading pair id must
   * be provided in order to receive the trading chart data.
   */
  tradingPairCandlesticks: Array<Maybe<TradingPairCandlestick>>;
  /**
   * tradingPairs provides trading pairs information. Specifying id will either
   * return 1 trading pair or empty array. Nested properties of contracts
   * (underwriter, athlete, dutchAuction) will not be provided and will result in
   * an error if required.
   */
  tradingPairs: Array<Maybe<TradingPair>>;
  /** userBids accepts auctionId and a list of bids received so far made by the current user. */
  userBids: Array<Maybe<DutchAuctionBid>>;
  /**
   * userTradingPairs provides user's trading pairs information. Notice this query will only bring
   * trading pairs the user has orders filled or have won an auction.
   * Nested properties of contracts (underwriter, athlete, dutchAuction) will not be provided and
   * will result in an error if required.
   */
  userTradingPairs: TradingPairsPaginated;
};

export type QueryActiveDutchAuctionsArgs = {
  input: DutchAuctionSearchInput;
};

export type QueryAthleteContractArgs = {
  id: Scalars['ID'];
};

export type QueryAthleteContractsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ContractStatus>;
};

export type QueryAthletesArgs = {
  input: AthleteSearchInput;
};

export type QueryBidsArgs = {
  auctionId: Scalars['Int'];
};

export type QueryCompactAthletesArgs = {
  input: AthleteSearchInput;
};

export type QueryCompactTradingPairsArgs = {
  input: TradingPairSearchInput;
};

export type QueryDutchAuctionsArgs = {
  input: DutchAuctionSearchInput;
};

export type QueryOrderFillsArgs = {
  input: OrderFillSearchInput;
};

export type QueryOrdersArgs = {
  input: OrderSearchInput;
};

export type QuerySponsorActivityListArgs = {
  activityType: SponsorActivityListType;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySponsorUserListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
};

export type QueryTalentApplicationActivityListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryTalentTypeConfigurationArgs = {
  talentType: TalentType;
};

export type QueryTokenClaimsArgs = {
  auctionBlockchainId: Scalars['Int'];
};

export type QueryTradingPairCandlesticksArgs = {
  fromTimestamp: Scalars['Date'];
  resolution: Scalars['Int'];
  toTimestamp: Scalars['Date'];
  tradingPairId: Scalars['ID'];
};

export type QueryTradingPairsArgs = {
  input: TradingPairSearchInput;
};

export type QueryUserBidsArgs = {
  auctionId?: InputMaybe<Scalars['Int']>;
};

export type QueryUserTradingPairsArgs = {
  input: TradingPairSearchInput;
};

/** is a union of different activity types: SponsorUserActivity, SponsorApplicationActivity */
export type SponsorActivity = SponsorApplicationActivity | SponsorUserActivity;

/** paginated result of activityList query. */
export type SponsorActivityList = Paginated & {
  __typename?: 'SponsorActivityList';
  data: Array<Maybe<SponsorActivity>>;
  pagination?: Maybe<Pagination>;
};

export enum SponsorActivityListType {
  ApplicationActivity = 'ApplicationActivity',
  SponsorUserActivity = 'SponsorUserActivity',
}

export type SponsorApplication = {
  __typename?: 'SponsorApplication';
  approvedAt?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  reviewedAt?: Maybe<Scalars['Time']>;
  sponsorInformation: SponsorApplicationInformation;
  sponsorOwnerInformation: SponsorApplicationOwnerInformation;
  status: SponsorApplicationStatus;
};

/** is a part of SponsorApplicationActivityList type. */
export type SponsorApplicationActivity = {
  __typename?: 'SponsorApplicationActivity';
  createdAt: Scalars['Time'];
  field: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** is a part of sponsor application input. */
export type SponsorApplicationInformation = {
  __typename?: 'SponsorApplicationInformation';
  agreementUrl: Scalars['String'];
  apartment: Scalars['String'];
  city: Scalars['String'];
  corporateName: Scalars['String'];
  corporateType: Scalars['String'];
  country: Scalars['String'];
  description: Scalars['String'];
  /** is a Employer Identification Number */
  ein: Scalars['String'];
  logoUrlForDarkBackground: Scalars['String'];
  logoUrlForLightBackground: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: Scalars['String'];
  streetAddress: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

/** is a part of sponsor application input. */
export type SponsorApplicationInformationInput = {
  apartment?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporateName?: InputMaybe<Scalars['String']>;
  /**
   * corporation type depends from the selected country.
   * below is the list in the format `country code`: `list of supported corporation types`:
   *     `us`: ccorp, scorp, llp, llc, sp
   *     `uk`: plc, ltd, cbg, uc, llp, rc
   *     `au`: st, plc, asx, p
   *     `ca`: sp, p, corp
   */
  corporateType?: InputMaybe<Scalars['String']>;
  /** allowed countries: us, uk, au, ca. */
  country?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** is a Employer Identification Number */
  ein?: InputMaybe<Scalars['String']>;
  logoForDarkBackground?: InputMaybe<Scalars['Upload']>;
  logoForLightBackground?: InputMaybe<Scalars['Upload']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** is a part of sponsor application input. */
export type SponsorApplicationOwnerInformation = {
  __typename?: 'SponsorApplicationOwnerInformation';
  apartment: Scalars['String'];
  city: Scalars['String'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  state: Scalars['String'];
  streetAddress: Scalars['String'];
  zip: Scalars['String'];
};

/** is a part of sponsor application input. */
export type SponsorApplicationOwnerInformationInput = {
  apartment?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

/** represents sponsor application input */
export type SponsorApplicationParameters = {
  sponsorInformation?: InputMaybe<SponsorApplicationInformationInput>;
  sponsorOwnerInformation?: InputMaybe<SponsorApplicationOwnerInformationInput>;
};

export enum SponsorApplicationStatus {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Declined = 'Declined',
  InReview = 'InReview',
  Initiated = 'Initiated',
  Submitted = 'Submitted',
}

export type SponsorInformation = {
  __typename?: 'SponsorInformation';
  level: SponsorLevel;
  role: SponsorUserRole;
};

export type SponsorInformationParameters = {
  level?: InputMaybe<SponsorLevel>;
};

export enum SponsorLevel {
  Level1 = 'Level1',
  Level2 = 'Level2',
  Level3 = 'Level3',
}

export enum SponsorStatus {
  Completed = 'Completed',
  Pending = 'Pending',
}

/** is a part of SponsorUserList type. */
export type SponsorUser = {
  __typename?: 'SponsorUser';
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  role: SponsorUserRole;
  status: SponsorUserStatus;
  userId: Scalars['Int'];
};

/** is a part of SponsorUserActivityList type. */
export type SponsorUserActivity = {
  __typename?: 'SponsorUserActivity';
  action: SponsorUserActivityAction;
  createdAt: Scalars['Time'];
  sponsorName: Scalars['String'];
  sponsorUserName: Scalars['String'];
  value: Scalars['String'];
};

export enum SponsorUserActivityAction {
  InvitationAcceptedAction = 'InvitationAcceptedAction',
  InvitationCanceledAction = 'InvitationCanceledAction',
  InvitationDeclinedAction = 'InvitationDeclinedAction',
  InvitationResentAction = 'InvitationResentAction',
  InvitationSentAction = 'InvitationSentAction',
  RoleChangedAction = 'RoleChangedAction',
  StatusChangedAction = 'StatusChangedAction',
}

/** paginated result of sponsorUserList query. */
export type SponsorUserList = Paginated & {
  __typename?: 'SponsorUserList';
  data: Array<Maybe<SponsorUser>>;
  pagination?: Maybe<Pagination>;
};

export enum SponsorUserRole {
  Admin = 'Admin',
  Manager = 'Manager',
  Member = 'Member',
  Owner = 'Owner',
}

export enum SponsorUserStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Disabled = 'Disabled',
  Invited = 'Invited',
}

export type Subscription = {
  __typename?: 'Subscription';
  /** subscribe to latest dutch auction stats */
  latestAuctionStats: DutchAuctionStats;
  /** Subscribe to latest bids recieved on a given dutch auction */
  latestBidRecievedOnAuction: DutchAuctionBidSubscriptionItem;
  /** subscribe to latest trading pair stats. Data is refreshed after 15 minutes. */
  latestTradinPairStats: TradingPairStats;
  /**
   * returns latest trading pair candle data point subscription. Data is
   * refreshed every second. See trading pairs query for resolution parameter.
   */
  latestTradingPairCandle: TradingPairCandlestick;
};

export type SubscriptionLatestAuctionStatsArgs = {
  auctionId: Scalars['Int'];
};

export type SubscriptionLatestBidRecievedOnAuctionArgs = {
  auctionId: Scalars['Int'];
};

export type SubscriptionLatestTradinPairStatsArgs = {
  tradingPairId: Scalars['ID'];
};

export type SubscriptionLatestTradingPairCandleArgs = {
  resolution: Scalars['Int'];
  tradingPairId: Scalars['ID'];
};

export type TalentApplication = {
  __typename?: 'TalentApplication';
  createdAt: Scalars['Time'];
  financialModels: TalentApplicationFinancialModels;
  launch: TalentApplicationLaunch;
  legalClauses: Array<TalentApplicationLegalClause>;
  offeringParameters: TalentApplicationOfferingParameters;
  profession: TalentApplicationProfession;
  profile: TalentApplicationProfile;
  sponsors: TalentApplicationSponsors;
  state: TalentApplicationState;
  status: TalentApplicationStatus;
  updatedAt: Scalars['Time'];
  updatedBy: Scalars['String'];
};

export type TalentApplicationSponsorsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderDirection?: InputMaybe<OrderDirection>;
  orderType?: InputMaybe<TalentSponsorOrderType>;
};

/** is a part of TalentActivity union. */
export type TalentApplicationActionActivity = {
  __typename?: 'TalentApplicationActionActivity';
  action: Scalars['String'];
  actionDetail: Scalars['String'];
  createdAt: Scalars['Time'];
  userName: Scalars['String'];
};

/** is a union of different activity types: SponsorUserActivity, SponsorApplicationActivity */
export type TalentApplicationActivity =
  | TalentApplicationActionActivity
  | TalentApplicationFieldChangeActivity;

/** paginated result of talentApplicationActivityList query. */
export type TalentApplicationActivityList = Paginated & {
  __typename?: 'TalentApplicationActivityList';
  data: Array<Maybe<TalentApplicationActivity>>;
  pagination?: Maybe<Pagination>;
};

export type TalentApplicationBasicInformationInput = {
  apartment?: InputMaybe<Scalars['String']>;
  backgroundCheckAuthorization?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  condition: FieldCondition;
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedinProfileUrl?: InputMaybe<Scalars['String']>;
  maritalStatus?: InputMaybe<TalentApplicationBasicInformationMaritalStatus>;
  middleName?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  passportPhoto?: InputMaybe<Scalars['Upload']>;
  placeOfBirthCity?: InputMaybe<Scalars['String']>;
  placeOfBirthCountry?: InputMaybe<Scalars['String']>;
  placeOfBirthState?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['Upload']>;
  resume?: InputMaybe<Scalars['Upload']>;
  sex?: InputMaybe<TalentApplicationBasicInformationSex>;
  ssn?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export enum TalentApplicationBasicInformationMaritalStatus {
  Married = 'Married',
  Single = 'Single',
}

export enum TalentApplicationBasicInformationSex {
  Female = 'Female',
  Male = 'Male',
}

/** input for updateTalentApplicationProfile mutation. */
export type TalentApplicationEmergencyContactInput = {
  condition: FieldCondition;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
};

/** is a part of TalentActivity union. */
export type TalentApplicationFieldChangeActivity = {
  __typename?: 'TalentApplicationFieldChangeActivity';
  createdAt: Scalars['Time'];
  field: Scalars['String'];
  fieldValue: Scalars['String'];
  userName: Scalars['String'];
};

/** is a part of TalentApplication type. */
export type TalentApplicationFinancialModel = {
  __typename?: 'TalentApplicationFinancialModel';
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  fileName: Scalars['String'];
  fileUrl: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
  userType: FinancialModelUserType;
};

/** is a part of TalentApplication type. */
export type TalentApplicationFinancialModels = {
  __typename?: 'TalentApplicationFinancialModels';
  current?: Maybe<TalentApplicationFinancialModel>;
  history?: Maybe<Array<Maybe<TalentApplicationFinancialModel>>>;
  templateUrl: Scalars['String'];
};

/** is a part of `initTalentApplication` query. */
export type TalentApplicationLaunch = {
  __typename?: 'TalentApplicationLaunch';
  approvals: TalentApplicationLaunchApprovals;
  state: TalentApplicationLaunchState;
};

/** is a part of `TalentApplicationLaunch` type. */
export type TalentApplicationLaunchApprovalSection = {
  __typename?: 'TalentApplicationLaunchApprovalSection';
  approved: Scalars['Boolean'];
  approvedAt: Scalars['Date'];
  approvedBy: Scalars['String'];
};

/** is a part of `TalentApplicationLaunch` type. */
export type TalentApplicationLaunchApprovals = {
  __typename?: 'TalentApplicationLaunchApprovals';
  hcx: TalentApplicationLaunchApprovalSection;
  sponsor: TalentApplicationLaunchApprovalSection;
  talent: TalentApplicationLaunchApprovalSection;
};

export enum TalentApplicationLaunchPersonType {
  Hcx = 'HCX',
  Sponsor = 'Sponsor',
  Talent = 'Talent',
}

export enum TalentApplicationLaunchSection {
  FinancialModel = 'FinancialModel',
  Legal = 'Legal',
  OfferingParameters = 'OfferingParameters',
  Profession = 'Profession',
  Profile = 'Profile',
  Sponsorship = 'Sponsorship',
}

/** is a part of `TalentApplicationLaunch` type. */
export type TalentApplicationLaunchState = {
  __typename?: 'TalentApplicationLaunchState';
  hcx: TalentApplicationLaunchStateSection;
  sponsor: TalentApplicationLaunchStateSection;
  talent: TalentApplicationLaunchStateSection;
};

/** is a part of `TalentApplicationLaunchState` type. */
export type TalentApplicationLaunchStateSection = {
  __typename?: 'TalentApplicationLaunchStateSection';
  financialModel: Scalars['Boolean'];
  legal: Scalars['Boolean'];
  offeringParameters: Scalars['Boolean'];
  profession: Scalars['Boolean'];
  profile: Scalars['Boolean'];
  sponsorship: Scalars['Boolean'];
};

/** is a part of `talentApplication` query. */
export type TalentApplicationLegalClause = {
  __typename?: 'TalentApplicationLegalClause';
  checked: Scalars['Boolean'];
  id: Scalars['ID'];
};

/** is a part of TalentApplication type. */
export type TalentApplicationOfferingParameters = {
  __typename?: 'TalentApplicationOfferingParameters';
  endDate: Scalars['Date'];
  futureIncome: Scalars['Float'];
  futureIncomeShared: Scalars['Float'];
  minimumGross: Scalars['Float'];
  minimumSharePrice: Scalars['Float'];
  numberOfShares: Scalars['Int'];
  startDate: Scalars['Date'];
};

/** is a part of `talentApplicationProfession` query. */
export type TalentApplicationProfession = {
  __typename?: 'TalentApplicationProfession';
  occupation: TalentApplicationProfessionOccupation;
};

/** is a part of `talentApplicationProfession` query. */
export type TalentApplicationProfessionOccupation = {
  __typename?: 'TalentApplicationProfessionOccupation';
  details: Scalars['Object'];
  talentType: TalentType;
};

export type TalentApplicationProfessionOccupationInput = {
  details: Scalars['Any'];
  talentType: TalentType;
};

/** input for updateTalentApplicationProfession mutation. */
export type TalentApplicationProfessionParameters = {
  occupation: TalentApplicationProfessionOccupationInput;
};

export type TalentApplicationProfile = {
  __typename?: 'TalentApplicationProfile';
  basicInformation: TalentApplicationProfileBasicInformation;
  emergencyContacts: Array<TalentApplicationProfileEmergencyContact>;
  references: Array<TalentApplicationProfileReference>;
  socialMedias: Array<TalentApplicationProfileSocialMedia>;
};

export type TalentApplicationProfileBasicInformation = {
  __typename?: 'TalentApplicationProfileBasicInformation';
  apartment: Scalars['String'];
  backgroundCheckAuthorization: Scalars['Boolean'];
  city: Scalars['String'];
  condition: Scalars['Any'];
  country: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  description: Scalars['String'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  linkedinProfileUrl: Scalars['String'];
  maritalStatus: TalentApplicationBasicInformationMaritalStatus;
  middleName: Scalars['String'];
  nationality: Scalars['String'];
  passportPhotoUrl: Scalars['String'];
  placeOfBirthCity: Scalars['String'];
  placeOfBirthCountry: Scalars['String'];
  placeOfBirthState: Scalars['String'];
  profilePictureUrl: Scalars['String'];
  resumeFileName: Scalars['String'];
  resumeUrl: Scalars['String'];
  sex: TalentApplicationBasicInformationSex;
  ssn: Scalars['String'];
  state: Scalars['String'];
  streetAddress: Scalars['String'];
  zip: Scalars['String'];
};

export type TalentApplicationProfileEmergencyContact = {
  __typename?: 'TalentApplicationProfileEmergencyContact';
  condition: Scalars['Any'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  relationship: Scalars['String'];
};

/** input for updateTalentApplicationProfile mutation. */
export type TalentApplicationProfileParameters = {
  basicInformation?: InputMaybe<TalentApplicationBasicInformationInput>;
  emergencyContact?: InputMaybe<TalentApplicationEmergencyContactInput>;
  reference?: InputMaybe<TalentApplicationReferenceInput>;
};

export type TalentApplicationProfileReference = {
  __typename?: 'TalentApplicationProfileReference';
  condition: Scalars['Any'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type TalentApplicationProfileSocialMedia = {
  __typename?: 'TalentApplicationProfileSocialMedia';
  condition: Scalars['Any'];
  delay: Scalars['Int'];
  enabled: Scalars['Boolean'];
  medialType: TalentApplicationSocialMediaType;
  shareWithFollowers: Scalars['Boolean'];
  shareWithFriends: Scalars['Boolean'];
};

/** is a part of `TalentApplicationState` type. */
export type TalentApplicationProfileState = {
  __typename?: 'TalentApplicationProfileState';
  authorizationCheck: Scalars['Boolean'];
  emergencyContact: Scalars['Boolean'];
  personalProfile: Scalars['Boolean'];
  references: Scalars['Boolean'];
  resume: Scalars['Boolean'];
  verification: Scalars['Boolean'];
};

/** input for updateTalentApplicationProfile mutation. */
export type TalentApplicationReferenceInput = {
  condition: FieldCondition;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** input for updateTalentApplicationProfile mutation. */
export type TalentApplicationSocialMediaParameters = {
  delay?: InputMaybe<Scalars['Int']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  medialType?: InputMaybe<TalentApplicationSocialMediaType>;
  shareWithFollowers?: InputMaybe<Scalars['Boolean']>;
  shareWithFriends?: InputMaybe<Scalars['Boolean']>;
};

export enum TalentApplicationSocialMediaType {
  Discord = 'Discord',
  Facebook = 'Facebook',
  Linkedin = 'Linkedin',
  Twitter = 'Twitter',
}

/** paginated result of talent sponsors query. */
export type TalentApplicationSponsors = Paginated & {
  __typename?: 'TalentApplicationSponsors';
  data: Array<Maybe<TalentSponsor>>;
  pagination?: Maybe<Pagination>;
};

/** is a part of `TalentApplication` type. */
export type TalentApplicationState = {
  __typename?: 'TalentApplicationState';
  financialModels: Scalars['Boolean'];
  launch: Scalars['Boolean'];
  legalClauses: Scalars['Boolean'];
  offeringParameters: Scalars['Boolean'];
  profession: Scalars['Boolean'];
  profile?: Maybe<TalentApplicationProfileState>;
  sponsors: Scalars['Boolean'];
};

export enum TalentApplicationStatus {
  Approved = 'Approved',
  Canceled = 'Canceled',
  Declined = 'Declined',
  InReview = 'InReview',
  Initiated = 'Initiated',
  Submitted = 'Submitted',
}

/** is a part of `talentApplicationLegalClauses` query. */
export type TalentIpoLegalClause = {
  __typename?: 'TalentIPOLegalClause';
  checked: Scalars['Boolean'];
  id: Scalars['ID'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type TalentSponsor = {
  __typename?: 'TalentSponsor';
  description: Scalars['String'];
  emailAddress: Scalars['String'];
  id: Scalars['ID'];
  level: SponsorLevel;
  logoUrlForDarkBackground: Scalars['String'];
  logoUrlForLightBackground: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  /**
   * source represents invitation direction. who invited whom:
   * - Talent - means that talent invited a sponsor.
   * - Sponsor - means that sponsor invited a talent.
   */
  source: TalentSponsorInvitationSource;
  /** status represents current invitation status. */
  status: TalentSponsorInvitationStatus;
};

export enum TalentSponsorInvitationSource {
  Sponsor = 'Sponsor',
  Talent = 'Talent',
}

export enum TalentSponsorInvitationStatus {
  Accepted = 'Accepted',
  Canceled = 'Canceled',
  Created = 'Created',
  Declined = 'Declined',
}

export enum TalentSponsorOrderType {
  Alphabetical = 'Alphabetical',
  MostIho = 'MostIHO',
  MostRecent = 'MostRecent',
}

export enum TalentType {
  Athlete = 'Athlete',
  Doctor = 'Doctor',
}

/** is a part of `talentTypeConfiguration` query. */
export type TalentTypeConfiguration = {
  __typename?: 'TalentTypeConfiguration';
  jsonSchema: Scalars['Object'];
  talentType: TalentType;
  uiSchema: Scalars['Object'];
};

export type TokenClaims = {
  __typename?: 'TokenClaims';
  auctionBlockchainId: Scalars['Int'];
  bidderAddress: Scalars['String'];
  claimAmount: Scalars['Int'];
  tokenPrice: Scalars['Int'];
};

export type Trade = {
  __typename?: 'Trade';
  date: Scalars['Date'];
  id: Scalars['ID'];
  makerAddress: Scalars['NetworkAddress'];
  makerPosition: TradePosition;
  pricePerToken: Scalars['Int'];
  takerAddress: Scalars['NetworkAddress'];
  totalPrice: Scalars['Int'];
};

export enum TradePosition {
  Ask = 'Ask',
  Bid = 'Bid',
}

/**
 * Trading pair is a pair available on the HCX after the Dutch auction concludes
 * successfully.
 */
export type TradingPair = {
  __typename?: 'TradingPair';
  athlete: Athlete;
  contracts?: Maybe<Array<AthleteContract>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  stableCoinAddress: Scalars['NetworkAddress'];
  stats?: Maybe<TradingPairStats>;
  symbol: Scalars['String'];
  tokenAddress: Scalars['NetworkAddress'];
};

/** Data point representing a candle stick in trading chart */
export type TradingPairCandlestick = {
  __typename?: 'TradingPairCandlestick';
  close: Scalars['Float'];
  closeTimestamp: Scalars['Date'];
  high: Scalars['Float'];
  low: Scalars['Float'];
  open: Scalars['Float'];
  openTimestamp: Scalars['Date'];
  /**
   * Resolution in minutes. Smallest resolution is 5 minutes. Resolution is used
   * only for debug reasons.
   */
  resolution?: Maybe<Scalars['Int']>;
  volume: Scalars['Float'];
};

/**
 * if id is provided, symbolOrName will be ignored. If limit is not provided, 10
 * pairs are returned by default.
 */
export type TradingPairSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  /** trading pair symbol or name */
  symbolOrName?: InputMaybe<Scalars['String']>;
};

export type TradingPairStats = {
  __typename?: 'TradingPairStats';
  /** price change in last 24 hours */
  change24Amount: Scalars['Float'];
  /** Price change in last 24 hours */
  change24Percent: Scalars['Float'];
  lastPrice: Scalars['Float'];
  marketCap: Scalars['Int'];
  volume24: Scalars['Int'];
};

export type TradingPairsPaginated = Paginated & {
  __typename?: 'TradingPairsPaginated';
  data: Array<Maybe<TradingPair>>;
  pagination: Pagination;
};

/** is a part of `promotedTalents` query. */
export type TypeDetails = AthleteType | DoctorType;

export type Underwriter = {
  __typename?: 'Underwriter';
  dateCreated: Scalars['Date'];
  email: Scalars['String'];
  ethereumAddress: Scalars['NetworkAddress'];
  id: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ConfigurationAuthQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigurationAuthQuery = {
  __typename?: 'Query';
  configuration: {
    __typename?: 'Configuration';
    auth0: {
      __typename?: 'Auth0Configuration';
      domain: string;
      clientId: string;
      audience: string;
    };
  };
};

export type CoreAthleteJobFieldsFragment = {
  __typename?: 'AthleteJob';
  drafted: string;
  league: string;
  number?: number | null;
  position?: string | null;
  rosterStatus: boolean;
  team: string;
};

export type CoreAthleteFieldsFragment = {
  __typename?: 'Athlete';
  dob: any;
  education: string;
  erc20SmartContractAddress: any;
  ethereumAddress: any;
  fullName: string;
  height: number;
  imageUrl: string;
  nftName: string;
  nftSmartContractAddress: any;
  nftSymbol: string;
  sex: string;
  id: string;
};

export type AthletesQueryVariables = Exact<{
  input: AthleteSearchInput;
}>;

export type AthletesQuery = {
  __typename?: 'Query';
  athletes: {
    __typename?: 'AthletesPaginated';
    data: Array<{
      __typename?: 'Athlete';
      id: string;
      dob: any;
      education: string;
      erc20SmartContractAddress: any;
      ethereumAddress: any;
      fullName: string;
      height: number;
      imageUrl: string;
      nftName: string;
      nftSmartContractAddress: any;
      nftSymbol: string;
      sex: string;
      tradingPair?: {
        __typename?: 'TradingPair';
        id: string;
        tokenAddress: any;
        stableCoinAddress: any;
      } | null;
      currentJob: {
        __typename?: 'AthleteJob';
        drafted: string;
        league: string;
        number?: number | null;
        position?: string | null;
        rosterStatus: boolean;
        team: string;
      };
      jobHistory?: Array<{
        __typename?: 'AthleteJob';
        drafted: string;
        league: string;
        number?: number | null;
        position?: string | null;
        rosterStatus: boolean;
        team: string;
      }> | null;
    } | null>;
    pagination: { __typename?: 'Pagination'; limit: number; offset: number; total: number };
  };
};

export type TradingPairStatsQueryVariables = Exact<{
  input: TradingPairSearchInput;
}>;

export type TradingPairStatsQuery = {
  __typename?: 'Query';
  tradingPairs: Array<{
    __typename?: 'TradingPair';
    id: string;
    stats?: {
      __typename?: 'TradingPairStats';
      volume24: number;
      lastPrice: number;
      change24Percent: number;
      change24Amount: number;
      marketCap: number;
    } | null;
  } | null>;
};

export type ActivityListQueryVariables = Exact<{
  activityType: SponsorActivityListType;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;

export type ActivityListQuery = {
  __typename?: 'Query';
  sponsorActivityList: {
    __typename?: 'SponsorActivityList';
    data: Array<
      | {
          __typename?: 'SponsorApplicationActivity';
          field: string;
          value: string;
          userName?: string | null;
          createdAt: any;
        }
      | {
          __typename?: 'SponsorUserActivity';
          action: SponsorUserActivityAction;
          value: string;
          sponsorName: string;
          sponsorUserName: string;
          createdAt: any;
        }
      | null
    >;
    pagination?: { __typename?: 'Pagination'; total: number; offset: number; limit: number } | null;
  };
};

export type TalentActivityListQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;

export type TalentActivityListQuery = {
  __typename?: 'Query';
  talentApplicationActivityList: {
    __typename?: 'TalentApplicationActivityList';
    data: Array<
      | {
          __typename?: 'TalentApplicationActionActivity';
          action: string;
          actionDetail: string;
          createdAt: any;
          userName: string;
        }
      | {
          __typename?: 'TalentApplicationFieldChangeActivity';
          createdAt: any;
          field: string;
          fieldValue: string;
          userName: string;
        }
      | null
    >;
    pagination?: { __typename?: 'Pagination'; limit: number; total: number; offset: number } | null;
  };
};

export type LatestTradingPairStatsSubscriptionVariables = Exact<{
  tradingPairId: Scalars['ID'];
}>;

export type LatestTradingPairStatsSubscription = {
  __typename?: 'Subscription';
  latestTradinPairStats: {
    __typename?: 'TradingPairStats';
    volume24: number;
    lastPrice: number;
    change24Percent: number;
    change24Amount: number;
    marketCap: number;
  };
};

export type FavoriteAthleteIdsQueryVariables = Exact<{ [key: string]: never }>;

export type FavoriteAthleteIdsQuery = {
  __typename?: 'Query';
  favoriteAthletes: Array<{ __typename?: 'Athlete'; id: string } | null>;
};

export type FavoriteAthleteMutationVariables = Exact<{
  athleteId: Scalars['ID'];
}>;

export type FavoriteAthleteMutation = { __typename?: 'Mutation'; favoriteAthlete: string };

export type UnfavoriteAthleteMutationVariables = Exact<{
  athleteId: Scalars['ID'];
}>;

export type UnfavoriteAthleteMutation = { __typename?: 'Mutation'; unfavoriteAthlete: string };

export type TradingPairCandlesticksQueryVariables = Exact<{
  tradingPairId: Scalars['ID'];
  fromTimestamp: Scalars['Date'];
  toTimestamp: Scalars['Date'];
  resolution: Scalars['Int'];
}>;

export type TradingPairCandlesticksQuery = {
  __typename?: 'Query';
  tradingPairCandlesticks: Array<{
    __typename?: 'TradingPairCandlestick';
    openTimestamp: any;
    closeTimestamp: any;
    open: number;
    close: number;
    high: number;
    low: number;
    volume: number;
  } | null>;
};

export type LatestTradingPairCandleSubscriptionVariables = Exact<{
  tradingPairId: Scalars['ID'];
  resolution: Scalars['Int'];
}>;

export type LatestTradingPairCandleSubscription = {
  __typename?: 'Subscription';
  latestTradingPairCandle: {
    __typename?: 'TradingPairCandlestick';
    openTimestamp: any;
    closeTimestamp: any;
    open: number;
    close: number;
    high: number;
    low: number;
    volume: number;
  };
};

export type AllAthletesQueryVariables = Exact<{ [key: string]: never }>;

export type AllAthletesQuery = {
  __typename?: 'Query';
  athletes: {
    __typename?: 'AthletesPaginated';
    data: Array<{
      __typename?: 'Athlete';
      id: string;
      tradingPair?: {
        __typename?: 'TradingPair';
        id: string;
        name: string;
        tokenAddress: any;
        symbol: string;
      } | null;
    } | null>;
    pagination: { __typename?: 'Pagination'; limit: number; offset: number; total: number };
  };
};

export type NewOrderMutationVariables = Exact<{
  order?: InputMaybe<LimitOrder>;
}>;

export type NewOrderMutation = { __typename?: 'Mutation'; newOrder: string };

export type AthleteContractQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AthleteContractQuery = {
  __typename?: 'Query';
  athleteContract?: {
    __typename?: 'AthleteContract';
    id: string;
    dutchAuction: {
      __typename?: 'DutchAuction';
      id: string;
      blockchainId: string;
      floorPrice: number;
      discountRate: number;
      discountFrequency: number;
      status: DutchAuctionStatus;
      startDate: any;
      durationDays: number;
      stats?: {
        __typename?: 'DutchAuctionStats';
        recommendedBid: number;
        numBidders: number;
        highBid: number;
        lowBid: number;
      } | null;
    };
  } | null;
};

export type SponsorApplicationStatusQueryVariables = Exact<{ [key: string]: never }>;

export type SponsorApplicationStatusQuery = {
  __typename?: 'Query';
  sponsorApplication: {
    __typename?: 'SponsorApplication';
    status: SponsorApplicationStatus;
    createdAt: any;
    reviewedAt?: any | null;
    approvedAt?: any | null;
  };
};

export type SponsorApplicationDataQueryVariables = Exact<{ [key: string]: never }>;

export type SponsorApplicationDataQuery = {
  __typename?: 'Query';
  sponsorApplication: {
    __typename?: 'SponsorApplication';
    status: SponsorApplicationStatus;
    createdAt: any;
    reviewedAt?: any | null;
    approvedAt?: any | null;
    sponsorInformation: {
      __typename?: 'SponsorApplicationInformation';
      corporateName: string;
      corporateType: string;
      streetAddress: string;
      apartment: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      description: string;
      phoneNumber: string;
      websiteUrl?: string | null;
      agreementUrl: string;
      ein: string;
      logoUrlForDarkBackground: string;
      logoUrlForLightBackground: string;
    };
    sponsorOwnerInformation: {
      __typename?: 'SponsorApplicationOwnerInformation';
      firstName: string;
      lastName: string;
      streetAddress: string;
      apartment: string;
      city: string;
      state: string;
      zip: string;
      phoneNumber: string;
      emailAddress: string;
    };
  };
};

export type InitSponsorApplicationMutationVariables = Exact<{ [key: string]: never }>;

export type InitSponsorApplicationMutation = {
  __typename?: 'Mutation';
  initSponsorApplication: {
    __typename?: 'SponsorApplication';
    status: SponsorApplicationStatus;
    createdAt: any;
    reviewedAt?: any | null;
    approvedAt?: any | null;
    sponsorInformation: {
      __typename?: 'SponsorApplicationInformation';
      corporateName: string;
      corporateType: string;
      streetAddress: string;
      apartment: string;
      city: string;
      state: string;
      zip: string;
      country: string;
      description: string;
      phoneNumber: string;
      websiteUrl?: string | null;
      agreementUrl: string;
      ein: string;
      logoUrlForDarkBackground: string;
      logoUrlForLightBackground: string;
    };
    sponsorOwnerInformation: {
      __typename?: 'SponsorApplicationOwnerInformation';
      firstName: string;
      lastName: string;
      streetAddress: string;
      apartment: string;
      city: string;
      state: string;
      zip: string;
      phoneNumber: string;
      emailAddress: string;
    };
  };
};

export type UpdateSponsorApplicationMutationVariables = Exact<{
  parameters: SponsorApplicationParameters;
}>;

export type UpdateSponsorApplicationMutation = {
  __typename?: 'Mutation';
  updateSponsorApplication: string;
};

export type CancelSponsorApplicationMutationVariables = Exact<{ [key: string]: never }>;

export type CancelSponsorApplicationMutation = {
  __typename?: 'Mutation';
  cancelSponsorApplication: string;
};

export type SubmitSponsorApplicationMutationVariables = Exact<{ [key: string]: never }>;

export type SubmitSponsorApplicationMutation = {
  __typename?: 'Mutation';
  submitSponsorApplication: string;
};

export type LogoDarkQueryVariables = Exact<{ [key: string]: never }>;

export type LogoDarkQuery = {
  __typename?: 'Query';
  sponsorApplication: {
    __typename?: 'SponsorApplication';
    sponsorInformation: {
      __typename?: 'SponsorApplicationInformation';
      logoUrlForDarkBackground: string;
    };
  };
};

export type LogoLightQueryVariables = Exact<{ [key: string]: never }>;

export type LogoLightQuery = {
  __typename?: 'Query';
  sponsorApplication: {
    __typename?: 'SponsorApplication';
    sponsorInformation: {
      __typename?: 'SponsorApplicationInformation';
      logoUrlForLightBackground: string;
    };
  };
};

export type SponsorUserListQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order: Scalars['String'];
}>;

export type SponsorUserListQuery = {
  __typename?: 'Query';
  sponsorUserList: {
    __typename?: 'SponsorUserList';
    data: Array<{
      __typename?: 'SponsorUser';
      name: string;
      email: string;
      phone: string;
      userId: number;
      role: SponsorUserRole;
      status: SponsorUserStatus;
    } | null>;
    pagination?: { __typename?: 'Pagination'; limit: number; total: number; offset: number } | null;
  };
};

export type SponsorInformationQueryVariables = Exact<{ [key: string]: never }>;

export type SponsorInformationQuery = {
  __typename?: 'Query';
  sponsorInformation: {
    __typename?: 'SponsorInformation';
    role: SponsorUserRole;
    level: SponsorLevel;
  };
};

export type UpdateSponsorInformationMutationVariables = Exact<{
  info: SponsorInformationParameters;
}>;

export type UpdateSponsorInformationMutation = {
  __typename?: 'Mutation';
  updateSponsorInformation: string;
};

export type SendSponsorUserInvitationMutationVariables = Exact<{
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  role: SponsorUserRole;
}>;

export type SendSponsorUserInvitationMutation = {
  __typename?: 'Mutation';
  sendSponsorUserInvitation: string;
};

export type ResendSponsorUserInvitationMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type ResendSponsorUserInvitationMutation = {
  __typename?: 'Mutation';
  resendSponsorUserInvitation: string;
};

export type DeclineSponsorUserInvitationMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type DeclineSponsorUserInvitationMutation = {
  __typename?: 'Mutation';
  declineSponsorUserInvitation: string;
};

export type CancelSponsorUserInvitationMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type CancelSponsorUserInvitationMutation = {
  __typename?: 'Mutation';
  cancelSponsorUserInvitation: string;
};

export type ChangeSponsorUserRoleMutationVariables = Exact<{
  userId: Scalars['Int'];
  role: SponsorUserRole;
}>;

export type ChangeSponsorUserRoleMutation = {
  __typename?: 'Mutation';
  changeSponsorUserRole: string;
};

export type ChangeSponsorUserStatusMutationVariables = Exact<{
  userId: Scalars['Int'];
  status: SponsorUserStatus;
}>;

export type ChangeSponsorUserStatusMutation = {
  __typename?: 'Mutation';
  changeSponsorUserStatus: string;
};

export type SponsorManagementInfoQueryVariables = Exact<{ [key: string]: never }>;

export type SponsorManagementInfoQuery = {
  __typename?: 'Query';
  sponsorApplication: {
    __typename?: 'SponsorApplication';
    sponsorInformation: {
      __typename?: 'SponsorApplicationInformation';
      corporateName: string;
      corporateType: string;
      country: string;
      ein: string;
      logoUrlForDarkBackground: string;
      logoUrlForLightBackground: string;
      description: string;
    };
    sponsorOwnerInformation: {
      __typename?: 'SponsorApplicationOwnerInformation';
      firstName: string;
      lastName: string;
      streetAddress: string;
      apartment: string;
      city: string;
      state: string;
      zip: string;
      phoneNumber: string;
      emailAddress: string;
    };
  };
};

export type ProfilePhotoQueryVariables = Exact<{ [key: string]: never }>;

export type ProfilePhotoQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    profile: {
      __typename?: 'TalentApplicationProfile';
      basicInformation: {
        __typename?: 'TalentApplicationProfileBasicInformation';
        profilePictureUrl: string;
      };
    };
  };
};

export type DeleteTalentApplicationProfileResumeMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteTalentApplicationProfileResumeMutation = {
  __typename?: 'Mutation';
  deleteTalentApplicationProfileResume: string;
};

export type PassportPhotoQueryVariables = Exact<{ [key: string]: never }>;

export type PassportPhotoQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    profile: {
      __typename?: 'TalentApplicationProfile';
      basicInformation: {
        __typename?: 'TalentApplicationProfileBasicInformation';
        passportPhotoUrl: string;
      };
    };
  };
};

export type TalentApplicationProfileQueryVariables = Exact<{ [key: string]: never }>;

export type TalentApplicationProfileQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    profile: {
      __typename?: 'TalentApplicationProfile';
      basicInformation: {
        __typename?: 'TalentApplicationProfileBasicInformation';
        firstName: string;
        middleName: string;
        lastName: string;
        streetAddress: string;
        apartment: string;
        city: string;
        state: string;
        zip: string;
        country: string;
        emailAddress: string;
        dateOfBirth: any;
        sex: TalentApplicationBasicInformationSex;
        nationality: string;
        maritalStatus: TalentApplicationBasicInformationMaritalStatus;
        ssn: string;
        placeOfBirthCity: string;
        placeOfBirthState: string;
        placeOfBirthCountry: string;
        passportPhotoUrl: string;
        profilePictureUrl: string;
        resumeUrl: string;
        resumeFileName: string;
        linkedinProfileUrl: string;
        description: string;
        backgroundCheckAuthorization: boolean;
        condition: any;
      };
      emergencyContacts: Array<{
        __typename?: 'TalentApplicationProfileEmergencyContact';
        id: number;
        condition: any;
        firstName: string;
        lastName: string;
        emailAddress: string;
        phone: string;
        relationship: string;
      }>;
      references: Array<{
        __typename?: 'TalentApplicationProfileReference';
        id: number;
        condition: any;
        firstName: string;
        lastName: string;
        emailAddress: string;
        phone: string;
      }>;
      socialMedias: Array<{
        __typename?: 'TalentApplicationProfileSocialMedia';
        enabled: boolean;
        medialType: TalentApplicationSocialMediaType;
        shareWithFriends: boolean;
        shareWithFollowers: boolean;
        delay: number;
        condition: any;
      }>;
    };
  };
};

export type InitTalentApplicationMutationVariables = Exact<{ [key: string]: never }>;

export type InitTalentApplicationMutation = {
  __typename?: 'Mutation';
  initTalentApplication: { __typename?: 'TalentApplication'; status: TalentApplicationStatus };
};

export type TalentApplicationStatusQueryVariables = Exact<{ [key: string]: never }>;

export type TalentApplicationStatusQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    status: TalentApplicationStatus;
    updatedAt: any;
    updatedBy: string;
  };
};

export type TalentApplicationStateQueryVariables = Exact<{ [key: string]: never }>;

export type TalentApplicationStateQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    state: {
      __typename?: 'TalentApplicationState';
      launch: boolean;
      sponsors: boolean;
      profession: boolean;
      legalClauses: boolean;
      financialModels: boolean;
      offeringParameters: boolean;
      profile?: {
        __typename?: 'TalentApplicationProfileState';
        resume: boolean;
        references: boolean;
        verification: boolean;
        personalProfile: boolean;
        emergencyContact: boolean;
        authorizationCheck: boolean;
      } | null;
    };
  };
};

export type TalentApplicationFinancialModelsQueryVariables = Exact<{ [key: string]: never }>;

export type TalentApplicationFinancialModelsQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    financialModels: {
      __typename?: 'TalentApplicationFinancialModels';
      templateUrl: string;
      current?: {
        __typename?: 'TalentApplicationFinancialModel';
        userType: FinancialModelUserType;
        fileUrl: string;
        fileName: string;
        createdAt: any;
        createdBy: string;
        updatedAt: any;
      } | null;
      history?: Array<{
        __typename?: 'TalentApplicationFinancialModel';
        userType: FinancialModelUserType;
        fileUrl: string;
        fileName: string;
        createdAt: any;
        createdBy: string;
        updatedAt: any;
      } | null> | null;
    };
  };
};

export type UploadTalentApplicationFinancialModelMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;

export type UploadTalentApplicationFinancialModelMutation = {
  __typename?: 'Mutation';
  uploadTalentApplicationFinancialModel: string;
};

export type UpdateTalentApplicationProfileMutationVariables = Exact<{
  parameters: TalentApplicationProfileParameters;
}>;

export type UpdateTalentApplicationProfileMutation = {
  __typename?: 'Mutation';
  updateTalentApplicationProfile: {
    __typename?: 'TalentApplication';
    profile: {
      __typename?: 'TalentApplicationProfile';
      basicInformation: { __typename?: 'TalentApplicationProfileBasicInformation'; condition: any };
    };
  };
};

export type UpdateTalentApplicationProfileEmergenciesMutationVariables = Exact<{
  parameters: TalentApplicationProfileParameters;
}>;

export type UpdateTalentApplicationProfileEmergenciesMutation = {
  __typename?: 'Mutation';
  updateTalentApplicationProfile: { __typename?: 'TalentApplication'; updatedAt: any };
};

export type GetEmergenciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEmergenciesQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    profile: {
      __typename?: 'TalentApplicationProfile';
      emergencyContacts: Array<{
        __typename?: 'TalentApplicationProfileEmergencyContact';
        id: number;
        condition: any;
        firstName: string;
        lastName: string;
        emailAddress: string;
        phone: string;
        relationship: string;
      }>;
    };
  };
};

export type UpdateTalentApplicationProfileReferencesMutationVariables = Exact<{
  parameters: TalentApplicationProfileParameters;
}>;

export type UpdateTalentApplicationProfileReferencesMutation = {
  __typename?: 'Mutation';
  updateTalentApplicationProfile: {
    __typename?: 'TalentApplication';
    profile: {
      __typename?: 'TalentApplicationProfile';
      references: Array<{ __typename?: 'TalentApplicationProfileReference'; condition: any }>;
    };
  };
};

export type GetReferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetReferencesQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    profile: {
      __typename?: 'TalentApplicationProfile';
      references: Array<{
        __typename?: 'TalentApplicationProfileReference';
        id: number;
        condition: any;
        firstName: string;
        lastName: string;
        emailAddress: string;
        phone: string;
      }>;
    };
  };
};

export type TalentApplicationLaunchQueryVariables = Exact<{ [key: string]: never }>;

export type TalentApplicationLaunchQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    launch: {
      __typename?: 'TalentApplicationLaunch';
      state: {
        __typename?: 'TalentApplicationLaunchState';
        hcx: {
          __typename?: 'TalentApplicationLaunchStateSection';
          profile: boolean;
          profession: boolean;
          sponsorship: boolean;
          financialModel: boolean;
          offeringParameters: boolean;
          legal: boolean;
        };
        talent: {
          __typename?: 'TalentApplicationLaunchStateSection';
          profile: boolean;
          profession: boolean;
          sponsorship: boolean;
          financialModel: boolean;
          offeringParameters: boolean;
          legal: boolean;
        };
        sponsor: {
          __typename?: 'TalentApplicationLaunchStateSection';
          profile: boolean;
          profession: boolean;
          sponsorship: boolean;
          financialModel: boolean;
          offeringParameters: boolean;
          legal: boolean;
        };
      };
      approvals: {
        __typename?: 'TalentApplicationLaunchApprovals';
        hcx: {
          __typename?: 'TalentApplicationLaunchApprovalSection';
          approved: boolean;
          approvedBy: string;
          approvedAt: any;
        };
        talent: {
          __typename?: 'TalentApplicationLaunchApprovalSection';
          approved: boolean;
          approvedBy: string;
          approvedAt: any;
        };
        sponsor: {
          __typename?: 'TalentApplicationLaunchApprovalSection';
          approved: boolean;
          approvedBy: string;
          approvedAt: any;
        };
      };
    };
  };
};

export type UpdateTalentApplicationLaunchSectionMutationVariables = Exact<{
  section: TalentApplicationLaunchSection;
  checked: Scalars['Boolean'];
}>;

export type UpdateTalentApplicationLaunchSectionMutation = {
  __typename?: 'Mutation';
  updateTalentApplicationLaunchSection: string;
};

export type TalentIpoLegalClausesQueryVariables = Exact<{ [key: string]: never }>;

export type TalentIpoLegalClausesQuery = {
  __typename?: 'Query';
  talentIPOLegalClauses: Array<{
    __typename?: 'TalentIPOLegalClause';
    id: string;
    title: string;
    text: string;
    checked: boolean;
  }>;
};

export type TalentApplicationLegalClausesQueryVariables = Exact<{ [key: string]: never }>;

export type TalentApplicationLegalClausesQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    legalClauses: Array<{
      __typename?: 'TalentApplicationLegalClause';
      id: string;
      checked: boolean;
    }>;
  };
};

export type UpdateTalentApplicationLegalClausesMutationVariables = Exact<{
  legalClauses: Scalars['Int'];
  checked: Scalars['Boolean'];
}>;

export type UpdateTalentApplicationLegalClausesMutation = {
  __typename?: 'Mutation';
  updateTalentApplicationLegalClause: string;
};

export type ApproveTalentApplicationLaunchMutationVariables = Exact<{ [key: string]: never }>;

export type ApproveTalentApplicationLaunchMutation = {
  __typename?: 'Mutation';
  approveTalentApplicationLaunch: string;
};

export type TalentApplicationOfferingParametersQueryVariables = Exact<{ [key: string]: never }>;

export type TalentApplicationOfferingParametersQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    offeringParameters: {
      __typename?: 'TalentApplicationOfferingParameters';
      startDate: any;
      endDate: any;
      futureIncome: number;
      futureIncomeShared: number;
      minimumGross: number;
      numberOfShares: number;
      minimumSharePrice: number;
    };
  };
};

export type TalentTypeConfigurationQueryVariables = Exact<{
  talentType: TalentType;
}>;

export type TalentTypeConfigurationQuery = {
  __typename?: 'Query';
  talentTypeConfiguration: {
    __typename?: 'TalentTypeConfiguration';
    talentType: TalentType;
    uiSchema: any;
    jsonSchema: any;
  };
};

export type TalentTypesQueryVariables = Exact<{ [key: string]: never }>;

export type TalentTypesQuery = { __typename?: 'Query'; talentTypes: Array<TalentType> };

export type UpdateTalentApplicationProfessionMutationVariables = Exact<{
  parameters: TalentApplicationProfessionParameters;
}>;

export type UpdateTalentApplicationProfessionMutation = {
  __typename?: 'Mutation';
  updateTalentApplicationProfession: string;
};

export type TalentApplicationProfessionDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type TalentApplicationProfessionDetailsQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    profession: {
      __typename?: 'TalentApplicationProfession';
      occupation: {
        __typename?: 'TalentApplicationProfessionOccupation';
        talentType: TalentType;
        details: any;
      };
    };
  };
};

export type TalentApplicationSponsorsListQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  orderType: TalentSponsorOrderType;
  orderDirection: OrderDirection;
  filter: Scalars['String'];
}>;

export type TalentApplicationSponsorsListQuery = {
  __typename?: 'Query';
  talentApplication: {
    __typename?: 'TalentApplication';
    sponsors: {
      __typename?: 'TalentApplicationSponsors';
      data: Array<{
        __typename?: 'TalentSponsor';
        id: string;
        name: string;
        level: SponsorLevel;
        source: TalentSponsorInvitationSource;
        status: TalentSponsorInvitationStatus;
        description: string;
        phoneNumber: string;
        emailAddress: string;
        logoUrlForDarkBackground: string;
        logoUrlForLightBackground: string;
      } | null>;
      pagination?: {
        __typename?: 'Pagination';
        limit: number;
        total: number;
        offset: number;
      } | null;
    };
  };
};

export type SendTalentSponsorInvitationMutationVariables = Exact<{
  sponsorId: Scalars['ID'];
}>;

export type SendTalentSponsorInvitationMutation = {
  __typename?: 'Mutation';
  sendTalentSponsorInvitation: string;
};

export type CancelTalentSponsorInvitationMutationVariables = Exact<{
  sponsorId: Scalars['ID'];
}>;

export type CancelTalentSponsorInvitationMutation = {
  __typename?: 'Mutation';
  cancelTalentSponsorInvitation: string;
};

export type CancelTalentApplicationMutationVariables = Exact<{ [key: string]: never }>;

export type CancelTalentApplicationMutation = {
  __typename?: 'Mutation';
  cancelTalentApplication: string;
};

export type PromotedTalentsListQueryVariables = Exact<{ [key: string]: never }>;

export type PromotedTalentsListQuery = {
  __typename?: 'Query';
  promotedTalents: Array<{
    __typename?: 'PromotedTalent';
    profileImageUrl: string;
    typeDetails:
      | {
          __typename: 'AthleteType';
          arms: string;
          college: string;
          experience: string;
          hands: string;
          height: string;
          homeTown: string;
          jerseyNumber: string;
          league: string;
          role: string;
          team: string;
          weight: string;
        }
      | {
          __typename: 'DoctorType';
          graduated: string;
          specialty: string;
          medicalCollege: string;
          undergrad: string;
        };
  }>;
};

export type LatestBidsReceivedSubscriptionVariables = Exact<{
  auctionId: Scalars['Int'];
}>;

export type LatestBidsReceivedSubscription = {
  __typename?: 'Subscription';
  latestBidRecievedOnAuction: {
    __typename?: 'DutchAuctionBidSubscriptionItem';
    status: DutchAuctionBidSubscriptionBidStatus;
    bidData: {
      __typename?: 'DutchAuctionBid';
      auctionId: number;
      auctionBlockchainId: number;
      blockHash: string;
      bidderAddress: string;
      tokenAmount: number;
      tokenPrice: number;
      orderDate: any;
      orderType: DutchAuctionOrderType;
      status: BidOrderStatus;
      userId?: number | null;
    };
  };
};

export type AuctionAllBidsQueryVariables = Exact<{
  auctionId: Scalars['Int'];
}>;

export type AuctionAllBidsQuery = {
  __typename?: 'Query';
  bids: Array<{
    __typename?: 'DutchAuctionBid';
    auctionId: number;
    auctionBlockchainId: number;
    blockHash: string;
    bidderAddress: string;
    tokenAmount: number;
    tokenPrice: number;
    orderDate: any;
    orderType: DutchAuctionOrderType;
    status: BidOrderStatus;
    userId?: number | null;
  } | null>;
};

export type FinalizeAuctionMutationVariables = Exact<{
  auctionId: Scalars['ID'];
}>;

export type FinalizeAuctionMutation = { __typename?: 'Mutation'; finalizeAuction: string };

export type DutchAuctionBidFieldsFragment = {
  __typename?: 'DutchAuctionBid';
  auctionId: number;
  auctionBlockchainId: number;
  blockHash: string;
  bidderAddress: string;
  tokenAmount: number;
  tokenPrice: number;
  orderDate: any;
  orderType: DutchAuctionOrderType;
  status: BidOrderStatus;
  userId?: number | null;
};

export type AuctionStatsFieldsFragment = {
  __typename?: 'DutchAuctionStats';
  numBidders: number;
  thresholdPrice: number;
  highBid: number;
  lowBid: number;
  recommendedBid: number;
  averageBid: number;
  weightedAverageBidPrice: number;
};

export type ActiveMintsQueryVariables = Exact<{
  input: DutchAuctionSearchInput;
}>;

export type ActiveMintsQuery = {
  __typename?: 'Query';
  dutchAuctions: {
    __typename?: 'DutchAuctionPaginated';
    data: Array<{
      __typename?: 'DutchAuction';
      id: string;
      blockchainId: string;
      floorPrice: number;
      endPrice: number;
      durationDays: number;
      sponsorStatus: SponsorStatus;
      fractions: number;
      status: DutchAuctionStatus;
      startDate: any;
      auctionType: DutchAuctionType;
      minimumTotalProceeds: number;
      athleteContract: {
        __typename?: 'AthleteContract';
        id: string;
        nftTokenId: number;
        revenuePercentage: number;
        athlete: {
          __typename?: 'Athlete';
          id: string;
          dob: any;
          education: string;
          erc20SmartContractAddress: any;
          ethereumAddress: any;
          fullName: string;
          height: number;
          imageUrl: string;
          nftName: string;
          nftSmartContractAddress: any;
          nftSymbol: string;
          sex: string;
          tradingPair?: {
            __typename?: 'TradingPair';
            id: string;
            tokenAddress: any;
            stableCoinAddress: any;
          } | null;
          jobHistory?: Array<{
            __typename?: 'AthleteJob';
            drafted: string;
            league: string;
            number?: number | null;
            position?: string | null;
            rosterStatus: boolean;
            team: string;
          }> | null;
        };
      };
      stats?: {
        __typename?: 'DutchAuctionStats';
        numBidders: number;
        thresholdPrice: number;
        highBid: number;
        lowBid: number;
        recommendedBid: number;
        averageBid: number;
        weightedAverageBidPrice: number;
      } | null;
    } | null>;
    pagination: { __typename?: 'Pagination'; limit: number; offset: number; total: number };
  };
};

export type DutchAuctionStatsByIdQueryVariables = Exact<{
  input: DutchAuctionSearchInput;
}>;

export type DutchAuctionStatsByIdQuery = {
  __typename?: 'Query';
  dutchAuctions: {
    __typename?: 'DutchAuctionPaginated';
    data: Array<{
      __typename?: 'DutchAuction';
      id: string;
      fractions: number;
      athleteContract: { __typename?: 'AthleteContract'; id: string; revenuePercentage: number };
      stats?: {
        __typename?: 'DutchAuctionStats';
        numBidders: number;
        thresholdPrice: number;
        highBid: number;
        lowBid: number;
        recommendedBid: number;
        averageBid: number;
        weightedAverageBidPrice: number;
      } | null;
    } | null>;
    pagination: { __typename?: 'Pagination'; limit: number; offset: number; total: number };
  };
};

export type LatestAuctionStatsSubscriptionVariables = Exact<{
  auctionId: Scalars['Int'];
}>;

export type LatestAuctionStatsSubscription = {
  __typename?: 'Subscription';
  latestAuctionStats: {
    __typename?: 'DutchAuctionStats';
    numBidders: number;
    thresholdPrice: number;
    highBid: number;
    lowBid: number;
    recommendedBid: number;
    averageBid: number;
    weightedAverageBidPrice: number;
  };
};

export type AcceptSponsorUserInvitationMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type AcceptSponsorUserInvitationMutation = {
  __typename?: 'Mutation';
  acceptSponsorUserInvitation: string;
};

export type AuctionUserBidsQueryVariables = Exact<{
  auctionId?: InputMaybe<Scalars['Int']>;
}>;

export type AuctionUserBidsQuery = {
  __typename?: 'Query';
  userBids: Array<{
    __typename?: 'DutchAuctionBid';
    auctionId: number;
    auctionBlockchainId: number;
    blockHash: string;
    bidderAddress: string;
    tokenAmount: number;
    tokenPrice: number;
    orderDate: any;
    orderType: DutchAuctionOrderType;
    status: BidOrderStatus;
    userId?: number | null;
    dutchAuction: {
      __typename?: 'DutchAuction';
      id: string;
      athleteContract: {
        __typename?: 'AthleteContract';
        id: string;
        athlete: {
          __typename?: 'Athlete';
          id: string;
          fullName: string;
          nftName: string;
          nftSymbol: string;
          imageUrl: string;
        };
      };
    };
  } | null>;
};

export type UsersTradingPairsQueryVariables = Exact<{
  input: TradingPairSearchInput;
}>;

export type UsersTradingPairsQuery = {
  __typename?: 'Query';
  userTradingPairs: {
    __typename?: 'TradingPairsPaginated';
    data: Array<{
      __typename?: 'TradingPair';
      id: string;
      tokenAddress: any;
      stats?: {
        __typename?: 'TradingPairStats';
        volume24: number;
        lastPrice: number;
        change24Percent: number;
        change24Amount: number;
        marketCap: number;
      } | null;
      athlete: {
        __typename?: 'Athlete';
        id: string;
        fullName: string;
        nftSymbol: string;
        imageUrl: string;
      };
    } | null>;
    pagination: { __typename?: 'Pagination'; limit: number; total: number; offset: number };
  };
};

export type OrdersByUserIdQueryVariables = Exact<{
  input: OrderSearchInput;
}>;

export type OrdersByUserIdQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'OrdersPaginated';
    data: Array<{
      __typename?: 'Order';
      id: string;
      date: any;
      amountOfTokens: number;
      price: number;
      expiry: any;
      makerAddress: any;
      takerAddress: any;
      takerAmount: number;
      isBuy: boolean;
      tradingPair: {
        __typename?: 'TradingPair';
        athlete: { __typename?: 'Athlete'; fullName: string; nftSymbol: string; imageUrl: string };
      };
    } | null>;
    pagination: { __typename?: 'Pagination'; limit: number; total: number; offset: number };
  };
};

export const CoreAthleteJobFieldsFragmentDoc = gql`
  fragment CoreAthleteJobFields on AthleteJob {
    drafted
    league
    number
    position
    rosterStatus
    team
  }
`;
export const CoreAthleteFieldsFragmentDoc = gql`
  fragment CoreAthleteFields on Athlete {
    dob
    education
    erc20SmartContractAddress
    ethereumAddress
    fullName
    height
    imageUrl
    nftName
    nftSmartContractAddress
    nftSymbol
    sex
    id
  }
`;
export const DutchAuctionBidFieldsFragmentDoc = gql`
  fragment DutchAuctionBidFields on DutchAuctionBid {
    auctionId
    auctionBlockchainId
    blockHash
    bidderAddress
    tokenAmount
    tokenPrice
    orderDate
    orderType
    status
    userId
  }
`;
export const AuctionStatsFieldsFragmentDoc = gql`
  fragment AuctionStatsFields on DutchAuctionStats {
    numBidders
    thresholdPrice
    highBid
    lowBid
    recommendedBid
    averageBid
    weightedAverageBidPrice
  }
`;
export const ConfigurationAuthDocument = gql`
  query ConfigurationAuth {
    configuration {
      auth0 {
        domain
        clientId
        audience
      }
    }
  }
`;

/**
 * __useConfigurationAuthQuery__
 *
 * To run a query within a React component, call `useConfigurationAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigurationAuthQuery(
  baseOptions?: Apollo.QueryHookOptions<ConfigurationAuthQuery, ConfigurationAuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfigurationAuthQuery, ConfigurationAuthQueryVariables>(
    ConfigurationAuthDocument,
    options
  );
}
export function useConfigurationAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConfigurationAuthQuery, ConfigurationAuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfigurationAuthQuery, ConfigurationAuthQueryVariables>(
    ConfigurationAuthDocument,
    options
  );
}
export type ConfigurationAuthQueryHookResult = ReturnType<typeof useConfigurationAuthQuery>;
export type ConfigurationAuthLazyQueryHookResult = ReturnType<typeof useConfigurationAuthLazyQuery>;
export type ConfigurationAuthQueryResult = Apollo.QueryResult<
  ConfigurationAuthQuery,
  ConfigurationAuthQueryVariables
>;
export const AthletesDocument = gql`
  query Athletes($input: AthleteSearchInput!) {
    athletes(input: $input) {
      data {
        id
        dob
        education
        erc20SmartContractAddress
        ethereumAddress
        fullName
        height
        imageUrl
        nftName
        nftSmartContractAddress
        nftSymbol
        sex
        tradingPair {
          id
          tokenAddress
          stableCoinAddress
        }
        currentJob {
          drafted
          league
          number
          position
          rosterStatus
          team
        }
        jobHistory {
          drafted
          league
          number
          position
          rosterStatus
          team
        }
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`;

/**
 * __useAthletesQuery__
 *
 * To run a query within a React component, call `useAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAthletesQuery(
  baseOptions: Apollo.QueryHookOptions<AthletesQuery, AthletesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AthletesQuery, AthletesQueryVariables>(AthletesDocument, options);
}
export function useAthletesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AthletesQuery, AthletesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AthletesQuery, AthletesQueryVariables>(AthletesDocument, options);
}
export type AthletesQueryHookResult = ReturnType<typeof useAthletesQuery>;
export type AthletesLazyQueryHookResult = ReturnType<typeof useAthletesLazyQuery>;
export type AthletesQueryResult = Apollo.QueryResult<AthletesQuery, AthletesQueryVariables>;
export const TradingPairStatsDocument = gql`
  query TradingPairStats($input: TradingPairSearchInput!) {
    tradingPairs(input: $input) {
      id
      stats {
        volume24
        lastPrice
        change24Percent
        change24Amount
        marketCap
      }
    }
  }
`;

/**
 * __useTradingPairStatsQuery__
 *
 * To run a query within a React component, call `useTradingPairStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingPairStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingPairStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTradingPairStatsQuery(
  baseOptions: Apollo.QueryHookOptions<TradingPairStatsQuery, TradingPairStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradingPairStatsQuery, TradingPairStatsQueryVariables>(
    TradingPairStatsDocument,
    options
  );
}
export function useTradingPairStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TradingPairStatsQuery, TradingPairStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TradingPairStatsQuery, TradingPairStatsQueryVariables>(
    TradingPairStatsDocument,
    options
  );
}
export type TradingPairStatsQueryHookResult = ReturnType<typeof useTradingPairStatsQuery>;
export type TradingPairStatsLazyQueryHookResult = ReturnType<typeof useTradingPairStatsLazyQuery>;
export type TradingPairStatsQueryResult = Apollo.QueryResult<
  TradingPairStatsQuery,
  TradingPairStatsQueryVariables
>;
export const ActivityListDocument = gql`
  query ActivityList($activityType: SponsorActivityListType!, $offset: Int!, $limit: Int!) {
    sponsorActivityList(activityType: $activityType, offset: $offset, limit: $limit) {
      data {
        ... on SponsorUserActivity {
          action
          value
          sponsorName
          sponsorUserName
          createdAt
        }
        ... on SponsorApplicationActivity {
          field
          value
          userName
          createdAt
        }
      }
      pagination {
        total
        offset
        limit
      }
    }
  }
`;

/**
 * __useActivityListQuery__
 *
 * To run a query within a React component, call `useActivityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityListQuery({
 *   variables: {
 *      activityType: // value for 'activityType'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useActivityListQuery(
  baseOptions: Apollo.QueryHookOptions<ActivityListQuery, ActivityListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityListQuery, ActivityListQueryVariables>(
    ActivityListDocument,
    options
  );
}
export function useActivityListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActivityListQuery, ActivityListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityListQuery, ActivityListQueryVariables>(
    ActivityListDocument,
    options
  );
}
export type ActivityListQueryHookResult = ReturnType<typeof useActivityListQuery>;
export type ActivityListLazyQueryHookResult = ReturnType<typeof useActivityListLazyQuery>;
export type ActivityListQueryResult = Apollo.QueryResult<
  ActivityListQuery,
  ActivityListQueryVariables
>;
export const TalentActivityListDocument = gql`
  query TalentActivityList($offset: Int!, $limit: Int!) {
    talentApplicationActivityList(offset: $offset, limit: $limit) {
      data {
        ... on TalentApplicationFieldChangeActivity {
          createdAt
          field
          fieldValue
          userName
        }
        ... on TalentApplicationActionActivity {
          action
          actionDetail
          createdAt
          userName
        }
      }
      pagination {
        limit
        total
        offset
      }
    }
  }
`;

/**
 * __useTalentActivityListQuery__
 *
 * To run a query within a React component, call `useTalentActivityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentActivityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentActivityListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTalentActivityListQuery(
  baseOptions: Apollo.QueryHookOptions<TalentActivityListQuery, TalentActivityListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TalentActivityListQuery, TalentActivityListQueryVariables>(
    TalentActivityListDocument,
    options
  );
}
export function useTalentActivityListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentActivityListQuery,
    TalentActivityListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TalentActivityListQuery, TalentActivityListQueryVariables>(
    TalentActivityListDocument,
    options
  );
}
export type TalentActivityListQueryHookResult = ReturnType<typeof useTalentActivityListQuery>;
export type TalentActivityListLazyQueryHookResult = ReturnType<
  typeof useTalentActivityListLazyQuery
>;
export type TalentActivityListQueryResult = Apollo.QueryResult<
  TalentActivityListQuery,
  TalentActivityListQueryVariables
>;
export const LatestTradingPairStatsDocument = gql`
  subscription LatestTradingPairStats($tradingPairId: ID!) {
    latestTradinPairStats(tradingPairId: $tradingPairId) {
      volume24
      lastPrice
      change24Percent
      change24Amount
      marketCap
    }
  }
`;

/**
 * __useLatestTradingPairStatsSubscription__
 *
 * To run a query within a React component, call `useLatestTradingPairStatsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLatestTradingPairStatsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestTradingPairStatsSubscription({
 *   variables: {
 *      tradingPairId: // value for 'tradingPairId'
 *   },
 * });
 */
export function useLatestTradingPairStatsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LatestTradingPairStatsSubscription,
    LatestTradingPairStatsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LatestTradingPairStatsSubscription,
    LatestTradingPairStatsSubscriptionVariables
  >(LatestTradingPairStatsDocument, options);
}
export type LatestTradingPairStatsSubscriptionHookResult = ReturnType<
  typeof useLatestTradingPairStatsSubscription
>;
export type LatestTradingPairStatsSubscriptionResult =
  Apollo.SubscriptionResult<LatestTradingPairStatsSubscription>;
export const FavoriteAthleteIdsDocument = gql`
  query FavoriteAthleteIds {
    favoriteAthletes {
      id
    }
  }
`;

/**
 * __useFavoriteAthleteIdsQuery__
 *
 * To run a query within a React component, call `useFavoriteAthleteIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteAthleteIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteAthleteIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFavoriteAthleteIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<FavoriteAthleteIdsQuery, FavoriteAthleteIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FavoriteAthleteIdsQuery, FavoriteAthleteIdsQueryVariables>(
    FavoriteAthleteIdsDocument,
    options
  );
}
export function useFavoriteAthleteIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FavoriteAthleteIdsQuery,
    FavoriteAthleteIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FavoriteAthleteIdsQuery, FavoriteAthleteIdsQueryVariables>(
    FavoriteAthleteIdsDocument,
    options
  );
}
export type FavoriteAthleteIdsQueryHookResult = ReturnType<typeof useFavoriteAthleteIdsQuery>;
export type FavoriteAthleteIdsLazyQueryHookResult = ReturnType<
  typeof useFavoriteAthleteIdsLazyQuery
>;
export type FavoriteAthleteIdsQueryResult = Apollo.QueryResult<
  FavoriteAthleteIdsQuery,
  FavoriteAthleteIdsQueryVariables
>;
export const FavoriteAthleteDocument = gql`
  mutation FavoriteAthlete($athleteId: ID!) {
    favoriteAthlete(athleteID: $athleteId)
  }
`;
export type FavoriteAthleteMutationFn = Apollo.MutationFunction<
  FavoriteAthleteMutation,
  FavoriteAthleteMutationVariables
>;

/**
 * __useFavoriteAthleteMutation__
 *
 * To run a mutation, you first call `useFavoriteAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteAthleteMutation, { data, loading, error }] = useFavoriteAthleteMutation({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useFavoriteAthleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FavoriteAthleteMutation,
    FavoriteAthleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FavoriteAthleteMutation, FavoriteAthleteMutationVariables>(
    FavoriteAthleteDocument,
    options
  );
}
export type FavoriteAthleteMutationHookResult = ReturnType<typeof useFavoriteAthleteMutation>;
export type FavoriteAthleteMutationResult = Apollo.MutationResult<FavoriteAthleteMutation>;
export type FavoriteAthleteMutationOptions = Apollo.BaseMutationOptions<
  FavoriteAthleteMutation,
  FavoriteAthleteMutationVariables
>;
export const UnfavoriteAthleteDocument = gql`
  mutation UnfavoriteAthlete($athleteId: ID!) {
    unfavoriteAthlete(athleteID: $athleteId)
  }
`;
export type UnfavoriteAthleteMutationFn = Apollo.MutationFunction<
  UnfavoriteAthleteMutation,
  UnfavoriteAthleteMutationVariables
>;

/**
 * __useUnfavoriteAthleteMutation__
 *
 * To run a mutation, you first call `useUnfavoriteAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfavoriteAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfavoriteAthleteMutation, { data, loading, error }] = useUnfavoriteAthleteMutation({
 *   variables: {
 *      athleteId: // value for 'athleteId'
 *   },
 * });
 */
export function useUnfavoriteAthleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfavoriteAthleteMutation,
    UnfavoriteAthleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnfavoriteAthleteMutation, UnfavoriteAthleteMutationVariables>(
    UnfavoriteAthleteDocument,
    options
  );
}
export type UnfavoriteAthleteMutationHookResult = ReturnType<typeof useUnfavoriteAthleteMutation>;
export type UnfavoriteAthleteMutationResult = Apollo.MutationResult<UnfavoriteAthleteMutation>;
export type UnfavoriteAthleteMutationOptions = Apollo.BaseMutationOptions<
  UnfavoriteAthleteMutation,
  UnfavoriteAthleteMutationVariables
>;
export const TradingPairCandlesticksDocument = gql`
  query TradingPairCandlesticks(
    $tradingPairId: ID!
    $fromTimestamp: Date!
    $toTimestamp: Date!
    $resolution: Int!
  ) {
    tradingPairCandlesticks(
      tradingPairId: $tradingPairId
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      resolution: $resolution
    ) {
      openTimestamp
      closeTimestamp
      open
      close
      high
      low
      volume
    }
  }
`;

/**
 * __useTradingPairCandlesticksQuery__
 *
 * To run a query within a React component, call `useTradingPairCandlesticksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradingPairCandlesticksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradingPairCandlesticksQuery({
 *   variables: {
 *      tradingPairId: // value for 'tradingPairId'
 *      fromTimestamp: // value for 'fromTimestamp'
 *      toTimestamp: // value for 'toTimestamp'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useTradingPairCandlesticksQuery(
  baseOptions: Apollo.QueryHookOptions<
    TradingPairCandlesticksQuery,
    TradingPairCandlesticksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradingPairCandlesticksQuery, TradingPairCandlesticksQueryVariables>(
    TradingPairCandlesticksDocument,
    options
  );
}
export function useTradingPairCandlesticksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradingPairCandlesticksQuery,
    TradingPairCandlesticksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TradingPairCandlesticksQuery, TradingPairCandlesticksQueryVariables>(
    TradingPairCandlesticksDocument,
    options
  );
}
export type TradingPairCandlesticksQueryHookResult = ReturnType<
  typeof useTradingPairCandlesticksQuery
>;
export type TradingPairCandlesticksLazyQueryHookResult = ReturnType<
  typeof useTradingPairCandlesticksLazyQuery
>;
export type TradingPairCandlesticksQueryResult = Apollo.QueryResult<
  TradingPairCandlesticksQuery,
  TradingPairCandlesticksQueryVariables
>;
export const LatestTradingPairCandleDocument = gql`
  subscription LatestTradingPairCandle($tradingPairId: ID!, $resolution: Int!) {
    latestTradingPairCandle(tradingPairId: $tradingPairId, resolution: $resolution) {
      openTimestamp
      closeTimestamp
      open
      close
      high
      low
      volume
    }
  }
`;

/**
 * __useLatestTradingPairCandleSubscription__
 *
 * To run a query within a React component, call `useLatestTradingPairCandleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLatestTradingPairCandleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestTradingPairCandleSubscription({
 *   variables: {
 *      tradingPairId: // value for 'tradingPairId'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useLatestTradingPairCandleSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LatestTradingPairCandleSubscription,
    LatestTradingPairCandleSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LatestTradingPairCandleSubscription,
    LatestTradingPairCandleSubscriptionVariables
  >(LatestTradingPairCandleDocument, options);
}
export type LatestTradingPairCandleSubscriptionHookResult = ReturnType<
  typeof useLatestTradingPairCandleSubscription
>;
export type LatestTradingPairCandleSubscriptionResult =
  Apollo.SubscriptionResult<LatestTradingPairCandleSubscription>;
export const AllAthletesDocument = gql`
  query AllAthletes {
    athletes(input: { hasTradingPair: true }) {
      data {
        id
        tradingPair {
          id
          name
          tokenAddress
          symbol
        }
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`;

/**
 * __useAllAthletesQuery__
 *
 * To run a query within a React component, call `useAllAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAthletesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAthletesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllAthletesQuery, AllAthletesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllAthletesQuery, AllAthletesQueryVariables>(AllAthletesDocument, options);
}
export function useAllAthletesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllAthletesQuery, AllAthletesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllAthletesQuery, AllAthletesQueryVariables>(
    AllAthletesDocument,
    options
  );
}
export type AllAthletesQueryHookResult = ReturnType<typeof useAllAthletesQuery>;
export type AllAthletesLazyQueryHookResult = ReturnType<typeof useAllAthletesLazyQuery>;
export type AllAthletesQueryResult = Apollo.QueryResult<
  AllAthletesQuery,
  AllAthletesQueryVariables
>;
export const NewOrderDocument = gql`
  mutation NewOrder($order: LimitOrder) {
    newOrder(order: $order)
  }
`;
export type NewOrderMutationFn = Apollo.MutationFunction<
  NewOrderMutation,
  NewOrderMutationVariables
>;

/**
 * __useNewOrderMutation__
 *
 * To run a mutation, you first call `useNewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderMutation, { data, loading, error }] = useNewOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNewOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<NewOrderMutation, NewOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NewOrderMutation, NewOrderMutationVariables>(NewOrderDocument, options);
}
export type NewOrderMutationHookResult = ReturnType<typeof useNewOrderMutation>;
export type NewOrderMutationResult = Apollo.MutationResult<NewOrderMutation>;
export type NewOrderMutationOptions = Apollo.BaseMutationOptions<
  NewOrderMutation,
  NewOrderMutationVariables
>;
export const AthleteContractDocument = gql`
  query AthleteContract($id: ID!) {
    athleteContract(id: $id) {
      id
      dutchAuction {
        id
        blockchainId
        floorPrice
        discountRate
        discountFrequency
        status
        startDate
        durationDays
        stats {
          recommendedBid
          numBidders
          highBid
          lowBid
        }
      }
    }
  }
`;

/**
 * __useAthleteContractQuery__
 *
 * To run a query within a React component, call `useAthleteContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteContractQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteContractQuery(
  baseOptions: Apollo.QueryHookOptions<AthleteContractQuery, AthleteContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AthleteContractQuery, AthleteContractQueryVariables>(
    AthleteContractDocument,
    options
  );
}
export function useAthleteContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AthleteContractQuery, AthleteContractQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AthleteContractQuery, AthleteContractQueryVariables>(
    AthleteContractDocument,
    options
  );
}
export type AthleteContractQueryHookResult = ReturnType<typeof useAthleteContractQuery>;
export type AthleteContractLazyQueryHookResult = ReturnType<typeof useAthleteContractLazyQuery>;
export type AthleteContractQueryResult = Apollo.QueryResult<
  AthleteContractQuery,
  AthleteContractQueryVariables
>;
export const SponsorApplicationStatusDocument = gql`
  query SponsorApplicationStatus {
    sponsorApplication {
      status
      createdAt
      reviewedAt
      approvedAt
    }
  }
`;

/**
 * __useSponsorApplicationStatusQuery__
 *
 * To run a query within a React component, call `useSponsorApplicationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorApplicationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorApplicationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useSponsorApplicationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SponsorApplicationStatusQuery,
    SponsorApplicationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SponsorApplicationStatusQuery, SponsorApplicationStatusQueryVariables>(
    SponsorApplicationStatusDocument,
    options
  );
}
export function useSponsorApplicationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SponsorApplicationStatusQuery,
    SponsorApplicationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SponsorApplicationStatusQuery, SponsorApplicationStatusQueryVariables>(
    SponsorApplicationStatusDocument,
    options
  );
}
export type SponsorApplicationStatusQueryHookResult = ReturnType<
  typeof useSponsorApplicationStatusQuery
>;
export type SponsorApplicationStatusLazyQueryHookResult = ReturnType<
  typeof useSponsorApplicationStatusLazyQuery
>;
export type SponsorApplicationStatusQueryResult = Apollo.QueryResult<
  SponsorApplicationStatusQuery,
  SponsorApplicationStatusQueryVariables
>;
export const SponsorApplicationDataDocument = gql`
  query SponsorApplicationData {
    sponsorApplication {
      status
      sponsorInformation {
        corporateName
        corporateType
        streetAddress
        apartment
        city
        state
        zip
        country
        description
        phoneNumber
        websiteUrl
        agreementUrl
        ein
        logoUrlForDarkBackground
        logoUrlForLightBackground
      }
      sponsorOwnerInformation {
        firstName
        lastName
        streetAddress
        apartment
        city
        state
        zip
        phoneNumber
        emailAddress
      }
      createdAt
      reviewedAt
      approvedAt
    }
  }
`;

/**
 * __useSponsorApplicationDataQuery__
 *
 * To run a query within a React component, call `useSponsorApplicationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorApplicationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorApplicationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSponsorApplicationDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SponsorApplicationDataQuery,
    SponsorApplicationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SponsorApplicationDataQuery, SponsorApplicationDataQueryVariables>(
    SponsorApplicationDataDocument,
    options
  );
}
export function useSponsorApplicationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SponsorApplicationDataQuery,
    SponsorApplicationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SponsorApplicationDataQuery, SponsorApplicationDataQueryVariables>(
    SponsorApplicationDataDocument,
    options
  );
}
export type SponsorApplicationDataQueryHookResult = ReturnType<
  typeof useSponsorApplicationDataQuery
>;
export type SponsorApplicationDataLazyQueryHookResult = ReturnType<
  typeof useSponsorApplicationDataLazyQuery
>;
export type SponsorApplicationDataQueryResult = Apollo.QueryResult<
  SponsorApplicationDataQuery,
  SponsorApplicationDataQueryVariables
>;
export const InitSponsorApplicationDocument = gql`
  mutation InitSponsorApplication {
    initSponsorApplication {
      status
      sponsorInformation {
        corporateName
        corporateType
        streetAddress
        apartment
        city
        state
        zip
        country
        description
        phoneNumber
        websiteUrl
        agreementUrl
        ein
        logoUrlForDarkBackground
        logoUrlForLightBackground
      }
      sponsorOwnerInformation {
        firstName
        lastName
        streetAddress
        apartment
        city
        state
        zip
        phoneNumber
        emailAddress
      }
      createdAt
      reviewedAt
      approvedAt
    }
  }
`;
export type InitSponsorApplicationMutationFn = Apollo.MutationFunction<
  InitSponsorApplicationMutation,
  InitSponsorApplicationMutationVariables
>;

/**
 * __useInitSponsorApplicationMutation__
 *
 * To run a mutation, you first call `useInitSponsorApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitSponsorApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initSponsorApplicationMutation, { data, loading, error }] = useInitSponsorApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitSponsorApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitSponsorApplicationMutation,
    InitSponsorApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitSponsorApplicationMutation,
    InitSponsorApplicationMutationVariables
  >(InitSponsorApplicationDocument, options);
}
export type InitSponsorApplicationMutationHookResult = ReturnType<
  typeof useInitSponsorApplicationMutation
>;
export type InitSponsorApplicationMutationResult =
  Apollo.MutationResult<InitSponsorApplicationMutation>;
export type InitSponsorApplicationMutationOptions = Apollo.BaseMutationOptions<
  InitSponsorApplicationMutation,
  InitSponsorApplicationMutationVariables
>;
export const UpdateSponsorApplicationDocument = gql`
  mutation UpdateSponsorApplication($parameters: SponsorApplicationParameters!) {
    updateSponsorApplication(parameters: $parameters)
  }
`;
export type UpdateSponsorApplicationMutationFn = Apollo.MutationFunction<
  UpdateSponsorApplicationMutation,
  UpdateSponsorApplicationMutationVariables
>;

/**
 * __useUpdateSponsorApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateSponsorApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSponsorApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSponsorApplicationMutation, { data, loading, error }] = useUpdateSponsorApplicationMutation({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useUpdateSponsorApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSponsorApplicationMutation,
    UpdateSponsorApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSponsorApplicationMutation,
    UpdateSponsorApplicationMutationVariables
  >(UpdateSponsorApplicationDocument, options);
}
export type UpdateSponsorApplicationMutationHookResult = ReturnType<
  typeof useUpdateSponsorApplicationMutation
>;
export type UpdateSponsorApplicationMutationResult =
  Apollo.MutationResult<UpdateSponsorApplicationMutation>;
export type UpdateSponsorApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSponsorApplicationMutation,
  UpdateSponsorApplicationMutationVariables
>;
export const CancelSponsorApplicationDocument = gql`
  mutation CancelSponsorApplication {
    cancelSponsorApplication
  }
`;
export type CancelSponsorApplicationMutationFn = Apollo.MutationFunction<
  CancelSponsorApplicationMutation,
  CancelSponsorApplicationMutationVariables
>;

/**
 * __useCancelSponsorApplicationMutation__
 *
 * To run a mutation, you first call `useCancelSponsorApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSponsorApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSponsorApplicationMutation, { data, loading, error }] = useCancelSponsorApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSponsorApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSponsorApplicationMutation,
    CancelSponsorApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSponsorApplicationMutation,
    CancelSponsorApplicationMutationVariables
  >(CancelSponsorApplicationDocument, options);
}
export type CancelSponsorApplicationMutationHookResult = ReturnType<
  typeof useCancelSponsorApplicationMutation
>;
export type CancelSponsorApplicationMutationResult =
  Apollo.MutationResult<CancelSponsorApplicationMutation>;
export type CancelSponsorApplicationMutationOptions = Apollo.BaseMutationOptions<
  CancelSponsorApplicationMutation,
  CancelSponsorApplicationMutationVariables
>;
export const SubmitSponsorApplicationDocument = gql`
  mutation SubmitSponsorApplication {
    submitSponsorApplication
  }
`;
export type SubmitSponsorApplicationMutationFn = Apollo.MutationFunction<
  SubmitSponsorApplicationMutation,
  SubmitSponsorApplicationMutationVariables
>;

/**
 * __useSubmitSponsorApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitSponsorApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSponsorApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSponsorApplicationMutation, { data, loading, error }] = useSubmitSponsorApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitSponsorApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitSponsorApplicationMutation,
    SubmitSponsorApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitSponsorApplicationMutation,
    SubmitSponsorApplicationMutationVariables
  >(SubmitSponsorApplicationDocument, options);
}
export type SubmitSponsorApplicationMutationHookResult = ReturnType<
  typeof useSubmitSponsorApplicationMutation
>;
export type SubmitSponsorApplicationMutationResult =
  Apollo.MutationResult<SubmitSponsorApplicationMutation>;
export type SubmitSponsorApplicationMutationOptions = Apollo.BaseMutationOptions<
  SubmitSponsorApplicationMutation,
  SubmitSponsorApplicationMutationVariables
>;
export const LogoDarkDocument = gql`
  query LogoDark {
    sponsorApplication {
      sponsorInformation {
        logoUrlForDarkBackground
      }
    }
  }
`;

/**
 * __useLogoDarkQuery__
 *
 * To run a query within a React component, call `useLogoDarkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoDarkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoDarkQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoDarkQuery(
  baseOptions?: Apollo.QueryHookOptions<LogoDarkQuery, LogoDarkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogoDarkQuery, LogoDarkQueryVariables>(LogoDarkDocument, options);
}
export function useLogoDarkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogoDarkQuery, LogoDarkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogoDarkQuery, LogoDarkQueryVariables>(LogoDarkDocument, options);
}
export type LogoDarkQueryHookResult = ReturnType<typeof useLogoDarkQuery>;
export type LogoDarkLazyQueryHookResult = ReturnType<typeof useLogoDarkLazyQuery>;
export type LogoDarkQueryResult = Apollo.QueryResult<LogoDarkQuery, LogoDarkQueryVariables>;
export const LogoLightDocument = gql`
  query LogoLight {
    sponsorApplication {
      sponsorInformation {
        logoUrlForLightBackground
      }
    }
  }
`;

/**
 * __useLogoLightQuery__
 *
 * To run a query within a React component, call `useLogoLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useLogoLightQuery(
  baseOptions?: Apollo.QueryHookOptions<LogoLightQuery, LogoLightQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LogoLightQuery, LogoLightQueryVariables>(LogoLightDocument, options);
}
export function useLogoLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogoLightQuery, LogoLightQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LogoLightQuery, LogoLightQueryVariables>(LogoLightDocument, options);
}
export type LogoLightQueryHookResult = ReturnType<typeof useLogoLightQuery>;
export type LogoLightLazyQueryHookResult = ReturnType<typeof useLogoLightLazyQuery>;
export type LogoLightQueryResult = Apollo.QueryResult<LogoLightQuery, LogoLightQueryVariables>;
export const SponsorUserListDocument = gql`
  query SponsorUserList($offset: Int!, $limit: Int!, $order: String!) {
    sponsorUserList(offset: $offset, limit: $limit, order: $order) {
      data {
        name
        email
        phone
        userId
        role
        status
      }
      pagination {
        limit
        total
        offset
      }
    }
  }
`;

/**
 * __useSponsorUserListQuery__
 *
 * To run a query within a React component, call `useSponsorUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorUserListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSponsorUserListQuery(
  baseOptions: Apollo.QueryHookOptions<SponsorUserListQuery, SponsorUserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SponsorUserListQuery, SponsorUserListQueryVariables>(
    SponsorUserListDocument,
    options
  );
}
export function useSponsorUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SponsorUserListQuery, SponsorUserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SponsorUserListQuery, SponsorUserListQueryVariables>(
    SponsorUserListDocument,
    options
  );
}
export type SponsorUserListQueryHookResult = ReturnType<typeof useSponsorUserListQuery>;
export type SponsorUserListLazyQueryHookResult = ReturnType<typeof useSponsorUserListLazyQuery>;
export type SponsorUserListQueryResult = Apollo.QueryResult<
  SponsorUserListQuery,
  SponsorUserListQueryVariables
>;
export const SponsorInformationDocument = gql`
  query SponsorInformation {
    sponsorInformation {
      role
      level
    }
  }
`;

/**
 * __useSponsorInformationQuery__
 *
 * To run a query within a React component, call `useSponsorInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useSponsorInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<SponsorInformationQuery, SponsorInformationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SponsorInformationQuery, SponsorInformationQueryVariables>(
    SponsorInformationDocument,
    options
  );
}
export function useSponsorInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SponsorInformationQuery,
    SponsorInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SponsorInformationQuery, SponsorInformationQueryVariables>(
    SponsorInformationDocument,
    options
  );
}
export type SponsorInformationQueryHookResult = ReturnType<typeof useSponsorInformationQuery>;
export type SponsorInformationLazyQueryHookResult = ReturnType<
  typeof useSponsorInformationLazyQuery
>;
export type SponsorInformationQueryResult = Apollo.QueryResult<
  SponsorInformationQuery,
  SponsorInformationQueryVariables
>;
export const UpdateSponsorInformationDocument = gql`
  mutation UpdateSponsorInformation($info: SponsorInformationParameters!) {
    updateSponsorInformation(info: $info)
  }
`;
export type UpdateSponsorInformationMutationFn = Apollo.MutationFunction<
  UpdateSponsorInformationMutation,
  UpdateSponsorInformationMutationVariables
>;

/**
 * __useUpdateSponsorInformationMutation__
 *
 * To run a mutation, you first call `useUpdateSponsorInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSponsorInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSponsorInformationMutation, { data, loading, error }] = useUpdateSponsorInformationMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useUpdateSponsorInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSponsorInformationMutation,
    UpdateSponsorInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSponsorInformationMutation,
    UpdateSponsorInformationMutationVariables
  >(UpdateSponsorInformationDocument, options);
}
export type UpdateSponsorInformationMutationHookResult = ReturnType<
  typeof useUpdateSponsorInformationMutation
>;
export type UpdateSponsorInformationMutationResult =
  Apollo.MutationResult<UpdateSponsorInformationMutation>;
export type UpdateSponsorInformationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSponsorInformationMutation,
  UpdateSponsorInformationMutationVariables
>;
export const SendSponsorUserInvitationDocument = gql`
  mutation SendSponsorUserInvitation(
    $emailAddress: String!
    $message: String!
    $role: SponsorUserRole!
  ) {
    sendSponsorUserInvitation(emailAddress: $emailAddress, message: $message, role: $role)
  }
`;
export type SendSponsorUserInvitationMutationFn = Apollo.MutationFunction<
  SendSponsorUserInvitationMutation,
  SendSponsorUserInvitationMutationVariables
>;

/**
 * __useSendSponsorUserInvitationMutation__
 *
 * To run a mutation, you first call `useSendSponsorUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSponsorUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSponsorUserInvitationMutation, { data, loading, error }] = useSendSponsorUserInvitationMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      message: // value for 'message'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useSendSponsorUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSponsorUserInvitationMutation,
    SendSponsorUserInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendSponsorUserInvitationMutation,
    SendSponsorUserInvitationMutationVariables
  >(SendSponsorUserInvitationDocument, options);
}
export type SendSponsorUserInvitationMutationHookResult = ReturnType<
  typeof useSendSponsorUserInvitationMutation
>;
export type SendSponsorUserInvitationMutationResult =
  Apollo.MutationResult<SendSponsorUserInvitationMutation>;
export type SendSponsorUserInvitationMutationOptions = Apollo.BaseMutationOptions<
  SendSponsorUserInvitationMutation,
  SendSponsorUserInvitationMutationVariables
>;
export const ResendSponsorUserInvitationDocument = gql`
  mutation ResendSponsorUserInvitation($userId: Int!) {
    resendSponsorUserInvitation(userId: $userId)
  }
`;
export type ResendSponsorUserInvitationMutationFn = Apollo.MutationFunction<
  ResendSponsorUserInvitationMutation,
  ResendSponsorUserInvitationMutationVariables
>;

/**
 * __useResendSponsorUserInvitationMutation__
 *
 * To run a mutation, you first call `useResendSponsorUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendSponsorUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendSponsorUserInvitationMutation, { data, loading, error }] = useResendSponsorUserInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendSponsorUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendSponsorUserInvitationMutation,
    ResendSponsorUserInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendSponsorUserInvitationMutation,
    ResendSponsorUserInvitationMutationVariables
  >(ResendSponsorUserInvitationDocument, options);
}
export type ResendSponsorUserInvitationMutationHookResult = ReturnType<
  typeof useResendSponsorUserInvitationMutation
>;
export type ResendSponsorUserInvitationMutationResult =
  Apollo.MutationResult<ResendSponsorUserInvitationMutation>;
export type ResendSponsorUserInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendSponsorUserInvitationMutation,
  ResendSponsorUserInvitationMutationVariables
>;
export const DeclineSponsorUserInvitationDocument = gql`
  mutation DeclineSponsorUserInvitation($token: String!) {
    declineSponsorUserInvitation(token: $token)
  }
`;
export type DeclineSponsorUserInvitationMutationFn = Apollo.MutationFunction<
  DeclineSponsorUserInvitationMutation,
  DeclineSponsorUserInvitationMutationVariables
>;

/**
 * __useDeclineSponsorUserInvitationMutation__
 *
 * To run a mutation, you first call `useDeclineSponsorUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineSponsorUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineSponsorUserInvitationMutation, { data, loading, error }] = useDeclineSponsorUserInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDeclineSponsorUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineSponsorUserInvitationMutation,
    DeclineSponsorUserInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineSponsorUserInvitationMutation,
    DeclineSponsorUserInvitationMutationVariables
  >(DeclineSponsorUserInvitationDocument, options);
}
export type DeclineSponsorUserInvitationMutationHookResult = ReturnType<
  typeof useDeclineSponsorUserInvitationMutation
>;
export type DeclineSponsorUserInvitationMutationResult =
  Apollo.MutationResult<DeclineSponsorUserInvitationMutation>;
export type DeclineSponsorUserInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeclineSponsorUserInvitationMutation,
  DeclineSponsorUserInvitationMutationVariables
>;
export const CancelSponsorUserInvitationDocument = gql`
  mutation CancelSponsorUserInvitation($userId: Int!) {
    cancelSponsorUserInvitation(userId: $userId)
  }
`;
export type CancelSponsorUserInvitationMutationFn = Apollo.MutationFunction<
  CancelSponsorUserInvitationMutation,
  CancelSponsorUserInvitationMutationVariables
>;

/**
 * __useCancelSponsorUserInvitationMutation__
 *
 * To run a mutation, you first call `useCancelSponsorUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSponsorUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSponsorUserInvitationMutation, { data, loading, error }] = useCancelSponsorUserInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCancelSponsorUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSponsorUserInvitationMutation,
    CancelSponsorUserInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSponsorUserInvitationMutation,
    CancelSponsorUserInvitationMutationVariables
  >(CancelSponsorUserInvitationDocument, options);
}
export type CancelSponsorUserInvitationMutationHookResult = ReturnType<
  typeof useCancelSponsorUserInvitationMutation
>;
export type CancelSponsorUserInvitationMutationResult =
  Apollo.MutationResult<CancelSponsorUserInvitationMutation>;
export type CancelSponsorUserInvitationMutationOptions = Apollo.BaseMutationOptions<
  CancelSponsorUserInvitationMutation,
  CancelSponsorUserInvitationMutationVariables
>;
export const ChangeSponsorUserRoleDocument = gql`
  mutation ChangeSponsorUserRole($userId: Int!, $role: SponsorUserRole!) {
    changeSponsorUserRole(userId: $userId, role: $role)
  }
`;
export type ChangeSponsorUserRoleMutationFn = Apollo.MutationFunction<
  ChangeSponsorUserRoleMutation,
  ChangeSponsorUserRoleMutationVariables
>;

/**
 * __useChangeSponsorUserRoleMutation__
 *
 * To run a mutation, you first call `useChangeSponsorUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSponsorUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSponsorUserRoleMutation, { data, loading, error }] = useChangeSponsorUserRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useChangeSponsorUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeSponsorUserRoleMutation,
    ChangeSponsorUserRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeSponsorUserRoleMutation, ChangeSponsorUserRoleMutationVariables>(
    ChangeSponsorUserRoleDocument,
    options
  );
}
export type ChangeSponsorUserRoleMutationHookResult = ReturnType<
  typeof useChangeSponsorUserRoleMutation
>;
export type ChangeSponsorUserRoleMutationResult =
  Apollo.MutationResult<ChangeSponsorUserRoleMutation>;
export type ChangeSponsorUserRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeSponsorUserRoleMutation,
  ChangeSponsorUserRoleMutationVariables
>;
export const ChangeSponsorUserStatusDocument = gql`
  mutation ChangeSponsorUserStatus($userId: Int!, $status: SponsorUserStatus!) {
    changeSponsorUserStatus(userId: $userId, status: $status)
  }
`;
export type ChangeSponsorUserStatusMutationFn = Apollo.MutationFunction<
  ChangeSponsorUserStatusMutation,
  ChangeSponsorUserStatusMutationVariables
>;

/**
 * __useChangeSponsorUserStatusMutation__
 *
 * To run a mutation, you first call `useChangeSponsorUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSponsorUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSponsorUserStatusMutation, { data, loading, error }] = useChangeSponsorUserStatusMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeSponsorUserStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeSponsorUserStatusMutation,
    ChangeSponsorUserStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeSponsorUserStatusMutation,
    ChangeSponsorUserStatusMutationVariables
  >(ChangeSponsorUserStatusDocument, options);
}
export type ChangeSponsorUserStatusMutationHookResult = ReturnType<
  typeof useChangeSponsorUserStatusMutation
>;
export type ChangeSponsorUserStatusMutationResult =
  Apollo.MutationResult<ChangeSponsorUserStatusMutation>;
export type ChangeSponsorUserStatusMutationOptions = Apollo.BaseMutationOptions<
  ChangeSponsorUserStatusMutation,
  ChangeSponsorUserStatusMutationVariables
>;
export const SponsorManagementInfoDocument = gql`
  query SponsorManagementInfo {
    sponsorApplication {
      sponsorInformation {
        corporateName
        corporateType
        country
        ein
        logoUrlForDarkBackground
        logoUrlForLightBackground
        description
      }
      sponsorOwnerInformation {
        firstName
        lastName
        streetAddress
        apartment
        city
        state
        zip
        phoneNumber
        emailAddress
      }
    }
  }
`;

/**
 * __useSponsorManagementInfoQuery__
 *
 * To run a query within a React component, call `useSponsorManagementInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorManagementInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorManagementInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useSponsorManagementInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SponsorManagementInfoQuery,
    SponsorManagementInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SponsorManagementInfoQuery, SponsorManagementInfoQueryVariables>(
    SponsorManagementInfoDocument,
    options
  );
}
export function useSponsorManagementInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SponsorManagementInfoQuery,
    SponsorManagementInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SponsorManagementInfoQuery, SponsorManagementInfoQueryVariables>(
    SponsorManagementInfoDocument,
    options
  );
}
export type SponsorManagementInfoQueryHookResult = ReturnType<typeof useSponsorManagementInfoQuery>;
export type SponsorManagementInfoLazyQueryHookResult = ReturnType<
  typeof useSponsorManagementInfoLazyQuery
>;
export type SponsorManagementInfoQueryResult = Apollo.QueryResult<
  SponsorManagementInfoQuery,
  SponsorManagementInfoQueryVariables
>;
export const ProfilePhotoDocument = gql`
  query ProfilePhoto {
    talentApplication {
      profile {
        basicInformation {
          profilePictureUrl
        }
      }
    }
  }
`;

/**
 * __useProfilePhotoQuery__
 *
 * To run a query within a React component, call `useProfilePhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePhotoQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePhotoQuery(
  baseOptions?: Apollo.QueryHookOptions<ProfilePhotoQuery, ProfilePhotoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfilePhotoQuery, ProfilePhotoQueryVariables>(
    ProfilePhotoDocument,
    options
  );
}
export function useProfilePhotoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProfilePhotoQuery, ProfilePhotoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfilePhotoQuery, ProfilePhotoQueryVariables>(
    ProfilePhotoDocument,
    options
  );
}
export type ProfilePhotoQueryHookResult = ReturnType<typeof useProfilePhotoQuery>;
export type ProfilePhotoLazyQueryHookResult = ReturnType<typeof useProfilePhotoLazyQuery>;
export type ProfilePhotoQueryResult = Apollo.QueryResult<
  ProfilePhotoQuery,
  ProfilePhotoQueryVariables
>;
export const DeleteTalentApplicationProfileResumeDocument = gql`
  mutation DeleteTalentApplicationProfileResume {
    deleteTalentApplicationProfileResume
  }
`;
export type DeleteTalentApplicationProfileResumeMutationFn = Apollo.MutationFunction<
  DeleteTalentApplicationProfileResumeMutation,
  DeleteTalentApplicationProfileResumeMutationVariables
>;

/**
 * __useDeleteTalentApplicationProfileResumeMutation__
 *
 * To run a mutation, you first call `useDeleteTalentApplicationProfileResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTalentApplicationProfileResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTalentApplicationProfileResumeMutation, { data, loading, error }] = useDeleteTalentApplicationProfileResumeMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteTalentApplicationProfileResumeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTalentApplicationProfileResumeMutation,
    DeleteTalentApplicationProfileResumeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTalentApplicationProfileResumeMutation,
    DeleteTalentApplicationProfileResumeMutationVariables
  >(DeleteTalentApplicationProfileResumeDocument, options);
}
export type DeleteTalentApplicationProfileResumeMutationHookResult = ReturnType<
  typeof useDeleteTalentApplicationProfileResumeMutation
>;
export type DeleteTalentApplicationProfileResumeMutationResult =
  Apollo.MutationResult<DeleteTalentApplicationProfileResumeMutation>;
export type DeleteTalentApplicationProfileResumeMutationOptions = Apollo.BaseMutationOptions<
  DeleteTalentApplicationProfileResumeMutation,
  DeleteTalentApplicationProfileResumeMutationVariables
>;
export const PassportPhotoDocument = gql`
  query passportPhoto {
    talentApplication {
      profile {
        basicInformation {
          passportPhotoUrl
        }
      }
    }
  }
`;

/**
 * __usePassportPhotoQuery__
 *
 * To run a query within a React component, call `usePassportPhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePassportPhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePassportPhotoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePassportPhotoQuery(
  baseOptions?: Apollo.QueryHookOptions<PassportPhotoQuery, PassportPhotoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PassportPhotoQuery, PassportPhotoQueryVariables>(
    PassportPhotoDocument,
    options
  );
}
export function usePassportPhotoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PassportPhotoQuery, PassportPhotoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PassportPhotoQuery, PassportPhotoQueryVariables>(
    PassportPhotoDocument,
    options
  );
}
export type PassportPhotoQueryHookResult = ReturnType<typeof usePassportPhotoQuery>;
export type PassportPhotoLazyQueryHookResult = ReturnType<typeof usePassportPhotoLazyQuery>;
export type PassportPhotoQueryResult = Apollo.QueryResult<
  PassportPhotoQuery,
  PassportPhotoQueryVariables
>;
export const TalentApplicationProfileDocument = gql`
  query TalentApplicationProfile {
    talentApplication {
      profile {
        basicInformation {
          firstName
          middleName
          lastName
          streetAddress
          apartment
          city
          state
          zip
          country
          emailAddress
          dateOfBirth
          sex
          nationality
          maritalStatus
          ssn
          placeOfBirthCity
          placeOfBirthState
          placeOfBirthCountry
          passportPhotoUrl
          profilePictureUrl
          resumeUrl
          resumeFileName
          linkedinProfileUrl
          description
          backgroundCheckAuthorization
          condition
        }
        emergencyContacts {
          id
          condition
          firstName
          lastName
          emailAddress
          phone
          relationship
        }
        references {
          id
          condition
          firstName
          lastName
          emailAddress
          phone
        }
        socialMedias {
          enabled
          medialType
          shareWithFriends
          shareWithFollowers
          delay
          condition
        }
      }
    }
  }
`;

/**
 * __useTalentApplicationProfileQuery__
 *
 * To run a query within a React component, call `useTalentApplicationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentApplicationProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentApplicationProfileQuery,
    TalentApplicationProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TalentApplicationProfileQuery, TalentApplicationProfileQueryVariables>(
    TalentApplicationProfileDocument,
    options
  );
}
export function useTalentApplicationProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationProfileQuery,
    TalentApplicationProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TalentApplicationProfileQuery, TalentApplicationProfileQueryVariables>(
    TalentApplicationProfileDocument,
    options
  );
}
export type TalentApplicationProfileQueryHookResult = ReturnType<
  typeof useTalentApplicationProfileQuery
>;
export type TalentApplicationProfileLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationProfileLazyQuery
>;
export type TalentApplicationProfileQueryResult = Apollo.QueryResult<
  TalentApplicationProfileQuery,
  TalentApplicationProfileQueryVariables
>;
export const InitTalentApplicationDocument = gql`
  mutation InitTalentApplication {
    initTalentApplication {
      status
    }
  }
`;
export type InitTalentApplicationMutationFn = Apollo.MutationFunction<
  InitTalentApplicationMutation,
  InitTalentApplicationMutationVariables
>;

/**
 * __useInitTalentApplicationMutation__
 *
 * To run a mutation, you first call `useInitTalentApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitTalentApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initTalentApplicationMutation, { data, loading, error }] = useInitTalentApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitTalentApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitTalentApplicationMutation,
    InitTalentApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InitTalentApplicationMutation, InitTalentApplicationMutationVariables>(
    InitTalentApplicationDocument,
    options
  );
}
export type InitTalentApplicationMutationHookResult = ReturnType<
  typeof useInitTalentApplicationMutation
>;
export type InitTalentApplicationMutationResult =
  Apollo.MutationResult<InitTalentApplicationMutation>;
export type InitTalentApplicationMutationOptions = Apollo.BaseMutationOptions<
  InitTalentApplicationMutation,
  InitTalentApplicationMutationVariables
>;
export const TalentApplicationStatusDocument = gql`
  query TalentApplicationStatus {
    talentApplication {
      status
      updatedAt
      updatedBy
    }
  }
`;

/**
 * __useTalentApplicationStatusQuery__
 *
 * To run a query within a React component, call `useTalentApplicationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentApplicationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentApplicationStatusQuery,
    TalentApplicationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TalentApplicationStatusQuery, TalentApplicationStatusQueryVariables>(
    TalentApplicationStatusDocument,
    options
  );
}
export function useTalentApplicationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationStatusQuery,
    TalentApplicationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TalentApplicationStatusQuery, TalentApplicationStatusQueryVariables>(
    TalentApplicationStatusDocument,
    options
  );
}
export type TalentApplicationStatusQueryHookResult = ReturnType<
  typeof useTalentApplicationStatusQuery
>;
export type TalentApplicationStatusLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationStatusLazyQuery
>;
export type TalentApplicationStatusQueryResult = Apollo.QueryResult<
  TalentApplicationStatusQuery,
  TalentApplicationStatusQueryVariables
>;
export const TalentApplicationStateDocument = gql`
  query TalentApplicationState {
    talentApplication {
      state {
        launch
        profile {
          resume
          references
          verification
          personalProfile
          emergencyContact
          authorizationCheck
        }
        sponsors
        profession
        legalClauses
        financialModels
        offeringParameters
      }
    }
  }
`;

/**
 * __useTalentApplicationStateQuery__
 *
 * To run a query within a React component, call `useTalentApplicationStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentApplicationStateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentApplicationStateQuery,
    TalentApplicationStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TalentApplicationStateQuery, TalentApplicationStateQueryVariables>(
    TalentApplicationStateDocument,
    options
  );
}
export function useTalentApplicationStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationStateQuery,
    TalentApplicationStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TalentApplicationStateQuery, TalentApplicationStateQueryVariables>(
    TalentApplicationStateDocument,
    options
  );
}
export type TalentApplicationStateQueryHookResult = ReturnType<
  typeof useTalentApplicationStateQuery
>;
export type TalentApplicationStateLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationStateLazyQuery
>;
export type TalentApplicationStateQueryResult = Apollo.QueryResult<
  TalentApplicationStateQuery,
  TalentApplicationStateQueryVariables
>;
export const TalentApplicationFinancialModelsDocument = gql`
  query TalentApplicationFinancialModels {
    talentApplication {
      financialModels {
        current {
          userType
          fileUrl
          fileName
          createdAt
          createdBy
          updatedAt
        }
        history {
          userType
          fileUrl
          fileName
          createdAt
          createdBy
          updatedAt
        }
        templateUrl
      }
    }
  }
`;

/**
 * __useTalentApplicationFinancialModelsQuery__
 *
 * To run a query within a React component, call `useTalentApplicationFinancialModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationFinancialModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationFinancialModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentApplicationFinancialModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentApplicationFinancialModelsQuery,
    TalentApplicationFinancialModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TalentApplicationFinancialModelsQuery,
    TalentApplicationFinancialModelsQueryVariables
  >(TalentApplicationFinancialModelsDocument, options);
}
export function useTalentApplicationFinancialModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationFinancialModelsQuery,
    TalentApplicationFinancialModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TalentApplicationFinancialModelsQuery,
    TalentApplicationFinancialModelsQueryVariables
  >(TalentApplicationFinancialModelsDocument, options);
}
export type TalentApplicationFinancialModelsQueryHookResult = ReturnType<
  typeof useTalentApplicationFinancialModelsQuery
>;
export type TalentApplicationFinancialModelsLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationFinancialModelsLazyQuery
>;
export type TalentApplicationFinancialModelsQueryResult = Apollo.QueryResult<
  TalentApplicationFinancialModelsQuery,
  TalentApplicationFinancialModelsQueryVariables
>;
export const UploadTalentApplicationFinancialModelDocument = gql`
  mutation UploadTalentApplicationFinancialModel($file: Upload!) {
    uploadTalentApplicationFinancialModel(file: $file)
  }
`;
export type UploadTalentApplicationFinancialModelMutationFn = Apollo.MutationFunction<
  UploadTalentApplicationFinancialModelMutation,
  UploadTalentApplicationFinancialModelMutationVariables
>;

/**
 * __useUploadTalentApplicationFinancialModelMutation__
 *
 * To run a mutation, you first call `useUploadTalentApplicationFinancialModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTalentApplicationFinancialModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTalentApplicationFinancialModelMutation, { data, loading, error }] = useUploadTalentApplicationFinancialModelMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadTalentApplicationFinancialModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadTalentApplicationFinancialModelMutation,
    UploadTalentApplicationFinancialModelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadTalentApplicationFinancialModelMutation,
    UploadTalentApplicationFinancialModelMutationVariables
  >(UploadTalentApplicationFinancialModelDocument, options);
}
export type UploadTalentApplicationFinancialModelMutationHookResult = ReturnType<
  typeof useUploadTalentApplicationFinancialModelMutation
>;
export type UploadTalentApplicationFinancialModelMutationResult =
  Apollo.MutationResult<UploadTalentApplicationFinancialModelMutation>;
export type UploadTalentApplicationFinancialModelMutationOptions = Apollo.BaseMutationOptions<
  UploadTalentApplicationFinancialModelMutation,
  UploadTalentApplicationFinancialModelMutationVariables
>;
export const UpdateTalentApplicationProfileDocument = gql`
  mutation UpdateTalentApplicationProfile($parameters: TalentApplicationProfileParameters!) {
    updateTalentApplicationProfile(parameters: $parameters) {
      profile {
        basicInformation {
          condition
        }
      }
    }
  }
`;
export type UpdateTalentApplicationProfileMutationFn = Apollo.MutationFunction<
  UpdateTalentApplicationProfileMutation,
  UpdateTalentApplicationProfileMutationVariables
>;

/**
 * __useUpdateTalentApplicationProfileMutation__
 *
 * To run a mutation, you first call `useUpdateTalentApplicationProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentApplicationProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentApplicationProfileMutation, { data, loading, error }] = useUpdateTalentApplicationProfileMutation({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useUpdateTalentApplicationProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTalentApplicationProfileMutation,
    UpdateTalentApplicationProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTalentApplicationProfileMutation,
    UpdateTalentApplicationProfileMutationVariables
  >(UpdateTalentApplicationProfileDocument, options);
}
export type UpdateTalentApplicationProfileMutationHookResult = ReturnType<
  typeof useUpdateTalentApplicationProfileMutation
>;
export type UpdateTalentApplicationProfileMutationResult =
  Apollo.MutationResult<UpdateTalentApplicationProfileMutation>;
export type UpdateTalentApplicationProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateTalentApplicationProfileMutation,
  UpdateTalentApplicationProfileMutationVariables
>;
export const UpdateTalentApplicationProfileEmergenciesDocument = gql`
  mutation UpdateTalentApplicationProfileEmergencies(
    $parameters: TalentApplicationProfileParameters!
  ) {
    updateTalentApplicationProfile(parameters: $parameters) {
      updatedAt
    }
  }
`;
export type UpdateTalentApplicationProfileEmergenciesMutationFn = Apollo.MutationFunction<
  UpdateTalentApplicationProfileEmergenciesMutation,
  UpdateTalentApplicationProfileEmergenciesMutationVariables
>;

/**
 * __useUpdateTalentApplicationProfileEmergenciesMutation__
 *
 * To run a mutation, you first call `useUpdateTalentApplicationProfileEmergenciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentApplicationProfileEmergenciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentApplicationProfileEmergenciesMutation, { data, loading, error }] = useUpdateTalentApplicationProfileEmergenciesMutation({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useUpdateTalentApplicationProfileEmergenciesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTalentApplicationProfileEmergenciesMutation,
    UpdateTalentApplicationProfileEmergenciesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTalentApplicationProfileEmergenciesMutation,
    UpdateTalentApplicationProfileEmergenciesMutationVariables
  >(UpdateTalentApplicationProfileEmergenciesDocument, options);
}
export type UpdateTalentApplicationProfileEmergenciesMutationHookResult = ReturnType<
  typeof useUpdateTalentApplicationProfileEmergenciesMutation
>;
export type UpdateTalentApplicationProfileEmergenciesMutationResult =
  Apollo.MutationResult<UpdateTalentApplicationProfileEmergenciesMutation>;
export type UpdateTalentApplicationProfileEmergenciesMutationOptions = Apollo.BaseMutationOptions<
  UpdateTalentApplicationProfileEmergenciesMutation,
  UpdateTalentApplicationProfileEmergenciesMutationVariables
>;
export const GetEmergenciesDocument = gql`
  query GetEmergencies {
    talentApplication {
      profile {
        emergencyContacts {
          id
          condition
          firstName
          lastName
          emailAddress
          phone
          relationship
        }
      }
    }
  }
`;

/**
 * __useGetEmergenciesQuery__
 *
 * To run a query within a React component, call `useGetEmergenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmergenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmergenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmergenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEmergenciesQuery, GetEmergenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEmergenciesQuery, GetEmergenciesQueryVariables>(
    GetEmergenciesDocument,
    options
  );
}
export function useGetEmergenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEmergenciesQuery, GetEmergenciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEmergenciesQuery, GetEmergenciesQueryVariables>(
    GetEmergenciesDocument,
    options
  );
}
export type GetEmergenciesQueryHookResult = ReturnType<typeof useGetEmergenciesQuery>;
export type GetEmergenciesLazyQueryHookResult = ReturnType<typeof useGetEmergenciesLazyQuery>;
export type GetEmergenciesQueryResult = Apollo.QueryResult<
  GetEmergenciesQuery,
  GetEmergenciesQueryVariables
>;
export const UpdateTalentApplicationProfileReferencesDocument = gql`
  mutation UpdateTalentApplicationProfileReferences(
    $parameters: TalentApplicationProfileParameters!
  ) {
    updateTalentApplicationProfile(parameters: $parameters) {
      profile {
        references {
          condition
        }
      }
    }
  }
`;
export type UpdateTalentApplicationProfileReferencesMutationFn = Apollo.MutationFunction<
  UpdateTalentApplicationProfileReferencesMutation,
  UpdateTalentApplicationProfileReferencesMutationVariables
>;

/**
 * __useUpdateTalentApplicationProfileReferencesMutation__
 *
 * To run a mutation, you first call `useUpdateTalentApplicationProfileReferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentApplicationProfileReferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentApplicationProfileReferencesMutation, { data, loading, error }] = useUpdateTalentApplicationProfileReferencesMutation({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useUpdateTalentApplicationProfileReferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTalentApplicationProfileReferencesMutation,
    UpdateTalentApplicationProfileReferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTalentApplicationProfileReferencesMutation,
    UpdateTalentApplicationProfileReferencesMutationVariables
  >(UpdateTalentApplicationProfileReferencesDocument, options);
}
export type UpdateTalentApplicationProfileReferencesMutationHookResult = ReturnType<
  typeof useUpdateTalentApplicationProfileReferencesMutation
>;
export type UpdateTalentApplicationProfileReferencesMutationResult =
  Apollo.MutationResult<UpdateTalentApplicationProfileReferencesMutation>;
export type UpdateTalentApplicationProfileReferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdateTalentApplicationProfileReferencesMutation,
  UpdateTalentApplicationProfileReferencesMutationVariables
>;
export const GetReferencesDocument = gql`
  query GetReferences {
    talentApplication {
      profile {
        references {
          id
          condition
          firstName
          lastName
          emailAddress
          phone
        }
      }
    }
  }
`;

/**
 * __useGetReferencesQuery__
 *
 * To run a query within a React component, call `useGetReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetReferencesQuery, GetReferencesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReferencesQuery, GetReferencesQueryVariables>(
    GetReferencesDocument,
    options
  );
}
export function useGetReferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReferencesQuery, GetReferencesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReferencesQuery, GetReferencesQueryVariables>(
    GetReferencesDocument,
    options
  );
}
export type GetReferencesQueryHookResult = ReturnType<typeof useGetReferencesQuery>;
export type GetReferencesLazyQueryHookResult = ReturnType<typeof useGetReferencesLazyQuery>;
export type GetReferencesQueryResult = Apollo.QueryResult<
  GetReferencesQuery,
  GetReferencesQueryVariables
>;
export const TalentApplicationLaunchDocument = gql`
  query TalentApplicationLaunch {
    talentApplication {
      launch {
        state {
          hcx {
            profile
            profession
            sponsorship
            financialModel
            offeringParameters
            legal
          }
          talent {
            profile
            profession
            sponsorship
            financialModel
            offeringParameters
            legal
          }
          sponsor {
            profile
            profession
            sponsorship
            financialModel
            offeringParameters
            legal
          }
        }
        approvals {
          hcx {
            approved
            approvedBy
            approvedAt
          }
          talent {
            approved
            approvedBy
            approvedAt
          }
          sponsor {
            approved
            approvedBy
            approvedAt
          }
        }
      }
    }
  }
`;

/**
 * __useTalentApplicationLaunchQuery__
 *
 * To run a query within a React component, call `useTalentApplicationLaunchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationLaunchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationLaunchQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentApplicationLaunchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentApplicationLaunchQuery,
    TalentApplicationLaunchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TalentApplicationLaunchQuery, TalentApplicationLaunchQueryVariables>(
    TalentApplicationLaunchDocument,
    options
  );
}
export function useTalentApplicationLaunchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationLaunchQuery,
    TalentApplicationLaunchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TalentApplicationLaunchQuery, TalentApplicationLaunchQueryVariables>(
    TalentApplicationLaunchDocument,
    options
  );
}
export type TalentApplicationLaunchQueryHookResult = ReturnType<
  typeof useTalentApplicationLaunchQuery
>;
export type TalentApplicationLaunchLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationLaunchLazyQuery
>;
export type TalentApplicationLaunchQueryResult = Apollo.QueryResult<
  TalentApplicationLaunchQuery,
  TalentApplicationLaunchQueryVariables
>;
export const UpdateTalentApplicationLaunchSectionDocument = gql`
  mutation UpdateTalentApplicationLaunchSection(
    $section: TalentApplicationLaunchSection!
    $checked: Boolean!
  ) {
    updateTalentApplicationLaunchSection(section: $section, checked: $checked)
  }
`;
export type UpdateTalentApplicationLaunchSectionMutationFn = Apollo.MutationFunction<
  UpdateTalentApplicationLaunchSectionMutation,
  UpdateTalentApplicationLaunchSectionMutationVariables
>;

/**
 * __useUpdateTalentApplicationLaunchSectionMutation__
 *
 * To run a mutation, you first call `useUpdateTalentApplicationLaunchSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentApplicationLaunchSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentApplicationLaunchSectionMutation, { data, loading, error }] = useUpdateTalentApplicationLaunchSectionMutation({
 *   variables: {
 *      section: // value for 'section'
 *      checked: // value for 'checked'
 *   },
 * });
 */
export function useUpdateTalentApplicationLaunchSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTalentApplicationLaunchSectionMutation,
    UpdateTalentApplicationLaunchSectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTalentApplicationLaunchSectionMutation,
    UpdateTalentApplicationLaunchSectionMutationVariables
  >(UpdateTalentApplicationLaunchSectionDocument, options);
}
export type UpdateTalentApplicationLaunchSectionMutationHookResult = ReturnType<
  typeof useUpdateTalentApplicationLaunchSectionMutation
>;
export type UpdateTalentApplicationLaunchSectionMutationResult =
  Apollo.MutationResult<UpdateTalentApplicationLaunchSectionMutation>;
export type UpdateTalentApplicationLaunchSectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateTalentApplicationLaunchSectionMutation,
  UpdateTalentApplicationLaunchSectionMutationVariables
>;
export const TalentIpoLegalClausesDocument = gql`
  query TalentIPOLegalClauses {
    talentIPOLegalClauses {
      id
      title
      text
      checked
    }
  }
`;

/**
 * __useTalentIpoLegalClausesQuery__
 *
 * To run a query within a React component, call `useTalentIpoLegalClausesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentIpoLegalClausesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentIpoLegalClausesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentIpoLegalClausesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentIpoLegalClausesQuery,
    TalentIpoLegalClausesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TalentIpoLegalClausesQuery, TalentIpoLegalClausesQueryVariables>(
    TalentIpoLegalClausesDocument,
    options
  );
}
export function useTalentIpoLegalClausesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentIpoLegalClausesQuery,
    TalentIpoLegalClausesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TalentIpoLegalClausesQuery, TalentIpoLegalClausesQueryVariables>(
    TalentIpoLegalClausesDocument,
    options
  );
}
export type TalentIpoLegalClausesQueryHookResult = ReturnType<typeof useTalentIpoLegalClausesQuery>;
export type TalentIpoLegalClausesLazyQueryHookResult = ReturnType<
  typeof useTalentIpoLegalClausesLazyQuery
>;
export type TalentIpoLegalClausesQueryResult = Apollo.QueryResult<
  TalentIpoLegalClausesQuery,
  TalentIpoLegalClausesQueryVariables
>;
export const TalentApplicationLegalClausesDocument = gql`
  query TalentApplicationLegalClauses {
    talentApplication {
      legalClauses {
        id
        checked
      }
    }
  }
`;

/**
 * __useTalentApplicationLegalClausesQuery__
 *
 * To run a query within a React component, call `useTalentApplicationLegalClausesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationLegalClausesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationLegalClausesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentApplicationLegalClausesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentApplicationLegalClausesQuery,
    TalentApplicationLegalClausesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TalentApplicationLegalClausesQuery,
    TalentApplicationLegalClausesQueryVariables
  >(TalentApplicationLegalClausesDocument, options);
}
export function useTalentApplicationLegalClausesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationLegalClausesQuery,
    TalentApplicationLegalClausesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TalentApplicationLegalClausesQuery,
    TalentApplicationLegalClausesQueryVariables
  >(TalentApplicationLegalClausesDocument, options);
}
export type TalentApplicationLegalClausesQueryHookResult = ReturnType<
  typeof useTalentApplicationLegalClausesQuery
>;
export type TalentApplicationLegalClausesLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationLegalClausesLazyQuery
>;
export type TalentApplicationLegalClausesQueryResult = Apollo.QueryResult<
  TalentApplicationLegalClausesQuery,
  TalentApplicationLegalClausesQueryVariables
>;
export const UpdateTalentApplicationLegalClausesDocument = gql`
  mutation UpdateTalentApplicationLegalClauses($legalClauses: Int!, $checked: Boolean!) {
    updateTalentApplicationLegalClause(clauseId: $legalClauses, checked: $checked)
  }
`;
export type UpdateTalentApplicationLegalClausesMutationFn = Apollo.MutationFunction<
  UpdateTalentApplicationLegalClausesMutation,
  UpdateTalentApplicationLegalClausesMutationVariables
>;

/**
 * __useUpdateTalentApplicationLegalClausesMutation__
 *
 * To run a mutation, you first call `useUpdateTalentApplicationLegalClausesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentApplicationLegalClausesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentApplicationLegalClausesMutation, { data, loading, error }] = useUpdateTalentApplicationLegalClausesMutation({
 *   variables: {
 *      legalClauses: // value for 'legalClauses'
 *      checked: // value for 'checked'
 *   },
 * });
 */
export function useUpdateTalentApplicationLegalClausesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTalentApplicationLegalClausesMutation,
    UpdateTalentApplicationLegalClausesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTalentApplicationLegalClausesMutation,
    UpdateTalentApplicationLegalClausesMutationVariables
  >(UpdateTalentApplicationLegalClausesDocument, options);
}
export type UpdateTalentApplicationLegalClausesMutationHookResult = ReturnType<
  typeof useUpdateTalentApplicationLegalClausesMutation
>;
export type UpdateTalentApplicationLegalClausesMutationResult =
  Apollo.MutationResult<UpdateTalentApplicationLegalClausesMutation>;
export type UpdateTalentApplicationLegalClausesMutationOptions = Apollo.BaseMutationOptions<
  UpdateTalentApplicationLegalClausesMutation,
  UpdateTalentApplicationLegalClausesMutationVariables
>;
export const ApproveTalentApplicationLaunchDocument = gql`
  mutation ApproveTalentApplicationLaunch {
    approveTalentApplicationLaunch
  }
`;
export type ApproveTalentApplicationLaunchMutationFn = Apollo.MutationFunction<
  ApproveTalentApplicationLaunchMutation,
  ApproveTalentApplicationLaunchMutationVariables
>;

/**
 * __useApproveTalentApplicationLaunchMutation__
 *
 * To run a mutation, you first call `useApproveTalentApplicationLaunchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTalentApplicationLaunchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTalentApplicationLaunchMutation, { data, loading, error }] = useApproveTalentApplicationLaunchMutation({
 *   variables: {
 *   },
 * });
 */
export function useApproveTalentApplicationLaunchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveTalentApplicationLaunchMutation,
    ApproveTalentApplicationLaunchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveTalentApplicationLaunchMutation,
    ApproveTalentApplicationLaunchMutationVariables
  >(ApproveTalentApplicationLaunchDocument, options);
}
export type ApproveTalentApplicationLaunchMutationHookResult = ReturnType<
  typeof useApproveTalentApplicationLaunchMutation
>;
export type ApproveTalentApplicationLaunchMutationResult =
  Apollo.MutationResult<ApproveTalentApplicationLaunchMutation>;
export type ApproveTalentApplicationLaunchMutationOptions = Apollo.BaseMutationOptions<
  ApproveTalentApplicationLaunchMutation,
  ApproveTalentApplicationLaunchMutationVariables
>;
export const TalentApplicationOfferingParametersDocument = gql`
  query TalentApplicationOfferingParameters {
    talentApplication {
      offeringParameters {
        startDate
        endDate
        futureIncome
        futureIncomeShared
        minimumGross
        numberOfShares
        minimumSharePrice
      }
    }
  }
`;

/**
 * __useTalentApplicationOfferingParametersQuery__
 *
 * To run a query within a React component, call `useTalentApplicationOfferingParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationOfferingParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationOfferingParametersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentApplicationOfferingParametersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentApplicationOfferingParametersQuery,
    TalentApplicationOfferingParametersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TalentApplicationOfferingParametersQuery,
    TalentApplicationOfferingParametersQueryVariables
  >(TalentApplicationOfferingParametersDocument, options);
}
export function useTalentApplicationOfferingParametersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationOfferingParametersQuery,
    TalentApplicationOfferingParametersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TalentApplicationOfferingParametersQuery,
    TalentApplicationOfferingParametersQueryVariables
  >(TalentApplicationOfferingParametersDocument, options);
}
export type TalentApplicationOfferingParametersQueryHookResult = ReturnType<
  typeof useTalentApplicationOfferingParametersQuery
>;
export type TalentApplicationOfferingParametersLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationOfferingParametersLazyQuery
>;
export type TalentApplicationOfferingParametersQueryResult = Apollo.QueryResult<
  TalentApplicationOfferingParametersQuery,
  TalentApplicationOfferingParametersQueryVariables
>;
export const TalentTypeConfigurationDocument = gql`
  query TalentTypeConfiguration($talentType: TalentType!) {
    talentTypeConfiguration(talentType: $talentType) {
      talentType
      uiSchema
      jsonSchema
    }
  }
`;

/**
 * __useTalentTypeConfigurationQuery__
 *
 * To run a query within a React component, call `useTalentTypeConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentTypeConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentTypeConfigurationQuery({
 *   variables: {
 *      talentType: // value for 'talentType'
 *   },
 * });
 */
export function useTalentTypeConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    TalentTypeConfigurationQuery,
    TalentTypeConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TalentTypeConfigurationQuery, TalentTypeConfigurationQueryVariables>(
    TalentTypeConfigurationDocument,
    options
  );
}
export function useTalentTypeConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentTypeConfigurationQuery,
    TalentTypeConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TalentTypeConfigurationQuery, TalentTypeConfigurationQueryVariables>(
    TalentTypeConfigurationDocument,
    options
  );
}
export type TalentTypeConfigurationQueryHookResult = ReturnType<
  typeof useTalentTypeConfigurationQuery
>;
export type TalentTypeConfigurationLazyQueryHookResult = ReturnType<
  typeof useTalentTypeConfigurationLazyQuery
>;
export type TalentTypeConfigurationQueryResult = Apollo.QueryResult<
  TalentTypeConfigurationQuery,
  TalentTypeConfigurationQueryVariables
>;
export const TalentTypesDocument = gql`
  query TalentTypes {
    talentTypes
  }
`;

/**
 * __useTalentTypesQuery__
 *
 * To run a query within a React component, call `useTalentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<TalentTypesQuery, TalentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TalentTypesQuery, TalentTypesQueryVariables>(TalentTypesDocument, options);
}
export function useTalentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TalentTypesQuery, TalentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TalentTypesQuery, TalentTypesQueryVariables>(
    TalentTypesDocument,
    options
  );
}
export type TalentTypesQueryHookResult = ReturnType<typeof useTalentTypesQuery>;
export type TalentTypesLazyQueryHookResult = ReturnType<typeof useTalentTypesLazyQuery>;
export type TalentTypesQueryResult = Apollo.QueryResult<
  TalentTypesQuery,
  TalentTypesQueryVariables
>;
export const UpdateTalentApplicationProfessionDocument = gql`
  mutation UpdateTalentApplicationProfession($parameters: TalentApplicationProfessionParameters!) {
    updateTalentApplicationProfession(parameters: $parameters)
  }
`;
export type UpdateTalentApplicationProfessionMutationFn = Apollo.MutationFunction<
  UpdateTalentApplicationProfessionMutation,
  UpdateTalentApplicationProfessionMutationVariables
>;

/**
 * __useUpdateTalentApplicationProfessionMutation__
 *
 * To run a mutation, you first call `useUpdateTalentApplicationProfessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTalentApplicationProfessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTalentApplicationProfessionMutation, { data, loading, error }] = useUpdateTalentApplicationProfessionMutation({
 *   variables: {
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useUpdateTalentApplicationProfessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTalentApplicationProfessionMutation,
    UpdateTalentApplicationProfessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTalentApplicationProfessionMutation,
    UpdateTalentApplicationProfessionMutationVariables
  >(UpdateTalentApplicationProfessionDocument, options);
}
export type UpdateTalentApplicationProfessionMutationHookResult = ReturnType<
  typeof useUpdateTalentApplicationProfessionMutation
>;
export type UpdateTalentApplicationProfessionMutationResult =
  Apollo.MutationResult<UpdateTalentApplicationProfessionMutation>;
export type UpdateTalentApplicationProfessionMutationOptions = Apollo.BaseMutationOptions<
  UpdateTalentApplicationProfessionMutation,
  UpdateTalentApplicationProfessionMutationVariables
>;
export const TalentApplicationProfessionDetailsDocument = gql`
  query TalentApplicationProfessionDetails {
    talentApplication {
      profession {
        occupation {
          talentType
          details
        }
      }
    }
  }
`;

/**
 * __useTalentApplicationProfessionDetailsQuery__
 *
 * To run a query within a React component, call `useTalentApplicationProfessionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationProfessionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationProfessionDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTalentApplicationProfessionDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TalentApplicationProfessionDetailsQuery,
    TalentApplicationProfessionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TalentApplicationProfessionDetailsQuery,
    TalentApplicationProfessionDetailsQueryVariables
  >(TalentApplicationProfessionDetailsDocument, options);
}
export function useTalentApplicationProfessionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationProfessionDetailsQuery,
    TalentApplicationProfessionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TalentApplicationProfessionDetailsQuery,
    TalentApplicationProfessionDetailsQueryVariables
  >(TalentApplicationProfessionDetailsDocument, options);
}
export type TalentApplicationProfessionDetailsQueryHookResult = ReturnType<
  typeof useTalentApplicationProfessionDetailsQuery
>;
export type TalentApplicationProfessionDetailsLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationProfessionDetailsLazyQuery
>;
export type TalentApplicationProfessionDetailsQueryResult = Apollo.QueryResult<
  TalentApplicationProfessionDetailsQuery,
  TalentApplicationProfessionDetailsQueryVariables
>;
export const TalentApplicationSponsorsListDocument = gql`
  query TalentApplicationSponsorsList(
    $offset: Int!
    $limit: Int!
    $orderType: TalentSponsorOrderType!
    $orderDirection: OrderDirection!
    $filter: String!
  ) {
    talentApplication {
      sponsors(
        offset: $offset
        limit: $limit
        orderType: $orderType
        orderDirection: $orderDirection
        filter: $filter
      ) {
        data {
          id
          name
          level
          source
          status
          description
          phoneNumber
          emailAddress
          logoUrlForDarkBackground
          logoUrlForLightBackground
        }
        pagination {
          limit
          total
          offset
        }
      }
    }
  }
`;

/**
 * __useTalentApplicationSponsorsListQuery__
 *
 * To run a query within a React component, call `useTalentApplicationSponsorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTalentApplicationSponsorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTalentApplicationSponsorsListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      orderType: // value for 'orderType'
 *      orderDirection: // value for 'orderDirection'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTalentApplicationSponsorsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    TalentApplicationSponsorsListQuery,
    TalentApplicationSponsorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TalentApplicationSponsorsListQuery,
    TalentApplicationSponsorsListQueryVariables
  >(TalentApplicationSponsorsListDocument, options);
}
export function useTalentApplicationSponsorsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TalentApplicationSponsorsListQuery,
    TalentApplicationSponsorsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TalentApplicationSponsorsListQuery,
    TalentApplicationSponsorsListQueryVariables
  >(TalentApplicationSponsorsListDocument, options);
}
export type TalentApplicationSponsorsListQueryHookResult = ReturnType<
  typeof useTalentApplicationSponsorsListQuery
>;
export type TalentApplicationSponsorsListLazyQueryHookResult = ReturnType<
  typeof useTalentApplicationSponsorsListLazyQuery
>;
export type TalentApplicationSponsorsListQueryResult = Apollo.QueryResult<
  TalentApplicationSponsorsListQuery,
  TalentApplicationSponsorsListQueryVariables
>;
export const SendTalentSponsorInvitationDocument = gql`
  mutation SendTalentSponsorInvitation($sponsorId: ID!) {
    sendTalentSponsorInvitation(sponsorId: $sponsorId)
  }
`;
export type SendTalentSponsorInvitationMutationFn = Apollo.MutationFunction<
  SendTalentSponsorInvitationMutation,
  SendTalentSponsorInvitationMutationVariables
>;

/**
 * __useSendTalentSponsorInvitationMutation__
 *
 * To run a mutation, you first call `useSendTalentSponsorInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTalentSponsorInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTalentSponsorInvitationMutation, { data, loading, error }] = useSendTalentSponsorInvitationMutation({
 *   variables: {
 *      sponsorId: // value for 'sponsorId'
 *   },
 * });
 */
export function useSendTalentSponsorInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendTalentSponsorInvitationMutation,
    SendTalentSponsorInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendTalentSponsorInvitationMutation,
    SendTalentSponsorInvitationMutationVariables
  >(SendTalentSponsorInvitationDocument, options);
}
export type SendTalentSponsorInvitationMutationHookResult = ReturnType<
  typeof useSendTalentSponsorInvitationMutation
>;
export type SendTalentSponsorInvitationMutationResult =
  Apollo.MutationResult<SendTalentSponsorInvitationMutation>;
export type SendTalentSponsorInvitationMutationOptions = Apollo.BaseMutationOptions<
  SendTalentSponsorInvitationMutation,
  SendTalentSponsorInvitationMutationVariables
>;
export const CancelTalentSponsorInvitationDocument = gql`
  mutation CancelTalentSponsorInvitation($sponsorId: ID!) {
    cancelTalentSponsorInvitation(sponsorId: $sponsorId)
  }
`;
export type CancelTalentSponsorInvitationMutationFn = Apollo.MutationFunction<
  CancelTalentSponsorInvitationMutation,
  CancelTalentSponsorInvitationMutationVariables
>;

/**
 * __useCancelTalentSponsorInvitationMutation__
 *
 * To run a mutation, you first call `useCancelTalentSponsorInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTalentSponsorInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTalentSponsorInvitationMutation, { data, loading, error }] = useCancelTalentSponsorInvitationMutation({
 *   variables: {
 *      sponsorId: // value for 'sponsorId'
 *   },
 * });
 */
export function useCancelTalentSponsorInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelTalentSponsorInvitationMutation,
    CancelTalentSponsorInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelTalentSponsorInvitationMutation,
    CancelTalentSponsorInvitationMutationVariables
  >(CancelTalentSponsorInvitationDocument, options);
}
export type CancelTalentSponsorInvitationMutationHookResult = ReturnType<
  typeof useCancelTalentSponsorInvitationMutation
>;
export type CancelTalentSponsorInvitationMutationResult =
  Apollo.MutationResult<CancelTalentSponsorInvitationMutation>;
export type CancelTalentSponsorInvitationMutationOptions = Apollo.BaseMutationOptions<
  CancelTalentSponsorInvitationMutation,
  CancelTalentSponsorInvitationMutationVariables
>;
export const CancelTalentApplicationDocument = gql`
  mutation CancelTalentApplication {
    cancelTalentApplication
  }
`;
export type CancelTalentApplicationMutationFn = Apollo.MutationFunction<
  CancelTalentApplicationMutation,
  CancelTalentApplicationMutationVariables
>;

/**
 * __useCancelTalentApplicationMutation__
 *
 * To run a mutation, you first call `useCancelTalentApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTalentApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTalentApplicationMutation, { data, loading, error }] = useCancelTalentApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelTalentApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelTalentApplicationMutation,
    CancelTalentApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelTalentApplicationMutation,
    CancelTalentApplicationMutationVariables
  >(CancelTalentApplicationDocument, options);
}
export type CancelTalentApplicationMutationHookResult = ReturnType<
  typeof useCancelTalentApplicationMutation
>;
export type CancelTalentApplicationMutationResult =
  Apollo.MutationResult<CancelTalentApplicationMutation>;
export type CancelTalentApplicationMutationOptions = Apollo.BaseMutationOptions<
  CancelTalentApplicationMutation,
  CancelTalentApplicationMutationVariables
>;
export const PromotedTalentsListDocument = gql`
  query PromotedTalentsList {
    promotedTalents {
      profileImageUrl
      typeDetails {
        ... on AthleteType {
          __typename
          arms
          college
          experience
          hands
          height
          homeTown
          jerseyNumber
          league
          role
          team
          weight
        }
        ... on DoctorType {
          __typename
          graduated
          specialty
          medicalCollege
          undergrad
        }
      }
    }
  }
`;

/**
 * __usePromotedTalentsListQuery__
 *
 * To run a query within a React component, call `usePromotedTalentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotedTalentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotedTalentsListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromotedTalentsListQuery(
  baseOptions?: Apollo.QueryHookOptions<PromotedTalentsListQuery, PromotedTalentsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PromotedTalentsListQuery, PromotedTalentsListQueryVariables>(
    PromotedTalentsListDocument,
    options
  );
}
export function usePromotedTalentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PromotedTalentsListQuery,
    PromotedTalentsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PromotedTalentsListQuery, PromotedTalentsListQueryVariables>(
    PromotedTalentsListDocument,
    options
  );
}
export type PromotedTalentsListQueryHookResult = ReturnType<typeof usePromotedTalentsListQuery>;
export type PromotedTalentsListLazyQueryHookResult = ReturnType<
  typeof usePromotedTalentsListLazyQuery
>;
export type PromotedTalentsListQueryResult = Apollo.QueryResult<
  PromotedTalentsListQuery,
  PromotedTalentsListQueryVariables
>;
export const LatestBidsReceivedDocument = gql`
  subscription LatestBidsReceived($auctionId: Int!) {
    latestBidRecievedOnAuction(auctionId: $auctionId) {
      bidData {
        ...DutchAuctionBidFields
      }
      status
    }
  }
  ${DutchAuctionBidFieldsFragmentDoc}
`;

/**
 * __useLatestBidsReceivedSubscription__
 *
 * To run a query within a React component, call `useLatestBidsReceivedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLatestBidsReceivedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestBidsReceivedSubscription({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useLatestBidsReceivedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LatestBidsReceivedSubscription,
    LatestBidsReceivedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LatestBidsReceivedSubscription,
    LatestBidsReceivedSubscriptionVariables
  >(LatestBidsReceivedDocument, options);
}
export type LatestBidsReceivedSubscriptionHookResult = ReturnType<
  typeof useLatestBidsReceivedSubscription
>;
export type LatestBidsReceivedSubscriptionResult =
  Apollo.SubscriptionResult<LatestBidsReceivedSubscription>;
export const AuctionAllBidsDocument = gql`
  query AuctionAllBids($auctionId: Int!) {
    bids(auctionId: $auctionId) {
      ...DutchAuctionBidFields
    }
  }
  ${DutchAuctionBidFieldsFragmentDoc}
`;

/**
 * __useAuctionAllBidsQuery__
 *
 * To run a query within a React component, call `useAuctionAllBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionAllBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionAllBidsQuery({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useAuctionAllBidsQuery(
  baseOptions: Apollo.QueryHookOptions<AuctionAllBidsQuery, AuctionAllBidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuctionAllBidsQuery, AuctionAllBidsQueryVariables>(
    AuctionAllBidsDocument,
    options
  );
}
export function useAuctionAllBidsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuctionAllBidsQuery, AuctionAllBidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuctionAllBidsQuery, AuctionAllBidsQueryVariables>(
    AuctionAllBidsDocument,
    options
  );
}
export type AuctionAllBidsQueryHookResult = ReturnType<typeof useAuctionAllBidsQuery>;
export type AuctionAllBidsLazyQueryHookResult = ReturnType<typeof useAuctionAllBidsLazyQuery>;
export type AuctionAllBidsQueryResult = Apollo.QueryResult<
  AuctionAllBidsQuery,
  AuctionAllBidsQueryVariables
>;
export const FinalizeAuctionDocument = gql`
  mutation FinalizeAuction($auctionId: ID!) {
    finalizeAuction(auctionId: $auctionId)
  }
`;
export type FinalizeAuctionMutationFn = Apollo.MutationFunction<
  FinalizeAuctionMutation,
  FinalizeAuctionMutationVariables
>;

/**
 * __useFinalizeAuctionMutation__
 *
 * To run a mutation, you first call `useFinalizeAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeAuctionMutation, { data, loading, error }] = useFinalizeAuctionMutation({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useFinalizeAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeAuctionMutation,
    FinalizeAuctionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizeAuctionMutation, FinalizeAuctionMutationVariables>(
    FinalizeAuctionDocument,
    options
  );
}
export type FinalizeAuctionMutationHookResult = ReturnType<typeof useFinalizeAuctionMutation>;
export type FinalizeAuctionMutationResult = Apollo.MutationResult<FinalizeAuctionMutation>;
export type FinalizeAuctionMutationOptions = Apollo.BaseMutationOptions<
  FinalizeAuctionMutation,
  FinalizeAuctionMutationVariables
>;
export const ActiveMintsDocument = gql`
  query ActiveMints($input: DutchAuctionSearchInput!) {
    dutchAuctions(input: $input) {
      data {
        id
        blockchainId
        floorPrice
        endPrice
        durationDays
        sponsorStatus
        fractions
        athleteContract {
          id
          nftTokenId
          revenuePercentage
          athlete {
            id
            dob
            education
            erc20SmartContractAddress
            ethereumAddress
            fullName
            height
            imageUrl
            nftName
            nftSmartContractAddress
            nftSymbol
            sex
            tradingPair {
              id
              tokenAddress
              stableCoinAddress
            }
            jobHistory {
              drafted
              league
              number
              position
              rosterStatus
              team
            }
          }
        }
        status
        startDate
        stats {
          ...AuctionStatsFields
        }
        auctionType
        minimumTotalProceeds
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
  ${AuctionStatsFieldsFragmentDoc}
`;

/**
 * __useActiveMintsQuery__
 *
 * To run a query within a React component, call `useActiveMintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveMintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveMintsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActiveMintsQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveMintsQuery, ActiveMintsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveMintsQuery, ActiveMintsQueryVariables>(ActiveMintsDocument, options);
}
export function useActiveMintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveMintsQuery, ActiveMintsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveMintsQuery, ActiveMintsQueryVariables>(
    ActiveMintsDocument,
    options
  );
}
export type ActiveMintsQueryHookResult = ReturnType<typeof useActiveMintsQuery>;
export type ActiveMintsLazyQueryHookResult = ReturnType<typeof useActiveMintsLazyQuery>;
export type ActiveMintsQueryResult = Apollo.QueryResult<
  ActiveMintsQuery,
  ActiveMintsQueryVariables
>;
export const DutchAuctionStatsByIdDocument = gql`
  query DutchAuctionStatsById($input: DutchAuctionSearchInput!) {
    dutchAuctions(input: $input) {
      data {
        id
        fractions
        athleteContract {
          id
          revenuePercentage
        }
        stats {
          ...AuctionStatsFields
        }
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
  ${AuctionStatsFieldsFragmentDoc}
`;

/**
 * __useDutchAuctionStatsByIdQuery__
 *
 * To run a query within a React component, call `useDutchAuctionStatsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDutchAuctionStatsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDutchAuctionStatsByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDutchAuctionStatsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    DutchAuctionStatsByIdQuery,
    DutchAuctionStatsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DutchAuctionStatsByIdQuery, DutchAuctionStatsByIdQueryVariables>(
    DutchAuctionStatsByIdDocument,
    options
  );
}
export function useDutchAuctionStatsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DutchAuctionStatsByIdQuery,
    DutchAuctionStatsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DutchAuctionStatsByIdQuery, DutchAuctionStatsByIdQueryVariables>(
    DutchAuctionStatsByIdDocument,
    options
  );
}
export type DutchAuctionStatsByIdQueryHookResult = ReturnType<typeof useDutchAuctionStatsByIdQuery>;
export type DutchAuctionStatsByIdLazyQueryHookResult = ReturnType<
  typeof useDutchAuctionStatsByIdLazyQuery
>;
export type DutchAuctionStatsByIdQueryResult = Apollo.QueryResult<
  DutchAuctionStatsByIdQuery,
  DutchAuctionStatsByIdQueryVariables
>;
export const LatestAuctionStatsDocument = gql`
  subscription LatestAuctionStats($auctionId: Int!) {
    latestAuctionStats(auctionId: $auctionId) {
      ...AuctionStatsFields
    }
  }
  ${AuctionStatsFieldsFragmentDoc}
`;

/**
 * __useLatestAuctionStatsSubscription__
 *
 * To run a query within a React component, call `useLatestAuctionStatsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLatestAuctionStatsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestAuctionStatsSubscription({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useLatestAuctionStatsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LatestAuctionStatsSubscription,
    LatestAuctionStatsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LatestAuctionStatsSubscription,
    LatestAuctionStatsSubscriptionVariables
  >(LatestAuctionStatsDocument, options);
}
export type LatestAuctionStatsSubscriptionHookResult = ReturnType<
  typeof useLatestAuctionStatsSubscription
>;
export type LatestAuctionStatsSubscriptionResult =
  Apollo.SubscriptionResult<LatestAuctionStatsSubscription>;
export const AcceptSponsorUserInvitationDocument = gql`
  mutation AcceptSponsorUserInvitation($token: String!) {
    acceptSponsorUserInvitation(token: $token)
  }
`;
export type AcceptSponsorUserInvitationMutationFn = Apollo.MutationFunction<
  AcceptSponsorUserInvitationMutation,
  AcceptSponsorUserInvitationMutationVariables
>;

/**
 * __useAcceptSponsorUserInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptSponsorUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSponsorUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSponsorUserInvitationMutation, { data, loading, error }] = useAcceptSponsorUserInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptSponsorUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptSponsorUserInvitationMutation,
    AcceptSponsorUserInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptSponsorUserInvitationMutation,
    AcceptSponsorUserInvitationMutationVariables
  >(AcceptSponsorUserInvitationDocument, options);
}
export type AcceptSponsorUserInvitationMutationHookResult = ReturnType<
  typeof useAcceptSponsorUserInvitationMutation
>;
export type AcceptSponsorUserInvitationMutationResult =
  Apollo.MutationResult<AcceptSponsorUserInvitationMutation>;
export type AcceptSponsorUserInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptSponsorUserInvitationMutation,
  AcceptSponsorUserInvitationMutationVariables
>;
export const AuctionUserBidsDocument = gql`
  query AuctionUserBids($auctionId: Int) {
    userBids(auctionId: $auctionId) {
      auctionId
      auctionBlockchainId
      blockHash
      bidderAddress
      dutchAuction {
        id
        athleteContract {
          id
          athlete {
            id
            fullName
            nftName
            nftSymbol
            imageUrl
          }
        }
      }
      tokenAmount
      tokenPrice
      orderDate
      orderType
      status
      userId
    }
  }
`;

/**
 * __useAuctionUserBidsQuery__
 *
 * To run a query within a React component, call `useAuctionUserBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionUserBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionUserBidsQuery({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useAuctionUserBidsQuery(
  baseOptions?: Apollo.QueryHookOptions<AuctionUserBidsQuery, AuctionUserBidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuctionUserBidsQuery, AuctionUserBidsQueryVariables>(
    AuctionUserBidsDocument,
    options
  );
}
export function useAuctionUserBidsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuctionUserBidsQuery, AuctionUserBidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuctionUserBidsQuery, AuctionUserBidsQueryVariables>(
    AuctionUserBidsDocument,
    options
  );
}
export type AuctionUserBidsQueryHookResult = ReturnType<typeof useAuctionUserBidsQuery>;
export type AuctionUserBidsLazyQueryHookResult = ReturnType<typeof useAuctionUserBidsLazyQuery>;
export type AuctionUserBidsQueryResult = Apollo.QueryResult<
  AuctionUserBidsQuery,
  AuctionUserBidsQueryVariables
>;
export const UsersTradingPairsDocument = gql`
  query UsersTradingPairs($input: TradingPairSearchInput!) {
    userTradingPairs(input: $input) {
      data {
        id
        tokenAddress
        stats {
          volume24
          lastPrice
          change24Percent
          change24Amount
          marketCap
        }
        athlete {
          id
          fullName
          nftSymbol
          imageUrl
        }
      }
      pagination {
        limit
        total
        offset
      }
    }
  }
`;

/**
 * __useUsersTradingPairsQuery__
 *
 * To run a query within a React component, call `useUsersTradingPairsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersTradingPairsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersTradingPairsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersTradingPairsQuery(
  baseOptions: Apollo.QueryHookOptions<UsersTradingPairsQuery, UsersTradingPairsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersTradingPairsQuery, UsersTradingPairsQueryVariables>(
    UsersTradingPairsDocument,
    options
  );
}
export function useUsersTradingPairsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersTradingPairsQuery, UsersTradingPairsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersTradingPairsQuery, UsersTradingPairsQueryVariables>(
    UsersTradingPairsDocument,
    options
  );
}
export type UsersTradingPairsQueryHookResult = ReturnType<typeof useUsersTradingPairsQuery>;
export type UsersTradingPairsLazyQueryHookResult = ReturnType<typeof useUsersTradingPairsLazyQuery>;
export type UsersTradingPairsQueryResult = Apollo.QueryResult<
  UsersTradingPairsQuery,
  UsersTradingPairsQueryVariables
>;
export const OrdersByUserIdDocument = gql`
  query OrdersByUserId($input: OrderSearchInput!) {
    orders(input: $input) {
      data {
        id
        date
        amountOfTokens
        price
        expiry
        makerAddress
        takerAddress
        takerAmount
        isBuy
        tradingPair {
          athlete {
            fullName
            nftSymbol
            imageUrl
          }
        }
      }
      pagination {
        limit
        total
        offset
      }
    }
  }
`;

/**
 * __useOrdersByUserIdQuery__
 *
 * To run a query within a React component, call `useOrdersByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersByUserIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrdersByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<OrdersByUserIdQuery, OrdersByUserIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersByUserIdQuery, OrdersByUserIdQueryVariables>(
    OrdersByUserIdDocument,
    options
  );
}
export function useOrdersByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersByUserIdQuery, OrdersByUserIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersByUserIdQuery, OrdersByUserIdQueryVariables>(
    OrdersByUserIdDocument,
    options
  );
}
export type OrdersByUserIdQueryHookResult = ReturnType<typeof useOrdersByUserIdQuery>;
export type OrdersByUserIdLazyQueryHookResult = ReturnType<typeof useOrdersByUserIdLazyQuery>;
export type OrdersByUserIdQueryResult = Apollo.QueryResult<
  OrdersByUserIdQuery,
  OrdersByUserIdQueryVariables
>;
