import type { FC, MouseEventHandler } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { EditRowIcon } from '../../../components/icons/edit-row-icon';
import styles from './column-configuration-list-item.module.scss';
import classNames from 'classnames';
import { memo } from 'react';
import { TableColumnsIcon } from '../../../components/icons/table-columns-icon';

interface PropsI {
  title: string;
  id: string;
  onClick: (id: string) => void;
  onEdit?: (id: string) => void;
  active?: boolean;
}

export const ColumnConfigurationListItem: FC<PropsI> = ({
  id,
  title,
  onClick,
  onEdit,
  active = false,
}) => {
  const handleEditClick: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit?.(id);
  };
  return (
    <Box
      className={classNames(styles.wrapper, active && styles.active)}
      onClick={() => onClick(id)}
    >
      <TableColumnsIcon className={styles.columnIcon} />
      <Typography variant="subtitle2" className={styles.title}>
        {title}
      </Typography>
      {onEdit && (
        <IconButton onClick={handleEditClick} className={styles.edit}>
          <EditRowIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default memo(ColumnConfigurationListItem);
